import {Inject, Injectable} from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { UserStore, UserState } from './user.store';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UserService extends NgEntityService<UserState> {

  constructor(
    @Inject('config') public override config: any,
    protected override store: UserStore,
    private httpClient: HttpClient,
  ) {
    super(store);
  }
  checkResetPasswordToken(token: string, email: string): Observable<any> {
    return this.httpClient.get(this.config.apiUrl + '/check_reset_password_token?token=' + token + '&email=' + email);
  }
  setPassword(token: string, email: string, password: string): Observable<any> {
    return this.httpClient.post(this.config.apiUrl + '/change_password', {token, email, password});
  }
  defineClientPassword(token: string, email: string, password: string): Observable<any> {
    return this.httpClient.post(this.config.apiUrl + '/define_client_password', {token, email, password});
  }
  sendResetPasswordEmail(email: string): Observable<any> {
    return this.httpClient.post(this.config.apiUrl + '/send_reset_password_email', {email});
  }
  userHasLinkedData(email: string): Observable<any> {
    return this.httpClient.get(this.config.apiUrl + '/user_has_linked_data?email=' + email);
  }

}
