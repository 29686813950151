<ng-container *ngIf="withTitles && documentTypes.length">
  <h3 class="rec">Documents à télécharger</h3>
  <p-divider></p-divider>
</ng-container>

<div class="flex" [formGroup]="form">
  <div class="flex flex-column">
    <ng-container *ngFor="let docType of documentTypes">
      <ng-container *ngIf="docType.multiple" [formArrayName]="docType.field">
        <ng-container *ngFor="let multipleDoc of getMultipleDocsF(docType.field)?.controls; index as i">

          <ng-container *ngIf="!multipleDoc.value.doc?.files?.length && !(multipleDoc.value.doc?.id && !multipleDoc.value.name)">
            <div class="flex align-items-center flex-grow-1 mb-3 doc-title">
              <div *ngIf="multipleDoc.value?.name">{{multipleDoc.value?.name}}</div>
              <div *ngIf="!multipleDoc.value?.name">{{docType.code | translate}} </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!docType.multiple">
        <ng-container *ngIf="!getSimpleDocsF(docType)?.value?.files && getSimpleDocsF(docType)?.value !== undefined && !getSimpleDocsF(docType)?.value?.id">
          <div class="flex align-items-center flex-grow-1 mb-3 doc-title">
            <div>
              {{docType.code | translate}}
              <ng-container *ngIf="docType.owner?.civility">{{' de ' + docType.owner.civility + ' ' + docType.owner.firstName + ' ' + docType.owner.lastName}}</ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </ng-container>
  </div>
  <div class="flex flex-column ml-6 doc-upload">
    <ng-container *ngFor="let docType of documentTypes ; index as i">
      <ng-container *ngIf="docType.multiple" [formArrayName]="docType.field">
        <ng-container *ngFor="let multipleDoc of getMultipleDocsF(docType.field)?.controls; index as i">
          <ng-container *ngIf="!multipleDoc.value.doc?.files?.length && !(multipleDoc.value.doc?.id && !multipleDoc.value.name)">
            <div class="mb-3">
              <app-file-uploader [formControlName]="i" [clientType]="clientType" [clientId]="clientId" [documentType]="docType"></app-file-uploader>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!docType.multiple">
        <ng-container *ngIf="!getSimpleDocsF(docType)?.value?.files && getSimpleDocsF(docType)?.value !== undefined && !getSimpleDocsF(docType)?.value?.id">
          <div class="mb-3">
            <app-file-uploader *ngIf="docType.owner?.id" [formControlName]="docType.field+'#'+docType.owner?.id" [clientType]="clientType" [clientId]="clientId" [documentType]="docType"></app-file-uploader>
            <app-file-uploader *ngIf="!docType.owner?.id" [formControlName]="docType.field" [clientType]="clientType" [clientId]="clientId" [documentType]="docType"></app-file-uploader>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

