<div #title></div>
<h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Biens immobiliers</h2>
<h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">Votre patrimoine immobilier</h2>
<p-divider></p-divider>

<ng-container *ngIf="clientOrAdmin === 'ADMIN'">
  <!--button encapsulé dans une div car tooltip marche pas sur btn disabled-->
  <div class="inline-block"
       pTooltip="Vous ne pouvez renseigner qu'une seule résidence principale"
       [tooltipDisabled]="!doesHaveMainType()">
    <p-button label="Résidence principale" icon="pi pi-plus" styleClass="p-button-raised mr-2"
              [badge]="countImmoByType(immobilierType.IMMO_TYPE_PRINCIPALE)"
              [disabled]="doesHaveMainType()" (onClick)="add(immobilierType.IMMO_TYPE_PRINCIPALE)"></p-button>
  </div>
  <p-button label="Résidence secondaire" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            [badge]="countImmoByType(immobilierType.IMMO_TYPE_SECONDAIRE)"
            (onClick)="add(immobilierType.IMMO_TYPE_SECONDAIRE)"></p-button>
  <p-button label="Foncier forestier" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            [badge]="countImmoByType(immobilierType.IMMO_TYPE_FONCIER_FORESTIER)"
            (onClick)="add(immobilierType.IMMO_TYPE_FONCIER_FORESTIER)"></p-button>
  <p-button label="Immobilier locatif" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            [badge]="countImmoByType(immobilierType.IMMO_TYPE_LOCATIF)"
            (onClick)="add(immobilierType.IMMO_TYPE_LOCATIF)"></p-button>
  <p-button label="SCPI" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            [badge]="countImmoByType(immobilierType.IMMO_TYPE_SCPI)"
            (onClick)="add(immobilierType.IMMO_TYPE_SCPI)"></p-button>
</ng-container>

<ng-container *ngIf="clientOrAdmin === 'CLIENT'">
  <div class="grid">
    <!--button encapsulé dans une div car tooltip marche pas sur btn disabled-->
    <div class="inline-block col-3"
         pTooltip="Vous ne pouvez renseigner qu'une seule résidence principale"
         [tooltipDisabled]="!doesHaveMainType()">
      <p-button label="Résidence principale" icon="pi pi-plus" styleClass="p-button-raised w-full"
                [badge]="countImmoByType(immobilierType.IMMO_TYPE_PRINCIPALE)"
                [disabled]="doesHaveMainType()" (onClick)="add(immobilierType.IMMO_TYPE_PRINCIPALE)"></p-button>
    </div>
    <div class="col-3">
      <p-button label="Résidence secondaire" icon="pi pi-plus" styleClass="p-button-raised w-full"
                [badge]="countImmoByType(immobilierType.IMMO_TYPE_SECONDAIRE)"
                (onClick)="add(immobilierType.IMMO_TYPE_SECONDAIRE)"></p-button>
    </div>
    <div class="col-3">
      <p-button label="Foncier forestier" icon="pi pi-plus" styleClass="p-button-raised w-full"
                [badge]="countImmoByType(immobilierType.IMMO_TYPE_FONCIER_FORESTIER)"
                (onClick)="add(immobilierType.IMMO_TYPE_FONCIER_FORESTIER)"></p-button>
    </div>
  </div>
  <div class="grid mt-2">
    <div class="col-3">
      <p-button label="Immobilier locatif" icon="pi pi-plus" styleClass="p-button-raised w-full"
                [badge]="countImmoByType(immobilierType.IMMO_TYPE_LOCATIF)"
                (onClick)="add(immobilierType.IMMO_TYPE_LOCATIF)"></p-button>
    </div>
    <div class="col-3">
      <p-button label="SCPI" icon="pi pi-plus" styleClass="p-button-raised w-full"
                [badge]="countImmoByType(immobilierType.IMMO_TYPE_SCPI)"
                (onClick)="add(immobilierType.IMMO_TYPE_SCPI)"></p-button>
    </div>
  </div>

</ng-container>


<ng-container *ngIf="clientOrAdmin === 'ADMIN'">
  <div [formGroup]="form" class="mt-5">
    <ng-container formArrayName="bienImmobiliers">
      <ng-container *ngFor="let item of immobilierControl?.controls; index as i">
        <p-fieldset legend="{{item.value['typeBien'] | translate}}" [toggleable]="true" styleClass="mt-5" [(collapsed)]="collapsedPanels[i].collapsed">
          <div class="flex justify-content-end mb-3">
            <button pButton type="button" icon="icon-font icon-changer-type-de-biens-2" class="p-button-raised p-button-text mr-3" (click)="openTypeDialog(i)"></button>
            <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text" (click)="remove(i)"></button>
          </div>
            <app-immobilier-form [formControlName]="i" [clientOrAdmin]="clientOrAdmin"></app-immobilier-form>
        </p-fieldset>
      </ng-container>
    </ng-container>

    <app-comment-read *ngIf="client.recueil !== undefined"
                      title="Informations complémentaires client"
                      [content]="client.recueil?.comBienImmobilier">
    </app-comment-read>

    <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comConseilleBienImmobilier"></app-comment-form>
  </div>
</ng-container>

<ng-container *ngIf="clientOrAdmin === 'CLIENT'">
  <div [formGroup]="form" class="mt-5">
    <ng-container formArrayName="bienImmobiliers">
      <p-accordion *ngFor="let item of immobilierControl?.controls; index as i" styleClass="mb-6">
        <p-accordionTab [(selected)]="!collapsedPanels[i].collapsed">

          <ng-template pTemplate="header">
            <div class="flex justify-content-between w-full align-items-center">
              <h3 class="rec">{{item.value['typeBien'] | translate}}</h3>
              <div>
                <button pButton type="button" icon="icon-font icon-changer-type-de-biens-2" class="p-button-raised p-button-text mr-3" (click)="openTypeDialog(i)"></button>
                <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text" (click)="remove(i)"></button>
              </div>
            </div>
          </ng-template>
          <app-immobilier-form
            [formControlName]="i" [clientId]="clientId"
            [clientType]="clientType"
            [clientOrAdmin]="clientOrAdmin"
            [documentTypes]="[documentType.IMMO_STATUT_SCI,
              documentType.IMMO_ATTEST_NOTARIAL,
              documentType.IMMO_TAXE_FONCIERE,
              documentType.IMMO_TITRE_PROPRIETE,
              documentType.IMMO_OFFRE_PRET,
              documentType.AUTRE_IMMO]"
          ></app-immobilier-form>
        </p-accordionTab>
      </p-accordion>
    </ng-container>

    <h2 class="rec mt-6">{{'CLIENT_STEP_BIENSIMMO_COMMENT' | translate}}</h2>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comBienImmobilier"></app-comment-form>
  </div>
</ng-container>

<p-dialog
  [(visible)]="displaySwitchTypeDialog"
  [style]="{width: '70vw', height: '590px'}"
  header="Changer le type de bien immobilier"
  [modal]="true">
  <ng-container *ngIf="selectedToSwitchType">
    <div class="p-d-flex p-ai-center p-3 field" >
      <label for="type">Type de bien</label>
      <p-dropdown
        [(ngModel)]="selectedToSwitchType.type"
        placeholder="..."
        id="type"
        styleClass="w-full"
        [options]="immobilierTypeOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        [virtualScroll]="true"
        [itemSize]="30"
      ></p-dropdown>
    </div>
    <div class="pl-3 invalid-feedback" *ngIf="doesHaveMainType() && selectedToSwitchType.type === immobilierType.IMMO_TYPE_PRINCIPALE">
      Vous avez déjà une résidence principale, veuillez la modifier avant d'indiquer ce bien comme Résidence principale
    </div>
  </ng-container>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end mt-5">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="displaySwitchTypeDialog = false">

      </p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-primary p-button-raised"
                (onClick)="switchType()"
                [disabled]="doesHaveMainType() && selectedToSwitchType.type === immobilierType.IMMO_TYPE_PRINCIPALE">
      </p-button>
    </div>
  </ng-template>
</p-dialog>


