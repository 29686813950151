import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import {ID, isArray} from '@datorama/akita';
import {
  ControlValueAccessor, UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DocumentCodeType} from 'common';
@Component({
  selector: 'app-documents-form',
  templateUrl: './documents-form.component.html',
  styleUrls: ['./documents-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentsFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocumentsFormComponent),
      multi: true
    }
  ]
})
export class DocumentsFormComponent implements OnChanges, ControlValueAccessor, OnDestroy {
  @Input() documentTypes: DocumentCodeType[];
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() withTitles = true;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  constructor(private formBuilder: UntypedFormBuilder, private cdref: ChangeDetectorRef) {
    this.form = this.formBuilder.group({});
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe((value: {[key: string]: any}) => {
          Object.entries(value).map( ([key, val]) => {
            if(isArray(val)){
              // ajout d'un control pour autre doc si besoin (lorsqu'on upload un doc other)
              if(!val?.some((v: any) => (v?.doc === null)) && val.length){
                this.getMultipleDocsF(key).push(this.formBuilder.control({doc: null, name: ''}));
              }

              // // suppression d'un control pour autre doc si besoin (lorsqu'on remove un doc other)
              if(val?.find((v: any) => (v?.doc === undefined))){
                const indexToRemove = val?.map((v: any) => v.doc).indexOf(undefined);
                this.getMultipleDocsF(key).removeAt(indexToRemove);
              }

              let docs: any[] = [];
              // filtre des null et suppression du champs name dans les docs multiples, utile uniquement pour ce composant
              val.filter((doc: any) => doc.doc !== null).map( (doc: any) => {
                docs = [...docs,doc.doc];
              });
              value[key] = docs;

            } else {
              if(val){
                value[key] = val.doc;
              }
            }
          });
          this.onChange(value);
          this.onTouched();
        })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  getMultipleDocsF(fieldName: string){
    return this.form.get(fieldName) as UntypedFormArray;
  }

  getSimpleDocsF(docType: any){
    return docType.owner?.id ? this.form.get(docType.field+'#'+docType.owner.id) as UntypedFormControl : this.form.get(docType.field) as UntypedFormControl;
  }


  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: {[key: string]: any}) {
    if(value){
      //patch sinon les docs multiple vide (qui ont un formcontrol initialisé) n'ont pas de valeur a ecrire
      this.form.patchValue(value);

      Object.entries(value).map(([key, val]) => {
        if(Array.isArray(val)){
          //patch des doc multiples
          if(val.length){
            val.map((doc: any) => {
              this.getMultipleDocsF(key).push(this.formBuilder.control({doc, name: doc.name}));
            });
          }
        }
      });
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.errors ? this.form.errors : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.documentTypes &&
      changes['documentTypes'] &&
      (changes['documentTypes'].firstChange ||
      (JSON.stringify(changes['documentTypes'].currentValue) !== JSON.stringify(changes['documentTypes'].previousValue)))) {
      this.documentTypes.map((docType: DocumentCodeType) => {
        let owner = null;
        if (docType.owner?.id){
          owner = docType.owner;
        }
        if (docType.multiple) {
          if(this.form.value.otherDocs === undefined || !this.form.value.otherDocs?.some((v: any) => (v?.doc === null)) && this.form.value.otherDocs?.length){
            this.form.addControl(docType.field, this.formBuilder.array([]));
            this.getMultipleDocsF(docType.field).push(this.formBuilder.control({doc: null, name: ''}));
          }
        } else {
          if(owner){
            this.form.addControl(docType.field + '#'+owner?.id, this.formBuilder.control(null));
          } else {
            this.form.addControl(docType.field, this.formBuilder.control(null));
          }
        }
      });
    }
  }
}
