<h2 class="mt-4">Configurer les tâches par défaut de l'étape : {{ stepMaster?.name }}</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div [formGroup]="form">
    <ng-container formArrayName="groupMasters">
      <div cdkDropListGroup>
        <ng-container *ngFor="let group of groupMastersF.controls; let groupIndex = index">

          <div [formGroupName]="groupIndex">
            <ng-container *ngIf="groupIndex !== 0 && getTaskMastersFByGroupIndex(groupIndex).controls.length > 0">
              <div class="field delay-input">
                <label for="delay">Délai</label>
                <p-inputNumber formControlName="delayBefore" [showButtons]="true" buttonLayout="horizontal" id="delay" spinnerMode="horizontal" [min]="0" [step]="1"
                               decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" suffix=" jours">
                </p-inputNumber>
              </div>
            </ng-container>
            <ng-container formArrayName="taskMasters">
              <div cdkDropList class="group-list" (cdkDropListDropped)="dropItemByGroupIndex($event, groupIndex)"  [cdkDropListData]="group.get('taskMasters')">

                <div class="flex">
                  <div class="group-rank">{{group.value.position}}</div>
                  <div class="flex flex-column w-full">
                    <div class="task-box" *ngFor="let task of getTaskMastersFByGroupIndex(groupIndex).controls; let taskIndex = index" cdkDrag (cdkDragEnded)="onDragEnded(task.value.id)">

                      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                      <div [formGroupName]="taskIndex" class="flex">
                        <div class="drag-handle" cdkDragHandle><i class="pi pi-arrows-v"></i></div>
                        <div class="grid w-full">
                          <div class="field col-4">
                            <label for="task-name">Tâche</label>
                            <input formControlName="name" id="task-name" type="text" pInputText class="inputfield w-full">
                            <lib-field-error *ngIf="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('name')?.touched &&
                                                    getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('name')?.errors"
                                             [fieldError]="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('name')?.errors">

                            </lib-field-error>
                          </div>
                          <div class="field col-3 flex flex-column ">
                            <label for="task-pole">Pôle assigné</label>
                            <p-dropdown formControlName="pole" id="task-pole" styleClass="inputfield w-full"
                                        [options]="polesOptions"
                                        appendTo="body"
                                        [autoDisplayFirst]="false" placeholder="Pôle">
                            </p-dropdown>
                            <lib-field-error *ngIf="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.touched &&
                                                    getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.errors"
                                             [fieldError]="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.errors">

                            </lib-field-error>
                          </div>
                          <div class="field col-2 flex flex-column">
                            <label>Importance</label>
                            <app-importance-input radioName="{{groupIndex}}-{{taskIndex}}" [formControl]="$any(getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('importance'))"></app-importance-input>
                            <lib-field-error *ngIf="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.touched &&
                                                    getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.errors"
                                             [fieldError]="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.errors">
                            </lib-field-error>
                          </div>
                          <div class="field col-3 p-fluid">
                              <label for="task-plannedWorkload">Charge prévue</label>
                              <p-inputNumber formControlName="plannedWorkload" [showButtons]="true" buttonLayout="horizontal" id="task-plannedWorkload" spinnerMode="horizontal" [min]="0" [step]="15"
                                             decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" suffix=" minutes">
                              </p-inputNumber>
                              <lib-field-error *ngIf="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.touched &&
                                                    getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.errors"
                                               [fieldError]="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.errors">

                              </lib-field-error>
                          </div>
                          <div class="field m-0 col-12">
                            <label for="help-text">Texte d'aide</label>
                            <input formControlName="helpText" id="help-text" type="text" pInputText class="inputfield w-full">
                            <lib-field-error *ngIf="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('helpText')?.touched &&
                                                    getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('helpText')?.errors"
                                             [fieldError]="getTaskMastersFByGroupIndex(groupIndex).controls[taskIndex].get('helpText')?.errors">

                            </lib-field-error>
                          </div>
                        </div>
                        <div class="task-btn">
                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger p-button-raised p-button-text" (click)="removeTask(groupIndex, taskIndex)"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <p-button label="Ajouter une tâche" icon="pi pi-plus" styleClass="mt-3 p-button-success p-button-raised p-button-text" (click)="addTask()"></p-button>
  <div class="col-12 flex justify-content-end mt-5">
    <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="cancel()"></p-button>
    <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="submit()"></p-button>
  </div>
</ng-template>
