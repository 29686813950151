import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { ConfigurationStore, ConfigurationState } from './configuration.store';

@Injectable({ providedIn: 'root' })
export class ConfigurationService extends NgEntityService<ConfigurationState> {

  constructor(protected override store: ConfigurationStore) {
    super(store);
  }

}
