<ng-container *ngIf="isCreation; else editionTitle">
  <h2 class="col-12">Créer un utilisateur</h2>
</ng-container>
<ng-template #editionTitle>
  <h2 class="col-12">Modifier l'utilisateur {{ user?.lastName }} {{ user?.firstName }}</h2>
</ng-template>

<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only></app-desktop-only>
  </ng-container>
  <ng-template #desktop class="container">
    <form [formGroup]="form" class="grid justify-content-center">
      <div class="field col-6 mt-3">
        <label for="firstName-input">Prénom</label>
        <input formControlName="firstName" id="firstName-input" type="text" size="30" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="firstNameF?.touched && firstNameF?.errors" [fieldError]="firstNameF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <label for="lastName-input">Nom</label>
        <input formControlName="lastName" id="lastName-input" type="text" [value]="lastNameF?.value" size="30" pInputText class="inputfield w-full" appUpperCaseInput>
        <lib-field-error *ngIf="lastNameF?.touched && lastNameF?.errors" [fieldError]="lastNameF?.errors"></lib-field-error>
      </div>
      <div class="field col-12 mt-3">
        <label for="email-input">Email</label>
        <input formControlName="email" id="email-input" type="text" size="30" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="emailF?.touched && emailF?.errors" [fieldError]="emailF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <label for="poles-input">Pôle(s)</label>
        <p-multiSelect styleClass="inputfield w-full"
                       id="poles-input"
                       [options]="poles"
                       formControlName="poles"
                       [filter]="false"
                       emptyMessage="aucun pôle disponible"
                       [showHeader]="false"
                       optionLabel="label"
                       optionValue="value"
                       display="chip"
        ></p-multiSelect>
        <lib-field-error *ngIf="polesF?.touched && polesF?.errors" [fieldError]="polesF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <label for="roles-input">Rôle(s)</label>
        <p-multiSelect styleClass="inputfield w-full"
                       appendTo="body"
                       id="roles-input"
                       [options]="roles"
                       formControlName="roles"
                       [filter]="false"
                       emptyMessage="aucun rôle disponible"
                       [showHeader]="false"
                       optionLabel="label"
                       optionValue="value"
                       display="chip"
        >
          <ng-template pTemplate="selectedItems" let-values>
            <ng-container *ngFor="let value of values">
              <!-- Classe "p-multiselect-token-icon" empêche ouverture ou fermeture du menu déroulant à la suppression d'une valeur-->
              <p-chip [label]="this.translatorService.getTranslation(value) | async"
                      [removable]="!readonlyRoles.includes(value)"
                      (onRemove)="onRoleRemove(value)"
                      styleClass="mr-2 custom-chip"
                      removeIcon="pi pi-times-circle p-multiselect-token-icon"
              >
              </p-chip>
            </ng-container>
          </ng-template>
        </p-multiSelect>
        <lib-field-error *ngIf="rolesF?.touched && rolesF?.errors" [fieldError]="rolesF?.errors"></lib-field-error>
      </div>
      <div class="field col-12 mt-3 flex align-items-center">
        <p-inputSwitch  id="is-active-input"
                        formControlName="isActive"
                        [trueValue]="true"
                        [falseValue]="false"
        ></p-inputSwitch>
        <label for="is-active-input" class="ml-2 mb-0" [ngClass]="{'text-400' : !isActiveF?.value}">{{ isActiveF?.value ? "Compte actif" : "Compte inactif" }}</label>
        <lib-field-error *ngIf="isActiveF?.touched && isActiveF?.errors" [fieldError]="isActiveF?.errors"></lib-field-error>
      </div>
      <div class="col-12 flex justify-content-end mt-5">
        <p-button pTooltip="Suppression impossible : l'utilisateur est lié à des clients ou a le rôle Dirigeant."
                  tooltipPosition="top"
                  [tooltipDisabled]="canUserBeDeleted"
                  label="Supprimer l'utilisateur"
                  icon="pi pi-times"
                  class="mr-auto"
                  styleClass="p-button-danger p-button-raised p-button-text"
                  [disabled]="!canUserBeDeleted"
                  (click)="canUserBeDeleted && setShowDeleteUserModal(true)">
        </p-button>

        <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="cancel()"></p-button>
        <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="submitForm()" [loading]="submissionLoading"></p-button>
      </div>
    </form>
  </ng-template>

<p-dialog
  class="border-round"
  [(visible)]="showDeleteUserModal"
  (onHide)="setShowDeleteUserModal(false)"
  [style]="{ width: '40vw' }"
  [modal]="true"
  header="Supprimer l'utilisateur {{ user?.lastName }} {{ user?.firstName }}">
  <p>Êtes-vous sûr de vouloir supprimer l'utilisateur {{ user?.lastName }} {{ user?.firstName }} ?</p>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-between">
      <p-button label="Non" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="setShowDeleteUserModal(false)"></p-button>
      <p-button label="Oui" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-text" (click)="deleteUser()"></p-button>
    </div>
  </ng-template>
</p-dialog>
