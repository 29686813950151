<h2 class="mt-4">Liste des produits</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;" >
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div>
    <p-table #tableauProduits [value]="products" [loading]="productsLoading" [rows]="10" [paginator]="true" [globalFilterFields]="['name', 'fournisseur.name']" responsiveLayout="scroll"
             [rowHover]="true" dataKey="id" currentPageReportTemplate="Page {currentPage} sur {totalPages}" [showCurrentPageReport]="true"
             [scrollHeight]="productsLoading ? '500px' : ''"  (onPage)="onPageChange($event)" [first]="pageOffset"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
      <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" #textInput (input)="tableauProduits.filterGlobal(textInput.value, 'contains')" placeholder="Recherche" />
      </span>
          <p-button label="Ajouter un produit" icon="pi pi-plus" styleClass="p-button-primary p-button-raised p-button-text mr-2" (click)="onAdd()" ></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" >
        <tr>
          <th class="px-5" pSortableColumn="name">Nom <p-sortIcon field="name"></p-sortIcon></th>
          <th class="px-5" pSortableColumn="fournisseur.name">Fournisseur<p-sortIcon field="fournisseur.name"></p-sortIcon></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr>
          <td class="px-5 w-5">{{ product.name }}</td>
          <td class="px-5 w-full">{{ product.fournisseur.name }}</td>
          <td class="flex flex-row">
            <p-button icon="pi pi-pencil" styleClass="p-button-warning p-button-text p-button-raised mr-2" (click)="onEdit(product.id)"></p-button>
            <p-button icon="pi pi-trash" styleClass="p-button-danger p-button-text p-button-raised mr-2" (click)="onRemove(product)"></p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        Au total il y a {{ products ? products.length : 0 }} produits.
      </ng-template>
    </p-table>
  </div>

  <!--  MODALE SUPPRESSION PRODUIT -->
  <p-dialog
    class="border-round"
    [(visible)]="showDeleteProductModal"
    (onHide)="showDeleteProductModal = false"
    [style]="{width: '40vw', height: '300px'}"
    [modal]="true"
    header="Suppression du produit {{ selectedProduct?.name }}"
  >
    <p class="flex align-items-center mt-6">Êtes-vous sûr de vouloir supprimer le produit {{ selectedProduct?.name }} ?</p>
    <ng-template pTemplate="footer">
      <div class="col-12 flex justify-content-between">
        <p-button label="Non" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="showDeleteProductModal = false"></p-button>
        <p-button label="Oui" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-text" (click)="removeProduct()"></p-button>
      </div>
    </ng-template>
  </p-dialog>

</ng-template>
