<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <ng-container *ngIf="willRedirectToRecueil; else mobile;">
    <app-desktop-only [clientOrAdmin]="'CLIENT'"></app-desktop-only>
  </ng-container>
  <ng-template #mobile>
    <div class="flex flex-column mt-8">
      <img src="assets/recueil/MASTER-LOGO-EMD.png" class="loader-logo-emd-mobile mx-auto" alt="logoEMD/" >
      <h1 class="rec mx-auto text-5xl">TIM</h1>
    </div>
    <form class="mt-3 grid grid-nogutter flex-column justify-content-center align-items-center w-full mb-8" [formGroup]="loginForm" (ngSubmit)="onAuth()">
      <div class="field col-6 mt-3">
        <label for="username-input">Identifiant :</label>
        <input formControlName="username" id="username-input" type="text" size="70" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="usernameF?.touched && usernameF?.errors" [fieldError]="usernameF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <label class="color-black" for="password-input">Mot de passe :</label>
        <input formControlName="password" id="password-input" type="password" size="40" pPassword class="inputfield w-full" [feedback]="false">
        <lib-field-error *ngIf="passwordF?.touched && passwordF?.errors" [fieldError]="passwordF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <button pButton [loading]="loading" type="submit" icon="pi pi-sign-in" label="Connexion" class="mt-3 p-button-raised rec w-full">
        </button>
      </div>
      <a class="mt-4" href="/password-forgotten">
        Mot de passe oublié
      </a>
    </form>
  </ng-template>
</ng-container>
<ng-template #desktop>
  <div class="grid rec mr-0">
    <div id="imgDiv" class="col-6 col-sm-12 p-7 pt-2 pb-4 right-panel flex flex-column align-items-center">
      <img src="assets/recueil/00-accueil-background.jpg" alt="backgroundBienvenue/" class="backgroundImg">
      <img src="assets/recueil/00-accueil-txt.svg" alt="backgroundBienvenue/" class="textImg">
    </div>
    <div class="col-6 col-sm-12 p-6 right-panel flex justify-content-center align-items-center">
      <form class="grid grid-nogutter flex-column justify-content-center align-items-center w-full" [formGroup]="loginForm" (ngSubmit)="onAuth()">
        <img src="assets/recueil/MASTER-LOGO-EMD.png" class="mb-7" alt="logoEMD/" height="153px"  >
        <div class="field col-6 mt-3">
          <label for="username-input">Identifiant :</label>
          <input formControlName="username" id="username-input" type="text" size="30" pInputText class="inputfield w-full">
          <lib-field-error *ngIf="usernameF?.touched && usernameF?.errors" [fieldError]="usernameF?.errors"></lib-field-error>
        </div>
        <div class="field col-6 mt-3">
          <label class="color-black" for="password-input">Mot de passe :</label>
          <input formControlName="password" id="password-input" type="password" size="40" pPassword class="inputfield w-full" [feedback]="false">
          <lib-field-error *ngIf="passwordF?.touched && passwordF?.errors" [fieldError]="passwordF?.errors"></lib-field-error>
        </div>
        <div class="field col-6 mt-3">
          <button pButton [loading]="loading" type="submit" icon="pi pi-sign-in" label="Connexion" class="mt-3 p-button-raised rec w-full">
          </button>
        </div>
        <a class="mt-4" href="/password-forgotten">
          Mot de passe oublié
        </a>
      </form>
    </div>
  </div>
</ng-template>
