import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FournisseurStore, FournisseurState } from './fournisseur.store';

@Injectable({ providedIn: 'root' })
export class FournisseurQuery extends QueryEntity<FournisseurState> {

  constructor(protected override store: FournisseurStore) {
    super(store);
  }

}
