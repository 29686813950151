import { Inject, Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { ProductState, ProductStore } from './product.store';

@Injectable({ providedIn: 'root' })
export class ProductService extends NgEntityService<ProductState>{

  constructor(
    @Inject('config') public override config: any,
    protected override store: ProductStore,
  ) {
    super(store);
  }

}
