import {ChangeDetectorRef, Component, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ControlValueAccessor,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {ClientType, DocumentCodeType, DocumentType, SessionQuery} from 'common';
import {ID} from '@datorama/akita';
import {FileUpload} from 'primeng/fileupload';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements ControlValueAccessor, OnDestroy, OnInit{
  @ViewChild('fileInput') fileInput: FileUpload;
  @Input() documentType: DocumentCodeType;
  @Input() clientType: string = ClientType.NATURAL_PERSON;
  @Input() clientId?: ID = 0;
  loading = false;
  error = false;
  errorMsg: string | null = null;
  success = false;
  file: any = null;
  showOtherDocDialog = false;
  docName = null;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  // docNameForm: FormGroup = this.formBuilder.group({
  //   name: this.formBuilder.control(null, Validators.required)
  // });
  docTypeEnums = DocumentType;
  filesToUpload: Partial<File>[]|null = null;
  constructor(private httpClient: HttpClient,
              private formBuilder: UntypedFormBuilder,
              private sessionQuery: SessionQuery,
              private cdref: ChangeDetectorRef,
              @Inject('config') private config: any,) {}

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      doc: this.formBuilder.control(null),
    });
    if (this.documentType.multiple){
      this.form.addControl('name', this.formBuilder.control('', Validators.required));
    }
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe(value => {
          if (value){
            this.onChange(value);
            this.onTouched();
          } else {
            this.onChange(value.doc);
            this.onTouched();
          }

        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    this.value.doc = value;
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.errors ? this.form.errors : null;
  }

  choose(){
    this.fileInput.choose();
  }

  remove(){
    this.fileInput.clear();
    if(this.documentType.multiple) {
      this.form.patchValue({name: null, doc: undefined});
    } else {
      this.form.patchValue({name: null, doc: null});
    }
  }

  openOtherDocDialog(filesToUpload: Partial<File>[]){
    this.showOtherDocDialog = true;
    this.filesToUpload = filesToUpload;
    this.form.patchValue({name: null});
  }

  cancelOtherDocDialog(){
    this.showOtherDocDialog = false;
    this.filesToUpload = null;
    this.fileInput.clear();
    this.form.patchValue({name: null});
  }

  upload(filesToUpload: Partial<File>[] | null) {
    this.showOtherDocDialog = false;
    const formData = new FormData();
    filesToUpload?.map((file: any,index: number)=>{
      formData.append('file-'+index, file);
    });

    let url = this.config.apiUrl + '/files/' + this.clientId + '/'  + this.clientType + '/' + this.documentType.code;

    if(this.documentType.multiple){
      url = this.config.apiUrl + '/files/' + this.clientId + '/'  + this.clientType + '/' + this.documentType.code + '?docName=' + this.form.get('name')?.value;
    }

    this.loading = true;
    this.httpClient.post<any>(url, formData).subscribe({
        next: (val) => {
          this.loading = false;
          this.error = false;
          this.success = true;
          this.filesToUpload = null;
          this.form.get('doc')?.patchValue(val);
          this.cdref.markForCheck();
        },
        error: (val) => {
          this.loading = false;
          this.error = true;
          this.errorMsg = val.error?.error?.length ? val.error?.error[0] : 'Une erreur est survenue';
          this.success = false;
          this.filesToUpload = null;
          this.cdref.markForCheck();
        }
      }
    );
  }

  onSelect(event: any){
    this.loading = false;
    this.error = false;
    this.success = false;
  }

}
