import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Address } from './address.model';

export interface AddressState extends EntityState<Address> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'addresses' })
export class AddressStore extends EntityStore<AddressState> {

  constructor() {
    super();
  }

}
