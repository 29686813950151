import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompteRenduQuery, CompteRendu, NavigationService, UiScreenQuery } from 'common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-page-meeting-report',
  templateUrl: './page-meeting-report.component.html',
  styleUrls: ['./page-meeting-report.component.scss'],
})
export class PageMeetingReportComponent implements OnInit {

  compteRendu?: CompteRendu;
  isPageLoading = true;

  constructor(
    private route: ActivatedRoute,
    private compteRenduQuery: CompteRenduQuery,
    private sanitizer: DomSanitizer,
    public navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = +params['id'];

      this.compteRenduQuery.selectEntity(id).subscribe(compteRendu => {
        if (compteRendu) {
          this.compteRendu = {
            ...compteRendu,
            content: this.sanitizer.bypassSecurityTrustHtml(compteRendu.content) as string
          };
          this.isPageLoading = false;
        }
      });
    });
  }

  convertMinutesToDuration(duration = 0) {
    const formattedDuration = duration / 60;

    const hours = Math.floor(formattedDuration);
    const minutes = Math.floor((formattedDuration - Math.floor(formattedDuration)) * 60);

    if (minutes === 0) {
      return hours + 'h';
    } else if (minutes.toString().length < 2) {
      return hours + 'h' + '0' + minutes;
    } else {
      return hours + 'h' + minutes;
    }
  }
}
