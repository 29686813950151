<h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Biens divers</h2>
<h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">Biens divers</h2>
<p-divider></p-divider>
<div [formGroup]="form" class="mt-5">
  <app-bien-divers-form formControlName="bienDivers"></app-bien-divers-form>
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <app-comment-read *ngIf="client.recueil !== undefined"
                      title="Informations complémentaires client"
                      [content]="client.recueil?.comBienDivers"
    ></app-comment-read>
      <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
      <p-divider class="mt-5"></p-divider>
      <app-comment-form formControlName="comConseilleBienDivers"></app-comment-form>
  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <app-documents-form
      formControlName="docs"
      [clientId]="clientId"
      [clientType]="clientType"
      [documentTypes]="[documentType.DIVERS_TITRE_PROPRIETE,
              documentType.DIVERS_JUSTIF_INVEST,
              documentType.AUTRE_DIVERS]"></app-documents-form>

    <h3 class="rec mt-6">{{'CLIENT_STEP_BIENSDIV_COMMENT' | translate}}</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comBienDivers"></app-comment-form>
  </ng-container>


</div>
