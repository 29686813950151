<!-- MOBILE HEADER -->
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async);else desktop">
  <div class="header-mobile grid justify-content-around align-items-center">
    <button *ngIf="displayMenuByRoute" pButton type="button" icon="pi pi-bars"
            class="p-button-raised p-button-rounded p-button-outlined bg-white" (click)="showMenu()"
            [hidden]="displayMenu"></button>
    <button *ngIf="displayMenuByRoute" pButton type="button" icon="pi pi-search"
            class="p-button-raised p-button-rounded p-button-outlined bg-white" (click)="showSearchbar()"
            [hidden]="displayMenu"></button>
    <div class="relative" (click)="MenuCommentBlocked.toggle($event)">
      <i class="pi pi-lock notification lock p-2 bg-white" style="font-size: 1.2rem"></i>
      <p *ngIf="notificationsCommentBlocked.length > 0" class="notification-indicator lock text-sm">
        {{notificationsCommentBlocked.length}}
      </p>
      <p-tieredMenu #MenuCommentBlocked [popup]="true" [model]="menuCommentBlocked" appendTo="body"
                    styleClass="text-sm"></p-tieredMenu>
    </div>
    <div class="relative" (click)="MenuCommentResolved.toggle($event)">
      <i class="pi pi-unlock notification unlock success p-2 bg-white" style="font-size: 1.2rem"></i>
      <p *ngIf="notificationsCommentResolved.length > 0" class="notification-indicator unlock text-sm">
        {{notificationsCommentResolved.length}}
      </p>
      <p-tieredMenu #MenuCommentResolved [popup]="true" [model]="menuCommentResolved" appendTo="body"
                    styleClass="text-sm"></p-tieredMenu>
    </div>
    <div class="relative" (click)="MenuCommentConsult.toggle($event)">
      <i class="pi pi-comment notification comment p-2 bg-white" style="font-size: 1.2rem"></i>
      <p *ngIf="notificationsCommentConsult.length > 0" class="notification-indicator comment text-sm">
        {{notificationsCommentConsult.length}}
      </p>
      <p-tieredMenu #MenuCommentConsult [popup]="true" [model]="menuCommentConsult" appendTo="body"
                    styleClass="text-sm"></p-tieredMenu>
    </div>
  </div>
  <div class="flex justify-content-center align-items-center mt-3" *ngIf="displaySearchbar">
    <p-autoComplete #searchBar
                    [(ngModel)]="text"
                    [suggestions]="searchBarResults"
                    placeholder="Rechercher un client"
                    (keydown.enter)="displaySearchResult()"
                    (completeMethod)="searchClient($event)"
                    field="label"
                    [minLength]="2"
                    (onSelect)="onSearchResultSelect($event)"
    ></p-autoComplete>
    <p-button class="p-button-raised ml-1" *ngIf="displayMenuByRoute" icon="pi pi-search"
              (onClick)="displaySearchResult()"></p-button>
    <p-button class="p-button-raised ml-1" *ngIf="displayMenuByRoute" icon="pi pi-times"
              (onClick)="hideSearchbar()"></p-button>
  </div>
</ng-container>
<!-- DESKTOP HEADER -->
<ng-template #desktop>
  <div class="header grid justify-content-between align-items-center">
    <div>
      <p-button class="p-button-raised" *ngIf="displayMenuByRoute" icon="pi pi-bars" (onClick)="showMenu()"
                [hidden]="displayMenu"></p-button>
    </div>
    <div class="flex justify-content-center align-items-center cursor-pointer" routerLink="/">
      <img src="assets/HEADER-LOGO-EMD.png" class="m-1">
      <img src="assets/header-splitter.png" class="mt-2">
      <h1 class="text-white m-0">
        TIM
      </h1>
    </div>
    <div class="flex justify-content-center align-items-center">
      <p-autoComplete #searchBar
                      [(ngModel)]="text"
                      [suggestions]="searchBarResults"
                      placeholder="Rechercher un client"
                      (keydown.enter)="displaySearchResult()"
                      (completeMethod)="searchClient($event)"
                      field="label"
                      [minLength]="2"
                      (onSelect)="onSearchResultSelect($event)"
      ></p-autoComplete>
      <p-button class="p-button-raised ml-1" *ngIf="displayMenuByRoute" icon="pi pi-search"
                (onClick)="displaySearchResult()"></p-button>
    </div>
    <div class="flex align-items-center">
      <div class="relative" (click)="MenuCommentBlocked.toggle($event)">
        <i class="pi pi-lock notification lock p-2 bg-white ml-3" style="font-size: 1.2rem"></i>
        <p *ngIf="notificationsCommentBlocked.length > 0" class="notification-indicator lock text-sm">
          {{notificationsCommentBlocked.length}}
        </p>
        <p-tieredMenu #MenuCommentBlocked [popup]="true" [model]="menuCommentBlocked" appendTo="body"
                      styleClass="text-sm"></p-tieredMenu>
      </div>
      <div class="relative" (click)="MenuCommentResolved.toggle($event)">
        <i class="pi pi-unlock notification unlock success p-2 bg-white ml-3" style="font-size: 1.2rem"></i>
        <p *ngIf="notificationsCommentResolved.length > 0" class="notification-indicator unlock text-sm">
          {{notificationsCommentResolved.length}}
        </p>
        <p-tieredMenu #MenuCommentResolved [popup]="true" [model]="menuCommentResolved" appendTo="body"
                      styleClass="text-sm"></p-tieredMenu>
      </div>
      <div class="relative" (click)="MenuCommentConsult.toggle($event)">
        <i class="pi pi-comment notification comment p-2 bg-white ml-3" style="font-size: 1.2rem"></i>
        <p *ngIf="notificationsCommentConsult.length > 0" class="notification-indicator comment text-sm">
          {{notificationsCommentConsult.length}}
        </p>
        <p-tieredMenu #MenuCommentConsult [popup]="true" [model]="menuCommentConsult" appendTo="body"
                      styleClass="text-sm"></p-tieredMenu>
      </div>
    </div>
    <div class="grid justify-content-end align-items-center">
      <p class="mr-2 text-white user-name">{{ userFirstname }} {{ userLastname | slice:0:1 }}.</p>
      <p-button *ngIf="displayMenuByRoute && !isClientAndCOllab()" icon="pi pi-sign-out" class="align-items-end p-button-raised"
                (click)="logOut()"></p-button>
      <p-button *ngIf="displayMenuByRoute && isClientAndCOllab()" icon="pi pi-chevron-down" class="align-items-end p-button-raised"
                (click)="menu.toggle($event)"></p-button>
      <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
    </div>
  </div>
</ng-template>


<app-menu [display]="displayMenu" (hideMenu)="hideMenu()"></app-menu>
