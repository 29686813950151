<form [formGroup]="form">
  <!--  BLOC SOCIÉTÉ  -->
  <p class="mt-6 mb-2 text-primary font-semibold">Société</p>
  <p-divider></p-divider>
  <div class="grid mt-5">
    <div class="field col-3">
      <!-- STRUCTURE JURIDIQUE -->
      <label for="structureJuridique-input">Structure juridique</label>
      <p-dropdown styleClass="w-full"
                  formControlName="structureJuridique"
                  id="structureJuridique-input"
                  [options]="legalStatusOptions"
                  [autoDisplayFirst]="false">
      </p-dropdown>
      <lib-field-error *ngIf="structureJuridiqueF?.touched && structureJuridiqueF?.errors" [fieldError]="structureJuridiqueF?.errors"></lib-field-error>
    </div>
    <!-- RAISON SOCIALE -->
    <div class="field col-3">
      <label for="name-input">Raison sociale</label>
      <input formControlName="name" id="name-input" type="text" [value]="nameF?.value" size="30" pInputText class="inputfield w-full" appUpperCaseInput>
      <lib-field-error *ngIf="nameF?.touched && nameF?.errors" [fieldError]="nameF?.errors"></lib-field-error>
    </div>
    <!-- SIRET -->
    <div class="field col-3">
      <label for="siretNumber-input">Numéro de siret</label>
      <input formControlName="siretNumber" id="siretNumber-input" type="text" size="30" pInputText class="inputfield w-full">
      <lib-field-error *ngIf="siretNumberF?.touched && siretNumberF?.errors" [fieldError]="siretNumberF?.errors"></lib-field-error>
    </div>
    <!-- DATE DE CRÉATION -->
    <div class="field col-3">
      <label for="creationDate-input">Date de création</label>
      <p-calendar appendTo="body"
                  styleClass="inputfield w-full"
                  formControlName="creationDate"
                  id="creationDate-input"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
      <lib-field-error *ngIf="creationDateF?.touched && creationDateF?.errors" [fieldError]="creationDateF?.errors"></lib-field-error>
    </div>
  </div>

  <!--  BLOC CONTACT PRINCIPAL  -->
  <div [hidden]="!isNewLegalPerson">
    <p class="mt-4 mb-2 text-primary font-semibold">Contact principal</p>
    <p-divider></p-divider>
    <div class="grid mt-5">
      <app-natural-legal-relation-form formControlName="relation" [clientType]="clientType.LEGAL_PERSON" [isNewLegalPerson]="isNewLegalPerson" [markAsTouched]="markAsTouched"
                                       class="col-6" >
      </app-natural-legal-relation-form>
    </div>
  </div>

  <!--  BLOC CABINET  -->
  <p class="mt-4 mb-2 text-primary font-semibold">Cabinet</p>
  <p-divider></p-divider>
  <div class="grid mt-5">
    <div class="field col-6">
      <label for="customerStatus-input">Statut</label>
      <p-multiSelect styleClass="inputfield w-full"
                     id="customerStatus-input"
                     [options]="statusOptions"
                     formControlName="customerStatus"
                     [filter]="false"
                     emptyMessage="aucun pôle disponible"
                     [showHeader]="false"
                     optionLabel="label"
                     optionValue="value"
                     display="chip"
      ></p-multiSelect>
      <lib-field-error *ngIf="customerStatusF?.touched && customerStatusF?.errors" [fieldError]="customerStatusF?.errors"></lib-field-error>
    </div>
  </div>

  <!--  BLOC COORDONNÉES  -->
  <p class="mt-4 mb-2 text-primary font-semibold">Coordonnées de la société</p>
  <p-divider></p-divider>
  <div class="grid mt-5">
    <app-address-form formControlName="address" class="col-12"></app-address-form>
  </div>
</form>

