import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Phone } from './phone.model';

export interface PhoneState extends EntityState<Phone> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'phones' })
export class PhoneStore extends EntityStore<PhoneState> {

  constructor() {
    super();
  }

}
