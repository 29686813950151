import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  ClientType,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
  NaturalPersonService,
  NavigationService,
  SituationMatrimoniale,
  TranslatorService,
  UserService,
  Handicap,
  Immobilier,
  AvoirFinancier,
  RecueilHelperService,
  SessionQuery,
  NaturalNaturalRelation,
  NaturalNaturalRelationTypeEnums,
  NaturalNaturalRelationService,
  NaturalNaturalRelationTypeQuery,
  NaturalNaturalRelationType,
  MessageService,
  RempliPar,
  AChargeFiscale,
  SessionService, DropdownOptionsService, DocumentHelperService, UiRecueilQuery, UiScreenQuery
} from 'common';
import {MenuItem} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {HttpClient} from '@angular/common/http';
import {LocationStrategy} from '@angular/common';

type ConfirmQuestionFiltreType =  'RELATION' | 'IMMO' | 'AVOIR_FI' | 'BIEN_DIVERS';

@UntilDestroy()
@Component({
  selector: 'app-page-recueil-client',
  templateUrl: './page-recueil-client.component.html',
  styleUrls: ['./page-recueil-client.component.scss']
})
export class PageRecueilClientComponent implements OnInit {
  activeIndex = -2;
  steps: MenuItem[];
  userId: number;
  clientType: string;
  client: NaturalPerson;
  conjointRelation: NaturalNaturalRelation | undefined;
  conjointData: NaturalPerson;
  conjointHasPhones = false;
  conjointHasAddresses = false;
  clientRelations: NaturalNaturalRelation[] = [];
  nextButtonDisabled = false;
  typesRelation: NaturalNaturalRelationType[] = [];
  emailContactFound: any;
  phoneContactFound: any;
  addressContactFound: any;
  addressFiscalFound: any;
  submitLoading = false;
  formIsReady = false;
  loadingRecueilValidation = false;
  error: string | null = null;
  showConfirmQuestionFiltreModal = false;
  confirmPromise: Promise<boolean>;
  confirmResolve: any;
  confirmReject: any;
  selectedConfirmQuestionFiltreType: ConfirmQuestionFiltreType | null = null;

  form: UntypedFormGroup = this.formBuilder.group({
    presentation: this.formBuilder.control(null),
    questionFiltres: this.formBuilder.control(null),
    informations: this.formBuilder.control({
      infoCivile: this.formBuilder.control({}),
      comInformation: this.formBuilder.control(null),
      situationPro: this.formBuilder.control({}),
      etranger: this.formBuilder.control({}),
      celebre: this.formBuilder.control({}),
      contact: this.formBuilder.control({}),
    }),
    infoCabinet: this.formBuilder.control({
      mainInterlocutor: this.formBuilder.control({}),
      secondaryInterlocutor: this.formBuilder.control({}),
      dateEntreeEnRelation: this.formBuilder.control({}),
      customerOrigin: this.formBuilder.control({}),
      customerStatus: this.formBuilder.control({}),
      segmentation: this.formBuilder.control({}),
    }),
    conjoint: this.formBuilder.control({}),
    famille: this.formBuilder.control({
      enfants: this.formBuilder.control([]),
      personnesCharge: this.formBuilder.control([]),
      comment: this.formBuilder.control({}),
      // docs: this.formBuilder.control({})
    }),
    objectif: this.formBuilder.control({}),
    immobilier: this.formBuilder.control({
      bienImmobiliers: this.formBuilder.control([]),
      comBienImmobilier: this.formBuilder.control(null),
    }),
    financier: this.formBuilder.control({
      avoirFinanciers: this.formBuilder.control({}),
      comAvoirFinancier: this.formBuilder.control(null),
    }),
    bienDivers: this.formBuilder.control({
      bienDivers: this.formBuilder.control({}),
      autreBienDivers: this.formBuilder.control({}),
      comBienDivers: this.formBuilder.control(null),
    }),
    autre: this.formBuilder.control({
      creditNonImmobiliers: this.formBuilder.control({}),
      comComplement: this.formBuilder.control(null),
      impot: this.formBuilder.control({
        trancheImpots: this.formBuilder.control({}),
        assujettiIfi: this.formBuilder.control({}),
        avisImpot: this.formBuilder.control({}),
        declarationImpot: this.formBuilder.control({}),
      }),
      donation: this.formBuilder.control({
        realiseDonation: this.formBuilder.control({}),
        recuDonation: this.formBuilder.control({}),
        redigeTestament: this.formBuilder.control({}),
        testament: this.formBuilder.control({}),
        acteDonation: this.formBuilder.control({}),
      }),
    }),
    recap: this.formBuilder.control({})
  });

  constructor(
    private navigation: NavigationService,
    private formBuilder: UntypedFormBuilder,
    public translatorService: TranslatorService,
    private activatedRoute: ActivatedRoute,
    public naturalPersonQuery: NaturalPersonQuery,
    public naturalPersonService: NaturalPersonService,
    private naturalNaturalRelationService: NaturalNaturalRelationService,
    public legalPersonQuery: LegalPersonQuery,
    private userService: UserService,
    private recueilHelperService: RecueilHelperService,
    private sessionQuery: SessionQuery,
    private naturalNaturalRelationTypeQuery: NaturalNaturalRelationTypeQuery,
    private sessionService: SessionService,
    private router: Router,
    private dropdownService: DropdownOptionsService,
    private cdref: ChangeDetectorRef,
    private httpClient: HttpClient,
    @Inject('config') private config: any,
    private documentHelperService: DocumentHelperService,
    private messageService: MessageService,
    private location: LocationStrategy,
    private uiRecueilQuery: UiRecueilQuery,
    public uiScreenQuery: UiScreenQuery,
  ) { }

  ngOnInit(): void {
    history.pushState(null, '', window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
    });
    this.activatedRoute.params.subscribe((params) => {
      this.clientType = params['clientType'];
      this.userId = params['userId'];

      this.userService.get(this.userId).pipe(untilDestroyed(this)).subscribe((user: any) => {
        if (this.clientType === ClientType.NATURAL_PERSON && user) {
          this.naturalPersonService.get(user.naturalPerson.id).pipe(untilDestroyed(this)).subscribe(
            () => {},
            (error) => {
              this.error = error.error;
              this.cdref.markForCheck();
            });
          this.naturalPersonQuery.selectEntity(user.naturalPerson.id).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
            if(naturalPerson && naturalPerson.hasOwnProperty('recueil')){
              this.client = naturalPerson;
              this.clientRelations = naturalPerson.naturalNaturalRelations ? naturalPerson.naturalNaturalRelations : [];
              this.conjointRelation = naturalPerson.naturalNaturalRelations ?
                naturalPerson.naturalNaturalRelations.find((relation: NaturalNaturalRelation) => relation.type.label === NaturalNaturalRelationTypeEnums.MARIE ||
                relation.type.label === NaturalNaturalRelationTypeEnums.PACSE) : undefined;
              if(this.conjointRelation?.secondNaturalPerson.id)
              {
                this.naturalPersonService.get(this.conjointRelation?.secondNaturalPerson.id).pipe(untilDestroyed(this)).subscribe((conjoint: any) => {
                  this.conjointData = conjoint;
                  this.verifyConjointPhonesAndAddresses(conjoint);
                  this.initNaturalPersonForm(naturalPerson);
                });
              } else {
                this.initNaturalPersonForm(naturalPerson);
              }
            }
          });
        }
        if (this.clientType === ClientType.LEGAL_PERSON) {

        }
      },
      (error) => {
        this.error = error.error;
        this.cdref.markForCheck();
      }
      );

    });

    this.recalculateSteps();
    this.form.valueChanges.subscribe(val => {
      this.formIsReady = true;
      this.naturalNaturalRelationTypeQuery.selectAll().subscribe(types => {
        this.typesRelation = types;
      });
      this.emailContactFound = this.form.value.informations.contact.emails && this.form.value.informations.contact.emails.find((email: any) => email.isContact === true);
      this.phoneContactFound = this.form.value.informations.contact.phones && this.form.value.informations.contact.phones.find((phone: any) => phone.isContact === true);
      this.addressContactFound = this.form.value.informations.contact.addresses && this.form.value.informations.contact.addresses.find((address: any) => address.isContact === true);
      this.addressFiscalFound = this.form.value.informations.contact.addresses && this.form.value.informations.contact.addresses.find((address: any) => address.fiscale === true);
      this.nextButtonDisabled = ((this.emailContactFound === undefined ||
        this.phoneContactFound === undefined ||
        this.addressContactFound === undefined ||
        this.addressFiscalFound === undefined) && (this.activeIndex === 0));
      this.recalculateSteps();
      this.cdref.markForCheck();
    });
  }

  toggleQuestionFiltre() {
    //netoyage quand question filtre passe à non
    // closure pour acceder au contact form et non au form enfant
    return {
      callParentMethod: (type: any) => {
        switch (type) {
          case 'RELATION':
            if(this.form.value.famille.personnesCharge?.length || this.form.value.famille.enfants.length){
              this.openQuestionFiltreModal('RELATION');
            }
            break;
          case 'IMMO':
            if(this.form.value.immobilier.bienImmobiliers?.length){
              this.openQuestionFiltreModal('IMMO');
            }
            break;
          case 'AVOIR_FI':
            if(this.form.value.financier.avoirFinanciers?.length){
              this.openQuestionFiltreModal('AVOIR_FI');
            }
            break;
          case 'BIEN_DIVERS':
            if(this.form.value.bienDivers.bienDivers?.length || this.form.value.bienDivers.autreBienDivers){
              this.openQuestionFiltreModal('BIEN_DIVERS');
            }
            break;
        }
      }
    };
  }

  initNaturalPersonForm(naturalPerson: NaturalPerson) {
    const enfants = naturalPerson?.naturalNaturalRelations?.filter((relation) => relation.type.label === NaturalNaturalRelationTypeEnums.ENFANT);
    const clientRelationsACharge = naturalPerson?.naturalNaturalRelations?.filter((relation: NaturalNaturalRelation) =>
      relation.aChargeFiscale === AChargeFiscale.CHARGE_TOTALE && (
      relation.type.label === NaturalNaturalRelationTypeEnums.PARENT ||
      relation.type.label === NaturalNaturalRelationTypeEnums.FRATRIE ||
      relation.type.label === NaturalNaturalRelationTypeEnums.GRAND_PARENT ||
      relation.type.label === NaturalNaturalRelationTypeEnums.AUTRE_FAMILLE ||
      relation.type.label === NaturalNaturalRelationTypeEnums.ONCLE_TANTE ||
      relation.type.label === NaturalNaturalRelationTypeEnums.TIERS));
    this.form.reset({
      presentation: {
        clientType : ClientType.NATURAL_PERSON,
        causeRempliTier: naturalPerson?.recueil ? naturalPerson?.recueil.causeRempliParUnTiers : null,
        rempliPar : naturalPerson?.recueil ? naturalPerson?.recueil.rempliPar : RempliPar.YOU,
        tiersCivility: naturalPerson?.recueil ? naturalPerson?.recueil.tiersCivility : null,
        tiersFirstName: naturalPerson?.recueil ? naturalPerson?.recueil.tiersFirstName : null,
        tiersLastName: naturalPerson?.recueil ? naturalPerson?.recueil.tiersLastName : null,
        tiersStructure: naturalPerson?.recueil ? naturalPerson?.recueil.rempliParNomStructure : null,
      },
      questionFiltres: {
        situationMatrimoniale: naturalPerson?.situationMatrimoniale ? naturalPerson?.situationMatrimoniale : SituationMatrimoniale.CELIBATAIRE,
        precedentMariage: naturalPerson?.precedentMariage,
        personneACharge: naturalPerson?.recueil ? naturalPerson?.recueil?.personneACharge : false,
        bienImmobilierOwner: naturalPerson?.recueil ? naturalPerson?.recueil?.bienImmobilierOwner : false,
        avoirFinancierOwner: naturalPerson?.recueil ? naturalPerson?.recueil?.avoirFinancierOwner : false,
        bienDiversOwner: naturalPerson?.recueil ? naturalPerson?.recueil?.bienDiversOwner : false,
        objectif: {
          firstObjective: naturalPerson?.recueil ? naturalPerson?.recueil?.firstObjective : null,
          secondObjective: naturalPerson?.recueil ? naturalPerson?.recueil?.secondObjective : null,
          thirdObjective: naturalPerson?.recueil ? naturalPerson?.recueil?.thirdObjective : null,
        }
      },
      informations: {
        infoCivile: {
          civility: naturalPerson?.civility,
          lastName: naturalPerson?.lastName,
          firstName: naturalPerson?.firstName,
          birthName: naturalPerson?.birthName,
          birthDate: naturalPerson?.birthDate ? new Date(naturalPerson?.birthDate) : null,
          birthCity: naturalPerson?.birthCity,
          birthState: naturalPerson?.birthState,
          birthCountry: naturalPerson?.birthCountry,
          nationality: naturalPerson?.nationality,
          otherNationality: naturalPerson?.otherNationality,
          mesureProtection: naturalPerson?.mesureProtection,
          handicap: naturalPerson?.handicap,
          dead: naturalPerson?.dead,
          deathDate: naturalPerson?.deathDate ? new Date(naturalPerson?.deathDate) : null,
        },
        situationPro: {
          situationPro: naturalPerson?.situationPro,
          currentJob: naturalPerson?.currentJob,
          jobCompany: naturalPerson?.jobCompany,
          revenusAnnuel: naturalPerson?.revenusAnnuel,
          rentesAnnuelle: naturalPerson?.rentesAnnuelle,
        },
        comInfoCivile: naturalPerson?.recueil ? naturalPerson?.recueil?.comInfoCivile : null,
        etranger: {
          usPerson: naturalPerson?.usPerson,
          vecuEtrange: naturalPerson?.vecuEtrange,
          vecuEtrangeDixAns: naturalPerson?.vecuEtrangeDixAns,
        },
        celebre: {
          politiquementExpose: naturalPerson?.politiquementExpose,
          prochePolitiquementExpose: naturalPerson?.prochePolitiquementExpose,
        },
        contact: {
          phones: naturalPerson?.phones,
          emails: naturalPerson?.emails,
          addresses: naturalPerson?.addresses,
          rentPrincipalResidence: naturalPerson?.rentPrincipalResidence,
          loyerAnnuel: naturalPerson?.loyerAnnuel,
        },
        pi: naturalPerson?.pi,
        justifDomicile: naturalPerson?.justifDomicile,
        jugementDivorce: naturalPerson?.jugementDivorce,
        otherDocs: naturalPerson?.otherDocs ? naturalPerson?.otherDocs : [],
      },
      conjoint: {
        relationID: this.conjointRelation?.id ? this.conjointRelation?.id : null,
        id: this.conjointData?.id ? this.conjointData?.id : null,
        situationMatrimoniale: {
          dateMariage: naturalPerson?.dateMariage ? new Date(naturalPerson?.dateMariage) : null,
          lieuMariage: naturalPerson?.lieuMariage,
          regimeMatrimonial: naturalPerson?.regimeMatrimonial,
          regimePacsimonial: naturalPerson?.regimePacsimonial,
          donationDernierVivant: naturalPerson?.donationDernierVivant,
          sensDonation: naturalPerson?.sensDonation,
          testamentCroise: naturalPerson?.testamentCroise,
          sensTestamentCroise: naturalPerson?.sensTestamentCroise,
          situationMatrimoniale: naturalPerson?.situationMatrimoniale,
          precedentMariage: naturalPerson?.precedentMariage,
        },
        infoCivile: {
          civility: this.conjointData?.civility ? this.conjointData?.civility : '',
          lastName: this.conjointData?.lastName ? this.conjointData?.lastName : '',
          firstName: this.conjointData?.firstName ? this.conjointData?.firstName : '',
          birthName: this.conjointData?.birthName ? this.conjointData?.birthName : null,
          birthDate: this.conjointData?.birthDate ? new Date(this.conjointData?.birthDate) : null,
          birthCity: this.conjointData?.birthCity ? this.conjointData?.birthCity : null,
          birthState: this.conjointData?.birthState ? this.conjointData?.birthState : null,
          birthCountry: this.conjointData?.birthCountry ? this.conjointData?.birthCountry : null,
          nationality: this.conjointData?.nationality ? this.conjointData?.nationality : null,
          otherNationality: this.conjointData?.otherNationality ? this.conjointData?.otherNationality : null,
          mesureProtection: this.conjointData?.mesureProtection ? this.conjointData?.mesureProtection : null,
          handicap: this.conjointData?.handicap ? this.conjointData?.handicap : false,
          dead: this.conjointData?.dead ? this.conjointData?.dead : false,
          deathDate: this.conjointData?.deathDate ? new Date(this.conjointData?.deathDate) : null,
        },
        situationPro: {
          situationPro: this.conjointData?.situationPro ? this.conjointData?.situationPro : null,
          currentJob: this.conjointData?.currentJob ? this.conjointData?.currentJob : null,
          jobCompany: this.conjointData?.jobCompany ? this.conjointData?.jobCompany : null,
          revenusAnnuel: this.conjointData?.revenusAnnuel ? this.conjointData?.revenusAnnuel : null,
          rentesAnnuelle: this.conjointData?.rentesAnnuelle ? this.conjointData?.rentesAnnuelle : null,
        },
        celebre: {
          politiquementExpose: this.conjointData?.politiquementExpose ? this.conjointData?.politiquementExpose : false,
          prochePolitiquementExpose: this.conjointData?.prochePolitiquementExpose ? this.conjointData?.prochePolitiquementExpose : false,
        },
        etranger: {
          usPerson: this.conjointData?.usPerson ? this.conjointData?.usPerson : false,
          vecuEtrange: this.conjointData?.vecuEtrange ? this.conjointData?.vecuEtrange : false,
          vecuEtrangeDixAns: this.conjointData?.vecuEtrangeDixAns ? this.conjointData?.vecuEtrangeDixAns : false,
        },
        contact: {
          phones: this.conjointData?.phones ? this.conjointData?.phones : [],
          emails: this.conjointData?.emails ? this.conjointData?.emails : [],
          addresses: this.conjointData?.addresses ? this.conjointData?.addresses : [],
          rentPrincipalResidence: this.conjointData?.rentPrincipalResidence ? this.conjointData?.rentPrincipalResidence : false,
          loyerAnnuel: this.conjointData?.loyerAnnuel ? this.conjointData?.loyerAnnuel : null,
        },
        comConjoint: naturalPerson?.recueil ? naturalPerson?.recueil?.comConjoint : null,
        contratMariage: naturalPerson?.contratMariage,
        attestDonation: naturalPerson?.attestDonation,
        pi: this.conjointData?.pi ? this.conjointData?.pi : null,
        justifDomicile: this.conjointData?.justifDomicile ? this.conjointData?.justifDomicile : null,
      },
      famille: {
        enfants: enfants?.map(relation => (
          {
            relationID: relation.id,
            id: relation.secondNaturalPerson.id,
            infoCivile: {
              civility: relation.secondNaturalPerson.civility,
              lastName: relation.secondNaturalPerson.lastName,
              firstName: relation.secondNaturalPerson.firstName,
              birthName: null,
              birthDate: relation?.secondNaturalPerson?.birthDate ? new Date(relation?.secondNaturalPerson?.birthDate) : null,
              birthCity: null,
              birthState: null,
              birthCountry: null,
              nationality: null,
              otherNationality: null,
              mesureProtection: null,
              handicap: false,
              dead: false,
              deathDate: null,
            },
            enfantDe: relation.enfantDe,
            adopted: relation.adopted,
            situationHandicap: relation.secondNaturalPerson.handicap === true ? Handicap.YES : Handicap.NO,
            lienPersonne: null,
            aChargeFiscale: relation.aChargeFiscale,
            pi: relation.secondNaturalPerson.pi ? relation.secondNaturalPerson.pi : null
          }
        )),
        personnesCharge: clientRelationsACharge?.map(relation => (
          {
            relationID: relation.id,
            id: relation.secondNaturalPerson.id,
            infoCivile: {
              civility: relation.secondNaturalPerson.civility,
              lastName: relation.secondNaturalPerson.lastName,
              firstName: relation.secondNaturalPerson.firstName,
              birthName: null,
              birthDate: relation?.secondNaturalPerson?.birthDate ? new Date(relation?.secondNaturalPerson?.birthDate) : null,
              birthCity: null,
              birthState: null,
              birthCountry: null,
              nationality: null,
              otherNationality: null,
              mesureProtection: null,
              handicap: false,
              dead: false,
              deathDate: null,
            },
            enfantDe: null,
            adopted: null,
            situationHandicap: relation.secondNaturalPerson.handicap === true ? Handicap.YES : Handicap.NO,
            aChargeFiscale: relation.aChargeFiscale,
            lienPersonne: relation.type.label,
            pi: relation.secondNaturalPerson.pi
          }
        )),
        comPersonneACharge: naturalPerson?.recueil ? naturalPerson?.recueil?.comPersonneACharge : null,
        livretFamille: naturalPerson?.livretFamille,
      },
      infoCabinet: {
        mainInterlocutor: naturalPerson?.mainInterlocutor,
        secondaryInterlocutor: naturalPerson?.secondaryInterlocutor,
        dateEntreeEnRelation: naturalPerson?.dateEntreeEnRelation ? new Date(naturalPerson?.dateEntreeEnRelation) : null,
        customerOrigin: naturalPerson?.customerOrigin,
        customerStatus: naturalPerson?.customerStatus,
        segmentation: naturalPerson?.segmentation,
      },
      immobilier:{
        bienImmobiliers: naturalPerson?.recueil?.bienImmobiliers?.map((x: Immobilier) => (
          {
            id: x.id,
            typeBien: x.typeBien,
            natureBien: x.natureBien,
            valeur: x.valeur,
            partsDetenues: x.partsDetenues,
            detenteur: x.detenteur,
            modeDetention: x.modeDetention,
            address: x.address ? {
              id: x.address.id,
              label: x.address.label,
              fiscale: x.address.fiscale,
              street: x.address.street,
              city: x.address.city,
              zipCode: x.address.zipCode,
              state: x.address.state,
              country: x.address.country,
              isContact: x.address.isContact,
              type: x.address.type,
            } : null,
            credit: x.credit,
            typeCredit: x.typeCredit,
            souscripteur: x.souscripteur,
            genereRevenus: x.genereRevenus,
            revenusAnnuels: x.revenusAnnuels,
            detenuSciOrScOrSarl: x.detenuSciOrScOrSarl,
            companyName: x.companyName,
            attestNotarial: x.attestNotarial,
            taxeFonciere: x.taxeFonciere,
            titrePropriete: x.titrePropriete,
            statutSci: x.statutSci,
            amortissement: x.amortissement,
            offrePret: x.offrePret,
            otherDocs: x.otherDocs ? x.otherDocs : null

          })),
        comBienImmobilier: naturalPerson?.recueil ? naturalPerson?.recueil?.comBienImmobilier : null,
      },
      financier: {
        avoirFinanciers: naturalPerson?.recueil?.avoirFinanciers?.map((x: AvoirFinancier) => (
          {
            id: x.id,
            typeAvoir: x.typeAvoir,
            assureurBanque: x.assureurBanque,
            dateSouscription: x.dateSouscription ? new Date(x.dateSouscription) : null,
            souscripteur: x.souscripteur,
            valeur: x.valeur,
            versementProgramme: x.versementProgramme,
            montantVersementProgramme: x.montantVersementProgramme,
            clauseBeneficiaire: x.clauseBeneficiaire,
            releveCompte: x.releveCompte,
            releveSituation: x.releveSituation,
            titrePropriete: x.titrePropriete,
            otherDocs: x.otherDocs ? x.otherDocs : null
          }
        )),
        comAvoirFinancier: naturalPerson?.recueil ? naturalPerson?.recueil?.comAvoirFinancier : null,
      },
      bienDivers: {
        bienDivers: naturalPerson?.recueil ? naturalPerson?.recueil?.bienDivers : [],
        autreBienDivers: naturalPerson?.recueil ? naturalPerson?.recueil?.autreBienDivers : null,
        comBienDivers: naturalPerson?.recueil ? naturalPerson?.recueil?.comBienDivers : null,
        justifInvestissementBienDivers: naturalPerson?.recueil ? naturalPerson?.recueil?.justifInvestissementBienDivers : null,
        titreProprieteBienDivers: naturalPerson?.recueil ? naturalPerson?.recueil?.titreProprieteBienDivers : null,
      },
      autre:{
        creditNonImmobiliers: naturalPerson?.recueil ? naturalPerson?.recueil?.creditNonImmobiliers : [],
        comComplement: naturalPerson?.recueil ? naturalPerson?.recueil?.comComplement : null,
        impot: {
          trancheImpots: naturalPerson?.recueil ? naturalPerson?.recueil?.trancheImpots : null,
          assujettiIfi: naturalPerson?.recueil ? naturalPerson?.recueil?.assujettiIfi : null,
          avisImpot: naturalPerson?.avisImpot,
          declarationImpot: naturalPerson?.declarationImpot,
        },
        donation: {
          realiseDonation: naturalPerson?.recueil ? naturalPerson?.recueil?.realiseDonation : false,
          recuDonation: naturalPerson?.recueil ? naturalPerson?.recueil?.recuDonation : false,
          redigeTestament: naturalPerson?.recueil ? naturalPerson?.recueil?.redigeTestament : false,
          testament: naturalPerson?.testament,
          acteDonation: naturalPerson?.acteDonation,
        },
        prevoyance: {
          contratPrevoyance: naturalPerson?.recueil ? naturalPerson?.recueil?.contratPrevoyance : false,
          descriptionContratPrevoyance: naturalPerson?.recueil ? naturalPerson?.recueil?.descriptionContratPrevoyance : null,
          certifPrevoyances: naturalPerson?.recueil ? naturalPerson?.recueil?.certifPrevoyances : null,
          conditionPrevoyances: naturalPerson?.recueil ? naturalPerson?.recueil?.conditionPrevoyances : null,
        },
      },
      recap: {}
    });
  }


  recalculateSteps() {
    this.steps = [
      {
        label: this.translatorService.instant('CLIENT_STEP_VOUS_STEPPER_TITLE'),
        id: 'CLIENT_STEP_VOUS_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 0;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_CONJOINT_STEPPER_TITLE'),
        id: 'CLIENT_STEP_CONJOINT_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 1;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_FAMILLE_STEPPER_TITLE'),
        id: 'CLIENT_STEP_FAMILLE_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 2;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_BIENSIMMO_STEPPER_TITLE'),
        id: 'CLIENT_STEP_BIENSIMMO_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 3;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_AVOIRSFI_STEPPER_TITLE'),
        id: 'CLIENT_STEP_AVOIRSFI_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 4;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_BIENSDIV_STEPPER_TITLE'),
        id: 'CLIENT_STEP_BIENSDIV_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 5;
          this.submitForm(false, false);
        }
      },
      {
        label: this.translatorService.instant('CLIENT_STEP_COMPLEMENT_STEPPER_TITLE'),
        id: 'CLIENT_STEP_COMPLEMENT_STEPPER_TITLE',
        command: (event: any) => {
          this.activeIndex = 6;
          this.submitForm(false, false);
        }
      },
    ];

    //adaptation du stepper selon le form
    if (this.form.value.questionFiltres === null ||
      this.form.value.questionFiltres?.situationMatrimoniale !== SituationMatrimoniale.MARIE &&
      this.form.value.questionFiltres?.situationMatrimoniale !== SituationMatrimoniale.PACSE) {
      this.steps = this.steps.filter(val => val.id !== 'CLIENT_STEP_CONJOINT_STEPPER_TITLE');
    }

    if (this.form.value.questionFiltres === null || this.form.value.questionFiltres.personneACharge === false) {
      this.steps = this.steps.filter(val => val.id !== 'CLIENT_STEP_FAMILLE_STEPPER_TITLE');
    }

    if (this.form.value.questionFiltres === null || this.form.value.questionFiltres?.bienImmobilierOwner === false) {
      this.steps = this.steps.filter(val => val.id !== 'CLIENT_STEP_BIENSIMMO_STEPPER_TITLE');
    }

    if (this.form.value.questionFiltres === null || this.form.value.questionFiltres?.bienDiversOwner === false) {
      this.steps = this.steps.filter(val => val.id !== 'CLIENT_STEP_BIENSDIV_STEPPER_TITLE');
    }

    if (this.form.value.questionFiltres === null || this.form.value.questionFiltres?.avoirFinancierOwner === false) {
      this.steps = this.steps.filter(val => val.id !== 'CLIENT_STEP_AVOIRSFI_STEPPER_TITLE');
    }

    //reindexation des command du menu
    this.steps.map((val: MenuItem, stepIndex) => {
      this.steps[stepIndex].command = () => {
        this.activeIndex = stepIndex;
        this.submitForm(false, false);
      };
    });
  }

  submitForm(validate: boolean, finishLater: boolean) {
    this.loadingRecueilValidation = validate;
    let typeACharge: NaturalNaturalRelationType;

    let relationToKeep: any[] = [];
    relationToKeep = this.client?.naturalNaturalRelations?.length ?  this.client?.naturalNaturalRelations?.filter((relation: NaturalNaturalRelation) =>
      relation.type.label !== NaturalNaturalRelationTypeEnums.ENFANT &&
      !(relation.aChargeFiscale === AChargeFiscale.CHARGE_TOTALE && (
        relation.type.label === NaturalNaturalRelationTypeEnums.PARENT ||
        relation.type.label === NaturalNaturalRelationTypeEnums.FRATRIE ||
        relation.type.label === NaturalNaturalRelationTypeEnums.GRAND_PARENT ||
        relation.type.label === NaturalNaturalRelationTypeEnums.AUTRE_FAMILLE ||
        relation.type.label === NaturalNaturalRelationTypeEnums.ONCLE_TANTE ||
        relation.type.label === NaturalNaturalRelationTypeEnums.TIERS))) : [];

    let relationsToAdd: NaturalNaturalRelation[] = [];
    // relations personnes à charge à enregistrer
    this.form.value.famille.personnesCharge.map((person: any) => {
      const personAChargeToAdd: Partial<NaturalPerson> = {
        id: person.id,
        civility: person.infoCivile.civility,
        lastName: person.infoCivile.lastName,
        firstName: person.infoCivile.firstName,
        birthDate: person.infoCivile.birthDate,
        pi: person.pi,
      };
      this.naturalNaturalRelationTypeQuery.selectInverse(person.lienPersonne).subscribe((val: any)=> {
        typeACharge = val;
      });
      if(person.lienPersonne !== null){
        const newRelationACharge: NaturalNaturalRelation = {
          id: person.relationID,
          firstNaturalPerson: {id: this.client.id},
          secondNaturalPerson: personAChargeToAdd,
          type: this.typesRelation.filter(type => type.label === person.lienPersonne)[0],
          enfantDe: person.enfantDe,
          aChargeFiscale: person.aChargeFiscale,
          adopted: person.adopted,
        };
        relationsToAdd = [...relationsToAdd, newRelationACharge];
      }

    });
    // relation enfants à enregistrer
    this.form.value.famille.enfants.map((enfant: any) => {
      const enfantToAdd: Partial<NaturalPerson> = {
        id: enfant.id,
        civility: enfant.infoCivile.civility,
        lastName: enfant.infoCivile.lastName,
        firstName: enfant.infoCivile.firstName,
        birthDate: enfant.infoCivile.birthDate,
        pi: enfant.pi,
      };
      const newRelationEnfant: NaturalNaturalRelation = {
        id: enfant.relationID,
        firstNaturalPerson: {id: this.client.id},
        secondNaturalPerson: enfantToAdd,
        type: this.typesRelation.filter((type) => type.label === NaturalNaturalRelationTypeEnums.ENFANT)[0],
        enfantDe: enfant.enfantDe,
        aChargeFiscale: enfant.aChargeFiscale,
        adopted: enfant.adopted,
      };
      relationsToAdd = [...relationsToAdd, newRelationEnfant];
    });

    if(this.form.value.conjoint.infoCivile?.civility !== '' && this.form.value.conjoint.infoCivile?.lastName!== '' &&
      this.form.value.conjoint.infoCivile?.firstName !== ''){


      let conjointValues: Partial<NaturalPerson> = {
        id: this.conjointRelation === undefined ? this.form.value.conjoint.id : this.conjointRelation?.secondNaturalPerson?.id,
      };
      if(!this.uiRecueilQuery.getValue().sentToConjoint){
        conjointValues = {
          id: conjointValues.id,
          civility: this.form.value.conjoint.infoCivile.civility,
          lastName: this.form.value.conjoint.infoCivile.lastName ? this.form.value.conjoint.infoCivile.lastName.toUpperCase() : null,
          firstName: this.form.value.conjoint.infoCivile.firstName,
          birthName: this.form.value.conjoint.infoCivile.birthName,
          birthDate: this.form.value.conjoint.infoCivile.birthDate,
          birthCity: this.form.value.conjoint.infoCivile.birthCity,
          birthState: this.form.value.conjoint.infoCivile.birthState,
          birthCountry: this.form.value.conjoint.infoCivile.birthCountry,
          nationality: this.form.value.conjoint.infoCivile.nationality,
          otherNationality: this.form.value.conjoint.infoCivile.otherNationality,
          mesureProtection: this.form.value.conjoint.infoCivile.mesureProtection,
          handicap: this.form.value.conjoint.infoCivile.handicap,
          dead: this.form.value.conjoint.infoCivile.dead,
          deathDate: this.form.value.conjoint.infoCivile.deathDate,
          situationPro: this.form.value.conjoint.situationPro.situationPro,
          currentJob: this.form.value.conjoint.situationPro.currentJob,
          jobCompany: this.form.value.conjoint.situationPro.jobCompany,
          revenusAnnuel: this.form.value.conjoint.situationPro.revenusAnnuel,
          rentesAnnuelle: this.form.value.conjoint.situationPro.rentesAnnuelle,
          usPerson: this.form.value.conjoint.etranger.usPerson,
          vecuEtrange: this.form.value.conjoint.etranger.vecuEtrange,
          vecuEtrangeDixAns: this.form.value.conjoint.etranger.vecuEtrangeDixAns,
          politiquementExpose: this.form.value.conjoint.celebre.politiquementExpose,
          prochePolitiquementExpose: this.form.value.conjoint.celebre.prochePolitiquementExpose,
          phones: this.form.value.conjoint.contact.phones,
          emails: this.form.value.conjoint.contact.emails,
          addresses: this.form.value.conjoint.contact.addresses,
          rentPrincipalResidence: this.form.value.conjoint.contact.rentPrincipalResidence,
          loyerAnnuel: this.form.value.conjoint.contact.loyerAnnuel,
          customerStatus: [],
          clientType: '',
          steps: [],
          pi: this.form.value.conjoint.pi,
          justifDomicile: this.form.value.conjoint.justifDomicile,
        };
      }
      const relationToAddConjoint: NaturalNaturalRelation = {
        id: this.conjointRelation === undefined ? this.form.value.conjoint.relationID : this.conjointRelation.id,
        firstNaturalPerson: {id: this.client.id},
        secondNaturalPerson: conjointValues,
        type: this.typesRelation.filter((type) => type.label === this.form.value.questionFiltres.situationMatrimoniale)[0],
        // @ts-ignore
        enfantDe: null,
        // @ts-ignore
        aChargeFiscale: null,
        // @ts-ignore
        adopted: null,
      };

      relationsToAdd = [...relationToKeep, ...relationsToAdd, relationToAddConjoint];
    }
    //docs multiples du form info
    let otherDocsInformations = [];
    otherDocsInformations = this.form.value.informations.otherDocs ? [...this.form.value.informations.otherDocs] : [];
    //docs multiples du form conjoint
    let otherDocsCoinjoint = [];
    otherDocsCoinjoint = this.form.value.conjoint.otherDocs ? [...this.form.value.conjoint.otherDocs] : [];
    //docs multiples du form conjoint
    let otherDocsFamille = [];
    otherDocsFamille = this.form.value.famille.otherDocs ? [...this.form.value.famille.otherDocs] : [];
    //docs multiples du form autre
    let otherDocsAutre = [];
    otherDocsAutre = this.form.value.autre.otherDocs ? [...this.form.value.autre.otherDocs] : [];
    //docs multiples du form bienDivers
    let otherDocsBienDivers = [];
    otherDocsBienDivers = this.form.value.bienDivers.otherDocs ? [...this.form.value.bienDivers.otherDocs] : [];

    this.submitLoading = true;
    let values: any = {
      recueil: {
        id: this.client?.recueil?.id,
        updateAuthor: this.sessionQuery.getValue(),
        updatedAt: new Date(),
        creditNonImmobiliers: this.form.value.autre.creditNonImmobiliers?.map((val: any) => ({...val})),
        comConseilleRevenuFiscaux: this.form.value.autre.comConseilleRevenuFiscaux,
        comConseilleDonation: this.form.value.autre.comConseilleDonation,
        comConseilleBienDivers: this.form.value.bienDivers.comConseilleBienDivers,
        bienDivers: this.form.value.bienDivers.bienDivers,
        autreBienDivers: this.form.value.bienDivers.autreBienDivers,
        ...this.form.value.autre.impot,
        ...this.form.value.autre.donation,
        ...this.form.value.autre.prevoyance,
        ...this.form.value.financier,
        ...this.form.value.immobilier,
        ...this.form.value.questionFiltres.objectif,
        justifInvestissementBienDivers: this.form.value.bienDivers.justifInvestissementBienDivers,
        titreProprieteBienDivers: this.form.value.bienDivers.titreProprieteBienDivers,
        certifPrevoyances: this.form.value.autre.certifPrevoyances,
        conditionPrevoyances: this.form.value.autre.conditionPrevoyances,
        bienImmobilierOwner: this.form.value.questionFiltres.bienImmobilierOwner,
        avoirFinancierOwner: this.form.value.questionFiltres.avoirFinancierOwner,
        bienDiversOwner: this.form.value.questionFiltres.bienDiversOwner,
        personneACharge: this.form.value.questionFiltres.personneACharge,
        comInfoCivile: this.form.value.informations.comInfoCivile,
        comPersonneACharge: this.form.value.famille.comPersonneACharge,
        comConjoint: this.form.value.conjoint.comConjoint,
        comBienDivers: this.form.value.bienDivers.comBienDivers,
        comComplement: this.form.value.autre.comComplement,
        causeRempliParUnTiers: this.form.value.presentation ? this.form.value.presentation.causeRempliTier : null,
        tiersCivility: this.form.value.presentation ? this.form.value.presentation.tiersCivility : null,
        tiersLastName: this.form.value.presentation ? this.form.value.presentation.tiersLastName : null,
        tiersFirstName: this.form.value.presentation ? this.form.value.presentation.tiersFirstName : null,
        rempliPar: this.form.value.presentation ? this.form.value.presentation.rempliPar : null,
        rempliParNomStructure: this.form.value.presentation ? this.form.value.presentation.tiersStructure : null,
      },
      // *** Situation Matri ***
      situationMatrimoniale: this.form.value.questionFiltres.situationMatrimoniale,
      precedentMariage: this.form.value.questionFiltres.precedentMariage,

      dateMariage: this.form.value.conjoint.situationMatrimoniale.dateMariage,
      lieuMariage: this.form.value.conjoint.situationMatrimoniale.lieuMariage,
      regimeMatrimonial: this.form.value.conjoint.situationMatrimoniale.regimeMatrimonial,
      regimePacsimonial: this.form.value.conjoint.situationMatrimoniale.regimePacsimonial,
      donationDernierVivant: this.form.value.conjoint.situationMatrimoniale.donationDernierVivant,
      sensDonation: this.form.value.conjoint.situationMatrimoniale.sensDonation,
      testamentCroise: this.form.value.conjoint.situationMatrimoniale.testamentCroise,
      sensTestamentCroise: this.form.value.conjoint.situationMatrimoniale.sensTestamentCroise,
      // *** Infos Natural Person ***
      addresses: this.form.value.informations.contact.addresses,
      birthCity: this.form.value.informations.infoCivile.birthCity,
      birthCountry: this.form.value.informations.infoCivile.birthCountry,
      birthDate: this.form.value.informations.infoCivile.birthDate,
      birthName: this.form.value.informations.infoCivile.birthName,
      birthState: this.form.value.informations.infoCivile.birthState,
      civility: this.form.value.informations.infoCivile.civility,
      currentJob: this.form.value.informations.situationPro.currentJob,
      dead: this.form.value.informations.infoCivile.dead,
      deathDate: this.form.value.informations.infoCivile.deathDate,
      emails: this.form.value.informations.contact.emails,
      firstName: this.form.value.informations.infoCivile.firstName,
      handicap: this.form.value.informations.infoCivile.handicap,
      jobCompany: this.form.value.informations.situationPro.jobCompany,
      lastName: this.form.value.informations.infoCivile.lastName,
      loyerAnnuel: this.form.value.informations.contact.loyerAnnuel,
      mesureProtection: this.form.value.informations.infoCivile.mesureProtection,
      nationality: this.form.value.informations.infoCivile.nationality,
      naturalNaturalRelations: JSON.parse(JSON.stringify(relationsToAdd)),
      otherNationality: this.form.value.informations.infoCivile.otherNationality,
      phones: this.form.value.informations.contact.phones,
      politiquementExpose: this.form.value.informations.celebre.politiquementExpose,
      prochePolitiquementExpose: this.form.value.informations.celebre.prochePolitiquementExpose,
      rentPrincipalResidence: this.form.value.informations.contact.rentPrincipalResidence,
      rentesAnnuelle: this.form.value.informations.situationPro.rentesAnnuelle,
      revenusAnnuel: this.form.value.informations.situationPro.revenusAnnuel,
      situationPro: this.form.value.informations.situationPro.situationPro,
      usPerson: this.form.value.informations.etranger.usPerson,
      vecuEtrange: this.form.value.informations.etranger.vecuEtrange,
      vecuEtrangeDixAns: this.form.value.informations.etranger.vecuEtrangeDixAns,
      // *** Docs Natural Person ***
      jugementDivorce: this.form.value.informations.jugementDivorce,
      justifDomicile: this.form.value.informations.justifDomicile,
      otherDocs: [
        ...otherDocsInformations,
        ...otherDocsCoinjoint,
        ...otherDocsFamille,
        ...otherDocsBienDivers,
        ...otherDocsAutre],
      pi: this.form.value.informations.pi,
      contratMariage: this.form.value.conjoint.contratMariage,
      attestDonation: this.form.value.conjoint.attestDonation,
    };
    //mapping des docs du recap dans leur objets respectifs
    Object.entries(this.form.value.recap).map(([formField, formVal]) => {
      if(formVal){
        const obj: any = formVal;
        Object.entries(obj).map(([k, v]) => {
          if(v){
            const fieldName = k.split('#')[0];
            const ownerId = k.split('#')[1];
            if(formField.startsWith('IMMO')) {
              values.recueil.bienImmobiliers.find((val: any) => val.id === Number(ownerId))[fieldName] = v;
            }
            if(formField.startsWith('AVOIR_FI')){
              values.recueil.avoirFinanciers.find((val: any) => val.id === Number(ownerId))[fieldName] = v;
            }
            if(formField.startsWith('PRET')){
              values.recueil.creditNonImmobiliers.find((val: any) => val.id === Number(ownerId))[fieldName] = v;
            }
            if(formField.startsWith('STEP_RECAP_DOC_INDISPENSABLE')){
              if(ownerId){
                values.naturalNaturalRelations.find((val: any) => val.secondNaturalPerson.id === Number(ownerId)).secondNaturalPerson[fieldName] = v;
              } else {
                values[fieldName] = v;
              }
            }
            if(formField.startsWith('STEP_VOUS')){
              values[fieldName] = v;
            }
            if(formField.startsWith('STEP_FAMILLE')){
              values[fieldName] = v;
            }
            if(formField.startsWith('STEP_RECAP_ENFANT')){
              values.naturalNaturalRelations.find((val: any) => val.secondNaturalPerson.id === Number(ownerId)).secondNaturalPerson[fieldName] = v;
            }
            if(formField.startsWith('STEP_RECAP_PERSONNE_A_CHARGE')){
              values.naturalNaturalRelations.find((val: any) => val.secondNaturalPerson.id === Number(ownerId)).secondNaturalPerson[fieldName] = v;
            }
            if(formField.startsWith('STEP_BIENSDIV_TITLE')){
              values.recueil[fieldName] = v;
            }
            if(formField.startsWith('STEP_RECAP_IMPOT')){
              values[fieldName] = v;
            }
            if(formField.startsWith('STEP_RECAP_DONATION_TESTAMENT')){
              values[fieldName] = v;
            }
          }
        });
      }
    });

    values = this.recueilHelperService.cleanRecueilClient(values);

    this.naturalPersonService.update(this.client?.id, values).subscribe({
      next: (result: any) => {
        relationsToAdd = [];
        this.submitLoading = false;
        this.conjointRelation = result.naturalNaturalRelations.filter((relation: NaturalNaturalRelation) => relation.type.label === NaturalNaturalRelationTypeEnums.MARIE ||
          relation.type.label === NaturalNaturalRelationTypeEnums.PACSE)[0];
        if(this.conjointRelation !== undefined){
          this.naturalPersonService.get(this.conjointRelation?.secondNaturalPerson.id).pipe(untilDestroyed(this)).subscribe((conjoint: any) => {
            this.conjointData = conjoint;
            this.verifyConjointPhonesAndAddresses(conjoint);
          });
        }
        else {
          this.conjointHasPhones = false;
          this.conjointHasAddresses = false;
        }
        //Generate PDF
        if (validate){
          this.generateRecueilPdf(result);
        }
        if (finishLater){
          this.sessionService.logout();
          this.router.navigate(['/login']);
          //on retarde le toast pour laisser le temps à la page login "d'activer les toast"
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Vos données ont bien été enregistrées.',
              life: 10000
            });
          }, 1000);

        }
      },
      error: () => {
        this.submitLoading = false;
      }
    });
  }

  generateRecueilPdf(naturalPerson: NaturalPerson) {
     this.httpClient.get<any>(this.config.apiUrl + '/document/generate/recueil/' + naturalPerson.id + '/' + true).pipe(untilDestroyed(this)).subscribe({
          next: (results) => {
            if (results.success) {
                this.router.navigate(['/recueil/recueil-final'], {replaceUrl:true});
            }
            this.loadingRecueilValidation = false;
          },
          error: () => {
            this.loadingRecueilValidation = false;
          }
        });
  }

  verifyConjointPhonesAndAddresses(conjoint: any){
    this.conjointHasPhones = !!(conjoint && conjoint?.phones?.length !== 0);
    this.conjointHasAddresses = !!(conjoint && conjoint?.addresses?.length !== 0);
  }

  nextPage() {
    this.submitForm(false, false);
    this.activeIndex++;
    this.navigation.scrollToTop();
    this.nextButtonDisabled = ((this.emailContactFound === undefined ||
      this.phoneContactFound === undefined ||
      this.addressContactFound === undefined ||
      this.addressFiscalFound === undefined) && (this.activeIndex === 0));
    if (this.form.value.informations.contact.emails) {
      this.form.value.informations.contact.emails = this.form.value.informations.contact.emails.filter((email: any) => email.email !== null || email.label !== null || email.type !== null);
    }
    if (this.form.value.informations.contact.phones) {
      this.form.value.informations.contact.phones = this.form.value.informations.contact.phones.filter((phone: any) => phone.number !== null || phone.label !== null || phone.type !== null);
    }
    if (this.form.value.informations.contact.addresses) {
      this.form.value.informations.contact.addresses = this.form.value.informations.contact.addresses.filter((address: any) => address.street !== null ||
        address.label !== null || address.type !== null || address.city !== null || address.state !== null || address.zipCode !== null || address.country !== null);
    }
  }

  prevPage() {
    this.submitForm(false, false);
    if ( this.activeIndex > -1 && this.activeIndex < this.steps.length && this.steps[this.activeIndex].id === 'CLIENT_STEP_VOUS_STEPPER_TITLE') {
      this.nextButtonDisabled = false;
    }
    this.activeIndex--;
    this.navigation.scrollToTop();
  }

  getHeaderImg(){
    let img = '';
    if(this.activeIndex <  0){
      img= 'Q-filtrantes.jpg';
    }
    else if(this.activeIndex >=  this.steps.length){
      img= 'E8-recap.jpg';
    }
    else if(this.activeIndex >=  0){
      switch (this.steps[this.activeIndex].id){
        case 'CLIENT_STEP_VOUS_STEPPER_TITLE':
          img = 'E1-infos-civiles.jpg';
          break;
        case 'CLIENT_STEP_CONJOINT_STEPPER_TITLE':
          img = 'E2-conjoint.jpg';
          break;
        case 'CLIENT_STEP_FAMILLE_STEPPER_TITLE':
          img = 'E3-enfant-a-charge.jpg';
          break;
        case 'CLIENT_STEP_BIENSIMMO_STEPPER_TITLE':
          img = 'E4-biens-immo.jpg';
          break;
        case 'CLIENT_STEP_AVOIRSFI_STEPPER_TITLE':
          img = 'E5-avoirs-financiers.jpg';
          break;
        case 'CLIENT_STEP_BIENSDIV_STEPPER_TITLE':
          img = 'E6-biens-divers.jpg';
          break;
        case 'CLIENT_STEP_COMPLEMENT_STEPPER_TITLE':
          img = 'E7-infos-compl.jpg';
          break;

      }
    }
    return img;
  }

  cancelQuestionFiltreModal(){
    this.confirmResolve(false);
  }

  confirmQuestionFiltreModal(){
    this.confirmResolve(true);
  }

  openQuestionFiltreModal(type: ConfirmQuestionFiltreType){
    this.showConfirmQuestionFiltreModal = true;
    this.selectedConfirmQuestionFiltreType = type;
    const self = this;
    this.confirmPromise = new Promise((resolve, reject) => {
      self.confirmResolve = resolve;
      self.confirmReject = reject;
    });
    this.confirmPromise.then((response: any) => {
      if(response){
        switch (type){
          case 'IMMO':
            self.form.value.immobilier.bienImmobiliers = [];
            self.form.value.immobilier.comBienImmobilier = null;
            break;
          case 'AVOIR_FI':
            self.form.value.financier.avoirFinanciers = [];
            self.form.value.financier.comAvoirFinancier = null;
            break;
          case 'BIEN_DIVERS':
            self.form.value.bienDivers.bienDivers = [];
            self.form.value.bienDivers.autreBienDivers = null;
            self.form.value.bienDivers.comBienDivers = null;
            break;
          case 'RELATION':
            self.form.value.famille.enfants = [];
            self.form.value.famille.personnesCharge = [];
            self.form.value.famille.comPersonneACharge = null;
            break;
        }

        self.showConfirmQuestionFiltreModal = false;
      } else {
        switch (type) {
          case 'IMMO':
            self.form.patchValue({
              ...self.form.value,
              questionFiltres: {...self.form.value.questionFiltres, bienImmobilierOwner: true}
            });
            break;
          case 'AVOIR_FI':
            self.form.patchValue({
              ...self.form.value,
              questionFiltres: {...self.form.value.questionFiltres, avoirFinancierOwner: true}
            });
            break;
          case 'BIEN_DIVERS':
            self.form.patchValue({
              ...self.form.value,
              questionFiltres: {...self.form.value.questionFiltres, bienDiversOwner: true}
            });
            break;
          case 'RELATION':
            self.form.patchValue({
              ...self.form.value,
              questionFiltres: {...self.form.value.questionFiltres, personneACharge: true}
            });
            break;
        }
        self.showConfirmQuestionFiltreModal = false;
      }
    });
  }


}
