<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="type-immo">Nature du bien</label>
      <p-dropdown
        formControlName="natureBien"
        id="type-immo"
        [options]="natureBienOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
    <div class="field col-3">
      <label for="value">Valeur globale du bien</label>
      <p-inputNumber formControlName="valeur" id="value"  mode="decimal"></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
    <div class="field col-3 ">
      <label for="detenteur">Qui détient le bien ?</label>
      <p-dropdown
        formControlName="detenteur"
        id="detenteur"
        [options]="detenteurOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
    <div class="field col-3">
      <label for="modeDetention">Mode de détention</label>
      <p-dropdown
        formControlName="modeDetention"
        id="modeDetention"
        [options]="modeDetentionOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
  </div>

<!--        BLOC ADDRESS-->
  <div class="mb-3"></div>
  <app-address-form formControlName="address"></app-address-form>
  <div class="mb-3"></div>

  <div class="grid p-fluid">
    <div class="field col-3 flex flex-column">
      <label for="credit">Bien à crédit ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.credit}">Non</div>
        <p-inputSwitch  id="credit"
                        formControlName="credit"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.credit}">Oui</div>
      </div>
    </div>
    <div class="field col-3" *ngIf="value.credit">
      <label for="typeCredit">Type</label>
      <p-dropdown
        formControlName="typeCredit"
        id="typeCredit"
        [options]="typeCreditOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
    <div class="field col-3" *ngIf="value.credit">
      <label for="souscripteur">Souscripteur</label>
      <p-dropdown
        formControlName="souscripteur"
        id="souscripteur"
        [options]="souscripteurOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
  </div>

  <div class="grid p-fluid">
    <div class="field col-3 flex flex-column">
      <label for="genereRevenus">Ce bien génère-t-il des revenus ?</label>
        <div class="flex flex-grow-1 align-items-center">
          <div [ngClass]="{'text-400' : value.genereRevenus}">Non</div>
          <p-inputSwitch  id="genereRevenus"
                          formControlName="genereRevenus"
                          [trueValue]="true"
                          [falseValue]="false"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.genereRevenus}">Oui</div>
        </div>
    </div>
    <div class="field col-3" *ngIf="value.genereRevenus">
      <label for="revenusAnnuels">Revenus annuels moyens</label>
      <p-inputNumber formControlName="revenusAnnuels" id="revenusAnnuels"  mode="decimal"></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-3 flex flex-column">
      <label for="detenuSciOrScOrSarl">Ce bien est-il détenu via une société (SCI, SC, SARL) ?</label>
        <div class="flex flex-grow-1 align-items-center">
          <div [ngClass]="{'text-400' : value.detenuSciOrScOrSarl}">Non</div>
          <p-inputSwitch  id="detenuSciOrScOrSarl"
                          formControlName="detenuSciOrScOrSarl"
                          [trueValue]="true"
                          [falseValue]="false"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.detenuSciOrScOrSarl}">Oui</div>
        </div>
    </div>
    <div class="field col-3" *ngIf="value.detenuSciOrScOrSarl">
      <label for="companyName">Nom de la société</label>
      <input formControlName="companyName" id="companyName" pInputText class="inputfield">
    </div>
    <div class="field col-3" *ngIf="value.detenuSciOrScOrSarl">
      <label for="partsDetenues">% des parts détenues</label>
      <p-inputNumber formControlName="partsDetenues" id="partsDetenues"  mode="decimal" [min]="0" [max]="100"></p-inputNumber>
    </div>
  </div>
  <app-documents-form
    *ngIf="clientOrAdmin === 'CLIENT'"
    formControlName="docs"
    [clientId]="clientId"
    [clientType]="clientType"
    [documentTypes]="documentTypes"></app-documents-form>
</div>
