import {Component, forwardRef, OnDestroy} from '@angular/core';
import { CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ObjectifQuery, ClientType} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

type ObjectifType = {label: string; type: 'other' | null; customLabel: string | null; position: number; clientType?: string};
@UntilDestroy()
@Component({
  selector: 'app-recueil-objectif',
  templateUrl: './recueil-objectif.component.html',
  styleUrls: ['./recueil-objectif.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RecueilObjectifComponent),
    multi: true
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => RecueilObjectifComponent),
    multi: true
  }
]
})
export class RecueilObjectifComponent implements ControlValueAccessor, OnDestroy {
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  objectifs: ObjectifType[];
  obj1: ObjectifType[];
  obj2: ObjectifType[];
  obj3: ObjectifType[];
  selectedOtherObjLabel: string;


  constructor(private formBuilder: UntypedFormBuilder,private objectifQuery: ObjectifQuery) {

    this.objectifQuery.selectAll().pipe(untilDestroyed(this)).subscribe(objectifs=>{
      this.objectifs = [];
      objectifs
        .map( objectif => {
          this.objectifs = [...this.objectifs,
            {label: objectif.label, type: null, customLabel: null, position: objectif.position, clientType: objectif.clientType}];
        });
      this.objectifs = this.objectifs.filter( objectif => objectif.clientType === ClientType.NATURAL_PERSON);
      this.objectifs = [...this.objectifs, {label: 'Autre: ', type: 'other', customLabel: '', position: 100}];
      this.sortObjectifList();
      if(this.form?.value !== undefined){
        this.cleanObjectifList();
      }
    });

    this.obj1 = [];
    this.obj2 = [];
    this.obj3 = [];

    this.form = this.formBuilder.group({
      firstObjective: this.formBuilder.control([]),
      secondObjective: this.formBuilder.control([]),
      thirdObjective: this.formBuilder.control([]),
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }
  get isObj1Available() {
    return this.obj1.length === 0;
  }

  get isObj2Available() {
    return this.obj2.length === 0;
  }

  get isObj3Available() {
    return this.obj3.length === 0;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(value){
      this.value = value;
      this.updateModel(value);
      this.cleanObjectifList();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  obj1Predicate = (): boolean => this.isObj1Available;

  obj2Predicate = (): boolean => this.isObj2Available;

  obj3Predicate = (): boolean => this.isObj3Available;

  drop(event: CdkDragDrop<any>) {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex,
    );
    // si objectif autre, on en remet un dans la liste
    if(event.container.data[0].type === 'other'){
      this.objectifs = [...this.objectifs,{label: 'Autre: ', type: 'other', customLabel: '', position: 100}];
    }
    this.updateForm();
  }

  updateForm(){
    this.form.patchValue({
      firstObjective: this.obj1[0] ? this.obj1[0].customLabel ? this.obj1[0]?.customLabel : this.obj1[0]?.label : null,
      secondObjective: this.obj2[0] ? this.obj2[0].customLabel ? this.obj2[0]?.customLabel : this.obj2[0]?.label : null,
      thirdObjective: this.obj3[0] ? this.obj3[0].customLabel ? this.obj3[0]?.customLabel : this.obj3[0]?.label : null,
    });
    this.sortObjectifList();
  }

  sortObjectifList(){
    this.objectifs = this.objectifs.sort((a,b) => a.position - b.position);
  }

  cleanObjectifList(){
    this.objectifs = this.objectifs.filter(objectif =>
        objectif.label !== this.value.firstObjective &&
        objectif.label !== this.value.secondObjective &&
        objectif.label !== this.value.thirdObjective
    );
  }

  updateModel(value: any){
    if (value.firstObjective){
      this.obj1[0] = {label: value.firstObjective, type: null, customLabel: null, position: value.position};
    } else {
      this.obj1 = [];
    }
    if (value.secondObjective){
      this.obj2[0] = {label: value.secondObjective, type: null, customLabel: null, position: value.position};
    } else {
      this.obj2 = [];
    }
    if (value.thirdObjective){
      this.obj3[0] = {label: value.thirdObjective, type: null, customLabel: null, position: value.position};
    } else {
      this.obj3 = [];
    }
  }

  removeObjectif(objectif: ObjectifType[], objectifNumber: number){
    switch (objectifNumber) {
      case 1:
        this.obj1 = [];
        break;
      case 2:
        this.obj2 = [];
        break;
      case 3:
        this.obj3 = [];
        break;
    }
    if(objectif[0].type !== 'other'){
      this.objectifs = [...this.objectifs, objectif[0]];
    }
    this.updateForm();
  }

}
