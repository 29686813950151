import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UiScreenQuery, NaturalPerson, NaturalPersonQuery, NaturalPersonService, TranslatorService, ClientStatus} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineQueries} from '@datorama/akita';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-page-new-natural-persons-list',
  templateUrl: './page-new-natural-persons-list.component.html',
  styleUrls: ['./page-new-natural-persons-list.component.scss']
})
export class PageNewNaturalPersonsListComponent implements OnInit {
  naturalPersons: NaturalPerson[] = [];
  loading = false;
  clientStatus = ClientStatus;

  constructor(public uiScreenQuery: UiScreenQuery,
              public naturalPersonQuery: NaturalPersonQuery,
              public naturalPersonService: NaturalPersonService,
              public translatorService: TranslatorService,
              private changeDetectionRef: ChangeDetectorRef,
              private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.getNaturalPersonList();
  }

  getNaturalPersonList() {
    this.loading = true;
    let getByIdRequests: any[] = [];
    this.naturalPersonQuery.selectAll().pipe(untilDestroyed(this)).subscribe(naturalPersonsResult => {
      naturalPersonsResult.filter(naturalPerson => naturalPerson.doublonTraite === false)
        .map(naturalPerson => {
          getByIdRequests = [...getByIdRequests, this.naturalPersonService.get(naturalPerson.id).pipe(untilDestroyed(this))];
        });
    });
    if(getByIdRequests.length === 0){
       this.loading = false;
    }
    combineQueries([...getByIdRequests]).pipe(untilDestroyed(this)).subscribe((result: any) => {
      this.naturalPersons = [...result];
      this.changeDetectionRef.markForCheck();
      this.loading = false;
    });
  }

  updateNaturalPersonDoublon(naturalPerson: NaturalPerson) {
    naturalPerson.doublonTraite = true;
    this.naturalPersonService.update(naturalPerson.id, naturalPerson).subscribe(() => {
      this.naturalPersons = this.naturalPersons.filter(val => val.id !== naturalPerson.id);
    });
  }

  translate(word: string | undefined): string | void {
    if (word) {
      return this.translatorService.instant(word);
    }
  }

  onRelationTagClick(personId: number) {
    this.router.navigate(['/natural-person/' + personId]);
  }

}
