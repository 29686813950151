import { ID } from '@datorama/akita';
import {User} from '../../user/state/user.model';
import {LegalPerson} from '../../client/legalPerson/state/legal-person.model';
import {NaturalPerson} from '../../client/naturalPerson/state/natural-person.model';

export interface CompteRendu {
  id: ID;
  meetingDate: Date;
  subject: string;
  meetingDuration: number;
  content: string;
  collaboratorPresent: User[];
  legalPersonsConcerned: LegalPerson[];
  naturalPersonsConcerned: NaturalPerson[];
}

export function createCompteRendu(params: Partial<CompteRendu>) {
  return {

  } as CompteRendu;
}
