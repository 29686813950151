import {ID} from '@datorama/akita';
import {User} from '../../../user/state/user.model';
import {NaturalPerson} from '../../naturalPerson/state/natural-person.model';
import {CreditNonImmobilier} from '../../creditNonImmobilier/state/credit-non-immobilier.model';
import {Immobilier} from '../../immobilier/state/immobilier.model';
import {AvoirFinancier} from '../../avoirFinancier/state/avoir-financier.model';
import {BienDiversType} from '../../bienDiversType/state/bien-divers-type.model';
import {Document} from '../../../document/state/document.model';

export interface Recueil {
  id?: ID;
  updatedAt?: Date;
  updateAuthor?: User;
  personneACharge?: boolean;
  bienImmobilierOwner?: boolean;
  avoirFinancierOwner?: boolean;
  bienDiversOwner?: boolean;
  firstObjective?: string;
  secondObjective?: string;
  thirdObjective?: string;
  tiersCivility?: string;
  tiersLastName?: string;
  tiersFirstName?: string;
  rempliPar?: string;
  rempliParNomStructure?: string;
  causeRempliParUnTiers?: string;
  comQuestionFiltrante?: string;
  comInfoCivile?: string;
  comConjoint?: string;
  comPersonneACharge?: string;
  comBienImmobilier?: string;
  comAvoirFinancier?: string;
  comBienDivers?: string;
  comComplement?: string;
  comConseillePersonneACharge?: string;
  comConseilleBienImmobilier?: string;
  comConseilleAvoirFinancier?: string;
  comConseilleBienDivers?: string;
  comConseilleRevenuFiscaux?: string;
  comConseilleDonation?: string;
  comConseilleInformation?: string;
  bienDivers?: BienDiversType[];
  autreBienDivers?: string;
  creditNonImmobiliers?: CreditNonImmobilier[];
  trancheImpots?: string;
  assujettiIfi?: string;
  descriptionContratPrevoyance?: string;
  realiseDonation?: boolean;
  recuDonation?: boolean;
  redigeTestament?: boolean;
  contratPrevoyance?: boolean;
  bienImmobiliers?: Immobilier[];
  avoirFinanciers?: AvoirFinancier[];
  pdfDate?: Date;
  pdfAuthor?: User;
  justifInvestissementBienDivers?: Document[];
  titreProprieteBienDivers?: Document[];
  conditionPrevoyances?: Document[];
  certifPrevoyances?: Document[];
  otherDocs?: Document[];
  recueilDoc?: Document;
}

export function createRecueil(params: Partial<Recueil>) {
  return {

  } as Recueil;
}
