import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  ClientSearchService,
  ClientType,
  DropdownOption,
  DropdownOptionsService, FormGuardInterface,
  LegalPersonQuery,
  NaturalPersonQuery, NavigationService,
  PoleClient,
  PoleInterne,
  Product,
  ProductQuery,
  SessionQuery,
  SessionState,
  Step,
  StepMaster,
  StepMasterQuery,
  StepService,
  TaskHelperService,
  TaskStatus,
  TranslatorService,
  UserQuery,
  UiScreenQuery
} from 'common';
import * as moment from 'moment';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {addWeekDays, weekDays} from 'moment-business';

@Component({
  selector: 'app-page-new-client-step',
  templateUrl: './page-new-client-step.component.html',
  styleUrls: ['./page-new-client-step.component.scss']
})

@UntilDestroy()
export class PageNewClientStepComponent implements OnInit, FormGuardInterface {

  user: SessionState;
  isFormSubmissionLoading = false;
  stepRelatedCustomer: {id: number; type: ClientType.NATURAL_PERSON | ClientType.LEGAL_PERSON};
  activeIndex = 0;
  checkAllTasks = false;
  stepMasters: StepMaster[] = [];
  products: Product[] = [];
  today = new Date();

  newCustomTaskForm = this.formBuilder.group({
    name:['']
  });
  form = this.formBuilder.group({
    clientsConcernes: this.formBuilder.array([[ [], Validators.required]]),
    workflowTasks: this.formBuilder.array([]),
    customTasks: this.formBuilder.array([]),
    step: ['', Validators.required],
    product: [''],
    amount: [null],
  },{ validators:[this.taskValidator, this.amountValidator(), this.productValidator()]});

  orderingForm = this.formBuilder.group({
    groups: this.formBuilder.array([]),
  });

  stepOptions: DropdownOption<any>[] = [];
  productOptions: DropdownOption<any>[] = [];
  workflowTasks: any[] = [];
  selectedTasks: any[] = [];
  customTasks: any[] = [];
  polesOptions: { label: string; value: any }[] = [];
  collaboratorOptions: DropdownOption<{id}>[] = [];
  stepStartDate =  new Date();
  currentDate =  new Date();
  deltaStartDateDeadlineDate: {groupIndex: number; taskIndex: number; delta: number}[] = [];
  showCustomTaskNotAddedModal = false;
  canLeave = true;
  canSave = true;
  displaySave = false;
  dateConsistencyError = false;

  steps: MenuItem[] = [{
      label: 'Sélectionner les tâches',
      command: (event: any) => {
        this.activeIndex = 0;
      }
    },
    {
      label: 'Ordonner les tâches',
      command: (event: any) => {
        this.activeIndex = 1;
      }
    },
    {
      label: 'Planifier les tâches',
      command: (event: any) => {
        this.activeIndex = 2;
      }
    },
  ];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery,
    private userQuery: UserQuery,
    private stepMasterQuery: StepMasterQuery,
    private stepService: StepService,
    private productQuery: ProductQuery,
    private sessionQuery: SessionQuery,
    private dropdownService: DropdownOptionsService,
    private cdr: ChangeDetectorRef,
    private translatorService: TranslatorService,
    private messageService: MessageService,
    public clientSearchService: ClientSearchService,
    private taskHelperService: TaskHelperService,
    private activatedRoute: ActivatedRoute,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  get clientsConcernesF() { return this.form?.get('clientsConcernes') as UntypedFormArray;}
  get productF() { return this.form?.get('product');}
  get workflowTasksF() { return this.form?.get('workflowTasks') as UntypedFormArray;}
  get customTasksF() { return this.form?.get('customTasks') as UntypedFormArray;}
  get newTaskF() { return this.newCustomTaskForm?.get('name');}
  get amountF() { return this.form?.get('amount');}
  get stepF() { return this.form?.get('step');}

  get groupsF() { return this.orderingForm.get('groups') as UntypedFormArray;}

  getTasksFByGroupIndex(index: number) { return this.groupsF.controls[index].get('tasks') as UntypedFormArray;}
  getTaskFByGroupAndTaskIndex(groupIndex: number, taskIndex: number) { return this.getTasksFByGroupIndex(groupIndex).controls[taskIndex];}

  getLegalPersonsConcernes(){
    //met à jour un tableau de legalPerson pour etre injecté dans les tasks
    const clientsConcernes: any[] = this.clientsConcernesF.value;
    const tempLegalPersons = clientsConcernes.filter(client => client.value.clientType === ClientType.LEGAL_PERSON);

    let legalPersonConcernes: any[] = [];

    tempLegalPersons.map(person => {
      legalPersonConcernes = [...legalPersonConcernes, {id: person.value.id}];
    });
    return legalPersonConcernes;
  }

  getNaturalPersonsConcernes(){
    //met à jour un tableau de naturalPerson pour etre injecté dans les tasks
    const clientsConcernes: any[] = this.clientsConcernesF.value;
    const tempNaturalPersons = clientsConcernes.filter(client => client.value.clientType === ClientType.NATURAL_PERSON);

    let naturalPersonConcernes: any[] = [];

    tempNaturalPersons.map(person => {
      naturalPersonConcernes = [...naturalPersonConcernes, {id: person.value.id}];
    });
    return naturalPersonConcernes;
  }

  productValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.get('step')?.value.hasProduct &&
        (control.get('product')?.value === '' ||
          control.get('product')?.value === null)) {
        return { productRequired: true };
      }
      return null;
    };
  }

  amountValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.get('step')?.value.hasAmount &&
        (control.get('amount')?.value === null ||
         control.get('amount')?.value === '')) {
        return { amountRequired: true };
      }
      return null;
    };
  }

  taskValidator(formGroup: UntypedFormGroup) {
    const customTasks = formGroup.value.customTasks;
    const workflowTasks = formGroup.value.workflowTasks;
    const taskValid = customTasks?.length !== 0 || workflowTasks?.length !== 0;
    return !taskValid ? {needTasks:true}: null;
  };

  setStepOptions() {
    this.stepOptions = [];
    this.stepMasters.map((step, index) => {
      this.stepOptions = [
        ...this.stepOptions,
        {
          label: step.name,
          value: step,
        },
      ];
    });
  }

  setWorkflowTasksByStepId(id: number){
    this.workflowTasks = [];
    this.stepMasters.filter(step => step.id === id)[0].groupMasters?.map(group => {
      group.taskMasters?.map(task => {
        this.workflowTasks = [...this.workflowTasks, {task, group: {id: group.id, position: group.position, delayBefore: group.delayBefore }}];
      });
    });
  }


  setProductOptions() {
    this.productOptions = [];
    this.products.map((product, index) => {
      this.productOptions = [
        ...this.productOptions,
        {
          label: product.name,
          value: product.name,
        },
      ];
    });
    this.productOptions.sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  setPolesOptions() {
    let allPoles: [] | DropdownOption[] = [];

    const polesCustomer = Object.values(PoleClient);
    polesCustomer.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          allPoles = [
            ...allPoles,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
    const polesInterne = Object.values(PoleInterne);
    polesInterne.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          allPoles = [
            ...allPoles,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
    this.polesOptions = allPoles.sort((resultA: DropdownOption, resultB: DropdownOption) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  setDefaultPole() {
    const defaultPole = this.polesOptions.filter((poleOption) =>
      poleOption.label === 'Back Office'
    );
    return defaultPole[0].value;
  }

  setDefaultTaskRelatedPerson() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        // Case access from natural person detail page.
        if (params['naturalPerson']) {
          // Set taskRelatedCustomer info for appropriate redirection when user create a task from related customer page.
          this.stepRelatedCustomer = {id: params['naturalPerson'], type: ClientType.NATURAL_PERSON};
          this.clientsConcernesF?.setValue([
            {
              label: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.firstName + ' ' +this.naturalPersonQuery.getEntity(params['naturalPerson'])?.lastName,
              value: {
                id: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.id,
                clientType: ClientType.NATURAL_PERSON
              }
            },
          ]);
        }
        // Case access from legal person detail page.
        if (params['legalPerson']) {
          // Set taskRelatedCustomer info for appropriate redirection when user create a task from related customer page.
          this.stepRelatedCustomer = {id: params['legalPerson'], type: ClientType.LEGAL_PERSON};
          this.clientsConcernesF?.setValue([
            {
              label: this.legalPersonQuery.getEntity(params['legalPerson'])?.name,
              value: {
                id: this.legalPersonQuery.getEntity(params['legalPerson'])?.id,
                clientType: ClientType.LEGAL_PERSON
              }
            },
          ]);
        }
      });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((val) => {
      this.canLeave = this.form.pristine;
    });
    this.setPolesOptions();
    this.sessionQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe((currentUser) => {
        this.user = currentUser;
      });
    this.userQuery.selectAll().pipe(untilDestroyed(this)).subscribe(users => {
        this.collaboratorOptions = users.map(user => this.dropdownService.mapUserIntoDropdownOption(user)).sort((resultA, resultB) =>
          resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
      }
    );
    this.stepMasterQuery.selectAll().pipe(untilDestroyed(this)).subscribe( stepMasters => {
      this.stepMasters = stepMasters;
      this.setStepOptions();
    });
    this.productQuery.selectAll().pipe(untilDestroyed(this)).subscribe( products => {
      this.products = products;
      this.setProductOptions();
    });
    this.setDefaultTaskRelatedPerson();
  }

  addClientConcerne() {
    this.clientsConcernesF.push(this.formBuilder.control('', Validators.required));
  }

  removeClientConcerne(index: number) {
    this.clientsConcernesF.removeAt(index);
  }

  addCustomTask(){
    if (this.newCustomTaskForm.get('name')?.value === '') {
      return;
    }
    this.customTasks = [...this.customTasks, {
      name: this.newTaskF?.value,
      plannedWorkload: 15,
      pole: null,
      importance: 2,
      helpText: '',
      comments: this.formBuilder.array([this.formBuilder.group({
        author: {id: this.user.id},
        content: '',
        resolved: false,
        blocked: false,
        creationDate: new Date().toDateString(),
      })]),
      startDate: '',
      deadlineDate: '',
      collaborator: null,
      contractor: this.user.id,
      isInternal: false,
      isPrivate: false,
      isLeaderPrivate: false,
      isPriority: false,
      status: '',
      //uniqueId est la pour eviter un bug de ObjectUtils.equalsByValue de primeng
      uniqueId: this.customTasks.length
    }];
    this.newTaskF?.reset();
    this.clearCustomTaskF();
    this.customTasks.map((task: any) => this.customTasksF.push(this.formBuilder.control(task)));
  }

  onSelectedStepChange(event: any){
    if(!event.value.hasAmount){
      this.amountF?.setValue(null);
    }
    if(!event.value.hasProduct){
      this.productF?.setValue(null);
    }
    this.clearWorkflowTaskF();
    this.setWorkflowTasksByStepId(event.value.id);
    this.checkAllTasks = true;
    this.checkAllTask();
  }

  onStartDateSelect(event: Date, groupIndex: number, taskIndex: number){
    const delta = this.deltaStartDateDeadlineDate.filter(deltaObject=> (deltaObject.taskIndex === taskIndex && deltaObject.groupIndex === groupIndex))[0].delta;
    //decalage deadlineDate function of startDate
    this.getTaskFByGroupAndTaskIndex(groupIndex, taskIndex).get('deadlineDate')?.setValue(addWeekDays(moment(event).clone(), delta).toDate());
  }

  onCheckAllTasks(event: any){
    if(event.checked){
      this.checkAllTask();
    } else {
      this.clearWorkflowTaskF();
      this.selectedTasks = [];
    }
  }

  checkAllTask(){
    this.clearWorkflowTaskF();
    this.selectedTasks = [...this.workflowTasks];
    this.workflowTasks.map((task: any) => this.workflowTasksF.push(this.formBuilder.control(task)));
  }

  onSelectTask(event: any){
    this.clearWorkflowTaskF();
    event.checked.map((task: any) => this.workflowTasksF.push(this.formBuilder.control(task)));
  }

  clearWorkflowTaskF(){
    while (this.workflowTasksF.length !== 0){
      this.workflowTasksF.removeAt(0);
    }
  }

  clearCustomTaskF(){
    while (this.customTasksF.length !== 0){
      this.customTasksF.removeAt(0);
    }
  }

  onSelectCustomTask(event: any){
    this.clearCustomTaskF();
    event.checked.map((task: any) => this.customTasksF.push(this.formBuilder.control(task)));
  }

  dropItemByGroupIndex(event: CdkDragDrop<any>, groupIndex: number) {
    //deplacement dans le tableau controls du form
    if (event.previousContainer === event.container){
      // moveItemInArray(this.getTaskMastersFByGroupIndex(groupIndex).controls, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.controls,
        this.getTasksFByGroupIndex(groupIndex).controls,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.cdr.detectChanges();
    this.syncForm();
    this.cleanGroups();
  }

  cleanGroups(){
    // suppression  groupes vide intermediaires si besoin et ajout groupe vide
    let groupsToKeep: AbstractControl[] = [];
    this.groupsF.controls.map( (group, index) => {
      if(this.getTasksFByGroupIndex(index).controls.length > 0){
        groupsToKeep = [...groupsToKeep, group];
      }
    });

    this.groupsF.clear();
    this.groupsF.controls = groupsToKeep;
    this.addEmptyGroup();
    this.updatePositions();
    this.cdr.detectChanges();
  }

  addEmptyGroup(){
    this.groupsF.push(
      this.formBuilder.group({
        position: [this.groupsF.length+1],
        delayBefore: 0,
        tasks: this.formBuilder.array([]),
      })
    );
    this.cdr.detectChanges();
  }

  updatePositions(){
    this.groupsF.controls.map( (group, index) => {
      group.patchValue({position:index+1});
    });
  }

  onDragEnded(taskId: number){
    //au deplacement d'une tache, on met l'id de la tache à null pour que celle ci soit remove (par cascade) puis recréé
    //taskId: l'id de la tache deplacée
    this.groupsF.controls.map( (group, index) => {
      this.getTasksFByGroupIndex(index).controls.map( (taskControls, taskIndex) => {
        if(taskControls.value.id === taskId){
          // delete taskControls.value.id;
          taskControls.patchValue({id: null});
        }
      });
    });
  }

  syncForm(){
    // synchro des values par rapport aux controls du form
    // sinon, les array value ne sont plus en lignes avec les array controls
    // ex: controls:Array(2) value:Array(1)
    this.groupsF.controls.map( (group, index) => {
      //recuperation des values de chaque control
      let values: any[] = [];
      this.getTasksFByGroupIndex(index).controls.map( control => values = [...values, control]);
      //update des values
      this.getTasksFByGroupIndex(index).patchValue(values);
    });
  }

  initOrderingForm() {
    //reconstitution d'un objet groups indéxé par position de group
    // ex: {1: delayBefore:x, position:1 ...,tasks[{id:x, name:y...}]
    const groups: any = {};
    this.workflowTasksF.controls.map((control, groupsIndex) => {
      if(!Object.keys(groups).includes(control.value.group.position.toString())){
        groups[control.value.group.position] = {...control.value.group, tasks: [control.value.task]};
      } else {
        groups[control.value.group.position].tasks = [...groups[control.value.group.position].tasks, control.value.task];
      }
    });

    const groupCount = Object.keys(groups).length;
    if(this.customTasksF.value.length > 0){
      groups[groupCount+1] = {delayBefore: 0, position: groupCount + 1, tasks: this.customTasksF.value};
    }


    //init orederingForm
    this.orderingForm = this.formBuilder.group({
      groups: this.formBuilder.array([]),
    });
    Object.values(groups).map((group, index) => {

      this.groupsF.push(this.formBuilder.group({
        // @ts-ignore
        position: group.position,
        // @ts-ignore
        delayBefore: group.delayBefore,
        tasks: this.formBuilder.array([]),
      }));
      // @ts-ignore
      group.tasks.map(task => {
        this.getTasksFByGroupIndex(index).push(this.formBuilder.group({
          plannedWorkload: [task.plannedWorkload, Validators.required],
          name: task.name,
          pole: task.pole,
          importance: task.importance,
          helpText: task.helpText,
          comments: this.formBuilder.array([this.formBuilder.group({
            author: {id: this.user.id},
            content: '',
            resolved: false,
            blocked: false,
            creationDate: new Date(),
          })]),
          startDate: '',
          deadlineDate: '',
          collaborator: null,
          contractor: {id: this.user.id},
          isInternal: false,
          isPrivate: false,
          isLeaderPrivate: false,
          isPriority: false,
          status: ''
        }));
      });
    });
    this.cleanGroups();
    this.cdr.detectChanges();
  }

  updateOrderingFormValidators(stepIndex: number) {
    this.groupsF.controls.map((group,groupIndex) => {
      this.getTasksFByGroupIndex(groupIndex).controls.map(task => {
        if(stepIndex === 1){
          //add validators on step 3 fields
          task.get('startDate')?.setValidators([Validators.required]);
          task.get('deadlineDate')?.setValidators([Validators.required]);
          task.get('importance')?.setValidators([Validators.required]);
          task.get('pole')?.setValidators([Validators.required]);
        }
        if(stepIndex === 2){
          //remove validators on step 3 fields
          task.get('startDate')?.setValidators([]);
          task.get('deadlineDate')?.setValidators([]);
          task.get('importance')?.setValidators([]);
          task.get('pole')?.setValidators([]);
        }
      });
    });
  }

  // calcule les dates de taches en fonction de la date de début, et y associe le status
  calculateDates(groupStartIndex = 0, stepStartDate: Date){
    // si groupStartIndex est renseigné, on boucle à partir de cet index
    // cela permet un recalcul descendant à partir du group concerné
    this.groupsF.controls.map((group,groupFIndex) => {
      if (groupFIndex >= groupStartIndex) {
        this.getTasksFByGroupIndex(groupFIndex).controls.map((task, taskFIndex) => {
          // conversion minutes en jours
          const taskPlannedWorkloadDays = Math.floor(task.get('plannedWorkload')?.value / 60 / 7);
          // group 1 : on ajoute pas de delay before et pas de tache precedente
          if (groupFIndex === 0) {
            task.get('startDate')?.setValue(moment(stepStartDate).toDate());
            task.get('deadlineDate')?.setValue(addWeekDays(moment(stepStartDate, 'L'), taskPlannedWorkloadDays).toDate());
            task.get('status')?.setValue(this.taskHelperService.calculateStatus(task.get('startDate')?.value));
          }
          // group > 1 : on ajoute le delay before et demarre à la date la plus tardive du groupe precedent
          if (groupFIndex > 0) {
            const previousGroupTasksControls = this.getTasksFByGroupIndex(groupFIndex - 1).controls as any[];
            let previousGroupLatestDate = previousGroupTasksControls[0].get('deadlineDate')?.value;
            previousGroupTasksControls.map(previousTaskControl => {
              if (previousTaskControl.get('deadlineDate')?.value > previousGroupLatestDate) {
                previousGroupLatestDate = previousTaskControl.get('deadlineDate')?.value;
              }
            });
            const delayBefore = this.groupsF.controls[groupFIndex].get('delayBefore')?.value;
            const startDate = addWeekDays(moment(previousGroupLatestDate).clone(), delayBefore).toDate();
            const deadlineDate = addWeekDays(moment(startDate, 'L').clone(), taskPlannedWorkloadDays).toDate();
            task.get('startDate')?.setValue(startDate);
            task.get('deadlineDate')?.setValue(deadlineDate);
            task.get('status')?.setValue(this.taskHelperService.calculateStatus(task.get('startDate')?.value));
          }
        });
      }
    });
  }

  calculateDeltaDate(){
    this.deltaStartDateDeadlineDate = [];
    //Pour toutes tache, on calcule la diff entre startDate et deadlineDate pour pouvoir appliquer la diff si on edite les startDAte en step 3
    this.groupsF.controls.map((group, groupIndex)=>{
      this.getTasksFByGroupIndex(groupIndex).controls.map((task, taskIndex) => {
        const startDate = this.getTaskFByGroupAndTaskIndex(groupIndex, taskIndex).get('startDate')?.value;
        const deadlineDate = this.getTaskFByGroupAndTaskIndex(groupIndex, taskIndex).get('deadlineDate')?.value;
        this.deltaStartDateDeadlineDate = [...this.deltaStartDateDeadlineDate,
          {groupIndex, taskIndex, delta: weekDays(moment(startDate, 'YYYYMMDD').clone(), moment(deadlineDate, 'YYYYMMDD').clone())}];
      });
    });
  }

  checkDateConsistency(): boolean{
    let consistency = true;
    this.groupsF.controls.map((group, groupIndex)=>{
      this.getTasksFByGroupIndex(groupIndex).controls.map((task, taskIndex) => {
        if (groupIndex > 0) {
          const previousGroupTasksControls = this.getTasksFByGroupIndex(groupIndex - 1).controls as any[];
          let previousGroupLatestDate = previousGroupTasksControls[0].get('deadlineDate')?.value;
          previousGroupTasksControls.map(previousTaskControl => {
            if (previousTaskControl.get('deadlineDate')?.value > previousGroupLatestDate) {
              previousGroupLatestDate = previousTaskControl.get('deadlineDate')?.value;
            }
          });

          const currentGroupTasksControls = this.getTasksFByGroupIndex(groupIndex).controls as any[];
          let currentGroupEarlierDate = currentGroupTasksControls[0].get('startDate')?.value;
          currentGroupTasksControls.map(currentTaskControl => {
            if (currentTaskControl.get('startDate')?.value < previousGroupLatestDate) {
              currentGroupEarlierDate = currentTaskControl.get('startDate')?.value;
            }
          });
          if(weekDays(moment(previousGroupLatestDate).clone().startOf('day'), moment(currentGroupEarlierDate).clone().startOf('day')) < 0) {
            consistency = false;
          }
        }
        return consistency;
      });
    });
    return consistency;
  }

  cancel(){
    this.navigation.back();
  }

  getRelatedCustomerPageUrl(taskRelatedCustomer: undefined | {id: number; type: ClientType.LEGAL_PERSON | ClientType.NATURAL_PERSON}) {
    if (taskRelatedCustomer) {
      const relatedCustomerType = taskRelatedCustomer.type === ClientType.NATURAL_PERSON ? 'natural-person' : 'legal-person';
      return '/' + relatedCustomerType + '/' + taskRelatedCustomer.id;
    } else {
      return undefined;
    }
  }

  submitForm(navigate: boolean){
    if (this.orderingForm.invalid) {
      this.orderingForm.markAllAsTouched();
      return;
    }
    if (!this.checkDateConsistency()){
      this.dateConsistencyError = true;
      return;
    }
    this.canLeave = true;
    const step: Step = {
      name:this.stepF?.value.name,
      product:this.productF?.value,
      amount:this.amountF?.value ? this.amountF?.value.toString() : null,
      legalPersons: this.getLegalPersonsConcernes(),
      naturalPersons: this.getNaturalPersonsConcernes(),
      // @ts-ignore
      stepGroups: this.orderingForm.value.groups.filter(group => group.tasks.length > 0),
    };

    //suppression comments vides et calcul status et conversion de date d'échéance en UTC
    step.stepGroups.map(group => {
      group.tasks.map(task => {
        task.comments = task.comments?.filter(comment => (comment.content !== '' && comment.content !== null));
        task.status = this.taskHelperService.calculateStatus(new Date(task.startDate), TaskStatus.PLANNED);
        task.startDate = this.taskHelperService.convertStartDateToUTC(task.startDate);
        task.deadlineDate = this.taskHelperService.convertDeadlineDateToUTC(task.deadlineDate);
      });
    });

    this.stepService.add(step).subscribe({
        next: () => {
          this.onSaveSuccess();
        },
        error: () => {
          this.onSaveError();
        },
      }
    );
  }

  onSaveSuccess() {
    this.isFormSubmissionLoading = false;
    const relatedCustomerDetailPage = this.getRelatedCustomerPageUrl(this.stepRelatedCustomer);
    this.router.navigate([relatedCustomerDetailPage ? relatedCustomerDetailPage : '/dashboard']);
    this.messageService.add({
      severity: 'success',
      summary: 'La nouvelle étape a été créée',
      life: 10000,
    });
  }

  onSaveError() {
    this.isFormSubmissionLoading = false;
    const relatedCustomerDetailPage = this.getRelatedCustomerPageUrl(this.stepRelatedCustomer);
    this.router.navigate([relatedCustomerDetailPage ? relatedCustomerDetailPage : '/dashboard']);
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000,
    });
  }


  nextPage() {
    switch (this.activeIndex) {
      case 0:
        if (this.form.invalid) {
          this.form.markAllAsTouched();
          return;
        }
        if (this.newCustomTaskForm.get('name')?.value !== '' && this.newCustomTaskForm.get('name')?.value !== null) {
          this.showCustomTaskNotAddedModal = true;
          return;
        }
        this.initOrderingForm();
        this.activeIndex++;
        this.navigation.scrollToTop();
        break;
      case 1:
        this.cleanGroups();
        this.updateOrderingFormValidators(this.activeIndex);
        this.calculateDates(0,this.stepStartDate);
        this.calculateDeltaDate();
        if (this.orderingForm.invalid) {
          this.orderingForm.markAllAsTouched();
          return;
        }
        this.activeIndex++;
        this.navigation.scrollToTop();
        break;
      case 2:
        break;
    }

  }

  prevPage() {
    switch (this.activeIndex) {
      case 0:
        break;
      case 1:
        this.activeIndex--;
        this.navigation.scrollToTop();
        break;
      case 2:
        this.updateOrderingFormValidators(this.activeIndex);
        this.activeIndex--;
        this.navigation.scrollToTop();
        break;
    }
  }

}
