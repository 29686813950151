import { ID } from '@datorama/akita';
import { Product } from '../../product/state/product.model';
import { Group } from '../../group/state/group.model';
import {LegalPerson} from '../../../client/legalPerson/state/legal-person.model';
import {NaturalPerson} from '../../../client/naturalPerson/state/natural-person.model';

export interface Step {
  id?: ID;
  name: string;
  stepGroups: Group[];
  product?: Product;
  amount?: number;
  isFollowStep?: boolean;
  legalPersons?: LegalPerson[];
  naturalPersons?: NaturalPerson[];
}

export function createStep(params: Partial<Step>) {
  return {

  } as Step;
}
