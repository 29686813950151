import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Objectif } from './objectif.model';

export interface ObjectifState extends EntityState<Objectif> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'objectifs' })
export class ObjectifStore extends EntityStore<ObjectifState> {

  constructor() {
    super();
  }

}
