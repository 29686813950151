import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  NaturalPersonQuery,
  NaturalPersonService,
  NaturalPerson,
  NaturalNaturalRelationTypeQuery,
  TranslatorService,
  NaturalNaturalRelationTypeService,
  NaturalNaturalRelation,
  DropdownOption,
  DropdownOptionsService
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-natural-natural-relation-form',
  templateUrl: './natural-natural-relation-form.component.html',
  styleUrls: ['./natural-natural-relation-form.component.scss']
})
export class NaturalNaturalRelationFormComponent implements OnInit {

  @Input() currentNaturalPersonId: number | string | undefined;
  @Input() naturalNaturalRelations: Partial<NaturalNaturalRelation>[] | undefined;
  allNaturalPerson: NaturalPerson[];
  form: UntypedFormGroup;
  formIsReady = false;
  naturalPersonOptions: DropdownOption[] = [];
  relationLabelOptions: DropdownOption[] = [];
  enfantDeOptions: DropdownOption[] = [];
  aCchargeFiscalOptions: DropdownOption[] = [];
  adoptionOptions: DropdownOption[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private naturalPersonQuery: NaturalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private naturalNaturalRelationTypeQuery: NaturalNaturalRelationTypeQuery,
    private naturalNaturalRelationTypeService: NaturalNaturalRelationTypeService,
    private dropdownService: DropdownOptionsService,
    private translatorService: TranslatorService
  ) {
  }

  ngOnInit(): void {
    this.relationLabelOptions = this.dropdownService.relationTypes;
    this.enfantDeOptions = this.dropdownService.enfantDe;
    this.aCchargeFiscalOptions = this.dropdownService.aChargeFiscale;
    this.adoptionOptions = this.dropdownService.adoption;
    this.naturalPersonQuery.selectAll().pipe(untilDestroyed(this)).subscribe(naturalPersons => this.allNaturalPerson = naturalPersons);
  }

  createFormGroup() {
    this.form = this.formBuilder.group({
      naturalPerson: [null, Validators.required],
      relationLabel: [null, Validators.required],
      enfantDe: null,
      aChargeFiscale: null,
      adopted: null,
    });
    this.formIsReady = true;
  }

  filterNaturalPersonOptions(allNaturalPersons: NaturalPerson[]) {
    let naturalPersonIdToRemove = [this.currentNaturalPersonId];
    this.naturalNaturalRelations?.map(naturalNaturalRelation => {
      naturalPersonIdToRemove = [... naturalPersonIdToRemove,naturalNaturalRelation.secondNaturalPerson?.id];
    });
    let naturalPersonOptionsFiltered: any[] = [];
    allNaturalPersons?.map(naturalPerson => {
      if (!naturalPersonIdToRemove.includes(naturalPerson.id)) {
        naturalPersonOptionsFiltered = [...naturalPersonOptionsFiltered, {
          label: naturalPerson.lastName + ' ' + naturalPerson.firstName,
          value: naturalPerson.id
        }];
      }
    });
    return naturalPersonOptionsFiltered;
  };

  /* get form from template */
  get naturalPersonF() { return this.form?.get('naturalPerson');}
  get relationLabelF() { return this.form?.get('relationLabel');}
  get enfantDeF() { return this.form?.get('enfantDe');}
  get chargeFiscaleF() { return this.form?.get('aChargeFiscale');}
  get adoptedF() { return this.form?.get('adopted');}
}
