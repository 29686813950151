export enum NaturalNaturalRelationTypeEnums {
  GRAND_PARENT = 'GRAND_PARENT',
  PARENT = 'PARENT',
  ENFANT = 'ENFANT',
  FRATRIE = 'FRATRIE',
  PETIT_ENFANT = 'PETIT_ENFANT',
  MARIE = 'MARIE',
  PACSE = 'PACSE',
  CONCUBIN = 'CONCUBIN',
  AUTRE_FAMILLE = 'AUTRE_FAMILLE',
  ONCLE_TANTE = 'ONCLE_TANTE',
  NEVEU_NIECE = 'NEVEU_NIECE',
  TIERS = 'TIERS',
}
