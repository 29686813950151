import { ID } from '@datorama/akita';
import { User } from '../../../user/state/user.model';
import { Comment } from '../../comment/state/comment.model';
import { Group } from '../../group/state/group.model';
import { LegalPerson } from '../../../client/legalPerson/state/legal-person.model';
import { NaturalPerson } from '../../../client/naturalPerson/state/natural-person.model';

export interface Task {
  id?: ID;
  name: string;
  status: string;
  startDate: string;
  deadlineDate: string;
  importance: number;
  plannedWorkload: number;
  isInternal: boolean;
  isPriority: boolean;
  isPrivate: boolean;
  isLeaderPrivate: boolean;
  pole: string;
  contractor: User;
  taskGroup?: Group;
  helpText?: string;
  closingDate?: string;
  realWorkload?: number;
  collaborator?: User;
  comments?: Comment[];
  legalPersons?: LegalPerson[];
  naturalPersons?: NaturalPerson[];
  hiddenUntil?: string;
}

export function createTask(params: Partial<Task>) {
  return {} as Task;
}
