<p-panel class="col-12" header="Boutons" [toggleable]="true">
  <div class="grid">

    <!-- ACTIONS -->
    <div class="col-12 grid">
      <!-- ACTIONS NV1 -->
      <p-card header="Actions niveau 1" class="col-12 lg:col-4">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-plus" label="Nouveau"></button>
          </div>
          <div class="mr-3">
            <p>Disabled</p>
            <button pButton icon="pi pi-plus" [disabled]="true" style="cursor: not-allowed" label="Nouveau"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="Bouton par défaut"></i>
        </ng-template>
      </p-card>
      <!-- ACTIONS NV2 -->
      <p-card header="Actions niveau 2" class="col-12 lg:col-4">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-pencil" class="p-button-raised p-button-text"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-raised p-button-text"></i>
        </ng-template>
      </p-card>
      <!-- ACTIONS NV3 -->
      <p-card header="Actions niveau 3" class="col-12 lg:col-4">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-check-circle" class="p-button-success p-button-raised p-button-text"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-success p-button-raised p-button-text"></i>
        </ng-template>
      </p-card>
    </div>

    <!-- FILTRES -->
    <div class="col-12 grid">
      <!-- ACTIONS FILTRE -->
      <p-card header="Filtres niveau 1" class="col-4">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton class="p-button-outlined" label="CLIENTS"></button>
          </div>
          <div class="mr-3">
            <p>Selected</p>
            <button pButton label="CLIENTS"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="Neutral : p-button-outlined / Selected : bouton par défaut"></i>
        </ng-template>
      </p-card>
    </div>

    <!-- AUTRES -->
    <div class="col-12 grid">
      <!-- NAVIGATION -->
      <p-card header="Navigation" class="col-6">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-chevron-left" class="p-button-raised p-button-text" label="Précedent"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-raised p-button-text"></i>
        </ng-template>
      </p-card>
      <!-- VALIDATION -->
      <p-card header="Validation" class="col-6">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-check" class="p-button-success p-button-raised" label="Valider"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-success"></i>
        </ng-template>
      </p-card>
    </div>
    <div class="col-12 grid">
      <!-- SUPPRESSION -->
      <p-card header="Suppression" class="col-6">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-trash" class="p-button-danger" label="Supprimer"></button>
          </div>
          <div class="mr-3">
            <p>Disabled</p>
            <button pButton icon="pi pi-trash" class="p-button-danger" [disabled]="true" style="cursor: not-allowed" label="Supprimer"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-danger"></i>
        </ng-template>
      </p-card>
      <!-- ANNULATION -->
      <p-card header="Annulation" class="col-6">
        <div class="flex">
          <div class="mr-3">
            <p>Neutral</p>
            <button pButton icon="pi pi-times" class="p-button-danger p-button-raised p-button-text" label="Supprimer"></button>
          </div>
        </div>
        <ng-template pTemplate="footer">
          <i class="pi pi-file" pTooltip="p-button-danger p-button-raised p-button-text"></i>
        </ng-template>
      </p-card>
    </div>

  </div>
</p-panel>
