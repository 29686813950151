import {User} from '../../user/state/user.model';
import {NotificationStatus} from '../../../enums/NotificationState';
import {NotificationNature} from '../../../enums/NotificationNature';

export interface Notification {
  id: number | string;
  user: User;
  created_at: Date;
  state: NotificationStatus;
  nature: NotificationNature;
  link: string;
}

export function createNotification(params: Partial<Notification>) {
  return {

  } as Notification;
}
