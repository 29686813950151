import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { PhoneStore, PhoneState } from './phone.store';

@Injectable({ providedIn: 'root' })
export class PhoneQuery extends QueryEntity<PhoneState> {

  constructor(protected override store: PhoneStore) {
    super(store);
  }

}
