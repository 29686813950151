import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiRecueilState {
   sentToConjoint: boolean;
}

export function createInitialState(): UiRecueilState {
  return {
    sentToConjoint: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'uiRecueil' })
export class UiRecueilStore extends Store<UiRecueilState> {

  constructor() {
    super(createInitialState());
  }

}
