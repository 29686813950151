import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { AvoirFinancierStore, AvoirFinancierState } from './avoir-financier.store';

@Injectable({ providedIn: 'root' })
export class AvoirFinancierService extends NgEntityService<AvoirFinancierState> {

  constructor(protected override store: AvoirFinancierStore) {
    super(store);
  }

}
