import {Inject, Injectable} from '@angular/core';
import {NgEntityService} from '@datorama/akita-ng-entity-service';
import {NaturalPersonStore, NaturalPersonState} from './natural-person.store';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class NaturalPersonService extends NgEntityService<NaturalPersonState> {

  constructor(
    @Inject('config') public override config: any,
    protected override store: NaturalPersonStore,
    private httpClient: HttpClient,
  ) {
    super(store);
  }

  checkRecueilConjointLinkToken(token: string, email: string): Observable<any> {
    return this.httpClient.get(this.config.apiUrl + '/check_new_recueil_conjoint_link_token?email=' + email +
      '&token=' + token);
  }

}
