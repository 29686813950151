import {ID} from '@datorama/akita';
import {File} from '../../file/state/file.model';

export interface Document {
  id?: ID;
  name?: string;
  type?: string;
  external?: boolean;
  files?: File[];
}

export function createDocument(params: Partial<Document>) {
  return {

  } as Document;
}
