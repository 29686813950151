import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Translation } from './translation.model';

export interface TranslationState extends EntityState<Translation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'translations' })
export class TranslationStore extends EntityStore<TranslationState> {

  constructor() {
    super();
  }

}
