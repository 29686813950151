import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taskImportance'
})
export class TaskImportancePipe implements PipeTransform {

  transform(value: number, ...args: any[]): any {
    switch (value){
      case 1:{
        return '€';
      }
      case 2:{
        return '€€';
      }
      case 3:{
        return '€€€';
      }
    }

  }

}
