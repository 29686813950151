import { Component, OnInit } from '@angular/core';
import {
  Objectif,
  NavigationService,
  MessageService,
  UiScreenQuery,
  ObjectifQuery,
  ObjectifService,
  ClientType
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {forkJoin} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
@UntilDestroy()
@Component({
  selector: 'app-page-objectif-list',
  templateUrl: './page-objectif-list.component.html',
  styleUrls: ['./page-objectif-list.component.scss']
})
export class PageObjectifListComponent implements OnInit {
    objectifs: Objectif[] = [];
  loading = false;
  clientType =  ClientType.NATURAL_PERSON;
  selectedObjectif: Objectif|null = null;
  showDeleteConfirmModal = false;
  constructor(
    public objectifQuery: ObjectifQuery,
    public objectifService: ObjectifService,
    public uiScreenQuery: UiScreenQuery,
    private messageService: MessageService,
    private navigation: NavigationService,
    private activatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.clientType = params['clientType'];
      this.objectifQuery.selectAll().pipe(untilDestroyed(this)).subscribe(objectifs => {
        this.objectifs = [];
        objectifs
          .filter(val => val.clientType === this.clientType)
          .map(objectif => this.objectifs = [...this.objectifs, {...objectif}]);

        this.objectifs.sort((a,b) => a.position - b.position);
      });
    });
  }

  add(){
    this.objectifs = [...this.objectifs, {label:'', position: 1000, clientType: this.clientType}];
  }

  delete(objectif: Objectif | null){
    this.showDeleteConfirmModal = false;
    if(objectif?.id){
      this.objectifService.delete(objectif?.id).subscribe(
        {
          next: () => {
            this.onSuccess();
            this.loading = false;
          },
          error: () => {
            this.onError();
            this.loading = false;
          },
        }
      );
    }
  }

  handleObjectifDelete(selectedObjectif: Objectif){
    if (selectedObjectif.id) {
      this.showDeleteConfirmModal = true;
      this.selectedObjectif = selectedObjectif;
    } else {
      this.objectifs = this.objectifs.filter(val => val !== selectedObjectif);
    }
  }

  onSubmit() {
    this.loading = true;
    let position = 1;
    this.objectifs.map(bien => {
      bien.position = position;
      position++;
    });

    let updateRequests: any[] = [];
    let createRequests: any[] = [];

    this.objectifs
      .filter((val: any) => val.code !== '' && val.label !== '')
      .map((bien, index) => {
        if(bien.id){
          updateRequests = [...updateRequests, this.objectifService.update(bien.id, bien)];
        } else{
          createRequests = [...createRequests, this.objectifService.add(bien)];
        }
      });

    forkJoin([...updateRequests, ...createRequests]).subscribe({
      next: () => {
        this.onSuccess();
        this.loading = false;
      },
      error: () => {
        this.onError();
        this.loading = false;
      },
    });
  }

  onSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Les modifications ont bien été sauvegardées',
      life: 10000
    });
  }

  onCancel() {
    this.navigation.back();
  }

  onError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la sauvegarde',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }

}
