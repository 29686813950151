<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only [clientOrAdmin]="'CLIENT'"></app-desktop-only>
</ng-container>

<ng-template #desktop>
  <ng-container *ngIf="loading">
    <app-loader></app-loader>
  </ng-container>
  <ng-container *ngIf="!loading && !error">
    <div class="grid rec mr-0">
      <div id="imgDiv" class="col-6 col-sm-12 p-7 pt-2 pb-4 right-panel flex flex-column align-items-center">
        <img src="assets/recueil/00-accueil-background.jpg" alt="backgroundBienvenue/" class="backgroundImg">
        <img src="assets/recueil/00-accueil-txt.svg" alt="backgroundBienvenue/" class="textImg">
      </div>
      <div class="col-6 col-sm-12 p-6 left-panel flex flex-column align-items-center">
        <h1 class="rec">{{'CLIENT_WELCOME_TITLE' | translate}}</h1>
        <p style="text-align: center">{{'CLIENT_WELCOME_TEXT1' | translate}}</p>
        <form [formGroup]="form" class="grid grid-nogutter flex-column justify-content-center align-items-center w-full">
          <div class="field col-9 mt-3 w-full">
            <input formControlName="password" id="password-input" type="password" placeholder="Écrivez ici votre mot de passe"
                   size="30" pInputText class="inputfield w-full">
            <lib-field-error *ngIf="passF?.touched && passF?.errors" [fieldError]="passF?.errors"></lib-field-error>
          </div>
          <div class="field col-9 mt-3 w-full">
            <input formControlName="confirmPass" id="confirm-password-input" placeholder="Confirmez votre mot de passe" type="password" size="40" pPassword class="inputfield w-full" [feedback]="false">
            <lib-field-error *ngIf="confirmPassF?.touched && confirmPassF?.errors" [fieldError]="confirmPassF?.errors"></lib-field-error>
          </div>
        </form>
        <p class="pb-5 mt-0" id="compte">{{'CLIENT_WELCOME_TEXT2' | translate}}</p>
        <div id="scrollPanelDiv">
          <p-scrollPanel [style]="{width: '100%', height: '242px'}" styleClass="custom">
            <br>
            <strong>Mise en garde et informations préalables</strong> <br><br>
            Préalablement à la commercialisation de tout contrat d’assurance, d’un instrument financier, d’un service
            d’investissement ou sur la réalisation d’opérations sur « biens divers » définies à l’article L.551- 1 du Code
            monétaire et financier, votre conseiller doit recueillir les informations nécessaires afin d’agir au mieux de
            vos intérêts et de délivrer un conseil adapté à votre situation, en justifiant notamment les différentes
            recommandations, les avantages et les risques qu’elles comportent et en expliquant en quoi elles sont adaptées.

            A cet effet, nous vous soumettons ce recueil d’informations par la signature duquel vous vous engagez à une
            communication exhaustive et sincère des informations vous concernant. Dans le cas contraire, les recommandations
            prodiguées par votre conseiller pourraient ne pas être en adéquation avec votre situation. Toute modification de
            votre situation familiale, patrimoniale ou professionnelle peut avoir une incidence sur le conseil donné. Elle
            doit donc être signalée à votre conseiller afin d’actualiser le présent document. <br><br>

            <strong>Règlement Général sur la Protection des Données (RGPD)</strong><br><br>

            Les informations recueillies dans le présent formulaire font l’objet d’un traitement destiné à établir un
            diagnostic de votre situation patrimoniale et de celle de votre foyer, et à vous conseiller relativement à la
            gestion de votre patrimoine dans le cadre des activités professionnelles de conseil en gestion de patrimoine.
            Ces informations sont nécessaires pour permettre au cabinet de réaliser ses missions. Le défaut de réponse peut
            avoir des conséquences sur la réalisation conforme des missions du cabinet. Les destinataires des données sont
            les conseils en gestion de patrimoine eux-mêmes, leur personnel conformément à leurs attributions internes, les
            destinataires habituels du conseil en gestion de patrimoine habilités à recueillir certaines informations en
            fonction de leurs attributions respectives et les partenaires sélectionnés pour réaliser l’investissement
            retenu. Les données collectées vous concernant vous et vos proches seront conservées pendant toute la durée de
            nos relations contractuelles et ensuite en archive pendant un délai de cinq (5) ans, à défaut des délais plus
            courts ou plus longs spécialement prévus notamment en cas de litige.

            Conformément au Règlement Général sur la Protection des Données personnelles (RGPD), vous disposez sur ces données
            d’un droit d'accès, de rectification, et de limitation du traitement, ainsi que d’un droit d’opposition et de
            portabilité conformément à la loi. Si vous souhaitez exercer ces droits, vous pouvez nous contacter par
            courriel. Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas
            respectés, vous pouvez adresser une réclamation en ligne à la CNIL ou par courrier postal.
            <br><br>

          </p-scrollPanel>
        </div>
        <div class="flex justify-content-center pt-6">
          <p-button icon="icon-icon-commencer-recueil fontello" label="Commencer le recueil" styleClass="p-button p-button-raised" (onClick)="onSubmit()"></p-button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && error">
    <div class="flex justify-content-center align-items-center error rec">
      {{errorMsg}}
    </div>
  </ng-container>

</ng-template>
