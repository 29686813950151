import { Component, OnInit } from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {UserService, UiScreenQuery} from 'common';

@Component({
  selector: 'app-page-activate-account',
  templateUrl: './page-activate-account.component.html',
  styleUrls: ['./page-activate-account.component.scss']
})
export class PageActivateAccountComponent implements OnInit {

  isAccountActivation = true;
  setPasswordForm: UntypedFormGroup;
  loading = false;
  resetPasswordToken: string;
  accountEmail: string;
  submissionLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private userService: UserService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  ngOnInit(): void {
    this.activeRoute.url.subscribe(url => {
      if(url[0].path === 'change-password') {
        this.isAccountActivation = false;
      }
    });
    /* check token (accuracy and validity) */
    this.activeRoute.queryParams
      .subscribe((params) => {
        this.resetPasswordToken = params['token'];
        this.accountEmail = params['email'];
        /* Si pas de token ou email dans la requete on a un problème, le lien de init password doit avoir les deux infos */
        if (!this.resetPasswordToken || !this.accountEmail) {
          this.router.navigate(['/login']);
          this.messageService.add({
            severity: 'error',
            summary: 'Le lien que vous avez suivi est incorrect',
            detail: 'Veuillez essayer à nouveau ou contacter l\'administrateur TIM',
            life: 15000
          });
        } else {
          this.userService.checkResetPasswordToken(this.resetPasswordToken, this.accountEmail).subscribe();
        }
      });
    this.setPasswordForm = this.formBuilder.group({
      pass: [null, [
        Validators.required,
        this.validatePasswordStrength(),
        this.checkSamePasswordValidator('confirmPass', true)
      ]],
      confirmPass: [null, [Validators.required, this.checkSamePasswordValidator('pass')]]
    });
  }

  onSubmit() {
    if (this.setPasswordForm.invalid) {
      this.setPasswordForm.markAllAsTouched();
      return;
    }
    this.submissionLoading = true;
    this.userService.setPassword(this.resetPasswordToken, this.accountEmail, this.passF?.value).subscribe(res => {
      this.router.navigate(['/login']);
      this.messageService.add({
        severity: 'success',
        summary: 'Votre mot de passe a été correctement enregistré',
        detail: 'Vous pouvez vous connecter à TIM avec vos nouveaux identifiants',
        life: 10000
      });
    });
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

  /* get form from template */
  get passF() { return this.setPasswordForm?.get('pass');}
  get confirmPassF() { return this.setPasswordForm?.get('confirmPass');}

  /* Custom validator */
  checkSamePasswords(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.passF?.value !== this.confirmPassF?.value) {
        return { notSamePassword: true };
      }
      return null;
    };
  }

  validatePasswordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Regex validant présence d'au moins 10 caractères dont : une minuscule, une majuscule et un chiffre
      const strongEnough =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)^.{12,}$/gm.test(control.value);

      return strongEnough ? null : { weakPassword: true };
    };
  }

  /* Same Password Validator */
  checkSamePasswordValidator(
    matchTo: string,
    reverse?: boolean
  ): ValidatorFn {
    return (control: AbstractControl):
      ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
      !!control.parent.value &&
      control.value ===
      (control.parent?.controls as any)[matchTo].value
        ? null
        : {notSamePassword: true};
    };
  }
}
