import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  AvoirFinancier,
  AvoirFinancierType,
  ClientType, DocumentType,
  DropdownOption,
  DropdownOptionsService,
  LegalPerson, LegalPersonQuery, LegalPersonService,
  NaturalPerson,
  NaturalPersonQuery, NaturalPersonService, NavigationService
} from 'common';
import {ID} from '@datorama/akita';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, Subscription} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-recueil-financier',
  templateUrl: './recueil-financier.component.html',
  styleUrls: ['./recueil-financier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilFinancierComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilFinancierComponent),
      multi: true
    }
  ]
})
export class RecueilFinancierComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @ViewChild('title') title: ElementRef;
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  documentType = DocumentType;
  client: NaturalPerson|LegalPerson;
  avoirFinancierTypeOptions: DropdownOption<any>[] = [];
  avoirFinancierType = AvoirFinancierType;
  selectedavoirFinancierType: AvoirFinancierType | null = null;
  collapsedPanels: {collapsed: boolean}[] = [];
  initialControlValue = {
    id: null,
    typeAvoir: null,
    assureurBanque: null,
    dateSouscription: null,
    souscripteur: null,
    valeur: null,
    versementProgramme: null,
    montantVersementProgramme: null,
    clauseBeneficiaire: null,
    releveCompte: null,
    releveSituation: null,
    titrePropriete: null,
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dropdownOptionsService: DropdownOptionsService,
    public naturalPersonQuery: NaturalPersonQuery,
    public legalPersonQuery: LegalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private legalPersonService: LegalPersonService,
    private translateService: TranslateService,
    private navigation: NavigationService) {}

  get financierControl() {
    return this.form.get('avoirFinanciers') as UntypedFormArray;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      avoirFinanciers: this.formBuilder.array([]),
    });
    if(this.clientOrAdmin === 'ADMIN'){
      this.form.addControl('comConseilleAvoirFinancier', this.formBuilder.control(null));
    }
    if(this.clientOrAdmin === 'CLIENT'){
      this.form.addControl('comAvoirFinancier', this.formBuilder.control(null));
    }
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe(value => {
          this.onChange(value);
          this.onTouched();
        })
    );
    this.avoirFinancierTypeOptions = this.dropdownOptionsService.avoirFinancierType;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(value.avoirFinanciers){
      this.collapsedPanels = [];
      while (this.financierControl.length !== 0) {
        this.financierControl.removeAt(0);
      }
      value.avoirFinanciers.map((val: any)=> {
        this.financierControl.push(this.formBuilder.control({}));
        this.collapsedPanels = [...this.collapsedPanels, {collapsed: true}];
      });

      this.value = value;
      this.form.markAsPristine();
      this.form.get('comConseilleAvoirFinancier')?.markAsPristine();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  add(){
    this.financierControl.push(this.formBuilder.control({...this.initialControlValue, typeAvoir: this.selectedavoirFinancierType}));
    this.selectedavoirFinancierType = null;
    this.collapsedPanels.map(panel=>panel.collapsed = true);
    this.collapsedPanels = [...this.collapsedPanels, {collapsed: false}];
    this.navigation.scrollToTop(this.title.nativeElement.offsetTop);
  }

  remove(index: number){
    this.financierControl.removeAt(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientType'] && changes['clientId']){
      if (changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.naturalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
          if(naturalPerson){
            this.client = naturalPerson;
          }
        });
      }
      if (changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.legalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(legalPerson => {
          if(legalPerson){
            this.client = legalPerson;
          }
        });
      }
    }

  }
}
