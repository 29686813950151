import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidatorFn, Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DropdownOption, DropdownOptionsService} from 'common';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailFormComponent),
      multi: true
    }
  ]
})
export class EmailFormComponent implements ControlValueAccessor, OnDestroy {
  @Input() toogleIsContact: any;
  // sert a conditionner l'affichage de certains champs
  @Input() advanced = false;
  @Input() recueilNaturalPerson = false;
  @Input() conjoint = false;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  contactTypeOptions: DropdownOption<any>[] = [];
  initialValues =
    {
      email: [null, this.emailValidator()],
      id: null,
      isContact: null,
      label: null,
      type: null,
    };

  constructor(private formBuilder: UntypedFormBuilder, private dropdownOptionsService: DropdownOptionsService) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.contactTypeOptions = this.dropdownOptionsService.contactType;
  }

  get typeF(): any {
    return this.form.get('type');
  }

  get emailF() {
    return this.form?.get('email');
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(this.conjoint){
      this.form.get('email')?.addValidators(Validators.required);
    }
    if (value) {
      this.value = value;
    }
  }

  onIsContactChange(event: any) {
    if (event.checked) {
      this.toogleIsContact.callParentMethod(this.form.value);
      this.form.get('isContact')?.patchValue(true);
    }
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {valid: false};
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const isValid = emailRegex.test(control.value);
      return isValid ? null : {email: true};
    };
  }


}
