import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-desktop-only',
  templateUrl: './desktop-only.component.html',
  styleUrls: ['./desktop-only.component.scss']
})
export class DesktopOnlyComponent {
  @Input() clientOrAdmin = 'ADMIN';
  constructor(private router: Router,) { }

  goToDashboard(){
    this.router.navigate(['/dashboard/']);
  }

}
