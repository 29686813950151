import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GroupStore, GroupState } from './group.store';

@Injectable({ providedIn: 'root' })
export class GroupQuery extends QueryEntity<GroupState> {

  constructor(protected override store: GroupStore) {
    super(store);
  }

}
