import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Product, Fournisseur, FournisseurQuery } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  initialValues: any;

  form = this.formBuilder.group({
    name: '',
    fournisseur: '',
  }) ;

  fournisseurs: Fournisseur[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private fournisseurQuery: FournisseurQuery,
  ) { }

  ngOnInit(): void {
    this.fournisseurQuery.selectAll().pipe(untilDestroyed(this)).subscribe(fournisseurs => {
      this.fournisseurs = fournisseurs.sort((resultA, resultB) => resultA.name.localeCompare(resultB.name, 'fr', {sensitivity: 'base'}));
    });
  }

  createFormGroup(product?: Product): UntypedFormGroup {
    this.initialValues = {
      name: product ? product.name : '',
      fournisseur: product ? product.fournisseur.id : null,
    };

    this.form = this.formBuilder.group({
      name: [this.initialValues.name, Validators.required],
      fournisseur: [this.initialValues.fournisseur, Validators.required],
    });

    return this.form;
  }

  get name() { return this.form?.get('name');}
  get fournisseur() { return this.form?.get('fournisseur');}
}
