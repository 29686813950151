import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { NaturalNaturalRelationTypeStore, NaturalNaturalRelationTypeState } from './natural-natural-relation-type.store';

@Injectable({ providedIn: 'root' })
export class NaturalNaturalRelationTypeService extends NgEntityService<NaturalNaturalRelationTypeState> {

  constructor(protected override store: NaturalNaturalRelationTypeStore) {
    super(store);
  }

}
