import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NaturalLegalRelation } from './natural-legal-relation.model';

export interface NaturalLegalRelationState extends EntityState<NaturalLegalRelation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'natural-legal-relation' })
export class NaturalLegalRelationStore extends EntityStore<NaturalLegalRelationState> {

  constructor() {
    super();
  }

}
