import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent {

  @Input() minValue = 0;
  @Input() maxValue = 10;
  @Input() styleClass: string;
  @Input() inputStyleClass: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() formGroupName: string;

  constructor() {}

  minusOne() {
    if (this.parentForm.controls[this.formGroupName].value - 1 >= this.minValue) {
      this.parentForm.controls[this.formGroupName].setValue(this.parentForm.controls[this.formGroupName].value - 1);
    }
  }
  plusOne() {
    if (this.parentForm.controls[this.formGroupName].value + 1 <= this.maxValue) {
      this.parentForm.controls[this.formGroupName].setValue(this.parentForm.controls[this.formGroupName].value + 1);
    }
  }
}
