import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiRecueilStore, UiRecueilState } from './ui-recueil.store';

@Injectable({ providedIn: 'root' })
export class UiRecueilQuery extends Query<UiRecueilState> {
  isConjointSent$ = this.select(state => state.sentToConjoint);

  constructor(protected override store: UiRecueilStore) {
    super(store);
  }

}
