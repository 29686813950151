import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Configuration } from './configuration.model';

export interface ConfigurationState extends EntityState<Configuration> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'configurations' })
export class ConfigurationStore extends EntityStore<ConfigurationState> {

  constructor() {
    super();
  }

}
