import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TranslationStore, TranslationState } from './translation.store';

@Injectable({ providedIn: 'root' })
export class TranslationQuery extends QueryEntity<TranslationState> {

  constructor(protected override store: TranslationStore) {
    super(store);
  }

}
