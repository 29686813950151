import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  UserService,
  UserQuery,
  User,
  TranslatorService,
  UiScreenQuery
} from 'common';
import { ActivatedRoute, Router } from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-page-user-list',
  templateUrl: './page-user-list.component.html',
  styleUrls: ['./page-user-list.component.scss'],
  providers: [MessageService, ConfirmationService]
})

export class PageUserListComponent implements OnInit {

  userList: User[] = [];
  user: User|null = null;
  loading = false;
  pageOffset = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public userQuery: UserQuery,
    private translatorService: TranslatorService,
    public uiScreenQuery: UiScreenQuery
  ) {  }

  ngOnInit(): void {
    this.loading = true;
    this.userQuery.selectLoading().pipe(untilDestroyed(this)).subscribe(loading => {
      this.loading = loading;
    });
    this.userQuery.selectAll().pipe(untilDestroyed(this)).subscribe( users => {
      // eslint-disable-next-line
      this.userList = users.map((user) => {
        // Table global filter can't suggest the value "BACK_OFFICE" when user search for "back office" because of the underscore.
        // This solution replace the underscore by an espace and format the value for an appropriate display.
        const userPoles =  user.poles.map((pole: any) => {
          const cleanedLowerCasePoleName = pole.replace(/_/g, ' ').toLowerCase();
          return cleanedLowerCasePoleName[0].toUpperCase() + cleanedLowerCasePoleName.substring(1);
        } );
        return {...user, poles: userPoles};
      });
    });
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (+params['first']) {
        this.pageOffset = +params['first'];
      }
    });
  }

  onPageChange(page: any) {
    this.pageOffset = page.first;
    this.router.navigate([], {
      queryParams: {
        first: this.pageOffset
      }
    });
  }

  translate(word: string) {
    return this.translatorService.instant(word);
  }

  openNewUser() {
    this.router.navigate(['/user-new']);
  }

  editUser(user: User) {
    this.router.navigate(['/user-edit', user.id]);
  }

  deleteUser(user: User) {
    this.confirmationService.confirm({
      message: 'Êtes vous sûre de vouloir supprimer ' + user.lastName + ' ' + user.firstName + ' de la liste des utilisateurs ?',
      header: 'Confirmer',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.user = null;
        this.userService.delete(user.id).pipe(untilDestroyed(this)).subscribe({
          next: (success) => {
            this.userList = this.userList.filter(val => val.id !== user.id);
            this.messageService.add({
              severity: 'success',
              summary: 'Suppression réussie',
              detail: 'L\'utilisateur a été supprimé avec succès.',
              life: 10000
            });
          },
          error: (e) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'L\'utilisateur n\'a pas été supprimé',
              life: 10000
            });
          },
        });
      }
    });
  }
}
