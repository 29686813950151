import { Inject, Injectable } from '@angular/core';
import { StepMasterState, StepMasterStore } from './step-master.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class StepMasterService extends NgEntityService<StepMasterState>{

  constructor(
    @Inject('config') public override config: any,
    protected override store: StepMasterStore
  ) {
    super(store);
  }

}
