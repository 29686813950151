import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BienDiversType } from './bien-divers-type.model';

export interface BienDiversTypeState extends EntityState<BienDiversType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bien_divers_types' })
export class BienDiversTypeStore extends EntityStore<BienDiversTypeState> {

  constructor() {
    super();
  }

}
