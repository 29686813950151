<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <div class="flex flex-column mt-8">
    <img src="assets/recueil/MASTER-LOGO-EMD.png" class="loader-logo-emd-mobile mx-auto" alt="logoEMD/" >
    <h1 class="rec mx-auto text-5xl">TIM</h1>
  </div>
  <form class="grid grid-nogutter flex-column justify-content-center align-items-center w-full mb-8" [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="isAccountActivation; else resetPasswordTitle">
      <h2 class="rec">Activez votre compte</h2>
    </ng-container>
    <ng-template #resetPasswordTitle>
      <h2 class="rec">Modifier votre mot de passe</h2>
    </ng-template>
    <div class="field col-6 mt-3">
      <label for="password-input">Mot de passe :</label>
      <input formControlName="pass" id="password-input" type="password" size="40" pInputText class="inputfield w-full">
      <lib-field-error *ngIf="passF?.touched && passF?.errors" [fieldError]="passF?.errors"></lib-field-error>
    </div>
    <div class="field col-6 mt-3">
      <label class="color-black" for="confirm-password-input">Confirmez votre mot de passe :</label>
      <input formControlName="confirmPass" id="confirm-password-input" type="password" size="40" pPassword class="inputfield w-full" [feedback]="false">
      <lib-field-error *ngIf="confirmPassF?.touched && confirmPassF?.errors" [fieldError]="confirmPassF?.errors"></lib-field-error>
    </div>
    <div class="field col-6 mt-3">
      <button pButton [loading]="loading" icon="pi pi-check" [label]="isAccountActivation? 'Créer mon mot de passe' : 'Modifier mon mot de passe'" type="submit" class="mt-3 p-button-raised rec w-full">
      </button>
    </div>
    <a class="mt-4" href="/login">Retourner à la page de connexion</a>
  </form>
</ng-container>
<ng-template #desktop>
  <div class="grid rec mr-0">
    <div id="imgDiv" class="col-6 col-sm-12 p-7 pt-2 pb-4 right-panel flex flex-column align-items-center">
      <img src="assets/recueil/00-accueil-background.jpg" alt="backgroundBienvenue/" class="backgroundImg">
      <img src="assets/recueil/00-accueil-txt.svg" alt="backgroundBienvenue/" class="textImg">
    </div>
    <div class="col-6 col-sm-12 right-panel flex flex-column justify-content-center align-items-center">
      <img src="assets/recueil/MASTER-LOGO-EMD.png" class="mb-5" alt="logoEMD/" height="153px" >
      <ng-container *ngIf="isAccountActivation; else resetPasswordTitle">
        <h2 class="rec pb-3">Activez votre compte</h2>
      </ng-container>
      <ng-template #resetPasswordTitle>
        <h2 class="rec pb-3">Modifier votre mot de passe</h2>
      </ng-template>
      <form class="grid grid-nogutter flex-column justify-content-center align-items-center w-full" [formGroup]="setPasswordForm" (ngSubmit)="onSubmit()">
        <div class="field col-6 mt-3">
          <label for="password-input">Mot de passe :</label>
          <input formControlName="pass" id="password-input" type="password" size="40" pInputText class="inputfield w-full">
          <lib-field-error *ngIf="passF?.touched && passF?.errors" [fieldError]="passF?.errors"></lib-field-error>
        </div>
        <div class="field col-6 mt-3">
          <label class="color-black" for="confirm-password-input">Confirmez votre mot de passe :</label>
          <input formControlName="confirmPass" id="confirm-password-input" type="password" size="40" pPassword class="inputfield w-full" [feedback]="false">
          <lib-field-error *ngIf="confirmPassF?.touched && confirmPassF?.errors" [fieldError]="confirmPassF?.errors"></lib-field-error>
        </div>
        <div class="field col-6 mt-3">
          <button pButton [loading]="loading" type="submit" class="mt-3 p-button-raised rec w-full">
            <i class="pi pi-check mr-2"></i>{{isAccountActivation? 'Créer mon mot de passe' : 'Modifier mon mot de passe'}}
          </button>
        </div>
        <a class="mt-4" href="/login">Retourner à la page de connexion</a>
      </form>
    </div>
  </div>
</ng-template>
