<div *ngIf="(uiScreenQuery.isMobile$ | async)" class="mb-3">
  <p class="text-xl text-primary font-semibold mb-">Résultats de la recherche</p>
  <p-divider></p-divider>
  <p-dropdown
    [options]="stepOptions"
    [showClear]="true"
    appendTo="body"
    placeholder="Toutes les étapes"
    styleClass="w-full"
    (onChange)="handleMobileStepFiltering($event.value)"
  >
  </p-dropdown>
</div>
<p-table #dt [value]="searchResults"
         [resizableColumns]="true"
         [rowHover]="true"
         styleClass="w-full">
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn style="width:25%">Nom</th>
      <th pResizableColumn style="width:75%">
        <p-columnFilter field="steps" [showMenu]="false" [showClearButton]="false" [matchMode]="'containsStep'">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown
              [ngModel]="value"
              [options]="stepOptions"
              [showClear]="true"
              appendTo="body"
              placeholder="Toutes les étapes"
              (onChange)="filter($event.value)"
            >
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-searchResult>
    <tr (click)="goto(searchResult)">
      <td style="width:25%"
          class="p-3">{{searchResult.clientType === clientType.LEGAL_PERSON ? searchResult.name : searchResult.lastName + ' ' + searchResult.firstName}}</td>
      <td style="width:75%" class="text-sm">
        <div class="flex flex-wrap">
          <p-tag *ngFor="let step of searchResult.steps"
                 styleClass="p-0 pl-3 m-1 step-tag"
          >
            <div class="flex flex-row-reverse align-items-center">
              <p-tag [rounded]="true" styleClass="m-1 count-tag" value="{{ step.count }}"></p-tag>
              <div>{{ step.name }}</div>
            </div>
          </p-tag>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5" class="p-3 text-center text-sm">Aucun client trouvé</td>
    </tr>
  </ng-template>
</p-table>


