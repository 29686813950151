import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DropdownOption, DropdownOptionsService, SensDonation, SituationMatrimoniale} from 'common';

@Component({
  selector: 'app-situation-matrimoniale-form',
  templateUrl: './situation-matrimoniale-form.component.html',
  styleUrls: ['./situation-matrimoniale-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SituationMatrimonialeFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SituationMatrimonialeFormComponent),
      multi: true
    }
  ]
})
export class SituationMatrimonialeFormComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() conjoint = false;
  @Input() personSituation = '';
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  situationMatrimonialeOptions: DropdownOption[];
  regimeMatrimonialOptions: DropdownOption[];
  regimePacsimonialOptions: DropdownOption[];
  sensDonation = SensDonation;
  situationMatrimonial = SituationMatrimoniale;
  currentDate = new Date();

  initialValues =
    {
      situationMatrimoniale: null,
      precedentMariage: null,
      dateMariage: null,
      lieuMariage: null,
      regimeMatrimonial: null,
      regimePacsimonial: null,
      donationDernierVivant: null,
      sensDonation: null,
      testamentCroise: null,
      sensTestamentCroise: null,
    };
  constructor( private formBuilder: UntypedFormBuilder,private dropdownService: DropdownOptionsService,) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.situationMatrimonialeOptions  = this.dropdownService.situationMatrimoniale;
    this.regimeMatrimonialOptions  = this.dropdownService.regimeMatrimonial;
    this.regimePacsimonialOptions  = this.dropdownService.regimePacsimonial;
  }

  get sensDonationControl(){
    return this.form.get('sensDonation') as UntypedFormControl;
  }

  get sensTestamentCroiseControl(){
    return this.form.get('sensTestamentCroise') as UntypedFormControl;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    if(this.conjoint){
      this.form.get('situationMatrimoniale')?.setValue(this.personSituation);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }


}
