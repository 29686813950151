<div cdkDropListGroup>
  <div class="grid">
    <div class="col-6">
      <div class="objectif-container">
        <p-badge [value]="'1'" size="xlarge" styleClass="mr-3"></p-badge>
        <div
          cdkDropList
          [cdkDropListData]="obj1"
          [cdkDropListEnterPredicate]="obj1Predicate"
          id="obj1"
          class="objectif-selected-area"
          (cdkDropListDropped)="drop($event)">
          <p-card class="objectif-card" *ngFor="let item of obj1" cdkDrag>
            <div class="flex align-items-center">
              <div class="p-3" (click)="removeObjectif(obj1,1)"><i class="pi pi-times"></i></div>
              {{item.label}} {{item.customLabel}}
            </div>
          </p-card>
        </div>
      </div>

      <div class="objectif-container">
        <p-badge [value]="'2'" size="xlarge" styleClass="mr-3"></p-badge>
        <div
          cdkDropList
          [cdkDropListData]="obj2"
          [cdkDropListEnterPredicate]="obj2Predicate"
          id="obj2"
          class="objectif-selected-area"
          (cdkDropListDropped)="drop($event)">
          <p-card class="objectif-card" *ngFor="let item of obj2" cdkDrag>
            <div class="flex align-items-center">
              <div class="p-3" (click)="removeObjectif(obj2,2)"><i class="pi pi-times"></i></div>
              {{item.label}} {{item.customLabel}}
            </div>
          </p-card>
        </div>
      </div>

      <div class="objectif-container">
        <p-badge [value]="'3'" size="xlarge" styleClass="mr-3"></p-badge>
        <div
          cdkDropList
          [cdkDropListData]="obj3"
          [cdkDropListEnterPredicate]="obj3Predicate"
          id="obj3"
          class="objectif-selected-area"
          (cdkDropListDropped)="drop($event)">
          <p-card class="objectif-card" *ngFor="let item of obj3" cdkDrag>
            <div class="flex align-items-center">
              <div class="p-3" (click)="removeObjectif(obj3,3)"><i class="pi pi-times"></i></div>
              {{item.label}} {{item.customLabel}}
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="list-container">
        <div
          cdkDropList
          [cdkDropListData]="objectifs"
          id="objectif-list"
          (cdkDropListDropped)="drop($event)">
          <div class="objectif-list-card" *ngFor="let item of objectifs" >
            <p-card class="objectif-card" cdkDrag [cdkDragDisabled]="item.type === 'other' && (item.customLabel === '' || item.customLabel === null)">
              <div class="flex align-items-center drag-handle" cdkDragHandle>
                <div class="p-3"><i class="pi pi-ellipsis-v"></i></div>
                <div>
                  <div class="flex align-items-center">
                    <div class="mr-2">
                      {{item.label}}
                    </div>
                    <input *ngIf="item.type === 'other'" [(ngModel)]="item.customLabel" pInputText class="inputfield" [placeholder]="'Compléter et déplacer.'">
                  </div>
                </div>

              </div>
            </p-card>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>





