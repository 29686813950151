import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FilterService} from 'primeng/api';
import {Table} from 'primeng/table';
import {Dropdown} from 'primeng/dropdown';
import {combineQueries} from '@datorama/akita';
import * as moment from 'moment';
import {
  ClientOptionValue,
  DashboardFiltersType,
  DashboardView,
  DropdownOption,
  DropdownOptionsService,
  LegalPerson,
  NaturalPerson,
  PoleAndCollaboratorOptionValue,
  PoleClient,
  PoleInterne,
  SessionQuery,
  Step,
  StepAndTaskOptionValue,
  StepMasterQuery,
  StepQuery,
  Task,
  TaskDeadlineStatus,
  TaskHelperService,
  TaskQuery,
  TaskStatus,
  TranslatorService,
  UiQuery,
  UiScreenQuery,
  UiState,
  UiStore,
  User,
  UserQuery,
  UserRole
} from 'common';

// Object shape added to task to allow filtering by pole / collaborator / my tasks.
type AddonFilterPoleAndCollaborator = {
  pole: PoleInterne;
  collaborator?: User;
  isPriority: boolean;
  status: TaskStatus;
};

// Object shape added to task to allow filtering by customer.
type AddonFilterLegalAndNaturalCustomer = {
  nameLabel: string;
  naturalPeople: [] | NaturalPerson[];
  legalPeople: [] | LegalPerson[];
};

// Object shape added to task to allow filtering by step and task name.
type AddonFilterStepAndTaskName = {
  relatedStep: string;
  taskName: string;
};

// Final form of a task.
interface DashboardTask extends Task {
  step?: Step;
  poleAndCollaborator?: AddonFilterPoleAndCollaborator;
  legalAndNaturalCustomer?: AddonFilterLegalAndNaturalCustomer;
  stepAndTaskName?: AddonFilterStepAndTaskName;
}

@UntilDestroy()
@Component({
  selector: 'app-page-dashboard',
  templateUrl: './page-dashboard.component.html',
  styleUrls: ['./page-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDashboardComponent implements OnInit {
  @ViewChild('tasksTableCustomer', { static: true }) tasksTableCustomer: Table;
  @ViewChild('tasksTableInternal', { static: true }) tasksTableInternal: Table;
  @ViewChild('filterImportanceCustomer', { static: false }) filterImportanceCustomer: Dropdown;
  @ViewChild('filterImportanceInternal', { static: false }) filterImportanceInternal: Dropdown;
  @ViewChild('filterSteps', { static: false }) filterSteps: Dropdown;
  @ViewChild('filterTasks', { static: false }) filterTasks: Dropdown;
  @ViewChild('filterCustomer', { static: false }) filterCustomer: Dropdown;
  @ViewChild('filterCollaboratorAndPoleCustomer', { static: false }) filterCollaboratorAndPoleCustomer: Dropdown;
  @ViewChild('filterCollaboratorAndPoleInternal', { static: false }) filterCollaboratorAndPoleInternal: Dropdown;

  // Reference for use enum in HTML.
  dashboardView = DashboardView;
  dashboardFiltersType = DashboardFiltersType;
  taskStatus = TaskStatus;
  // Others.
  currentUser: User;
  currentUserHasCustomerPriorityTasks: boolean;
  currentUserHasInternalPriorityTasks: boolean;
  dashboardFirstLoad = true;
  // Tasks data.
  allCustomerTasks: [] | Task[];
  allInternalTasks: [] | Task[];
  rawCustomerTasks: [] | Task[];
  rawInternalTasks: [] | Task[];
  customerTasks: DashboardTask[];
  internalTasks: DashboardTask[];
  tasksIndicators: undefined | Record<'prioritary' | 'closeDeadline' | 'highImportance' | 'validate', number>;
  // UI.
  uiStoreState: UiState;
  currentDashboardView: DashboardView = DashboardView.CUSTOMER;
  displayCloseDialog = false;
  closingTask: any = null;
  // Dropdown options.
  importanceOptions: DropdownOption[];
  stepsOptions: DropdownOption<any>[];
  stepsTasksOption: DropdownOption<any>[];
  stepFilterRelatedTaskOption: DropdownOption<any>[]; // Task options depending on selected step filter.
  clientOptions: DropdownOption<ClientOptionValue>[];
  poleAndCollaboratorOptionsCustomer: DropdownOption<any>[];
  poleAndCollaboratorOptionsInternal: DropdownOption<any>[];
  // The form is used for set fitler label in dropdown.
  form = this.formBuilder.group({
    importanceFilterCustomer: [],
    importanceFilterInternal: [],
    stepFilter: [],
    stepTaskFilter: [],
    customerFilter: [],
    poleAndCollaboratorFilterCustomer: [],
    poleAndCollaboratorFilterInternal: [],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translatorService: TranslatorService,
    private taskQuery: TaskQuery,
    private stepMasterQuery: StepMasterQuery,
    private dropdownOptionsService: DropdownOptionsService,
    private filterService: FilterService,
    private sessionQuery: SessionQuery,
    private userQuery: UserQuery,
    private taskHelperService: TaskHelperService,
    private formBuilder: UntypedFormBuilder,
    private stepQuery: StepQuery,
    private uiStore: UiStore,
    private uiQuery: UiQuery,
    public uiScreenQuery: UiScreenQuery,
    private cdref: ChangeDetectorRef
  ) {}

  setImportanceFilter(filterValue: number, table: DashboardView) {
    if (table === this.dashboardView.CUSTOMER) {
      this.form.get('importanceFilterCustomer')?.setValue(filterValue, {emitModelToViewChange:false});
      this.tasksTableCustomer.filter(filterValue, 'importance', 'containsImportance');
    } else if (table === this.dashboardView.INTERNAL) {
      this.form.get('importanceFilterInternal')?.setValue(filterValue, {emitModelToViewChange:false});
      this.tasksTableInternal.filter(filterValue, 'importance', 'containsImportance');
    }
  }

  setStepFilter(filterValue: StepAndTaskOptionValue) {
      this.form.get('stepFilter')?.setValue(filterValue, {emitModelToViewChange:false});
      this.tasksTableCustomer.filter(filterValue, 'step', 'containsStep');
  }

  setStepTaskFilter(filterValue: StepAndTaskOptionValue) {
    this.form.get('stepTaskFilter')?.setValue(filterValue, {emitModelToViewChange:false});
    this.tasksTableCustomer.filter(filterValue, 'name', 'containsStepTask');
  }

  setCustomerFilter(filterValue: ClientOptionValue){
    this.form.get('customerFilter')?.setValue(filterValue, {emitModelToViewChange:false});
    this.tasksTableCustomer.filter(filterValue, 'legalAndNaturalCustomer', 'containsClient');
  }

  setCollaboratorAndPoleFilter(filterValue: PoleAndCollaboratorOptionValue, table: DashboardView) {
    if (table === this.dashboardView.CUSTOMER) {
        this.form.get('poleAndCollaboratorFilterCustomer')?.setValue(filterValue, {emitModelToViewChange:false});
        this.tasksTableCustomer.filter(filterValue, 'poleAndCollaborator', 'containsPoleOrCollaborator');
    } else if (table === this.dashboardView.INTERNAL) {
        this.form.get('poleAndCollaboratorFilterInternal')?.setValue(filterValue, {emitModelToViewChange:false});
        this.tasksTableInternal.filter(filterValue, 'poleAndCollaborator', 'containsPoleOrCollaborator');
    }
  }

  ngOnInit() {
    this.getCurrentUserInformation();
    this.getUiStoreState();
    this.setTableFilters();
    this.setDropdownOptions();
  }

  getCurrentUserInformation() {
    this.sessionQuery.select().pipe(untilDestroyed(this)).subscribe((sessionUser) => {
        if (sessionUser && sessionUser.id) {
          this.userQuery.selectEntity(sessionUser.id).subscribe((user) => {
            if (user) {
              this.currentUser = user;
              this.getAllTasks();
            }
          });
        }
      });
  }

  getUiStoreState() {
    this.uiQuery.select().pipe(untilDestroyed(this)).subscribe((uiStoreState) => {
        if (uiStoreState) {
          this.uiStoreState = uiStoreState;
        }
      });
  }

  getAllTasks() {
    combineQueries([
        this.stepQuery.selectAll().pipe(untilDestroyed(this)),
        this.taskQuery.selectAll({filterBy: [
          (task) => task.isInternal && task.status !== '' && task.status !== TaskStatus.PLANNED
        ]}).pipe(untilDestroyed(this)),
      ]
    ).pipe(untilDestroyed(this)).subscribe(
      ([customerStepTasks, internalTasks]) => {
        if (customerStepTasks) {
          let tasks: any[] = [];
          customerStepTasks.map((step) => {
              step.stepGroups.map((group) =>
                group.tasks.map((task) => {
                  tasks = [...tasks, {
                    ...task,
                    step,
                    naturalPersons: step.naturalPersons ?? [],
                    legalPersons: step.legalPersons ?? [],
                  }];
                })
              );
            }
          );
          this.allCustomerTasks = tasks;
        }
        if (internalTasks) {
          this.allInternalTasks = internalTasks;
        }
        this.initDashboardsWorkflow();
      }
    );
  };

  setTableFilters() {
    this.filterService.register('containsImportance',(value: number, filter: number): boolean => this.doesTaskContainImportance(value, filter));
    this.filterService.register('containsClient',(value: AddonFilterLegalAndNaturalCustomer, filter: ClientOptionValue): boolean => this.doesTaskContainClient(value, filter));
    this.filterService.register('containsPoleOrCollaborator',(value: AddonFilterPoleAndCollaborator, filter: PoleAndCollaboratorOptionValue): boolean =>
      this.doesTaskContainPoleOrCollaborator(value, filter));
    this.filterService.register('containsStep',(value: Step, filter: StepAndTaskOptionValue): boolean => this.doesTaskContainStepName(value, filter));
    this.filterService.register('containsStepTask',(value: any, filter: any): boolean => this.doesTaskContainsTaskName(value, filter));
  }

  setDropdownOptions() {
    this.importanceOptions = this.dropdownOptionsService.tasksImportance;
    this.stepsOptions = this.dropdownOptionsService.steps;
    this.stepsTasksOption = this.dropdownOptionsService.stepsTasks;
    this.stepFilterRelatedTaskOption = this.dropdownOptionsService.stepsTasks;
    this.poleAndCollaboratorOptionsCustomer = this.dropdownOptionsService.polesAndCollaborators;
    const poleAndCollaboratorOptionsInternal = this.dropdownOptionsService.polesAndCollaborators;
    // Remove customer pole of pole and collaborator dropdown for internal table.
    poleAndCollaboratorOptionsInternal[1].items = poleAndCollaboratorOptionsInternal[1].items?.filter((poleAndCollaboratorOption) =>
      !Object.values(PoleClient).includes(poleAndCollaboratorOption.value.id));
    this.poleAndCollaboratorOptionsInternal = poleAndCollaboratorOptionsInternal;
    this.clientOptions = this.dropdownOptionsService.clients;
  }

  // Dashboard tasks workflow from database tasks to displayed tasks.
  initDashboardsWorkflow() {
    // [1/7]
    this.rawCustomerTasks = this.tasksSorter(this.tasksFilter(this.allCustomerTasks, DashboardView.CUSTOMER));
    this.rawInternalTasks = this.tasksSorter(this.tasksFilter(this.allInternalTasks, DashboardView.INTERNAL));
    // [2/7] Check if user has priority task (needed information for "Mes tâches" filter).
    this.currentUserHasCustomerPriorityTasks = this.doesUserHasPriorityTasks(this.rawCustomerTasks.concat(this.rawInternalTasks), DashboardView.CUSTOMER);
    this.currentUserHasInternalPriorityTasks = this.doesUserHasPriorityTasks(this.rawCustomerTasks.concat(this.rawInternalTasks), DashboardView.INTERNAL);
    // [3/7] Add properties to tasks for table filtering, sorting, display...
    this.customerTasks = this.addPropertiesToTasks(this.rawCustomerTasks);
    this.internalTasks = this.addPropertiesToTasks(this.rawInternalTasks);
    // [4/7] Filter dropdown options with data related to the dashboard tasks.
    if (this.customerTasks.length > 0) {
      this.clientOptions = this.filterClientOptions(this.customerTasks, this.clientOptions);
      this.stepsOptions = this.filterStepOptions(this.customerTasks, this.stepsOptions);
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(this.customerTasks);
    }
    // [5/7] Calculate tasks indicators.
    this.calculateIndicators(this.allCustomerTasks, this.allInternalTasks);
    // [6/7] Set appropriate dashboard view bases on UI store (Akita).
    this.setStorredDashboardView();
    // [7/7] Programmatically set the "Mes tâches" filter in the dropdown on user login.
    if (this.dashboardFirstLoad) {
      this.handleFiltering({ id: 'MYTASKS', type: 'other' }, DashboardFiltersType.COLLABORATORANDPOLE, DashboardView.CUSTOMER);
      this.handleFiltering({ id: 'MYTASKS', type: 'other' }, DashboardFiltersType.COLLABORATORANDPOLE, DashboardView.INTERNAL);
    }
    this.cdref.markForCheck();
  }

  onDashboardStateRestore(event: any, table: DashboardView) {
    this.dashboardFirstLoad = false;

    if (event.filters) {
      if (event.filters.importance && table === this.dashboardView.CUSTOMER) {this.form.get('importanceFilterCustomer')?.setValue(event.filters.importance.value);}
      if (event.filters.importance && table === this.dashboardView.INTERNAL) {this.form.get('importanceFilterInternal')?.setValue(event.filters.importance.value);}
      if (event.filters.step) { this.form.get('stepFilter')?.setValue(event.filters.step.value);}
      if (event.filters.name) {this.form.get('stepTaskFilter')?.setValue(event.filters.name.value);}
      if (event.filters.legalAndNaturalCustomer) {this.form.get('customerFilter')?.setValue(event.filters.legalAndNaturalCustomer.value);}
      if (event.filters.poleAndCollaborator && table === this.dashboardView.CUSTOMER) {this.form.get('poleAndCollaboratorFilterCustomer')?.setValue(event.filters.poleAndCollaborator.value);}
      if (event.filters.poleAndCollaborator && table === this.dashboardView.INTERNAL) {this.form.get('poleAndCollaboratorFilterInternal')?.setValue(event.filters.poleAndCollaborator.value);}
    }
  }

  tasksFilter(tasks: [] | Task[], table: DashboardView): [] | Task[] {
    if (table === DashboardView.CUSTOMER) {
      return tasks.filter((task: Task) => !task.isInternal && task.status && task.status !== TaskStatus.DONE && task.status !== TaskStatus.PLANNED);
    }
    if (table === DashboardView.INTERNAL) {
      return tasks.filter((task: Task) =>
        task.status && task.status !== TaskStatus.DONE && task.status !== TaskStatus.PLANNED &&
        ((!task.isPrivate || task.contractor?.id === this.currentUser.id) &&
        (!task.isLeaderPrivate || this.currentUser.roles.some((role) => role === UserRole.ROLE_DIRIGEANT))) ||
        (this.currentUser.poles.includes(task.pole) && !task.collaborator && !task.isPrivate && !task.isLeaderPrivate));
    }
    return tasks;
  }

  tasksSorter(task: Task[]) {
    // Important to keep the less meaning sorting first and the most meaning last.
    const sortedByImportance = task.sort((taskA, taskB) =>
      this.abstractTaskSorter(taskA.importance, taskB.importance)
    );
    const sortedByDeadline = sortedByImportance.sort((taskA, taskB) =>
      this.abstractTaskSorter(moment(taskA.deadlineDate, 'YYYYMMDD'), moment(taskB.deadlineDate, 'YYYYMMDD'), 'ASC')
    );
    const sortedByStartDate = sortedByDeadline.sort((taskA, taskB) =>
      this.abstractTaskSorter(moment(taskA.startDate, 'YYYYMMDD'), moment(taskB.startDate, 'YYYYMMDD'), 'ASC')
    );
    return sortedByStartDate.sort((taskA, taskB) =>
      this.abstractTaskSorter(taskA.isPriority, taskB.isPriority)
    );
  }

  abstractTaskSorter(taskA: any, taskB: any, sort: 'ASC' | 'DESC' = 'DESC') {
    if (taskA > taskB) { return sort === 'DESC' ? -1 : +1; }
    else if (taskA < taskB) { return sort === 'DESC' ? +1 : -1; }
    else { return 0; }
  }

  doesUserHasPriorityTasks(tasks: Task[], table: DashboardView): boolean {
    return tasks.some((task) => {
      if (table === DashboardView.CUSTOMER && task.isInternal) { return false; }
      if (table === DashboardView.INTERNAL && !task.isInternal) { return false; }

      const doesTaskIsPrioritary = task.isPriority;
      const doesTaskStatusIsTodo = task.status === TaskStatus.TODO;
      const doesTaskBelongToMe = task.collaborator?.id === this.currentUser.id;
      const doesTaskBelongToMyPole = this.currentUser.poles.includes(task.pole) && !task.collaborator;

      return (doesTaskIsPrioritary && doesTaskStatusIsTodo && (doesTaskBelongToMe || doesTaskBelongToMyPole));
    });
  }

  addPropertiesToTasks(tasks: Task[]): DashboardTask[] {
    const tasksWithCustomerProperty = this.addLegalAndNaturalPersonKeyToTasks(tasks);
    const tasksWithPoleAndCollaboratorProperty = this.addPoleAndCollaboratorKeyToTasks(tasksWithCustomerProperty);
    return this.addDeadlineProgressStatusKeyToTasks(tasksWithPoleAndCollaboratorProperty);
  }

  addPoleAndCollaboratorKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      poleAndCollaborator: {
        pole: task.pole as PoleInterne,
        collaborator: task.collaborator,
        isPriority: task.isPriority,
        status: task.status as TaskStatus,
      },
    }));
  }

  addLegalAndNaturalPersonKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => {
      // The alphabetical sorting of customers must be done on the same field regardless of whether the task is linked
      // to a natural or legal person, for this we create an intermediate "nameLabel" field on which to rely for sorting.
      let nameLabel = '';
      if (task.naturalPersons && task.naturalPersons[0]) {
        nameLabel = task.naturalPersons[0].lastName;
      } else if (task.legalPersons && task.legalPersons[0]) {
        nameLabel = task.legalPersons[0].name;
      }
      return {
        ...task,
        legalAndNaturalCustomer: {
          nameLabel,
          legalPeople: task.legalPersons,
          naturalPeople: task.naturalPersons,
        },
      };
    });
  }

  addDeadlineProgressStatusKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      deadlineProgress: this.taskHelperService.calculateDeadlineIndicator(
        new Date(task.startDate),
        new Date(task.deadlineDate)
      ),
    }));
  }

  filterClientOptions(tasks: DashboardTask[], clientOptions: DropdownOption<ClientOptionValue>[]): DropdownOption<ClientOptionValue>[] {
    let customersWithTaskInDashboard: [] | ({personType: string} & NaturalPerson | {personType: string} & LegalPerson)[] = [];

    tasks.map((task) => {
      task.legalAndNaturalCustomer?.legalPeople?.map((legalPerson) => {
        if (!customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
          customerWithTaskInDashboard.id === legalPerson.id)) {
          customersWithTaskInDashboard = [...customersWithTaskInDashboard, { ...legalPerson, personType: 'legalPerson'},];
        }
      });
    });

    tasks.map((task) => {
      task.legalAndNaturalCustomer?.naturalPeople?.map((naturalPerson) => {
        if (!customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
          customerWithTaskInDashboard.id === naturalPerson.id)) {
          customersWithTaskInDashboard = [...customersWithTaskInDashboard, { ...naturalPerson, personType: 'naturalPerson'}];
        }
      });
    });

    return clientOptions.filter((clientOption: DropdownOption<ClientOptionValue>) => customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
      (clientOption.value.id === customerWithTaskInDashboard.id && clientOption.value.type === customerWithTaskInDashboard.personType)
    )).sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  filterStepOptions(tasks: DashboardTask[], stepOptions: DropdownOption<StepAndTaskOptionValue>[]): DropdownOption<StepAndTaskOptionValue>[] {
    return stepOptions.filter((stepOption) => tasks.some((task) => stepOption.value.name === task.step?.name))
      .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  filterStepTasksOptions(tasks: DashboardTask[]): DropdownOption<StepAndTaskOptionValue>[] {
    let stepTasks: DropdownOption<StepAndTaskOptionValue>[] = [];
    tasks.map((task) => {
      if (!stepTasks.some((stepTask) => stepTask.value.name === task.step?.name) && task.step?.name) {
        stepTasks = [...stepTasks, { label: task.step?.name, value: { name: task.step?.name, type: 'STEP' }, items: [] }];
      }

      stepTasks.map((stepTask, index) => {
        if (task.step && stepTask.value.name === task.step.name && stepTask.items && !stepTask.items.some((stepTaskItem) => stepTaskItem.value.name === task.name)) {
          // @ts-ignore
          stepTasks[index].items.push({label: task.name, value: { name: task.name, type: 'TASK' }});
        }
      });
    });

    return stepTasks.sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  doesTaskContainImportance(item: any, filterValue: number) {
    if (item) { return item === filterValue; }
    return !filterValue;
  }

  doesTaskContainClient(item: AddonFilterLegalAndNaturalCustomer, filterValue: ClientOptionValue) {
    if (item && filterValue.type === 'naturalPerson') {
      return item.naturalPeople?.some((naturalPerson: NaturalPerson) => naturalPerson.id === filterValue.id);
    }
    if (item && filterValue.type === 'legalPerson') {
      return item.legalPeople?.some((legalPerson: LegalPerson) => legalPerson.id === filterValue.id);
    }
    return !filterValue;
  }

  doesTaskContainPoleOrCollaborator(item: AddonFilterPoleAndCollaborator, filterValue: PoleAndCollaboratorOptionValue) {
    if (item && filterValue.type === 'pole') {
      return item.pole === filterValue.id;
    }
    if (item.collaborator && filterValue.type === 'collaborator') {
      return item.collaborator.id === filterValue.id;
    }
    if (item && filterValue.type === 'other' && filterValue.id === 'MYTASKS') {
      const doesTaskBelongToMe = item.collaborator?.id === this.currentUser.id;
      const doesTaskBelongToMyPole = this.currentUser.poles.includes(item.pole) && !item.collaborator;
      if (this.currentDashboardView === DashboardView.CUSTOMER && this.currentUserHasCustomerPriorityTasks ||
        this.currentDashboardView === DashboardView.INTERNAL && this.currentUserHasInternalPriorityTasks) {
        const doesTaskIsPrioritary = item.isPriority;
        return (doesTaskIsPrioritary && (doesTaskBelongToMe || doesTaskBelongToMyPole));
      } else {
        return doesTaskBelongToMe || doesTaskBelongToMyPole;
      }
    }
    return !filterValue;
  }

  doesTaskContainStepName(item: Step, filterValue: StepAndTaskOptionValue) {
    if (item) {
      // Set step related tasks for task filter options.
      this.stepFilterRelatedTaskOption = this.stepFilterRelatedTaskOption.filter((stepTasks) => stepTasks.label === filterValue.name);
      return item.name === filterValue.name;
    }
    return !filterValue;
  }

  doesTaskContainsTaskName(item: Step['name'], filterValue: StepAndTaskOptionValue) {
    if (item) { return item === filterValue.name; }
    return !filterValue;
  }

  calculateIndicators(tasksCustomer: [] | Task[], tasksInternal: [] | Task[]) {
    // @ts-ignore
    const tasks = tasksCustomer.concat(tasksInternal ? tasksInternal : []);

    const myTasks = tasks.filter(
      (task) =>
        (
          (!task.isPrivate || task.contractor?.id === this.currentUser.id) &&
          (!task.isLeaderPrivate || this.currentUser.roles.some((role) => role === UserRole.ROLE_DIRIGEANT)) &&
          (this.currentUser.poles.includes(task.pole) && !task.collaborator || task.collaborator?.id === this.currentUser.id)
        )
        ||
        (
          (this.currentUser.poles.includes(task.pole) && !task.collaborator || task.collaborator?.id === this.currentUser.id) &&
          !task.isPrivate && !task.isLeaderPrivate
        )
    );

    const prioritary: number = myTasks.filter((task) => task.isPriority && task.status !== TaskStatus.DONE && task.status !== TaskStatus.PLANNED).length;
    const closeDeadline: number = myTasks.filter((task) =>
      this.taskHelperService.calculateDeadlineIndicator(new Date(task.startDate), new Date(task.deadlineDate)) === TaskDeadlineStatus.EXCEED &&
      task.status !== TaskStatus.DONE && task.status !== TaskStatus.PLANNED).length;
    const highImportance: number = myTasks.filter((task) => task.importance === 3 && task.status !== TaskStatus.DONE && task.status !== TaskStatus.PLANNED).length;
    // Validate indicator rule : status = done, related to the current user (not his pole), between today and previous monday.
    const previousMonday = moment().startOf('isoWeek').toDate();
    const validate: number = myTasks.filter((task) =>
      task.status === TaskStatus.DONE && task.collaborator?.id === this.currentUser.id && task.closingDate && new Date(task.closingDate) > previousMonday).length;
    this.tasksIndicators = {prioritary, closeDeadline, highImportance, validate};
  }

  setStorredDashboardView() {
    if (this.uiStoreState.dashboard.view) {this.currentDashboardView = this.uiStoreState.dashboard.view;}
  }

  handleFiltering(filterValue: any, fieldToFilter: DashboardFiltersType, table?: DashboardView) {
    if (fieldToFilter === DashboardFiltersType.IMPORTANCE && table) {
      this.setImportanceFilter(filterValue, table);
    } else if (fieldToFilter === DashboardFiltersType.STEP) {
      // Re-filter step related task option if step filter is changed.
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(this.customerTasks);
      this.setStepFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.STEPTASK) {
      this.setStepTaskFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.CUSTOMER) {
      this.setCustomerFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.COLLABORATORANDPOLE && table) {
      this.setCollaboratorAndPoleFilter(filterValue, table);
    }
  }

  clearFilters(table: DashboardView, event: Event) {
    // If we do not clean each filter we encounter the following problem :
    // the filter is reset but the filter label remains selected in the dropdown.
    if (table === DashboardView.CUSTOMER) {
      this.filterImportanceCustomer.clear(event);
      this.filterSteps.clear(event);
      this.filterTasks.clear(event);
      this.filterCustomer.clear(event);
      this.filterCollaboratorAndPoleCustomer.clear(event);
    }
    if (table === DashboardView.INTERNAL) {
      this.filterImportanceInternal.clear(event);
      this.filterCollaboratorAndPoleInternal.clear(event);
    }
  }

  // Reset task filter after cleared step filter.
  clearFilterTask(event: Event) {
    this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(this.customerTasks);
    this.filterTasks.clear(event);
    this.form.get('stepTaskFilter')?.setValue(undefined);
  }

  // Switch dashboard view and re-init dashboard with appropriate data.
  switchDashboard(desiredTable: DashboardView) {
    this.currentDashboardView = desiredTable;
    this.uiStore.update({dashboard: {view: desiredTable}});
    this.initDashboardsWorkflow();
  }

  onCustomerTagClick(customerId: number, customerType: 'legalPerson' | 'naturalPerson') {
    if (customerType === 'legalPerson') {
      this.router.navigate(['/legal-person/' + customerId]);
    } else if (customerType === 'naturalPerson') {
      this.router.navigate(['/natural-person/' + customerId]);
    }
  }

  translate(word: string) {
    return this.translatorService.instant(word);
  }

  editTask(task: any) {
    this.router.navigate(['/task-customer-edit', task.step.id, task.id]);
  }

  editInternalTask(task: any) {
    this.router.navigate(['/task-internal-edit', task.id]);
  }

  openCloseDialog(task: any) {
    this.closingTask = task;
    this.displayCloseDialog = true;
  }

  newCustomerTask() {
    this.router.navigate(['/task-customer-new']);
  }

  newIntenalTask() {
    this.router.navigate(['/task-internal-new']);
  }
}
