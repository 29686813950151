import {Component, OnInit, ViewChild} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService, Product, ProductQuery, ProductService, UiScreenQuery } from 'common';
import { MessageService } from 'primeng/api';
import { ProductFormComponent } from '../../../components/form/product-form/product-form.component';

@UntilDestroy()
@Component({
  selector: 'app-page-product',
  templateUrl: './page-product.component.html',
  styleUrls: ['./page-product.component.scss']
})
export class PageProductComponent implements OnInit {
  productForm: ProductFormComponent;
  isEdit: boolean;
  product?: Product;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productQuery: ProductQuery,
    private productService: ProductService,
    private messageService: MessageService,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  @ViewChild(ProductFormComponent, { static: false }) set ref(productForm: ProductFormComponent) {
    if(productForm) {
      this.productForm = productForm;
      this.productForm.createFormGroup(this.product ?? undefined);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];

      if (id) {
        this.productQuery.selectEntity(id).pipe(untilDestroyed(this)).subscribe(product => {
          this.isEdit = true;
          this.product = product;
        });
      }
    });
  }

  onSubmit() {
    if (this.productForm.form.invalid) {
      this.productForm.form.markAllAsTouched();
      return;
    }

    let product = this.productForm.form.value;
    product = {...product, fournisseur: {id: product.fournisseur}};

    if (this.isEdit) {
      this.productService.update(this.product?.id, product).subscribe({
        next: () => this.onSuccess(),
        error: () => this.onError(),
      });
    } else {
      this.productService.add<Product>(product).subscribe({
        next: () => this.onSuccess(),
        error: () => this.onError(),
      });
    }
  }

  onSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: this.isEdit ? 'Les modifications ont bien été sauvegardées' : 'Le produit a bien été créé',
      life: 10000
    });

    if (this.isEdit) {
      this.navigation.back();
    } else {
      this.router.navigate(['/product-list']);
    }
  }

  onError() {
    this.messageService.add({
      severity: 'error',
      summary: this.isEdit ? 'Une erreur est survenue lors de la sauvegarde' : 'Une erreur est survenue lors de la création',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }

  onCancel() {
    this.navigation.back();
  }

}
