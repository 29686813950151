<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="typeCredit">Type</label>
      <p-dropdown
        formControlName="typeCredit"
        id="typeCredit"
        [options]="creditTypeOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
    <div class="field col-3">
      <label for="souscripteur">Souscripteur</label>
      <p-dropdown
        formControlName="souscripteur"
        id="souscripteur"
        [options]="souscripteurOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
  </div>
  <app-documents-form
    *ngIf="clientOrAdmin === 'CLIENT'"
    formControlName="docs"
    [clientId]="clientId"
    [clientType]="clientType"
    [documentTypes]="[documentType.CREDIT_AMORTISSEMENT,
              documentType.CREDIT_OFFRE, documentType.AUTRE_CREDIT]"></app-documents-form>
</div>

