<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="assureurBanque">Banque / Assureur</label>
      <input formControlName="assureurBanque" id="assureurBanque" pInputText class="inputfield">
    </div>
    <div class="field col-3" *ngIf="displayDateSouscription">
      <label for="dateSouscription">Date de souscription</label>
      <p-calendar appendTo="body"
                  styleClass="w-full"
                  formControlName="dateSouscription"
                  id="dateSouscription"
                  [firstDayOfWeek]=1
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
      ></p-calendar>
    </div>
    <div class="field col-3">
      <label for="souscripteur">Souscripteur</label>
      <p-dropdown
        formControlName="souscripteur"
        id="souscripteur"
        [options]="souscripteurOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
    <div class="field col-3">
      <label for="value">Valeur</label>
      <p-inputNumber formControlName="valeur" id="value"  mode="decimal" ></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
  </div>
  <div class="grid p-fluid" *ngIf="displayVersementProgramme">
    <div class="field col-3 flex flex-column">
      <label for="versementProgramme">Versements programmés ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.versementProgramme}">Non</div>
        <p-inputSwitch  id="versementProgramme"
                        formControlName="versementProgramme"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.versementProgramme}">Oui</div>
      </div>
    </div>
    <div class="field col-3" *ngIf="value.versementProgramme">
      <label for="montantVersementProgramme">Montant annuel</label>
      <p-inputNumber formControlName="montantVersementProgramme" id="montantVersementProgramme"  mode="decimal"></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-12" *ngIf="value.typeAvoir === avoirFinancierType.AVOIR_FI_ASSURANCE_VIE">
      <label>Clause bénéficiaire</label>
      <app-comment-form formControlName="clauseBeneficiaire"></app-comment-form>
    </div>
  </div>
  <app-documents-form
    *ngIf="clientOrAdmin === 'CLIENT'"
    formControlName="docs"
    [clientId]="clientId"
    [clientType]="clientType"
    [documentTypes]="documentTypes"></app-documents-form>
</div>
