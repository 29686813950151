<div *ngIf="fieldError.required" class="invalid-feedback p-mt-2">Le champ est obligatoire</div>
<div *ngIf="fieldError.email" class="invalid-feedback p-mt-2">L'email saisi n'est pas valide</div>
<div *ngIf="fieldError.pattern" class="invalid-feedback p-mt-2">{{ patternErrorText }}</div>
<div *ngIf="fieldError.min" class="invalid-feedback p-mt-2">La valeur ne peut pas être inférieur à {{fieldError.min.min}}</div>
<div *ngIf="fieldError.max" class="invalid-feedback p-mt-2">La valeur ne peut pas être supérieur à {{fieldError.max.max}}</div>
<div *ngIf="fieldError.minlength" class="invalid-feedback p-mt-2">{{fieldError.minlength.actualLength}}/{{fieldError.minlength.requiredLength}} caractères minimum</div>
<div *ngIf="fieldError.maxlength" class="invalid-feedback p-mt-2">{{fieldError.maxlength.actualLength}}/{{fieldError.maxlength.requiredLength}} caractères maximum</div>
<div *ngIf="fieldError.needTasks" class="invalid-feedback p-mt-2">Vous devez sélectionner au moins une tâche du workflow, ou en ajouter une nouvelle.</div>
<div *ngIf="fieldError.validatePhoneNumber" class="invalid-feedback p-mt-2">Le format du numéro n'est pas valide.</div>
<!-- Custom validator page activate account -->
<div *ngIf="fieldError.notSamePassword" class="invalid-feedback p-mt-2">Les deux mots de passe doivent être identiques</div>
<div *ngIf="fieldError.weakPassword" class="invalid-feedback p-mt-2">Votre mot de passe doit faire au moins 12 caractères et inclure : une minuscule, une majuscule et un chiffre</div>
<div *ngIf="fieldError.customerStatusIsClientOrProspect" class="invalid-feedback p-mt-2">Une personne morale ne peut pas être cliente et prospecte à la fois</div>
<!-- Custom validator page informations -->
<div *ngIf="fieldError.phoneContact" class="invalid-feedback p-mt-2">Vous devez définir un téléphone de contact</div>
<div *ngIf="fieldError.emailContact" class="invalid-feedback p-mt-2">Vous devez définir un email de contact</div>
<div *ngIf="fieldError.addressContact && !fieldError.addressFiscale" class="invalid-feedback p-mt-2">Vous devez définir une adresse de contact</div>
<div *ngIf="fieldError.addressFiscale && !fieldError.addressContact" class="invalid-feedback p-mt-2">Vous devez définir une adresse fiscale</div>
<div *ngIf="fieldError.addressFiscale && fieldError.addressContact" class="invalid-feedback p-mt-2">Vous devez définir une adresse fiscale et de contact</div>
