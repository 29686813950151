import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { TaskImportance, TranslatorService} from 'common';
import {UntilDestroy} from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-importance-input',
  templateUrl: './importance-input.component.html',
  styleUrls: ['./importance-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ImportanceInputComponent)
  }]
})
export class ImportanceInputComponent implements  ControlValueAccessor {

  @Input() radioName: string;
  selected!: number;
  importanceOptions: { label: string; value: any }[] = [];

  constructor(private translatorService: TranslatorService) {
    const taskImportance = Object.values(TaskImportance);
    this.translatorService.getTranslation(taskImportance).subscribe(taskImportances=>{
      this.importanceOptions = [];
      Object.entries(taskImportances).map((label, index)=>{
        this.importanceOptions = [
          ...this.importanceOptions,
          {
            label: label[1] as string,
            value: index + 1,
          },
        ];
      });
    });
  }


  public propagateChange: any = () => {};
  public propagateTouched: any = () => {};
  public propagateDisable: any = () => {};

  select(value: number){
    this.selected = value;
    this.propagateChange(value);
  }

  writeValue(value: number): void {
    this.selected = value;
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.propagateDisable = isDisabled;
  }
}
