import {Component, OnInit} from '@angular/core';
import {UiScreenQuery} from 'common';
import {LocationStrategy} from '@angular/common';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-recueil-final',
  templateUrl: './recueil-final.component.html',
  styleUrls: ['./recueil-final.component.scss']
})
export class RecueilFinalComponent implements OnInit {
  content = '';

  constructor(public uiScreenQuery: UiScreenQuery, private location: LocationStrategy, private route: ActivatedRoute) {
    history.pushState(null, '', window.location.href);
    // check if back or forward button is pressed.
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
    });
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          this.content = params['person'] === 'conjoint' ? 'Vos informations ont été enregistrées' : 'Vous pouvez modifier vos informations depuis votre espace.<br>\n' +
            'Nous allons étudier votre situation, nous nous voyons vite pour la suite';
        }
      );
  }
}
