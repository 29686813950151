<h2 class="mt-4">Liste des étapes</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop class="w-11 m-auto">
  <p-table #dt [value]="stepMasterList" [loading]="loading"
           [globalFilterFields]="['name']" responsiveLayout="scroll"
           [rowHover]="true" dataKey="id" currentPageReportTemplate="{first} à {last} de {totalRecords}"
           [showCurrentPageReport]="true"
           [scrollHeight]="loading? '500px' : ''"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="px-5">
          Étapes
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-stepMaster>
      <tr>
        <td class="px-5 w-full">{{stepMaster.name}}</td>
        <td>
          <p-button icon="pi pi-pencil" styleClass="p-button-raised p-button-text"
                    (click)="editStepMaster(stepMaster)"></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
