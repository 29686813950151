import {Component, forwardRef, Input, OnDestroy, OnInit,} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AvoirFinancier, AvoirFinancierType, DocumentCodeType, DropdownOption, DropdownOptionsService,DocumentType} from 'common';
import {ID} from '@datorama/akita';

@Component({
  selector: 'app-financier-form',
  templateUrl: './financier-form.component.html',
  styleUrls: ['./financier-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FinancierFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FinancierFormComponent),
      multi: true
    }
  ]
})
export class FinancierFormComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() documentTypes: DocumentCodeType[];
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  souscripteurOptions: DropdownOption<any>[] = [];
  avoirFinancierType = AvoirFinancierType;

  initialValues = {
    id: null,
    typeAvoir: null,
    assureurBanque: null,
    dateSouscription: null,
    souscripteur: null,
    valeur: null,
    versementProgramme: null,
    montantVersementProgramme: null,
    clauseBeneficiaire: null,
    docs: null,
  };
  constructor(private formBuilder: UntypedFormBuilder, private dropdownOptionsService: DropdownOptionsService,) {}

  get displayDateSouscription(){
    return this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEL ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEA ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CROWDFUNDING ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEA_PME ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_FIP ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_FCPI ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_FCPR ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PART_HOLDING ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_ASSURANCE_VIE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEP ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_AUTRE;
  }
  get displayVersementProgramme(){
    return this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_CHEQUE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_LIVRET ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_A ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_JEUNE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_EPARGNE_POPULAIRE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_EPARGNE_LOGEMENT ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_A_TERME ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_AUTRE_DISPONIBILITE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEL ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_ASSURANCE_VIE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEP ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PER ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PERCO ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_RETRAITE_MADELIN ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEE ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEI ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PREFON ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_PERP ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_ART_83 ||
      this.value.typeAvoir === this.avoirFinancierType.AVOIR_FI_AUTRE;
  }

  get value(): AvoirFinancier {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        // docs conditionnés en fonction du type d'avoir
        if (this.documentTypes &&
          (value.typeAvoir !== AvoirFinancierType.AVOIR_FI_COMPTE_CHEQUE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_COMPTE_LIVRET &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_LIVRET_A &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_LIVRET_JEUNE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_LIVRET_EPARGNE_POPULAIRE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_COMPTE_EPARGNE_LOGEMENT &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_COMPTE_A_TERME &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_AUTRE_DISPONIBILITE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEL) &&
          this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_RELEVE_COMPTE.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.FINANCIER_RELEVE_COMPTE.code);
        }
        if (this.documentTypes &&
          (value.typeAvoir !== AvoirFinancierType.AVOIR_FI_FIP &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_FCPR &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_FCPI &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PART_HOLDING &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON) &&
          this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_TITRE_PROPRIETE.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.FINANCIER_TITRE_PROPRIETE.code);
        }
        if (this.documentTypes &&
          (value.typeAvoir !== AvoirFinancierType.AVOIR_FI_COMPTE_TITRE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEA &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEA_PME &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_ASSURANCE_VIE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEP &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PER &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PERCO &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_CONTRAT_RETRAITE_MADELIN &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEE &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PEI &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PREFON &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_PERP &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_ART_83 &&
          value.typeAvoir !== AvoirFinancierType.AVOIR_FI_AUTRE) &&
          this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_RELEVE_SITUATION.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.FINANCIER_RELEVE_SITUATION.code);
        }

        if (this.documentTypes &&
          (value.typeAvoir === AvoirFinancierType.AVOIR_FI_COMPTE_CHEQUE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_COMPTE_LIVRET ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_LIVRET_A ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_LIVRET_JEUNE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_LIVRET_EPARGNE_POPULAIRE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_COMPTE_EPARGNE_LOGEMENT ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_COMPTE_A_TERME ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_AUTRE_DISPONIBILITE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEL) &&
          !this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_RELEVE_COMPTE.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.FINANCIER_RELEVE_COMPTE];
        }
        if (this.documentTypes &&
          (value.typeAvoir === AvoirFinancierType.AVOIR_FI_FIP ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_FCPR ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_FCPI ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PART_HOLDING ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON) &&
          !this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_TITRE_PROPRIETE.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.FINANCIER_TITRE_PROPRIETE];
        }
        if (this.documentTypes &&
          (value.typeAvoir === AvoirFinancierType.AVOIR_FI_COMPTE_TITRE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEA ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEA_PME ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_ASSURANCE_VIE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEP ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PER ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PERCO ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_CONTRAT_RETRAITE_MADELIN ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEE ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PEI ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PREFON ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_PERP ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_ART_83 ||
          value.typeAvoir === AvoirFinancierType.AVOIR_FI_AUTRE) &&
          !this.documentTypes?.find(docType => docType.code === DocumentType.FINANCIER_RELEVE_SITUATION.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.FINANCIER_RELEVE_SITUATION];
        }
        if (value.typeAvoir === AvoirFinancierType.AVOIR_FI_CROWDFUNDING){
          this.documentTypes = [];
        }
        //on remet les documents dans leur objet initial
        const { docs, ...rest } = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
    this.souscripteurOptions = this.dropdownOptionsService.souscripteurAvoirFinancier;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      //on place les documents dans un objet docs pour qu'ils soient gérés par app-documents-form
      const { releveCompte,
        releveSituation,
        titrePropriete,
        otherDocs,
        ...rest } = value;
      this.value = {...rest, docs: {
          releveCompte: value.releveCompte,
          releveSituation: value.releveSituation,
          titrePropriete: value.titrePropriete,
          otherDocs: value.otherDocs,
        }};
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
}
