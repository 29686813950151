import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionQuery, SessionService, UiScreenQuery, UserRole} from 'common';
import {combineQueries, resetStores} from '@datorama/akita';
import {untilDestroyed} from '@ngneat/until-destroy';

@Component({
  selector: 'app-page-login',
  templateUrl: './page-login.component.html',
  styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
  loginForm!: UntypedFormGroup;
  loading = false;
  willRedirectToRecueil = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    public uiScreenQuery: UiScreenQuery,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if(this.route.snapshot.queryParamMap.get('returnUrl')?.startsWith('/recueil')){
      this.willRedirectToRecueil = true;
    }
    resetStores({ exclude: ['uiScreen'] });
    this.initForm();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  onAuth() {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    this.sessionService.login({username: this.loginForm.value.username, password: this.loginForm.value.password}).subscribe({
      next: (success) => {
        this.loading = false;
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if(this.sessionQuery.getValue().roles.every((role: string) => role === UserRole.ROLE_CLIENT) && this.sessionQuery.getValue().id){
          //ACCES CLIENT
          if(returnUrl){
            this.router.navigate([returnUrl]);
          } else {
            this.router.navigate(['/recueil/NATURAL_PERSON/'+this.sessionQuery.getValue().id], {replaceUrl:true});
          }
        } else {
          //ACCES ADMIN
          if(returnUrl){
            this.router.navigate([returnUrl]);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        this.loginForm.reset({
          username: null,
          password: null
        });
        // this.messageService.add({
        //   severity: 'success',
        //   summary: 'Bienvenue ' + success.firstname + ' ' + success.lastname?.slice(0,1) + '.',
        //   life: 10000
        // });
      },
      error: (response) => {
        this.loading = false;
      },
    });
  }

  /* get form from template */
  get usernameF() { return this.loginForm?.get('username');}
  get passwordF() { return this.loginForm?.get('password');}
}
