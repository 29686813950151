import {Router} from '@angular/router';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FilterService} from 'primeng/api';
import {Table} from 'primeng/table';
import {Dropdown} from 'primeng/dropdown';
import {combineQueries} from '@datorama/akita';
import {
  ClientOptionValue,
  DashboardFiltersType,
  DropdownOption,
  DropdownOptionsService,
  LegalPerson,
  MessageService,
  MondayDashboard,
  MondayDashboardTaskType,
  NaturalPerson,
  PoleAndCollaboratorOptionValue,
  PoleInterne,
  Step,
  StepAndTaskOptionValue,
  StepQuery,
  StepService,
  Task,
  TaskHelperService,
  TaskQuery,
  TaskService,
  TaskStatus,
  TranslatorService,
  UiQuery,
  UiState,
  UiStore,
  User,
  UiScreenQuery,
} from 'common';
import * as moment from 'moment';

// Object shape added to task to allow filtering by pole / collaborator / my tasks.
type AddonFilterCollaboratorAndPole = {
  pole: PoleInterne;
  collaborator?: User;
  isPriority: boolean;
  status: TaskStatus;
};

// Object shape added to task to allow filtering by customer.
type AddonFilterLegalAndNaturalCustomer = {
  nameLabel: string;
  naturalPeople: [] | NaturalPerson[];
  legalPeople: [] | LegalPerson[];
};

// Object shape added to task to allow filtering by step and task name.
type AddonFilterStepAndTaskName = {
  relatedStep: string;
  taskName: string;
};

// Final form of a task.
interface DashboardTask extends Task {
  step?: Step;
  groupIndex?: number;
  taskIndex?: number;
  poleAndCollaborator?: AddonFilterCollaboratorAndPole;
  legalAndNaturalCustomer?: AddonFilterLegalAndNaturalCustomer;
  stepAndTaskName?: AddonFilterStepAndTaskName;
}

@UntilDestroy()
@Component({
  selector: 'app-page-monday-review',
  templateUrl: './page-monday-review.component.html',
  styleUrls: ['./page-monday-review.component.scss']
})
export class PageMondayReviewComponent implements OnInit {
  @ViewChild('mondayReviewTableDone', {static: false}) mondayReviewTableDone: Table;
  @ViewChild('mondayReviewTableProgress', {static: false}) mondayReviewTableProgress: Table;
  @ViewChild('filterImportanceDashboardDone', {static: false}) filterImportanceDashboardDone: Dropdown;
  @ViewChild('filterImportanceDashboardProgress', {static: false}) filterImportanceDashboardProgress: Dropdown;
  @ViewChild('filterStepDashboardDone', {static: false}) filterStepDashboardDone: Dropdown;
  @ViewChild('filterStepDashboardProgress', {static: false}) filterStepDashboardProgress: Dropdown;
  @ViewChild('filterTaskDashboardDone', {static: false}) filterTaskDashboardDone: Dropdown;
  @ViewChild('filterTaskDashboardProgress', {static: false}) filterTaskDashboardProgress: Dropdown;
  @ViewChild('filterCustomerDashboardDone', {static: false}) filterCustomerDashboardDone: Dropdown;
  @ViewChild('filterCustomerDashboardProgress', {static: false}) filterCustomerDashboardProgress: Dropdown;
  @ViewChild('filterCollaboratorAndPoleDashboardDone', {static: false}) filterCollaboratorAndPoleDashboardDone: Dropdown;
  @ViewChild('filterPoleDashboardProgress', {static: false}) filterPoleDashboardProgress: Dropdown;
  @ViewChild('filterCollaboratorDashboardProgress', {static: false}) filterCollaboratorDashboardProgress: Dropdown;

  uiStoreState: UiState;
  currentMondayDashboardView: MondayDashboard = MondayDashboard.DONE;
  allCustomerTasks: [] | Task[] = [];
  allInternalTasks: [] | Task[] = [];
  doneTasks: [] | Task[];
  progressTasks: [] | DashboardTask[];
  emdMaximumNumberOfMonthsForTasksCompleted = 2;
  currentDate: Date;
  currentDatePreviousMonday: Date ;
  currentDateNextMonday: Date;
  mondayWeekdayTwoMonthsAgoDate: Date;
  mondayWeekdayTwoMonthsAgoString: string;
  mondayDashboardDoneTaskTypeFilter: undefined | MondayDashboardTaskType;
  mondayDashboardProgressTaskTypeFilter: undefined | MondayDashboardTaskType;
  mondayDashboardTaskType = MondayDashboardTaskType;

  // Reference for use enum in HTML.
  mondayDashboardView = MondayDashboard;
  dashboardFiltersType = DashboardFiltersType;
  taskStatus = TaskStatus;

  // Dropdown options.
  importanceOptions: DropdownOption[];
  stepsOptions: DropdownOption<StepAndTaskOptionValue>[];
  filteredStepsOptions: DropdownOption<StepAndTaskOptionValue>[];
  stepRelatedTaskOptions: DropdownOption<any>[];
  customerOptions: DropdownOption<ClientOptionValue>[];
  filteredCustomerOptions: DropdownOption<ClientOptionValue>[];
  poleAndCollaboratorOptions: DropdownOption<any>[];
  poleOptions: DropdownOption<any>[];
  collaboratorOptions: DropdownOption<any>[];
  rowCollaboratorOptions: DropdownOption<any>[];

  // The form is used for set fitler label in dropdown.
  form = this.formBuilder.group({
    datePickerDashboardDone: [],
    datePickerDashboardProgress: [],
    importanceFilterDashboardDone: [],
    importanceFilterDashboardProgress: [],
    stepFilterDashboardDone: [],
    stepFilterDashboardProgress: [],
    taskFilterDashboardDone: [],
    taskFilterDashboardProgress: [],
    customerFilterDashboardDone: [],
    customerFilterDashboardProgress: [],
    collaboratorAndPoleFilterDashboardDone: [],
    poleFilterDashboardProgress: [],
    collaboratorFilterDashboardProgress: [],
    rowPoleDropdownDashboardProgress: this.formBuilder.array([[null]]),
    rowCollaboratorDropdownDashboardProgress: this.formBuilder.array([[null]]),
  });

  constructor(
    private uiStore: UiStore,
    private stepQuery: StepQuery,
    private taskQuery: TaskQuery,
    private router: Router,
    private dropdownOptionsService: DropdownOptionsService,
    private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    private translatorService: TranslatorService,
    private taskHelperService: TaskHelperService,
    private uiQuery: UiQuery,
    public uiScreenQuery: UiScreenQuery,
    private taskService: TaskService,
    private messageService: MessageService,
    private changeDetectorRef: ChangeDetectorRef,
    private stepService: StepService
  ) {
  }

  get rowPoleDropdownDashboardProgress() { return this.form.get('rowPoleDropdownDashboardProgress') as UntypedFormArray; };
  get rowCollaboratorDropdownDashboardProgress() { return this.form.get('rowCollaboratorDropdownDashboardProgress') as UntypedFormArray; };

  ngOnInit(): void {
    this.setDates();
    this.setTableFilters();
    this.getUiStoreState();
    this.getAllTasks();
    this.setDropdownOptions();
  }

  // Set application dates.
  setDates() {
    // Today at 00h00m00s.
    this.currentDate = this.setDateTime(new Date());
    // Previous monday at 00h00m00s.
    this.currentDatePreviousMonday = moment().startOf('isoWeek').toDate();
    // Next monday at 00h00m00s.
    this.currentDateNextMonday = moment(moment().add(1, 'weeks')).startOf('isoWeek').toDate();
    // Previous monday two months ago at 00h00m00s.
    this.mondayWeekdayTwoMonthsAgoDate = moment(moment().subtract(this.emdMaximumNumberOfMonthsForTasksCompleted, 'months')).startOf('isoWeek').toDate();
    this.mondayWeekdayTwoMonthsAgoString = moment(moment().subtract(this.emdMaximumNumberOfMonthsForTasksCompleted, 'months')).startOf('isoWeek').format('DD-MM-YYYY');
  }

  // Set date time at 00h00m00s.
  setDateTime(date: Date): Date {
    return new Date(date.setHours(0,0,0,0));
  }

  // Set custom table filters.
  setTableFilters() {
    this.filterService.register('containsImportance', (value: number, filter: number): boolean => this.doesTaskContainImportance(value, filter));
    this.filterService.register('containsStep', (value: Step, filter: StepAndTaskOptionValue): boolean => this.doesTaskContainStep(value, filter));
    this.filterService.register('containsStepTask', (value: any, filter: any): boolean => this.doesTaskContainsTaskName(value, filter));
    this.filterService.register('containsCustomer', (value: AddonFilterLegalAndNaturalCustomer, filter: ClientOptionValue): boolean => this.doesTaskContainClient(value, filter));
    // eslint-disable-next-line max-len
    this.filterService.register('containsCollaboratorOrPole', (value: AddonFilterCollaboratorAndPole, filter: PoleAndCollaboratorOptionValue): boolean => this.doesTaskContainCollaboratorOrPole(value, filter));
    this.filterService.register('containsPole', (value: any, filter: any): boolean => this.doesTaskContainPole(value, filter));
    this.filterService.register('containsCollaborator', (value: any, filter: any): boolean => this.doesTaskContainCollaborator(value, filter));
  }

  // Get all needed data.
  getAllTasks() {
    /* eslint-disable */
    this.taskService.get({
      params: {'status': 'DONE', 'closingDate[strictly_after]': this.mondayWeekdayTwoMonthsAgoString}, upsert: true
    }).subscribe();

    this.stepService.get({
      params: {'status': 'DONE', 'closingDate[strictly_after]': this.mondayWeekdayTwoMonthsAgoString}, upsert: true
    }).subscribe();
    /* eslint-enable */

    combineQueries([
        this.stepQuery.selectAll({
          filterBy: [
            (step) => step.stepGroups.some((group) => group.tasks.some((task) => (!task.isPrivate || !task.isLeaderPrivate)))
          ]
        }).pipe(untilDestroyed(this)),
        this.taskQuery.selectAll({
          filterBy: [
            (task) => task.isInternal && task.status !== '' && !task.isPrivate && !task.isLeaderPrivate
          ]
        }).pipe(untilDestroyed(this))
      ]
    ).pipe(untilDestroyed(this)).subscribe(([customerStepTasks, internalTasks]) => {
        if (customerStepTasks) {
          let resCustomerTasks: any = [];
          // Add naturalPersons, legalPersons, step and group information from step into task.
          customerStepTasks.map((step) => {
            step.stepGroups.map((group, groupIndex) => {
              group.tasks.map((task, taskIndex) => {
                resCustomerTasks = [...resCustomerTasks, {
                  ...task,
                  step,
                  groupIndex,
                  taskIndex,
                  naturalPersons: step.naturalPersons ?? [],
                  legalPersons: step.legalPersons ?? [],
                }];
              });
            });
            this.allCustomerTasks = resCustomerTasks;
          });
        }
        if (internalTasks) {
          let resInternalTasks: any = [];
          internalTasks.map((task) => {
            resInternalTasks = [...resInternalTasks, {
              ...task,
              step: { name: 'Tâche interne' }
            }];
          });
          this.allInternalTasks = resInternalTasks;
        }
        this.initDashboardsWorkflow();
      }
    );
  }

  // Get UI store data.
  getUiStoreState() {
    this.uiQuery.select().pipe(untilDestroyed(this)).subscribe((uiStoreState) => {
      if (uiStoreState) {
        this.uiStoreState = uiStoreState;
      }
    });
  }

  // Dashboard workflow from database data to displayed data.
  initDashboardsWorkflow() {
    // [1/7] Set the appropriate monday dashboard view depending on the ui stored date.
    if (this.uiStoreState.mondayDashboard.view) {
      this.currentMondayDashboardView = this.uiStoreState.mondayDashboard.view;
    }
    if (this.uiStoreState.mondayDashboard.doneTaskType) {
      this.mondayDashboardDoneTaskTypeFilter = this.uiStoreState.mondayDashboard.doneTaskType;
    }
    if (this.uiStoreState.mondayDashboard.progressTaskType) {
      this.mondayDashboardProgressTaskTypeFilter = this.uiStoreState.mondayDashboard.progressTaskType;
    }
    // [2/7] Concat customer and internal tasks.
    const allTasks = this.allCustomerTasks.concat(this.allInternalTasks as never[]);
    // [3/7] Init to previous monday or ui stored date
    if (this.uiStoreState.mondayDashboard.progressDate) {
      this.form.get('datePickerDashboardProgress')?.setValue(moment(this.uiStoreState.mondayDashboard.progressDate).toDate());
    } else {
      this.form.get('datePickerDashboardProgress')?.setValue(this.currentDateNextMonday);
    }
    if (this.uiStoreState.mondayDashboard.doneDate) {
      this.form.get('datePickerDashboardDone')?.setValue(moment(this.uiStoreState.mondayDashboard.doneDate).toDate());
    } else {
      this.form.get('datePickerDashboardDone')?.setValue(this.currentDatePreviousMonday);
    }
    // [4/7] Filter and sort tasks.
    const filteredDoneTasks = this.tasksSorter(this.tasksFilter(allTasks, MondayDashboard.DONE), 'ASC');
    const filteredProgressTasks = this.tasksSorter(this.tasksFilter(allTasks, MondayDashboard.PROGRESS), 'ASC');
    // [5/7] Add appropriate properties to tasks for filtering.
    this.doneTasks = this.addPropertiesToTasks(filteredDoneTasks);
    this.progressTasks = this.addPropertiesToTasks(filteredProgressTasks);
    // [6/7] Apply filters on filter dropdown options.
    if (this.currentMondayDashboardView === MondayDashboard.DONE && this.doneTasks.length > 0) {
      this.filteredCustomerOptions = this.filtercustomerOptions(this.doneTasks, this.customerOptions);
      this.filteredStepsOptions = this.filterStepOptions(this.doneTasks, this.stepsOptions);
      this.stepRelatedTaskOptions = this.filterStepTasksOptions(this.doneTasks, MondayDashboard.DONE);
    }
    if (this.currentMondayDashboardView === MondayDashboard.PROGRESS && this.progressTasks.length > 0) {
      this.filteredCustomerOptions = this.filtercustomerOptions(this.progressTasks, this.customerOptions);
      this.filteredStepsOptions = this.filterStepOptions(this.progressTasks, this.stepsOptions);
      this.stepRelatedTaskOptions = this.filterStepTasksOptions(this.progressTasks, MondayDashboard.PROGRESS);
    }
    // [7/7] Set row pole and collaborator dropdown.
    this.setRowTaskDropdowns();
    this.changeDetectorRef.detectChanges();
  }

  // Apply table storage data (filters, pagination...) on table initialization.
  onDashboardStateRestore(event: any, table: MondayDashboard) {
    if (event.filters && table === this.mondayDashboardView.DONE) {
      if (event.filters.importance) {this.form.get('importanceFilterDashboardDone')?.setValue(event.filters.importance.value);}
      if (event.filters.step) {this.form.get('stepFilterDashboardDone')?.setValue(event.filters.step.value);}
      if (event.filters.name) {this.form.get('taskFilterDashboardDone')?.setValue(event.filters.name.value);}
      if (event.filters.legalAndNaturalCustomer) {this.form.get('customerFilterDashboardDone')?.setValue(event.filters.legalAndNaturalCustomer.value);}
      if (event.filters.poleAndCollaborator) {this.form.get('collaboratorAndPoleFilterDashboardDone')?.setValue(event.filters.poleAndCollaborator.value);}
    }
    if (event.filters && table === this.mondayDashboardView.PROGRESS) {
      if (event.filters.importance) {this.form.get('importanceFilterDashboardProgress')?.setValue(event.filters.importance.value);}
      if (event.filters.step) {this.form.get('stepFilterDashboardProgress')?.setValue(event.filters.step.value);}
      if (event.filters.name) {this.form.get('taskFilterDashboardProgress')?.setValue(event.filters.name.value);}
      if (event.filters.legalAndNaturalCustomer) {this.form.get('customerFilterDashboardProgress')?.setValue(event.filters.legalAndNaturalCustomer.value);}
      if (event.filters.pole) {this.form.get('poleFilterDashboardProgress')?.setValue(event.filters.pole.value);}
      if (event.filters.collaborator) {this.form.get('collaboratorFilterDashboardProgress')?.setValue(event.filters.collaborator.value);}
    }
  }

  // Set filter dropdowns options.
  setDropdownOptions() {
    this.importanceOptions = this.dropdownOptionsService.tasksImportance;
    this.stepsOptions = this.dropdownOptionsService.steps;
    this.stepRelatedTaskOptions = this.dropdownOptionsService.stepsTasks;
    this.customerOptions = this.dropdownOptionsService.clients;
    this.poleAndCollaboratorOptions = this.dropdownOptionsService.polesAndCollaborators.filter((option) => option.value !== 'other');
    this.poleOptions = this.dropdownOptionsService.poles;
    this.collaboratorOptions = this.dropdownOptionsService.collaborators;

    // adding the 'non assigned collaborator' option
    this.collaboratorOptions.unshift({label: 'Aucun', value: 0});

    const collaboratorOptions = this.dropdownOptionsService.collaborators;
    // @ts-ignore
    collaboratorOptions.unshift({label: 'Aucun', value: null});
    this.rowCollaboratorOptions = collaboratorOptions;
  }

  // Filter tasks depending on the monday dashboard view.
  tasksFilter(tasks: [] | Task[], table: MondayDashboard): [] | Task[] {
    if (table === MondayDashboard.DONE) {
      // Pre-filter task type.
      if (this.mondayDashboardDoneTaskTypeFilter && this.mondayDashboardDoneTaskTypeFilter === MondayDashboardTaskType.INTERNAL) {
        tasks = tasks.filter((task: Task) => task.isInternal);
      }
      if (this.mondayDashboardDoneTaskTypeFilter && this.mondayDashboardDoneTaskTypeFilter === MondayDashboardTaskType.CUSTOMER) {
        tasks = tasks.filter((task: Task) => !task.isInternal);
      }

      // Task with status DONE, with a closing date and a closing date superior to the datePickerDashboardDone value.
      return tasks.filter((task: Task) => task.status === TaskStatus.DONE && task.closingDate && new Date(task.closingDate) > new Date(this.form.get('datePickerDashboardDone')?.value));
    }

    if (table === MondayDashboard.PROGRESS) {
      // Pre-filter task type.
      if (this.mondayDashboardProgressTaskTypeFilter && this.mondayDashboardProgressTaskTypeFilter === MondayDashboardTaskType.INTERNAL) {
        tasks = tasks.filter((task: Task) => task.isInternal);
      }
      if (this.mondayDashboardProgressTaskTypeFilter && this.mondayDashboardProgressTaskTypeFilter === MondayDashboardTaskType.CUSTOMER) {
        tasks = tasks.filter((task: Task) => !task.isInternal);
      }

      // Task without status DONE or PLANNED, with a start date inferior to the datePickerDashboardProgress value
      // and not hiddenUntil next monday or whith a hiddenUntil date inferior to week current monday.
      return tasks.filter((task: Task) => ![TaskStatus.DONE, TaskStatus.PLANNED].includes(task.status as TaskStatus)
        && new Date(task.startDate) < new Date(this.form.get('datePickerDashboardProgress')?.value)
        && ((task.hiddenUntil && new Date(task.hiddenUntil) <= new Date() || !task.hiddenUntil)));
    }
    return tasks;
  }

  // Sort tasks for table display.
  tasksSorter(tasks: Task[], sort: 'ASC' | 'DESC' = 'DESC') {
    // Important to keep the less meaning sorting first and the most meaning last.
    if (this.currentMondayDashboardView === MondayDashboard.DONE) {
      return tasks.sort((taskA, taskB) =>
        this.abstractTaskSorter(taskA.closingDate, taskB.closingDate, sort)
      );
    }
    if (this.currentMondayDashboardView === MondayDashboard.PROGRESS) {
      const tasksSortedByDeadLine = tasks.sort((taskA, taskB) =>
        this.abstractTaskSorter(taskA.deadlineDate, taskB.deadlineDate, sort)
      );
      return tasksSortedByDeadLine.sort((taskA, taskB) =>
        this.abstractTaskSorter(taskA.startDate, taskB.startDate, sort)
      );
    }

    return tasks;
  }

  // Abstract task sorter.
  abstractTaskSorter(taskA: any, taskB: any, sort: 'ASC' | 'DESC' = 'DESC') {
    if (taskA > taskB) { return sort === 'DESC' ? -1 : +1; }
    else if (taskA < taskB) { return sort === 'DESC' ? +1 : -1; }
    else { return 0; }
  }

  // Add properties to tasks for filter system.
  addPropertiesToTasks(tasks: Task[]): DashboardTask[] {
    const tasksWithCustomerProperty = this.addLegalAndNaturalPersonKeyToTasks(tasks);
    const tasksWithPoleAndCollaborator = this.addPoleAndCollaboratorKeyToTasks(tasksWithCustomerProperty);
    return this.addDeadlineProgressStatusKeyToTasks(tasksWithPoleAndCollaborator);
  }

  // See addPropertiesToTasks.
  addPoleAndCollaboratorKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      poleAndCollaborator: {
        pole: task.pole as PoleInterne,
        collaborator: task.collaborator,
        isPriority: task.isPriority,
        status: task.status as TaskStatus,
      },
    }));
  }

  // See addPropertiesToTasks.
  addDeadlineProgressStatusKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      deadlineProgress: this.taskHelperService.calculateDeadlineIndicator(
        new Date(task.startDate),
        new Date(task.deadlineDate)
      ),
    }));
  }

  // See addPropertiesToTasks.
  addLegalAndNaturalPersonKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => {
      // The alphabetical sorting of customers must be done on the same field regardless of whether the task is linked
      // to a natural or legal person, for this we create an intermediate "nameLabel" field on which to rely for sorting.
      let nameLabel = '';
      if (task.naturalPersons && task.naturalPersons[0]) {
        nameLabel = task.naturalPersons[0].lastName;
      } else if (task.legalPersons && task.legalPersons[0]) {
        nameLabel = task.legalPersons[0].name;
      }
      return {
        ...task,
        legalAndNaturalCustomer: {
          nameLabel,
          legalPeople: task.legalPersons,
          naturalPeople: task.naturalPersons,
        },
      };
    });
  }

  // Filters customer options to keep table tasks related customers.
  filtercustomerOptions(tasks: DashboardTask[], customerOptions: DropdownOption<ClientOptionValue>[]): DropdownOption<ClientOptionValue>[] {
    let customersWithTaskInDashboard: [] | ({personType: string} & NaturalPerson | {personType: string} & LegalPerson)[] = [];

    tasks.map((task) => {
      task.legalAndNaturalCustomer?.legalPeople?.map((legalPerson) => {
        if (!customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
          customerWithTaskInDashboard.id === legalPerson.id)) {
          customersWithTaskInDashboard = [...customersWithTaskInDashboard, { ...legalPerson, personType: 'legalPerson'},];
        }
      });
    });

    tasks.map((task) => {
      task.legalAndNaturalCustomer?.naturalPeople?.map((naturalPerson) => {
        if (!customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
          customerWithTaskInDashboard.id === naturalPerson.id)) {
          customersWithTaskInDashboard = [...customersWithTaskInDashboard, { ...naturalPerson, personType: 'naturalPerson'}];
        }
      });
    });

    return customerOptions.filter((clientOption: DropdownOption<ClientOptionValue>) => customersWithTaskInDashboard.some((customerWithTaskInDashboard: {personType: string} & NaturalPerson | {personType: string} & LegalPerson) =>
      (clientOption.value.id === customerWithTaskInDashboard.id && clientOption.value.type === customerWithTaskInDashboard.personType)
    )).sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  // Filters step options to keep table tasks related steps.
  filterStepOptions(tasks: DashboardTask[], stepOptions: DropdownOption<StepAndTaskOptionValue>[]): DropdownOption<StepAndTaskOptionValue>[] {
    return stepOptions.filter((stepOption) => tasks.some((task) => stepOption.value.name === task.step?.name))
      .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  // Filters task options to keep table tasks related tasks.
  filterStepTasksOptions(tasks: DashboardTask[], table: MondayDashboard): DropdownOption<StepAndTaskOptionValue>[] {
    let stepTasks: [] | DropdownOption<StepAndTaskOptionValue>[] = [];

    tasks.map((task) => {
      if (!stepTasks.some((stepTask: DropdownOption<StepAndTaskOptionValue>) => stepTask.value.name === task.step?.name) && task.step?.name) {
        stepTasks = [...stepTasks, { label: task.step?.name, value: { name: task.step?.name, type: 'STEP' }, items: [] }];
      }

      stepTasks.map((stepTask, index) => {
        if (task.step && stepTask.value.name === task.step.name && stepTask.items && !stepTask.items.some((stepTaskItem) => stepTaskItem.value.name === task.name)) {
          // @ts-ignore
          stepTasks[index].items.push({label: task.name, value: { name: task.name, type: 'TASK' }});
        }
      });
    });

    const sortFunction = (resultA: any, resultB: any) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'});

    if (table === MondayDashboard.DONE && this.form.get('stepFilterDashboardDone')?.value) {
      return stepTasks.filter((step: DropdownOption<StepAndTaskOptionValue>) => step.label === this.form.get('stepFilterDashboardDone')?.value.name).sort(sortFunction);
    }
    if (table === MondayDashboard.PROGRESS && this.form.get('stepFilterDashboardProgress')?.value) {
      return stepTasks.filter((step: DropdownOption<StepAndTaskOptionValue>) => step.label === this.form.get('stepFilterDashboardProgress')?.value.name).sort(sortFunction);
    }

    return stepTasks.sort(sortFunction);
  }

  // Set default value of the task row pole dropdown.
  setRowTaskDropdowns() {
    if (this.progressTasks) {
      this.rowPoleDropdownDashboardProgress.controls = [];
      this.rowCollaboratorDropdownDashboardProgress.controls = [];

      this.progressTasks.map((progressTask, index) => {
        if (this.rowPoleDropdownDashboardProgress.controls.length < this.progressTasks.length &&
          this.rowCollaboratorDropdownDashboardProgress.controls.length < this.progressTasks.length) {
          // Push a form control for each row.
          this.rowPoleDropdownDashboardProgress.controls.push(this.formBuilder.control([]));
          this.rowCollaboratorDropdownDashboardProgress.controls.push(this.formBuilder.control([]));
          // Populate this form control with the default value.
          this.rowPoleDropdownDashboardProgress.controls[index].setValue(progressTask.pole);
          this.rowCollaboratorDropdownDashboardProgress.controls[index].setValue({id: progressTask.collaborator?.id});
        }
      });
    }
  }

  getFormControleName(task: Task) {
    return this.progressTasks.findIndex((arrayItem: DashboardTask) => arrayItem.id === task.id);
  }

  handleProgressDateSelect(value: any){
    this.uiStoreState = {...this.uiStoreState, mondayDashboard: {...this.uiStoreState.mondayDashboard, progressDate: value}};
    this.uiStore.update(this.uiStoreState);
    this.initDashboardsWorkflow();
  }

  handleDoneDateSelect(value: any){
    this.uiStoreState = {...this.uiStoreState, mondayDashboard: {...this.uiStoreState.mondayDashboard, doneDate: value}};
    this.uiStore.update(this.uiStoreState);
    this.initDashboardsWorkflow();
  }

  // Handle table filtering.
  handleFiltering(filterValue: any, fieldToFilter: DashboardFiltersType, table: MondayDashboard) {
    if (fieldToFilter === DashboardFiltersType.IMPORTANCE && table) { this.setImportanceFilter(filterValue, table); }
    if (fieldToFilter === DashboardFiltersType.STEP && table) { this.setStepFilter(filterValue, table); }
    if (fieldToFilter === DashboardFiltersType.STEPTASK && table) { this.setStepTaskFilter(filterValue, table); }
    if (fieldToFilter === DashboardFiltersType.CUSTOMER && table) { this.setCustomerFilter(filterValue, table); }
    if (fieldToFilter === DashboardFiltersType.COLLABORATORANDPOLE) { this.setCollaboratorOrPoleFilter(filterValue); }
    if (fieldToFilter === DashboardFiltersType.POLE) { this.setPoleFilter(filterValue); }
    if (fieldToFilter === DashboardFiltersType.COLLABORATOR) { this.setCollaboratorFilter(filterValue); }
  }

  // Apply importance filter.
  setImportanceFilter(filterValue: number, table: MondayDashboard) {
    if (table === this.mondayDashboardView.DONE) {
      this.form.get('importanceFilterDashboardDone')?.setValue(filterValue);
      this.mondayReviewTableDone.filter(filterValue, 'importance', 'containsImportance');
    } else if (table === this.mondayDashboardView.PROGRESS) {
      this.form.get('importanceFilterDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'importance', 'containsImportance');
    }
  }

  // Apply step filter.
  setStepFilter(filterValue: StepAndTaskOptionValue, table: MondayDashboard) {
    if (table === this.mondayDashboardView.DONE) {
      this.form.get('stepFilterDashboardDone')?.setValue(filterValue);
      this.mondayReviewTableDone.filter(filterValue, 'step', 'containsStep');
      // Set task dropdown appriopriate options based on selected step.
      this.stepRelatedTaskOptions = this.filterStepTasksOptions(this.doneTasks, MondayDashboard.DONE);
    } else if (table === this.mondayDashboardView.PROGRESS) {
      this.form.get('stepFilterDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'step', 'containsStep');
      // Set task dropdown appriopriate options based on selected step.
      this.stepRelatedTaskOptions = this.filterStepTasksOptions(this.progressTasks, MondayDashboard.PROGRESS);
    }
  }

  // Apply task filter.
  setStepTaskFilter(filterValue: StepAndTaskOptionValue, table: MondayDashboard) {
    if (table === this.mondayDashboardView.DONE) {
      this.form.get('filterTaskDashboardDone')?.setValue(filterValue);
      this.mondayReviewTableDone.filter(filterValue, 'name', 'containsStepTask');
    } else if (table === this.mondayDashboardView.PROGRESS) {
      this.form.get('filterTaskDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'name', 'containsStepTask');
    }
  }

  // Apply customer filter.
  setCustomerFilter(filterValue: ClientOptionValue, table: MondayDashboard) {
    if (table === this.mondayDashboardView.DONE) {
      this.form.get('customerFilterDashboardDone')?.setValue(filterValue);
      this.mondayReviewTableDone.filter(filterValue, 'legalAndNaturalCustomer', 'containsCustomer');
    } else if (table === this.mondayDashboardView.PROGRESS) {
      this.form.get('customerFilterDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'legalAndNaturalCustomer', 'containsCustomer');
    }
  }

  // Apply collaborator or pole filter.
  setCollaboratorOrPoleFilter(filterValue: PoleAndCollaboratorOptionValue) {
      this.form.get('collaboratorAndPoleFilterDashboardDone')?.setValue(filterValue);
      this.mondayReviewTableDone.filter(filterValue, 'poleAndCollaborator', 'containsCollaboratorOrPole');
  }

  // Apply collaborator filter.
  setCollaboratorFilter(filterValue: PoleAndCollaboratorOptionValue) {
      this.form.get('collaboratorFilterDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'collaborator', 'containsCollaborator');
  }

  // Apply pole filter.
  setPoleFilter(filterValue: PoleAndCollaboratorOptionValue) {
      this.form.get('poleFilterDashboardProgress')?.setValue(filterValue);
      this.mondayReviewTableProgress.filter(filterValue, 'pole', 'containsPole');
  }

  // Importance filter logic.
  doesTaskContainImportance(item: any, filterValue: number) {
    if (item) { return item === filterValue; }
    return !filterValue;
  }

  // Step filter logic.
  doesTaskContainStep(item: any, filterValue: StepAndTaskOptionValue) {
    if (item) { return item.name === filterValue.name; }
    return !filterValue;
  }

  // Task filter logic.
  doesTaskContainsTaskName(item: Step['name'], filterValue: StepAndTaskOptionValue) {
    if (item) { return item === filterValue.name; }
    return !filterValue;
  }

  // Customer filter logic.
  doesTaskContainClient(item: AddonFilterLegalAndNaturalCustomer, filterValue: ClientOptionValue) {
    if (item && filterValue.type === 'naturalPerson') {
      return item.naturalPeople?.some((naturalPerson: NaturalPerson) => naturalPerson.id === filterValue.id);
    }
    if (item && filterValue.type === 'legalPerson') {
      return item.legalPeople?.some((legalPerson: LegalPerson) => legalPerson.id === filterValue.id);
    }
    return !filterValue;
  }

  // Collaborator or pole filter logic.
  doesTaskContainCollaboratorOrPole(item: AddonFilterCollaboratorAndPole, filterValue: PoleAndCollaboratorOptionValue) {
    if (item && filterValue.type === 'pole') {
      return item.pole === filterValue.id;
    }
    if (item.collaborator && filterValue.type === 'collaborator') {
      return item.collaborator.id === filterValue.id;
    }
    return !filterValue;
  }

  // Pole filter logic.
  doesTaskContainPole(item: any, filterValue: any) {
    if (item && filterValue) {
      return item === filterValue.toUpperCase();
    }

    return !filterValue;
  }

  // Collaborator filter logic.
  doesTaskContainCollaborator(item: any, filterValue: any) {
    // if task is not assigned to any collaborator
    if (item === null && filterValue === 0) {
      return true;
    }

    // if task is assigned to a collaborator
    else if (item && filterValue !== 0) {
      return item.id === filterValue.id;
    } else {
      return false;
    }
  }

  // Reinit button action.
  clearFilters(table: MondayDashboard, event: Event) {
    // If we do not clean each filter we encounter the following problem :
    // the filter is reset but the filter label remains selected in the dropdown.
    if (table === MondayDashboard.DONE) {
      this.filterImportanceDashboardDone.clear(event);
      this.filterStepDashboardDone.clear(event);
      this.filterTaskDashboardDone.clear(event);
      this.filterCustomerDashboardDone.clear(event);
      this.filterCollaboratorAndPoleDashboardDone.clear(event);
    }
    if (table === MondayDashboard.PROGRESS) {
      this.filterImportanceDashboardProgress.clear(event);
      this.filterStepDashboardProgress.clear(event);
      this.filterTaskDashboardProgress.clear(event);
      this.filterCustomerDashboardProgress.clear(event);
      this.filterPoleDashboardProgress.clear(event);
      this.filterCollaboratorDashboardProgress.clear(event);
    }
  }

  // Handle task button type filtering.
  handleFilteringTaskType(type: undefined | MondayDashboardTaskType, table: MondayDashboard, event: Event){
    this.clearFilters(table, event);
    if (table === MondayDashboard.DONE) {
      this.mondayDashboardDoneTaskTypeFilter = type;
      this.uiStoreState = {...this.uiStoreState, mondayDashboard: {...this.uiStoreState.mondayDashboard, doneTaskType: type}};
      this.uiStore.update(this.uiStoreState);
    }
    if (table === MondayDashboard.PROGRESS) {
      this.mondayDashboardProgressTaskTypeFilter = type;
      this.uiStoreState = {...this.uiStoreState, mondayDashboard: {...this.uiStoreState.mondayDashboard, progressTaskType: type}};
      this.uiStore.update(this.uiStoreState);
    }
    this.initDashboardsWorkflow();
  }

  // Switch monday dashboard view and re-init dashboard with appropriate data.
  switchMondayDashboard(desiredTable: MondayDashboard) {
    this.currentMondayDashboardView = desiredTable;
    this.uiStoreState = {...this.uiStoreState, mondayDashboard: {...this.uiStoreState.mondayDashboard, view: desiredTable}};
    this.uiStore.update(this.uiStoreState);
    // Reinit dropdown options.
    this.stepsOptions = this.dropdownOptionsService.steps;
    this.stepRelatedTaskOptions = this.dropdownOptionsService.stepsTasks;
    this.customerOptions = this.dropdownOptionsService.clients;
    // Reinit dashboard workflow.
    this.initDashboardsWorkflow();
  }

  // Update task pole on task row dropdown changes.
  onRowPoleDropdownChanges(task: DashboardTask, rowIndex: number) {
    const selectedPole = this.rowPoleDropdownDashboardProgress.controls[rowIndex].value;

    if (task.isInternal) {
      const updatedTask = {...task, pole: selectedPole};
      // @ts-ignore
      this.taskService.update(task.id, updatedTask).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onRowPoleChangeSucces();
        } else {
          this.onRowPoleChangeError();
        }
      });
    }

    if (!task.isInternal && task.step && task.groupIndex !== undefined && task.taskIndex !== undefined) {
      const taskStep = this.taskHelperService.populateTaskEditingForm(task.step, task.groupIndex, task.taskIndex);

      // Set selected pole.
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].pole = selectedPole;

      // When populating the task edit form ( taskHelperService.populateTaskEditingForm ) we create an empty comment, remove it.
      const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments = taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments.filter((comment: any) =>
        !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

      this.stepService.update(taskStep.value.id, taskStep.value).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onRowPoleChangeSucces();
        } else {
          this.onRowPoleChangeError();
        }
      });
    }
  }

  // Update task collaborator on task row dropdown changes.
  onRowCollaboratorDropdownChanges(task: DashboardTask, rowIndex: number) {
    const selectedCollaborator = this.rowCollaboratorDropdownDashboardProgress.controls[rowIndex].value;

    if (task.isInternal) {
      const updatedTask = {...task, collaborator: selectedCollaborator};
      // @ts-ignore
      this.taskService.update(task.id, updatedTask).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onRowCollaboratorChangeSucces();
        } else {
          this.onRowCollaboratorChangeError();
        }
      });
    }

    if (!task.isInternal && task.step && task.groupIndex !== undefined && task.taskIndex !== undefined) {
      const taskStep = this.taskHelperService.populateTaskEditingForm(task.step, task.groupIndex, task.taskIndex);

      // Set selected collaborator.
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].collaborator = selectedCollaborator;

      // When populating the task edit form ( taskHelperService.populateTaskEditingForm ) we create an empty comment, remove it.
      const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments = taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments.filter((comment: any) =>
        !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

      this.stepService.update(taskStep.value.id, taskStep.value).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onRowCollaboratorChangeSucces();
        } else {
          this.onRowCollaboratorChangeError();
        }
      });
    }
  }

  // Redirection when user click on table row customer tag.
  onCustomerTagClick(customerId: number, customerType: 'legalPerson' | 'naturalPerson') {
    if (customerType === 'legalPerson') {
      this.router.navigate(['/legal-person/' + customerId]);
    } else if (customerType === 'naturalPerson') {
      this.router.navigate(['/natural-person/' + customerId]);
    }
  }

  // Redirection when user click on table row edit action button.
  onEditTaskClick(task: DashboardTask) {
    if (!task.isInternal) {
      this.router.navigate(['/task-customer-edit', task.step?.id, task.id, {from: 'mondayReview'}]);
    }
    if (task.isInternal) {
      this.router.navigate(['/task-internal-edit', task.id, {from: 'mondayReview'}]);
    }
  }

  // Hide task from monday progress review by setting a hidden until date.
  onHideTaskClick(task: DashboardTask) {
    if (task.isInternal) {
      const updatedTask = {...task, hiddenUntil: this.currentDateNextMonday};
      // @ts-ignore
      this.taskService.update(task.id, updatedTask).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onTaskHideSucces();
        } else {
          this.onTaskHideError();
        }
      });
    }

    if (!task.isInternal && task.step && task.groupIndex !== undefined && task.taskIndex !== undefined) {
      const taskStep = this.taskHelperService.populateTaskEditingForm(task.step, task.groupIndex, task.taskIndex);

      // Set hidden until date.
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].hiddenUntil = this.currentDateNextMonday;

      // When populating the task edit form ( taskHelperService.populateTaskEditingForm ) we create an empty comment, remove it.
      const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
      taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments = taskStep.value.stepGroups[task.groupIndex].tasks[task.taskIndex].comments.filter((comment: any) =>
        !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

      this.stepService.update(taskStep.value.id, taskStep.value).pipe(untilDestroyed(this)).subscribe((res) => {
        if (res) {
          this.onTaskHideSucces();
        } else {
          this.onTaskHideError();
        }
      });
    }
  }

  onRowPoleChangeSucces() {
    this.messageService.add({
      severity: 'success',
      summary: 'Tâche assignée au pôle avec succès',
      life: 3000,
    });
  }

  onRowPoleChangeError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la mise à jour de la tâche',
      life: 3000,
    });
  }

  onRowCollaboratorChangeSucces() {
    this.messageService.add({
      severity: 'success',
      summary: 'Tâche assignée au collaborateur avec succès',
      life: 3000,
    });
  }

  onRowCollaboratorChangeError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la mise à jour de la tâche',
      life: 3000,
    });
  }

  onTaskHideSucces() {
    this.messageService.add({
      severity: 'success',
      summary: 'Tâche cachée jusqu\'à lundi prochain',
      life: 3000,
    });
  }

  onTaskHideError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la mise à jour de la tâche',
      life: 3000,
    });
  }

  // Translate service.
  translate(word: string) {
    return this.translatorService.instant(word);
  }
}
