export interface Configuration {
  id?: number | string;
  code: number | string;
  value: number | string;
}

export function createConfiguration(params: Partial<Configuration>) {
  return {

  } as Configuration;
}
