import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ClientStatus, ClientType, DropdownOption, LegalPerson, TranslatorService, DropdownOptionsService} from 'common';

@Component({
  selector: 'app-legal-person-form',
  templateUrl: './legal-person-form.component.html',
  styleUrls: ['./legal-person-form.component.scss']
})
export class LegalPersonFormComponent implements OnInit {

  @Input() isNewLegalPerson = false;
  @Input() markAsTouched = false;

  form = this.formBuilder.group({
    name: ['', Validators.required],
    siretNumber: [''],
    structureJuridique: [''],
    creationDate: [null],
    customerStatus: [[]],
    relation: this.formBuilder.control({
      naturalPerson: [null, Validators.required],
      naturalPersonRole: [null, Validators.required],
    }),
    address: this.formBuilder.control({
      id: null,
      street: '',
      city: '',
      zipCode: '',
      state: '',
      country: '',
      label: '',
      isContact: true,
      fiscale: true,
      type: null
    })
  });

  initialValues: any;
  formIsReady = false;

  clientType = ClientType;

  statusOptions: DropdownOption[];
  legalStatusOptions: DropdownOption[];
  currentDate = new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translatorService: TranslatorService,
    private dropdownOptionsService: DropdownOptionsService,
  ) { }

  ngOnInit(): void {
    this.statusOptions = this.dropdownOptionsService.clientStatuses;
    this.legalStatusOptions = this.dropdownOptionsService.legalStatuses.sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  createFormGroup(legalPerson?: LegalPerson, update: boolean = false) {
    this.initialValues = {
      name: legalPerson ? legalPerson.name : '',
      siretNumber: legalPerson ? legalPerson.siretNumber : '',
      structureJuridique: legalPerson ? legalPerson.structureJuridique : '',
      creationDate: legalPerson ? legalPerson.creationDate && new Date(legalPerson.creationDate) : null,
      customerStatus: legalPerson ? legalPerson.customerStatus : [],
      address: legalPerson && legalPerson.addresses.length ? {
        id: legalPerson ? legalPerson.addresses[0].id: null,
        label: legalPerson ? legalPerson.addresses[0].label: null,
        street: legalPerson ? legalPerson.addresses[0].street: '',
        city: legalPerson ? legalPerson.addresses[0].city: '',
        zipCode: legalPerson ? legalPerson.addresses[0].zipCode: '',
        state: legalPerson ? legalPerson.addresses[0].state: '',
        country: legalPerson ? legalPerson.addresses[0].country: '',
        isContact: legalPerson ? legalPerson.addresses[0].isContact: false,
        fiscale: legalPerson ? legalPerson.addresses[0].fiscale: false,
        type: legalPerson ? legalPerson.addresses[0].type: '',
      } : null,
      relation: {
        naturalPerson: (legalPerson && legalPerson.naturalLegalRelations?.length)? {id: legalPerson.naturalLegalRelations[0].naturalPerson.id} : null,
        naturalPersonRole: (legalPerson && legalPerson.naturalLegalRelations?.length)? {id: legalPerson.naturalLegalRelations[0].naturalPersonRole} : null,
      },
      generalComment: legalPerson ? legalPerson.generalComment : ''
    };

    if(update){
      this.form.get('relation')?.clearValidators();
    }

    this.form.reset(this.initialValues);

    this.formIsReady = true;
    return this.form;
  }
  /* get form from template */
  get nameF() { return this.form?.get('name');}
  get siretNumberF() { return this.form?.get('siretNumber');}
  get structureJuridiqueF() { return this.form?.get('structureJuridique');}
  get creationDateF() { return this.form?.get('creationDate');}
  get customerStatusF() { return this.form?.get('customerStatus');}
  customerStatusIsClientOrProspect(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.customerStatusF?.value?.includes(ClientStatus.CLIENT) && this.customerStatusF?.value?.includes(ClientStatus.PROSPECT)) {
        return {customerStatusIsClientOrProspect: true};
      }
      return null;
    };
  }
}
