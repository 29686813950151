import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { CompteRenduStore, CompteRenduState } from './compte-rendu.store';

@Injectable({ providedIn: 'root' })
export class CompteRenduService extends NgEntityService<CompteRenduState> {

  constructor(protected override store: CompteRenduStore) {
    super(store);
  }

}
