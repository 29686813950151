<div [formGroup]="form">
  <div class="grid p-fluid" *ngIf="!conjoint">
    <div class="field col-3">
      <label for="situationMatrimoniale">Situation</label>
      <p-dropdown
        formControlName="situationMatrimoniale"
        id="situationMatrimoniale"
        [options]="situationMatrimonialeOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
    <div class="field col-3">
      <label for="precedentMariage">Précédent mariage ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.precedentMariage}">Non</div>
        <p-inputSwitch  id="precedentMariage"
                        formControlName="precedentMariage"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.precedentMariage}">Oui</div>
      </div>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-3" *ngIf="value.situationMatrimoniale === situationMatrimonial.MARIE || value.situationMatrimoniale === situationMatrimonial.PACSE">
      <label for="dateMariage">Date</label>
      <p-calendar appendTo="body"
                  formControlName="dateMariage"
                  id="dateMariage"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
    </div>
    <div class="field col-3" *ngIf="value.situationMatrimoniale === situationMatrimonial.MARIE || value.situationMatrimoniale === situationMatrimonial.PACSE">
      <label for="lieuMariage">Lieu</label>
      <input formControlName="lieuMariage" id="lieuMariage" type="text" pInputText class="inputfield">
    </div>
    <div class="field col-3" *ngIf="value.situationMatrimoniale === situationMatrimonial.MARIE">
      <label for="regimeMatrimonial">Régime matrimonial</label>
      <p-dropdown
        formControlName="regimeMatrimonial"
        id="regimeMatrimonial"
        [options]="regimeMatrimonialOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="value.situationMatrimoniale === situationMatrimonial.PACSE">
      <label for="regimePacsimonial">Régime pacsimonial</label>
      <p-dropdown
        formControlName="regimePacsimonial"
        id="regimePacsimonial"
        [options]="regimePacsimonialOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
  </div>
  <div class="grid p-fluid" *ngIf="value.situationMatrimoniale === situationMatrimonial.MARIE">
    <div class="field col-3">
      <label for="donationDernierVivant">Donation au dernier vivant</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.donationDernierVivant}">Non</div>
        <p-inputSwitch  id="donationDernierVivant"
                        formControlName="donationDernierVivant"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.donationDernierVivant}">Oui</div>
      </div>
    </div>
    <div class="col-3 field" *ngIf="value.donationDernierVivant && !conjoint">
      <div class="field-checkbox">
        <p-radioButton value="{{sensDonation.MADAME_TO_MONSIEUR}}"
                       label="Madame, envers Monsieur"
                       [formControl]="sensDonationControl"
        ></p-radioButton>
      </div>
      <div class="field-checkbox">
        <p-radioButton value="{{sensDonation.MONSIEUR_TO_MADAME}}"
                       label="Monsieur, envers Madame"
                       [formControl]="sensDonationControl"
        ></p-radioButton>
      </div>
      <div class="field-checkbox">
        <p-radioButton value="{{sensDonation.MUTUELLE}}"
                       label="Mutuelle"
                       [formControl]="sensDonationControl"
        ></p-radioButton>
      </div>
    </div>
  </div>
  <div class="grid p-fluid" *ngIf="value.situationMatrimoniale === situationMatrimonial.PACSE">
    <div class="field col-3">
      <label for="testamentCroise">Testament croisé</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.testamentCroise}">Non</div>
        <p-inputSwitch  id="testamentCroise"
                        formControlName="testamentCroise"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.testamentCroise}">Oui</div>
      </div>
    </div>
    <div class="col-3" *ngIf="value.testamentCroise && !conjoint">
        <div class="field-checkbox">
          <p-radioButton value="{{sensDonation.MADAME_TO_MONSIEUR}}"
                         label="Madame, envers Monsieur"
                         [formControl]="sensTestamentCroiseControl"
          ></p-radioButton>
        </div>
        <div class="field-checkbox">
          <p-radioButton value="{{sensDonation.MONSIEUR_TO_MADAME}}"
                         label="Monsieur, envers Madame"
                         [formControl]="sensTestamentCroiseControl"
          ></p-radioButton>
        </div>
        <div class="field-checkbox">
          <p-radioButton value="{{sensDonation.MUTUELLE}}"
                         label="Mutuel"
                         [formControl]="sensTestamentCroiseControl"
          ></p-radioButton>
        </div>
    </div>
  </div>
</div>
