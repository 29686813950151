import {Inject, Injectable} from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { NaturalNaturalRelationStore, NaturalNaturalRelationState } from './natural-natural-relation.store';

@Injectable({ providedIn: 'root' })
export class NaturalNaturalRelationService extends NgEntityService<NaturalNaturalRelationState> {

  constructor(
    @Inject('config') public override config: any,
      protected override store: NaturalNaturalRelationStore,
  ) {
    super(store);
  }

}
