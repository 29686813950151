import {ID} from '@datorama/akita';

export interface Objectif {
  id?: ID;
  label: string;
  clientType: string;
  position: number;
}

export function createObjectif(params: Partial<Objectif>) {
  return {

  } as Objectif;
}
