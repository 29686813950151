import {Component, forwardRef, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ClientType, CauseRempliTier, RempliPar, DropdownOption, DropdownOptionsService, TranslatorService} from 'common';


@Component({
  selector: 'app-presentation-form',
  templateUrl: './presentation-form.component.html',
  styleUrls: ['./presentation-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PresentationFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PresentationFormComponent),
      multi: true
    }
  ]
})
export class PresentationFormComponent implements ControlValueAccessor, OnDestroy  {

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  clientTypeOptions: DropdownOption[];
  civilityOptions: DropdownOption[];
  tiersTypeOptions: DropdownOption[];
  rempliParOptions: DropdownOption[];
  rempliPar = RempliPar;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dropdownService: DropdownOptionsService,
    public translatorService: TranslatorService,
    ) {
    this.form = this.formBuilder.group({
      clientType : this.formBuilder.control(ClientType.NATURAL_PERSON),
      causeRempliTier: this.formBuilder.control(CauseRempliTier.AUTRE),
      rempliPar : this.formBuilder.control(RempliPar.YOU),
      tiersCivility: this.formBuilder.control(null),
      tiersFirstName: this.formBuilder.control(null),
      tiersLastName: this.formBuilder.control(null),
      tiersStructure: this.formBuilder.control(null),
    });

    this.clientTypeOptions  = [
      {label: 'Une personne physique', value: ClientType.NATURAL_PERSON},
      {label: 'Une personne morale', value: ClientType.LEGAL_PERSON, disabled: true}
    ];

    this.rempliParOptions  = [
      {label: 'Pour vous', value: RempliPar.YOU},
      {label: 'Pour un tiers', value: RempliPar.TIERS}
    ];

    this.tiersTypeOptions  = [
      {label: this.translate(CauseRempliTier.PERSONNE_MINEURE), value: CauseRempliTier.PERSONNE_MINEURE},
      {label: this.translate(CauseRempliTier.PERSONNE_SOUS_TUTELLE), value: CauseRempliTier.PERSONNE_SOUS_TUTELLE},
      {label: this.translate(CauseRempliTier.PERSONNE_SOUS_CURATELLE), value: CauseRempliTier.PERSONNE_SOUS_CURATELLE},
      {label: this.translate(CauseRempliTier.AUTRE), value: CauseRempliTier.AUTRE}
    ];

    this.civilityOptions  = this.dropdownService.civility;

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        if(value.rempliPar === RempliPar.TIERS){
          this.civilityF?.setValidators(Validators.required);
          this.lastNameF?.setValidators(Validators.required);
          this.firstNameF?.setValidators(Validators.required);
        } else {
          this.civilityF?.setValidators(null);
          this.lastNameF?.setValidators(null);
          this.firstNameF?.setValidators(null);
          value.tiersCivility = null;
          value.tiersLastName = null;
          value.tiersFirstName = null;
          value.tiersStructure = null;
        }
        if(value.tiersLastName){
          value.tiersLastName = value.tiersLastName.toUpperCase();
        }
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  get civilityF() { return this.form?.get('tiersCivility');}
  get lastNameF() { return this.form?.get('tiersLastName');}
  get firstNameF() { return this.form?.get('tiersFirstName');}
  get tiersStructureF() { return this.form?.get('tiersStructure');}

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  translate(word: string | undefined): string {
    if (word) {
      return this.translatorService.instant(word);
    } else {
      return '';
    }
  }


}
