export enum ImmobilierNature {
  IMMO_NATURE_MAISON = 'IMMO_NATURE_MAISON',
  IMMO_NATURE_IMMEUBLE = 'IMMO_NATURE_IMMEUBLE',
  IMMO_NATURE_APPARTEMENT = 'IMMO_NATURE_APPARTEMENT',
  IMMO_NATURE_GARAGE_PARKING = 'IMMO_NATURE_GARAGE_PARKING',
  IMMO_NATURE_TERRAIN = 'IMMO_NATURE_TERRAIN',
  IMMO_NATURE_AUTRE = 'IMMO_NATURE_AUTRE',
}

export enum ImmobilierType {
  IMMO_TYPE_PRINCIPALE = 'IMMO_TYPE_PRINCIPALE',
  IMMO_TYPE_SECONDAIRE = 'IMMO_TYPE_SECONDAIRE',
  IMMO_TYPE_FONCIER_FORESTIER = 'IMMO_TYPE_FONCIER_FORESTIER',
  IMMO_TYPE_LOCATIF = 'IMMO_TYPE_LOCATIF',
  IMMO_TYPE_SCPI = 'IMMO_TYPE_SCPI',
}

export enum DetenteurBien {
  IMMO_DETENTEUR_MOI = 'IMMO_DETENTEUR_MOI',
  IMMO_DETENTEUR_NOUS = 'IMMO_DETENTEUR_NOUS',
  IMMO_DETENTEUR_CONJOINT = 'IMMO_DETENTEUR_CONJOINT',
  IMMO_DETENTEUR_AUTRE = 'IMMO_DETENTEUR_AUTRE'
}

export enum ModeDetention {
  IMMO_DETENTION_PLEINE_PROPRIETE = 'IMMO_DETENTION_PLEINE_PROPRIETE',
  IMMO_DETENTION_INDIVISION = 'IMMO_DETENTION_INDIVISION',
  IMMO_DETENTION_DEMEMBREMENT = 'IMMO_DETENTION_DEMEMBREMENT',
}
