<div class="recap-content mx-auto">
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <ng-container *ngIf="patrimoine !== 0; else patrimoineZero">
      <div class="flex justify-content-center mt-6">
        <img src="assets/recueil/restit-patrimoine.png" style="width: 100px">
      </div>
      <div>
        <h1 class="rec text-center">Nous estimons votre patrimoine à {{patrimoine | number:'':'fr-FR'}} €.
          <app-help-tooltip class="ml-3" [text]="'Il s\'agit d\'un patrimoine brut hors passif et ne tenant pas ' +
      'compte de la valorisation de vos sociétés, patrimoniale ou professionnelle.'"></app-help-tooltip>
        </h1>
      </div>

      <h2 class="rec text-center mb-6">On n'oublie rien ?</h2>
    </ng-container>
    <ng-template #patrimoineZero>
      <div class="flex justify-content-center mt-6">
        <h1 class="rec text-center mb-6">On n'oublie rien ?</h1>
      </div>
    </ng-template>

    <ng-container *ngIf="!sentDocsEmpty">
      <h2 class="rec">Vous nous avez transmis les documents suivants :</h2>
      <p-divider></p-divider>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <h2 class="rec text-center">Nous estimons le patrimoine total à {{patrimoine | number:'':'fr-FR'}} €. </h2>

    <h3 class="rec">Documents transmis</h3>
    <p-divider></p-divider>
  </ng-container>


  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <ng-container *ngFor="let sent of docSummary.sent">
      <ng-container *ngIf="sent.docItems?.length">

        <div class="flex align-items-center" *ngFor="let docItem of sent.docItems">
          <div class="flex m-3">
            <p-checkbox
              [value]="true"
              [ngModel]="true"
              [binary]="true"
              (onChange)="onSentClick(docItem, sent.categorie)"
              [label]="docItem.doc.type ? translate(docItem.doc.type.code) : ''"
            ></p-checkbox>
            <div class="ml-1" *ngIf="docItem.owner?.civility">de {{docItem.owner.civility + ' ' + docItem.owner.firstName + ' ' + docItem.owner.lastName}}</div>
          </div>
          <div *ngIf="docItem.doc.files && docItem.doc.files?.length && !docItem.doc.external; else docExternal" class="ml-3 link" (click)="onDocumentClick(docItem.doc)">
            {{docItem.doc.files[0].name}}.{{docItem.doc.files[0].extension}}
          </div>
          <ng-template #docExternal>
            <div class="ml-3">
              Consulter ce document via l'onglet GED
            </div>
          </ng-template>
        </div>

      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <div class="grid">
      <ng-container *ngFor="let sent of docSummary.sent">
        <ng-container *ngIf="sent.docItems?.length">
          <div class="col-6 align-items-center client-sent-grid" *ngFor="let docItem of sent.docItems">
            <div class="grid" *ngIf="!docItem.doc.external">
              <div class="col-4 ellipsis">
                <ng-container *ngIf="docItem.doc.type.multiple">
                  {{docItem.doc ? docItem.doc.name : ''}}
                </ng-container>
                <ng-container *ngIf="!docItem.doc.type.multiple">
                  {{docItem.doc.type ? translate(docItem.doc.type.code) : ''}}
                </ng-container>
                <div class="ml-1" *ngIf="docItem.owner?.civility">de {{docItem.owner.civility + ' ' + docItem.owner.firstName + ' ' + docItem.owner.lastName}}</div>
              </div>
              <div class="flex justify-content-center col-1">
                <i class="pi pi-file" style="font-size: 1.6rem"></i>
              </div>
              <div class="col-4">
                <div *ngIf="docItem.doc.files && docItem.doc.files?.length && !docItem.doc.external" (click)="onDocumentClick(docItem.doc)" class="link ellipsis">
                  {{docItem.doc.files[0].name}}.{{docItem.doc.files[0].extension}}
                </div>
              </div>
              <div class="flex justify-content-center col-2">
                <div *ngIf="docItem.doc.files && docItem.doc.files?.length && !docItem.doc.external" (click)="onDocumentClick(docItem.doc)" class="ellipsis">
                  {{docItem.doc.files[0].size | number: '1.0-0'}}Ko
                </div>
              </div>
              <div class="flex justify-content-center col-1">
                <i class="icon-delete font-icon" style="font-size: 1.2rem; cursor: pointer" (click)="onDocumentRemove(docItem, sent.categorie)"></i>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <h2 class="rec">Il vous reste à nous transmettre :</h2>
    <p-divider></p-divider>
    <div>Les documents suivants nous seront d'une grande aide pour vous conseiller.</div>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <h3 class="rec">Documents non transmis</h3>
    <p-divider></p-divider>
  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <ng-container *ngFor="let notSent of docToUpload">
      <div class="flex align-items-baseline" *ngIf="notSent.docs?.length">
        <i class="{{notSent.categorie.icon}} mr-3 bg-white text-primary" style="font-size: 1.2rem"></i>
        <h3 class="rec" *ngIf="notSent.categorie.subLabel">{{translate(notSent.categorie.label)+notSent.categorie.subLabel}}</h3>
        <h3 class="rec" *ngIf="!notSent.categorie.subLabel">{{translate(notSent.categorie.label)}}</h3>
        <p-divider class="flex-grow-1 pl-2"></p-divider>
      </div>
      <div class="flex" [formGroup]="form">
        <div class="flex flex-column">
          <app-documents-form
            [formControlName]="notSent.categorie.label"
            [clientId]="clientId"
            [clientType]="clientType"
            [withTitles]="false"
            [documentTypes]="notSent.docs">
          </app-documents-form>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <ng-container *ngFor="let notSent of docSummary.notSent">
    <div class="flex align-items-baseline" *ngIf="notSent.docItems?.length">
      <i class="{{notSent.categorie.icon}} mr-3 bg-white" style="font-size: 1.2rem"></i>
      <h4 class="rec" *ngIf="notSent.categorie.subLabel">{{translate(notSent.categorie.label)+notSent.categorie.subLabel}}</h4>
      <h4 class="rec" *ngIf="!notSent.categorie.subLabel">{{translate(notSent.categorie.label)}}</h4>
    </div>
    <div class="flex align-items-center" *ngFor="let docItem of notSent.docItems">
      <div class="m-3 field-checkbox">
        <p-checkbox
          [value]="docItem"
          [binary]="true"
          (onChange)="onNotSentClick(docItem, notSent.categorie)"
          [label]="docItem.doc ? translate(docItem.doc.type.code) : ''"
        ></p-checkbox>
        <div class="ml-1" *ngIf="docItem.owner?.civility">de {{docItem.owner.civility + ' ' + docItem.owner.firstName + ' ' + docItem.owner.lastName}}</div>
      </div>
    </div>
  </ng-container>
  </ng-container>
</div>
