<div class="flex justify-content-center flex-wrap">
  <ng-container *ngFor="let item of items">
    <button pButton type="button"
      [label]="item.label"
      [disabled]="item.disabled"
      [attr.tabindex]="selected === item.value ? -1 : 0"
      (click)="selectItem(item.value)"
      class="p-button-outlined custom-btn"
      [class.selected]="!disabled && selected === item.value"
    >
      <ng-container *ngIf="!disabled && selected === item.value">
      </ng-container>
    </button>
  </ng-container>
</div>
