<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>

<ng-template #desktop>
  <ng-container>
    <div class="grid rec mr-0">
      <div id="imgDiv" class="col-6 col-sm-12 p-7 pt-2 pb-4 right-panel flex flex-column align-items-center">
        <img src="assets/recueil/00-final.jpg" alt="backgroundBienvenue/" class="backgroundImg">
      </div>
      <div style="justify-content: center" class="col-6 col-sm-12 p-6 left-panel flex flex-column align-items-center">
        <h1 class="rec">Merci pour vos réponses.</h1>
        <p style="text-align: center" [innerHTML]="content"></p>
        <a class="mt-4" href="/login">Retourner à mon recueil</a>
      </div>
    </div>
  </ng-container>

</ng-template>
