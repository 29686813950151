import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DropdownOption, DropdownOptionsService} from 'common';

@Component({
  selector: 'app-info-civile-form',
  templateUrl: './info-civile-form.component.html',
  styleUrls: ['./info-civile-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfoCivileFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InfoCivileFormComponent),
      multi: true
    }
  ]
})
export class InfoCivileFormComponent implements ControlValueAccessor, OnDestroy {
  // sert a conditionner l'affichage de certains champs
  @Input() advanced = false;
  @Input() existence = false;
  @Input() conjointExistence = false;
  @Input() personACharge = false;
  form: UntypedFormGroup;
  // sert à rendre le nom et le prénom non modifiables par le client
  @Input() fullNameDisabled = false;
  subscriptions: Subscription[] = [];
  civilityOptions: DropdownOption[];
  mesureProtectionOptions: DropdownOption[];
  currentDate = new Date();
  initialValues =
    {
      civility: [{value: ''}, Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      birthName: null,
      birthDate: null,
      birthCity: null,
      birthState: null,
      birthCountry: null,
      nationality: null,
      otherNationality: null,
      mesureProtection: null,
      handicap: null,
      dead: null,
      deathDate: null,
    };
  constructor( private formBuilder: UntypedFormBuilder,private dropdownService: DropdownOptionsService,) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        value = {...this.form.getRawValue(),
          lastName: this.form.getRawValue().lastName?.toUpperCase(),
          birthName: this.form.getRawValue().birthName ? this.form.getRawValue().birthName.toUpperCase() : null};
        this.onChange(value);
        this.onTouched();
      })
    );
    this.civilityOptions  = this.dropdownService.civility;
    this.mesureProtectionOptions  = this.dropdownService.mesureProtection;
  }
  get civilityF() { return this.form?.get('civility');}
  get lastNameF() { return this.form?.get('lastName');}
  get firstNameF() { return this.form?.get('firstName');}
  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(this.fullNameDisabled) {
      this.form.controls['lastName'].disable();
      this.form.controls['firstName'].disable();
    }
    if (value) {
      this.value = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  // FixMe: à voir avec Nico si on doit la supprimer (s'il n'y aura pas d'impact) ou la modifier
  // En mettant cette méthode en commentaire, le nom est affiché correctement au lieu de undefined
  // mais dans le formulaire lastname est undefined et firstname est écrasé
  // setDisabledState(isDisabled: boolean) {
  //   isDisabled ? this.form.disable() : this.form.enable();
  // }

}
