import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CompteRendu,
  LegalPersonQuery,
  NaturalPersonQuery,
  UserQuery,
  ClientOptionValue,
  DropdownOption,
  DropdownOptionsService,
  ClientSearchService,
  ClientType
} from 'common';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ID} from '@datorama/akita';

@UntilDestroy()
@Component({
  selector: 'app-create-meeting-report-form',
  templateUrl: './create-meeting-report-form.component.html',
  styleUrls: ['./create-meeting-report-form.component.scss']
})
export class CreateMeetingReportFormComponent implements OnInit {

  @Input() meetingReport?: CompteRendu;
  form: UntypedFormGroup;
  isFormReady = false;
  currentDate = new Date();
  floatCommaDotFilter = /^[\d.,\/]+$/;

  authenticatedUserId: ID | undefined;
  collaboratorPresentOptions: DropdownOption<{id}>[] = [];
  clientsConcernesOptions: DropdownOption<ClientOptionValue>[] = [];
  defaultClientConcerneValue: any;
  defaultReportContent =
    '<div>Autres personnes présentes au rendez-vous :</div>' +
    '<br><br>' +
    '<div>Compte rendu du rendez-vous :</div>';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private userQuery: UserQuery,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery,
    private dropdownService: DropdownOptionsService,
    public clientSearchService: ClientSearchService
  ) { }

  ngOnInit(): void {
    this.userQuery.selectAll().pipe(untilDestroyed(this)).subscribe(
      users => {
        const unsortedCollaboratorPresentOptions: [] | DropdownOption<{id}>[] = [] = users.map(
          user => this.dropdownService.mapUserIntoDropdownOption(user)
        );
        this.collaboratorPresentOptions = unsortedCollaboratorPresentOptions.sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
      }
    );

    this.clientsConcernesOptions = this.dropdownService.clients;
  }

  createFormGroup(authenticatedUserId: ID | undefined, meetingReport?: CompteRendu) {
    this.route.queryParams.subscribe(params => {
      if (+params['naturalPerson']) {
        this.defaultClientConcerneValue = {
          label: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.lastName + ' ' + this.naturalPersonQuery.getEntity(params['naturalPerson'])?.firstName,
          value: {
            id: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.id,
            clientType: ClientType.NATURAL_PERSON
          }
        };
      } else if (+params['legalPerson']) {
        this.defaultClientConcerneValue = {
          label: this.legalPersonQuery.getEntity(params['legalPerson'])?.name,
          value: {
            id: this.legalPersonQuery.getEntity(params['legalPerson'])?.id,
            clientType: ClientType.LEGAL_PERSON
          }
        };
      }
    });

    this.authenticatedUserId = authenticatedUserId;

    if (meetingReport) {
      const collaboratorPresent = meetingReport.collaboratorPresent.map(
        collaborator => {
          return {id: collaborator.id}
        }
      );
      const naturalPersonsConcerned = meetingReport.naturalPersonsConcerned.map(
        personnePhysique => ({
          label: personnePhysique.lastName + ' ' + personnePhysique.firstName,
          value: {
            id: personnePhysique.id,
            clientType: ClientType.NATURAL_PERSON
          }
        })
      );
      const legalPersonsConcerned = meetingReport.legalPersonsConcerned.map(
        legalPerson => ({
          label: legalPerson.name,
          value: {
            id: legalPerson.id,
            clientType: ClientType.LEGAL_PERSON
          }
        })
      );
      const clientsConcerned = [...naturalPersonsConcerned, ...legalPersonsConcerned];

      this.form = this.formBuilder.group({
        meetingDate: new Date(meetingReport.meetingDate),
        subject: meetingReport.subject,
        meetingDuration: meetingReport.meetingDuration,
        content: meetingReport.content,
      });
      this.form.setControl('collaboratorPresent', this.formBuilder.array(collaboratorPresent));
      this.form.setControl('clientsConcernes', this.formBuilder.array(clientsConcerned));
    } else {
      this.form = this.formBuilder.group({
        meetingDate: [new Date(), Validators.required],
        subject: ['', Validators.required],
        collaboratorPresent: this.formBuilder.array([
          [{id:this.authenticatedUserId}, Validators.required]
        ]),
        clientsConcernes: this.formBuilder.array([
          [this.defaultClientConcerneValue ?? [], Validators.required]
        ]),
        meetingDuration: [60, Validators.required],
        content: [this.defaultReportContent, Validators.required],
      });
    }
    this.isFormReady = true;
  }

  convertDurationToNumber(duration: number) {
    const hours = Math.floor(duration) / 60;
    const minutes = (duration - Math.floor(duration)) / 60;

    return hours + minutes;
  }

  addCollaboratorPresent() {
    this.collaboratorPresentF.push(new UntypedFormControl('', Validators.required));
  }

  removeCollaboratorPresent(index: number) {
    this.collaboratorPresentF.removeAt(index);
  }

  addClientConcerne() {
    this.clientsConcernesF.push(this.formBuilder.control(this.clientsConcernesOptions[0].value));
  }

  removeClientConcerne(index: number) {
    this.clientsConcernesF.removeAt(index);
  }

  /* get form from template */
  get meetingDateF() { return this.form?.get('meetingDate');}
  get subjectF() { return this.form?.get('subject');}
  get collaboratorPresentF() { return this.form?.get('collaboratorPresent') as UntypedFormArray;}
  get clientsConcernesF() { return this.form?.get('clientsConcernes') as UntypedFormArray;}
  get meetingDurationF() { return this.form?.get('meetingDuration');}
  get contentF() { return this.form?.get('content');}
  /* eslint-enable */
}
