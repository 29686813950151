<div [formGroup]="form">
  <div class="grid p-fluid">
    <div class="field col-3 w-auto">
      <label for="usPerson">Considéré comme une US person ?</label>
      <app-help-tooltip class="ml-2" [text]="'Qui répond à l’un des 3 critères du FATCA: \n ' +
       '- Détenir la nationalité américaine (même en cas de double nationalité). \n - Détenir un titre permanent de ' +
       'séjour sur le territoire américain. \n - Passer ou entendre passer plus de 31 jours sur le territoire ' +
       'américain au cours de l’année et y avoir passé plus de 183 jours sur les 3 dernières années.'">
      </app-help-tooltip>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.usPerson}">Non</div>
        <p-inputSwitch  id="usPerson"
                        formControlName="usPerson"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.usPerson}">Oui</div>
      </div>
    </div>
    <div class="field col-3 ml-8">
      <label for="vecuEtrange" *ngIf="clientOrAdmin === 'ADMIN'">A-t-il vécu à l’étranger ?</label>
      <label for="vecuEtrange" *ngIf="clientOrAdmin === 'CLIENT'">Avez-vous vécu à l'étranger ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.vecuEtrange}">Non</div>
        <p-inputSwitch  id="vecuEtrange"
                        formControlName="vecuEtrange"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.vecuEtrange}">Oui</div>
      </div>
    </div>
    <div class="field col-3" *ngIf="value.vecuEtrange">
      <label for="vecuEtrangeDixAns">Plus de 10 ans ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.vecuEtrangeDixAns}">Non</div>
        <p-inputSwitch  id="vecuEtrangeDixAns"
                        formControlName="vecuEtrangeDixAns"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.vecuEtrangeDixAns}">Oui</div>
      </div>
    </div>
  </div>
</div>
