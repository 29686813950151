import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { ObjectifStore, ObjectifState } from './objectif.store';

@Injectable({ providedIn: 'root' })
export class ObjectifService extends NgEntityService<ObjectifState> {

  constructor(protected override store: ObjectifStore) {
    super(store);
  }

}
