<div class="question-filtre-content mx-auto">
  <div [formGroup]="form">
    <h2 class="rec text-center mt-8 mb-3">Ce questionnaire concerne :</h2>
    <app-radio-input [items]="clientTypeOptions" formControlName="clientType"></app-radio-input>
    <div class="text-center mt-3 neutral0">
      Si vous souhaitez répondre pour « une personne morale », adressez-vous à votre conseiller.
    </div>

    <h2 class="rec text-center mt-8 mb-3">Vous le remplissez :</h2>
    <app-radio-input [items]="rempliParOptions" formControlName="rempliPar"></app-radio-input>

    <ng-container *ngIf="value.rempliPar === rempliPar.TIERS">
      <h2 class="rec text-center mt-8 mb-3">Cette personne est :</h2>
      <app-radio-input [items]="tiersTypeOptions" formControlName="causeRempliTier"></app-radio-input>

      <h2 class="rec text-center mt-8 mb-3">À propos de vous (l’aidant) :</h2>
      <p-divider></p-divider>
      <div class="grid p-fluid">
        <div class="field col-6">
          <label for="civility">Civilité</label>
          <p-dropdown
            formControlName="tiersCivility"
            id="civility"
            [options]="civilityOptions"
            optionLabel="label"
            optionValue="value"
            [autoDisplayFirst]="false">
          </p-dropdown>
          <lib-field-error *ngIf="civilityF?.errors" [fieldError]="civilityF?.errors"></lib-field-error>

        </div>
      </div>
      <div class="grid p-fluid mb-5">
        <div class="field col-6">
          <label for="tiersFirstName">Prénom</label>
          <input formControlName="tiersFirstName" id="tiersFirstName" type="text" pInputText class="inputfield">
          <lib-field-error *ngIf="firstNameF?.errors" [fieldError]="firstNameF?.errors"></lib-field-error>

        </div>
        <div class="field col-6">
          <label for="tiersLastName">Nom</label>
          <input formControlName="tiersLastName"
                 id="tiersLastName"
                 [value]="value?.tiersLastName"
                 type="text"
                 pInputText
                 class="inputfield" appUpperCaseInput>
          <lib-field-error *ngIf="lastNameF?.errors" [fieldError]="lastNameF?.errors"></lib-field-error>
        </div>

        <div class="field col-12">
          <label for="tiersStructure">Structure (si vous agissez pour le compte d’une association, d’une
            entreprise)</label>
          <input formControlName="tiersStructure" id="tiersStructure" type="text" pInputText class="inputfield">
          <lib-field-error *ngIf="tiersStructureF?.errors" [fieldError]="tiersStructureF?.errors"></lib-field-error>
        </div>
      </div>

      <app-note
        [content]="'CLIENT_PREZ_TEXT_TIERS' | translate"
      ></app-note>
    </ng-container>

  </div>
</div>

