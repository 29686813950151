import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Recueil } from './recueil.model';

export interface RecueilState extends EntityState<Recueil> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'recueil' })
export class RecueilStore extends EntityStore<RecueilState> {

  constructor() {
    super();
  }

}
