import {ID} from '@datorama/akita';
import {Address} from '../../address/state/address.model';
import {Document} from '../../../document/state/document.model';

export interface Immobilier {
  id: ID;
  typeBien?: string;
  natureBien?: string;
  valeur?: number;
  detenteur?: string;
  modeDetention?: string;
  address: Address;
  credit?: boolean;
  typeCredit?: string;
  souscripteur?: string;
  genereRevenus?: boolean;
  revenusAnnuels?: number;
  detenuSciOrScOrSarl?: boolean;
  companyName?: string;
  partsDetenues?: number;
  attestNotarial?: Document;
  taxeFonciere?: Document;
  titrePropriete?: Document;
  statutSci?: Document;
  amortissement?: Document;
  offrePret?: Document;
  otherDocs?: Document[];

}

export function createImmobilier(params: Partial<Immobilier>) {
  return {

  } as Immobilier;
}
