<h2 class="mt-4">Liste des utilisateurs</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async), else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div class="w-12 m-auto">
    <p-table #dt [value]="userList" [loading]="loading" [rows]="10" [paginator]="true" [globalFilterFields]="['firstName', 'lastName', 'email', 'poles', 'roles']" responsiveLayout="scroll"
             [rowHover]="true" dataKey="id" currentPageReportTemplate="{first} à {last} de {totalRecords}" [showCurrentPageReport]="true"
             [scrollHeight]="loading? '500px' : ''" (onPage)="onPageChange($event)" [first]="pageOffset"
    >
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" #textInput (input)="dt.filterGlobal(textInput.value, 'contains')" placeholder="Recherche" />
        </span>
          <p-button label="Ajouter un utilisateur" icon="pi pi-plus" styleClass="p-button-raised p-button-text mr-2" (click)="openNewUser()"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header" >
        <tr>
          <th class="px-5" pSortableColumn="firstName">Nom <p-sortIcon field="lastName"></p-sortIcon></th>
          <th class="px-5" pSortableColumn="lastName">Prénom <p-sortIcon field="firstName"></p-sortIcon></th>
          <th class="px-5" pSortableColumn="email">Email <p-sortIcon field="email"></p-sortIcon></th>
          <th class="px-5" pSortableColumn="poles">Pôles <p-sortIcon field="poles"></p-sortIcon></th>
          <th class="px-5" pSortableColumn="roles">Rôles <p-sortIcon field="roles"></p-sortIcon></th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td class="px-5">{{user.lastName}}</td>
          <td class="px-5">{{user.firstName}}</td>
          <td class="px-5">{{user.email}}</td>
          <td class="px-5">
            <ng-container *ngFor="let userPole of user.poles; let last = last;">
              {{ translate(userPole) }}<ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </td>
          <td class="px-5">
            <ng-container *ngFor="let userRole of user.roles; let last = last;">
              {{ translate(userRole) }}<ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </td>
          <td>
            <p-button icon="pi pi-user-edit" styleClass="p-button-raised p-button-text mr-2" (click)="editUser(user)"></p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div class="flex align-items-center justify-content-between">
          Au total il y a {{ userList ? userList.length : 0 }} utilisateurs.
        </div>
      </ng-template>
    </p-table>
  </div>
</ng-template>

  <p-confirmDialog
    [style]="{width: '450px'}"
    rejectLabel="Non"
    acceptLabel="Oui"
    acceptButtonStyleClass="p-button-success"
    rejectButtonStyleClass="p-button-danger"
  >
  </p-confirmDialog>
  <p-toast></p-toast>
