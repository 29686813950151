<div [formGroup]="form" class="mt-5">
  <h3 class="rec">{{'STEP_VOUS_ETAT_CIVIL' | translate}}</h3>
  <p-divider></p-divider>
  <app-info-civile-form formControlName="infoCivile" [advanced]=true></app-info-civile-form>

  <h3 class="rec">{{'STEP_VOUS_SITUATION_CIVILE' | translate}}</h3>
  <p-divider></p-divider>
  <app-situation-matrimoniale-form formControlName="situationMatrimoniale"></app-situation-matrimoniale-form>

  <h3 class="rec">{{'STEP_VOUS_CONTACT' | translate}}</h3>
  <p-divider></p-divider>
  <app-contact-form [clientOrAdmin]="'ADMIN'" [client]="client" formControlName="contact"></app-contact-form>

  <h3 class="rec">{{'STEP_VOUS_JOB' | translate}}</h3>
  <p-divider></p-divider>
  <app-situation-pro-form formControlName="situationPro"></app-situation-pro-form>

  <h3 class="rec">Informations complémentaires</h3>
  <p-divider></p-divider>
  <app-celebre-form formControlName="celebre"></app-celebre-form>
  <app-etranger-form [clientOrAdmin]="'ADMIN'" formControlName="etranger"></app-etranger-form>
  <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
  <p-divider class="mt-5"></p-divider>
  <app-comment-form formControlName="comConseilleInformation"></app-comment-form>
</div>
