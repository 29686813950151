  <h2 class="mt-5">Timeline</h2>
    <ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
      <app-desktop-only></app-desktop-only>
    </ng-container>
    <ng-template #desktop class="container">
      <ng-container *ngIf="fullcalendar && filterValues && calendarOptions">
      <div class="grid card mt-5">
        <div class="col-4 p-5">
          <p class="m-0 mb-2 text-primary font-semibold pl-0">Période</p>
          <div class="mt-2 divider"></div>
          <div class="mt-3">
            <div class="flex">
              <p-button icon="pi pi-chevron-left" styleClass="h-3rem w-3rem" (click)="fullcalendar.getApi().prev(); onWeekNavigation()"></p-button>
              <p class="m-0 mx-3 p-2 line-height-4 flex-grow-1 text-center border-round border-1 border-primary">{{ fullcalendar.getApi()?.view?.title }}</p>
              <p-button icon="pi pi-chevron-right" styleClass="h-3rem w-3rem" (click)="fullcalendar.getApi().next(); onWeekNavigation()"></p-button>
            </div>
            <p-button icon="pi pi-calendar" class="bg-primary" styleClass="w-full mt-3 py-3" label="Afficher la semaine en cours" (click)="fullcalendar.getApi().today(); onWeekNavigation();"></p-button>
            <!-- AVERTISSEMENT TÂCHES NON TERMINÉES SUR LES SEMAINES PRÉCÉDENTES -->
            <ng-container *ngIf="showUnfinishedTasksWarning">
              <div class="mt-4 flex align-items-center text-danger">
                <i class="pi pi-directions-alt" style="font-size: 1.2rem"></i>
                <p class="m-0 ml-3 text-sm ">Il y a des tâches dépassées sur les semaines précédentes</p>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-8 p-5">
          <p class="m-0 mb-2 text-primary font-semibold pl-0">Type de tâche</p>
          <div class="mt-2 divider"></div>
          <div class="mt-3">
            <div class="grid">
              <div class="col-4">
                <p-button styleClass="w-full py-3 {{ (filterValues[timelineFilter.TYPE] === null ||
                          filterValues[timelineFilter.TYPE] === undefined) ? '' : 'p-button-outlined' }}"
                          (onClick)="filterTasks(null, timelineFilter.TYPE)"
                          label="TOUTES"></p-button>
              </div>
              <div class="col-4">
                <p-button styleClass="w-full py-3 {{ filterValues[timelineFilter.TYPE] === 'client' ? '' : 'p-button-outlined' }}"
                          (onClick)="filterTasks('client', timelineFilter.TYPE)"
                          label="CLIENTS"></p-button>
              </div>
              <div class="col-4">
                <p-button styleClass="w-full py-3 {{ filterValues[timelineFilter.TYPE] === 'internal' ? '' : 'p-button-outlined' }}"
                          (onClick)="filterTasks('internal', timelineFilter.TYPE)"
                          label="INTERNES"></p-button>
              </div>
            </div>
          </div>
          <!-- TITRE DE SECTION -->
          <p class="m-0 mt-4 mb-2 text-primary font-semibold pl-0">Filtres</p>
          <div class="mt-2 divider"></div>
          <!-- FILTRES -->
          <div class="mt-3 grid">
            <div class="col-3">
              <!-- ÉTAPE -->
              <p-dropdown
                appendTo="body"
                styleClass="w-full"
                [options]="stepOptions"
                optionLabel="label"
                optionValue="value"
                emptyFilterMessage="Aucune étape trouvée"
                placeholder="Étape"
                [(ngModel)]="filterValues[timelineFilter.STEP]"
                [autoDisplayFirst]="false"
                [showClear]="true"
                (onChange)="filterTasks($event.value, timelineFilter.STEP)"
              ></p-dropdown>
            </div>
            <!-- TÂCHE -->
            <div class="col-3">
              <p-dropdown
                appendTo="body"
                styleClass="w-full"
                [options]="taskOptions"
                optionLabel="label"
                optionValue="value"
                emptyFilterMessage="Aucune tâche trouvée"
                placeholder="Tâche"
                [(ngModel)]="filterValues[timelineFilter.TASK]"
                [autoDisplayFirst]="false"
                [showClear]="true"
                [group]="true"
                (onChange)="filterTasks($event.value, timelineFilter.TASK)"
              ></p-dropdown>
            </div>
            <div class="col-3">
              <!-- CLIENT -->
              <p-dropdown
                appendTo="body"
                styleClass="w-full"
                [options]="clientOptions"
                optionLabel="label"
                optionValue="value"
                emptyFilterMessage="Aucun client trouvé"
                placeholder="Client"
                [(ngModel)]="filterValues[timelineFilter.CLIENT]"
                [filter]="true"
                [autoDisplayFirst]="false"
                [showClear]="true"
                [resetFilterOnHide]="true"
                (onChange)="filterTasks($event.value, timelineFilter.CLIENT)"
              ></p-dropdown>
            </div>
            <div class="col-3">
              <!-- COLLABORATEUR / PÔLE -->
              <p-dropdown
                appendTo="body"
                styleClass="w-full"
                [group]="true"
                [options]="poleAndCollaboratorOptions"
                optionLabel="label"
                optionValue="value"
                emptyFilterMessage="Aucun collaborateur / pôle trouvé"
                placeholder="Collaborateur / Pôle"
                [(ngModel)]="filterValues[timelineFilter.POLE_AND_COLLABORATOR]"
                [autoDisplayFirst]="false"
                [showClear]="true"
                (onChange)="filterTasks($event.value, timelineFilter.POLE_AND_COLLABORATOR)"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
      </ng-container>
      <full-calendar #fullcalendar [options]="calendarOptions" [deepChangeDetection]="true" ></full-calendar>
    </ng-template>


