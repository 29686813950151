import {Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';
import {
  DocumentType, NaturalNaturalRelationService,
  NaturalNaturalRelation,
  NaturalNaturalRelationTypeEnums, NavigationService
} from 'common';
import {ID} from '@datorama/akita';

@UntilDestroy()
@Component({
  selector: 'app-recueil-a-charge',
  templateUrl: './recueil-a-charge.component.html',
  styleUrls: ['./recueil-a-charge.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilAChargeComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilAChargeComponent),
      multi: true
    }
  ]
})
export class RecueilAChargeComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('title') title: ElementRef;
  @Input() clientId: ID | undefined;
  @Input() clientRelations: NaturalNaturalRelation[] = [];
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  documentType = DocumentType;
  documentTypes: { code: string; field: string }[] = [];
  collapsedPanelsEnfants: {collapsed: boolean}[] = [];
  collapsedPanelsCharge: {collapsed: boolean}[] = [];
  initialControlValue =
    {
      relationID: null,
      id: null,
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: false,
        dead: false,
        deathDate: null,
      },
      enfantDe: null,
      adopted: null,
      situationHandicap: null,
      lienPersonne: null,
      pi: null
    };


  constructor(private formBuilder: UntypedFormBuilder,
              private naturalNaturalRelationService: NaturalNaturalRelationService,
              private navigation: NavigationService) {

    this.form = this.formBuilder.group({
      enfants: this.formBuilder.array([]),
      personnesCharge: this.formBuilder.array([]),
      comPersonneACharge: null,
      docs: {
        livretFamille: null,
        otherDocs: null,
      }
    });

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        const { docs, ...rest } = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
  }

  get enfantControl() {
    return this.form.get('enfants') as UntypedFormArray;
  }

  get personnesChargeControl() {
    return this.form.get('personnesCharge') as UntypedFormArray;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  writeValue(value: any) {
    if (value.enfants) {
      this.collapsedPanelsEnfants = [];
      while (this.enfantControl.length !== 0) {
        this.enfantControl.removeAt(0);
      }
      value.enfants.map((val: any) => {
        this.enfantControl.push(this.formBuilder.control({}));
        this.collapsedPanelsEnfants = [...this.collapsedPanelsEnfants, {collapsed: true}];
      });
      this.form.markAsPristine();
    }

    if (value.personnesCharge) {
      this.collapsedPanelsCharge = [];
      while (this.personnesChargeControl.length !== 0) {
        this.personnesChargeControl.removeAt(0);
      }
      value.personnesCharge.map((val: any) => {
        this.personnesChargeControl.push(this.formBuilder.control({}));
        this.collapsedPanelsCharge = [...this.collapsedPanelsCharge, {collapsed: true}];
      });
      this.form.markAsPristine();
    }
    if (value) {
      const {
        livretFamille,
        otherDocs,
        ...rest
      } = value;
      this.value = {
        ...rest, docs: {
          livretFamille: value.livretFamille,
          otherDocs: value.otherDocs
        }
      };
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {valid: false};
  }

  add(type: string) {
    if (type === 'ENFANT') {
      this.enfantControl.push(this.formBuilder.control({...this.initialControlValue, aChargeFiscale: null}));
      this.collapsedPanelsEnfants.map(panel=>panel.collapsed = true);
      this.collapsedPanelsEnfants = [...this.collapsedPanelsEnfants, {collapsed: false}];
      this.navigation.scrollToTop(this.title.nativeElement.offsetTop);
    }
    if (type === 'ACHARGE') {
      this.personnesChargeControl.push(this.formBuilder.control({
        ...this.initialControlValue,
        aChargeFiscale: 'CHARGE_TOTALE'
      }));
      this.collapsedPanelsCharge.map(panel=>panel.collapsed = true);
      this.collapsedPanelsCharge = [...this.collapsedPanelsCharge, {collapsed: false}];
    }
  }

  remove(index: number, type: string) {
    if (type === 'ENFANT') {
      const clientRelationsEnfants = this.clientRelations.filter((relation: NaturalNaturalRelation) => relation.type.label === NaturalNaturalRelationTypeEnums.PARENT);
      clientRelationsEnfants?.map((relation) => {
        if (relation.secondNaturalPerson.id === this.enfantControl.value[index].id) {
          this.naturalNaturalRelationService.delete(relation.id).subscribe();
        }
      });
      this.enfantControl.removeAt(index);
    }
    if (type === 'ACHARGE') {
      const clientRelationsACharge = this.clientRelations.filter((relation: NaturalNaturalRelation) => relation.type.label === NaturalNaturalRelationTypeEnums.ENFANT ||
        relation.type.label === NaturalNaturalRelationTypeEnums.FRATRIE ||
        relation.type.label === NaturalNaturalRelationTypeEnums.PETIT_ENFANT ||
        relation.type.label === NaturalNaturalRelationTypeEnums.AUTRE_FAMILLE);
      clientRelationsACharge?.map((relation) => {
        if (relation.secondNaturalPerson.id === this.personnesChargeControl.value[index].id) {
          this.naturalNaturalRelationService.delete(relation.id).subscribe();
        }
      });
      this.personnesChargeControl.removeAt(index);
    }
  }

}
