import {ID} from '@datorama/akita';

export interface Translation {
  id: ID;
  code?: string;
  value?: string;
}

export function createTranslation(params: Partial<Translation>) {
  return {

  } as Translation;
}
