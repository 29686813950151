<div #title></div>
<h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Crédits</h2>
<h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">{{'CLIENT_STEP_COMPLEMENT_SUBTITLE1' | translate}}</h2>
<p-divider></p-divider>

<div class="grid p-fluid align-items-end">
  <div class="field col-3">
    <label for="objetPret">Objet du prêt</label>
    <p-dropdown
      [(ngModel)]="selectedavoirObjetPret"
      id="objetPret"
      [options]="objetPretOptions"
      optionLabel="label"
      optionValue="value"
      [autoDisplayFirst]="false"
      placeholder="..."
    >
    </p-dropdown>
  </div>
  <div class="field col-3">
    <div class="inline-block"
         pTooltip="Vous devez séléctionner un type d'avoir financier"
         [tooltipDisabled]="selectedavoirObjetPret !== null">
      <p-button label="Ajouter un crédit" icon="pi pi-plus" styleClass="p-button-raised mr-2"
                [disabled]="!selectedavoirObjetPret"
                (onClick)="add()"></p-button>
    </div>
  </div>
</div>

<div [formGroup]="form" class="mt-5">
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <ng-container formArrayName="creditNonImmobiliers">
      <ng-container *ngFor="let item of creditControl?.controls; index as i">
        <p-fieldset legend="{{item.value['objetPret'] | translate}}" [toggleable]="true" styleClass="mb-3" [(collapsed)]="collapsedPanels[i].collapsed">
          <div class="flex justify-content-end mb-3">
            <button pButton type="button" icon="icon-delete font-icon" class="p-button-raised p-button-text" (click)="remove(i)"></button>
          </div>
          <app-credit-form [formControlName]="i"></app-credit-form>
        </p-fieldset>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <ng-container formArrayName="creditNonImmobiliers">
      <p-accordion *ngFor="let item of creditControl?.controls; index as i" styleClass="mb-6">
        <p-accordionTab [(selected)]="!collapsedPanels[i].collapsed">
          <ng-template pTemplate="header">
            <div class="flex justify-content-between w-full align-items-center">
              <h3 class="rec">{{item.value['objetPret'] | translate}}</h3>
              <button pButton type="button" icon="icon-delete font-icon" class="p-button-raised p-button-text" (click)="remove(i)"></button>
            </div>
          </ng-template>
          <app-credit-form [formControlName]="i" [clientId]="client.id" [clientType]="clientType" [clientOrAdmin]="'CLIENT'"></app-credit-form>
        </p-accordionTab>
      </p-accordion>
    </ng-container>
  </ng-container>

  <h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Revenus fiscaux</h2>
  <h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">{{'CLIENT_STEP_COMPLEMENT_SUBTITLE2' | translate}}</h2>

  <p-divider></p-divider>
  <app-impot-form [clientOrAdmin]="clientOrAdmin" formControlName="impot"></app-impot-form>
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comConseilleRevenuFiscaux"></app-comment-form>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <app-documents-form
      formControlName="docsImpot"
      [clientId]="clientId"
      [clientType]="clientType"
      [documentTypes]="[documentType.IMPOT_AVIS,
              documentType.IMPOT_DECLARATION]"></app-documents-form>
  </ng-container>

  <h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Donations et testaments</h2>
  <h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">{{'CLIENT_STEP_COMPLEMENT_SUBTITLE3' | translate}}</h2>

  <p-divider></p-divider>
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <app-donation-form formControlName="donation" [clientOrAdmin]="'CLIENT'"></app-donation-form>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <app-donation-form formControlName="donation" [clientOrAdmin]="'CLIENT'"></app-donation-form>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comConseilleDonation" ></app-comment-form>
  </ng-container>
  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <app-documents-form *ngIf="getDonationRequiredDocs(value).length"
      formControlName="docsDonation"
      [clientId]="clientId"
      [clientType]="clientType"
      [documentTypes]="getDonationRequiredDocs(value)"></app-documents-form>
  </ng-container>

  <h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Contrats de prévoyance</h2>
  <h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">{{'CLIENT_STEP_COMPLEMENT_SUBTITLE4' | translate}}</h2>

  <p-divider></p-divider>

  <app-prevoyance-form [clientOrAdmin]="clientOrAdmin" formControlName="prevoyance"></app-prevoyance-form>

  <ng-container *ngIf="clientOrAdmin === 'CLIENT'">
    <app-documents-form *ngIf="value.prevoyance.contratPrevoyance"
      formControlName="docsPrevoyance"
      [clientId]="clientId"
      [clientType]="clientType"
      [documentTypes]="[documentType.PREVOYANCE_CERTIF,
              documentType.PREVOYANCE_CONDITION,
              documentType.AUTRE_PREVOYANCE]"></app-documents-form>

    <h3 class="rec mt-6">{{'CLIENT_STEP_COMPLEMENT_COMMENT' | translate}}</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comComplement"></app-comment-form>
  </ng-container>

  <ng-container *ngIf="clientOrAdmin === 'ADMIN'">
    <app-comment-read *ngIf="client.recueil !== undefined"
                      title="Informations complémentaires client"
                      [content]="client.recueil?.comComplement"
    ></app-comment-read>
  </ng-container>

</div>
