import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NaturalPersonStore, NaturalPersonState } from './natural-person.store';

@Injectable({ providedIn: 'root' })
export class NaturalPersonQuery extends QueryEntity<NaturalPersonState> {

  constructor(protected override store: NaturalPersonStore) {
    super(store);
  }

}
