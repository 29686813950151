<div [formGroup]="form" class="mt-5">
  <app-info-civile-form formControlName="infoCivile" [personACharge]="true" ></app-info-civile-form>
  <div class="grid p-fluid">
    <div class="field col-3" *ngIf="type === 'ENFANT'">
      <label for="aChargeFiscale">A charge fiscalement</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="aChargeFiscale"
        id="aChargeFiscale"
        [options]="aCchargeFiscalOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [scrollHeight]="'140px'"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="type === 'ENFANT'">
      <label for="enfantDe">Enfant de</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="enfantDe"
        id="enfantDe"
        [options]="enfantDeOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
        [scrollHeight]="'140px'"
      >
      </p-dropdown>
    </div>
    <div class="field col-3">
      <label
        for="situationHandicap">{{type === 'ENFANT' ? 'Enfant porteur de handicap' : 'Situation de handicap' }} </label>
      <p-dropdown
        styleClass="w-full"
        formControlName="situationHandicap"
        id="situationHandicap"
        [options]="enfantHandicapOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
        [scrollHeight]="'100px'"
      >
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="type === 'ENFANT'">
      <label for="adopted">Enfant adopté</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="adopted"
        id="adopted"
        [options]="adoptionOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
        [scrollHeight]="'100px'"
      >
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="type === 'ACHARGE'">
      <label for="lienPersonne">Lien avec la personne</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="lienPersonne"
        id="lienPersonne"
        [options]="lienPersonneOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="lienPersonneF?.errors" [fieldError]="lienPersonneF?.errors"></lib-field-error>
    </div>
    <app-documents-form class="docs" formControlName="docs"
                        [clientId]="clientId"
                        [clientType]="'NATURAL_PERSON'"
                        [documentTypes]="documentTypes"></app-documents-form>
  </div>
</div>
