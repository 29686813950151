<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop class="container">
  <h2 class="mt-5">Revue du lundi</h2>
  <div class="grid">
  <p-tabView
    class="col-12"
    (onChange)="switchMondayDashboard(currentMondayDashboardView === mondayDashboardView.DONE ? mondayDashboardView.PROGRESS : mondayDashboardView.DONE)"
    [activeIndex]="currentMondayDashboardView === mondayDashboardView.DONE ? 0 : 1"
  >
    <!-- HEADER FOR TABLE TACHE TERMINEES -->
    <div [formGroup]="form" *ngIf="currentMondayDashboardView === mondayDashboardView.DONE" class="flex p-0 mt-2 monday-dashboard-header text-800">
      <!-- COLUMN DATE PICKER -->
      <div class="col-3 p-5">
        <p class="m-0 mb-2 text-primary font-semibold pl-0">Clôturées depuis</p>
        <div class="mt-2 divider"></div>
        <p-calendar appendTo="body"
                    id="dashboard-done-date-input"
                    formControlName="datePickerDashboardDone"
                    dateFormat="dd/mm/yy"
                    styleClass="mt-3 h-3rem datepicker w-full"
                    [showIcon]="true"
                    [firstDayOfWeek]=1
                    (onSelect)="handleDoneDateSelect($event)"
                    [minDate]="mondayWeekdayTwoMonthsAgoDate"
                    [maxDate]="currentDate"
        ></p-calendar>
      </div>
      <!-- COLUMN ACTION BUTTONS -->
      <div class="col-9 p-5">
        <p class="m-0 mb-2 text-primary font-semibold pl-0">Type de tâche</p>
        <div class="mt-2 divider"></div>
        <div class="mt-3">
          <div class="grid">
            <div class="col-4">
              <p-button
                label="TOUTES"
                styleClass="w-full py-3 {{ mondayDashboardDoneTaskTypeFilter === mondayDashboardTaskType.ALL ? '' : 'p-button-outlined' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.ALL, mondayDashboardView.DONE, $event)">
              </p-button>
            </div>
            <div class="col-4">
              <p-button
                label="CLIENTS"
                styleClass="w-full py-3 {{ mondayDashboardDoneTaskTypeFilter === mondayDashboardTaskType.CUSTOMER ? '' : 'p-button-outlined' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.CUSTOMER, mondayDashboardView.DONE, $event)">
              </p-button>
            </div>
            <div class="col-4">
              <p-button
                label="INTERNES"
                styleClass="w-full py-3 {{ mondayDashboardDoneTaskTypeFilter === mondayDashboardTaskType.INTERNAL ? '' : 'p-button-outlined' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.INTERNAL, mondayDashboardView.DONE, $event)">
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- HEADER FOR TABLE TACHE A FAIRE -->
    <div [formGroup]="form" *ngIf="currentMondayDashboardView === mondayDashboardView.PROGRESS" class="flex p-0 mt-2 monday-dashboard-header text-800">
      <!-- COLUMN DATE PICKER -->
      <div class="col-3 p-5">
        <p class="m-0 mb-2 text-primary font-semibold pl-0">Programmées jusqu'au</p>
        <div class="mt-2 divider"></div>
        <p-calendar appendTo="body"
                    id="dashboard-progress-date-input"
                    formControlName="datePickerDashboardProgress"
                    dateFormat="dd/mm/yy"
                    styleClass="mt-3 h-3rem datepicker w-full"
                    [showIcon]="true"
                    [firstDayOfWeek]=1
                    (onSelect)="handleProgressDateSelect($event)"
                    [minDate]="currentDate"
        ></p-calendar>
      </div>
      <!-- COLUMN ACTION BUTTONS -->
      <div class="col-9 p-5">
        <p class="m-0 mb-2 text-primary font-semibold pl-0">Type de tâche</p>
        <div class="mt-2 divider"></div>
        <div class="mt-3">
          <div class="grid">
            <div class="col-4">
              <p-button
                label="TOUTES"
                class="bg-primary"
                styleClass="w-full py-3 {{ mondayDashboardProgressTaskTypeFilter === mondayDashboardTaskType.ALL ? '' : 'bg-white text-primary' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.ALL, mondayDashboardView.PROGRESS, $event)">
              </p-button>
            </div>
            <div class="col-4">
              <p-button
                label="CLIENTS"
                class="bg-primary"
                styleClass="w-full py-3 {{ mondayDashboardProgressTaskTypeFilter === mondayDashboardTaskType.CUSTOMER ? '' : 'bg-white text-primary' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.CUSTOMER, mondayDashboardView.PROGRESS, $event)">
              </p-button>
            </div>
            <div class="col-4">
              <p-button
                label="INTERNES"
                class="bg-primary"
                styleClass="w-full py-3 {{ mondayDashboardProgressTaskTypeFilter === mondayDashboardTaskType.INTERNAL ? '' : 'bg-white text-primary' }}"
                (click)="handleFilteringTaskType(mondayDashboardTaskType.INTERNAL, mondayDashboardView.PROGRESS, $event)">
              </p-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PANEL VIEW FOR TABLE TACHE TERMINEES -->
    <p-tabPanel header="Tâches terminées">
      <p-table #mondayReviewTableDone
               [rows]="20"
               [paginator]="true"
               [alwaysShowPaginator]="false"
               styleClass="mt-5"
               dataKey="id"
               [value]="doneTasks"
               [filterDelay]="0"
               responsiveLayout="scroll"
               stateStorage="local"
               stateKey="dashboard_monday_review_done"
               (onStateRestore)="onDashboardStateRestore($event, mondayDashboardView.DONE)"
      >
        <!-- COLUMNS HEADER & SUB HEADER -->
        <ng-template pTemplate="header" [formGroup]="form">
          <!-- COLUMNS HEADER -->
          <tr>
            <!-- HEADER DATE CLOTURE -->
            <th class="text-sm">
              Date de clôture
            </th>
            <!-- HEADER IMPORTANCE -->
            <th class="text-sm" pSortableColumn="importance">
              Importance
              <p-sortIcon class="mr-2" field="importance"></p-sortIcon>
            </th>
            <!-- HEADER ETAPE -->
            <th class="text-sm">
              Étape client / Tâche
            </th>
            <!-- HEADER CHARGE -->
            <th class="text-sm text-center">
              Charge
            </th>
            <!-- HEADER CLIENT -->
            <th class="text-sm" pSortableColumn="legalAndNaturalCustomer.nameLabel">
              Client
              <p-sortIcon class="ml-auto" field="legalAndNaturalCustomer.nameLabel"></p-sortIcon>
            </th>
            <!-- HEADER COLLABORATEUR -->
            <th class="text-sm">
              Collaborateur / Pôle
            </th>
            <!-- ACTION BUTTON -->
            <th>
            </th>
          </tr>
          <!-- COLUMNS SUB HEADER -->
          <tr>
            <!-- REINIT BUTTON -->
            <th>
              <button id="reinitFilterButtonDashboardDone"
                      pButton
                      class="p-button-raised p-button-sm bg-white text-black border-none"
                      icon="pi pi-filter-slash" label="Réinitialiser"
                      (click)="clearFilters(mondayDashboardView.DONE, $event)">
              </button>
            </th>
            <!-- FILTRE IMPORTANCE -->
            <th>
              <p-dropdown #filterImportanceDashboardDone
                          styleClass="w-8rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="importanceFilterDashboardDone"
                          [showClear]="true"
                          [options]="importanceOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.IMPORTANCE, mondayDashboardView.DONE)"
              ></p-dropdown>
            </th>
            <!-- FILTRE STEP AND TACHE -->
            <th class="flex px-0">
              <p-dropdown #filterStepDashboardDone
                          styleClass="w-10rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="stepFilterDashboardDone"
                          [showClear]="true"
                          [options]="filteredStepsOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.STEP, mondayDashboardView.DONE)"
              ></p-dropdown>
              <p-dropdown #filterTaskDashboardDone
                            styleClass="w-10rem ml-3"
                            appendTo="body"
                            placeholder="..."
                            formControlName="taskFilterDashboardDone"
                            [group]="true"
                            [showClear]="true"
                            [options]="stepRelatedTaskOptions"
                            (onChange)="handleFiltering($event.value, dashboardFiltersType.STEPTASK, mondayDashboardView.DONE)"
                ></p-dropdown>
              </th>
            <!-- NO FILTRE COLUMN CHARGE PREVUE -->
            <th>
            </th>
            <!-- FILTRE CLIENT -->
            <th>
              <p-dropdown #filterCustomerDashboardDone
                          styleClass="w-10rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="customerFilterDashboardDone"
                          [filter]="true"
                          [showClear]="true"
                          [options]="filteredCustomerOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.CUSTOMER, mondayDashboardView.DONE)"
              ></p-dropdown>
            </th>
            <!-- FILTRE COLLABORATEUR AND POLE -->
            <th>
              <p-dropdown #filterCollaboratorAndPoleDashboardDone
                          styleClass="w-11rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="collaboratorAndPoleFilterDashboardDone"
                          [group]="true"
                          [showClear]="true"
                          [options]="poleAndCollaboratorOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE, mondayDashboardView.DONE)"
              ></p-dropdown>
            </th>
            <!-- NO FILTRE COLUMN ACTION BUTTON -->
            <th>
            </th>
          </tr>
        </ng-template>
        <!-- ROW BODY -->
        <ng-template pTemplate="body" let-task>
          <tr class="tableRow">
            <!-- CELL DATE DE CLOTURE -->
            <td class="col-1">
              <p class="text-sm font-bold m-0 mt-1">
                {{task.closingDate | date: 'dd/MM/yyyy'}}
              </p>
            </td>
            <!-- CELL IMPORTANCE -->
            <td class="col-1">
              <div *ngIf="task.importance && task.importance === 2"
                   class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                <i class="pi pi-euro" style="font-size: 0.5rem"></i>
              </div>
              <div *ngIf="task.importance && task.importance === 3"
                   class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                <i class="pi pi-euro" style="font-size: 0.5rem"></i>
              </div>
            </td>
            <!-- CELL ETAPE AND TACHE -->
            <td class="col-4">
              <p class="text-sm m-0">{{ task.step?.name }}</p>
              <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
            </td>
            <!-- CELL CHARGE PREVUE -->
            <td class="col-2 text-center">
              <p class="text-sm font-bold m-0 mt-1">
                {{task.plannedWorkload | minutesToDuration}}
                <span *ngIf="task.plannedWorkload !== task.realWorkload" class="text-sm font-bold ml-1">
                  <i class="pi pi-arrow-right mr-1"
                     style="font-size: 0.7rem"></i> {{task.realWorkload | minutesToDuration }}
              </span>
              </p>
            </td>
            <!-- CELL CLIENT -->
            <td class="col-2">
              <p-tag *ngFor="let naturalPerson of task?.naturalPersons"
                     styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                     value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                     [rounded]="true"
                     (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                     pTooltip="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                     [showDelay]="500"
              >
              </p-tag>
              <p-tag *ngFor="let legalPerson of task?.legalPersons"
                     styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-secondary text-white border-1 cursor-pointer"
                     value="{{ legalPerson.name }}"
                     [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')"
                     pTooltip="{{ legalPerson.name }}"
                     [showDelay]="500"
              >
              </p-tag>
            </td>
            <!-- CELL COLLABORATEUR -->
            <td class="col-1">
              <div class="flex flex-column">
                <p-tag *ngIf="task.collaborator"
                       styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                       value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                       [rounded]="true">
                </p-tag>
                <p-tag *ngIf="task.pole"
                       styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                       value="{{ translate(task.pole) }}"
                       [rounded]="true">
                </p-tag>
              </div>
            </td>
            <td>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-eye"
                class="mr-2 p-button-raised bg-white text-primary border-none"
                (click)="onEditTaskClick(task)"
              >
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <!-- PANEL VIEW FOR TABLE TACHE A FAIRE -->
    <p-tabPanel header="Tâches à faire">
      <p-table #mondayReviewTableProgress
               [formGroup]="form"
               styleClass="mt-5"
               dataKey="id"
               [value]="progressTasks"
               [filterDelay]="0"
               responsiveLayout="scroll"
               stateStorage="local"
               stateKey="dashboard_monday_review_progress"
               (onStateRestore)="onDashboardStateRestore($event, mondayDashboardView.PROGRESS)"
      >
        <!-- COLUMNS HEADER & SUB HEADER -->
        <ng-template pTemplate="header" [formGroup]="form">
          <!-- COLUMNS HEADER -->
          <tr>
            <!-- HEADER DATE ECHEANCE -->
            <th class="text-sm">
              Date d'échéance
            </th>
            <!-- HEADER IMPORTANCE -->
            <th class="text-sm" pSortableColumn="importance">
              Importance
              <p-sortIcon class="mr-2" field="importance"></p-sortIcon>
            </th>
            <!-- HEADER ETAPE AND TACHE -->
            <th class="text-sm">
              Étape client / Tâche
            </th>
            <!-- HEADER CLIENT -->
            <th class="text-sm" pSortableColumn="legalAndNaturalCustomer.nameLabel">
              Client
              <p-sortIcon class="ml-auto" field="legalAndNaturalCustomer.nameLabel"></p-sortIcon>
            </th>
            <!-- HEADER POLE -->
            <th class="text-sm">
              Pôle
            </th>
            <!-- HEADER COLLABORATEUR -->
            <th class="text-sm">
              Collaborateur
            </th>
            <!-- ACTION BUTTONS -->
            <th>
            </th>
          </tr>
          <!-- COLUMNS SUB HEADER -->
          <tr>
            <!-- REINIT BUTTON -->
            <th>
              <button id="reinitFilterButtonDashboardProgress"
                      pButton
                      class="p-button-raised p-button-sm bg-white text-black border-none"
                      icon="pi pi-filter-slash" label="Réinitialiser"
                      (click)="clearFilters(mondayDashboardView.PROGRESS, $event)">
              </button>
            </th>
            <!-- FILTRE IMPORTANCE -->
            <th>
              <p-dropdown #filterImportanceDashboardProgress
                          styleClass="w-10rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="importanceFilterDashboardProgress"
                          [showClear]="true"
                          [options]="importanceOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.IMPORTANCE, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
            </th>
            <!-- FILTRE STEP AND TACHE -->
            <th class="flex px-0">
              <p-dropdown #filterStepDashboardProgress
                          styleClass="w-8rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="stepFilterDashboardProgress"
                          [showClear]="true"
                          [options]="filteredStepsOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.STEP, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
              <p-dropdown #filterTaskDashboardProgress
                          styleClass="w-8rem ml-3"
                          appendTo="body"
                          placeholder="..."
                          formControlName="taskFilterDashboardProgress"
                          [group]="true"
                          [showClear]="true"
                          [options]="stepRelatedTaskOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.STEPTASK, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
            </th>
            <!-- FILTRE CLIENT -->
            <th>
              <p-dropdown #filterCustomerDashboardProgress
                          styleClass="w-8rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="customerFilterDashboardProgress"
                          [filter]="true"
                          [showClear]="true"
                          [options]="filteredCustomerOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.CUSTOMER, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
            </th>
            <!-- FILTRE POLE -->
            <th>
              <p-dropdown #filterPoleDashboardProgress
                          styleClass="w-8rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="poleFilterDashboardProgress"
                          [showClear]="true"
                          [options]="poleOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.POLE, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
            </th>
            <!-- FILTRE COLLABORATEUR -->
            <th>
              <p-dropdown #filterCollaboratorDashboardProgress
                          styleClass="w-8rem"
                          appendTo="body"
                          placeholder="..."
                          formControlName="collaboratorFilterDashboardProgress"
                          [showClear]="true"
                          [options]="collaboratorOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATOR, mondayDashboardView.PROGRESS)"
              ></p-dropdown>
            </th>
            <!-- NO FILTRE COLUMN ACTION BUTTONS -->
            <th>
            </th>
          </tr>
        </ng-template>
        <!-- ROW BODY -->
        <ng-template pTemplate="body" let-task let-index="rowIndex">
          <tr class="tableRow">
            <!-- CELL DATE DE CLOTURE -->
            <td class="col-1">
              <p class="text-sm font-bold m-0 mt-1">
                {{task.deadlineDate | date: 'dd/MM/yyyy'}}
              </p>
            </td>
            <!-- CELL IMPORTANCE AND OTHER -->
            <td class="col-3">
              <div class="flex w-12rem">
                <div class="grid align-items-end w-full">
                  <!-- PRIORITY -->
                  <div class="col-2 mr-2">
                    <div *ngIf="task.isPriority"
                         class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                      <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                    </div>
                  </div>
                  <!-- DEADLINE -->
                  <div class="col-2 mr-2">
                    <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                      <div
                        [ngClass]="{'bg-warning': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                        class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                        <svg width="15" height="15" viewBox="0 0 512 512">
                          <path
                            d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                            fill="#ffffff" data-original="#000000" class=""/>
                          <path
                            d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                            fill="#ffffff" data-original="#000000" class=""/>
                        </svg>
                      </div>
                    </ng-template>
                  </div>
                  <!-- IMPORTANCE -->
                  <div class="col-2 mr-2">
                    <div *ngIf="task.importance && task.importance === 2"
                         class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                      <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                    </div>
                    <div *ngIf="task.importance && task.importance === 3"
                         class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                      <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                    </div>
                  </div>
                  <!-- STATUS -->
                  <div class="col-4">
                    <p-tag *ngIf="task.status === taskStatus.DOING"
                           styleClass="px-2 text-xs font-medium vertical-align-middle bg-success"
                           value="doing"
                           [rounded]="true">
                    </p-tag>
                  </div>
                </div>
              </div>
            </td>
            <!-- CELL ETAPE AND TACHE -->
            <td class="col-4">
              <p class="text-sm m-0">{{ task.step?.name }}</p>
              <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
            </td>
            <!-- CELL CLIENT -->
            <td class="col-2">
              <p-tag *ngFor="let naturalPerson of task?.naturalPersons"
                     styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                     class=""
                     value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                     [rounded]="true"
                     (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                     pTooltip="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                     [showDelay]="500"
              >
              </p-tag>
              <p-tag *ngFor="let legalPerson of task?.legalPersons"
                     styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-secondary text-white border-1 cursor-pointer"
                     value="{{ legalPerson.name }}"
                     [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')"
                     pTooltip="{{ legalPerson.name }}"
                     [showDelay]="500"
              >
              </p-tag>
            </td>
            <!-- CELL POLE -->
            <td class="col-1" formArrayName="rowPoleDropdownDashboardProgress">
              <p-dropdown
                styleClass="ml-2 w-8rem"
                appendTo="body"
                formControlName="{{getFormControleName(task)}}"
                (onChange)="onRowPoleDropdownChanges(task, getFormControleName(task))"
                [options]="poleOptions">
              </p-dropdown>
            </td>
            <!-- CELL COLLABORATEUR -->
            <td class="col-1" formArrayName="rowCollaboratorDropdownDashboardProgress">
              <p-dropdown
                styleClass="ml-2 w-8rem"
                appendTo="body"
                formControlName="{{getFormControleName(task)}}"
                (onChange)="onRowCollaboratorDropdownChanges(task, getFormControleName(task))"
                [options]="rowCollaboratorOptions">
              </p-dropdown>
            </td>
            <td>
              <div class="flex ml-auto">
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-pencil"
                  class="mr-2 p-button-raised bg-white text-primary border-none"
                  (click)="onEditTaskClick(task)"
                >
                </button>
                <button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-eye-slash"
                  class="mr-2 p-button-raised bg-white text-primary border-none"
                  (click)="onHideTaskClick(task)"
                >
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>
</ng-template>
