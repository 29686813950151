import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {SessionQuery, SessionService} from 'common';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard  {
  isRoleFound = false;

  constructor(
    private router: Router,
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const roles: string[] = route.data['roles'];
    let found = 0;
    const userSession = this.sessionQuery.getValue();

      roles.map(role => {
        if (userSession.roles.includes(role)) {
          found++;
        }
      });
      this.isRoleFound = found !== 0;
      if(!this.isRoleFound){
        this.sessionService.logout();
        this.router.navigate(['/login']);
      }
      return this.isRoleFound;
  }

}
