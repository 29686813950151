<form [formGroup]="form" *ngIf="formIsReady">
  <div class="grid">
    <div class="field col-6">
      <label for="relationLabel-input">Relation</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="relationLabel"
        id="relationLabel-input"
        [options]="relationLabelOptions"
        optionLabel="label"
        optionValue="value"
        emptyFilterMessage="Aucune relation trouvée"
        [filter]="true"
      [autoDisplayFirst]="false"
      >
        <!--        [virtualScroll]="true"-->
        <!--        [itemSize]="30"-->
      </p-dropdown>
      <lib-field-error *ngIf="relationLabelF?.touched && relationLabelF?.errors" [fieldError]="relationLabelF?.errors"></lib-field-error>
    </div>
    <div class="field col-6">
      <label for="naturalPerson-input">Personne physique</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="naturalPerson"
        id="naturalPerson-input"
        [options]="filterNaturalPersonOptions(allNaturalPerson)"
        optionLabel="label"
        optionValue="value"
        emptyFilterMessage="Aucune personne physique trouvée"
        [virtualScroll]="true"
        [virtualScrollItemSize]="2"
        [filter]="true"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="naturalPersonF?.touched && naturalPersonF?.errors" [fieldError]="naturalPersonF?.errors"></lib-field-error>
    </div>
  </div>
  <div class="grid">
    <div class="field col-3">
      <label for="aChargeFiscale">A charge fiscalement</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="aChargeFiscale"
        id="aChargeFiscale"
        [options]="aCchargeFiscalOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
        [scrollHeight]="'130px'"
      >
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="relationLabelF?.value?.label === 'ENFANT'">
      <label for="enfantDe">Enfant de</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="enfantDe"
        id="enfantDe"
        [options]="enfantDeOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
    </div>
    <div class="field col-3" *ngIf="relationLabelF?.value?.label === 'ENFANT'">
      <label for="adopted">Enfant adopté</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="adopted"
        id="adopted"
        [options]="adoptionOptions"
        optionLabel="label"
        optionValue="value"
        [virtualScroll]="true"
        [itemSize]="30"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
    </div>
  </div>
</form>
