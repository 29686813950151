import {Component, OnInit, ViewChild} from '@angular/core';
import {
  ClientSearchResultQuery,
  LegalPerson,
  NaturalPerson,
  ClientType,
  StepQuery,
  Step,
  TaskStatus,
  DropdownOption,
  LegalPersonService,
  NaturalPersonService,
  LegalPersonQuery,
  NaturalPersonQuery,
  UiScreenQuery,
} from 'common';
import {Router} from '@angular/router';
import {FilterService} from 'primeng/api';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Table } from 'primeng/table';

@UntilDestroy()
@Component({
  selector: 'app-page-client-search',
  templateUrl: './page-client-search.component.html',
  styleUrls: ['./page-client-search.component.scss']
})

export class PageClientSearchComponent implements OnInit {
  @ViewChild('dt', { static: false }) dt: Table;

  searchResults: LegalPerson[] | NaturalPerson[] | any[] = [] ;
  clientType = ClientType;
  stepOptions: DropdownOption<any>[] = [];

  constructor(
    private clientSearchResultQuery: ClientSearchResultQuery,
    private stepQuery: StepQuery,
    private router: Router,
    private filterService: FilterService,
    private legalPersonService: LegalPersonService,
    private naturalPersonService: NaturalPersonService,
    private legalPersonQuery: LegalPersonQuery,
    private naturalPersonQuery: NaturalPersonQuery,
    public uiScreenQuery: UiScreenQuery,
  ) {}

  ngOnInit(): void {
    this.filterService.register('containsStep', (value: any, filter: any): boolean => this.doesCustomerContainStep(value, filter));

    this.clientSearchResultQuery.select('clients').pipe(untilDestroyed(this)).subscribe((searchResults: any) => {
      this.searchResults = [];
      this.stepOptions = [];
      searchResults.map((client: any)=>{
        if (client.clientType === ClientType.LEGAL_PERSON){
          this.legalPersonService.get(client.id).pipe(untilDestroyed(this)).subscribe();
          this.legalPersonQuery.selectEntity(client.id).pipe(untilDestroyed(this)).subscribe( (legalPerson: any) => {
            if(legalPerson && legalPerson.hasOwnProperty('steps')){
              legalPerson = {...legalPerson, clientType: ClientType.LEGAL_PERSON};
              if(this.searchResults.findIndex(val=> val.id === legalPerson.id && val.clientType === ClientType.LEGAL_PERSON) === -1){
                this.searchResults = [...this.searchResults,this.getInProgressSteps(legalPerson)];
              } else {
                this.searchResults[this.searchResults.findIndex(val=> val.id === legalPerson.id && val.clientType === ClientType.LEGAL_PERSON)] = this.getInProgressSteps(legalPerson);
              }
              this.dt?.clear();
              this.setStepOptions();
            }
          });
        }
        if (client.clientType === ClientType.NATURAL_PERSON){
          this.naturalPersonService.get(client.id).pipe(untilDestroyed(this)).subscribe();
          this.naturalPersonQuery.selectEntity(client.id).pipe(untilDestroyed(this)).subscribe((naturalPerson: any) => {
            if(naturalPerson && naturalPerson.hasOwnProperty('steps')) {
              naturalPerson = {...naturalPerson, clientType: ClientType.NATURAL_PERSON};
              if (this.searchResults.findIndex(val => val.id === naturalPerson.id && val.clientType === ClientType.NATURAL_PERSON) === -1) {
                this.searchResults = [...this.searchResults, this.getInProgressSteps(naturalPerson)];
              } else {
                this.searchResults[this.searchResults.findIndex(val => val.id === naturalPerson.id && val.clientType === ClientType.NATURAL_PERSON)] = this.getInProgressSteps(naturalPerson);
              }
              this.dt?.clear();
              this.setStepOptions();
            }
          });
        }
      });
    });
  }

  setStepOptions(){
    this.searchResults.map((client: any) => {
        client.steps.map((clientStep: any)=>{
          if (!this.stepOptions.find((a: any) => a.label === clientStep.name)){
            this.stepOptions = [...this.stepOptions,
              {
                label: clientStep.name,
                value: clientStep.name,
              }];
          }
        });
      });
  }

  getInProgressSteps(client: LegalPerson | NaturalPerson): LegalPerson | NaturalPerson{
    if(client.steps?.length){
      let steps: any[] = [];
      // DETECTION DES ETAPES AYANT DES TACHES NOT DONE
      client.steps.map((step: Step) =>{
        const tempStep = this.stepQuery.getEntity(step.id);
        let isInProgress = false;
        tempStep?.stepGroups.map(group =>{
          group.tasks.map(task=>{
            if (task.status !== TaskStatus.DONE){
              isInProgress = true;
              return;
            }
          });
        });
        if(isInProgress) {
          steps = [...steps, {name: tempStep?.name}];
        }
      });
      // REGROUPEMENT DES ETAPES ET CALCUL DU COMPTEUR
      const stepWithCount = steps.reduce((acc, step) => {
        const foundIndex = acc.findIndex((a: any) => a.name === step.name);
        if(foundIndex !== -1) {
          acc[foundIndex].count = acc[foundIndex].count + 1;
        } else {
          acc = [...acc, {name: step.name, count: 1}];
        }
        return acc;
      }, []);
      return {...client, steps: stepWithCount};
    } else {
      return {...client};
    }
  }

  handleMobileStepFiltering(filterValue: any) {
    if (filterValue) {
      this.dt.filter(filterValue, 'steps', 'containsStep');
    } else {
      this.dt.clear();
    }
  }

  doesCustomerContainStep(value: any, filter: any) {
    if (filter === undefined || filter === null) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    if (value.find((val: any) => val.name === filter)) {
      return true;
    }
    return false;
  }

  goto(client: LegalPerson | NaturalPerson){
    if (client.clientType === ClientType.LEGAL_PERSON) {
      this.router.navigate(['legal-person/' + client.id]);
    } else {
      this.router.navigate(['natural-person/' + client.id]);
    }
  }

}
