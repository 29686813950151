import {ChangeDetectorRef, Component, forwardRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ConjointFormComponent} from '../../form/conjoint-form/conjoint-form.component';
import {ID} from '@datorama/akita';
import {
  NaturalNaturalRelation, NaturalNaturalRelationTypeQuery,
  NaturalPerson,
  NaturalPersonQuery,
  NaturalPersonService,
  NaturalNaturalRelationType,
  NaturalNaturalRelationService,
  DocumentType, Email, UiRecueilQuery, UiRecueilStore,
} from 'common';
import {HttpClient} from '@angular/common/http';
import {MessageService} from 'primeng/api';
import {
  ControlValueAccessor, UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-recueil-conjoint',
  templateUrl: './recueil-conjoint.component.html',
  styleUrls: ['./recueil-conjoint.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilConjointComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilConjointComponent),
      multi: true
    }
  ]
})
export class RecueilConjointComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('dialogForm') conjointFormComponent: ConjointFormComponent;
  @Input() clientId: ID | undefined;
  @Input() personSituation = '';
  @Input() conjointHasPhones = false;
  @Input() conjointHasAddresses = false;
  @Input() conjoint: NaturalPerson | undefined;
  documentType = DocumentType;
  documentTypes: { code: string; field: string }[] = [];
  showConjointInfoModal = false;
  client: NaturalPerson;
  isConjointRequestSent = false;
  naturalNaturalRelationType: NaturalNaturalRelationType;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  initialValues =
    {
      relationID: null,
      id: null,
      situationMatrimoniale: {
        situationMatrimoniale: '',
        precedentMariage: null,
        dateMariage: null,
        lieuMariage: null,
        regimeMatrimonial: null,
        regimePacsimonial: null,
        donationDernierVivant: null,
        sensDonation: null,
        testamentCroise: null,
        sensTestamentCroise: null,
      },
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: null,
        dead: null,
        deathDate: null,
      },
      situationPro: {
        situationPro: null,
        currentJob: null,
        jobCompany: null,
        revenusAnnuel: null,
        rentesAnnuelle: null,
      },
      celebre: {
        politiquementExpose: null,
        prochePolitiquementExpose: null,
      },
      etranger: {
        usPerson: null,
        vecuEtrange: null,
        vecuEtrangeDixAns: null,
      },
      contact: {
        phones: [],
        emails: [],
        addresses: [],
        rentPrincipalResidence: null,
        loyerAnnuel: null,
      },
      comConjoint: '',
      docs: {
        contratMariage: null,
        attestDonation: null,
        pi: null,
        justifDomicile: null,
        otherDocs: null
      }
    };

  constructor(public naturalPersonQuery: NaturalPersonQuery,
              public naturalPersonService: NaturalPersonService,
              public naturalNaturalRelationTypeQuery: NaturalNaturalRelationTypeQuery,
              public naturalNaturalRelationService: NaturalNaturalRelationService,
              public uiRecueilStore: UiRecueilStore,
              public uiRecueilQuery: UiRecueilQuery,
              private httpClient: HttpClient,
              private messageService: MessageService,
              private formBuilder: UntypedFormBuilder,
              private changeDetector: ChangeDetectorRef,
              @Inject('config') private config: any,) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe(value => {
          const { docs, ...rest } = value;
          let phonesToSave: any[] = [];
          let addressesToSave: any[] = [];

          // Préparer la liste des numéros à enregistrer en concaténant samePhones et phones ajoutés par l'utilisateur
          if(value.contact.samePhones && value.contact.areSamePhonesDisplayed){
            value.contact.samePhones.map((samePhone: any) => {
              phonesToSave = [...phonesToSave, {...samePhone, id: null}];
            });
          }
          phonesToSave = [...phonesToSave, ...value.contact.phones];

          // Préparer la liste des adresses à enregistrer en concaténant sameAddresses et adresses ajoutés par l'utilisateur
          if(value.contact.sameAddresses && value.contact.areSameAddressesDisplayed){
            value.contact.sameAddresses.map((sameAddress: any) => {
              addressesToSave = [...addressesToSave, {...sameAddress, id: null}];
            });
          }
          addressesToSave = [...addressesToSave, ...value.contact.addresses];
          this.onChange(
            {...this.form.getRawValue(),
              ...rest,
              ...value.docs,
              infoCivile: {...value.infoCivile, lastName: value.infoCivile.lastName ? value.infoCivile.lastName.toUpperCase() : null},
              contact: {...value.contact, phones: phonesToSave, addresses: addressesToSave}}
          );
          this.onTouched();
        })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.naturalPersonQuery.selectEntity(this.clientId).subscribe(naturalPerson => {
      if (naturalPerson !== undefined) {
        this.client = naturalPerson;
      }
    });
    this.naturalNaturalRelationTypeQuery.selectAll(({filterBy: ({label}) => label === this.personSituation})).subscribe(result => {
      if (result) {
        this.naturalNaturalRelationType = result[0];
      }
    });
  }

  showConjointModal() {
    this.showConjointInfoModal = true;
    if(this.conjoint?.emails?.find(mail=>mail.isContact === true)){
      this.conjointFormComponent.emailF?.disable();
    }
  }

  closeConjointModal() {
    this.showConjointInfoModal = false;
  }

  sendLinkConjointEmailRequest(conjointEmail: string, clientId: ID | undefined, conjointId: ID | undefined){
    this.httpClient.post(this.config.apiUrl + '/send_link_conjoint_email', {
      conjointEmail,
      clientId,
      conjointId
    }).subscribe({
      next: (val) => {

        this.messageService.add({
          severity: 'success',
          summary: 'La demande a été envoyée à votre conjoint',
          life: 10000
        });
      }
    });
    this.changeDetector.markForCheck();
    this.showConjointInfoModal = false;
    this.isConjointRequestSent = true;
    this.uiRecueilStore.update({sentToConjoint: true});
  }

  sendToConjoint() {
    let conjointEmail!: Email;
    let conjointId: ID | undefined;
    const clientId = this.clientId;
    const conjointData = this.conjointFormComponent.getFormData();
    //3 cas possibles:
    //1- on trouve un conjoint dans les relations avec email
    //2- on trouve un conjoint dans les relations sans email
    //3- on ne trouve pas de conjoint dans les relations
    if (this.conjoint !== undefined) {
      conjointId = this.conjoint.id;
      if(this.conjoint?.emails?.filter(mail=>mail.isContact).length){
        //cas 1
        conjointEmail = this.conjoint?.emails?.filter(mail=>mail.isContact)[0];
      } else {
        //cas 2
        conjointEmail = conjointData.email;
        this.naturalPersonService.update(conjointId, {emails: [conjointData.email]}).subscribe();
      }

      this.sendLinkConjointEmailRequest(conjointEmail.email, clientId, conjointId);
    } else {
      //cas 3
      if (conjointData !== null) {
        this.showConjointInfoModal = false;
        const naturalPerson: NaturalPerson = {
          civility: conjointData.civility,
          lastName: conjointData.lastName ? conjointData.lastName.toUpperCase() : null,
          firstName: conjointData.firstName,
          emails: [conjointData.email],
          addresses: [],
          phones: [],
          customerStatus: [],
          clientType: '',
          steps: [],
        };

        this.naturalPersonService.add<NaturalPerson>(naturalPerson).subscribe((naturalPersonCreated: NaturalPerson) => {
          conjointEmail = conjointData.email;
          conjointId = naturalPersonCreated?.id;
          this.sendLinkConjointEmailRequest(conjointEmail.email, clientId, conjointId);
          if (this.client !== undefined) {
            const naturalNaturalRelation: NaturalNaturalRelation = {
              firstNaturalPerson: this.client,
              secondNaturalPerson: naturalPersonCreated,
              type: this.naturalNaturalRelationType,
              enfantDe: '',
              aChargeFiscale: '',
              adopted: '',
            };
            this.naturalNaturalRelationService.add<NaturalNaturalRelation>(naturalNaturalRelation).subscribe((result)=> {
              this.form.get('relationID')?.patchValue(result.id);
              this.form.get('id')?.patchValue(conjointId);
              this.form.get('infoCivile')?.patchValue({civility: conjointData.civility,
                firstName: conjointData?.firstName,
                lastName: conjointData?.lastName});
            });
          }
        });
      }
    }
  }


  writeValue(value: any) {
    if (value) {
      value.situationMatrimoniale.situationMatrimoniale = this.personSituation;
      const {
        contratMariage,
        attestDonation,
        pi,
        justifDomicile,
        otherDocs,
        ...rest
      } = value;
      this.value = {
        ...rest, docs: {
          contratMariage: value.contratMariage,
          attestDonation: value.attestDonation,
          pi: value.pi,
          justifDomicile: value.justifDomicile,
          otherDocs: value.otherDocs
        }
      };
    }
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {valid: false};
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}
