<div class="mt-4 grid">
  <!--  MES TÂCHES -->
  <p-fieldset class="mt-4 fieldset-bg-warning" legend="Mes tâches" *ngIf="(uiScreenQuery.isMobile$ | async) === false">
    <div class="mt-3 mb-2 mx-2 flex align-items-center">
      <!-- INDICATEUR TÂCHE PRIORITAIRE -->
      <div pTooltip="Prioritaires" tooltipPosition="bottom" class="flex align-items-center">
        <p class="my-0 font-bold text-5xl text-danger">
          {{tasksIndicators?.prioritary}}
        </p>
        <div
          class="indicator bg-danger text-white text-2xl font-semibold flex justify-content-center align-items-center ml-2">
          <i class="pi pi-exclamation-triangle" style="font-size: 1.5rem"></i>
        </div>
      </div>
      <!-- INDICATEUR TÂCHE DEADLINE KO -->
      <div pTooltip="Dépassées" tooltipPosition="bottom" class="flex align-items-center ml-7">
        <p class="my-0 font-bold text-5xl text-danger">
          {{tasksIndicators?.closeDeadline}}
        </p>
        <div
          class="indicator bg-danger text-white text-2xl font-semibold flex justify-content-center align-items-center ml-2">
          <svg width="25" height="25" viewBox="0 0 512 512">
            <path
              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
              fill="#ffffff" data-original="#000000" class=""/>
            <path
              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
              fill="#ffffff" data-original="#000000" class=""/>
          </svg>
        </div>
      </div>
      <!-- INDICATEUR TÂCHE HAUTE IMPORTANCE -->
      <div pTooltip="Très importantes" tooltipPosition="bottom" class="flex align-items-center ml-7">
        <p class="my-0 font-bold text-5xl text-info">
          {{tasksIndicators?.highImportance}}
        </p>
        <div
          class="indicator bg-info text-white text-2xl font-semibold flex justify-content-center align-items-center ml-2">
          <i class="pi pi-euro" style="font-size: 0.8rem"></i>
          <i class="pi pi-euro" style="font-size: 0.8rem"></i>
          <i class="pi pi-euro" style="font-size: 0.8rem"></i>
        </div>
      </div>
      <!-- INDICATEUR TÂCHE TERMINEE -->
      <div pTooltip="Terminées depuis lundi" tooltipPosition="bottom" class="flex align-items-center ml-7">
        <p class="my-0 font-bold text-5xl text-success">
          {{tasksIndicators?.validate}}
        </p>
        <div
          class="indicator bg-success text-white text-2xl font-semibold flex justify-content-center align-items-center ml-2">
          <i class="pi pi-check-circle" style="font-size: 2rem"></i>
        </div>
      </div>
    </div>
  </p-fieldset>
</div>
<div class="mt-4 grid">
  <p-tabView [formGroup]="form"
             (onChange)="switchDashboard(currentDashboardView === dashboardView.CUSTOMER ? dashboardView.INTERNAL : dashboardView.CUSTOMER)"
             [activeIndex]="currentDashboardView === dashboardView.CUSTOMER ? 0 : 1"
             class="col-12"
             [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'tab-view-mobile' : ''"
  >
    <!--  DASHBOARD TÂCHES CLIENT -->
    <p-tabPanel header="Tâches clients">
      <div [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'flex justify-content-between' : 'flex justify-content-end'">
        <p-dropdown *ngIf="(uiScreenQuery.isMobile$ | async)"
                    #filterCollaboratorAndPoleCustomer
                    appendTo="body"
                    class="mr-3"
                    styleClass="w-10rem"
                    placeholder="..."
                    formControlName="poleAndCollaboratorFilterCustomer"
                    [showClear]="true"
                    [options]="poleAndCollaboratorOptionsCustomer"
                    [group]="true"
                    (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE, dashboardView.CUSTOMER)"
        ></p-dropdown>
        <button pButton
                type="button"
                icon="pi pi-plus"
                [label]="(uiScreenQuery.isMobile$ | async) ? 'Créer tâche' : 'Créer une nouvelle tâche client'"
                class="p-button-success p-button-raised p-button-text mb-3"
                (click)="newCustomerTask()">
        </button>
      </div>
      <p-table #tasksTableCustomer
               [value]="customerTasks"
               dataKey="id"
               [rows]="10"
               [paginator]="true"
               [filterDelay]="0"
               [alwaysShowPaginator]="false"
               stateStorage="local"
               stateKey="dashboard_customer"
               (onStateRestore)="onDashboardStateRestore($event, dashboardView.CUSTOMER)"
               [showCurrentPageReport]="true"
               responsiveLayout="stack"
               currentPageReportTemplate="Tâches clients {first} à {last} sur {totalRecords} trouvées"
               [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'table-mobile' : ''"
      >
        <!-- TITRE ET TRI DES COLONNES -->
        <ng-template pTemplate="header">
          <tr>
            <!-- TRIE ECHEANCE -->
            <th class="text-sm" pSortableColumn="deadlineDate">Échéance
              <p-sortIcon class="ml-auto" field="deadlineDate"></p-sortIcon>
            </th>
            <!-- TRIE IMPORTANCE -->
            <th class="text-sm" pSortableColumn="importance">
              Importance
              <p-sortIcon class="mr-2" field="importance"></p-sortIcon>
            </th>
            <!-- TRIE ETAPE -->
            <th class="text-sm">Étape / Tâche</th>
            <!-- TRIE CLIENT -->
            <th class="text-sm" pSortableColumn="legalAndNaturalCustomer.nameLabel">Client
              <p-sortIcon class="ml-auto" field="legalAndNaturalCustomer.nameLabel"></p-sortIcon>
            </th>
            <!-- TRIE COLLABORATEUR / PÔLE -->
            <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">Collaborateur / Pôle
              <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
            </th>
            <th class="text-sm"></th>
          </tr>
          <tr>
            <!-- REINIT BUTTON -->
            <th class="col-1">
              <button id="reinitFilterButtonCustomer" pButton
                      class="p-button-raised p-button-sm bg-white text-black border-none"
                      icon="pi pi-filter-slash" label="Réinitialiser"
                      (click)="clearFilters(dashboardView.CUSTOMER, $event)"></button>
            </th>
            <!-- FILTRE IMPORTANCE -->
            <th class="col-2">
              <p-dropdown #filterImportanceCustomer
                          appendTo="body"
                          styleClass="w-10rem"
                          placeholder="..."
                          formControlName="importanceFilterCustomer"
                          [showClear]="true"
                          [options]="importanceOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.IMPORTANCE, dashboardView.CUSTOMER)"
              ></p-dropdown>
            </th>
            <!-- FILTRE ETAPE -->
            <th class="col-4">
              <p-dropdown #filterSteps
                          appendTo="body"
                          styleClass="w-8rem"
                          placeholder="..."
                          formControlName="stepFilter"
                          [showClear]="true"
                          [options]="stepsOptions"
                          (onClear)="clearFilterTask($event)"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.STEP)"
              ></p-dropdown>
              <!-- FILTRE ETAPE TÂCHE -->
              <p-dropdown #filterTasks
                          appendTo="body"
                          styleClass="w-8rem"
                          class="ml-3"
                          placeholder="..."
                          formControlName="stepTaskFilter"
                          [showClear]="true"
                          [options]="stepFilterRelatedTaskOption"
                          [group]="true"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.STEPTASK)"
              ></p-dropdown>
            </th>
            <!-- FILTRE CLIENT -->
            <th class="col-2">
              <p-dropdown #filterCustomer
                          appendTo="body"
                          styleClass="w-12rem"
                          placeholder="..."
                          emptyFilterMessage="Aucun résultat trouvé"
                          formControlName="customerFilter"
                          [showClear]="true"
                          [options]="clientOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.CUSTOMER)"
                          [filter]="true"
              ></p-dropdown>
            </th>
            <!-- FILTRE PÔLE/COLLABORATEUR -->
            <th class="col-2">
              <p-dropdown #filterCollaboratorAndPoleCustomer
                          appendTo="body"
                          styleClass="w-12rem"
                          placeholder="..."
                          formControlName="poleAndCollaboratorFilterCustomer"
                          [showClear]="true"
                          [options]="poleAndCollaboratorOptionsCustomer"
                          [group]="true"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE, dashboardView.CUSTOMER)"
              ></p-dropdown>
            </th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <!--  CORPS DU TABLEAU -->
        <ng-template pTemplate="body" let-task>
          <!-- CORPS DU TABLEAU MOBILE -->
          <ng-container *ngIf="(uiScreenQuery.isMobile$ | async);else desktop">
            <tr class="card">
              <!-- CLIENT & COLLABORATEUR / POLE -->
              <td class="px-3 pt-3">
                <!-- CLIENT -->
                <div class="flex flex-column mb-auto">
                  <p-tag *ngFor="let naturalPerson of task?.naturalPersons"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                         value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                         [rounded]="true"
                         (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                  >
                  </p-tag>
                  <p-tag *ngFor="let legalPerson of task?.legalPersons"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-secondary text-white border-1 cursor-pointer"
                         value="{{ legalPerson.name }}"
                         [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')">
                  </p-tag>
                </div>
                <!-- COLLABORATEUR / POLE -->
                <div class="flex flex-column mb-auto">
                  <p-tag *ngIf="task.collaborator"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                  <p-tag *ngIf="task.pole"
                         class="ml-auto"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                         value="{{ translate(task.pole) }}"
                         [rounded]="true">
                  </p-tag>
                </div>
              </td>
              <!-- ÉTAPE / TÂCHE -->
              <td class="px-3 flex-column align-items-start">
                <p class="text-sm m-0">{{ task.step?.name }}</p>
                <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
              </td>
              <!-- ÉCHÉANCE -->
              <td class="px-3">
                <p class="text-sm mb-0">{{ task.deadlineDate | date: 'dd/MM/yyyy' }}</p>
              </td>
              <!-- IMPORTANCE & ACTIONS -->
              <td class="px-3 pb-3 align-items-end">
                <!-- IMPORTANCE -->
                <div class="flex">
                  <div class="grid align-items-end w-max-w">
                    <div class="p-1 mr-1">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="p-1 mr-1">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-warning': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="p-1 mr-1">
                      <div *ngIf="task.importance && task.importance ===  2"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="p-1">
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-success"
                             value="doing"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </div>
                </div>
                <!-- ACTIONS -->
                <div class="flex">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          class="mr-2 p-button-raised bg-white text-primary border-none"
                          (click)="editTask(task)"></button>
                  <button pButton pRipple type="button" icon="pi pi-check-circle"
                          class="p-button-raised bg-white text-success border-none"
                          (click)="openCloseDialog(task)"></button>
                </div>
              </td>
            </tr>
          </ng-container>
          <!-- CORPS DU TABLEAU DESKTOP -->
          <ng-template #desktop>
            <tr class="tableRow">
              <!--  COLONNE ÉCHÉANCE -->
              <td class="p-2">
                <p class="text-sm">{{ task.deadlineDate | date: 'dd/MM/yyyy' }}</p>
              </td>
              <!--  COLONNE IMPORTANCE -->
              <td class="p-2">
                <div class="flex">
                  <div class="grid align-items-end w-full">
                    <div class="col-2 mr-2">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="col-2 mr-2">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-warning': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="col-2 mr-2">
                      <div *ngIf="task.importance && task.importance ===  2"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="col-4">
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-success"
                             value="doing"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </div>
                </div>
              </td>
              <!--  COLONNE ÉTAPE / TÂCHE -->
              <td class="p-2">
                <p class="text-sm m-0">{{ task.step?.name }}</p>
                <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
              </td>
              <!--  COLONNE CLIENT -->
              <td class="p-2">
                <p-tag *ngFor="let naturalPerson of task?.naturalPersons"
                       styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                       class=""
                       value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                       [rounded]="true"
                       (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                >
                </p-tag>
                <p-tag *ngFor="let legalPerson of task?.legalPersons"
                       styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-secondary text-white border-1 cursor-pointer"
                       value="{{ legalPerson.name }}"
                       [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')">
                </p-tag>
              </td>
              <!--  COLONNE COLLABORATEUR / POLE -->
              <td class="p-2">
                <div class="flex flex-column">
                  <p-tag *ngIf="task.collaborator"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                  <p-tag *ngIf="task.pole"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                         value="{{ translate(task.pole) }}"
                         [rounded]="true">
                  </p-tag>
                </div>
              </td>
              <!--  COLONNE ACTIONS -->
              <td class="p-2">
                <div class="flex">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          class="mr-2 p-button-raised bg-white text-primary border-none"
                          (click)="editTask(task)"></button>
                  <button pButton pRipple type="button" icon="pi pi-check-circle"
                          class="p-button-raised bg-white text-success border-none"
                          (click)="openCloseDialog(task)"></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </ng-template>
        <!--  AUCUNE TÂCHE TROUVÉE -->
        <ng-template pTemplate="emptymessage">
          <tr>
            <td
              colspan="5"
              class="p-3 text-sm"
              [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'flex justify-content-center' : 'text-center '">
              Aucune tâche trouvée
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <!-- DASHBOARD TÂCHES INTERNES -->
    <p-tabPanel header="Tâches internes">
      <div [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'flex justify-content-between' : 'flex justify-content-end'">
        <p-dropdown *ngIf="(uiScreenQuery.isMobile$ | async)" #filterCollaboratorAndPoleInternal
                    appendTo="body"
                    class="mr-3"
                    styleClass="w-10rem"
                    placeholder="..."
                    formControlName="poleAndCollaboratorFilterInternal"
                    [showClear]="true"
                    [options]="poleAndCollaboratorOptionsInternal"
                    [group]="true"
                    (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE, dashboardView.INTERNAL)"
        ></p-dropdown>
        <button pButton type="button" icon="pi pi-plus" [label]="(uiScreenQuery.isMobile$ | async) ? 'Créer tâche' : 'Créer une nouvelle tâche interne'"
                class="p-button-success p-button-raised p-button-text mb-3" (click)="newIntenalTask()"></button>
      </div>
      <p-table #tasksTableInternal [value]="internalTasks"
               dataKey="id"
               [rows]="10"
               responsiveLayout="stack"
               [paginator]="true"
               [filterDelay]="0"
               [alwaysShowPaginator]="false"
               stateStorage="local"
               stateKey="dashboard_internal"
               (onStateRestore)="onDashboardStateRestore($event, dashboardView.INTERNAL)"
               [showCurrentPageReport]="true"
               currentPageReportTemplate="Tâches internes {first} à {last} sur {totalRecords} trouvées"
               [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'table-mobile' : ''"
      >
        <!-- TITRE ET TRI DES COLONNES -->
        <ng-template pTemplate="header">
          <tr>
            <!-- TRIE ECHEANCE -->
            <th class="text-sm" pSortableColumn="deadlineDate">Échéance
              <p-sortIcon class="ml-auto" field="deadlineDate"></p-sortIcon>
            </th>
            <!-- TRIE IMPORTANCE -->
            <th class="text-sm" pSortableColumn="importance">
              Importance
              <p-sortIcon class="mr-2" field="importance"></p-sortIcon>
            </th>
            <th class="text-sm">Tâche</th>
            <!-- TRIE COLLABORATEUR / PÔLE -->
            <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">Collaborateur / Pôle
              <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
            </th>
            <th class="text-sm"></th>
          </tr>
          <tr>
            <!-- REINIT BUTTON -->
            <th class="col-1">
              <button id="reinitFilterButtonInternal" pButton
                      class="p-button-raised p-button-sm bg-white text-black border-none"
                      icon="pi pi-filter-slash" label="Réinitialiser"
                      (click)="clearFilters(dashboardView.INTERNAL, $event)"></button>
            </th>
            <!-- FILTRE IMPORTANCE -->
            <th class="col-2">
              <p-dropdown #filterImportanceInternal
                          appendTo="body"
                          styleClass="w-10rem"
                          placeholder="..."
                          formControlName="importanceFilterInternal"
                          [showClear]="true"
                          [options]="importanceOptions"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.IMPORTANCE, dashboardView.INTERNAL)"
              ></p-dropdown>
            </th>
            <th class="col-4">
            </th>
            <!-- FILTRE PÔLE/COLLABORATEUR -->
            <th class="col-3">
              <p-dropdown #filterCollaboratorAndPoleInternal
                          appendTo="body"
                          styleClass="w-10rem"
                          placeholder="..."
                          formControlName="poleAndCollaboratorFilterInternal"
                          [showClear]="true"
                          [options]="poleAndCollaboratorOptionsInternal"
                          [group]="true"
                          (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE, dashboardView.INTERNAL)"
              ></p-dropdown>
            </th>
            <th class="col-1"></th>
          </tr>
        </ng-template>
        <!--  CORPS DU TABLEAU -->
        <ng-template pTemplate="body" let-task>
          <!-- CORPS DU TABLEAU MOBILE -->
          <ng-container *ngIf="(uiScreenQuery.isMobile$ | async);else desktop">
            <tr class="card">
              <!-- CLIENT & COLLABORATEUR / POLE -->
              <td class="px-3 pt-3">
                <!-- COLLABORATEUR / POLE -->
                <div class="flex flex-column mb-auto ml-auto">
                  <p-tag *ngIf="task.collaborator"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                  <p-tag *ngIf="task.pole"
                         class="ml-auto"
                         styleClass="tag-nowrap mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                         value="{{ translate(task.pole) }}"
                         [rounded]="true">
                  </p-tag>
                </div>
              </td>
              <!-- ÉTAPE / TÂCHE -->
              <td class="px-3 flex-column align-items-start">
                <p class="text-sm m-0">{{ task.step?.name }}</p>
                <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
              </td>
              <!-- ÉCHÉANCE -->
              <td class="px-3">
                <p class="text-sm">{{ task.deadlineDate | date: 'dd/MM/yyyy' }}</p>
              </td>
              <!-- IMPORTANCE & ACTIONS -->
              <td class="px-3 pb-3">
                <!-- IMPORTANCE -->
                <div class="flex">
                  <div class="grid align-items-end w-full">
                    <div class="col-2 mr-2">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="col-2 mr-2">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-warning': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="col-2 mr-2">
                      <div *ngIf="task.importance && task.importance ===  2"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="col-4">
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-success"
                             value="doing"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </div>
                </div>
                <!-- ACTIONS -->
                <div class="flex">
                  <button pButton type="button" icon="pi pi-pencil"
                          class="mr-2 p-button-raised p-button-text"
                          (click)="editInternalTask(task)"></button>
                  <button pButton type="button" icon="pi pi-check-circle"
                          class="p-button-raised p-button-text p-button-success"
                          (click)="openCloseDialog(task)"></button>
                </div>
              </td>
            </tr>
          </ng-container>
          <!-- CORPS DU TABLEAU DESKTOP -->
          <ng-template #desktop>
            <tr>
              <!--  COLONNE ÉCHÉANCE -->
              <td class="p-2">
                <p class="text-sm">{{ task.deadlineDate | date: 'dd/MM/yyyy' }}</p>
              </td>
              <!--  COLONNE IMPORTANCE -->
              <td class="p-2">
                <div class="flex">
                  <div class="grid align-items-end w-full">
                    <div class="col-2 mr-2">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="col-2 mr-2">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-warning': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="col-2 mr-2">
                      <div *ngIf="task.importance && task.importance === 1"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 2"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="col-4">
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="mr-1 px-2 text-xs font-medium vertical-align-middle bg-success"
                             value="doing"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </div>
                </div>
              </td>
              <!--  COLONNE ÉTAPE / TÂCHE -->
              <td class="p-2">
                <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
              </td>
              <!--  COLONNE COLLABORATEUR / POLE -->
              <td class="p-2">
                <div class="flex flex-column">
                  <p-tag *ngIf="task.collaborator"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                  <p-tag *ngIf="task.pole"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                         value="{{ translate(task.pole) }}"
                         [rounded]="true">
                  </p-tag>
                </div>
              </td>
              <td class="p-2">
                <div class="flex">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          class="mr-2 p-button-raised bg-white text-primary border-none"
                          (click)="editInternalTask(task)"></button>
                  <button pButton pRipple type="button" icon="pi pi-check-circle"
                          class="p-button-raised bg-white text-success border-none"
                          (click)="openCloseDialog(task)"></button>
                </div>
              </td>
            </tr>
          </ng-template>
        </ng-template>
        <!--  AUCUNE TÂCHE TROUVÉE -->
        <ng-template pTemplate="emptymessage">
          <tr>
            <td
              colspan="5"
              class="p-3 text-sm"
              [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'flex justify-content-center' : 'text-center '">
              Aucune tâche trouvée
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>
<!--CUSTOMER TASK CLOSE DIALOG-->
<app-close-task-modal *ngIf="closingTask?.id"
                      [display]="displayCloseDialog"
                      [task]="closingTask"
                      (displayCloseDialog)="displayCloseDialog = $event"
></app-close-task-modal>

