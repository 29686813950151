import { ID } from '@datorama/akita';
import { Address } from '../../address/state/address.model';
import { CompteRendu } from '../../../compteRendu/state/compte-rendu.model';
import { NaturalLegalRelation } from '../../natural-legal-relation/state/natural-legal-relation.model';
import {Step} from '../../../task/step/state/step.model';
import {Recueil} from '../../recueil/state/recueil.model';

export interface LegalPerson {
  id?: ID;
  name: string;
  siretNumber: string;
  structureJuridique: string;
  creationDate: Date | null;
  addresses: Address[];
  uuidO2s?: string;
  naturalLegalRelations?: NaturalLegalRelation[];
  compteRendus?: CompteRendu[];
  clientType?: string;
  generalComment?: string;
  customerStatus: string[];
  steps: [] | Step[];
  recueil?: Recueil;

}

export function createLegalPerson(params: Partial<LegalPerson>) {
  return {} as LegalPerson;
}
