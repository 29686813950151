<ng-container *ngIf="currentLegalPersonId || isNewLegalPerson">
  <form [formGroup]="form">
    <div class="grid">
      <div class="field col-6">
        <label for="naturalPerson-input">Personne physique</label>
        <p-dropdown
          styleClass="w-full"
          formControlName="naturalPerson"
          id="naturalPerson-input"
          [options]="naturalPersonOptions"
          optionLabel="label"
          optionValue="value"
          emptyFilterMessage="Aucune personne physique trouvée"
          [filter]="true"
          [autoDisplayFirst]="false"
        >
        </p-dropdown>
        <lib-field-error *ngIf="(naturalPersonF?.touched || markAsTouched) && naturalPersonF?.errors " [fieldError]="naturalPersonF?.errors"></lib-field-error>
      </div>
      <div class="field col-6">
        <label for="naturalPersonRole-input">{{ isNewLegalPerson ? "Fonction" : "Relation" }}</label>
        <input formControlName="naturalPersonRole" id="naturalPersonRole-input" type="text" size="30" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="(naturalPersonRoleF?.touched || markAsTouched) && naturalPersonRoleF?.errors" [fieldError]="naturalPersonRoleF?.errors"></lib-field-error>
      </div>
    </div>
  </form>

</ng-container>
<ng-container *ngIf="currentNaturalPersonId">
  <form [formGroup]="form" class="py-6 px-2">
    <div class="grid">
      <div class="field col-6">
        <label for="naturalNaturalPersonRole-input">Relation</label>
        <input formControlName="naturalPersonRole" id="naturalNaturalPersonRole-input" type="text" size="30" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="(naturalPersonRoleF?.touched || markAsTouched) && naturalPersonRoleF?.errors" [fieldError]="naturalPersonRoleF?.errors"></lib-field-error>
      </div>
      <div class="field col-6">
        <label for="legalPerson-input">Personne morale</label>
        <p-dropdown
          styleClass="w-full"
          formControlName="legalPerson"
          id="legalPerson-input"
          [options]="legalPersonOptions"
          optionLabel="label"
          optionValue="value"
          emptyFilterMessage="Aucune personne morale trouvée"
          [filter]="true"
          [autoDisplayFirst]="false"
        >
        </p-dropdown>
        <lib-field-error *ngIf="(naturalPersonF?.touched || markAsTouched) && naturalPersonF?.errors" [fieldError]="naturalPersonF?.errors"></lib-field-error>
      </div>
    </div>
  </form>
</ng-container>
