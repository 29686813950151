import { Component, OnInit } from '@angular/core';
import { Product, ProductQuery, ProductService, UiScreenQuery } from 'common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-page-product-list',
  templateUrl: './page-product-list.component.html',
  styleUrls: ['./page-product-list.component.scss']
})
export class PageProductListComponent implements OnInit {

  products: Product[] = [];
  productsLoading = false;
  selectedProduct?: Product;
  pageOffset = 0;

  showDeleteProductModal = false;

  constructor(
    public productQuery: ProductQuery,
    public productService: ProductService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public uiScreenQuery: UiScreenQuery
  ) { }

  ngOnInit(): void {
    this.productQuery.selectLoading().pipe(untilDestroyed(this)).subscribe(loading => {
      this.productsLoading = loading;
    });
    this.productQuery.selectAll().pipe(untilDestroyed(this)).subscribe(products => {
      this.products = products;
    });

    this.route.queryParams.subscribe(params => {
      if (+params['first']) {
        this.pageOffset = +params['first'];
      }
    });
  }

  onPageChange(page: any) {
    this.pageOffset = page.first;
    this.router.navigate([], {
      queryParams: {
        first: this.pageOffset
      }
    });
  }

  onAdd() {
    this.router.navigate(['/product-create']);
  }

  onEdit(id: number) {
    this.router.navigate(['/product-edit/' + id]);
  }

  onRemove(selectedProduct: Product) {
    this.selectedProduct = selectedProduct;
    this.showDeleteProductModal = true;
  }

  removeProduct() {
    this.productService.delete(this.selectedProduct?.id).pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Le produit a bien été supprimé.',
          life: 10000
        });
        this.showDeleteProductModal = false;
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Une erreur est survenue lors de la suppression :',
          detail: 'Veuillez essayer à nouveau',
          life: 15000
        });
      }
    });
  }
}
