import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ImmobilierStore, ImmobilierState } from './immobilier.store';

@Injectable({ providedIn: 'root' })
export class ImmobilierQuery extends QueryEntity<ImmobilierState> {

  constructor(protected override store: ImmobilierStore) {
    super(store);
  }

}
