import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  NaturalPersonService,
  LegalPersonQuery,
  AddressService,
  AddressQuery,
  TranslatorService,
  FormGuardInterface,
  NaturalPerson,
  NavigationService,
  UiScreenQuery,
  AddressType
} from 'common';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
@UntilDestroy()

@Component({
  selector: 'app-page-natural-person-new',
  templateUrl: './page-natural-person-new.component.html',
  styleUrls: ['./page-natural-person-new.component.scss']
})
export class PageNaturalPersonNewComponent implements FormGuardInterface, OnInit {
  form: UntypedFormGroup;
  displaySave = true;
  canLeave = true;
  canSave = true;
  submissionLoading = false;

  constructor(
    private naturalPersonService: NaturalPersonService,
    private naturalPersonQuery: LegalPersonQuery,
    private addressService: AddressService,
    private addressQuery: AddressQuery,
    public translatorService: TranslatorService,
    private messageService: MessageService,
    private router: Router,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery,
    private changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,

  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: false,
        dead: false,
        deathDate: null,
      },
      infoCabinet: {
        mainInterlocutor: null,
        secondaryInterlocutor: null,
        dateEntreeEnRelation: null,
        customerOrigin: '',
        customerStatus: [],
        segmentation: null,
      },
      email: {email: null,
        id: null,
        isContact: true,
        label: null,
        type: null,
      },
      phone: {
        id: null,
        dialCode: null,
        countryCode: 'fr',
        isContact: true,
        number: null,
        phone: null,
        type: null,
        label: null,
      },
      address: {
        id: null,
        label: null,
        street: null,
        city: null,
        zipCode: null,
        state: null,
        country: null,
        isContact: true,
        fiscale: true,
        type: AddressType.ADDRESS_PRINCIPALE
      },
    });
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.canSave = this.form.valid;
      this.canLeave = this.form.pristine;
    });
  }

  submitForm(navigate = true): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.canLeave = true;
    this.submissionLoading = true;
    const { phone, ...phoneRest } = this.form.value.phone;
    const naturalPerson: NaturalPerson = {
      ...this.form.value.infoCivile,
      ...this.form.value.infoCabinet,
      addresses: this.form.value.address.city ? [this.form.value.address] : [],
      phones: this.form.value.phone.number ? [phoneRest] : [],
      emails: this.form.value.email.email ? [this.form.value.email] : [],
      doublonTraite: true,
    };

    this.naturalPersonService.add<NaturalPerson>(naturalPerson).subscribe({
      next: (naturalPersonSuccess) => {
        this.onNaturalPersonCreatedSuccess();
        if(navigate) {
          this.router.navigate(['/natural-person/' + naturalPersonSuccess.id]);
        }
      },
      error: (e) => {
        this.onSaveError();
      }
    });

  }
  cancel() {
    this.navigation.back();
  }
  onNaturalPersonCreatedSuccess() {
    this.submissionLoading = false;
    this.messageService.add({
      severity: 'success',
      summary: 'La personne physique a bien été créée.',
      life: 10000
    });
  }
  onSaveError() {
    this.submissionLoading = false;
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }
}
