<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field col-3 w-full">
        <label for="contratPrevoyance" *ngIf="clientOrAdmin === 'ADMIN'">A-t-il des contrats prévoyances ?</label>
        <label for="contratPrevoyance" *ngIf="clientOrAdmin === 'CLIENT'">Avez-vous des contrats de prévoyances ?</label>
        <app-help-tooltip class="ml-3" [text]="'Exemple : contrat Homme Clé, contrat collectif dans votre entreprise' +
       ' ou contrat individuel (exemple : risque accident de la vie, obseque, etc..) '"></app-help-tooltip>

      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.contratPrevoyance}">Non</div>
        <p-inputSwitch id="contratPrevoyance"
                       formControlName="contratPrevoyance"
                       [trueValue]="true"
                       [falseValue]="false"
                       [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.contratPrevoyance}">Oui</div>
      </div>
    </div>
    <div class="field col-12">
      <label for="descriptionContratPrevoyance" *ngIf="clientOrAdmin === 'ADMIN'">Description des contrats de
        prévoyance</label>
      <label for="descriptionContratPrevoyance" *ngIf="clientOrAdmin === 'CLIENT'">Quel contrat avez-vous / pensez-vous
        avoir ? Dites-nous en plus !</label>
      <app-comment-form id="descriptionContratPrevoyance"
                        formControlName="descriptionContratPrevoyance"></app-comment-form>
      <!--      <p-editor id="descriptionContratPrevoyance" formControlName="descriptionContratPrevoyance" styleClass="w-full"-->
      <!--                [style]="{'height':'130px'}">-->
      <!--        <ng-template pTemplate="header">-->
      <!--          <span class="ql-format-group"></span>-->
      <!--        </ng-template>-->
      <!--      </p-editor>-->
    </div>
  </div>
</div>
