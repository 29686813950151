import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AvoirFinancierStore, AvoirFinancierState } from './avoir-financier.store';

@Injectable({ providedIn: 'root' })
export class AvoirFinancierQuery extends QueryEntity<AvoirFinancierState> {

  constructor(protected override store: AvoirFinancierStore) {
    super(store);
  }

}
