import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {
  ClientSearchResultStore,
  ClientType,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
  Notification,
  NotificationQuery,
  NotificationService,
  SessionQuery,
  SessionService,
  Task,
  TaskQuery,
  UiScreenQuery,
  NotificationStatus,
  NotificationNature, TaskService, Email, UserRole
} from 'common';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dropdown } from 'primeng/dropdown';
import { ID } from '@datorama/akita';
import {MenuItem} from 'primeng/api';

type NotificationMenu = {
  label: string;
  icon?: string;
  command?: any;
};

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  @ViewChild('searchBar', {static: false}) searchBar: Dropdown;

  public displayMenu = false;
  public displaySearchbar = false;
  public displayMenuByRoute = true;
  userFirstname: string|null = '';
  userLastname: string|null = '';
  userId: ID | undefined;
  userRoles: string[];
  // texte recherché
  text = '';
  // resultat de recherche avec objet complet
  searchResults: any[] = [];
  // format pour affichage de l'autocomplete
  searchBarResults: {label: string; value: any; notificationToChecks: LegalPerson[]| NaturalPerson[]}[] = [];
  needDisplaySearchResult = true;
  allNotifications: [] | Notification[] = [];
  notificationsCommentBlocked: [] | Notification[] = [];
  menuCommentBlockedEmptyLabel = 'Vous avez bien travaillé ! Aucun point de blocage ne vous est assigné ! ';
  menuCommentBlocked: [] | NotificationMenu[] = [{label: this.menuCommentBlockedEmptyLabel}];
  notificationsCommentResolved: [] | Notification[] = [];
  menuCommentResolvedEmptyLabel = 'Toujours pas de résolution de vos potentiels points de blocage';
  menuCommentResolved: [] | NotificationMenu[] = [{label: this.menuCommentResolvedEmptyLabel}];
  notificationsCommentConsult: [] | Notification[] = [];
  menuCommentConsultEmptyLabel = 'Tout va bien : aucun commentaire ne vous est assigné';
  menuCommentConsult: [] | NotificationMenu[] = [{label: this.menuCommentConsultEmptyLabel}];
  menuItems: MenuItem[];

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private sessionQuery: SessionQuery,
    private legalPersonQuery: LegalPersonQuery,
    private naturalPersonQuery: NaturalPersonQuery,
    private clientSearchResultStore: ClientSearchResultStore,
    private notificationQuery: NotificationQuery,
    private notificationService: NotificationService,
    private taskQuery: TaskQuery,
    public uiScreenQuery: UiScreenQuery,
    private taskService: TaskService,
    private cdref: ChangeDetectorRef
  ) {}

  showMenu(): void {
    this.displayMenu = !this.displayMenu;
  }
  hideMenu(): void {
    this.displayMenu = false;
  }

  showSearchbar(){
    this.displaySearchbar = !this.displaySearchbar;
  }

  hideSearchbar(){
    this.displaySearchbar = false;
  }

  ngOnInit(): void {
    this.sessionQuery.select().pipe(untilDestroyed(this)).subscribe((sessionUser) => {
      if (sessionUser) {
        this.userFirstname = sessionUser.firstname;
        this.userLastname = sessionUser.lastname;
        this.userId = sessionUser.id;
        this.userRoles = sessionUser.roles;
      }
    });

    this.menuItems = [
      {
        label: 'Déconnexion',
        icon: 'pi pi-sign-out',
        command: () => {
          this.logOut();
        },
      },
      {
        label: 'Mon recueil',
        icon: 'pi pi-book',
        command: () => {
          window.open('/recueil/NATURAL_PERSON/'+this.sessionQuery.getValue().id);
        },
      },
    ];

    // Get user notification(s).
    this.notificationQuery.selectAll({
      filterBy: [
        (entity) => entity.user.id === this.userId && entity.state !== NotificationStatus.READ
      ]
    }).pipe(untilDestroyed(this)).subscribe((notifications) => {
      //réinit des menu pour le cas de la dernere notif du menu cliquée
      this.menuCommentBlocked = [{label: this.menuCommentBlockedEmptyLabel}];
      this.menuCommentConsult = [{label: this.menuCommentConsultEmptyLabel}];
      this.menuCommentResolved = [{label: this.menuCommentResolvedEmptyLabel}];
      if (notifications) {
        this.allNotifications = this.getLastestNotifications(notifications);
        // MENU BLOCKED
        this.notificationsCommentBlocked = this.getFilteredNotificationBy(NotificationNature.COMMENT_BLOCKED, this.allNotifications);
        let commentBlockedNotificationRelatedTask: [] | Task[] = [];
        this.notificationsCommentBlocked.map((notification) => {
          this.taskService.get(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe(
            () => {
              this.taskQuery.selectEntity(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe((task) => {
                if (task && !commentBlockedNotificationRelatedTask.some((relatadTask: Task) => relatadTask.id === this.getNotificationRelatedTaskId(notification) )) {
                  // @ts-ignore
                  commentBlockedNotificationRelatedTask = [...commentBlockedNotificationRelatedTask, task];
                  this.menuCommentBlocked = this.buildNotificationMenu(this.notificationsCommentBlocked, NotificationNature.COMMENT_BLOCKED, commentBlockedNotificationRelatedTask);
                }
              });
            },
            () => {
              this.notificationsCommentConsult = this.notificationsCommentConsult.filter((notificationToKeep) => notificationToKeep !== notification);
              this.cdref.markForCheck();
              this.notificationService.delete(notification.id).pipe(untilDestroyed(this)).subscribe();
            }
          );

        });

        // MENU RESOLVED
        this.notificationsCommentResolved = this.getFilteredNotificationBy(NotificationNature.COMMENT_RESOLVED, this.allNotifications);
        let commentResolvedNotificationRelatedTask: [] | Task[] = [];
        this.notificationsCommentResolved.map((notification) => {
          this.taskService.get(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe(
            () => {
              this.taskQuery.selectEntity(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe((task) => {
                if (task && !commentResolvedNotificationRelatedTask.some((relatadTask: Task) => relatadTask.id === this.getNotificationRelatedTaskId(notification) )) {
                  // @ts-ignore
                  commentResolvedNotificationRelatedTask = [...commentResolvedNotificationRelatedTask, task];
                  this.menuCommentResolved = this.buildNotificationMenu(this.notificationsCommentResolved, NotificationNature.COMMENT_RESOLVED, commentResolvedNotificationRelatedTask);
                }
              });
            },
            () => {
              this.notificationsCommentConsult = this.notificationsCommentConsult.filter((notificationToKeep) => notificationToKeep !== notification);
              this.cdref.markForCheck();
              this.notificationService.delete(notification.id).pipe(untilDestroyed(this)).subscribe();
            }
          );

        });

        // MENU CONSULT
        this.notificationsCommentConsult = this.getFilteredNotificationBy(NotificationNature.COMMENT_CONSULT, this.allNotifications);
        let commentConsultNotificationRelatedTask: [] | Task[] = [];
        this.notificationsCommentConsult.map((notification) => {
          this.taskService.get(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe(
            () => {
              this.taskQuery.selectEntity(this.getNotificationRelatedTaskId(notification)).pipe(untilDestroyed(this)).subscribe((task) => {
                if (task && !commentConsultNotificationRelatedTask.some((relatadTask: Task) => relatadTask.id === this.getNotificationRelatedTaskId(notification))) {
                  // @ts-ignore
                  commentConsultNotificationRelatedTask = [...commentConsultNotificationRelatedTask, task];
                  this.menuCommentConsult = this.buildNotificationMenu(this.notificationsCommentConsult, NotificationNature.COMMENT_CONSULT, commentConsultNotificationRelatedTask);
                }
              });
            },
            ()=> {
              this.notificationsCommentConsult = this.notificationsCommentConsult.filter((notificationToKeep) => notificationToKeep !== notification);
              this.cdref.markForCheck();
              this.notificationService.delete(notification.id).pipe(untilDestroyed(this)).subscribe();
            }
          );

        });
      }
      this.cdref.markForCheck();
    });
  }

  logOut(){
    this.sessionService.logout();
    this.router.navigate(['/login']);
  }

  isClientAndCOllab(){
    return this.userRoles?.includes(UserRole.ROLE_CLIENT) && this.userRoles?.includes(UserRole.ROLE_COLLABORATEUR);
  }

  searchClient(event: any){
    this.needDisplaySearchResult = true;
    const normalizedSearchString = event.query
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/-/g, ' ')
      .replace(/'/g, ' ');
    let naturalPersonResults: any[] = [];
    let legalPersonResults: any[] = [];
    // get des legalPeople et naturalPeople en filtrant sur le text recherché
    // mise en forme du resultat dans un format adapté à l'autocomplete et en ajoutant le type LEGAL ou NATURAL
    const legalPersonObs = this.legalPersonQuery.selectAll({
      filterBy: [
        (entity) => {
        const normalizedName = entity.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-/g, ' ')
        .replace(/'/g, ' ');
        return normalizedName.includes(normalizedSearchString);
        },
      ]
    }).pipe(
      tap(() => legalPersonResults = []),
      map(clients => clients.map(client => {
        legalPersonResults = [...legalPersonResults, {label: client.name, value: {...client, clientType:ClientType.LEGAL_PERSON}}];
      }))
    );

    const naturalPersonObs = this.naturalPersonQuery.selectAll({
      filterBy: [
        (entity) => {
        const normalizedFirstName = entity.firstName.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-/g, ' ')
          .replace(/'/g, ' ');
        const normalizedBirthName = entity.birthName?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-/g, ' ')
          .replace(/'/g, ' ');
        const normalizedLastName = entity.lastName.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/-/g, ' ')
          .replace(/'/g, ' ');
        return (normalizedFirstName + ' ' + normalizedLastName + ' ' + normalizedBirthName).includes(normalizedSearchString) ||
          (normalizedLastName + ' ' + normalizedFirstName + ' ' + normalizedBirthName).includes(normalizedSearchString) ||
          (normalizedFirstName + ' ' + normalizedBirthName).includes(normalizedSearchString) ||
          (normalizedBirthName + ' ' + normalizedFirstName).includes(normalizedSearchString);
        },
      ]
    }).pipe(
      tap(() => naturalPersonResults = []),
      map(clients => clients.map(client => {
        naturalPersonResults = [...naturalPersonResults, {
          label: client.lastName + ' ' + client.firstName,
          value: { ...client, clientType:ClientType.NATURAL_PERSON }
        }];
      }))
    );

    // reception des 2 observables
    // mise en forme de l'objet searchBarResults pour l'autocomplete, trié par type de clients

    combineLatest([legalPersonObs, naturalPersonObs]).subscribe(
      () => {
      this.searchResults = [];
      this.searchBarResults = legalPersonResults.concat(naturalPersonResults).sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
      naturalPersonResults.map(naturalPersonResult => {
        this.searchResults =  [...this.searchResults,naturalPersonResult.value];
      });

      legalPersonResults.map(legalPersonResult => {
        this.searchResults =  [...this.searchResults,legalPersonResult.value];
      });
    });
  }

  onSearchResultSelect(event: any){
    this.text = '';
    if (event.value.clientType === ClientType.LEGAL_PERSON) {
      this.router.navigate(['legal-person/' + event.value.id]);
    } else {
      this.router.navigate(['natural-person/' + event.value.id]);
    }
    this.needDisplaySearchResult = false;
    this.hideSearchbar();
  }

  displaySearchResult(){
    if(this.needDisplaySearchResult){
      this.searchBar.hide();
      this.text = '';
      this.clientSearchResultStore.reset();
      this.clientSearchResultStore.update({clients: this.searchResults});
      this.router.navigate(['/client-search']);
      this.hideSearchbar();
    }
  }

  buildNotificationMenu(notifications: Notification[], notificationNature: NotificationNature, notificationRelatedTask: [] | Task[]) {
    const menuIcon = 'pi pi-check-circle';

    notificationRelatedTask = [];

    notifications.map((notification) => {
      this.taskQuery.selectEntity(
        this.getNotificationRelatedTaskId(notification)
      ).pipe(untilDestroyed(this)).subscribe((task) => {
        if (task && !notificationRelatedTask.some((relatadTask: Task) => relatadTask.id === this.getNotificationRelatedTaskId(notification) )) {
          // @ts-ignore
          notificationRelatedTask = [...notificationRelatedTask, task];
        }
      });
    });

    let notificationMenus: [] | NotificationMenu[] = [];

    notificationRelatedTask.map((task) => {
      // Case with only natural person(s) or case with natural and legal person(s) related to the task.
      if ((task.taskGroup?.step.naturalPersons && task.taskGroup?.step.naturalPersons.length > 0 && task.taskGroup?.step.legalPersons && task.taskGroup?.step.legalPersons.length === 0)
        ||
        (task.taskGroup?.step.legalPersons && task.taskGroup?.step.legalPersons.length > 0 && task.taskGroup?.step.naturalPersons && task.taskGroup?.step.naturalPersons.length > 0))
      {
        const notificationMenu: NotificationMenu = {
          label: task.taskGroup?.step.naturalPersons[0].lastName + ' ' + task.taskGroup?.step.naturalPersons[0].firstName + ' - ' + task.name,
          icon: menuIcon,
          command: () => {
            this.goToTaskCustomer(task.taskGroup?.step.id, task.id, notificationNature);
          },
        };
        notificationMenus = [...notificationMenus, notificationMenu];
      }

      // Case with only legal person(s) related to the task.
      if (task.taskGroup?.step.legalPersons && task.taskGroup?.step.legalPersons.length > 0
        && task.taskGroup?.step.naturalPersons && task.taskGroup?.step.naturalPersons.length === 0)
      {
        const notificationMenu: NotificationMenu = {
          label: task.taskGroup?.step.legalPersons[0].name + ' - ' + task.name,
          icon: menuIcon,
          command: () => {
            this.goToTaskCustomer(task.taskGroup?.step.id, task.id, notificationNature);
          },
        };
        notificationMenus = [...notificationMenus, notificationMenu];
      }

      // Case internal task.
      if (!task.taskGroup) {
        const notificationMenu: NotificationMenu = {
          label: task.name,
          icon: menuIcon,
          command: () => {
            this.goToTaskinternal(task.id, notificationNature);
          },
        };
        notificationMenus = [...notificationMenus, notificationMenu];
      }
    });

    if (notificationMenus.length === 0) {
      switch (notificationNature) {
        case NotificationNature.COMMENT_BLOCKED:
          return [{label: this.menuCommentBlockedEmptyLabel}];
        case NotificationNature.COMMENT_RESOLVED:
          return [{label: this.menuCommentResolvedEmptyLabel}];
        case NotificationNature.COMMENT_CONSULT:
          return [{label: this.menuCommentConsultEmptyLabel}];
      }
    }

    return notificationMenus;
  }

  // Navigation to the task and confirm that user see the notification (for resolved and consult notifications).
  goToTaskCustomer(stepId: undefined | ID, taskId: undefined | ID, notificationNature: NotificationNature) {
    if (stepId && taskId) {
      if (notificationNature === NotificationNature.COMMENT_RESOLVED || notificationNature === NotificationNature.COMMENT_CONSULT) {
        const notificationsToClose = this.allNotifications.filter((notification: Notification) =>  notification.nature === notificationNature && this.getNotificationRelatedTaskId(notification) === taskId);
        notificationsToClose.map((notification) => {
          this.notificationService.update(notification.id, {state: NotificationStatus.READ}).subscribe();
        });
      }
      this.router.navigate(['task-customer-edit/' + stepId + '/' + taskId]);
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  goToTaskinternal(taskId: undefined | ID, notificationNature: NotificationNature) {
    if (taskId) {
      if (notificationNature === NotificationNature.COMMENT_RESOLVED || notificationNature === NotificationNature.COMMENT_CONSULT) {
        const notificationsToClose = this.allNotifications.filter((notification: Notification) =>  notification.nature === notificationNature && this.getNotificationRelatedTaskId(notification) === taskId);
        notificationsToClose.map((notification) => {
          this.notificationService.update(notification.id, {state: NotificationStatus.READ}).subscribe();
        });
      }
      this.router.navigate(['task-internal-edit/' + taskId]);
    } else {
      this.router.navigate(['dashboard']);
    }
  }

  // Extract lastest notifications of an array of notifications.
  getLastestNotifications(notifications: Notification[]) {
    const latestNotifications: [] | Notification[] = [];

    notifications.map((notification) => {
      if (this.isLastestNotification(notifications, notification)) {
        // @ts-ignore
        latestNotifications.push(notification);
      }
    });

    return latestNotifications;
  }

  // Verify that a notification is the lastet one of an array of notifications.
  isLastestNotification(allNotifications: Notification[], notificationToCheck: Notification) {
    return allNotifications.some((notification) =>
        (this.getNotificationRelatedCommentId(notificationToCheck) === this.getNotificationRelatedCommentId(notification)
          && notificationToCheck.created_at > notification.created_at)
      )
      ||
      allNotifications.filter((notification) =>
        (this.getNotificationRelatedCommentId(notificationToCheck) === this.getNotificationRelatedCommentId(notification))
      ).length === 1;
  }

  // Get notification related comment int ID.
  getNotificationRelatedCommentId(notification: Notification) {
    return Number(notification.link.split('#')[1]);
  }

  // Get notifcation related task int ID.
  getNotificationRelatedTaskId(notification: Notification) {
    return Number(notification.link.substring(notification.link.lastIndexOf('/') + 1, notification.link.indexOf('#')));
  }

  // Get notifications filtered by there nature.
  getFilteredNotificationBy(filter: NotificationNature, notifications: Notification[]) {
    return notifications.filter((notification) => notification.nature === filter);
  }
}
