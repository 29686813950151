import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AddressType, Address, NaturalPerson} from 'common';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true
    }
  ]
})
export class ContactFormComponent implements ControlValueAccessor, OnDestroy {
  @Input() client: NaturalPerson | undefined;
  @Input() conjoint = false;
  @Input() recueilNaturalPerson = false;
  @Input() conjointHasPhones = false;
  @Input() conjointHasAddresses = false;
  @Input() clientOrAdmin = '';
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  contactEmail: any = null;
  contactEmailWaringText: string | null = null;
  valid = {
    phoneContact: true,
    emailContact: true,
    addressContact: true,
    addressFiscale: true
  };
  initialPhoneValues = {
    id: null,
    dialCode: null,
    countryCode: 'fr',
    isContact: false,
    number: null,
    phone: null,
    type: null,
    label: null,
  };
  initialEmailValues = {
    id: null,
    email: null,
    isContact: false,
    label: null,
    type: null,
  };
  initialAddressValues: any = {
    id: null,
    label: null,
    street: null,
    city: null,
    zipCode: null,
    state: null,
    country: null,
    isContact: false,
    fiscale: false,
    type: null,
  };

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({
      phones: this.formBuilder.array([]),
      samePhones: this.formBuilder.control({}),
      areSamePhonesDisplayed: formBuilder.control({}),
      emails: this.formBuilder.array([]),
      addresses: this.formBuilder.array([]),
      sameAddresses: this.formBuilder.control({}),
      areSameAddressesDisplayed: formBuilder.control({}),
      rentPrincipalResidence: this.formBuilder.control({}),
      loyerAnnuel: this.formBuilder.control({}),
    }, {validators: [this.contactValidator]});
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe(value => {
          if(this.clientOrAdmin === 'ADMIN'){
            this.checkContactEmail(value);
          }
          const formValue = this.form.getRawValue();
          formValue.phones = formValue.phones.filter((phone: any) => phone.number !== null);
          formValue.emails = formValue.emails.filter((email: any) => email.email !== null);
          this.onChange(formValue);
          this.onTouched();
        })
    );
  }

  get phonesControl() {
    return this.form.get('phones') as UntypedFormArray;
  }

  get samePhonesControl(): UntypedFormControl {
    return this.form.get('samePhones') as UntypedFormControl;
  }

  get areSamePhonesDisplayedControl(): UntypedFormControl {
    return this.form.get('areSamePhonesDisplayed') as UntypedFormControl;
  }

  get areSameAddressesDisplayedControl(): UntypedFormControl {
    return this.form.get('areSameAddressesDisplayed') as UntypedFormControl;
  }

  get sameAddressesControl(): UntypedFormControl {
    return this.form.get('sameAddresses') as UntypedFormControl;
  }

  get emailsControl() {
    return this.form.get('emails') as UntypedFormArray;
  }

  get addressesControl() {
    return this.form.get('addresses') as UntypedFormArray;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    this.contactEmail = value.emails.filter((email: any) => email.isContact)[0];
    if (this.recueilNaturalPerson) {
      this.form.addValidators(this.fiscaleValidator);
    }
    while (this.phonesControl.length !== 0) {
      this.phonesControl.removeAt(0, {emitEvent:false});
    }
    while (this.emailsControl.length !== 0) {
      this.emailsControl.removeAt(0, {emitEvent:false});
    }
    while (this.addressesControl.length !== 0) {
      this.addressesControl.removeAt(0), {emitEvent:false};
    }

    value.phones?.map((val: any) => {
      this.phonesControl.push(this.formBuilder.control({}), {emitEvent:false});
    });

    value.emails?.map((val: any) => {
      if (val.isContact && this.recueilNaturalPerson) {
        const formControl = this.formBuilder.control({value: val});
        formControl.disable();
        this.emailsControl.push(formControl, {emitEvent:false});
      } else {
        this.emailsControl.push(this.formBuilder.control({}), {emitEvent:false});
      }
    });

    value.addresses?.map((val: any) => {
      this.addressesControl.push(this.formBuilder.control({}), {emitEvent:false});
    });

    let initialSamePhone: any[] = [];
    if(this.conjoint && value.phones.length === 0){
      initialSamePhone = [this.client?.phones[0]];
    }

    let initialSameAddresses: any[] = [];
    if (this.conjoint && value.addresses.length === 0) {
      initialSameAddresses = [this.client?.addresses[0]];
    }


    this.value = {
      phones: value.phones,
      samePhones: initialSamePhone,
      areSamePhonesDisplayed: false,
      emails: value.emails,
      addresses: value.addresses,
      sameAddresses: initialSameAddresses,
      areSameAddressesDisplayed: false,
      rentPrincipalResidence: value.rentPrincipalResidence,
      loyerAnnuel: value.loyerAnnuel,
    };
    // ajout bloc phone si pas de phone
    if (value.phones.length === 0){
      this.addPhone();
    }
    if (value.addresses.length === 0){
      this.addAdrress();
    }
  }

  togglePhoneIsContact() {
    // closure pour acceder au contact form et non au form enfant
    return {
      callParentMethod: () => {
        this.phonesControl.controls.map((control, index) => {
          this.phonesControl.controls[index].patchValue({...control.value, isContact: false});
        });
      }
    };
  }

  toggleEmailIsContact() {
    // closure pour acceder au contact form et non au form enfant
    return {
      callParentMethod: (email: any) => {
        this.emailsControl.controls.map((control, index) => {
          if(email !== this.emailsControl.controls[index].value.email){
            this.emailsControl.controls[index].patchValue({...control.value, isContact: false});
          }
        });
      }
    };
  }

  toggleAddressIsContact() {
    // closure pour acceder au contact form et non au form enfant
    return {
      callParentMethod: () => {
        this.addressesControl.controls.map((control, index) => {
          this.addressesControl.controls[index].patchValue({...control.value, isContact: false});
        });
      }
    };
  }

  toggleAddressIsFiscale() {
    // closure pour acceder au contact form et non au form enfant
    return {
      callParentMethod: () => {
        this.addressesControl.controls.map((control, index) => {
          this.addressesControl.controls[index].patchValue({...control.value, fiscale: false});
        });
      }
    };
  }

  checkContactEmail(formValue: any){
    //message d'alerte pour l'admin, si le client à un compte et qu'on modifie son mail de contact
    if (formValue.emails?.length && this.client?.userAccount && formValue.emails?.filter((email: any)=>email.isContact)[0]?.email !== this.contactEmail?.email){
      this.contactEmailWaringText = 'Attention : Vous allez modifier l\'email de contact qui est également utilisée comme login du client. Pensez à informer votre client.';
    } else {
      this.contactEmailWaringText = null;
    }
  }

  addAdrress() {
    this.initialAddressValues.isContact = !!this.addressesControl.value.every((address: any) => !address.isContact);

    this.initialAddressValues.fiscale = !!this.addressesControl.value.every((address: any) => !address.fiscale);

    if (this.addressesControl.value.every((address: Address) => address.type !== AddressType.ADDRESS_PRINCIPALE)) {
      this.initialAddressValues.type = AddressType.ADDRESS_PRINCIPALE;
    } else {
      this.initialAddressValues.type = null;
    }

    this.addressesControl.push(this.formBuilder.control({...this.initialAddressValues}));
  }

  addPhone() {
    this.initialPhoneValues.isContact = !!this.phonesControl.value.every((phone: any) => !phone.isContact);
    this.phonesControl.push(this.formBuilder.control({...this.initialPhoneValues}));
  }

  addEmail() {
    this.initialEmailValues.isContact = !!this.emailsControl.value.every((email: any) => !email.isContact);
    this.emailsControl.push(this.formBuilder.control({...this.initialEmailValues}));
  }

  removeAddress(index: number) {
    this.addressesControl.removeAt(index);

  }

  removePhone(index: number) {
    this.phonesControl.removeAt(index);
  }

  removeEmail(index: number) {
    this.emailsControl.removeAt(index);

  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  contactValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let error = false;
    if (control.get('phones')['controls'].length && control.get('phones')['controls'].every((val: any) => !val.value.isContact)) {
    // if (control.get('phones')?.value.length && control.get('phones')?.value.every((val: any) => !val.isContact)) {
      this.valid.phoneContact = false;
      error = true;
    } else {
      this.valid.phoneContact = true;
    }

    if (control.get('emails')['controls'].length && control.get('emails')['controls'].every((val: any) => !val.value.isContact)) {
    // if (this.form?.getRawValue().emails.length && this.form.getRawValue().emails.every((val: any) => !val.isContact)) {
      this.valid.emailContact = false;
      error = true;
    } else {
      this.valid.emailContact = true;
    }

    if (control.get('addresses')['controls'].length && control.get('addresses')['controls'].every((val: any) => !val.value.isContact)) {
    // if (control.get('addresses')?.value.length && control.get('addresses')?.value.every((val: any) => !val.isContact)) {
      this.valid.addressContact = false;
      error = true;
    } else {
      this.valid.addressContact = true;
    }
    return error ? this.valid : null;
  };

  fiscaleValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let error = false;
    if (control.get('addresses')?.value.length && control.get('addresses')?.value.every((val: any) => !val.fiscale)) {
      this.valid.addressFiscale = false;
      error = true;
    } else {
      this.valid.addressFiscale = true;
    }
    return error ? this.valid : null;
  };

  validate(_: UntypedFormControl) {
    return this.form.errors ? this.form.errors : null;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }
}
