import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { LegalPerson } from './legal-person.model';

export interface LegalPersonState extends EntityState<LegalPerson> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'legal_people',
  })
export class LegalPersonStore extends EntityStore<LegalPersonState> {

  constructor() {
    super();
  }

}
