import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {SessionQuery} from '../store/session/state/session.query';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {SessionState, SessionStore} from '../store/session/state/session.store';
import {SessionService} from '../store/session/state/session.service';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
      private sessionService: SessionService,
      private sessionQuery: SessionQuery,
      private sessionStore: SessionStore,
      private messageService: MessageService,
      private router: Router,
      @Inject('config') private config: any,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url except refresh request himself
        const currentUser = this.sessionQuery.getValue();
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(this.config.apiUrl);
        const isRefreshTokenUrl = request.url.startsWith(this.config.apiUrl+ '/token/refresh');
        if (isLoggedIn && isApiUrl && !isRefreshTokenUrl) {
            request = this.addTokenHeader(request, currentUser.token);
        }

        return next.handle(request).pipe(
          catchError((error) => {
            const DEFAULT_REFRESH_URL_BLACKLIST = [
              'login',
              'token',
            ];
            const isError = error instanceof HttpErrorResponse;
            const isStatus401 = error.status === 401;
            const isWhitelist = !DEFAULT_REFRESH_URL_BLACKLIST.find((url) => request.url.includes(url));
            if (isError && isStatus401 && isWhitelist) {
              return this.handle401Error(request, next);
            }

            return throwError(error);
          })
        );
    }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.sessionQuery.getValue().refresh_token;

      if (token){
        return this.sessionService.refreshToken({refresh_token: token}).pipe(
          switchMap((user: SessionState) => {
            this.isRefreshing = false;

            this.sessionStore.update(user);
            this.refreshTokenSubject.next(user.token);

            return next.handle(this.addTokenHeader(request, user.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;

            this.sessionService.logout();
            this.messageService.add({
              severity: 'warn',
              summary: 'Votre session a expiré',
              detail: 'Votre session a expiré, merci de vous reconnecter.',
              life: 5000
            });
            this.router.navigate(['/login']);
            return throwError(err);
          })
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string|null) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

}
