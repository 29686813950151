import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder, UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {
  NaturalPersonQuery,
  LegalPersonQuery,
  LegalPerson,
  NaturalPerson,
  ClientType
} from 'common';
import {ID} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-natural-legal-relation-form',
  templateUrl: './natural-legal-relation-form.component.html',
  styleUrls: ['./natural-legal-relation-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NaturalLegalRelationFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NaturalLegalRelationFormComponent),
      multi: true
    }
  ]
})
export class NaturalLegalRelationFormComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {

  @Input() currentNaturalPersonId: ID | undefined | null = null;
  @Input() currentLegalPersonId: ID | undefined | null = null;
  @Input() clientType: string;
  @Input() isNewLegalPerson = false;
  @Input() markAsTouched = false;

  allNaturalPerson: NaturalPerson[] = [];
  allLegalPerson: LegalPerson[] = [];
  form = this.formBuilder.group({
    naturalPerson: [null, Validators.required],
    naturalPersonRole: [null, Validators.required],
  });

  subscriptions: Subscription[] = [];

  naturalPersonOptions: any[] = [];
  legalPersonOptions: any[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery,
  ) {
  }

  ngOnInit(): void {
    this.naturalPersonQuery.selectAll().pipe(untilDestroyed(this)).subscribe(naturalPersons => {
      this.allNaturalPerson = naturalPersons;
      let unsortedNaturalPersonOptions: [] | {label: string; value: ID | undefined}[] = [];
      naturalPersons.map( naturalPerson => {
        unsortedNaturalPersonOptions = [...unsortedNaturalPersonOptions,{
            label: naturalPerson.lastName + ' ' + naturalPerson.firstName,
            value: naturalPerson.id
          }];
        }
      );
      this.naturalPersonOptions = unsortedNaturalPersonOptions.sort((resultA: {label: string; value: ID | undefined}, resultB: {label: string; value: ID | undefined}) => resultA?.label.localeCompare(resultB?.label, 'fr', {sensitivity: 'base'}));
    });
    this.legalPersonQuery.selectAll().subscribe(legalPersons => {
      this.allLegalPerson = legalPersons;
      legalPersons.map( legalPerson => {
          this.legalPersonOptions = [...this.legalPersonOptions,{
            label: legalPerson.name,
            value: legalPerson.id
          }];
        }
      );
    });
  }

  /* get form from template */
  get naturalPersonF() { return this.form?.get('naturalPerson');}
  get legalPersonF() { return this.form?.get('legalPerson');}
  get naturalPersonRoleF() { return this.form?.get('naturalPersonRole');}

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['clientType']){
      if(changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.form = this.formBuilder.group({
          naturalPerson: [null, Validators.required],
          naturalPersonRole: [null, Validators.required],
        });
      }
      if(changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.form = this.formBuilder.group({
          legalPerson: [null, Validators.required],
          naturalPersonRole: [null, Validators.required],
        });
      }
      this.subscriptions.push(
        // any time the inner form changes update the parent of any change
        this.form.valueChanges.subscribe(value => {
          this.onChange(value);
          this.onTouched();
        })
      );
    }
  }
}
