import {NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {PageLoginComponent} from './pages/user/page-login/page-login.component';
import {PageUserListComponent} from './pages/admin/page-user-list/page-user-list.component';
import {AuthGuard} from './guards/auth.guard';
import {PageUserNewComponent} from './pages/admin/page-user-new/page-user-new.component';
import {PageActivateAccountComponent} from './pages/user/page-activate-account/page-activate-account.component';
import {PageNaturalPersonComponent} from './pages/client/page-natural-person/page-natural-person.component';
import {PageLegalPersonComponent} from './pages/client/page-legal-person/page-legal-person.component';
import {PagePasswordForgottenComponent} from './pages/user/page-password-forgotten/page-password-forgotten.component';
import {FormGuard, UiModule, UserRole} from 'common';
import {PageDashboardComponent} from './pages/page-dashboard/page-dashboard.component';
import {PageLegalPersonNewComponent} from './pages/client/page-legal-person-new/page-legal-person-new.component';
import {PageMeetingReportNewComponent} from './pages/page-meeting-report-new/page-meeting-report-new.component';
import {PageClientSearchComponent} from './pages/client/page-client-search/page-client-search.component';
import {PageMeetingReportEditComponent} from './pages/page-meeting-report-edit/page-meeting-report-edit.component';
import {PageMeetingReportComponent} from './pages/page-meeting-report/page-meeting-report.component';
import {PageNaturalPersonNewComponent} from './pages/client/page-natural-person-new/page-natural-person-new.component';
import {PageTaskCustomerComponent} from './pages/tim/page-task-customer/page-task-customer.component';
import {PageStepsMasterListComponent} from './pages/admin/page-steps-master-list/page-steps-master-list.component';
import {PageStepsMasterComponent} from './pages/admin/page-steps-master/page-steps-master.component';
import {PageTaskInternalComponent} from './pages/tim/page-task-internal/page-task-internal.component';
import {PageProductListComponent} from './pages/admin/page-product-list/page-product-list.component';
import {PageProductComponent} from './pages/admin/page-product/page-product.component';
import {PageMondayReviewComponent} from './pages/page-monday-review/page-monday-review.component';
import {UiSummaryComponent} from './pages/ui-summary/ui-summary.component';
import {PageNewClientStepComponent} from './pages/tim/page-new-client-step/page-new-client-step.component';
import {PageTimelineComponent} from './pages/page-timeline/page-timeline.component';
import {PageBienDiversListComponent} from './pages/admin/page-bien-divers-list/page-bien-divers-list.component';
import {PageObjectifListComponent} from './pages/admin/page-objectif-list/page-objectif-list.component';
import {
  PageAdministrableTextListComponent
} from './pages/admin/page-administrable-text-list/page-administrable-text-list.component';
import {
  PageNewNaturalPersonsListComponent
} from './pages/admin/page-new-natural-persons-list/page-new-natural-persons-list.component';
import {RoleGuard} from './guards/role.guard';
import {PageRecueilClientComponent} from './pages/recueil/page-recueil-client/page-recueil-client.component';
import {
  PageRecueilClientBienvenueComponent
} from './pages/recueil/page-recueil-client-bienvenue/page-recueil-client-bienvenue.component';
import {PageRecueilConjointComponent} from './pages/recueil/page-recueil-conjoint/page-recueil-conjoint.component';
import {RecueilFinalComponent} from './components/recueil/recueil-final/recueil-final.component';

// TODO : À déplacer et à commenter de façon plus détaillée
// Permet de réinitialiser un composant quand on navigue sur une même page mais avec un paramètre différent
// Par exemple : quand on passe d'une fiche client à une autre
export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    if (
      future.routeConfig &&
      future.routeConfig.data &&
      // @ts-ignore
      future.routeConfig.data['reuse'] !== undefined
    ) {
      // @ts-ignore
      return future.routeConfig.data['reuse'];
    }
    return future.routeConfig === current.routeConfig;
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: PageLoginComponent,
  },
  {
    path: 'activate-account',
    component: PageActivateAccountComponent,
  },
  {
    path: 'password-forgotten',
    component: PagePasswordForgottenComponent,
  },
  {
    path: 'change-password',
    component: PageActivateAccountComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, RoleGuard],
    component: PageDashboardComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN]
    }
  },
  {
    path: 'monday-review',
    canActivate: [AuthGuard, RoleGuard],
    component: PageMondayReviewComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'task-internal-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageTaskInternalComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'task-internal-edit/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageTaskInternalComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'task-customer-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageTaskCustomerComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'task-customer-edit/:stepId/:taskId',
    canActivate: [AuthGuard, RoleGuard],
    component: PageTaskCustomerComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'user-list',
    canActivate: [AuthGuard, RoleGuard],
    component: PageUserListComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN]
    }
  },
  {
    path: 'client-step-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageNewClientStepComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'user-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageUserNewComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'bien-divers-list', canActivate: [AuthGuard, RoleGuard],
    component: PageBienDiversListComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN]
    }
  },
  {
    path: 'objectif-list/:clientType',
    canActivate: [AuthGuard, RoleGuard],
    component: PageObjectifListComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN]
    }
  },
  {
    path: 'administrable-text-list',
    canActivate: [AuthGuard, RoleGuard],
    component: PageAdministrableTextListComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN]
    }
  },
  {
    path: 'new-natural-persons-list',
    canActivate: [AuthGuard, RoleGuard],
    component: PageNewNaturalPersonsListComponent,
    data: {
      roles: [UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE]
    }
  },
  {
    path: 'steps-master-list', canActivate: [AuthGuard, RoleGuard],
    component: PageStepsMasterListComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'steps-master-edit/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageStepsMasterComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'user-edit/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageUserNewComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'natural-person-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageNaturalPersonNewComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'natural-person/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageNaturalPersonComponent,
    canDeactivate: [FormGuard],
    data: {
      reuse: false,
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'client-search',
    canActivate: [AuthGuard, RoleGuard],
    component: PageClientSearchComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'legal-person-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageLegalPersonNewComponent,
    canDeactivate: [FormGuard],
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'legal-person/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageLegalPersonComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'meeting-report-new',
    canActivate: [AuthGuard, RoleGuard],
    component: PageMeetingReportNewComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'meeting-report-edit/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageMeetingReportEditComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'meeting-report/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageMeetingReportComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'product-list',
    canActivate: [AuthGuard, RoleGuard],
    component: PageProductListComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'product-create',
    canActivate: [AuthGuard, RoleGuard],
    component: PageProductComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'product-edit/:id',
    canActivate: [AuthGuard, RoleGuard],
    component: PageProductComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'welcome/:userId',
    component: PageRecueilClientBienvenueComponent,
  },
  {
    path: 'recueil/:clientType/:userId',
    canActivate: [AuthGuard, RoleGuard],
    component: PageRecueilClientComponent,
    data: {
      roles: [UserRole.ROLE_CLIENT]
    }
  },
  {
    path: 'recueil-update/:clientType/:userId',
    canActivate: [AuthGuard, RoleGuard],
    component: PageRecueilClientComponent,
    data: {
      roles: [UserRole.ROLE_CLIENT]
    }
  },
  {
    path: 'recueil-conjoint/:conjointId',
    component: PageRecueilConjointComponent,
  },
  {
    path: 'recueil/recueil-final',
    component: RecueilFinalComponent,
  },
  {
    path: 'timeline',
    canActivate: [AuthGuard, RoleGuard],
    component: PageTimelineComponent,
    data: {
      roles: [UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_DIRIGEANT]
    }
  },
  {
    path: 'extellient/ui-summary',
    canActivate: [AuthGuard],
    component: UiSummaryComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
    UiModule
  ],
  exports: [RouterModule],
  providers: [FormGuard],
})
export class AppRoutingModule {
}
