import { Injectable } from '@angular/core';
import { TaskStatus } from '../enums/TaskStatus';

import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SessionQuery} from '../store/session/state/session.query';
import {NaturalPersonQuery} from '../store/client/naturalPerson/state/natural-person.query';
import {LegalPersonQuery} from '../store/client/legalPerson/state/legal-person.query';
import {Step} from '../store/task/step/state/step.model';
import {Task} from '../store/task/task/state/task.model';
import {ClientType} from '../enums/client/ClientType';
import moment from 'moment';
import {addWeekDays, weekDays} from 'moment-business';
import { TaskDeadlineStatus } from '../enums/TaskDeadlineStatus';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TaskHelperService {

  taskStatus = TaskStatus;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sessionQuery: SessionQuery,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery
  ) {}

  getGroups(stepForm: UntypedFormGroup) { return stepForm.get('stepGroups') as UntypedFormArray; }
  getTasks(stepForm: UntypedFormGroup, groupIndex: number) { return this.getGroups(stepForm).controls[groupIndex].get('tasks') as UntypedFormArray; }
  getTaskId(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('id'); }
  getTaskName(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('name'); }
  getTaskStartDate(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('startDate'); }
  getTaskDeadlineDate(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('deadlineDate'); }
  getTaskClosingDate(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('closingDate'); }
  getTaskImportance(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('importance'); }
  getTaskPlannedWorkload(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('plannedWorkload'); }
  getTaskRealWorkload(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('realWorkload'); }
  getTaskPoles(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('poles'); }
  getTaskCollaborator(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('collaborator'); }
  getTaskContractor(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('contractor'); }
  getTaskComments(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('comments') as UntypedFormArray; }
  getTaskIsInternal(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('isInternal'); }
  getTaskIsPriority(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('isPriority'); }
  getTaskRelatedPeople(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('relatedPeople') as UntypedFormArray; }
  getTaskStatus(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number) { return this.getTasks(stepForm, groupIndex).controls[taskIndex].get('status'); }
  getTasksFByGroupIndex(stepForm: UntypedFormGroup, index: number) { return this.getGroups(stepForm).controls[index].get('tasks') as UntypedFormArray;}

  getInternalTaskname(taskForm: UntypedFormGroup) { return taskForm.get('name'); }
  getInternalTaskId(taskForm: UntypedFormGroup) { return taskForm.get('id'); }
  getInternalTaskStartDate(taskForm: UntypedFormGroup) { return taskForm.get('startDate'); }
  getInternalTaskDeadlineDate(taskForm: UntypedFormGroup) { return taskForm.get('deadlineDate'); }
  getInternalTaskClosingDate(taskForm: UntypedFormGroup) { return taskForm.get('closingDate'); }
  getInternalTaskImportance(taskForm: UntypedFormGroup) { return taskForm.get('importance'); }
  getInternalTaskPlannedWorkload(taskForm: UntypedFormGroup) { return taskForm.get('plannedWorkload'); }
  getInternalTaskRealWorkload(taskForm: UntypedFormGroup) { return taskForm.get('realWorkload'); }
  getInternalTaskPoles(taskForm: UntypedFormGroup) { return taskForm.get('poles'); }
  getInternalTaskCollaborator(taskForm: UntypedFormGroup) { return taskForm.get('collaborator'); }
  getInternalTaskContractor(taskForm: UntypedFormGroup) { return taskForm.get('contractor'); }
  getInternalTaskComments(taskForm: UntypedFormGroup) { return taskForm.get('comments') as UntypedFormArray; }
  getInternalTaskIsInternal(taskForm: UntypedFormGroup) { return taskForm.get('isInternal'); }
  getInternalTaskIsPriority(taskForm: UntypedFormGroup) { return taskForm.get('isPriority'); }
  getInternalTaskIsPrivate(taskForm: UntypedFormGroup) { return taskForm.get('isPrivate'); }
  getInternalTaskStatus(taskForm: UntypedFormGroup) { return taskForm.get('status'); }
  getInternalTaskIsLeaderPrivate(taskForm: UntypedFormGroup) { return taskForm.get('isLeaderPrivate'); }

  populateTaskEditingForm(editingStep: Step, groupIndex: number, taskIndex: number, dateOffset = 0, isClosingTask = false): UntypedFormGroup {
    const stepForm = this.formBuilder.group({
      id: null,
      name: '',
      product: null,
      amount: null,
      isFollowStep: false,
      legalPersons: null,
      naturalPersons: null,
      stepGroups: this.formBuilder.array([]),
    });
    const editingTask = editingStep.stepGroups[groupIndex].tasks[taskIndex];
    //populate step
    stepForm.get('id')?.setValue(editingStep.id);
    stepForm.get('name')?.setValue(editingStep.name);
    stepForm.get('product')?.setValue(editingStep.product);
    stepForm.get('amount')?.setValue(editingStep.amount);
    stepForm.get('isFollowStep')?.setValue(editingStep.isFollowStep);
    stepForm.get('legalPersons')?.setValue(editingStep.legalPersons);
    stepForm.get('naturalPersons')?.setValue(editingStep.naturalPersons);

    //populate groups
    editingStep.stepGroups.map((group) => {
      this.getGroups(stepForm).push(
        this.formBuilder.group({
          id: group.id,
          delayBefore: group.delayBefore,
          position: group.position,
          tasks: this.formBuilder.array([]),
        })
      );
    });
    //populate tasks
    editingStep.stepGroups.map((group, gIndex) => {
      group.tasks.map((task) => {
        this.getTasksFByGroupIndex(stepForm, gIndex).push(
          this.formBuilder.group({
            id: task.id,
            name: [task.name, Validators.required],
            startDate: [task.startDate, Validators.required],
            deadlineDate: [task.deadlineDate, Validators.required],
            closingDate: task.closingDate,
            importance: [task.importance, Validators.required],
            plannedWorkload: task.plannedWorkload,
            realWorkload: task.realWorkload,
            pole: [task.pole, Validators.required],
            collaborator: task.collaborator ? {id: task.collaborator?.id} : null,
            contractor: {id: task.contractor.id},
            comments: this.formBuilder.array([]),
            status: task.status,
            isInternal: [task.isInternal],
            isPriority: [task.isPriority],
            relatedPeople: this.formBuilder.array([]),
            hiddenUntil: [task.hiddenUntil]
          })
        );
      });
    });
    //populate task comments
    editingStep.stepGroups.map((group, gIndex) => {
      group.tasks.map((task, tIndex) => {
        task.comments?.map((comment) => {
          this.getTaskComments(stepForm, gIndex, tIndex).push(
            this.formBuilder.group({
              id: comment.id,
              author: {id: comment.author.id},
              assignedUsers: this.formBuilder.array(comment.assignedUsers.map((assignedUser) => {return {id: assignedUser.id}})),
              content: comment.content,
              resolved: comment.resolved,
              blocked: comment.blocked,
              creationDate: comment.creationDate,
            })
          );
        });
      });
    });
    //populate task related people
    editingStep.stepGroups.map((group, gIndex) => {
      group.tasks.map((task, tIndex) => {
        editingStep.naturalPersons?.map((naturalPerson) => {
          this.getTaskRelatedPeople(stepForm, gIndex, tIndex).push(
            this.formBuilder.control({
              label: naturalPerson.lastName + ' ' + naturalPerson.firstName,
              value: {
                id: naturalPerson.id,
                clientType: ClientType.NATURAL_PERSON,
              },
            })
          );
        });
        editingStep.legalPersons?.map((legalPerson) => {
          this.getTaskRelatedPeople(stepForm, gIndex, tIndex).push(
            this.formBuilder.control({
              label: legalPerson.name,
              value: {
                id: legalPerson.id,
                clientType: ClientType.LEGAL_PERSON,
              },
            })
          );
        });
      });
    });

    this.getTaskName(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.name);
    this.getTaskStartDate(stepForm, groupIndex, taskIndex)?.setValue(new Date(editingTask?.startDate));
    this.getTaskDeadlineDate(stepForm, groupIndex, taskIndex)?.setValue(new Date(editingTask?.deadlineDate));
    this.getTaskImportance(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.importance);
    this.getTaskPlannedWorkload(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.plannedWorkload);
    this.getTaskRealWorkload(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.realWorkload);
    this.getTaskPoles(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.pole);
    if (editingTask.collaborator) {
      this.getTaskCollaborator(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.collaborator ? {id: editingTask?.collaborator.id} : null);
    }
    this.getTaskContractor(stepForm, groupIndex, taskIndex)?.setValue({id: editingTask?.contractor.id});
    this.getTaskStatus(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.status);
    this.getTaskIsPriority(stepForm, groupIndex, taskIndex)?.setValue(editingTask?.isPriority);
    this.getTaskId(stepForm, groupIndex, taskIndex)?.setValue(editingTask.id);
    this.getTaskRelatedPeople(stepForm, groupIndex, taskIndex)?.setValue(this.getTaskRelatedPeopleForEdition(editingStep));

    this.getTaskComments(stepForm, groupIndex, taskIndex).push(this.formBuilder.group({
      author: {id: this.sessionQuery.getValue().id},
      content: '',
      assignedUsers: this.formBuilder.array([[null]]),
      resolved: false,
      blocked: false,
      creationDate: new Date(),
    }));
    return stepForm;
  }

  recalculateStepDateAndStatus(formValue: [],groupIndex: number, dateOffset = 0) {
    formValue.map((group: any, gIndex) => {
      group.tasks.map((task: any) => {
        const startDate = (gIndex >= groupIndex + 1 && dateOffset !== 0) ? addWeekDays(moment(task.startDate).clone(), dateOffset).toDate() : moment(task.startDate).toDate();
        const deadlineDate = (gIndex >= groupIndex + 1 && dateOffset !== 0) ? addWeekDays(moment(task.deadlineDate).clone(), dateOffset).toDate() : task.deadlineDate;
        task.startDate = startDate;
        task.deadlineDate = deadlineDate;
        task.status = this.calculateStatus(startDate, task.status);
      });
    });
    return formValue;
  }

  populateInternalTaskEditingForm(editingTask: Task): UntypedFormGroup {
    const taskForm = this.formBuilder.group({
      name: [null, Validators.required],
      startDate: [null, Validators.required],
      deadlineDate: [null, Validators.required],
      closingDate: null,
      importance: [null, Validators.required],
      plannedWorkload: [null],
      realWorkload: [null],
      poles: [null, Validators.required],
      collaborator: [null],
      contractor: [null, Validators.required],
      comments: this.formBuilder.array([]),
      status: [null],
      isInternal: [null],
      isPriority: [null],
      isPrivate: [null],
      isLeaderPrivate: [null],
    });

    this.getInternalTaskname(taskForm)?.setValue(editingTask?.name);
    this.getInternalTaskStartDate(taskForm)?.setValue(moment(editingTask?.startDate).clone().toDate());
    this.getInternalTaskDeadlineDate(taskForm)?.setValue(moment(editingTask?.deadlineDate).clone().toDate());
    this.getInternalTaskClosingDate(taskForm)?.setValue(null);
    this.getInternalTaskImportance(taskForm)?.setValue(editingTask?.importance);
    this.getInternalTaskPlannedWorkload(taskForm)?.setValue(editingTask?.plannedWorkload);
    this.getInternalTaskRealWorkload(taskForm)?.setValue(editingTask?.realWorkload);
    this.getInternalTaskPoles(taskForm)?.setValue(editingTask?.pole);
    if (editingTask.collaborator) {
      this.getInternalTaskCollaborator(taskForm)?.setValue(editingTask?.collaborator ? {id: editingTask?.collaborator.id} : null);
    }
    this.getInternalTaskContractor(taskForm)?.setValue({id: editingTask?.contractor.id
  });
    this.getInternalTaskIsPriority(taskForm)?.setValue(editingTask?.isPriority);
    this.getInternalTaskStatus(taskForm)?.setValue(editingTask?.status);
    this.getInternalTaskIsPrivate(taskForm)?.setValue(editingTask?.isPrivate);
    this.getInternalTaskIsInternal(taskForm)?.setValue(true);
    this.getInternalTaskIsLeaderPrivate(taskForm)?.setValue(editingTask?.isLeaderPrivate);

    editingTask.comments?.map(comment => {
      this.getInternalTaskComments(taskForm).push(this.formBuilder.group({
        id: comment.id,
        author: {id: comment.author.id},
        assignedUsers: this.formBuilder.array(comment.assignedUsers),
        content: comment.content,
        resolved: comment.resolved,
        blocked: comment.blocked,
        creationDate: comment.creationDate,
      }));
    });

    this.getInternalTaskComments(taskForm).push(this.formBuilder.group({
      author: {id: this.sessionQuery.getValue().id},
      content: '',
      resolved: false,
      assignedUsers: this.formBuilder.array([[null]]),
      blocked: false,
      creationDate: new Date(),
    }));
    return taskForm;
  }

  getTaskRelatedPeopleForEdition(editingStep: Step) {
    let editingTaskRelatedPeople: [] | any[] = [];
    if (editingStep.naturalPersons) {
      this.naturalPersonQuery
        .selectMany(
          editingStep.naturalPersons.map((naturalPerson) => naturalPerson.id)
        )
        .pipe(untilDestroyed(this))
        .subscribe((naturalPeople) => {
          const relatedNaturalPeople = naturalPeople.map((naturalPerson) => ({
            label: naturalPerson.lastName + ' ' + naturalPerson.firstName,
            value: {
              id: naturalPerson.id,
              clientType: ClientType.NATURAL_PERSON
            }
          }));
          editingTaskRelatedPeople = [
            ...editingTaskRelatedPeople,
            ...relatedNaturalPeople,
          ];
        });
    }

    if (editingStep.legalPersons) {
      this.legalPersonQuery
        .selectMany(
          (editingStep.legalPersons.map((legalPerson) => legalPerson.id))
        )
        .pipe(untilDestroyed(this))
        .subscribe((legalPeople) => {
          const relatedLegalPeople = legalPeople.map((legalPerson) => ({
            label: legalPerson.name,
            value: {
              id: legalPerson.id,
              clientType: ClientType.LEGAL_PERSON
            }
          }));
          editingTaskRelatedPeople = [
            ...editingTaskRelatedPeople,
            ...relatedLegalPeople,
          ];
        });
    }

    return editingTaskRelatedPeople;
  }

  getLegalPersonsConcernes(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number){
    //met à jour un tableau de legalPerson pour etre injecté dans les tasks
    const clientsConcernes: any[] = this.getTaskRelatedPeople(stepForm, groupIndex, taskIndex).value;
    const tempLegalPersons = clientsConcernes.filter(client => client.value.clientType === ClientType.LEGAL_PERSON);

    let legalPersonConcernes: any[] = [];

    tempLegalPersons.map(person => {
      legalPersonConcernes = [...legalPersonConcernes, {id: person.value.id}];
    });
    return legalPersonConcernes;
  }

  getNaturalPersonsConcernes(stepForm: UntypedFormGroup, groupIndex: number, taskIndex: number){
    //met à jour un tableau de naturalPerson pour etre injecté dans les tasks
    const clientsConcernes: any[] = this.getTaskRelatedPeople(stepForm, groupIndex, taskIndex).value;
    const tempNaturalPersons = clientsConcernes.filter(client => client.value.clientType === ClientType.NATURAL_PERSON);

    let naturalPersonConcernes: any[] = [];

    tempNaturalPersons.map(person => {
      naturalPersonConcernes = [...naturalPersonConcernes, {id: person.value.id}];
    });
    return naturalPersonConcernes;
  }

  isGroupComplete(tasks: any, taskEditingId: number): boolean {
    let isComplete = true;
    tasks.filter((task: { id: any }) => task.id !== taskEditingId).map( (otherTask: any) => {
      if (otherTask.status !== this.taskStatus.DONE) {
        isComplete = false;
      }
    });
    return isComplete;
  }

  getCloseDateOffset(step: Step, groupIndex: number): number {
    let nextGroupStartDate: Date | null = null;
    let nextGroupDelay = 0;
    //si un groupe suivant existe
    //Mantis8086: je ne sais pas comment, mais le groupe suivant peut visiblement ne pas avoir de tache
    // corrigé par && step.stepGroups[groupIndex + 1].tasks.length
    if (step.stepGroups.length > groupIndex + 1 && step.stepGroups[groupIndex + 1].tasks.length) {
      nextGroupStartDate = moment(step.stepGroups[groupIndex + 1].tasks[0].startDate).clone().toDate();
      nextGroupDelay = step.stepGroups[groupIndex + 1].delayBefore;
    } else {
      return 0;
    }
    return  Number(weekDays(moment(nextGroupStartDate).clone().startOf('day'), moment(new Date()).startOf('day'))) + Number(nextGroupDelay);
  }

  calculateStatus(taskStartDate: Date, taskStatus: string = TaskStatus.PLANNED) {
    const delay = weekDays(moment(taskStartDate).clone().startOf('day'), moment(new Date()).startOf('day'));
    if (
      delay >= 0 &&
      taskStatus === TaskStatus.PLANNED
    ) {
      // console.log('CALCULATE STATUS: ', TaskStatus.TODO);
      return TaskStatus.TODO;
    } else {
      // console.log('CALCULATE STATUS: ', taskStatus);
      return taskStatus;
    }
  }

  calculateDeadlineIndicator(
    startDate: Date,
    deadlineDate: Date
  ): TaskDeadlineStatus {
    // Business logic, EMD wants to be notified if less than 25%
    // of the time required for the task (deadline - start date) is exceeded.
    const warningPercentage = 25;

    const today = new Date(new Date().setHours(12,0,0,0));
    const taskStart = new Date(startDate.setHours(12,0,0,0));
    const taskEnd = new Date(deadlineDate.setHours(12,0,0,0));

    // Days between deadline and today.
    // Add one day to avoid a delta of 0 day between a specific day it's next day.
    const daysDeltaBetweenDeadlineAndToday = weekDays(moment(today).clone(), moment(taskEnd).clone());

    // Days between start and deadline.
    // Add one day to avoid a delta of 0 day between a specific day it's next day.
    const dayDeltaBetweenStartAndDeadline = weekDays(moment(taskStart).clone(), moment(taskEnd).clone()) + 1;

    // Progress percentage.
    const percentProgress = (daysDeltaBetweenDeadlineAndToday / dayDeltaBetweenStartAndDeadline) * 100;

    // Case deadline date is passed.
    if (today > taskEnd) {
      return TaskDeadlineStatus.EXCEED;
    }

    // Case less or equal 3 days between start date and deadline.
    if (dayDeltaBetweenStartAndDeadline <= 3) {
      return TaskDeadlineStatus.OK;
    }

    if (percentProgress > warningPercentage) {
      return TaskDeadlineStatus.OK;
    } else if (percentProgress <= warningPercentage && percentProgress >= 0) {
      return TaskDeadlineStatus.WARNING;
    } else {
      return TaskDeadlineStatus.EXCEED;
    }
  }

  // Used to set deadlineDate time at 22:59:00 and convert it to UTC so it will be saved in database on UTC
  // and displayed in front on local timezone (GMT+1 or GMT+2)
  // reason: when the deadlineDate was set from back in the old version, it was displayed in front with a delay of 1 day
  convertDeadlineDateToUTC(deadlineDate: any) {
    return moment(deadlineDate).clone().set({hour: 22, minute: 59, second: 0}).utc().toString();
  }

  // Used to set startDate time at 2:00:00 and convert it to UTC so it will be saved in database on UTC
  // and displayed in front on local timezone (GMT+1 or GMT+2)
  // reason: if i change the startDate, it will be displayed with a delay of 1 day in front
  convertStartDateToUTC(startDate: any) {
    return moment(startDate).clone().set({hour: 2, minute: 0, second: 0}).utc().toString();
  }
}
