import {Component, forwardRef, Input, } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {DropdownOption} from 'common';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RadioInputComponent),
      multi: true
    }
  ]
})
export class RadioInputComponent implements ControlValueAccessor {

  @Input() items: DropdownOption[];
  @Input() required = false;
  selected: string | null;
  disabled = false;

  selectItem(code: any) {
    this.onTouched();
    this.selected = code;
    this.onChange(code);
  }

  writeValue(value: string): void {
    if(value){
      this.selected = value;
    }
  }
  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  validate(_: UntypedFormControl) {
    if(this.required && this.selected !== null){

    } else {
      return null;
    }
    return this.required ? null : { valid: false };
  }

}
