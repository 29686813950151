import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ClientType, DocumentType, DropdownOption, DropdownOptionsService,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
  NaturalPersonService, NavigationService
} from 'common';
import {ID} from '@datorama/akita';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CreditType, ObjetPret} from 'common';
import {Subscription} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-recueil-autre',
  templateUrl: './recueil-autre.component.html',
  styleUrls: ['./recueil-autre.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilAutreComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilAutreComponent),
      multi: true
    }
  ]
})
export class RecueilAutreComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @ViewChild('title') title: ElementRef;
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  client: NaturalPerson|LegalPerson;
  collapsedPanels: {collapsed: boolean}[] = [];
  objetPretOptions: DropdownOption<any>[] = [];
  selectedavoirObjetPret: ObjetPret | null = null;
  creditType = CreditType;
  objetPret = ObjetPret;

  documentType = DocumentType;

  initialControlValue = {
    id: null,
    typeCredit: null,
    objetPret: null,
    souscripteur: null,
    amortissement: null,
    offreCredit: null,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    public naturalPersonQuery: NaturalPersonQuery,
    public legalPersonQuery: LegalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private dropdownOptionsService: DropdownOptionsService,
    private navigation: NavigationService
  ) {}

  get creditControl() {
    return this.form.get('creditNonImmobiliers') as UntypedFormArray;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  getDonationRequiredDocs(value: any){

    let docs: any[] = [];
    if(value.donation.realiseDonation && value.donation.redigeTestament){
      docs = [DocumentType.TESTAMENT,
        DocumentType.ACTE_DONATION,
        DocumentType.AUTRE_DONATION];
    }
    else if(value.donation.realiseDonation && !value.donation.redigeTestament){
      docs = [DocumentType.ACTE_DONATION,
        DocumentType.AUTRE_DONATION];
    }
    else if(!value.donation.realiseDonation && value.donation.redigeTestament){
      return [DocumentType.TESTAMENT];
    } else {
      docs =  [];
    }
    return docs;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      creditNonImmobiliers: this.formBuilder.array([]),
      impot: this.formBuilder.control({
        trancheImpots: this.formBuilder.control({}),
        assujettiIfi: this.formBuilder.control({}),
      }),
      donation: this.formBuilder.control({
        realiseDonation: this.formBuilder.control([]),
        recuDonation: this.formBuilder.control([]),
        redigeTestament: this.formBuilder.control([])
      }),
      prevoyance: this.formBuilder.control({
        contratPrevoyance: this.formBuilder.control([]),
        descriptionContratPrevoyance: this.formBuilder.control([]),
      }),
    });
    if(this.clientOrAdmin === 'ADMIN'){
      this.form.addControl('comConseilleRevenuFiscaux', this.formBuilder.control(null));
      this.form.addControl('comConseilleDonation', this.formBuilder.control(null));
    }
    if(this.clientOrAdmin === 'CLIENT'){
      this.form.addControl('comComplement', this.formBuilder.control(null));
      this.form.addControl('docsImpot', this.formBuilder.control(null));
      this.form.addControl('docsDonation', this.formBuilder.control(null));
      this.form.addControl('docsPrevoyance', this.formBuilder.control(null));
    }
    this.objetPretOptions = this.dropdownOptionsService.objetPret;
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        //on remet les documents dans leur objet initial
        const { docsImpot, docsDonation, docsPrevoyance, ...rest } = value;
        const otherPrevoyanceDocs = docsPrevoyance?.otherDocs ? [...docsPrevoyance?.otherDocs] : [];
        const otherDonationDocs = docsDonation?.otherDocs ? [...docsDonation?.otherDocs] : [];
        this.onChange({...rest,
          ...value.docsImpot,
          acteDonation : docsDonation?.acteDonation,
          testament: docsDonation?.testament,
          certifPrevoyances: docsPrevoyance?.certifPrevoyances,
          conditionPrevoyance: docsPrevoyance?.conditionPrevoyance,
          otherDocs: [...otherPrevoyanceDocs, ...otherDonationDocs]});
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(value){
      this.collapsedPanels = [];
      while (this.creditControl.length !== 0) {
        this.creditControl.removeAt(0);
      }
      value.creditNonImmobiliers.map(()=> {
        this.creditControl.push(this.formBuilder.control({}));
        this.collapsedPanels = [...this.collapsedPanels, {collapsed: true}];
      });
      if(this.clientOrAdmin === 'ADMIN') {
        this.value = {
          creditNonImmobiliers: value.creditNonImmobiliers,
          impot: {
            trancheImpots: value.impot.trancheImpots,
            assujettiIfi: value.impot.assujettiIfi,
          },
          donation: {
            realiseDonation: value.donation.realiseDonation,
            recuDonation: value.donation.recuDonation,
            redigeTestament: value.donation.redigeTestament
          },
          prevoyance: {
            contratPrevoyance: value.prevoyance.contratPrevoyance,
            descriptionContratPrevoyance: value.prevoyance.descriptionContratPrevoyance,
          },
          comConseilleRevenuFiscaux: value.comConseilleRevenuFiscaux,
          comConseilleDonation: value.comConseilleDonation,
        };
      }
      if(this.clientOrAdmin === 'CLIENT') {
        console.log(value);
        this.value = {
          creditNonImmobiliers: value.creditNonImmobiliers,
          impot: {
            trancheImpots: value.impot.trancheImpots,
            assujettiIfi: value.impot.assujettiIfi,
          },
          donation: {
            realiseDonation: value.donation.realiseDonation,
            recuDonation: value.donation.recuDonation,
            redigeTestament: value.donation.redigeTestament
          },
          prevoyance: {
            contratPrevoyance: value.prevoyance.contratPrevoyance,
            descriptionContratPrevoyance: value.prevoyance.descriptionContratPrevoyance,
          },
          comComplement: value.comComplement,
          docsImpot:{
            avisImpot: value.impot.avisImpot,
            declarationImpot: value.impot.declarationImpot,
          },
          docsDonation:{
            testament: value.donation.testament,
            acteDonation: value.donation.acteDonation,
          },
          docsPrevoyance:{
            certifPrevoyances: value.prevoyance.certifPrevoyances,
            conditionPrevoyances: value.prevoyance.conditionPrevoyances,
          }
        };
        console.log(this.form);
      }
      this.form.markAsPristine();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  add(){
    this.creditControl.push(this.formBuilder.control({
      ...this.initialControlValue,
      objetPret: this.selectedavoirObjetPret
    }));
    this.collapsedPanels.map(panel=>panel.collapsed = true);
    this.collapsedPanels = [...this.collapsedPanels, {collapsed: false}];
    this.navigation.scrollToTop(this.title.nativeElement.offsetTop);
  }

  remove(index: number){
    this.creditControl.removeAt(index);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientType'] && changes['clientId']){
      if (changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.naturalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
          if(naturalPerson){
            this.client = naturalPerson;
          }
        });
      }
      if (changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.legalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(legalPerson => {
          if(legalPerson){
            this.client = legalPerson;
          }
        });
      }
    }

  }

}
