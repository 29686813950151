<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only [clientOrAdmin]="'CLIENT'"></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <ng-container *ngIf="!loading && !error">
    <div class="header flex justify-content-center">
      <div class="banner">
        <img src="assets/recueil/E2-conjoint.jpg" alt="recueil-header/">
      </div>
    </div>
    <div class="w-11 mx-auto pt-3 rec">
      <h1 class="rec text-center">
        Bienvenue {{this.conjoint?.civility === 'MME' ? 'Mme' : (this.conjoint?.civility === 'MR' ? 'Mr' : (this.conjoint?.civility === 'MX' ? 'Mx' : '')) }} {{this.conjoint?.lastName}} {{this.conjoint?.firstName}}, </h1>
      <p class="rec pb-3 text-center">Afin de pouvoir conseiller au mieux votre conjoint, nous vous remercions de bien
        vouloir
        compléter vos informations. <br>Celles-ci nous permettront de proposer un conseil adapté à la situation de votre
        moitié.
      </p>

      <div id="scrollPanelDiv">
        <p-scrollPanel [style]="{width: '100%', height: '170px'}" styleClass="custom">
          <br>
          <strong>Mise en garde et informations préalables</strong> <br><br>
          Préalablement à la commercialisation de tout contrat d’assurance, d’un instrument financier, d’un service
          d’investissement ou sur la réalisation d’opérations sur « biens divers » définies à l’article L.551- 1 du Code
          monétaire et financier, votre conseiller doit recueillir les informations nécessaires afin d’agir au mieux de
          vos intérêts et de délivrer un conseil adapté à votre situation, en justifiant notamment les différentes
          recommandations, les avantages et les risques qu’elles comportent et en expliquant en quoi elles sont adaptées.

          A cet effet, nous vous soumettons ce recueil d’informations par la signature duquel vous vous engagez à une
          communication exhaustive et sincère des informations vous concernant. Dans le cas contraire, les recommandations
          prodiguées par votre conseiller pourraient ne pas être en adéquation avec votre situation. Toute modification de
          votre situation familiale, patrimoniale ou professionnelle peut avoir une incidence sur le conseil donné. Elle
          doit donc être signalée à votre conseiller afin d’actualiser le présent document. <br><br>

          <strong>Règlement Général sur la Protection des Données (RGPD)</strong><br><br>

          Les informations recueillies dans le présent formulaire font l’objet d’un traitement destiné à établir un
          diagnostic de votre situation patrimoniale et de celle de votre foyer, et à vous conseiller relativement à la
          gestion de votre patrimoine dans le cadre des activités professionnelles de conseil en gestion de patrimoine.
          Ces informations sont nécessaires pour permettre au cabinet de réaliser ses missions. Le défaut de réponse peut
          avoir des conséquences sur la réalisation conforme des missions du cabinet. Les destinataires des données sont
          les conseils en gestion de patrimoine eux-mêmes, leur personnel conformément à leurs attributions internes, les
          destinataires habituels du conseil en gestion de patrimoine habilités à recueillir certaines informations en
          fonction de leurs attributions respectives et les partenaires sélectionnés pour réaliser l’investissement
          retenu. Les données collectées vous concernant vous et vos proches seront conservées pendant toute la durée de
          nos relations contractuelles et ensuite en archive pendant un délai de cinq (5) ans, à défaut des délais plus
          courts ou plus longs spécialement prévus notamment en cas de litige.

          Conformément au Règlement Général sur la Protection des Données personnelles (RGPD), vous disposez sur ces données
          d’un droit d'accès, de rectification, et de limitation du traitement, ainsi que d’un droit d’opposition et de
          portabilité conformément à la loi. Si vous souhaitez exercer ces droits, vous pouvez nous contacter par
          courriel. Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas
          respectés, vous pouvez adresser une réclamation en ligne à la CNIL ou par courrier postal.
          <br><br><br>

        </p-scrollPanel>
      </div>

      <ng-container [formGroup]="form">

        <!-- infos civiles -->
        <h2 class="rec pt-2">{{'CLIENT_STEP_VOUS_SITUATION_CIVILE' | translate}}</h2>
        <p-divider></p-divider>
        <div class="pt-4">
          <app-info-civile-form [advanced]="true" [conjointExistence]="true" [fullNameDisabled]="true"
                                formControlName="infoCivile"></app-info-civile-form>
        </div>

        <!-- Contact -->
        <h2 class="rec">{{'CLIENT_STEP_VOUS_CONTACT' | translate}}</h2>
        <p-divider></p-divider>
        <app-contact-form [clientOrAdmin]="'CLIENT'" formControlName="contact"></app-contact-form>

        <!-- Situation pro -->
        <h2 class="rec">{{'CLIENT_STEP_VOUS_JOB' | translate}}</h2>
        <p-divider></p-divider>
        <app-situation-pro-form formControlName="situationPro"></app-situation-pro-form>

        <!-- Celebre -->
        <h2 class="rec">{{'CLIENT_STEP_VOUS_FAMOUS' | translate}}</h2>
        <p-divider></p-divider>
        <app-celebre-form formControlName="celebre"></app-celebre-form>

        <!-- International -->
        <h2 class="rec">{{'CLIENT_STEP_VOUS_US_PERSON' | translate}}</h2>
        <p-divider></p-divider>
        <app-etranger-form [clientOrAdmin]="'CLIENT'" formControlName="etranger"></app-etranger-form>

        <!-- Commentaire -->
        <h2 class="rec">{{'CLIENT_STEP_VOUS_COMMENT' | translate}}</h2>
        <p-divider></p-divider>
        <app-comment-form formControlName="comment"></app-comment-form>

      </ng-container>


      <div class="flex w-full mt-3 mb-5">
        <p-button label="Enregistrer" class="ml-auto" [loading]="submitLoading" (onClick)="submitForm()" styleClass="p-button p-button-raised"
                  icon="pi pi-check"
                  iconPos="left"></p-button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!loading && error">
    <div class="flex justify-content-center align-items-center error rec">
      {{errorMsg}}
    </div>
  </ng-container>
</ng-template>

<p-toast></p-toast>

