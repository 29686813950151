import { Inject, Injectable } from '@angular/core';
import { StepState, StepStore } from './step.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class StepService extends NgEntityService<StepState>{

  constructor(
    @Inject('config') public override config: any,
    protected override store: StepStore
  ) {
    super(store);
  }

}
