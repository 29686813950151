import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DocsToAddState {
  docs: any[];
}

export function createInitialState(): DocsToAddState {
  return {
    docs: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'docsToAdd', resettable: true })
export class DocsToAddStore extends Store<DocsToAddState> {

  constructor() {
    super(createInitialState());
  }

}
