<ng-container *ngIf="clientOrAdmin === 'ADMIN'">
  <div class="w-8 m-auto text-center">
    <img src="assets/desktop-only.png" class="desktop-only-img mt-5">
    <p class="mt-5 text-primary font-semibold">Cette page n'est pas disponible depuis la version mobile de TIM.</p>
    <p class="mt-3">Connectez-vous depuis votre ordinateur pour pouvoir la consulter.</p>
    <p-button (click)="goToDashboard()" label="Retourner au Dashboard" styleClass="mt-3 p-button p-button-raised p-button-primary" icon="pi pi-chart-bar" iconPos="left"></p-button>
  </div>
</ng-container>

<ng-container *ngIf="clientOrAdmin === 'CLIENT'">
  <div class="h-full w-full" style="background-image: url('assets/recueil/visuel-accueil-mobile.jpg'); background-size: 100% 100%">
    <div class="h-full text-center p-3">
      <div class="h-full flex flex-column justify-content-center" >
        <img src="assets/recueil/MASTER-LOGO-EMD.png" class="loader-logo-emd-mobile mx-auto" alt="logoEMD/" >
        <h3 class="rec">Le Recueil Client EMD Patrimoine serait beaucoup trop à l'étroit en version mobile !</h3>
        <h2 class="rec">Connectez-vous depuis votre ordinateur pour pouvoir le remplir.</h2>
      </div>
    </div>
  </div>

</ng-container>
