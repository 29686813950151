import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {DocumentCodeType, DocumentType, DropdownOption, DropdownOptionsService, Immobilier} from 'common';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ID} from '@datorama/akita';

@Component({
  selector: 'app-credit-form',
  templateUrl: './credit-form.component.html',
  styleUrls: ['./credit-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CreditFormComponent),
      multi: true
    }
  ]
})
export class CreditFormComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  creditTypeOptions: DropdownOption<any>[] = [];
  objetPretOptions: DropdownOption<any>[] = [];
  souscripteurOptions: DropdownOption<any>[] = [];
  documentType = DocumentType;


  initialValues = {
    id: null,
    typeCredit: null,
    objetPret: null,
    souscripteur: null,
    docs: null,
  };
  constructor(private formBuilder: UntypedFormBuilder,private dropdownOptionsService: DropdownOptionsService,) {}
  get value(): Immobilier {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        //on remet les documents dans leur objet initial
        const { docs, ...rest } = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
    this.creditTypeOptions = this.dropdownOptionsService.creditType;
    this.objetPretOptions = this.dropdownOptionsService.objetPret;
    this.souscripteurOptions = this.dropdownOptionsService.souscripteurCredit;

    if(this.clientOrAdmin === 'CLIENT'){
      this.form.addControl('docs', this.formBuilder.control(null));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      const { amortissement,
        offreCredit,
        otherDocs,
        ...rest } = value;
      this.value = {...rest, docs: {
          amortissement: value.amortissement,
          offreCredit: value.offreCredit,
          otherDocs: value.otherDocs,
        }};
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }


}
