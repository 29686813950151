import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-summary',
  templateUrl: './ui-summary.component.html',
  styleUrls: ['./ui-summary.component.scss']
})
export class UiSummaryComponent {

  constructor() { }

}
