import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { CreditNonImmobilierStore, CreditNonImmobilierState } from './credit-non-immobilier.store';

@Injectable({ providedIn: 'root' })
export class CreditNonImmobilierService extends NgEntityService<CreditNonImmobilierState> {

  constructor(protected override store: CreditNonImmobilierStore) {
    super(store);
  }

}
