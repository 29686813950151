import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UiScreenState {
  screen: undefined | string;
}

export function createInitialState(): UiScreenState {
  return {
    screen: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'uiScreen' })
export class UiScreenStore extends Store<UiScreenState> {

  constructor() {
    super(createInitialState());
  }

}
