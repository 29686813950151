import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Step } from './step.model';

export interface StepState extends EntityState<Step> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'steps'
})
export class StepStore extends EntityStore<StepState> {

  constructor() {
    super();
  }

}
