<div [formGroup]="form">
  <h4 class="rec">Téléphones</h4>
  <div class="grid p-fluid"
       *ngIf="conjoint && !conjointHasPhones">
    <div class="field col-3 flex flex-row mr-4 w-auto">
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.areSamePhonesDisplayed}">Non</div>
        <p-inputSwitch id="samePhone"
                       [trueValue]="true"
                       [falseValue]="false"
                       [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                       [formControl]="areSamePhonesDisplayedControl"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.areSamePhonesDisplayed}">Oui</div>
      </div>
      <label class="pl-4 pt-2" for="samePhone">Même numéro que moi</label>
    </div>
    <div class="field col-3 pt-3 w-auto" style="display: flex" *ngIf="value.areSamePhonesDisplayed">

      <!--        <p-checkbox inputId="checkOption{{phone.id}}" name="option" value="{{phone}}"-->
      <!--                    (onChange)="handlePhoneCheckboxChange($event, phone)"></p-checkbox>-->
      <!--        <label for="checkOption{{phone.id}}" class="pl-3">{{phone.label |titlecase}}-->
      <!--          : {{phone.dialCode}} {{phone.number}}</label>-->
<!--      <div *ngFor="let item of phonesControl?.controls; index as i">-->
        <div class="p-field-checkbox pr-4" *ngFor="let phone of client?.phones">
          <p-checkbox name="groupname" [value]="phone" [formControl]="samePhonesControl"
                      [label]="phone.label ? (phone.label |titlecase) + ':' + phone.dialCode + ' ' + phone.number : phone.dialCode + ' ' + phone.number "></p-checkbox>
        </div>
<!--      </div>-->
    </div>
  </div>


  <div class="flex align-items-end">
    <p-button label="Ajouter un autre numéro" icon="pi pi-plus" styleClass="p-button-outlined mr-2"
              (onClick)="addPhone()"></p-button>
    <lib-field-error *ngIf="!valid.phoneContact" [fieldError]="{phoneContact:true}"></lib-field-error>
  </div>

  <ng-container formArrayName="phones">
    <div class="flex align-items-center" *ngFor="let item of phonesControl?.controls; index as i">
      <ng-container *ngIf="!item.value.same">
        <p-card class="m-3 w-full">
          <app-phone-form [formControlName]="i" [toogleIsContact]="togglePhoneIsContact()"
                          [advanced]=true></app-phone-form>
        </p-card>
        <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text ml-3"
                (click)="removePhone(i)"></button>
      </ng-container>
    </div>
  </ng-container>


  <h4 class="rec">Emails</h4>
  <div class="flex align-items-end">
    <p-button label="Ajouter un autre email" icon="pi pi-plus" styleClass="p-button-outlined mr-2"
              (onClick)="addEmail()"></p-button>
    <div class="flex flex-column">
      <lib-field-error *ngIf="!valid.emailContact" [fieldError]="{emailContact:true}"></lib-field-error>
      <div *ngIf="contactEmailWaringText" class="invalid-feedback mt-2">{{contactEmailWaringText}}</div>
    </div>


  </div>
  <ng-container formArrayName="emails">
    <div class="flex align-items-center" *ngFor="let item of emailsControl?.controls; index as i">
      <p-card class="m-3 w-full">
        <app-email-form [formControlName]="i" [toogleIsContact]="toggleEmailIsContact()"
                        [advanced]=true [recueilNaturalPerson]="recueilNaturalPerson"></app-email-form>
      </p-card>
      <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text ml-3"
              (click)="removeEmail(i)" [disabled]="item.value.isContact && recueilNaturalPerson"></button>
    </div>
  </ng-container>


  <h4 class="rec">Adresses</h4>
  <div class="grid p-fluid" *ngIf="conjoint && !conjointHasAddresses">
    <div class="field col-3 flex flex-row mr-4 w-auto">
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.areSameAddressesDisplayed}">Non</div>
        <p-inputSwitch id="sameAddress"
                       [trueValue]="true"
                       [falseValue]="false"
                       [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                       [formControl]="areSameAddressesDisplayedControl"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.areSameAddressesDisplayed}">Oui</div>
      </div>
      <label class="pl-4 pt-2" for="sameAddress">Même adresse que moi</label>
    </div>
    <div class="field col-3 pt-3 w-auto" style="display: flex" *ngIf="value.areSameAddressesDisplayed">
      <div class="p-field-checkbox pr-4" *ngFor="let address of client?.addresses">
        <p-checkbox name="groupname" [value]="address" [formControl]="sameAddressesControl"
                    [label]="address.street ? (address.street |titlecase) + ', ' + address.zipCode + ', ' + address.city + ', ' + address.country  : address.zipCode + ', ' + address.city + ', ' + address.country "></p-checkbox>
      </div>
    </div>

  </div>

  <div class="flex align-items-end">
    <p-button label="Ajouter une autre adresse" icon="pi pi-plus" styleClass="p-button-outlined mr-2"
              (onClick)="addAdrress()"></p-button>
    <ng-container *ngIf="!valid.addressFiscale && !valid.addressContact; else elseCase">
      <lib-field-error [fieldError]="{addressFiscale:true, addressContact:true}"></lib-field-error>
    </ng-container>
    <ng-template #elseCase>
      <lib-field-error *ngIf="!valid.addressContact"
                       [fieldError]="{addressContact:true, addressFiscale:false}"></lib-field-error>
      <lib-field-error *ngIf="!valid.addressFiscale"
                       [fieldError]="{addressFiscale:true, addressContact:false}"></lib-field-error>
    </ng-template>

  </div>

  <ng-container formArrayName="addresses">
    <div class="flex align-items-center" *ngFor="let item of addressesControl?.controls; index as i">
      <ng-container *ngIf="!item.value.same">
        <p-card class="m-3 w-full">
          <app-address-form [formControlName]="i" [toogleIsContact]="toggleAddressIsContact()"
                            [toogleIsFiscale]="toggleAddressIsFiscale()" [advanced]=true></app-address-form>
        </p-card>
        <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text ml-3"
                (click)="removeAddress(i)"></button>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="value.addresses?.length > 0">
    <div class="grid p-fluid mt-3">
      <div class="field col-3 flex flex-column">
        <label for="rentPrincipalResidence" *ngIf="clientOrAdmin === 'ADMIN'">Locataire de sa résidence principale
          ?</label>
        <label for="rentPrincipalResidence" *ngIf="clientOrAdmin === 'CLIENT'">Êtes-vous locataire de votre résidence
          principale ?</label>
        <div class="flex flex-grow-1 align-items-center">
          <div [ngClass]="{'text-400' : value.rentPrincipalResidence}">Non</div>
          <p-inputSwitch id="rentPrincipalResidence"
                         formControlName="rentPrincipalResidence"
                         [trueValue]="true"
                         [falseValue]="false"
                         [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.rentPrincipalResidence}">Oui</div>
        </div>
      </div>
      <div class="field col-3" *ngIf="value.rentPrincipalResidence">
        <label for="loyerAnnuel">Montant du loyer annuel</label>
        <p-inputNumber formControlName="loyerAnnuel" id="loyerAnnuel" mode="decimal"></p-inputNumber>
      </div>
    </div>
  </ng-container>


</div>
