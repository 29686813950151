import {Inject, Injectable} from '@angular/core';
import {NaturalPerson} from '../store/client/naturalPerson/state/natural-person.model';
import {File} from '../store/file/state/file.model';
import {ClientType} from '../enums/client/ClientType';
import {LegalPerson} from '../store/client/legalPerson/state/legal-person.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentHelperService {

  constructor(@Inject('config') private config: any) { }

  // Génération du lien de download d'un file, route de type /file/{clientId}/{clientType}/download/{fileHash}/{filename}
  getFileDownloadLink(client: NaturalPerson | LegalPerson, clientType: string, file: File)
  {
    return this.config.apiUrl + '/file/download/' + client?.id + '/' + clientType + '/' +
    file.hash + '/' + file.name;
  }
}
