import {ID} from '@datorama/akita';
import {Document} from '../../../document/state/document.model';

export interface AvoirFinancier {
  id: ID;
  typeAvoir?: string;
  assureurBanque?: string;
  dateSouscription?: Date;
  souscripteur?: string;
  valeur?: number;
  versementProgramme?: boolean;
  montantVersementProgramme?: number;
  clauseBeneficiaire?: string;
  releveCompte?: Document;
  releveSituation?: Document;
  titrePropriete?: Document;
  otherDocs?: Document[];
}

export function createAvoirFinancier(params: Partial<AvoirFinancier>) {
  return {

  } as AvoirFinancier;
}
