<div class="question-filtre-content mx-auto">
  <h1 class="rec text-center mt-8 mb-8">{{'CLIENT_REQUEST_FIRST_TITLE' | translate}}</h1>
  <div [formGroup]="form">
    <h2 class="rec text-center mb-3">Êtes-vous :</h2>
    <app-radio-input [items]="situationMatrimonialeOptions" formControlName="situationMatrimoniale"></app-radio-input>
    <h2 class="rec text-center mt-8 mb-3">Avez-vous :</h2>
    <div class="flex flex-column">
      <div class="flex align-items-center justify-content-between">
        <label for="precedentMariage">Eu un précédent mariage ?</label>
        <div class="flex align-items-center justify-content-right">
          <div [ngClass]="{'text-400' : value.precedentMariage}">Non</div>
          <p-inputSwitch  id="precedentMariage"
                          formControlName="precedentMariage"
                          [trueValue]="true"
                          [falseValue]="false"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.precedentMariage}">Oui</div>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="flex align-items-center justify-content-between">
        <div>
          <label for="personneACharge">Des enfants ou des personnes à charge ?</label>
          <app-help-tooltip class="ml-3" [text]="'Il s’agit de vos enfants (à charge ou non) quelle que soit leur situation, ainsi que tout autre personne que vous pourriez avoir à charge fiscalement (parent, tiers..)'"></app-help-tooltip>
        </div>

        <div class="flex align-items-center justify-content-right">
          <div [ngClass]="{'text-400' : value.personneACharge}">Non</div>
          <p-inputSwitch  id="personneACharge"
                          formControlName="personneACharge"
                          [trueValue]="true"
                          [falseValue]="false"
                          (onChange)="!value.personneACharge ? toggleQuestionFiltre.callParentMethod('RELATION'): null"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.personneACharge}">Oui</div>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="flex align-items-center justify-content-between">
        <label for="bienImmobilierOwner">Des biens immobiliers ?</label>
        <div class="flex align-items-center justify-content-right">
          <div [ngClass]="{'text-400' : value.bienImmobilierOwner}">Non</div>
          <p-inputSwitch  id="bienImmobilierOwner"
                          formControlName="bienImmobilierOwner"
                          [trueValue]="true"
                          [falseValue]="false"
                          (onChange)="!value.bienImmobilierOwner ? toggleQuestionFiltre.callParentMethod('IMMO'): null"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.bienImmobilierOwner}">Oui</div>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="flex align-items-center justify-content-between">
        <label for="avoirFinancierOwner">Des placements, des livrets ? Des crédits ?</label>
        <div class="flex align-items-center justify-content-right">
          <div [ngClass]="{'text-400' : value.avoirFinancierOwner}">Non</div>
          <p-inputSwitch  id="avoirFinancierOwner"
                          formControlName="avoirFinancierOwner"
                          [trueValue]="true"
                          [falseValue]="false"
                          (onChange)="!value.avoirFinancierOwner ? toggleQuestionFiltre.callParentMethod('AVOIR_FI'): null"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.avoirFinancierOwner}">Oui</div>
        </div>
      </div>

      <p-divider></p-divider>

      <div class="flex align-items-center justify-content-between">
        <label for="bienDiversOwner">Des biens atypiques ? (vin, voitures, art, cryptomonnaies…) ?</label>
        <div class="flex align-items-center justify-content-right">
          <div [ngClass]="{'text-400' : value.bienDiversOwner}">Non</div>
          <p-inputSwitch  id="bienDiversOwner"
                          formControlName="bienDiversOwner"
                          [trueValue]="true"
                          [falseValue]="false"
                          (onChange)="!value.bienDiversOwner ? toggleQuestionFiltre.callParentMethod('BIEN_DIVERS'): null"
                          [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
          ></p-inputSwitch>
          <div [ngClass]="{'text-400' : !value.bienDiversOwner}">Oui</div>
        </div>
      </div>

    </div>

    <h2 class="rec text-center mt-8 mb-3">Vous souhaitez :</h2>
    <div class="text-center mb-5 neutral0">
      Sélectionnez trois objectifs dans la liste ci-contre et faites les glisser dans l’ordre de vos priorités.
    </div>

    <app-recueil-objectif formControlName="objectif"></app-recueil-objectif>
  </div>
</div>

