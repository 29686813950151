import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {MessageService} from 'primeng/api';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  DropdownOptionsService,
  NavigationService,
  PoleInterne,
  PoleClient,
  SessionQuery,
  SessionState,
  StepService,
  TaskService,
  TaskStatus,
  TranslatorService,
  UserQuery,
  UserRole,
  NaturalPersonQuery,
  NaturalPersonService,
  LegalPersonQuery,
  LegalPersonService,
  DropdownOption,
  Task,
  TaskQuery,
  StepQuery,
  TaskHelperService,
  ClientSearchService,
  ClientType,
  Step,
  NotificationService,
  NotificationQuery,
  Comment,
  NotificationStatus,
  UiScreenQuery,
  StepStore
} from 'common';
import * as moment from 'moment';
import {addWeekDays, weekDays} from 'moment-business';
import {take} from 'rxjs';
import {map} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'app-page-task-customer',
  templateUrl: './page-task-customer.component.html',
  styleUrls: ['./page-task-customer.component.scss'],
})
export class PageTaskCustomerComponent implements OnInit {

  user: SessionState;
  leaderOrPoleManager: boolean;
  isFormEditing = false;
  isFormReady = false;
  taskEditing: Task;
  stepEditing: Step;
  taskRelatedCustomer: { id: number; type: ClientType.NATURAL_PERSON | ClientType.LEGAL_PERSON };
  taskRemainingTime: 'OK' | 'WARNING' | 'EXCEED';
  rawTaskStatus = TaskStatus;
  // l'index de groupe dans lequel est la tache à editer
  groupIndex = 0;
  // l'index de tache à editer
  taskIndex = 0;
  stepForm = this.formBuilder.group({
    id: null,
    name: '',
    product: null,
    amount: null,
    isFollowStep: false,
    legalPersons: null,
    naturalPersons: null,
    stepGroups: this.formBuilder.array([]),
  });
  polesOptions: DropdownOption[] = [];
  collaboratorOptions: { label: string; value: any }[] = [];
  contractorOptions: DropdownOption<{id}>[] = [];
  statusOptions: any[] = [];
  collaborateurCommentOptions: { label: string; value: any }[] = [];
  statusBeforeEditing!: string;
  deltaDateBeforeEditing!: number;
  displayCloseDate = false;
  isEditable = true;
  today = new Date();
  realWorkloadBeforeEdition!: number | undefined;
  currentDate = new Date();
  // Form default values and options.
  startDateDefaultValue = new Date();
  deadLineDefaultValue = new Date(
    new Date().setDate(this.startDateDefaultValue.getDate() + 1)
  );
  taskHasBlockedComment: boolean;
  blockingCommentWarningState = false;
  comesFromTimeline = false;
  comesFromMondayReview = false;

  constructor(
    private sessionQuery: SessionQuery,
    private formBuilder: UntypedFormBuilder,
    public translatorService: TranslatorService,
    private userQuery: UserQuery,
    private dropdownService: DropdownOptionsService,
    private navigation: NavigationService,
    private stepService: StepService,
    private taskService: TaskService,
    private router: Router,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private legalPersonService: LegalPersonService,
    private taskQuery: TaskQuery,
    private stepQuery: StepQuery,
    private stepStore: StepStore,
    private location: Location,
    private taskHelperService: TaskHelperService,
    public clientSearchService: ClientSearchService,
    private notificationService: NotificationService,
    private notificationQuery: NotificationQuery,
    public uiScreenQuery: UiScreenQuery,
    private cdr: ChangeDetectorRef
  ) {
  }

  get groups() {
    return this.stepForm?.get('stepGroups') as UntypedFormArray;
  }

  getTasks(groupIndex: number) {
    return this.groups.controls[groupIndex].get('tasks') as UntypedFormArray;
  }

  getTaskId(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('id');
  }

  getTaskName(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('name');
  }

  getTaskStartDate(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('startDate');
  }

  getTaskDeadlineDate(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('deadlineDate');
  }

  getTaskClosingDate(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('closingDate');
  }

  getTaskImportance(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('importance');
  }

  getTaskPlannedWorkload(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('plannedWorkload');
  }

  getTaskRealWorkload(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('realWorkload');
  }

  getTaskPoles(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('poles');
  }

  getTaskCollaborator(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('collaborator');
  }

  getTaskContractor(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('contractor');
  }

  getTaskComments(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('comments') as UntypedFormArray;
  }

  getTaskIsInternal(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('isInternal');
  }

  getTaskIsPriority(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('isPriority');
  }

  getTaskRelatedPeople(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('relatedPeople') as UntypedFormArray;
  }

  getTaskStatus(groupIndex: number, taskIndex: number) {
    return this.getTasks(groupIndex).controls[taskIndex].get('status');
  }

  getTasksFByGroupIndex(index: number) {
    return this.groups.controls[index].get('tasks') as UntypedFormArray;
  }

  // @ts-ignore
  getTaskLatestCommentAssignedUser(groupIndex: number, taskIndex: number) {
    return this.getTaskComments(groupIndex, taskIndex).controls.slice(-1)[0]?.get('assignedUsers') as UntypedFormArray;
  }

  getTaskLatestCommentContent(groupIndex: number, taskIndex: number) {
    return this.getTaskComments(groupIndex, taskIndex).controls.slice(-1)[0]?.get('content') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.getCurrentUserInformation();
    this.setTaskPolesOptions();
    this.setCollaboratorsOptions();
    this.setTaskStatusOptions();
    // Check if the taskForm is for creation or editing and set appropriate values.
    this.setInitializedForm();
  }

  getCurrentUserInformation() {
    this.sessionQuery.select().pipe(untilDestroyed(this)).subscribe((sessionUser) => {
      if (sessionUser && sessionUser.id) {
        this.user = sessionUser;
        this.userQuery.selectEntity(sessionUser.id).pipe(untilDestroyed(this)).subscribe((user) => {
          if (user) {
            this.leaderOrPoleManager = user.roles.includes(UserRole.ROLE_DIRIGEANT) ||
              user.roles.includes(UserRole.ROLE_RESPONSABLE_POLE);
          }
        });
      }
    });
  }

  // Set task taskForm poles field options.
  setTaskPolesOptions() {
    let allPoles: [] | DropdownOption[] = [];

    const polesCustomer = Object.values(PoleClient);
    polesCustomer.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          allPoles = [
            ...allPoles,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
    const polesInterne = Object.values(PoleInterne);
    polesInterne.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          allPoles = [
            ...allPoles,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
    this.polesOptions = allPoles.sort((resultA: DropdownOption, resultB: DropdownOption) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  // Set task taskForm collaborators field options.
  setCollaboratorsOptions() {
    this.userQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        const collaborators = users.filter((user) =>
          user.roles.includes(UserRole.ROLE_COLLABORATEUR)
        );
        const fullCollaboratorOptions = collaborators.map((collaborator) => this.dropdownService.mapUserIntoDropdownOption(collaborator))
          .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));

        this.contractorOptions = fullCollaboratorOptions;
        const collaboratorOptions = fullCollaboratorOptions;
        // @ts-ignore
        collaboratorOptions.unshift({label: 'Aucun', value: null});

        this.collaboratorOptions = collaboratorOptions;
      });
  }

  // Set task taskForm status field options.
  setTaskStatusOptions() {
    let statusOptions: [] | DropdownOption[] = [];
    Object.keys(this.rawTaskStatus).map((status) => {
      this.translatorService
        .getTranslation(status)
        .pipe(untilDestroyed(this))
        .subscribe((translatedStatus: string) => {
          statusOptions = [...statusOptions,
            {label: translatedStatus, value: status, disabled: status === this.rawTaskStatus.PLANNED}
          ];
        });
    });
    this.statusOptions = statusOptions;
  }

  // Check if the taskForm is for creation or editing and set appropriate values.
  setInitializedForm() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['from'] === 'mondayReview') {
        this.comesFromMondayReview = true;
      }
      if (params['from'] === 'timeline') {
        this.comesFromTimeline = true;
      }
      if (params['stepId']) {
        this.stepService
          .get(params['stepId'])
          .pipe(untilDestroyed(this))
          .subscribe();
        this.stepQuery
          .selectEntity(params['stepId'])
          .pipe(untilDestroyed(this))
          .subscribe((editingStep) => {
            if (editingStep) {
              this.isFormReady = false;
              // on detache la detection des changement le temps de maj le form, cela evite des bug des references
              this.cdr.detach();
              editingStep.stepGroups.map((group, groupIndex) => {
                group.tasks.map((task, taskIndex) => {
                  if (task.id?.toString() === params['taskId']) {
                    this.groupIndex = groupIndex;
                    this.taskIndex = taskIndex;
                  }
                });
              });
              this.isFormEditing = true;
              this.taskEditing = editingStep.stepGroups[this.groupIndex].tasks[this.taskIndex];
              this.statusBeforeEditing = this.taskEditing?.status;
              this.deltaDateBeforeEditing = weekDays(moment(this.taskEditing?.startDate, 'YYYYMMDD').clone(), moment(this.taskEditing?.deadlineDate, 'YYYYMMDD').clone());
              this.realWorkloadBeforeEdition = this.taskEditing?.realWorkload;
              this.stepEditing = editingStep;
              this.taskRemainingTime = this.taskHelperService.calculateDeadlineIndicator(new Date(this.taskEditing?.startDate), new Date(this.taskEditing?.deadlineDate));

              if (this.taskEditing?.status === this.rawTaskStatus.DONE) {
                this.isEditable = false;
                this.cdr.reattach();
                this.cdr.markForCheck();
              } else {
                this.stepForm = this.taskHelperService.populateTaskEditingForm(this.stepEditing, this.groupIndex, this.taskIndex);
                this.cdr.reattach();
                this.cdr.markForCheck();
                this.isFormReady = true;
              }

              if (this.taskEditing?.comments) {
                this.taskHasBlockedComment = this.taskEditing?.comments.some((comment: Comment) => comment.blocked && !comment.resolved);
              }
            }
          });
      }
      // Task creating.
      else {
        this.stepForm.get('name')?.setValue('Suivi');
        this.stepForm.get('isFollowStep')?.setValue(true);
        this.setDefaultGroup();
        this.setDefaultStartAndEndDate();
        this.setDefaultTaskName();
        this.setDefaultContractor(); // Set current logged user as default contractor.
        this.setDefaultImportance();
        this.setDefaultPlannedWorkLoad();
        this.setDefaultTaskRelatedPerson(); // Set related person when access from natural or legal person detail page.
        this.isFormReady = true;
      }
    });

    // Set taskRelatedCustomer info for appropriate redirection when user edit a task from related customer page.
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      if (queryParams['type'] && queryParams['id']) {
        const relatedCustomerType = queryParams['type'] === 'naturalPerson' ? ClientType.NATURAL_PERSON : ClientType.LEGAL_PERSON;
        this.taskRelatedCustomer = {id: queryParams['id'], type: relatedCustomerType};
      }
    });
  }

  setDefaultGroup() {
    this.groups.push(this.formBuilder.group({
      id: null,
      delayBefore: 0,
      position: 0,
      tasks: this.formBuilder.array([this.formBuilder.group({
        id: null,
        name: [null, Validators.required],
        startDate: [new Date(), Validators.required],
        deadlineDate: [null, Validators.required],
        closingDate: null,
        importance: [null, Validators.required],
        plannedWorkload: [null],
        realWorkload: [null],
        pole: [this.setDefaultPole(), Validators.required],
        collaborator: [null],
        contractor: [null, Validators.required],
        comments: this.formBuilder.array([this.formBuilder.group({
          author: {id: this.sessionQuery.getValue().id},
          content: '',
          assignedUsers: this.formBuilder.array([[null]]),
          resolved: false,
          blocked: false,
          creationDate: new Date(),
        })]),
        status: [this.taskHelperService.calculateStatus(new Date(), TaskStatus.PLANNED)],
        isInternal: [false],
        isPriority: [false],
        relatedPeople: this.formBuilder.array([[null, Validators.required]])
      })])
    }));
  }

  // Set creation taskForm start and deadline dates.
  setDefaultStartAndEndDate() {
    this.getTaskStartDate(this.groupIndex, this.taskIndex)?.setValue(new Date());
    this.getTaskDeadlineDate(this.groupIndex, this.taskIndex)?.setValue(new Date(new Date().setDate(new Date().getDate() + 1)));
  }

  // Set creation taskForm current logged user as default contractor.
  setDefaultContractor() {
    this.getTaskContractor(this.groupIndex, this.taskIndex)?.setValue({id: this.sessionQuery.getValue().id});
  }

  // Set creation taskForm pole.
  setDefaultPole() {
    const defaultPole = this.polesOptions.filter((poleOption) =>
      poleOption.label === 'Back Office'
    );
    return defaultPole[0].value;
  }

  // Set creation taskForm importance.
  setDefaultImportance() {
    this.getTaskImportance(this.groupIndex, this.taskIndex)?.setValue(1);
  }

  // Set creation taskForm planned work load.
  setDefaultPlannedWorkLoad() {
    this.getTaskPlannedWorkload(this.groupIndex, this.taskIndex)?.setValue(45);
  }

  // Set creation taskForm related people when access from natural or legal person detail page.
  setDefaultTaskRelatedPerson() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        // Case access from natural person detail page.
        if (params['naturalPerson']) {
          // Set taskRelatedCustomer info for appropriate redirection when user create a task from related customer page.
          this.taskRelatedCustomer = {id: params['naturalPerson'], type: ClientType.NATURAL_PERSON};
          this.getTaskRelatedPeople(this.groupIndex, this.taskIndex)?.setValue([
            {
              label: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.firstName + ' ' + this.naturalPersonQuery.getEntity(params['naturalPerson'])?.lastName,
              value: {
                id: this.naturalPersonQuery.getEntity(params['naturalPerson'])?.id,
                clientType: ClientType.NATURAL_PERSON
              }
            },
          ]);
        }
        // Case access from legal person detail page.
        if (params['legalPerson']) {
          // Set taskRelatedCustomer info for appropriate redirection when user create a task from related customer page.
          this.taskRelatedCustomer = {id: params['legalPerson'], type: ClientType.LEGAL_PERSON};
          this.getTaskRelatedPeople(this.groupIndex, this.taskIndex)?.setValue([
            {
              label: this.legalPersonQuery.getEntity(params['legalPerson'])?.name,
              value: {
                id: this.legalPersonQuery.getEntity(params['legalPerson'])?.id,
                clientType: ClientType.LEGAL_PERSON
              }
            },
          ]);
        }
      });
  }

  //set task name 'Rappeler le client' si on vient du btn 'Rappeler le client'
  setDefaultTaskName() {
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        // Case access from natural person detail page.
        if (params['rappeler']) {
          this.getTaskName(this.groupIndex, this.taskIndex)?.setValue('Rappeler le client');
        }
      });
  }

  addRelatedPeople() {
    this.getTaskRelatedPeople(this.groupIndex, this.taskIndex).push(new UntypedFormControl('', Validators.required));
  }

  removeRelatedPeople(index: number) {
    this.getTaskRelatedPeople(this.groupIndex, this.taskIndex).removeAt(index);
  }

  addCommentAssignedUser() {
    this.getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex).push(new UntypedFormControl('', Validators.required));
  }

  removeCommentAssignedUser(index: number) {
    this.getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex).removeAt(index);
  }

  onStatusClick(selectedStatus: string) {
    if (selectedStatus === this.rawTaskStatus.DONE) {
      // affichage date de cloture
      this.getTaskClosingDate(this.groupIndex, this.taskIndex)?.setValue(new Date());
      this.displayCloseDate = true;
      // date cloture et charge reelle obligatoire
      this.getTaskClosingDate(this.groupIndex, this.taskIndex)?.setValidators([Validators.required]);
      this.getTaskRealWorkload(this.groupIndex, this.taskIndex)?.setValidators([Validators.required]);
      //si pas de realworkload, prerempli avec plannedWorkload
      if (this.getTaskRealWorkload(this.groupIndex, this.taskIndex)?.value === null) {
        this.getTaskRealWorkload(this.groupIndex, this.taskIndex)?.setValue(this.getTaskPlannedWorkload(this.groupIndex, this.taskIndex)?.value);
      }
      //si pas de collaborateur assigné, prerempli avec user connecté
      if (this.getTaskCollaborator(this.groupIndex, this.taskIndex)?.value === null) {
        this.getTaskCollaborator(this.groupIndex, this.taskIndex)?.setValue({id: this.user.id});
      }
    } else {
      this.displayCloseDate = false;
      this.getTaskClosingDate(this.groupIndex, this.taskIndex)?.setValidators([]);
      this.getTaskClosingDate(this.groupIndex, this.taskIndex)?.setValue(null);
      this.getTaskRealWorkload(this.groupIndex, this.taskIndex)?.setValidators([]);
      this.getTaskRealWorkload(this.groupIndex, this.taskIndex)?.setValue(this.realWorkloadBeforeEdition);
    }
    if (selectedStatus === this.rawTaskStatus.TODO ||
      (selectedStatus === this.rawTaskStatus.DOING && this.statusBeforeEditing !== this.rawTaskStatus.TODO)
    ) {
      this.getTaskStartDate(this.groupIndex, this.taskIndex)?.setValue(new Date());
      //decalage deadlineDate function of startDate
      this.getTaskDeadlineDate(this.groupIndex, this.taskIndex)?.setValue(addWeekDays(moment(new Date()).clone(), this.deltaDateBeforeEditing).toDate());
    }
  }

  onStartDateSelect(event: Date) {
    const selectedDate = event.toLocaleDateString('fr');
    const today = new Date().toLocaleDateString('fr');
    //si startdate > today : statusPLANNED
    if (selectedDate > today) {
      this.getTaskStatus(this.groupIndex, this.taskIndex)?.setValue(this.rawTaskStatus.PLANNED);
    }
    //si startdate = today : statusTODO
    if (selectedDate === today) {
      this.getTaskStatus(this.groupIndex, this.taskIndex)?.setValue(this.rawTaskStatus.TODO);
    }
    //decalage deadlineDate function of startDate
    this.getTaskDeadlineDate(this.groupIndex, this.taskIndex)?.setValue(addWeekDays(moment(event).clone(), this.deltaDateBeforeEditing).toDate());
  }

  formCancel() {
    this.navigation.back();
  }

  formDelete() {
    this.taskService
      .delete(this.taskEditing.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskDeleteSuccess();

          //update the step store with the group of the deleted task
          this.getStepValues(this.taskEditing.id).then(values => {
            if(values !== null) {
              this.stepStore.update(values.step.id, entity => (
                {...values.step, stepGroups: [...values.stepGroups, values.groupToUpdate]})
              );
            }
          });
        },
        error: () => {
          this.onTaskActionError();
        },
      });
  }

  // return the step to update, its stepGroups and the stepGroup to update
  getStepValues(taskId: any): Promise<any> {
    return new Promise((resolve, reject) => {
      return this.stepQuery.selectAll().pipe(
        map((steps: any) => {
          let stepToUpdate: any = null;
          let group: any = null;

          steps.map((step: any) => {
            step.stepGroups.map((stepGroup: any) => {
              if (stepGroup.tasks.some((task: any) => task.id === taskId)) {
                stepToUpdate = step;
                group = stepGroup;
              }
            });
          });

          // stepGroup to update
          const groupToUpdate = { ...group, tasks: group?.tasks.filter((task: any) => task.id !== taskId) };

          const groups = stepToUpdate?.stepGroups.filter((stepGroup: any) => stepGroup.id !== groupToUpdate.id);

          return stepToUpdate !== null ? {step: stepToUpdate, stepGroups: groups, groupToUpdate} : null;
        })
      ).subscribe(values => {
        if(values !== null) {
          resolve(values);
        }
      });
    });
  }

  getRelatedCustomerPageUrl(taskRelatedCustomer: undefined | {
    id: number;
    type: ClientType.LEGAL_PERSON | ClientType.NATURAL_PERSON;
  }) {
    if (taskRelatedCustomer) {
      const relatedCustomerType = taskRelatedCustomer.type === ClientType.NATURAL_PERSON ? 'natural-person' : 'legal-person';
      return '/' + relatedCustomerType + '/' + taskRelatedCustomer.id;
    } else {
      return undefined;
    }
  }

  formSubmit() {
    const commentsArrayLength = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments.length;
    // @ts-ignore
    // eslint-disable-next-line max-len
    const latestCommentFormControl = this.stepForm.controls['stepGroups'].controls[this.groupIndex].controls.tasks.controls[this.taskIndex].controls.comments.controls[commentsArrayLength - 1].controls;
    const latestCommentFormValue = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments[commentsArrayLength - 1];

    // reinit latest comment validators
    //ici la validation n'est pas dynamique maise seulement au submit, donc on reset les validateurs avant de les recalculer
    latestCommentFormControl.assignedUsers.controls[0].setErrors(null);
    latestCommentFormControl.assignedUsers.setErrors(null);
    latestCommentFormControl.content.setErrors(null);

    // Création d'un commentaire :
    // Erreur si commentaire marqué comme point bloquant, mais sans collaborateur assigné
    if (latestCommentFormValue.blocked && latestCommentFormValue.assignedUsers.length === 0) {
      latestCommentFormControl.assignedUsers.controls[0].setErrors({required: true});
      latestCommentFormControl.assignedUsers.setErrors({required: true});
    }

    // Création d'un commentaire :
    // Erreur si commentaire marqué comme point bloquant, mais sans contenu
    if (latestCommentFormValue.blocked && latestCommentFormValue.content === '') {
      latestCommentFormControl.content.setErrors({required: true});
    }

    // Création d'un commentaire :
    // Erreur si présence d'un collaborateur assigné, mais contenu du commentaire est vide
    if (latestCommentFormValue.assignedUsers.length > 0 && latestCommentFormValue.assignedUsers[0] !== null && latestCommentFormValue.content === '') {
      latestCommentFormControl.content.setErrors({required: true});
    }

    if (this.stepForm.invalid) {
      this.stepForm.markAllAsTouched();
      return;
    }

    // Close task.
    if (this.getTaskStatus(this.groupIndex, this.taskIndex)?.value === this.rawTaskStatus.DONE) {
      if (this.taskEditing?.id && typeof this.taskEditing.id === 'number') {
        const isGroupComplete = this.taskHelperService.isGroupComplete(this.getTasks(this.groupIndex).value, this.taskEditing.id);
        if (isGroupComplete) {
          const offset = this.taskHelperService.getCloseDateOffset(this.stepEditing, this.groupIndex);
          this.groups.reset(this.taskHelperService.recalculateStepDateAndStatus(this.groups.value, this.groupIndex, offset));
        }
      }
    } else {
      this.getTaskStatus(this.groupIndex, this.taskIndex)?.setValue(
        this.taskHelperService.calculateStatus(this.getTaskStartDate(this.groupIndex, this.taskIndex)?.value,
          this.getTaskStatus(this.groupIndex, this.taskIndex)?.value
        ));
    }

    // When the comment does not have a user assigned, the dropdown defaults to null, removes it.
    if (this.getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex).value[0] === null) {
      this.getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex).removeAt(0);
    }
    // Remove comment if content is empty.
    const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments.filter((comment: any) =>
      !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

    // Format legal persons and natural persons in the appropriate backend format.
    this.stepForm.get('legalPersons')?.setValue(this.taskHelperService.getLegalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));
    this.stepForm.get('naturalPersons')?.setValue(this.taskHelperService.getNaturalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));

    // convert deadlineDate of the task to UTC
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].deadlineDate = this.taskHelperService.convertDeadlineDateToUTC(this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].deadlineDate);

    // convert startDate of the task to UTC
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].startDate = this.taskHelperService.convertStartDateToUTC(this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].startDate);

    if (!this.isFormEditing) {
      this.createTask(this.stepForm.value);
    }

    if (this.isFormEditing) {
      this.updateTask(this.stepForm.value);
    }
  }

  unlockComment(commentId: number) {
    // Update blocked comment notification state from 'unread' > 'read'.
    const relatedNotificationLink = '/task-customer-edit/' + this.stepEditing.id + '/' + this.taskEditing.id + '#' + commentId;
    this.notificationQuery.selectAll({
      filterBy: [
        (entity) => entity.link === relatedNotificationLink
      ]
    }).pipe(untilDestroyed(this)).pipe(take(1)).subscribe((notifications) => {
      if (notifications) {
        notifications.map((notification) => {
          this.notificationService.update(notification.id, {state: NotificationStatus.READ}).pipe(untilDestroyed(this)).subscribe();
        });
      }
    });

    // Resolve comment.
    const comments = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments;
    const updatedCommentIndex = comments.findIndex((comment: any) => comment.id === commentId);
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments[updatedCommentIndex].resolved = true;

    // Necessary for persist.
    this.stepForm.get('legalPersons')?.setValue(this.taskHelperService.getLegalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));
    this.stepForm.get('naturalPersons')?.setValue(this.taskHelperService.getNaturalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));


    // Handle comment without assigned user.
    const commentsArrayLength = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments.length;
    const latestCommentAssignedUsersRaw = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments[commentsArrayLength - 1].assignedUsers;
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments[commentsArrayLength - 1].assignedUsers = latestCommentAssignedUsersRaw.filter((item: any) => item !== null);
    // Handle comment without content.
    const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
    this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments = this.stepForm.value.stepGroups[this.groupIndex].tasks[this.taskIndex].comments.filter((comment: any) =>
      !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

    this.updateComment(this.stepForm.value);
  }

  createTask(step: Step) {
    this.stepService.add(step)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskCreationSuccess();
        },
        error: () => {
          this.onTaskActionError();
        },
      });
  }

  updateTask(step: Step) {
    const updatedStep = {...step, updatedAt: new Date()}
    this.stepService
      .update(this.stepEditing.id, updatedStep)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskUpdateSuccess();
        },
        error: () => {
          this.onTaskActionError();
        },
      });
  }

  updateComment(step: Step) {
    const updatedStep = {...step, updatedAt: new Date()}
    this.stepService
      .update(this.stepEditing.id, updatedStep)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onCommentUpdateSucces();
        },
        error: () => {
          this.onCommentUpdateError();
        },
      });
  }

  onTaskCreationSuccess() {
    if (this.comesFromTimeline) {
      this.router.navigate(['/timeline']);
    } else {
      const relatedCustomerDetailPage = this.getRelatedCustomerPageUrl(this.taskRelatedCustomer);
      this.router.navigate([relatedCustomerDetailPage ?? '/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'La nouvelle tâche a été créée',
      life: 10000,
    });
  }

  onTaskUpdateSuccess() {
    if (this.comesFromTimeline) {
      this.router.navigate(['/timeline']);
    } else if (this.comesFromMondayReview) {
      this.router.navigate(['/monday-review']);
    } else {
      const relatedCustomerDetailPage = this.getRelatedCustomerPageUrl(this.taskRelatedCustomer);
      this.router.navigate([relatedCustomerDetailPage ?? '/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Modifications enregistrées',
      life: 10000,
    });
  }

  onTaskDeleteSuccess() {
    if (this.comesFromTimeline) {
      this.router.navigate(['/timeline']);
    } else if (this.comesFromMondayReview) {
      this.router.navigate(['/monday-review']);
    } else {
      const relatedCustomerDetailPage = this.getRelatedCustomerPageUrl(this.taskRelatedCustomer);
      this.router.navigate([relatedCustomerDetailPage ?? '/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'La tâche a été supprimée',
      life: 10000,
    });
  }

  onTaskActionError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000,
    });
  }

  onCommentUpdateSucces() {
    this.messageService.add({
      severity: 'success',
      summary: 'Commentaire débloqué avec succès',
      life: 10000,
    });
  }

  onCommentUpdateError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors du déblocage du commentaire',
      life: 10000,
    });
  }

  // Used to display a warning comment when user choose to block a comment.
  blockingCommentWarning() {
    this.blockingCommentWarningState = !this.blockingCommentWarningState;
  }
}
