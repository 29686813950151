import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AvoirFinancier, DropdownOption, DropdownOptionsService, TrancheImpot} from 'common';


type TrancheType = {
  active: boolean;
  amount: string;
};
@Component({
  selector: 'app-impot-form',
  templateUrl: './impot-form.component.html',
  styleUrls: ['./impot-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImpotFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImpotFormComponent),
      multi: true
    }
  ]
})
export class ImpotFormComponent implements ControlValueAccessor, OnDestroy {
  @Input() clientOrAdmin = '';
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  trancheImpotTypes = TrancheImpot;
  yesNoOptions: DropdownOption<any>[] = [];

  initialValues = {
    trancheImpots: null,
    assujettiIfi: null,
  };
  trancheImpots: TrancheType[];

  constructor(private formBuilder: UntypedFormBuilder,private dropdownOptionsService: DropdownOptionsService,) {
    this.form = this.formBuilder.group({
      trancheImpots: this.formBuilder.control({}),
      assujettiIfi: this.formBuilder.control({}),
    }
    );
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.yesNoOptions = this.dropdownOptionsService.yesNo;

    this.trancheImpots = [
      {
        active: false,
        amount: this.trancheImpotTypes.ZERO_CINQ_MILLE,
      },
      {
        active: false,
        amount: this.trancheImpotTypes.CINQ_MILLE_QUINZE_MILLE,
      },
      {
        active: false,
        amount: this.trancheImpotTypes.QUINZE_MILLE_VINGT_CINQ_MILLE,
      },
      {
        active: false,
        amount: this.trancheImpotTypes.PLUS_VINGT_CINQ_MILLE,
      },

    ];
  }

  get value(): AvoirFinancier {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  onTrancheClick(selectedTranche: TrancheType){
    this.trancheImpots.filter(tranche => tranche === selectedTranche)[0].active = true;
    this.trancheImpots.filter(tranche => tranche !== selectedTranche).map(tranche =>{
      tranche.active = false;
    });
    this.form.patchValue({
      trancheImpots: selectedTranche.amount
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
      if(this.trancheImpots.filter(tranche => tranche.amount === value.trancheImpots).length){
        this.trancheImpots.filter(tranche => tranche.amount === value.trancheImpots)[0].active = true;
      }
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
}
