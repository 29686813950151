import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ClientSearchResultStore, ClientSearchResultState } from './client-search-result.store';

@Injectable({ providedIn: 'root' })
export class ClientSearchResultQuery extends Query<ClientSearchResultState> {

  constructor(protected override store: ClientSearchResultStore) {
    super(store);
  }

}
