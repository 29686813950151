import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CreditNonImmobilier } from './credit-non-immobilier.model';

export interface CreditNonImmobilierState extends EntityState<CreditNonImmobilier> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'creditNonImmobilier' })
export class CreditNonImmobilierStore extends EntityStore<CreditNonImmobilierState> {

  constructor() {
    super();
  }

}
