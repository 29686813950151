import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { DocumentStore, DocumentState } from './document.store';

@Injectable({ providedIn: 'root' })
export class DocumentService extends NgEntityService<DocumentState> {

  constructor(protected override store: DocumentStore) {
    super(store);
  }

}
