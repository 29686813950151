import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToDuration'
})
export class MinutesToDurationPipe implements PipeTransform {
  transform(duration: number): string {
    const formattedDuration = duration / 60;

    const hours = Math.floor(formattedDuration);
    const minutes = Math.floor((formattedDuration - Math.floor(formattedDuration)) * 60);

    if (minutes === 0) {
      return hours + 'h';
    } else if (minutes.toString().length < 2) {
      return hours + 'h' + '0' + minutes;
    } else {
      return hours + 'h' + minutes;
    }
  }


}
