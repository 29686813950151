import {Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import {DefaultValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: '[appUpperCaseInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UpperCaseInputDirective),
    },
  ],
})
export class UpperCaseInputDirective extends DefaultValueAccessor {

  lastValue: string;

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }

  @HostListener('input', ['$event']) input($event: InputEvent) {
    console.log('event', $event);
    const target = $event.target as HTMLInputElement;

    console.log('target', target);

    const start = target.selectionStart;
    const end = target.selectionEnd;

    console.log('start', start);
    console.log('end', end);

    target.value = target.value.toUpperCase();
    target.setSelectionRange(start, end);

    this.onChange(target.value);
    console.log('target value', target.value);
  }
}
