<ng-container *ngIf="isLoading">
  <h1>
    <p-skeleton class="w-4" height="43px"></p-skeleton>
  </h1>
  <p-skeleton class="w-4" height="188px"></p-skeleton>
</ng-container>
<div [hidden]="isLoading">
  <!-- FICHE CONTACT MOBILE -->
  <ng-container *ngIf="(uiScreenQuery.isMobile$ | async);else desktop">
    <h1 class="text-center">{{ legalPerson?.name }}</h1>
    <div class="card text-sm text-800">
      <div>
        <!--  CONTACT -->
        <p class="m-0 text-primary font-semibold text-lg">Contact</p>
        <div class="mt-2 divider"></div>
        <!-- ADRESSE -->
        <div class="mt-4 flex align-items-start">
          <div
            class="mr-3 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
            <i class="pi pi-home" style="font-size: 0.9rem"></i>
          </div>
          <p class="m-0 font-semibold line-height-3" *ngFor="let address of legalPerson?.addresses">
            {{ address.street }},<br>
            {{ address.zipCode }} {{ address.city }} {{ address.country }}
          </p>
        </div>
        <!-- CABINET -->
        <p class="m-0 text-primary font-semibold text-lg mt-6">Cabinet</p>
        <div class="mt-2 divider"></div>
        <!-- STATUT -->
        <div class="flex mt-3">
          <p class="m-0">Statut :
            <ng-container *ngFor="let status of legalPerson?.customerStatus; let last = last;">
              <b>{{ translate(status)}}</b>
              <ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- FICHE CONTACT DESKTOP -->
  <ng-template #desktop>
    <h1>{{ legalPerson?.name }}</h1>
    <!--  CONTACT -->
    <div class="px-7 py-4 card text-sm text-800">
      <div class="flex justify-content-between align-items-center">
        <div class="text-lg w-3">
          <p class="m-0 text-primary font-semibold">Contact</p>
          <div class="mt-2 divider"></div>
        </div>
        <button pButton type="button" icon="pi pi-pencil"
                class="p-button-raised p-button-text"
                (click)="showEditLegalPersonModal = true"></button>
      </div>
      <div class="grid mt-1">
        <!-- COLONNE 1 -->
        <div class="col-4">
          <div class="mt-1 flex align-items-start">
            <div
              class="mr-3 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
              <i class="pi pi-home" style="font-size: 0.9rem"></i>
            </div>
            <p class="m-0 font-semibold line-height-3" *ngFor="let address of legalPerson?.addresses">
              {{ address.street }},<br>
              {{ address.zipCode }} {{ address.city }} {{ address.country }}
            </p>
          </div>
        </div>
        <!-- COLONNE 2 -->
        <div class="col-4">
          <table class="contact-table">
            <!-- STRUCTURE JURIDIQUE -->
            <tr>
              <td>
                <p class="m-0">Structure juridique :</p>
              </td>
              <td>
                <p class="m-0 font-semibold">{{ translate(legalPerson?.structureJuridique) }}</p>
              </td>
            </tr>
            <!-- SIRET -->
            <tr>
              <td>
                <p class="m-0">Siret :</p>
              </td>
              <td>
                <p class="m-0 font-semibold">{{ legalPerson?.siretNumber }}</p>
              </td>
            </tr>
            <!-- DATE CRÉATION -->
            <tr>
              <td>
                <p class="m-0">Date de création :</p>
              </td>
              <td>
                <p class="m-0 font-semibold">{{ legalPerson?.creationDate | date: 'dd/MM/yyyy' }}</p>
              </td>
            </tr>
          </table>
        </div>
        <!-- COLONNE 3 -->
        <div class="col-4">
          <p class="m-0 mt-2">Statut :
            <ng-container *ngFor="let status of legalPerson?.customerStatus; let last = last;">
              <b>{{ translate(status)}}</b>
              <ng-container *ngIf="!last">, </ng-container>
            </ng-container>
          </p>
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="(uiScreenQuery.isMobile$ | async) === false;" class="mt-5">
    <p-tabView>
      <!--  PANNEAU ACTIVITE -->
      <p-tabPanel header="Activité">
        <!--  ETAPES EN COURS -->
        <p-fieldset styleClass="mt-4 steps" legend="Tâches en cours" [toggleable]="true">
          <div class="flex justify-content-end">
            <button pButton type="button" icon="pi pi-list" label="Créer étape client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/client-step-new', {legalPerson: legalPerson?.id})"></button>
            <button pButton type="button" icon="pi pi-plus" label="Créer tâche client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/task-customer-new', {legalPerson: legalPerson?.id})"></button>
            <button pButton type="button" icon="pi pi-plus" label="Rappeler le client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/task-customer-new', {legalPerson: legalPerson?.id, rappeler: true})"></button>
          </div>
          <p-table [formGroup]="form" #currentStepsTable [value]="legalPersonCurrentStepsTasks" dataKey="id" [rows]="10"
                   responsiveLayout="scroll"
                   [paginator]="true" [filterDelay]="0" [alwaysShowPaginator]="false"
                   [rowsPerPageOptions]="[10,25,50]"
                   stateStorage="local" [stateKey]="tableCustomerCurrentStorageKey"
                   styleClass="mt-5"
                   (onStateRestore)="onDashboardStateRestore($event, dashboardType.CURRENT)"
                   [showCurrentPageReport]="true"
                   currentPageReportTemplate="Tâches en cours {first} à {last} sur {totalRecords} trouvées"
          >
            <ng-template pTemplate="header">
              <tr>
                <!-- TRIE ECHEANCE -->
                <th class="text-sm" pSortableColumn="deadlineDate">
                  Échéance
                  <p-sortIcon class="ml-auto" field="deadlineDate"></p-sortIcon>
                </th>
                <!-- IMPORTANCE -->
                <th></th>
                <!-- TRIE ETAPE -->
                <th class="text-sm">Étape / Tâche</th>
                <!-- CLIENT -->
                <th></th>
                <!-- TRIE COLLABORATEUR / PÔLE -->
                <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">
                  Collaborateur / Pôle
                  <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
                </th>
                <!-- ACTION BUTTON -->
                <th></th>
              </tr>
              <tr>
                <!-- REINIT BUTTON -->
                <th>
                  <button id="reinitFilterButtonCustomer" pButton type="button"
                          class="p-button-raised p-button-sm bg-white text-black border-none"
                          icon="pi pi-filter-slash" label="Réinitialiser"
                          (click)="clearFilters($event, 'currentSteps')">
                  </button>
                </th>
                <!-- IMPORTANCE -->
                <th></th>
                <!-- FILTRE ETAPE -->
                <th>
                  <p-dropdown #filterStepsCurrentSteps
                              appendTo="body"
                              styleClass="w-7rem"
                              placeholder="..."
                              formControlName="stepFilterCurrentSteps"
                              [showClear]="true"
                              [options]="stepsOptions"
                              (onClear)="clearFilterTask($event)"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.STEP)"
                  ></p-dropdown>
                  <!-- FILTRE ETAPE TÂCHE -->
                  <p-dropdown #filterTasksCurrentSteps
                              appendTo="body"
                              styleClass="w-7rem"
                              class="ml-2"
                              placeholder="..."
                              formControlName="stepTaskFilterCurrentSteps"
                              [showClear]="true"
                              [options]="stepFilterRelatedTaskOption"
                              [group]="true"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.STEPTASK)"
                  ></p-dropdown>
                </th>
                <!-- FILTRE CLIENT -->
                <th></th>
                <!-- FILTRE PÔLE/COLLABORATEUR -->
                <th>
                  <p-dropdown #filterCollaboratorAndPoleCurrentSteps
                              appendTo="body"
                              styleClass="w-full"
                              placeholder="..."
                              formControlName="poleAndCollaboratorFilterCurrentSteps"
                              [showClear]="true"
                              [options]="poleAndCollaboratorOptions"
                              [group]="true"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE)"
                  ></p-dropdown>
                </th>
                <!-- ACTION BUTTON -->
                <th></th>
              </tr>
            </ng-template>
            <!--  CORPS DU TABLEAU -->
            <ng-template pTemplate="body" let-task>
              <tr>
                <!--  COLONNE ÉCHÉANCE -->
                <td class="p-2 col-2">
                  <p class="text-xs font-semibold">{{ task.startDate | date: 'dd/MM/yyyy' }}
                    <i class="pi pi-arrow-right"
                       style="font-size: 0.5rem;"></i> {{ task.deadlineDate | date: 'dd/MM/yyyy' }}
                  </p>
                </td>
                <!--  COLONNE IMPORTANCE -->
                <td class="p-2 col-2">
                  <div class="flex align-items-end w-full">
                    <div class="mr-1 col-2">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="mr-1 col-2">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-low-danger': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="mr-1 col-2">
                      <div *ngIf="task.importance && task.importance === 2"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="col-2">
                      <p-tag *ngIf="task.status === taskStatus.PLANNED"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-gray text-white"
                             value="planned" [rounded]="true"></p-tag>
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-success text-white"
                             value="doing" [rounded]="true"></p-tag>
                    </div>
                  </div>
                </td>
                <!--  COLONNE ÉTAPE / TÂCHE -->
                <td class="p-2 col-3">
                  <p class="text-sm m-0">{{ task.step?.name }}</p>
                  <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
                </td>
                <!--  COLONNE CLIENT -->
                <td class="p-2 col-2">
                  <p-tag *ngFor="let naturalPerson of task?.relatedNaturalPersons"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                         class=""
                         value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                         [rounded]="true"
                         (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                         pTooltip="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                         [showDelay]="500"
                  >
                  </p-tag>
                  <p-tag *ngFor="let legalPerson of task?.relatedLegalPersons"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-warning text-white border-1 border-warning cursor-pointer"
                         value="{{ legalPerson.name }}"
                         [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')"
                         pTooltip="{{ legalPerson.name }}"
                         [showDelay]="500"
                  >
                  </p-tag>
                </td>
                <!--  COLONNE COLLABORATEUR / POLE -->
                <td class="p-2 col-2">
                  <div class="flex flex-column">
                    <p-tag *ngIf="task.collaborator"
                           styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                           value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                           [rounded]="true">
                    </p-tag>
                    <p-tag *ngIf="task.pole"
                           styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                           value="{{ translate(task.pole) }}"
                           [rounded]="true">
                    </p-tag>
                  </div>
                </td>
                <!-- ACTION BUTTON -->
                <td class="p-2 col-1">
                  <div class="flex">
                    <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-text ml-3"
                            (click)="editTask(task)"></button>
                    <button pButton type="button" icon="pi pi-check-circle" class="p-button-success p-button-raised p-button-text ml-3"
                            (click)="openCloseDialog(task)"></button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <!--  AUCUNE TÂCHE TROUVÉE -->
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="p-3 text-center text-sm">Aucune tâche trouvée</td>
              </tr>
            </ng-template>
          </p-table>
        </p-fieldset>
        <ng-template [ngIf]="legalPersonHistoricalTasks && legalPersonHistoricalTasks.length > 0">
          <p-fieldset styleClass="mt-4 task-history" legend="Historique des tâches" [toggleable]="true">
            <p-table [formGroup]="form" #historicalTable [value]="legalPersonHistoricalTasks" dataKey="id" [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="true" [filterDelay]="0" [alwaysShowPaginator]="false"
                     [rowsPerPageOptions]="[10,25,50]"
                     stateStorage="local" [stateKey]="tableCustomerHistoricalStorageKey"
                     styleClass="mt-5"
                     (onStateRestore)="onDashboardStateRestore($event, dashboardType.HISTORICAL)"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="Tâches terminées {first} à {last} sur {totalRecords} trouvées"
            >
              <ng-template pTemplate="header">
                <tr>
                  <!-- TRIE ECHEANCE -->
                  <th class="text-sm flex flex-row align-items-baseline" pSortableColumn="closingDate">
                    <p>Date de clôture</p>
                    <p-sortIcon class="ml-3" field="closingDate"></p-sortIcon>
                  </th>
                  <th>Tâche</th>
                  <!-- TRIE COLLABORATEUR -->
                  <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">Collaborateur
                    <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
                  </th>
                  <th class="text-sm">
                    <div class="flex justify-content-end">
                      Charge réelle
                      <i class="pi pi-clock ml-2"></i>
                    </div>
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <!-- FILTRE COLLABORATEUR -->
                  <th class="col-2">
                    <p-dropdown #filterPoleHistorical
                                appendTo="body"
                                styleClass="w-10rem"
                                placeholder="..."
                                formControlName="poleFilterHistorical"
                                [showClear]="true"
                                [options]="collaboratorOptions"
                                [group]="true"
                                (onChange)="handleFiltering($event.value, dashboardFiltersType.POLE)"
                    ></p-dropdown>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <!--  CORPS DU TABLEAU -->
              <ng-template pTemplate="body" let-task>
                <tr>
                  <!--  COLONNE ÉCHÉANCE -->
                  <td class="p-2 col-2">
                    <p class="text-sm">{{ task.closingDate | date: 'dd/MM/yyyy' }}</p>
                  </td>
                  <td class="p-2 col-5">
                    <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
                  </td>
                  <!--  COLONNE COLLABORATEUR -->
                  <td class="p-2">
                    <div class="flex flex-column">
                      <p-tag *ngIf="task.collaborator"
                             styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                             value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </td>
                  <td class="p-2">
                    <div class="flex flex-row align-items-baseline justify-content-end">
                      <p>{{formatDurationTime(task.realWorkload)}}</p>
                    </div>
                  </td>
                  <td class="p-2">
                    <div class="flex justify-content-end">
                      <button pButton type="button" icon="pi pi-eye" class="ml-auto p-button-raised p-button-text"
                              (click)="editTask(task)"></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <!--  AUCUNE TÂCHE TROUVÉE -->
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="p-3 text-center text-sm">Aucune tâche trouvée</td>
                </tr>
              </ng-template>
            </p-table>
          </p-fieldset>
        </ng-template>
        <!--  COMPTES RENDUS -->
        <p-fieldset styleClass="mt-4 cr" legend="Comptes rendus" [toggleable]="true">
          <div class="flex justify-content-end">
            <button pButton type="button" icon="pi pi-list" label="Nouveau CR" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/meeting-report-new', {legalPerson: legalPerson?.id})"></button>
          </div>
          <p-table #meetingReportTable [formGroup]="form" [value]="compteRendus" dataKey="id" [rowHover]="true"
                   [rows]="10" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"
                   styleClass="mt-5"
                   [paginator]="true" [showCurrentPageReport]="true"
                   currentPageReportTemplate="{totalRecords} comptes rendus trouvés"
                   [filterDelay]="0" [alwaysShowPaginator]="false"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>
                  <div pSortableColumn="meetingDate" class="flex align-items-center text-sm">
                    Date du rendez-vous
                    <p-sortIcon class="ml-2" field="meetingDate"></p-sortIcon>
                  </div>
                </th>
                <th>
                  <div pSortableColumn="mainCollaborator.lastName" class="flex align-items-center text-sm">
                    Collaborateur
                    <p-sortIcon class="ml-2" field="mainCollaborator.lastName"></p-sortIcon>
                  </div>
                </th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <button pButton type="button" class="p-button-raised p-button-sm bg-white text-black border-none"
                          icon="pi pi-filter-slash" label="Réinitialiser"
                          (click)="meetingReportTable.clear()"></button>
                </th>
                <th></th>
                <th>
                  <p-dropdown
                    appendTo="body"
                    styleClass="w-12rem"
                    placeholder="..."
                    formControlName="collaboratorMeetingReport"
                    [showClear]="true"
                    [group]="true"
                    [options]="collaboratorOptions"
                    (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATOR)"
                  >
                  </p-dropdown>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!--  CORPS DU TABLEAU -->
            <ng-template pTemplate="body" let-compteRendu>
              <tr>
                <td class="col-2">
                  <p class="text-sm font-semibold">{{compteRendu.subject}}</p>
                </td>
                <td class="col-4">
                  <p class="text-sm">le {{compteRendu.meetingDate | date: 'dd/MM/yyyy' }}</p>
                </td>
                <td class="col-3">
                  <p-tag *ngFor="let collaborator of compteRendu.collaboratorPresent"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ collaborator.lastName }} {{ collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                </td>
                <td>
                  <div class="flex justify-content-end">
                    <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-text mt-3 ml-3"
                            (click)="onClickNavigateTo('/meeting-report-edit/' + compteRendu.id)"></button>

                    <button pButton type="button" icon="pi pi-eye" class="p-button-raised p-button-text mt-3 ml-3"
                            (click)="onClickNavigateTo('/meeting-report/' + compteRendu.id)"></button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <!--  AUCUN COMPTE RENDU TROUVÉ -->
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="p-3 text-center text-sm">Aucun compte rendu trouvé</td>
              </tr>
            </ng-template>
          </p-table>
        </p-fieldset>
      </p-tabPanel>
      <!--  PANNEAU INFORMATIONS -->
      <p-tabPanel header="Informations">
        <!--  RELATIONS -->
        <div class="flex justify-content-between align-items-center mt-4">
          <div class="flex align-items-center">
            <h3 class="inline text-900">Personnes physiques</h3>
            <p-badge value="{{ legalPerson?.naturalLegalRelations?.length }}" styleClass="ml-2 font-light"></p-badge>
          </div>
          <button pButton type="button" icon="pi pi-plus"
                  class="p-button-raised p-button-success p-button-text"
                  (click)="showAddNaturalLegalRelationModal = true"></button>
        </div>
        <div class="mt-3">
          <ng-container *ngIf="legalPerson?.naturalLegalRelations?.length; else noLegalRelations">
            <!--  TEMPLATE SI RELATIONS -->
            <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
            <p-tag *ngFor="let relation of legalPerson?.naturalLegalRelations"
                   styleClass="p-0 pr-3 m-2 bg-white text-900 font-light border-solid border-1 border-secondary cursor-pointer"
                   value="{{ relation?.naturalPerson?.lastName }} {{ relation?.naturalPerson?.firstName }}"
                   [rounded]="true"
                   (click)="activeRelation = relation; menu.toggle($event)">
              <p-tag styleClass="mr-2" value="{{ translate(relation?.naturalPersonRole) }}" [rounded]="true"></p-tag>
            </p-tag>

          </ng-container>
          <!--  TEMPLATE SI PAS DE RELATIONS -->
          <ng-template #noLegalRelations>
            <p>Aucune relation</p>
          </ng-template>
        </div>
        <!--  INFORMATIONS COMPLÉMENTAIRES -->
        <div class="flex justify-content-between align-items-center mt-5">
          <h3 class="text-900">Informations complémentaires</h3>
          <button *ngIf="!showGeneralCommentEdit" pButton type="button" icon="pi pi-pencil"
                  class="p-button-raised p-button-text"
                  (click)="displayGeneralCommentEdit()"></button>
        </div>
        <p-inplace [active]="showGeneralCommentEdit">
          <ng-template pTemplate="display">

          </ng-template>
          <ng-template pTemplate="content">
            <p-editor class="w-full" [(ngModel)]="generalComment" [style]="{'height':'320px'}"></p-editor>
            <div class="col-12 flex justify-content-end mt-5">
              <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                        (click)="cancelGeneralCommentEdit()"></p-button>
              <p-button label="Enregistrer" icon="pi pi-check"
                        styleClass="ml-3 p-button-success p-button-raised"
                        (click)="submitGeneralComment()"></p-button>
            </div>
          </ng-template>
        </p-inplace>
        <div *ngIf="!showGeneralCommentEdit" class="mt-3" [innerHTML]="legalPerson?.generalComment"></div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<!--CUSTOMER TASK CLOSE DIALOG-->
<app-close-task-modal *ngIf="closingTask?.id"
                      [display]="displayCloseDialog"
                      [task]="closingTask"
                      (displayCloseDialog)="displayCloseDialog = $event"
></app-close-task-modal>

<!--  MODALE ÉDITION PERSONNE MORALE -->
<p-dialog
  class="border-round"
  [(visible)]="showEditLegalPersonModal"
  (onHide)="hideEditLegalPersonModal()"
  [style]="{ width: '70vw' }"
  [modal]="true"
  header="Modifier les information de {{ legalPerson?.name }}">
  <app-legal-person-form [isNewLegalPerson]="false"></app-legal-person-form>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="hideEditLegalPersonModal()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="submitLegalPersonForm()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--  MODALE AJOUTER UN RELATION LEGAL - NATURAL -->
<p-dialog
  class="border-round"
  [(visible)]="showAddNaturalLegalRelationModal"
  (onHide)="hideAddNaturalLegalRelationModal()"
  [style]="{width: '70vw', height: '590px'}"
  [modal]="true"
  header="Ajouter une relation"
  styleClass="natural-natural-relation"
>
  <div class="py-6 px-2">
    <app-natural-legal-relation-form [clientType]="clientType.LEGAL_PERSON" [currentLegalPersonId]="legalPerson?.id"></app-natural-legal-relation-form>
  </div>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end mt-5">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="hideAddNaturalLegalRelationModal()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="submitNaturalLegalRelationsForm()"></p-button>
    </div>
  </ng-template>
</p-dialog>
