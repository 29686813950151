export enum Civility {
  MME = 'MME',
  MX = 'MX',
  M = 'M',
}

export enum MesureProtection {
  NO = 'NO',
  TUTELLE = 'TUTELLE',
  CURATELLE = 'CURATELLE',
  SAUVEGARDE_JUSTICE = 'SAUVEGARDE_JUSTICE',
}
