import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompteRendu,
  CompteRenduQuery,
  CompteRenduService,
  LegalPerson,
  NaturalPerson,
  SessionQuery,
  NavigationService,
  ClientType,
  UiScreenQuery
} from 'common';
import {
  CreateMeetingReportFormComponent
} from '../../components/create-meeting-report-form/create-meeting-report-form.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-page-meeting-report-edit',
  templateUrl: './page-meeting-report-edit.component.html',
  styleUrls: ['./page-meeting-report-edit.component.scss']
})
export class PageMeetingReportEditComponent implements OnInit, OnDestroy {
  meetingReportForm: CreateMeetingReportFormComponent;
  meetingReport?: CompteRendu;
  isLoading = true;
  autoSave: ReturnType<typeof setInterval>;

  constructor(
    private route: ActivatedRoute,
    private compteRenduService: CompteRenduService,
    private compteRenduQuery: CompteRenduQuery,
    private router: Router,
    private messageService: MessageService,
    private sessionQuery: SessionQuery,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild(CreateMeetingReportFormComponent, { static: false }) set ref(meetingReportForm: CreateMeetingReportFormComponent) {
    if (meetingReportForm) {
      this.meetingReportForm = meetingReportForm;
      this.meetingReportForm?.createFormGroup(this.sessionQuery.getValue().id, this.meetingReport);
    }
  };

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.compteRenduQuery.selectEntity(id).subscribe(
        compteRendu => {
          this.meetingReport = compteRendu;

          if (this.meetingReport) {
            this.isLoading = false;
          }
        }
      );
    });

    // Sauvegarde automatique du compte rendu toutes les 5 minutes
    this.autoSave = setInterval(() => this.formSubmit(true), 1000 * 60 * 5);

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.autoSave);
  }

  formSubmit(autoSave = false) {
    if (this.meetingReportForm.form.invalid) {
      this.meetingReportForm.form.markAllAsTouched();
      return;
    }

    const values = this.meetingReportForm.form.value;

    const personnesPhysiquesConcernees: NaturalPerson[] = values.clientsConcernes.filter((client: any) => client.value.clientType === ClientType.NATURAL_PERSON);
    const personnesMoralesConcernees: LegalPerson[] = values.clientsConcernes.filter((client: any) => client.value.clientType === ClientType.LEGAL_PERSON);

    const compteRendu = {
      ...values,
      naturalPersonsConcerned: personnesPhysiquesConcernees.map((personnePhysique: any) => {
        return {
          id: personnePhysique.value.id
        }
      }),
      legalPersonsConcerned: personnesMoralesConcernees.map((personneMorale: any) => {
        return {
          id: personneMorale.value.id
        }
      }),
    };

    this.compteRenduService.update(this.meetingReport?.id, compteRendu).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Les modifications ont bien été sauvegardées.',
          life: 10000
        });
        this.redirectUser(compteRendu, autoSave);
      },
      error: () => this.messageService.add({
        severity: 'error',
        summary: 'Une erreur est survenue lors de la sauvegarde',
        detail: 'Veuillez essayer à nouveau',
        life: 15000
      }),
    });
  }

  formCancel() {
    this.navigation.back();
  }

  redirectUser(values: any, autoSave = false) {
    let redirectionRoute;
    const firstClientConcerne = values.clientsConcernes[0].value;

    if (autoSave) {
      redirectionRoute = ['/meeting-report-edit', this.meetingReport?.id];
    } else if (firstClientConcerne.clientType === ClientType.NATURAL_PERSON) {
      redirectionRoute = ['/natural-person', firstClientConcerne.id];
    } else {
      redirectionRoute = ['/legal-person', firstClientConcerne.id];
    }

    this.router.navigate(redirectionRoute);
  }

  convertDurationToMinutes(duration: number) {
    const hours = Math.floor(duration) * 60;
    const minutes = (duration - Math.floor(duration)) * 60;

    return hours + minutes;
  }
}
