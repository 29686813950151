<div class="p-3 flex justify-content-center">
  <p-button class="btn-left col-fixed" icon="pi pi-times" label="Annuler" (onClick)="cancel()"></p-button>
  <p-button *ngIf="config.data.displaySave"
    [disabled]="!config.data.canSave"
    [tooltipDisabled]="config.data.canSave"
    pTooltip="Sauvegarde impossible: le formulaire est incomplet"
    tooltipPosition="top"
    class="btn-left col-fixed" icon="pi pi-save" label="Sauvegarder et quitter" (onClick)="save()"></p-button>
  <p-button class="btn-left col-fixed" icon="pi pi-sign-out" label="Quitter sans sauvegarder" (onClick)="noSave()"></p-button>
</div>
