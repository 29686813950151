<div [formGroup]="form">
  <div class="grid p-fluid">
    <div class="field col-2">
      <label for="civility">Civilité</label>
      <p-dropdown
        formControlName="civility"
        id="civility"
        [options]="civilityOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
      <lib-field-error *ngIf="civilityF?.errors" [fieldError]="civilityF?.errors"></lib-field-error>

    </div>
    <div class="field col-3">
      <label for="firstName">Prénom</label>
      <input formControlName="firstName" id="firstName" type="text" pInputText class="inputfield">
      <lib-field-error *ngIf="firstNameF?.errors" [fieldError]="firstNameF?.errors"></lib-field-error>

    </div>
    <div class="field col-3">
      <label for="lastName">Nom</label>
      <input formControlName="lastName" id="lastName" [value]="value?.lastName" type="text" pInputText appUpperCaseInput
             class="inputfield">
      <lib-field-error *ngIf="lastNameF?.errors" [fieldError]="lastNameF?.errors"></lib-field-error>
    </div>

    <div class="field col-3">
      <app-email-form formControlName="email" [conjoint]="true" style="width: 100%;" class="emailComponent"></app-email-form>
      <lib-field-error *ngIf="emailF?.errors" [fieldError]="emailF?.errors"></lib-field-error>
    </div>
  </div>

</div>


