import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
  ClientType,
  DropdownOption,
  DropdownOptionsService,
  Immobilier,
  ImmobilierType,
  LegalPerson, LegalPersonQuery, LegalPersonService,
  NaturalPerson, NaturalPersonQuery, NaturalPersonService,
  DocumentType, NavigationService
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ID} from '@datorama/akita';
import {Subscription} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-recueil-immobilier',
  templateUrl: './recueil-immobilier.component.html',
  styleUrls: ['./recueil-immobilier.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilImmobilierComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilImmobilierComponent),
      multi: true
    }
  ]
})
export class RecueilImmobilierComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @ViewChild('title') title: ElementRef;
  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  documentType = DocumentType;
  client: NaturalPerson|LegalPerson;
  immobilierTypeOptions: DropdownOption<any>[] = [];
  immobilierType = ImmobilierType;
  displaySwitchTypeDialog = false;
  selectedToSwitchType: {index: null|number; type: null|ImmobilierType} = {
    index: null,
    type: null
  };
  collapsedPanels: {collapsed: boolean}[] = [];
  initialControlValue = {
    id: null,
    typeBien: null,
    natureBien: null,
    valeur: null,
    partsDetenues: null,
    detenteur: null,
    modeDetention: null,
    address: null,
    credit: false,
    typeCredit: null,
    souscripteur: null,
    genereRevenus: false,
    revenusAnnuels: null,
    detenuSciOrScOrSarl: false,
    companyName: null,
    attestNotarial: null,
    taxeFonciere: null,
    titrePropriete: null,
    statutSci: null,
    offrePret: null,
  };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dropdownOptionsService: DropdownOptionsService,
    public naturalPersonQuery: NaturalPersonQuery,
    public legalPersonQuery: LegalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private legalPersonService: LegalPersonService,
    private navigation: NavigationService,) {}

  get immobilierControl() {
    return this.form.get('bienImmobiliers') as UntypedFormArray;
  }
  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      bienImmobiliers: this.formBuilder.array([]),
    });
    if(this.clientOrAdmin === 'ADMIN'){
      this.form.addControl('comConseilleBienImmobilier', this.formBuilder.control(null));
    }
    if(this.clientOrAdmin === 'CLIENT'){
      this.form.addControl('comBienImmobilier', this.formBuilder.control(null));
    }
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.immobilierTypeOptions = this.dropdownOptionsService.immobilierType;

  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value.bienImmobiliers) {
      this.collapsedPanels = [];
      while (this.immobilierControl.length !== 0) {
        this.immobilierControl.removeAt(0);
      }
      value.bienImmobiliers.map((val: any)=> {
        this.immobilierControl.push(this.formBuilder.control({}));
        this.collapsedPanels = [...this.collapsedPanels, {collapsed: true}];
      });
      this.value = value;
      this.form.markAsPristine();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
  add(type: ImmobilierType){
    this.immobilierControl.push(this.formBuilder.control({...this.initialControlValue, typeBien: type}));
    this.collapsedPanels.map(panel=>panel.collapsed = true);
    this.collapsedPanels = [...this.collapsedPanels, {collapsed: false}];
    this.navigation.scrollToTop(this.title.nativeElement.offsetTop);
  }

  openTypeDialog(index: number){
    this.displaySwitchTypeDialog = true;
    this.selectedToSwitchType = {
      index,
      type:null
    };
  }

  switchType(){
    if(this.selectedToSwitchType.index !== null){
      this.immobilierControl.controls[this.selectedToSwitchType.index].patchValue(
        {...this.immobilierControl.controls[this.selectedToSwitchType.index].value, typeBien:this.selectedToSwitchType.type}
      );
    }
    this.displaySwitchTypeDialog = false;
  }

  remove(index: number){
    this.immobilierControl.removeAt(index);
  }

  doesHaveMainType(){
    let haveMainType = false;
    this.immobilierControl.value.map((immobilier: Immobilier) => {
      if (immobilier.typeBien === this.immobilierType.IMMO_TYPE_PRINCIPALE){
        haveMainType = true;
      }
    });
    return haveMainType;
  }

  countImmoByType(type: ImmobilierType){
    return this.immobilierControl.value.filter((immobilier: Immobilier) => immobilier.typeBien === type).length;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientType'] && changes['clientId']){
      if (changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.naturalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
          if(naturalPerson){
            this.client = naturalPerson;
          }
        });
      }
      if (changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.legalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(legalPerson => {
          if(legalPerson){
            this.client = legalPerson;
          }
        });
      }
    }

  }

}
