<link rel="stylesheet" href="page-password-forgotten.component.scss">
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
    <div class="flex flex-column mt-8">
      <img src="assets/recueil/MASTER-LOGO-EMD.png" class="loader-logo-emd-mobile mx-auto" alt="logoEMD/" >
      <h1 class="rec mx-auto text-5xl">TIM</h1>
    </div>
    <form class="grid grid-nogutter flex-column justify-content-center align-items-center w-full mb-8" [formGroup]="form" (ngSubmit)="onSubmit()">
      <h2 class="rec">Mot de passe oublié</h2>
      <div class="field col-6 mt-3">
        <label for="email-input">Email de votre compte :</label>
        <input formControlName="email" id="email-input" type="text" size="30" pInputText class="inputfield w-full">
        <lib-field-error *ngIf="emailF?.touched && emailF?.errors" [fieldError]="emailF?.errors"></lib-field-error>
      </div>
      <div class="field col-6 mt-3">
        <button pButton [loading]="loading" type="submit" icon="pi pi-send" class="mt-3 p-button-raised rec w-full" label="Envoyer le lien">
        </button>
      </div>
      <a class="mt-4" href="/login">Retourner à la page de connexion</a>
    </form>
</ng-container>
<ng-template #desktop>
  <div class="grid rec mr-0">
    <div id="imgDiv" class="col-6 col-sm-12 p-7 pt-2 pb-4 right-panel flex flex-column align-items-center">
      <img src="assets/recueil/00-accueil-background.jpg" alt="backgroundBienvenue/" class="backgroundImg">
      <img src="assets/recueil/00-accueil-txt.svg" alt="backgroundBienvenue/" class="textImg">
    </div>
    <div class="col-6 col-sm-12 right-panel flex flex-column justify-content-center align-items-center">
      <img src="assets/recueil/MASTER-LOGO-EMD.png" class="mb-5" height="153px" alt="logoEMD/">
      <h2 class="rec">Mot de passe oublié</h2>
      <p style="text-align: center">
        Renseignez votre email pour obtenir un lien de <br> réinitialisation de mot de passe.
      </p>
      <form class="grid grid-nogutter flex-column justify-content-center align-items-center w-full" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="field col-6 mt-3">
          <label for="email-input">Email :</label>
          <input formControlName="email" id="email-input" type="text" size="30" pInputText class="inputfield w-full">
          <lib-field-error *ngIf="emailF?.touched && emailF?.errors" [fieldError]="emailF?.errors"></lib-field-error>
        </div>
        <div class="field col-6 mt-3">
          <button pButton
                  [loading]="loading"
                  type="submit"
                  icon="pi pi-send"
                  label="Envoyer le lien"
                  class="mt-3 p-button-raised rec w-full">
          </button>
        </div>
        <a class="mt-4" href="/login">Retourner à la page de connexion</a>
      </form>
    </div>
  </div>
</ng-template>
