import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FormGuardDialogComponent} from '../ui/form-guard-dialog/form-guard-dialog.component';

export interface FormGuardInterface {

  // Message par defaut
  alertMessage?: string;
  // Autorise l'affichage du bouton sauvegarder
  displaySave?: boolean;
  // Autorise le composant a leave (car pas de modif par exemple)
  canLeave: boolean;
  // Le ou les form sont valid on peut sauvegarder
  canSave: boolean;

  // Fonction pour soumettre le form puis naviguer si besoin
  submitForm(navigate: boolean): void;
}

@Injectable({
  providedIn: 'root'
})
export class FormGuard {

  ref: DynamicDialogRef;
  defaultValues: any;
  constructor(
    public dialogService: DialogService
  ) {
    this.defaultValues = {
      alertMessage : 'Etes vous sûr de vouloir quitter?',
      displaySave: true,
    };
  }

  getAlertMessage(options?: string): string {
    return options !== undefined ?  options : this.defaultValues.alertMessage;
  }

  getDisplaySave(options?: boolean): string {
    return options !== undefined ?  options : this.defaultValues.displaySave;
  }

  canDeactivate(
    component: FormGuardInterface,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
    const subject = new Subject<boolean>();
    console.log('FORMGUARD canLeave',component.canLeave);
    console.log('FORMGUARD canSave',component.canSave);
    console.log('FORMGUARD displaySave',component.displaySave);
    if(!component.canLeave){
      this.ref = this.dialogService.open(FormGuardDialogComponent, {
        header: this.getAlertMessage(component.alertMessage),
        width: '70%',
        contentStyle: {overflow: 'auto'},
        baseZIndex: 10000,
        data: {canSave:component.canSave, displaySave: this.getDisplaySave(component.displaySave)}
      });

      this.ref.onClose.subscribe((choice: string) => {
        switch (choice) {
          case 'cancel':
            subject.next(false);
            break;
          case 'save':
            component.submitForm(false);
            subject.next(true);
            break;
          case 'noSave':
            subject.next(true);
            break;
          default:
            subject.next(false);
            break;
        }
      });

    return subject.asObservable();
  } else {
    return true;
  }
}}
