<ng-template #content>
  <div class="page-base flex">
    <div class="flex flex-column flex-1 w-full">
      <div class="top-bloc flex" *ngIf="displayHeaderByRoute">
        <app-header></app-header>
      </div>
      <div class="content-bloc flex flex-column flex-1 overflow-auto">
        <p-scrollPanel #mainPanel [style]="{width: '100%', height: '100%'}" styleClass="custom-scroll-panel">
          <div class="custom-content" [ngClass]="displayHeaderByRoute ? 'w-11 mx-auto mt-5 main-content': ''">
            <router-outlet></router-outlet>
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="isLoading && isLogged else content">
  <app-loader></app-loader>
</div>

<p-toast *ngIf="displayToastByRoute" position="top-right"></p-toast>

