import {Inject, Injectable} from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { AddressStore, AddressState } from './address.store';

@Injectable({ providedIn: 'root' })
export class AddressService extends NgEntityService<AddressState> {

  constructor(
    @Inject('config') public override config: any,
    protected override store: AddressStore
  ) {
    super(store);
  }

}
