import localeFr from '@angular/common/locales/fr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule, CustomRouteReuseStrategy } from './app-routing.module';
import { AppComponent } from './app.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { PageLoginComponent } from './pages/user/page-login/page-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { CommonModule as CommonModuleXtl, UiModule, JwtInterceptor, ErrorInterceptor} from 'common';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PageUserListComponent } from './pages/admin/page-user-list/page-user-list.component';
import { PageUserNewComponent } from './pages/admin/page-user-new/page-user-new.component';
import { PageActivateAccountComponent } from './pages/user/page-activate-account/page-activate-account.component';
import { PagePasswordForgottenComponent } from './pages/user/page-password-forgotten/page-password-forgotten.component';
import { DialogService } from 'primeng/dynamicdialog';
import { PageDashboardComponent } from './pages/page-dashboard/page-dashboard.component';
import { PageNaturalPersonComponent } from './pages/client/page-natural-person/page-natural-person.component';
import { NaturalNaturalRelationFormComponent } from './components/form/natural-natural-relation-form/natural-natural-relation-form.component';
import { NaturalLegalRelationFormComponent } from './components/form/natural-legal-relation-form/natural-legal-relation-form.component';
import { PageLegalPersonNewComponent } from './pages/client/page-legal-person-new/page-legal-person-new.component';
import { LegalPersonFormComponent } from './components/form/legal-person-form/legal-person-form.component';
import { AddressFormComponent } from './components/form/address-form/address-form.component';
import { PageMeetingReportNewComponent } from './pages/page-meeting-report-new/page-meeting-report-new.component';
import { CreateMeetingReportFormComponent } from './components/create-meeting-report-form/create-meeting-report-form.component';
import { EditorModule } from 'primeng/editor';
import { PageMeetingReportEditComponent } from './pages/page-meeting-report-edit/page-meeting-report-edit.component';
import { PageClientSearchComponent } from './pages/client/page-client-search/page-client-search.component';
import { AngularDateHttpInterceptor } from './interceptors/date.interceptor';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { PageMeetingReportComponent } from './pages/page-meeting-report/page-meeting-report.component';
import { PageNaturalPersonNewComponent } from './pages/client/page-natural-person-new/page-natural-person-new.component';
import { PageLegalPersonComponent } from './pages/client/page-legal-person/page-legal-person.component';
import { akitaConfig, persistState } from '@datorama/akita';
import { PageTaskCustomerComponent } from './pages/tim/page-task-customer/page-task-customer.component';
import { PageStepsMasterListComponent } from './pages/admin/page-steps-master-list/page-steps-master-list.component';
import { PageStepsMasterComponent } from './pages/admin/page-steps-master/page-steps-master.component';
import { PageTaskInternalComponent } from './pages/tim/page-task-internal/page-task-internal.component';
import { PageProductListComponent } from './pages/admin/page-product-list/page-product-list.component';
import { ProductFormComponent } from './components/form/product-form/product-form.component';
import { PageProductComponent } from './pages/admin/page-product/page-product.component';
import { PageNewClientStepComponent } from './pages/tim/page-new-client-step/page-new-client-step.component';
import { TaskImportancePipe } from './pipes/task-importance.pipe';
import { ImportanceInputComponent } from './components/importance-input/importance-input.component';
import { CloseTaskModalComponent } from './components/close-task-modal/close-task-modal.component';
import { SortBydatePipe } from './pipes/sort-bydate.pipe';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { PageTimelineComponent } from './pages/page-timeline/page-timeline.component';
import {TieredMenuModule} from 'primeng/tieredmenu';
import { PageMondayReviewComponent } from './pages/page-monday-review/page-monday-review.component';
import { MinutesToDurationPipe } from './pipes/minutes-to-duration.pipe';
import { RouteReuseStrategy } from '@angular/router';
import { DesktopOnlyComponent } from './components/desktop-only/desktop-only.component';
import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import { UiSummaryComponent } from './pages/ui-summary/ui-summary.component';
import {PanelModule} from 'primeng/panel';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ImmobilierFormComponent } from './components/form/immobilier-form/immobilier-form.component';
import { RecueilAdminComponent } from './components/recueil/recueil-admin/recueil-admin.component';
import { CommentFormComponent } from './components/form/comment-form/comment-form.component';
import { CommentReadComponent } from './components/comment-read/comment-read.component';
import { RecueilImmobilierComponent } from './components/recueil/recueil-immobilier/recueil-immobilier.component';
import { RecueilObjectifComponent } from './components/recueil/recueil-objectif/recueil-objectif.component';
import { RecueilFinancierComponent } from './components/recueil/recueil-financier/recueil-financier.component';
import { FinancierFormComponent } from './components/form/financier-form/financier-form.component';
import { BienDiversFormComponent } from './components/form/bien-divers-form/bien-divers-form.component';
import { CreditFormComponent } from './components/form/credit-form/credit-form.component';
import { ImpotFormComponent } from './components/form/impot-form/impot-form.component';
import { DonationFormComponent } from './components/form/donation-form/donation-form.component';
import { RecueilBienDiversComponent } from './components/recueil/recueil-bien-divers/recueil-bien-divers.component';
import { RecueilAutreComponent } from './components/recueil/recueil-autre/recueil-autre.component';
import { RecueilRecapComponent } from './components/recueil/recueil-recap/recueil-recap.component';
import { RecueilInformationComponent } from './components/recueil/recueil-information/recueil-information.component';
import { InfoCivileFormComponent } from './components/form/info-civile-form/info-civile-form.component';
import { SituationProFormComponent } from './components/form/situation-pro-form/situation-pro-form.component';
import { SituationMatrimonialeFormComponent } from './components/form/situation-matrimoniale-form/situation-matrimoniale-form.component';
import {TimelineModule} from 'primeng/timeline';
import { GedReadComponent } from './components/ged/ged-read/ged-read.component';
import { EtrangerFormComponent } from './components/form/etranger-form/etranger-form.component';
import { CelebreFormComponent } from './components/form/celebre-form/celebre-form.component';
import { ContactFormComponent } from './components/form/contact-form/contact-form.component';
import { PhoneFormComponent } from './components/form/phone-form/phone-form.component';
import { EmailFormComponent } from './components/form/email-form/email-form.component';
import { PrevoyanceFormComponent } from './components/form/prevoyance-form/prevoyance-form.component';
import { PageBienDiversListComponent } from './pages/admin/page-bien-divers-list/page-bien-divers-list.component';
import { NoteComponent } from './components/note/note.component';
import { PageObjectifListComponent } from './pages/admin/page-objectif-list/page-objectif-list.component';
import { InfoCabinetFormComponent } from './components/form/info-cabinet-form/info-cabinet-form.component';
import { PageAdministrableTextListComponent } from './pages/admin/page-administrable-text-list/page-administrable-text-list.component';
import { PageNewNaturalPersonsListComponent } from './pages/admin/page-new-natural-persons-list/page-new-natural-persons-list.component';
import {PageRecueilClientComponent} from './pages/recueil/page-recueil-client/page-recueil-client.component';
import {PresentationFormComponent} from './components/form/presentation-form/presentation-form.component';
import {QuestionFiltreFormComponent} from './components/form/question-filtre-form/question-filtre-form.component';
import {RadioInputComponent} from './components/form/radio-input/radio-input.component';
import { DocumentsFormComponent } from './components/form/documents-form/documents-form.component';
import { PageRecueilClientBienvenueComponent } from './pages/recueil/page-recueil-client-bienvenue/page-recueil-client-bienvenue.component';
import { ConjointFormComponent } from './components/form/conjoint-form/conjoint-form.component';
import { RecueilConjointComponent } from './components/recueil/recueil-conjoint/recueil-conjoint.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HelpTooltipComponent } from './components/help-tooltip/help-tooltip.component';
import { PageRecueilConjointComponent } from './pages/recueil/page-recueil-conjoint/page-recueil-conjoint.component';
import { RecueilNaturalPersonComponent } from './components/recueil/recueil-natural-person/recueil-natural-person.component';
import { RecueilAChargeComponent } from './components/recueil/recueil-a-charge/recueil-a-charge.component';
import { EnfantPersonnesAChargeFormComponent } from './components/form/enfant-personnes-a-charge-form/enfant-personnes-a-charge-form.component';
import { RecueilFinalComponent } from './components/recueil/recueil-final/recueil-final.component';
import { ErrorComponent } from './components/error/error.component';
import { UpperCaseInputDirective } from './directives/upper-case-input.directive';

registerLocaleData(localeFr, 'fr');
const storage = persistState({ include: ['session', 'configurations', 'uiScreen'] });
akitaConfig({ resettable: true });
@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    HeaderComponent,
    MenuComponent,
    PageUserListComponent,
    PageUserNewComponent,
    PageActivateAccountComponent,
    PagePasswordForgottenComponent,
    PageNaturalPersonComponent,
    PagePasswordForgottenComponent,
    PageDashboardComponent,
    NaturalNaturalRelationFormComponent,
    NaturalLegalRelationFormComponent,
    PageDashboardComponent,
    PageLegalPersonNewComponent,
    LegalPersonFormComponent,
    AddressFormComponent,
    PageMeetingReportNewComponent,
    CreateMeetingReportFormComponent,
    PageMeetingReportEditComponent,
    PageClientSearchComponent,
    PageMeetingReportComponent,
    PageNaturalPersonNewComponent,
    PageLegalPersonComponent,
    PageTaskInternalComponent,
    PageTaskCustomerComponent,
    PageStepsMasterListComponent,
    PageStepsMasterComponent,
    PageTaskInternalComponent,
    PageProductListComponent,
    ProductFormComponent,
    PageProductComponent,
    PageNewClientStepComponent,
    TaskImportancePipe,
    ImportanceInputComponent,
    CloseTaskModalComponent,
    SortBydatePipe,
    LinkifyPipe,
    UiSummaryComponent,
    PageMondayReviewComponent,
    MinutesToDurationPipe,
    DesktopOnlyComponent,
    FileUploaderComponent,
    ImmobilierFormComponent,
    RecueilAdminComponent,
    CommentFormComponent,
    CommentReadComponent,
    RecueilImmobilierComponent,
    RecueilObjectifComponent,
    RecueilFinancierComponent,
    FinancierFormComponent,
    BienDiversFormComponent,
    CreditFormComponent,
    ImpotFormComponent,
    DonationFormComponent,
    RecueilBienDiversComponent,
    RecueilAutreComponent,
    RecueilRecapComponent,
    RecueilInformationComponent,
    InfoCivileFormComponent,
    SituationProFormComponent,
    SituationMatrimonialeFormComponent,
    PageTimelineComponent,
    GedReadComponent,
    EtrangerFormComponent,
    CelebreFormComponent,
    ContactFormComponent,
    PhoneFormComponent,
    EmailFormComponent,
    PrevoyanceFormComponent,
    PageBienDiversListComponent,
    NoteComponent,
    PageObjectifListComponent,
    InfoCabinetFormComponent,
    PageAdministrableTextListComponent,
    PageNewNaturalPersonsListComponent,
    PageRecueilClientComponent,
    PresentationFormComponent,
    QuestionFiltreFormComponent,
    RadioInputComponent,
    DocumentsFormComponent,
    PageRecueilClientBienvenueComponent,
    ConjointFormComponent,
    RecueilConjointComponent,
    LoaderComponent,
    PageRecueilConjointComponent,
    HelpTooltipComponent,
    RecueilNaturalPersonComponent,
    RecueilAChargeComponent,
    EnfantPersonnesAChargeFormComponent,
    RecueilFinalComponent,
    ErrorComponent,
    UpperCaseInputDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModuleXtl.forRoot(environment, storage),
    FormsModule,
    UiModule,
    EditorModule,
    FieldsetModule,
    TableModule,
    UiModule,
    TranslateModule,
    TieredMenuModule,
    AccordionModule,
    ButtonModule,
    PanelModule,
    TimelineModule
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    // { provide: 'persistStorage', useValue: storage },
    DialogService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ],
})
export class AppModule {}
