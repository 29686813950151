import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { RecueilStore, RecueilState } from './recueil.store';

@Injectable({ providedIn: 'root' })
export class RecueilQuery extends QueryEntity<RecueilState> {

  constructor(protected override store: RecueilStore) {
    super(store);
  }

}
