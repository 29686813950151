import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {
  NaturalPerson,
  NaturalPersonQuery,
  TranslatorService,
  NaturalPersonService,
  CompteRendu,
  CompteRenduService,
  CompteRenduQuery,
  UserService,
  UserQuery,
  User,
  NaturalLegalRelation,
  ClientType,
  PoleInterne,
  TaskStatus,
  Step,
  DashboardFiltersType,
  DropdownOption,
  StepAndTaskOptionValue,
  PoleAndCollaboratorOptionValue,
  StepQuery,
  SessionQuery,
  DropdownOptionsService,
  Task,
  TaskHelperService,
  Phone,
  Email,
  DashboardType,
  StepService,
  UiScreenQuery,
  FormGuardInterface,
  Immobilier,
  AvoirFinancier,
  NaturalPersonStore,
  DocumentHelperService,
  RecueilHelperService,
  ClientStatus,
  NavigationService,
  DocsToAddQuery,
  DocsToDeleteQuery,
  NaturalNaturalRelationTypeEnums,
  AChargeFiscale
} from 'common';
import {FilterService, MenuItem, MessageService} from 'primeng/api';
import {
  NaturalNaturalRelationFormComponent
} from '../../../components/form/natural-natural-relation-form/natural-natural-relation-form.component';
import {
  NaturalLegalRelationFormComponent
} from '../../../components/form/natural-legal-relation-form/natural-legal-relation-form.component';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Table} from 'primeng/table';
import {Dropdown} from 'primeng/dropdown';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as moment from 'moment';
import {debounceTime, combineLatest, interval} from 'rxjs';
import {UserRole} from 'common';

// Object shape added to task to allow filtering by step and task name.
type AddonFilterStepAndTaskName = {
  relatedStep: string;
  taskName: string;
};

// Object shape added to task to allow filtering by pole / collaborator / my tasks.
type AddonFilterPoleAndCollaborator = {
  pole: PoleInterne;
  collaborator?: User;
  isPriority: boolean;
  status: TaskStatus;
};

interface CurrentStepsDashboardTask extends Task {
  step?: Step;
  stepAndTaskName?: AddonFilterStepAndTaskName;
  poleAndCollaborator?: AddonFilterPoleAndCollaborator;
}

@UntilDestroy()
@Component({
  selector: 'app-page-natural-person',
  templateUrl: './page-natural-person.component.html',
  styleUrls: ['./page-natural-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNaturalPersonComponent implements OnInit, FormGuardInterface, AfterViewChecked {
  @ViewChild(NaturalNaturalRelationFormComponent, {static: false}) naturalNaturalRelationForm!: NaturalNaturalRelationFormComponent;
  @ViewChild(NaturalLegalRelationFormComponent, {static: false}) naturalLegalRelationForm!: NaturalLegalRelationFormComponent;
  @ViewChild('currentStepsTable', {static: false}) currentStepsTable!: Table;
  @ViewChild('historicalTable', {static: false}) historicalTable!: Table;
  @ViewChild('meetingReportTable', {static: false}) meetingReportTable!: Table;
  @ViewChild('filterStepsCurrentSteps', {static: false}) filterStepsCurrentSteps!: Dropdown;
  @ViewChild('filterTasksCurrentSteps', {static: false}) filterTasksCurrentSteps!: Dropdown;
  @ViewChild('filterCollaboratorAndPoleCurrentSteps', {static: false}) filterCollaboratorAndPoleCurrentSteps!: Dropdown;
  @ViewChild('filterPoleHistorical', {static: false}) filterPoleHistorical!: Dropdown;
  @ViewChild('filterCollaboratorMeetingReport', {static: false}) filterCollaboratorMeetingReport!: Dropdown;

  isLoading = true;
  submitLoading = false;
  naturalPerson?: NaturalPerson;
  naturalPersonCurrentStepsTasks!: [] | CurrentStepsDashboardTask[];
  naturalPersonHistoricalTasks!: [] | Task[];
  naturalPersonHasRegisteredPhone = false;
  naturalPersonHasRegisteredMail = false;
  naturalPersonHasRegisteredAdresse = false;
  dashboardType = DashboardType; // Reference for use in HTML.
  dashboardFiltersType = DashboardFiltersType; // Reference for use in HTML.
  compteRendus!: [] | CompteRendu[];
  showEditNaturalPersonModal = false;
  showAddNaturalNaturalRelationModal = false;
  showAddNaturalLegalRelationModal = false;
  showStatusConfirmModal = false;
  changeStatusConfirmed = false;
  showGeneralCommentEdit = false;
  showDeleteConfirmModal = false;
  generalComment? = '';
  defaultPhoneType!: string;
  defaultAddressType!: string;
  defaultEmailType!: string;
  headerMenuitems!: MenuItem[];
  naturalRelationitems!: MenuItem[];
  legalRelationitems!: MenuItem[];
  activeRelation: any;
  taskStatus = TaskStatus; // Reference for use in HTML.
  displayCloseDialog = false;
  closingTask: any = null;
  stepsOptions!: DropdownOption<any>[];
  stepsTasksOption!: DropdownOption<any>[];
  stepFilterRelatedTaskOption!: DropdownOption<any>[];
  poleAndCollaboratorOptions!: DropdownOption<any>[];
  collaboratorOptions!: DropdownOption<any>[];
  clientStatus = ClientStatus;
  aChargeFiscale = AChargeFiscale;
  filterForm = this.formBuilder.group({
    stepFilterCurrentSteps: [],
    stepTaskFilterCurrentSteps: [],
    poleAndCollaboratorFilterCurrentSteps: [],
    poleFilterHistorical: [],
    collaboratorMeetingReport: [],
  });
  form = this.formBuilder.group({
    informations: this.formBuilder.control({
      infoCivile: this.formBuilder.control({}),
      comConseilleInformation: this.formBuilder.control(null),
      situationMatrimoniale: this.formBuilder.control({}),
      situationPro: this.formBuilder.control({}),
      etranger: this.formBuilder.control({}),
      celebre: this.formBuilder.control({}),
      contact: this.formBuilder.control({}),
    }),
    infoCabinet: this.formBuilder.control({
      mainInterlocutor: this.formBuilder.control({}),
      secondaryInterlocutor: this.formBuilder.control({}),
      dateEntreeEnRelation: this.formBuilder.control({}),
      customerOrigin: this.formBuilder.control({}),
      customerStatus: this.formBuilder.control({}),
      segmentation: this.formBuilder.control({}),
    }),
    recueil: this.formBuilder.control({
      objectif: this.formBuilder.control({}),
      immobilier: this.formBuilder.control({
        bienImmobiliers: this.formBuilder.control({}),
        comConseilleBienImmobilier: this.formBuilder.control(null),
      }),
      financier: this.formBuilder.control({
        avoirFinanciers: this.formBuilder.control({}),
        comConseilleAvoirFinancier: this.formBuilder.control(null),
      }),
      bienDivers: this.formBuilder.control({
        bienDivers: this.formBuilder.control({}),
        autreBienDivers: this.formBuilder.control({}),
        comConseilleBienDivers: this.formBuilder.control(null),
      }),
      autre: this.formBuilder.control({
        creditNonImmobiliers: this.formBuilder.control({}),
        comConseilleRevenuFiscaux: this.formBuilder.control(null),
        comConseilleDonation: this.formBuilder.control(null),
        impot: this.formBuilder.control({
          trancheImpots: this.formBuilder.control({}),
          assujettiIfi: this.formBuilder.control({}),
        }),
        donation: this.formBuilder.control({
          realiseDonation: this.formBuilder.control({}),
          recuDonation: this.formBuilder.control({}),
          redigeTestament: this.formBuilder.control({})
        }),
      }),
    })
  });
  tableCustomerCurrentStorageKey!: string;
  tableCustomerHistoricalStorageKey!: string;
  clientType = ClientType;
  activePanelIndex: number;
  canLeave = true;
  canSave = true;
  generateRecueilPdfLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public naturalPersonQuery: NaturalPersonQuery,
    private messageService: MessageService,
    public translatorService: TranslatorService,
    private compteRenduService: CompteRenduService,
    private compteRenduQuery: CompteRenduQuery,
    private sessionQuery: SessionQuery,
    private userService: UserService,
    private userQuery: UserQuery,
    private naturalPersonService: NaturalPersonService,
    private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    private stepQuery: StepQuery,
    private dropdownOptionsService: DropdownOptionsService,
    public uiScreenQuery: UiScreenQuery,
    private taskHelperService: TaskHelperService,
    private stepService: StepService,
    private recueilHelperService: RecueilHelperService,
    private httpClient: HttpClient,
    @Inject('config') private config: any,
    private naturalPersonStore: NaturalPersonStore,
    private documentHelperService: DocumentHelperService,
    private navigation: NavigationService,
    private docsToAddQuery: DocsToAddQuery,
    private docsToDeleteQuery: DocsToDeleteQuery,
    private cdref: ChangeDetectorRef
  ) {
  }

  get recueilF() {
    return this.form.get('recueil') as UntypedFormControl;
  }

  get immobilierF() {
    return this.recueilF.get('immobilier') as UntypedFormGroup;
  }

  get bienImmobiliersF() {
    return this.immobilierF.get('bienImmobiliers') as UntypedFormArray;
  }

  setStepFilter(filterValue: StepAndTaskOptionValue) {
    this.filterForm.get('stepFilter')?.setValue(filterValue);
    this.currentStepsTable.filter(filterValue, 'step', 'containsStep');
  }

  setStepTaskFilter(filterValue: StepAndTaskOptionValue) {
    this.filterForm.get('stepTaskFilter')?.setValue(filterValue);
    this.currentStepsTable.filter(filterValue, 'name', 'containsStepTask');
  }

  setCollaboratorAndPoleFilter(filterValue: PoleAndCollaboratorOptionValue, table: DashboardType) {
    if (table === DashboardType.CURRENT) {
      if (filterValue !== null) {
        this.filterForm.get('poleAndCollaboratorFilterCurrentSteps')?.setValue({
          id: filterValue.id,
          type: filterValue.type
        });
        this.currentStepsTable.filter({
          id: filterValue.id,
          type: filterValue.type
        }, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      } else {
        this.filterForm.get('poleAndCollaboratorFilterCurrentSteps')?.setValue(null);
        this.currentStepsTable.filter(null, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      }
    }
    if (table === DashboardType.HISTORICAL) {
      if (filterValue !== null) {
        this.filterForm.get('poleFilterHistorical')?.setValue({id: filterValue.id, type: filterValue.type});
        this.historicalTable.filter({
          id: filterValue.id,
          type: filterValue.type
        }, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      } else {
        this.filterForm.get('poleFilterHistorical')?.setValue(null);
        this.historicalTable.filter(null, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      }
    }
  }

  setCollaboratorFilter(filterValue: StepAndTaskOptionValue) {
    this.filterForm.get('collaboratorMeetingReport')?.setValue(filterValue);
    this.meetingReportTable.filter(filterValue, 'collaboratorPresent', 'containsCollaborator');
  }

  ngOnInit(): void {
    this.getNaturalPersonInformation();
    this.setDropdownOptions();
    this.setTableFilters();
    this.setRelationMenu();
    this.setHeaderMenu();
  }

  ngAfterViewChecked(): void {
    // this.cdref.detectChanges();
    this.cdref.markForCheck();
  }

  onDashboardStateRestore(event: any, dashboardType: DashboardType) {
    if (event.filters) {
      if (event.filters.step) {
        this.filterForm.get('stepFilterCurrentSteps')?.setValue(event.filters.step.value);
      }
      if (event.filters.name) {
        this.filterForm.get('stepTaskFilterCurrentSteps')?.setValue(event.filters.name.value);
      }
      if (event.filters.poleAndCollaborator && dashboardType === DashboardType.CURRENT) {
        this.filterForm.get('poleAndCollaboratorFilterCurrentSteps')?.setValue(event.filters.poleAndCollaborator.value);
      }
      if (event.filters.poleAndCollaborator && dashboardType === DashboardType.HISTORICAL) {
        this.filterForm.get('poleFilterHistorical')?.setValue(event.filters.poleAndCollaborator.value);
      }
    }
  }

  getNaturalPersonInformation() {
    this.route.params.subscribe(params => {
      const id = +params['id'];

      this.tableCustomerCurrentStorageKey = 'dashboard_customer_natural_current_' + params['id'];
      this.tableCustomerHistoricalStorageKey = 'dashboard_customer_natural_historical_' + params['id'];

      this.naturalPersonService.get(id, {upsert: true}).pipe(untilDestroyed(this)).subscribe();
      this.naturalPersonQuery.selectEntity(id).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
        if (naturalPerson && naturalPerson.hasOwnProperty('addresses')) {
          this.naturalPerson = naturalPerson;
          this.naturalPersonHasRegisteredPhone = !!(this.naturalPerson?.phones && this.naturalPerson?.phones.length > 0);
          this.naturalPersonHasRegisteredMail = !!(this.naturalPerson?.emails && this.naturalPerson?.emails.length > 0);
          this.naturalPersonHasRegisteredAdresse = !!(this.naturalPerson?.addresses && this.naturalPerson?.addresses.length > 0);

          /* eslint-disable */
          this.stepService.get({
            params: {'naturalPersons': this.naturalPerson?.id}, upsert: true
          }).subscribe();
          // /* eslint-enable */
          this.initForm();
          this.naturalNaturalRelationForm?.createFormGroup();
          this.isLoading = false;
          this.cdref.markForCheck();

          this.generalComment = this.naturalPerson.generalComment;

          if (this.naturalPerson.compteRendus) {
            this.compteRendus = this.naturalPerson.compteRendus.map((report) => ({
              ...report,
              mainCollaborator: report.collaboratorPresent[0]
            })).sort((reportA, reportB) => this.abstractSorter(reportA.meetingDate, reportB.meetingDate, 'DESC'));
          } else {
            this.compteRendus = [];
          }

          // Get natural person related steps.
          this.stepQuery.selectAll({
            filterBy: [
              (entity) => entity.naturalPersons !== undefined && entity.naturalPersons.some((person) => person.id === this.naturalPerson?.id)
            ]
          }).pipe(untilDestroyed(this)).subscribe((steps) => {
            if (steps) {
              this.naturalPersonCurrentStepsTasks = this.addPropertiesToTasks(this.getNaturalPersonCurrentStepsTasks(steps));
              this.naturalPersonHistoricalTasks = this.addPropertiesToTasks(this.getNaturalPersonHistoricalTasks(steps));
            }
          });
        }
      });
    });

    /* Get default email, phone and address type for TIM V1 */
    this.defaultAddressType = 'Correspondance O2S';
    this.defaultEmailType = 'Principal';
    this.defaultPhoneType = 'Correspondance O2S';
  }

  initForm() {
    this.form.reset({
      informations: {
        infoCivile: {
          civility: this.naturalPerson?.civility,
          lastName: this.naturalPerson?.lastName,
          firstName: this.naturalPerson?.firstName,
          birthName: this.naturalPerson?.birthName,
          birthDate: this.naturalPerson?.birthDate ? new Date(this.naturalPerson?.birthDate) : null,
          birthCity: this.naturalPerson?.birthCity,
          birthState: this.naturalPerson?.birthState,
          birthCountry: this.naturalPerson?.birthCountry,
          nationality: this.naturalPerson?.nationality,
          otherNationality: this.naturalPerson?.otherNationality,
          mesureProtection: this.naturalPerson?.mesureProtection,
          handicap: this.naturalPerson?.handicap,
          dead: this.naturalPerson?.dead,
          deathDate: this.naturalPerson?.deathDate ? new Date(this.naturalPerson?.deathDate) : null,
        },
        situationMatrimoniale: {
          situationMatrimoniale: this.naturalPerson?.situationMatrimoniale,
          precedentMariage: this.naturalPerson?.precedentMariage,
          dateMariage: this.naturalPerson?.dateMariage ? new Date(this.naturalPerson?.dateMariage) : null,
          lieuMariage: this.naturalPerson?.lieuMariage,
          regimeMatrimonial: this.naturalPerson?.regimeMatrimonial,
          regimePacsimonial: this.naturalPerson?.regimePacsimonial,
          donationDernierVivant: this.naturalPerson?.donationDernierVivant,
          sensDonation: this.naturalPerson?.sensDonation,
          testamentCroise: this.naturalPerson?.testamentCroise,
          sensTestamentCroise: this.naturalPerson?.sensTestamentCroise,
        },
        situationPro: {
          situationPro: this.naturalPerson?.situationPro,
          currentJob: this.naturalPerson?.currentJob,
          jobCompany: this.naturalPerson?.jobCompany,
          revenusAnnuel: this.naturalPerson?.revenusAnnuel,
          rentesAnnuelle: this.naturalPerson?.rentesAnnuelle,
        },
        comConseilleInformation: this.naturalPerson?.recueil?.comConseilleInformation ? this.naturalPerson?.recueil?.comConseilleInformation : null,
        etranger: {
          usPerson: this.naturalPerson?.usPerson,
          vecuEtrange: this.naturalPerson?.vecuEtrange,
          vecuEtrangeDixAns: this.naturalPerson?.vecuEtrangeDixAns,
        },
        celebre: {
          politiquementExpose: this.naturalPerson?.politiquementExpose,
          prochePolitiquementExpose: this.naturalPerson?.prochePolitiquementExpose,
        },
        contact: {
          phones: this.naturalPerson?.phones,
          emails: this.naturalPerson?.emails,
          addresses: this.naturalPerson?.addresses,
          rentPrincipalResidence: this.naturalPerson?.rentPrincipalResidence,
          loyerAnnuel: this.naturalPerson?.loyerAnnuel,
        }
      },
      infoCabinet: {
        mainInterlocutor: this.naturalPerson?.mainInterlocutor,
        secondaryInterlocutor: this.naturalPerson?.secondaryInterlocutor,
        dateEntreeEnRelation: this.naturalPerson?.dateEntreeEnRelation ? new Date(this.naturalPerson?.dateEntreeEnRelation) : null,
        customerOrigin: this.naturalPerson?.customerOrigin,
        customerStatus: this.naturalPerson?.customerStatus,
        segmentation: this.naturalPerson?.segmentation,
      },
      recueil: {
        objectif: {
          firstObjective: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.firstObjective : null,
          secondObjective: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.secondObjective : null,
          thirdObjective: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.thirdObjective : null,
        },
        immobilier: {
          bienImmobiliers: this.naturalPerson?.recueil?.bienImmobiliers?.map((x: Immobilier) => {
            return {
              id: x.id,
              typeBien: x.typeBien,
              natureBien: x.natureBien,
              valeur: x.valeur,
              partsDetenues: x.partsDetenues,
              detenteur: x.detenteur,
              modeDetention: x.modeDetention,
              address: x.address ? {
                id: x.address.id,
                label: x.address.label,
                fiscale: x.address.fiscale,
                street: x.address.street,
                city: x.address.city,
                zipCode: x.address.zipCode,
                state: x.address.state,
                country: x.address.country,
                isContact: x.address.isContact,
                type: x.address.type,
              } : null,
              credit: x.credit,
              typeCredit: x.typeCredit,
              souscripteur: x.souscripteur,
              genereRevenus: x.genereRevenus,
              revenusAnnuels: x.revenusAnnuels,
              detenuSciOrScOrSarl: x.detenuSciOrScOrSarl,
              companyName: x.companyName,
              attestNotarial: x.attestNotarial,
              taxeFonciere: x.taxeFonciere,
              titrePropriete: x.titrePropriete,
              statutSci: x.statutSci,
              amortissement: x.amortissement,
              offrePret: x.offrePret,
              otherDocs: x.otherDocs ? x.otherDocs : null
            }
          }),
          comConseilleBienImmobilier: this.naturalPerson?.recueil?.comConseilleBienImmobilier,
        },
        financier: {
          avoirFinanciers: this.naturalPerson?.recueil?.avoirFinanciers?.map((x: AvoirFinancier) => {
            return {
              id: x.id,
              typeAvoir: x.typeAvoir,
              assureurBanque: x.assureurBanque,
              dateSouscription: x.dateSouscription ? new Date(x.dateSouscription) : null,
              souscripteur: x.souscripteur,
              valeur: x.valeur,
              versementProgramme: x.versementProgramme,
              montantVersementProgramme: x.montantVersementProgramme,
              clauseBeneficiaire: x.clauseBeneficiaire,
              releveCompte: x.releveCompte,
              releveSituation: x.releveSituation,
              titrePropriete: x.titrePropriete,
              otherDocs: x.otherDocs ? x.otherDocs : null
            }
          }),
          comConseilleAvoirFinancier: this.naturalPerson?.recueil?.comConseilleAvoirFinancier,
        },
        bienDivers: {
          bienDivers: this.naturalPerson?.recueil?.bienDivers ? this.naturalPerson?.recueil?.bienDivers : [],
          autreBienDivers: this.naturalPerson?.recueil?.bienDivers ? this.naturalPerson?.recueil?.autreBienDivers : null,
          comConseilleBienDivers: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.comConseilleBienDivers : null,
        },
        autre: {
          creditNonImmobiliers: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.creditNonImmobiliers : [],
          comConseilleRevenuFiscaux: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.comConseilleRevenuFiscaux : null,
          comConseilleDonation: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.comConseilleDonation : null,
          impot: {
            trancheImpots: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.trancheImpots : null,
            assujettiIfi: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.assujettiIfi : null,
          },
          donation: {
            realiseDonation: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.realiseDonation : false,
            recuDonation: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.recuDonation : false,
            redigeTestament: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.redigeTestament : false
          },
          prevoyance: {
            contratPrevoyance: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.contratPrevoyance : false,
            descriptionContratPrevoyance: this.naturalPerson?.recueil ? this.naturalPerson?.recueil?.descriptionContratPrevoyance : null,
          },
        },
      }
    });
    // A CREUSER
    // l'etat initial de pristine est faux, peut etre à cause du formControl multi field, donc on force pristine
    // Debounce de l'observable sinon pristine est faux aussi
    this.form.markAsPristine();
    const formValueObs = this.form.valueChanges.pipe(debounceTime(100), untilDestroyed(this));
    const docsToAddObs = this.docsToAddQuery.select('docs').pipe(untilDestroyed(this));
    const docsToDeleteObs = this.docsToDeleteQuery.select('docs').pipe(untilDestroyed(this));

    combineLatest([formValueObs, docsToAddObs, docsToDeleteObs]).subscribe(([formValue, docsToAdd, docsToDelete]) => {
      this.canSave = this.form.valid;
      this.canLeave = this.form.pristine && docsToAdd.length === 0 && docsToDelete.length === 0;
    })

  }

  submitForm(navigate: boolean): void {
    if (this.form.invalid) {
      return;
    }
    this.submitLoading = true;
    let values = {
      recueil: {
        id: this.naturalPerson?.recueil?.id,
        updateAuthor: this.sessionQuery.getValue(),
        updatedAt: new Date(),
        creditNonImmobiliers: this.form.get('recueil')?.value.autre.creditNonImmobiliers,
        comConseilleInformation: this.form.get('informations')?.value.comConseilleInformation,
        comConseilleRevenuFiscaux: this.form.get('recueil')?.value.autre.comConseilleRevenuFiscaux,
        comConseilleDonation: this.form.get('recueil')?.value.autre.comConseilleDonation,
        comConseilleBienDivers: this.form.get('recueil')?.value.bienDivers.comConseilleBienDivers,
        bienDivers: this.form.get('recueil')?.value.bienDivers.bienDivers.bienDivers,
        autreBienDivers: this.form.get('recueil')?.value.bienDivers.bienDivers.autreBienDivers,
        justifInvestissementBienDivers: this.naturalPerson?.recueil?.justifInvestissementBienDivers,
        titreProprieteBienDivers: this.naturalPerson?.recueil?.titreProprieteBienDivers,
        conditionPrevoyances: this.naturalPerson?.recueil?.conditionPrevoyances,
        certifPrevoyances: this.naturalPerson?.recueil?.certifPrevoyances,
        ...this.form.get('recueil')?.value.autre.impot,
        ...this.form.get('recueil')?.value.autre.donation,
        ...this.form.get('recueil')?.value.autre.prevoyance,
        ...this.form.get('recueil')?.value.financier,
        ...this.form.get('recueil')?.value.immobilier,
        ...this.form.get('recueil')?.value.objectif,
      },
      ...this.form.get('informations')?.value.infoCivile,
      ...this.form.get('informations')?.value.situationMatrimoniale,
      ...this.form.get('informations')?.value.situationPro,
      ...this.form.get('informations')?.value.etranger,
      ...this.form.get('informations')?.value.celebre,
      ...this.form.get('informations')?.value.contact,
    };
    this.naturalPersonService.update(this.naturalPerson?.id, values).subscribe({
      next: () => {
        this.submitLoading = false;
      },
      error: () => {
        this.submitLoading = false;
      }
    });
  }

  setDropdownOptions() {
    this.stepsOptions = this.dropdownOptionsService.steps;
    this.stepsTasksOption = this.dropdownOptionsService.stepsTasks;
    this.stepFilterRelatedTaskOption = this.dropdownOptionsService.stepsTasks;
    this.poleAndCollaboratorOptions = this.dropdownOptionsService.polesAndCollaborators.filter((item) => item.value !== 'other');
    this.collaboratorOptions = this.dropdownOptionsService.polesAndCollaborators.filter((item) => item.value !== 'other' && item.value !== 'poles');
  }

  setTableFilters() {
    this.filterService.register('containsPoleOrCollaborator', (value: AddonFilterPoleAndCollaborator, filter: PoleAndCollaboratorOptionValue): boolean =>
      this.doesTaskContainPoleOrCollaborator(value, filter));
    this.filterService.register('containsStep', (value: Step, filter: StepAndTaskOptionValue): boolean => this.doesTaskContainStepName(value, filter));
    this.filterService.register('containsStepTask', (value: any, filter: any): boolean => this.doesTaskContainsTaskName(value, filter));
    this.filterService.register('containsCollaborator', (value: any, filter: any): boolean => this.doesTaskContainsCollaborator(value, filter));
  }

  // Get tasks of current steps table.
  getNaturalPersonCurrentStepsTasks(steps: [] | Step[]): [] | CurrentStepsDashboardTask[] {
    let tasks: [] | any[] = [];

    steps.map((step) => {
      step.stepGroups.map((group) => {
        group.tasks.map((task) => {
          const relatedNaturalPersons = step.naturalPersons?.filter((naturalPerson) => naturalPerson.id !== this.naturalPerson?.id);
          const relatedLegalPersons = step.legalPersons?.filter((legalPersons) => legalPersons.id !== this.naturalPerson?.id);

          tasks = [...tasks, {
            ...task,
            step,
            relatedNaturalPersons,
            relatedLegalPersons
          }];
        });
      });
    });
    tasks = tasks.filter((task: Task) => task.status !== TaskStatus.DONE);
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(moment(taskA.deadlineDate, 'YYYYMMDD'), moment(taskB.deadlineDate, 'YYYYMMDD'), 'ASC'));
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(moment(taskA.startDate, 'YYYYMMDD'), moment(taskB.startDate, 'YYYYMMDD'), 'ASC'));
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(taskA.isPriority, taskB.isPriority));

    // Filter dropdown options with data related to the currentStep table tasks.
    if (tasks.length > 0) {
      this.stepsOptions = this.filterStepOptions(tasks, this.stepsOptions);
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(tasks);
    }

    return tasks;
  }

  // Get tasks of historical tasks table.
  getNaturalPersonHistoricalTasks(steps: [] | Step[]): [] | Task[] {
    let tasks: [] | any[] = [];

    steps.map((step) => {
      step.stepGroups.map((group) => {
        group.tasks.map((task) => {
          tasks = [...tasks, {
            ...task,
            step,
          }];
        });
      });
    });

    tasks = tasks.filter((task: Task) => task.status === TaskStatus.DONE);
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(taskA.startDate, taskB.startDate, 'DESC'));
    return tasks;
  }

  abstractSorter(taskA: any, taskB: any, sort: 'ASC' | 'DESC' = 'DESC') {
    if (new Date(taskA) > new Date(taskB)) {
      return sort === 'DESC' ? -1 : +1;
    } else if (new Date(taskA) < new Date(taskB)) {
      return sort === 'DESC' ? +1 : -1;
    } else {
      return 0;
    }
  }

  // Add the appropriate data to tasks.
  addPropertiesToTasks(tasks: Task[]): CurrentStepsDashboardTask[] {
    // Add property for filtering by pole and collaborator.
    const tasksWithPoleAndCollaboratorProperty = this.addPoleAndCollaboratorKeyToTasks(tasks);
    // Add property for filtering by step and task name.
    const tasksWithStepAndTasknameProperty = this.addStepAndTaskNameKeyToTasks(tasksWithPoleAndCollaboratorProperty);
    // Add property for display calculated deadline progress.
    return this.addDeadlineProgressStatusKeyToTasks(tasksWithStepAndTasknameProperty);
  }

  // Add property for filtering / sorting by poles and collaborators.
  addPoleAndCollaboratorKeyToTasks(tasks: CurrentStepsDashboardTask[]) {
    return tasks.map((task) => ({
      ...task,
      poleAndCollaborator: {
        pole: task.pole as PoleInterne,
        collaborator: task.collaborator,
        isPriority: task.isPriority,
        status: task.status as TaskStatus,
      },
    }));
  }

  // Add property for filtering / sorting by step and task name.
  addStepAndTaskNameKeyToTasks(tasks: CurrentStepsDashboardTask[]) {
    return tasks.map((task) => ({
      ...task,
      stepAndTaskName: {
        relatedStep: task?.step?.name ?? '',
        taskName: task.name,
      },
    }));
  }

  // Add property for display calculated deadline progress.
  addDeadlineProgressStatusKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      deadlineProgress: this.taskHelperService.calculateDeadlineIndicator(
        new Date(task.startDate),
        new Date(task.deadlineDate)
      ),
    }));
  }

  filterStepOptions(tasks: CurrentStepsDashboardTask[], stepOptions: DropdownOption<StepAndTaskOptionValue>[]): DropdownOption<StepAndTaskOptionValue>[] {
    if (stepOptions) {
      return stepOptions.filter((stepOption) => tasks.some((task) => stepOption.value.name === task.step?.name))
        .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
    } else {
      return stepOptions;
    }
  }

  filterStepTasksOptions(tasks: CurrentStepsDashboardTask[]): DropdownOption<StepAndTaskOptionValue>[] {
    let stepTasks: [] | DropdownOption<StepAndTaskOptionValue>[] = [];

    tasks.map((task) => {
      if (!stepTasks.some((stepTask: DropdownOption<StepAndTaskOptionValue>) => stepTask.value.name === task.step?.name) && task.step?.name) {
        stepTasks = [...stepTasks, {label: task.step?.name, value: {name: task.step?.name, type: 'STEP'}, items: []}];
      }

      stepTasks.map((stepTask, index) => {
        if (task.step && stepTask.value.name === task.step.name && stepTask.items && !stepTask.items.some((stepTaskItem) => stepTaskItem.value.name === task.name)) {
          // @ts-ignore
          stepTasks[index].items.push({label: task.name, value: {name: task.name, type: 'TASK'}});
        }
      });
    });

    return stepTasks.sort((resultA: DropdownOption<StepAndTaskOptionValue>, resultB: DropdownOption<StepAndTaskOptionValue>) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  // Logic for collaborator / pole / "Mes tâches" filter.
  doesTaskContainPoleOrCollaborator(item: AddonFilterPoleAndCollaborator, filterValue: PoleAndCollaboratorOptionValue) {
    if (item && filterValue.type === 'pole') {
      return item.pole === filterValue.id;
    }
    if (item.collaborator && filterValue.type === 'collaborator') {
      return item.collaborator.id === filterValue.id;
    }
    return !filterValue;
  }

  // Logic for step name filter.
  doesTaskContainStepName(item: Step, filterValue: StepAndTaskOptionValue) {
    if (item) {
      // Set step related tasks for task filter options.
      this.stepFilterRelatedTaskOption = this.stepFilterRelatedTaskOption.filter((stepTasks) => stepTasks.label === filterValue.name);
      return item.name === filterValue.name;
    }
    return !filterValue;
  }

  // Logic for task name filter.
  doesTaskContainsTaskName(item: Step['name'], filterValue: StepAndTaskOptionValue) {
    if (item) {
      return item === filterValue.name;
    }
    return !filterValue;
  }

  // Logic for collaborator filter.
  doesTaskContainsCollaborator(items: any, filterValue: any) {
    if (items) {
      return items.some((item: any) => item.id === filterValue.id);
    }
    return !filterValue;
  }

  // Apply filter and register filter in store.
  handleFiltering(filterValue: any, fieldToFilter: DashboardFiltersType) {
    if (fieldToFilter === DashboardFiltersType.STEP) {
      // Re-filter step related task option if step filter is changed.
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(this.naturalPersonCurrentStepsTasks);
      this.setStepFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.STEPTASK) {
      this.setStepTaskFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.COLLABORATORANDPOLE) {
      this.setCollaboratorAndPoleFilter(filterValue, DashboardType.CURRENT);
    } else if (fieldToFilter === DashboardFiltersType.POLE) {
      this.setCollaboratorAndPoleFilter(filterValue, DashboardType.HISTORICAL);
    } else if (fieldToFilter === DashboardFiltersType.COLLABORATOR) {
      this.setCollaboratorFilter(filterValue);
    }
  }

  // Function to reset array filters.
  clearFilters(event: Event, table: 'currentSteps' | 'historical' | 'meetingReports') {
    // If we do not clean each filter we encounter the following problem :
    // the filter is reset but the filter label remains selected in the dropdown.
    if (table === 'currentSteps') {
      this.filterStepsCurrentSteps.clear(event);
      this.filterTasksCurrentSteps.clear(event);
      this.filterCollaboratorAndPoleCurrentSteps.clear(event);
      this.currentStepsTable.clear();
    }
    if (table === 'historical') {
      this.filterPoleHistorical.clear(event);
      this.historicalTable.clear();
    }
    if (table === 'meetingReports') {
      this.filterCollaboratorMeetingReport.clear(event);
      this.meetingReportTable.clear();
    }
  }

  // Function to reset task filter after cleared step filter.
  clearFilterTask(event: Event) {
    this.stepFilterRelatedTaskOption = this.stepsTasksOption;
    this.filterTasksCurrentSteps.clear(event);
    this.filterForm.get('stepTaskFilterCurrentSteps')?.setValue(undefined);
  }

  formatDurationTime(duration: number) {
    // 60000 to transform minutes in millisecond.
    const hours = moment.utc(duration * 60000).format('HH');
    const minutes = moment.utc(duration * 60000).format('mm');
    return hours + 'H' + minutes;
  }

  // Redirect user on customer detail page.
  onCustomerTagClick(customerId: number, customerType: 'legalPerson' | 'naturalPerson') {
    if (customerType === 'legalPerson') {
      this.router.navigate(['/legal-person/' + customerId]);
    } else if (customerType === 'naturalPerson') {
      this.router.navigate(['/natural-person/' + customerId]);
    }
  }

  editTask(task: any) {
    this.router.navigate(['/task-customer-edit', task.step.id, task.id], {
      queryParams: {
        id: this.naturalPerson?.id,
        type: 'naturalPerson'
      }
    });
  }

  openCloseDialog(task: any) {
    this.closingTask = task;
    this.displayCloseDialog = true;
  }

  setHeaderMenu() {
    this.headerMenuitems = [
      {
        label: 'Modifier les informations',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.showEditNaturalPersonModal = true;
        }
      },
      {
        label: 'Supprimer le contact',
        icon: 'pi pi-fw pi-times',
        visible: this.sessionQuery.getValue().roles.includes(UserRole.ROLE_DIRIGEANT) ||
          this.sessionQuery.getValue().roles.includes(UserRole.ROLE_ADMIN),
        command: () => {
          this.showDeleteConfirmModal = true;
        }
      }
    ];
  }

  // Menu displayed when user click on relation tag.
  setRelationMenu() {
    this.naturalRelationitems = [
      {
        label: 'Voir la fiche',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.goToNaturalRelation();
        }
      },
      {
        label: 'Supprimer',
        icon: 'pi pi-fw pi-times',
        command: () => {
          this.deleteNaturalRelation();
        }
      }
    ];

    this.legalRelationitems = [
      {
        label: 'Voir la fiche',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.goToLegalRelation();
        }
      },
      {
        label: 'Supprimer',
        icon: 'pi pi-fw pi-times',
        command: () => {
          this.deleteLegalRelation();
        }
      }
    ];
  }

  deleteNaturalRelation() {
    if (this.naturalPerson?.naturalNaturalRelations) {
      const relationToKeep = this.naturalPerson.naturalNaturalRelations.filter(relation => relation.secondNaturalPerson.id !== this.activeRelation.secondNaturalPerson.id);
      let relationToKeepPayload: any[] = [];
      relationToKeep.map(relation => {
        relationToKeepPayload = [...relationToKeepPayload, {
          id: relation.id,
          secondNaturalPerson: {id: relation.secondNaturalPerson.id},
          type: {id: relation.type.id}
        }]
      })
      const updatedNaturalPerson = {id: this.naturalPerson.id, naturalNaturalRelations: [...relationToKeepPayload]};
      this.updateNaturalPerson(updatedNaturalPerson);
    }
  }

  goToNaturalRelation() {
    this.router.navigate(['natural-person/' + this.activeRelation.secondNaturalPerson.id]);
  }

  deleteLegalRelation() {
    if (this.naturalPerson?.naturalLegalRelations) {
      let relationsToKeep: NaturalLegalRelation[] = [];
      let relationToKeepPayload: any[] = [];
      relationsToKeep = this.naturalPerson.naturalLegalRelations.filter(relation => relation.legalPerson.id !== this.activeRelation.legalPerson.id);
      relationsToKeep.map(relation => {
        relationToKeepPayload = [...relationToKeepPayload, {
          id: relation.id,
          legalPerson: {id: relation.legalPerson.id},
          naturalPerson: {id: this.naturalPerson?.id},
          naturalPersonRole: relation.naturalPersonRole
        }];
      });
      const updatedNaturalPerson = {id: this.naturalPerson.id, naturalLegalRelations: [...relationToKeepPayload]};
      this.updateNaturalPerson(updatedNaturalPerson);
    }
  }

  goToLegalRelation() {
    this.router.navigate(['legal-person/' + this.activeRelation.legalPerson.id]);
  }

  submitInfoCabinet() {

    if (this.form.get('infoCabinet')?.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const updatedNaturalPerson: any = {
      mainInterlocutor: this.form.get('infoCabinet')?.value.mainInterlocutor,
      secondaryInterlocutor: this.form.get('infoCabinet')?.value.secondaryInterlocutor,
      dateEntreeEnRelation: this.form.get('infoCabinet')?.value.dateEntreeEnRelation,
      customerOrigin: this.form.get('infoCabinet')?.value.customerOrigin,
      customerStatus: this.form.get('infoCabinet')?.value.customerStatus,
      segmentation: this.form.get('infoCabinet')?.value.segmentation,
    };

    //check customerStatus and ask confirmation if we remove prospect or client if recueil is not empty
    if (!updatedNaturalPerson?.customerStatus?.includes(this.clientStatus.CLIENT) &&
      !updatedNaturalPerson?.customerStatus?.includes(this.clientStatus.PROSPECT) &&
      this.naturalPerson?.recueil !== null) {
      if (!this.changeStatusConfirmed) {
        this.showStatusConfirmModal = true;
      } else {
        updatedNaturalPerson.recueil = null;
        this.updateNaturalPerson(updatedNaturalPerson);
      }
    } else {
      this.updateNaturalPerson(updatedNaturalPerson);
    }
  };

  hideEditNaturalPersonModal() {
    this.showEditNaturalPersonModal = false;
    this.form.get('infoCabinet')?.reset(
      {
        mainInterlocutor: this.naturalPerson?.mainInterlocutor,
        secondaryInterlocutor: this.naturalPerson?.secondaryInterlocutor,
        dateEntreeEnRelation: this.naturalPerson?.dateEntreeEnRelation ? new Date(this.naturalPerson?.dateEntreeEnRelation) : null,
        customerOrigin: this.naturalPerson?.customerOrigin,
        customerStatus: this.naturalPerson?.customerStatus,
        segmentation: this.naturalPerson?.segmentation,
      }
    );
    this.form.markAsPristine();
  }

  submitNaturalNaturalRelationForm() {
    if (this.naturalNaturalRelationForm.form.invalid) {
      this.naturalNaturalRelationForm.form.markAllAsTouched();
      return;
    }
    if (this.naturalPerson?.naturalNaturalRelations) {

      let existingRelations: any[] = [];
      this.naturalPerson.naturalNaturalRelations.map(relation => {
        existingRelations = [...existingRelations, {
          id: relation.id,
          secondNaturalPerson: {id: relation.secondNaturalPerson.id},
          type: {id: relation.type.id},
          enfantDe: relation.enfantDe,
          aChargeFiscale: relation.aChargeFiscale,
          adopted: relation.adopted,
        }]
      })
      let updatedNaturalPerson: Partial<NaturalPerson> = {
        recueil: this.naturalPerson.recueil,
        naturalNaturalRelations: [...existingRelations, {
          id: null,
          // @ts-ignore
          firstNaturalPerson: {id: this.naturalPerson.id},
          secondNaturalPerson: {
            id: this.naturalNaturalRelationForm.naturalPersonF?.value
          },
          type: {
            id: this.naturalNaturalRelationForm.relationLabelF?.value?.id
          },
          enfantDe: this.naturalNaturalRelationForm.enfantDeF?.value,
          aChargeFiscale: this.naturalNaturalRelationForm.chargeFiscaleF?.value,
          adopted: this.naturalNaturalRelationForm.adoptedF?.value,
        }]
      };
      const enfants: Partial<NaturalPerson>[] | undefined = updatedNaturalPerson.naturalNaturalRelations?.filter((val: any) => val.type.label === NaturalNaturalRelationTypeEnums.ENFANT)
        .map((val: any) => val.secondNaturalPerson);

      const aCharges: Partial<NaturalPerson>[] | undefined = updatedNaturalPerson.naturalNaturalRelations?.filter((val: any) =>
        (val.type.label !== NaturalNaturalRelationTypeEnums.ENFANT &&
          (val.aChargeFiscale === AChargeFiscale.CHARGE_PARTAGEE || val.aChargeFiscale === AChargeFiscale.CHARGE_TOTALE))
      ).map((val: any) => val.secondNaturalPerson);

      if (this.naturalPerson.recueil !== null && updatedNaturalPerson.recueil) {
        if (aCharges?.length || enfants?.length) {
          updatedNaturalPerson = {
            ...updatedNaturalPerson,
            recueil: {...updatedNaturalPerson.recueil, personneACharge: true}
          };
        } else {
          updatedNaturalPerson = {
            ...updatedNaturalPerson,
            recueil: {...updatedNaturalPerson.recueil, personneACharge: false}
          };
        }
      }


      this.updateNaturalPerson(updatedNaturalPerson);
      this.showAddNaturalNaturalRelationModal = false;
      this.clearAddNaturalNaturalRelationForm();
    }
  }

  submitNaturalLegalRelationsForm() {
    if (this.naturalLegalRelationForm.form.invalid) {
      this.naturalLegalRelationForm.form.markAllAsTouched();
      return;
    }
    if (this.naturalPerson?.naturalLegalRelations) {
      let existingRelations: any[] = [];
      this.naturalPerson.naturalLegalRelations.map(relation => {
        existingRelations = [...existingRelations, {
          id: relation.id,
          legalPerson: {id: relation.legalPerson.id},
          naturalPerson: {id: this.naturalPerson?.id},
          naturalPersonRole: relation.naturalPersonRole
        }]
      })
      const updatedNaturalPerson: Partial<NaturalPerson> = {
        naturalLegalRelations: [...existingRelations, {
          // @ts-ignore
          legalPerson: {
            id: this.naturalLegalRelationForm.legalPersonF?.value
          },
          naturalPerson: {id: this.naturalPerson.id},
          naturalPersonRole: this.naturalLegalRelationForm.naturalPersonRoleF?.value
        }]
      };
      this.updateNaturalPerson(updatedNaturalPerson);
      this.showAddNaturalLegalRelationModal = false;
      this.clearAddNaturalLegalRelationForm();
    }
  }

  updateNaturalPerson(naturalPerson: Partial<NaturalPerson>) {
    this.naturalPersonService.update(this.naturalPerson?.id, naturalPerson).subscribe({
      next: () => {
        this.showEditNaturalPersonModal = false;
        this.changeStatusConfirmed = false;
        // on se replace sur l'onglet info si l'onglet recueil n'est plus affiché
        if (!(naturalPerson.customerStatus?.includes(ClientStatus.CLIENT) || naturalPerson.customerStatus?.includes(ClientStatus.PROSPECT)) &&
          this.activePanelIndex === 3
        ) {
          this.activePanelIndex = 1;
        }
      }
    });
  }

  confirmUpdateNaturalPerson(naturalPerson?: Partial<NaturalPerson>) {
    if (naturalPerson) {
      this.changeStatusConfirmed = true;
      this.submitInfoCabinet();
      this.showStatusConfirmModal = false;
      this.showEditNaturalPersonModal = false;
    }
  }

  deleteNaturalPerson() {
    this.naturalPersonService.delete(this.naturalPerson?.id).subscribe({
      next: () => {
        this.navigation.back();
      }
    });
  }

  hideAddNaturalNaturalRelationModal() {
    this.showAddNaturalNaturalRelationModal = false;
    this.clearAddNaturalNaturalRelationForm();
  }

  clearAddNaturalNaturalRelationForm() {
    this.naturalNaturalRelationForm.naturalPersonF?.reset();
    this.naturalNaturalRelationForm.relationLabelF?.reset();
  }

  hideAddNaturalLegalRelationModal() {
    this.showAddNaturalLegalRelationModal = false;
    this.clearAddNaturalLegalRelationForm();
  }

  clearAddNaturalLegalRelationForm() {
    this.naturalLegalRelationForm.legalPersonF?.reset();
    this.naturalLegalRelationForm.naturalPersonRoleF?.reset();
  }

  translate(word: string | undefined): string | void {
    if (word) {
      return this.translatorService.instant(word);
    }
  }

  displayGeneralCommentEdit() {
    this.showGeneralCommentEdit = true;
  }

  // cancelGeneralCommentEdit(){
  //   this.generalComment = this.naturalPersonForm.initialValues.generalComment;
  //   this.showGeneralCommentEdit = false;
  // }
  //
  // submitGeneralComment() {
  //   if (this.naturalPerson) {
  //     const updatedNaturalPerson: Partial<NaturalPerson> = {
  //       generalComment: this.generalComment
  //     };
  //     this.updateNaturalPerson(updatedNaturalPerson);
  //     this.showGeneralCommentEdit = false;
  //   }
  // }

  mailTo(mail: Email[] | undefined) {
    if (mail) {
      window.location.href = 'mailto:' + mail[0].email;
    }
  }

  callTo(phone: Phone[] | undefined) {
    if (phone) {
      window.location.href = 'tel:' + phone[0].number;
    }
  }

  onClickNavigateTo(url: string, parameters?: Record<string, any>) {
    this.router.navigate([url], {queryParams: parameters});
  }

  onClickGenerateRecueilPdf() {
    if (!this.generateRecueilPdfLoading && this.naturalPerson?.recueil !== undefined) { // On ne peut pas DL plusieurs documents à la fois
      this.submitForm(false);
      this.generateRecueilPdfLoading = true;
      setTimeout(() => {
        // Récupération du lien du document
        this.httpClient.get<any>(this.config.apiUrl + '/document/generate/recueil/' + this.naturalPerson?.id).pipe(untilDestroyed(this)).subscribe({
          next: (results) => {
            if (results.success) {
              // @ts-ignore
              this.naturalPersonStore.update(this.naturalPerson?.id,
                entity => {
                  return {
                    ...entity,
                    recueil: {
                      ...entity.recueil,
                      recueilDoc: results.recueilDoc,
                      pdfDate: results.pdfDate,
                      pdfAuthor: results.pdfAuthor,
                    }
                  }
                }
              );
              window.open(results.doclink, 'Recueil PDF');
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Erreur',
                detail: 'Une erreur est survenue lors de la génération du recueil. Veuillez essayer à nouveau ou contactez un administrateur. ' +
                  'Détail de l\'erreur: ' + results.message,
                life: 15000,
              });
            }
            this.generateRecueilPdfLoading = false;
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Une erreur est survenue lors de la génération du document. Veuillez essayer à nouveau ou contactez un administrateur.',
              life: 15000,
            });
            this.generateRecueilPdfLoading = false;
          }
        });
      }, 1500)
    }
  }

  onClickViewRecueilPdf() {
    const files = this.naturalPerson?.recueil?.recueilDoc?.files;
    if (this.naturalPerson && files) {
      window.open(this.documentHelperService.getFileDownloadLink(this.naturalPerson, ClientType.NATURAL_PERSON, files[0]), 'Recueil PDF');
    }
  }

  isContactEmail(): boolean {
    if (this.naturalPerson && this.naturalPerson?.emails.some((email: Email) => email.isContact)) {
      return true;
    } else {
      return false;
    }
  }

  requestNewRecueil() {
    const clientId = this.naturalPerson?.id;

    this.httpClient.post(this.config.apiUrl + '/request_new_recueil', {clientId}).subscribe({
      next: (val: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'La demande de recueil a été envoyée au client',
          life: 10000
        });
        this.naturalPersonService.get(clientId).subscribe();
      }
    });

  }

  requestUpdateRecueil() {
    const clientId = this.naturalPerson?.id;

    this.httpClient.post(this.config.apiUrl + '/request_update_recueil', {clientId}).subscribe({
      next: (val: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'La demande de mise à jour du recueil a été envoyée au client',
          life: 10000
        });
      }
    });

  }

  requestActivateAccount() {
    const clientId = this.naturalPerson?.id;

    this.httpClient.post(this.config.apiUrl + '/request_activate_account', {clientId}).subscribe({
      next: (val: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'La demande d\'activation du compte a été envoyée au client',
          life: 10000
        });
        this.naturalPersonService.get(clientId).subscribe();
      }
    });

  }
}
