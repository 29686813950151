<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field-checkbox col-3" *ngFor="let bienDiversType of bienDiversTypes">
      <p-checkbox name="groupname" [value]="{id: bienDiversType.id}" [formControl]="bienDivers"
                  id="vin" [label]="bienDiversType.label"></p-checkbox>
    </div>
    <div class="field grid col-3">
      <label for="autre" class="col-fixed">Autres</label>
      <div class="col">
        <input id="autre" formControlName="autreBienDivers" type="text" pInputText class="inputfield ml-3">
      </div>
    </div>
  </div>
</div>
