<div [formGroup]="form">
  <div class="grid p-fluid">

    <div class="field col-3" *ngIf="advanced">
      <label for="type">Type</label>
      <p-dropdown
        formControlName="type"
        id="type"
        [options]="contactTypeOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="typeF?.touched && typeF?.errors" [fieldError]="typeF?.errors"></lib-field-error>
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="label">Description</label>
      <input formControlName="label" id="label" type="text" size="30" pInputText class="inputfield w-full">
    </div>
    <div class="field col-3">
      <label for="phone-input">Téléphone</label>
      <ngx-intl-tel-input
        [inputId]="'phone-input'"
        id="phone-input"
        formControlName="phone"
        [cssClass]="'custom'"
        [preferredCountries]="[countryISO.France]"
        [enableAutoCountrySelect]="false"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [searchCountryField]="[searchCountryField.Iso2, searchCountryField.Name]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="selectedCountryCode"
        [maxLength]="15"
        [phoneValidation]="true"
        [separateDialCode]="true"
        [numberFormat]="phoneNumberFormat.National"
        [searchCountryPlaceholder]="'Rechercher un pays'"
        class="inputfield"
      ></ngx-intl-tel-input>
      <lib-field-error *ngIf="phoneF?.touched && phoneF?.errors" [fieldError]="phoneF?.errors"></lib-field-error>
    </div>
    <div class="field col-3 flex flex-column" *ngIf="advanced">
      <label for="isContact">Téléphone de contact</label>
      <div class="flex align-items-center">
        <div [ngClass]="{'text-400' : value.isContact}">Non</div>
        <p-inputSwitch  id="isContact"
                        formControlName="isContact"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                        (onChange)="onIsContactChange($event)"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.isContact}">Oui</div>
      </div>
    </div>

  </div>
</div>
