import {Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {
  ClientType,
  TranslatorService,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
  NaturalPersonService,
  RecueilHelperService,
  SessionQuery,
  NaturalNaturalRelation,
  RempliPar
} from 'common';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {MenuItem} from 'primeng/api';
import {ID} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Subscription} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-recueil-admin',
  templateUrl: './recueil-admin.component.html',
  styleUrls: ['./recueil-admin.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilAdminComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilAdminComponent),
      multi: true
    }
  ]
})
export class RecueilAdminComponent implements OnDestroy, OnChanges, ControlValueAccessor {

  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  client: NaturalPerson|LegalPerson;
  naturalNaturalRelations: NaturalNaturalRelation[] | undefined = [];
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  steps: MenuItem[];
  stepActiveIndex = 0;
  submitLoading = false;
  rempliPar = RempliPar;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public naturalPersonQuery: NaturalPersonQuery,
    public legalPersonQuery: LegalPersonQuery,
    private naturalPersonService: NaturalPersonService,
    private recueilHelperService: RecueilHelperService,
    private sessionQuery: SessionQuery,
    private translatorService: TranslatorService,
  ) {
    this.form = this.formBuilder.group({
      objectif: this.formBuilder.control([]),
      immobilier: this.formBuilder.control([]),
      financier: this.formBuilder.control([]),
      bienDivers: this.formBuilder.control([]),
      autre: this.formBuilder.control([]),

    });
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.steps = [
      {
        label: 'Général',
        styleClass: 'icon objectif',
        command: (event: any) => {
          this.stepActiveIndex = 0;
        }
      },
      {
        label: 'Immobilier',
        styleClass: 'icon immo',
        command: (event: any) => {
          this.stepActiveIndex = 1;
        }
      },
      {
        label: 'Financier',
        styleClass: 'icon financier',
        command: (event: any) => {
          this.stepActiveIndex = 2;
        }
      },
      {
        label: 'Biens divers',
        styleClass: 'icon divers',
        command: (event: any) => {
          this.stepActiveIndex = 3;
        }
      },
      {
        label: 'Autres',
        styleClass: 'icon autre',
        command: (event: any) => {
          this.stepActiveIndex = 4;
        }
      },
      {
        label: 'Récapitulatif',
        styleClass: 'icon recap',
        command: (event: any) => {
          this.stepActiveIndex = 5;
        }
      },
    ];
  }

  get immobilierF(): any {
    return this.form.get('immobilier') as UntypedFormControl;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  translate(word: string | undefined): string | void {
    if (word) {
      return this.translatorService.instant(word);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = {
        objectif: value.objectif,
        immobilier: value.immobilier,
        financier: value.financier,
        bienDivers: value.bienDivers,
        autre: value.autre,
      };
      this.form.markAsPristine();
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  submit(){
    this.submitLoading = true;
    let values = {
      recueil: {
        id: this.client?.recueil?.id,
        updateAuthor: this.sessionQuery.getValue(),
        updatedAt: new Date(),
        creditNonImmobiliers: this.form.value.autre.creditNonImmobiliers?.map((val: any) => ({...val})),
        comConseilleRevenuFiscaux: this.form.value.autre.comConseilleRevenuFiscaux,
        comConseilleDonation: this.form.value.autre.comConseilleDonation,
        comConseilleBienDivers: this.form.value.bienDivers.comConseilleBienDivers,
        bienDivers: this.form.value.bienDivers.bienDivers,
        autreBienDivers: this.form.value.bienDivers.autreBienDivers,
        ...this.form.value.autre.impot,
        ...this.form.value.autre.donation,
        ...this.form.value.autre.prevoyance,
        ...this.form.value.financier,
        ...this.form.value.immobilier,
        ...this.form.value.objectif,
        justifInvestissementBienDivers: this.client?.recueil?.justifInvestissementBienDivers,
        titreProprieteBienDivers: this.client?.recueil?.titreProprieteBienDivers,
        conditionPrevoyances: this.client?.recueil?.conditionPrevoyances,
        certifPrevoyances: this.client?.recueil?.certifPrevoyances,
      },
      naturalNaturalRelations: JSON.parse(JSON.stringify(this.naturalNaturalRelations))
    };
    values = this.recueilHelperService.clean(values);
    values = this.recueilHelperService.handleDocToDelete(values);
    values = this.recueilHelperService.handleDocToAdd(values);

    this.naturalPersonService.update(this.client?.id, values).subscribe({
      next: () => {this.submitLoading = false;},
      error: () => {this.submitLoading = false;}
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientType'] && changes['clientId']){
      if (changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.naturalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
          if(naturalPerson){
            this.client = naturalPerson;
            this.naturalNaturalRelations = naturalPerson.naturalNaturalRelations;
          }
        });
      }
      if (changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.legalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(legalPerson => {
          if(legalPerson){
            this.client = legalPerson;
          }
        });
      }
    }

  }

}
