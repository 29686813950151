import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {UserRole, SessionQuery, UiScreenQuery, SessionService} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit {
  @Input() display!: boolean;
  @Output() hideMenu = new EventEmitter<any>();

  menu: {
    menuCategoryLabel: string;
    mobileAccessible: boolean;
    menuItems: {
      label: string;
      link: string;
      primeIconName: string | null;
      customIconFileName: string | null;
      mobileAccessible: boolean;
    }[];
  }[];
  userFirstname: string | null = '';
  userLastname: string | null = '';
  configuration: any;

  constructor(
    private sessionQuery: SessionQuery,
    private sessionService: SessionService,
    public uiScreenQuery: UiScreenQuery,
    private router: Router,
    private cdref: ChangeDetectorRef,
    @Inject('config') private config: any
  ) {
    this.configuration = config;
  }

  onCloseMenuClick() {
    this.hideMenu.emit();
  }

  ngOnInit(): void {
    this.menu = [
      {
        menuCategoryLabel: 'Tâches',
        mobileAccessible: true,
        menuItems: [
          {
            label: 'Dashboard',
            link: '/',
            primeIconName: 'pi pi-chart-bar',
            customIconFileName: null,
            mobileAccessible: true
          },
          {
            label: 'Revue du lundi',
            link: '/monday-review',
            primeIconName: 'pi pi-chart-bar',
            customIconFileName: null,
            mobileAccessible: false
          },
          {
            label: 'Timeline',
            link: '/timeline',
            primeIconName: 'pi pi-calendar',
            customIconFileName: null,
            mobileAccessible: false
          },
          {
            label: 'Nouvelle tâche interne',
            link: '/task-internal-new',
            primeIconName: 'pi pi-check-circle',
            customIconFileName: null,
            mobileAccessible: true
          },
          {
            label: 'Nouvelle tâche suivi client',
            link: '/task-customer-new',
            primeIconName: 'pi pi-check-circle',
            customIconFileName: null,
            mobileAccessible: true
          },
          {
            label: 'Nouvelle étape client',
            link: '/client-step-new',
            primeIconName: 'pi pi-list',
            customIconFileName: null,
            mobileAccessible: false
          }
        ],
      },
      {
        menuCategoryLabel: 'Clients',
        mobileAccessible: false,
        menuItems: [
          {
            label: 'Nouvelle Personne physique',
            link: '/natural-person-new',
            primeIconName: 'pi pi-user-plus',
            customIconFileName: null,
            mobileAccessible: false
          },
          {
            label: 'Nouvelle Personne morale',
            link: '/legal-person-new',
            primeIconName: 'pi pi-user-plus',
            customIconFileName: null,
            mobileAccessible: false
          },
          {
            label: 'Nouveau Compte rendu',
            link: '/meeting-report-new',
            primeIconName: 'pi pi-file',
            customIconFileName: null,
            mobileAccessible: false
          },
        ],
      },
    ];
    this.sessionQuery.select().pipe(untilDestroyed(this)).subscribe((userSession) => {
      // add administration menu items if user have right to admin the app
      // TODO check si on gère les roles ou les droits dans l'app, avec un mapping ?
      if (userSession) {
        this.userFirstname = userSession.firstname;
        this.userLastname = userSession.lastname;

        if (userSession.roles.includes(UserRole.ROLE_ADMIN)) {
          this.menu.push(
            {
              menuCategoryLabel: 'Administration',
              mobileAccessible: false,
              menuItems: [
                {
                  label: 'Utilisateurs',
                  link: '/user-list',
                  primeIconName: 'pi pi-user',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Produits',
                  link: '/product-list',
                  primeIconName: 'pi pi-box',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Étapes client et tâches',
                  link: '/steps-master-list',
                  primeIconName: 'pi pi-cog',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Biens atypiques',
                  link: '/bien-divers-list',
                  primeIconName: 'font-icon icon-6-cat-biens-divers',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Objectifs personnes physiques',
                  link: '/objectif-list/NATURAL_PERSON',
                  primeIconName: 'font-icon icon-1-objectifs',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Textes administrables',
                  link: '/administrable-text-list',
                  primeIconName: 'pi pi-pencil',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                {
                  label: 'Nouvelles personnes physiques',
                  link: '/new-natural-persons-list',
                  primeIconName: 'pi pi-user',
                  customIconFileName: null,
                  mobileAccessible: false
                },
                // {
                //   label: 'Objectifs personnes morales',
                //   link: '/objectif-list/LEGAL_PERSON',
                //   primeIconName: 'font-icon icon-1-objectifs',
                //   customIconFileName: null,
                //   mobileAccessible: false
                // }
              ]
            }
          );
          this.cdref.markForCheck();
        }

        if (userSession.roles.includes(UserRole.ROLE_RESPONSABLE_POLE)) {
          this.menu.push(
            {
              menuCategoryLabel: 'Administration',
              mobileAccessible: false,
              menuItems: [
                {
                  label: 'Nouvelles personnes physiques',
                  link: '/new-natural-persons-list',
                  primeIconName: 'pi pi-user',
                  customIconFileName: null,
                  mobileAccessible: false
                },
              ]
            }
          );
          this.cdref.markForCheck();
        }
      }
    });
  }

  logOut() {
    this.sessionService.logout();
    this.router.navigate(['/login']);
  }
}
