<h2 class="mt-4">Liste des objectifs {{clientType | translate}}</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async), else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div>
    <p-table #dt [value]="objectifs" dataKey="id" responsiveLayout="scroll" [globalFilterFields]="['label', 'code']">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
        <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" #textInput (input)="dt.filterGlobal(textInput.value, 'contains')" placeholder="Recherche" />
        </span>
          <p-button label="Ajouter un objectif" icon="pi pi-plus" styleClass="p-button-raised p-button-text mr-2" (click)="add()"></p-button>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="px-5"></th>
          <th class="px-5 w-full">Label</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-objectif let-rowData let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td class="px-5">
            <span class="pi pi-arrows-v" pReorderableRowHandle></span>
          </td>
          <td class="px-5 w-full" [pEditableColumn]="rowData" [pEditableColumnField]="'label'" [pEditableColumnRowIndex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input class="w-full" pInputText type="text" [(ngModel)]="objectif.label" required>
              </ng-template>
              <ng-template pTemplate="output">
                {{objectif.label}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-button icon="pi pi-trash" styleClass="p-button-raised p-button-danger p-button-text mr-2" (click)="handleObjectifDelete(objectif)"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end mt-5">
    <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="onCancel()"></p-button>
    <p-button type="submit" label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="onSubmit()" [loading]="loading"></p-button>
  </div>
</ng-template>

<p-dialog
  class="border-round"
  [(visible)]="showDeleteConfirmModal"
  (onHide)="showDeleteConfirmModal = false"
  [style]="{ width: '40vw' }"
  [modal]="true"
  header="Supprimer un objectif">
  <p>Êtes-vous sûr de vouloir supprimer le type d'objectif: <br> {{selectedObjectif?.label}}?</p>
  <p class="invalid-feedback font-bold">Attention! Les clients ayant selectionné cette valeur perdront l'information.</p>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-between">
      <p-button label="Non" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="showDeleteConfirmModal = false"></p-button>
      <p-button label="Oui" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-text" (click)="delete(selectedObjectif)"></p-button>
    </div>
  </ng-template>
</p-dialog>
