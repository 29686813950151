<ng-container *ngIf="isLoading">
  <h1>
    <p-skeleton class="w-4" height="43px"></p-skeleton>
  </h1>
  <p-skeleton class="w-4" height="188px"></p-skeleton>
</ng-container>
<div [hidden]="isLoading">
  <!-- FICHE CONTACT MOBILE -->
  <ng-container *ngIf="(uiScreenQuery.isMobile$ | async);else desktop">
    <h1
      class="text-center">{{ translate(naturalPerson?.civility) }} {{ naturalPerson?.lastName }} {{ naturalPerson?.firstName }}</h1>
    <div class="card text-sm text-800">
      <div>
        <!-- CONTACT -->
        <p class="m-0 text-primary font-semibold text-lg">Contact</p>
        <p-divider></p-divider>
        <!-- EMAIL -->
        <div *ngIf="naturalPersonHasRegisteredMail" class="mt-4 flex align-items-center">
          <div
            class="mr-3 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
            <i class="pi pi-envelope" style="font-size: 0.9rem"></i>
          </div>
          <ng-container *ngFor="let email of naturalPerson?.emails">
            <p class="m-0 font-semibold" *ngIf="email.isContact">{{ email.email }} </p>
          </ng-container>
        </div>
        <!-- TELEPHONE -->
        <div *ngIf="naturalPersonHasRegisteredPhone" class="mt-3 flex align-items-center">
          <div
            class="mr-3 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
            <i class="pi pi-phone" style="font-size: 0.9rem"></i>
          </div>
          <ng-container *ngFor="let phone of naturalPerson?.phones">
            <p class="m-0 font-semibold" *ngIf="phone.isContact">({{ phone.dialCode }}
              ) {{ phone.number }}</p>
          </ng-container>
        </div>
        <!-- ADRESSE -->
        <div *ngIf="naturalPersonHasRegisteredAdresse" class="mt-3 flex align-items-start">
          <div
            class="mr-3 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
            <i class="pi pi-home" style="font-size: 0.9rem"></i>
          </div>
          <ng-container *ngFor="let address of naturalPerson?.addresses">
            <p class="m-0 font-semibold line-height-3" *ngIf="address.isContact">
              {{ address.street }},<br>
              {{ address.zipCode }} {{ address.city }} {{ address.country }}
            </p>
          </ng-container>
        </div>
        <!-- CABINET -->
        <p class="m-0 text-primary font-semibold text-lg mt-6">Cabinet</p>
        <div class="mt-2 divider"></div>
        <!-- INTERLOCUTEUR -->
        <p class="mt-4">Interlocuteurs
          : <span
            class="font-semibold ml-1 my-0">{{  naturalPerson?.mainInterlocutor?.lastName | titlecase }} {{ naturalPerson?.mainInterlocutor?.firstName }}</span>
          <ng-container *ngIf="naturalPerson?.secondaryInterlocutor">
            <br>
            {{  naturalPerson?.secondaryInterlocutor?.lastName | titlecase }} {{ naturalPerson?.secondaryInterlocutor?.firstName }}
          </ng-container>
        </p>
        <!-- ORIGINE CLIENT -->
        <p class="mt-3">Origine du client : <span
          class="font-semibold ml-1 my-0">{{ translate(naturalPerson?.customerOrigin) }}</span></p>
        <!-- ENTREE EN RELATION -->
        <p class="mt-3">Entrée en relation : <span
          class="font-semibold ml-1 my-0">{{ naturalPerson?.dateEntreeEnRelation | date: 'dd/MM/yyyy' }}</span></p>
        <!-- STATUT -->
        <div class="flex mt-3">
          <p class="m-0">Statut :
            <ng-container *ngFor="let status of naturalPerson?.customerStatus; let last = last;">
              <b>{{ translate(status)}}</b>
              <ng-container *ngIf="!last">,</ng-container>
            </ng-container>
          </p>
        </div>
        <!-- SEGMENTATION -->
        <p class="mt-3">Segmentation client : <span
          class="font-semibold ml-1 my-0">{{ translate(naturalPerson?.segmentation) }}</span></p>
        <!-- COMPTE ACTIF -->
        <p class="mt-3">Compte utilisateur :
          <span *ngIf="naturalPerson?.userAccount !== null" class="font-semibold ml-1 my-0">{{ naturalPerson?.userAccount?.isActive ? 'Activé' : 'Non activé'}}</span>
          <span *ngIf="naturalPerson?.userAccount === null" class="font-semibold ml-1 my-0">Non créé</span>
        </p>
      </div>
    </div>
    <div class="flex flex-wrap justify-content-between mt-4">
      <ng-template [ngIf]="naturalPersonHasRegisteredPhone">
        <button pButton
                type="button"
                icon="pi pi-phone"
                label="Appeler le client"
                class="p-button-success p-button-raised p-button-text mb-3"
                (click)="callTo(naturalPerson?.phones)"
        >
        </button>
      </ng-template>
      <ng-template [ngIf]="naturalPersonHasRegisteredMail">
        <button pButton
                type="button"
                icon="pi pi-send"
                label="Envoyer un mail"
                class="p-button-success p-button-raised p-button-text mb-3"
                (click)="mailTo(naturalPerson?.emails)"
        >
        </button>
      </ng-template>
    </div>
  </ng-container>
  <!-- FICHE CONTACT DESKTOP -->
  <ng-template #desktop>
    <h1>{{ translate(naturalPerson?.civility) }} {{ naturalPerson?.lastName }} {{ naturalPerson?.firstName }}</h1>
    <!--  CONTACT -->
    <div class="px-7 py-4 card text-sm text-800">
      <div class="flex justify-content-between align-items-center">
        <div class="text-lg w-3">
          <p class="m-0 text-primary font-semibold">Contact</p>
          <div class="mt-2 divider"></div>
        </div>
        <p-menu #menuHeader [popup]="true" [model]="headerMenuitems" appendTo="body"></p-menu>
        <button pButton type="button" icon="pi pi-ellipsis-h"
                class="p-button-raised p-button-text"
                (click)="menuHeader.toggle($event)"></button>
      </div>
      <div class="grid mt-1">
        <div class="col-4 flex flex-column justify-content-between" >
          <div class="flex align-items-center">
            <div
              class="mr-3 mb-2 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
              <i class="pi pi-envelope" style="font-size: 0.9rem"></i>
            </div>
            <ng-container *ngFor="let email of naturalPerson?.emails">
              <p class="m-0 font-semibold" *ngIf="email.isContact">{{ email.email }} </p>
            </ng-container>
          </div>

          <div class="flex align-items-center">
            <div
              class="mr-3 mb-2 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
              <i class="pi pi-phone" style="font-size: 0.9rem"></i>
            </div>
            <ng-container *ngFor="let phone of naturalPerson?.phones">
              <p class="m-0 font-semibold" *ngIf="phone.isContact">({{ phone.dialCode }}
                ) {{ phone.number }}</p>
            </ng-container>
          </div>

          <div class="flex align-items-start">
            <div
              class="mr-3 mb-2 p-1 indicator-extra-small bg-primary text-white flex justify-content-center align-items-center">
              <i class="pi pi-home" style="font-size: 0.9rem"></i>
            </div>
            <ng-container *ngFor="let address of naturalPerson?.addresses">
              <p class="m-0 font-semibold line-height-3" *ngIf="address.isContact">
                {{ address.street }},<br>
                {{ address.zipCode }} {{ address.city }} {{ address.country }}
              </p>
            </ng-container>
          </div>
        </div>

        <div class="col-4">
          <div class="flex flex-column justify-content-between h-full">
            <!-- INTERLOCUTEURS -->
            <div class="grid ">
              <div class="col-6 ">
                <p class="m-0">Interlocuteurs :</p>
              </div>
              <div class="col-6 overflow-hidden">
                <p
                  class="m-0 font-semibold line-height-3">{{  naturalPerson?.mainInterlocutor?.lastName | titlecase }} {{ naturalPerson?.mainInterlocutor?.firstName }}
                  <ng-container *ngIf="naturalPerson?.secondaryInterlocutor">
                    <br>
                    {{  naturalPerson?.secondaryInterlocutor?.lastName | titlecase }} {{ naturalPerson?.secondaryInterlocutor?.firstName }}
                  </ng-container>
                </p>
              </div>
            </div>
            <!-- ORIGINE DU CLIENT -->
            <div class="grid ">
              <div class="col-6 ">
                <p class="m-0">Origine du client :</p>
              </div>
              <div class="col-6 ">
                <p class="m-0 font-semibold">{{ translate(naturalPerson?.customerOrigin) }}</p>
              </div>
            </div>
            <!-- DATE ENTRÉE EN RELATION -->
            <div class="grid ">
              <div class="col-6 ">
                <p class="m-0">Entrée en relation :</p>
              </div>
              <div class="col-6 ">
                <p class="m-0 font-semibold">{{ naturalPerson?.dateEntreeEnRelation | date: 'dd/MM/yyyy' }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4 ">
          <div class="flex flex-column justify-content-between h-full">
            <!-- STATUS -->
            <div class="grid">
              <div class="col-6 ">
                <p class="m-0">Statut :</p>
              </div>
              <div class="col-6 ">
                <ng-container *ngFor="let status of naturalPerson?.customerStatus; let last = last;">
                  <span class="font-semibold ml-1 my-0">{{ translate(status)}}</span>
                  <ng-container *ngIf="!last">,</ng-container>
                </ng-container>
              </div>
            </div>
            <!-- SEGMENTATION -->
            <div class="grid ">
              <div class="col-6 ">
                <p class="m-0">Segmentation client :</p>
              </div>
              <div class="col-6 ">
                <span class="font-semibold ml-1 my-0">{{ translate(naturalPerson?.segmentation) }}</span>
              </div>
            </div>
            <!-- USER ACTIF -->
            <div class="grid ">
              <div class="col-6 ">
                <p class="m-0">Compte utilisateur :</p>
              </div>
              <div class="col-6 ">
                <span *ngIf="naturalPerson?.userAccount !== null" class="font-semibold ml-1 my-0">{{ naturalPerson?.userAccount?.isActive ? 'Activé' : 'Non activé'}}</span>
                <span *ngIf="naturalPerson?.userAccount === null" class="font-semibold ml-1 my-0">Non créé</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="(uiScreenQuery.isMobile$ | async) === false;" class="mt-4">
    <div class="flex justify-between mt-3 mb-6" *ngIf="naturalPerson?.customerStatus?.includes(clientStatus.PROSPECT) || naturalPerson?.customerStatus?.includes(clientStatus.CLIENT)">
      <div class="update-info mr-2 w-6 flex flex-column align-items-center">
        <div>Mise à jour:
          <ng-container *ngIf="naturalPerson?.recueil?.updateAuthor">
            {{naturalPerson?.recueil?.updatedAt | date: 'dd/MM/yyyy à HH'}}h{{naturalPerson?.recueil?.updatedAt | date: 'mm'}}
            <span class="font-semibold">({{naturalPerson?.recueil?.updateAuthor?.firstName}} {{ naturalPerson?.recueil?.updateAuthor?.lastName }})</span>
          </ng-container>
        </div>
<!--        <ng-container *ngIf="naturalPerson?.userAccount?.isActive; else accountDisabled">-->
<!--          <div [ngClass]="{'link' : isContactEmail(), 'disabled-link' : !isContactEmail()}"-->
<!--               (click)="requestUpdateRecueil()"-->
<!--               pTooltip="Le client n'a pas d'adresse email renseignée et ne peut pas avoir de compte utilisateur"-->
<!--               [tooltipDisabled]="isContactEmail()"-->
<!--               [tooltipPosition]="'bottom'"-->
<!--          >Demander une mise à jour au client</div>-->
<!--        </ng-container>-->
<!--        <ng-template #accountDisabled>-->
          <div class="flex align-items-center justify-content-around w-full">
            <div [ngClass]="{'link' : isContactEmail(), 'disabled-link' : !isContactEmail()}"
                 (click)="requestNewRecueil()"
                 pTooltip="Le client n'a pas d'adresse email renseignée et ne peut pas avoir de compte utilisateur"
                 [tooltipDisabled]="isContactEmail()"
                 [tooltipPosition]="'bottom'"
            >Envoyer un lien nouveau recueil au client</div>
<!--            <div [ngClass]="{'link' : isContactEmail(), 'disabled-link' : !isContactEmail()}"-->
<!--                 (click)="requestActivateAccount()"-->
<!--                 pTooltip="Le client n'a pas d'adresse email renseignée et ne peut pas avoir de compte utilisateur"-->
<!--                 [tooltipDisabled]="isContactEmail()"-->
<!--                 [tooltipPosition]="'bottom'"-->
<!--            >Demander au client d'activer son compte</div>-->
          </div>
<!--        </ng-template>-->

      </div>
      <div class="pdf-info ml-2 w-6 flex flex-column align-items-center " *ngIf="naturalPerson?.recueil">
        <div>Export:
          <ng-container *ngIf="naturalPerson?.recueil?.pdfAuthor">
            {{naturalPerson?.recueil?.pdfDate | date: 'dd/MM/yyyy à HH'}}h{{naturalPerson?.recueil?.pdfDate | date: 'mm'}}
            <span class="font-semibold">({{naturalPerson?.recueil?.pdfAuthor?.firstName}} {{ naturalPerson?.recueil?.pdfAuthor?.lastName }})</span>
          </ng-container>
        </div>
        <div class="flex align-items-center justify-content-around w-full">
          <div *ngIf="naturalPerson?.recueil?.recueilDoc" class="link" (click)="onClickViewRecueilPdf()">
            <span>Voir le PDF du recueil</span>
          </div>
          <div *ngIf="naturalPerson?.recueil" class="link" (click)="onClickGenerateRecueilPdf()">
            <i *ngIf="this.generateRecueilPdfLoading" class="pi pi-spin pi-spinner"></i>
            <span *ngIf="!this.generateRecueilPdfLoading">Générer le PDF du recueil</span>
          </div>
        </div>
      </div>
    </div>
    <p-tabView styleClass="steps" [(activeIndex)]="activePanelIndex">
      <!--  PANNEAU ACTIVITE -->
      <p-tabPanel header="Activité">
        <!--  ETAPES EN COURS -->
        <p-fieldset styleClass="mt-4 steps" legend="Tâches en cours" [toggleable]="true">
          <div class="flex justify-content-end">
            <button pButton type="button" icon="pi pi-list" label="Créer étape client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/client-step-new', {naturalPerson: naturalPerson?.id})"></button>
            <button pButton type="button" icon="pi pi-plus" label="Créer tâche client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/task-customer-new', {naturalPerson: naturalPerson?.id})"></button>
            <button pButton type="button" icon="pi pi-plus" label="Rappeler le client" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/task-customer-new', {rappeler: true, naturalPerson: naturalPerson?.id})"></button>
          </div>
          <p-table [formGroup]="filterForm" #currentStepsTable [value]="naturalPersonCurrentStepsTasks" dataKey="id"
                   [rows]="10"
                   responsiveLayout="scroll"
                   [paginator]="true" [filterDelay]="0" [alwaysShowPaginator]="false"
                   stateStorage="local" [stateKey]="tableCustomerCurrentStorageKey"
                   [rowsPerPageOptions]="[10,25,50]"
                   styleClass="mt-5"
                   (onStateRestore)="onDashboardStateRestore($event, dashboardType.CURRENT)"
                   [showCurrentPageReport]="true"
                   currentPageReportTemplate="Tâches en cours {first} à {last} sur {totalRecords} trouvées"
          >
            <ng-template pTemplate="header">
              <tr>
                <!-- TRIE ECHEANCE -->
                <th class="text-sm" pSortableColumn="deadlineDate">
                  Échéance
                  <p-sortIcon class="ml-auto" field="deadlineDate"></p-sortIcon>
                </th>
                <!-- IMPORTANCE -->
                <th></th>
                <!-- TRIE ETAPE -->
                <th class="text-sm">Étape / Tâche</th>
                <!-- CLIENT -->
                <th></th>
                <!-- TRIE COLLABORATEUR / PÔLE -->
                <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">
                  Collaborateur / Pôle
                  <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
                </th>
                <!-- ACTION BUTTON -->
                <th></th>
              </tr>
              <tr>
                <!-- REINIT BUTTON -->
                <th>
                  <button pButton
                          type="button"
                          class="p-button-raised p-button-sm bg-white text-black border-none"
                          icon="pi pi-filter-slash" label="Réinitialiser"
                          (click)="clearFilters($event, 'currentSteps')">
                  </button>
                </th>
                <!-- IMPORTANCE -->
                <th></th>
                <!-- FILTRE ETAPE -->
                <th>
                  <p-dropdown #filterStepsCurrentSteps
                              appendTo="body"
                              styleClass="w-7rem"
                              placeholder="..."
                              formControlName="stepFilterCurrentSteps"
                              [showClear]="true"
                              [options]="stepsOptions"
                              (onClear)="clearFilterTask($event)"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.STEP)"
                  ></p-dropdown>
                  <!-- FILTRE ETAPE TÂCHE -->
                  <p-dropdown #filterTasksCurrentSteps
                              appendTo="body"
                              styleClass="w-7rem"
                              class="ml-2"
                              placeholder="..."
                              formControlName="stepTaskFilterCurrentSteps"
                              [showClear]="true"
                              [options]="stepFilterRelatedTaskOption"
                              [group]="true"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.STEPTASK)"
                  ></p-dropdown>
                </th>
                <!-- CLIENT -->
                <th></th>
                <!-- FILTRE PÔLE/COLLABORATEUR -->
                <th>
                  <p-dropdown #filterCollaboratorAndPoleCurrentSteps
                              appendTo="body"
                              styleClass="w-full"
                              placeholder="..."
                              formControlName="poleAndCollaboratorFilterCurrentSteps"
                              [showClear]="true"
                              [options]="poleAndCollaboratorOptions"
                              [group]="true"
                              (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATORANDPOLE)"
                  ></p-dropdown>
                </th>
                <!-- ACTION BUTTON -->
                <th></th>
              </tr>
            </ng-template>
            <!--  CORPS DU TABLEAU -->
            <ng-template pTemplate="body" let-task>
              <tr>
                <!--  COLONNE ÉCHÉANCE -->
                <td class="p-2 col-2">
                  <p class="text-xs font-semibold">{{ task.startDate | date: 'dd/MM/yyyy' }}
                    <i class="pi pi-arrow-right"
                       style="font-size: 0.5rem;"></i> {{ task.deadlineDate | date: 'dd/MM/yyyy' }}
                  </p>
                </td>
                <td class="p-2 col-2">
                  <div class="flex align-items-end w-full">
                    <div class="mr-1 col-2">
                      <div *ngIf="task.isPriority"
                           class="col-3 indicator-extra-small bg-danger text-white flex justify-content-center align-items-center mr-1">
                        <i class="pi pi-exclamation-triangle" style="font-size: 0.9rem"></i>
                      </div>
                    </div>
                    <div class="mr-1 col-2">
                      <ng-template [ngIf]="task.deadlineProgress !== 'OK'">
                        <div
                          [ngClass]="{'bg-low-danger': task.deadlineProgress === 'WARNING', 'bg-danger': task.deadlineProgress === 'EXCEED'}"
                          class="indicator-extra-small flex justify-content-center align-items-center mr-1">
                          <svg width="15" height="15" viewBox="0 0 512 512">
                            <path
                              d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                              fill="#ffffff" data-original="#000000" class=""/>
                            <path
                              d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                              fill="#ffffff" data-original="#000000" class=""/>
                          </svg>
                        </div>
                      </ng-template>
                    </div>
                    <div class="mr-1 col-2">
                      <div *ngIf="task.importance && task.importance === 2"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                      <div *ngIf="task.importance && task.importance === 3"
                           class="indicator-extra-small bg-info text-white font-semibold mr-1 flex justify-content-center align-items-center">
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                        <i class="pi pi-euro" style="font-size: 0.5rem"></i>
                      </div>
                    </div>
                    <div class="col-2">
                      <p-tag *ngIf="task.status === taskStatus.PLANNED"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-gray text-white"
                             value="planned" [rounded]="true"></p-tag>
                      <p-tag *ngIf="task.status === taskStatus.DOING"
                             styleClass="px-2 text-xs font-medium vertical-align-middle bg-success text-white"
                             value="doing" [rounded]="true"></p-tag>
                    </div>
                  </div>
                </td>
                <td class="p-2 col-3">
                  <p class="text-sm m-0">{{ task.step?.name }}</p>
                  <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
                </td>
                <!--  COLONNE CLIENT -->
                <td class="p-2 col-2">
                  <p-tag *ngFor="let naturalPerson of task?.relatedNaturalPersons"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-info text-white border-1 border-info cursor-pointer"
                         class=""
                         value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                         [rounded]="true"
                         (click)="onCustomerTagClick(naturalPerson.id, 'naturalPerson')"
                         pTooltip="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                         [showDelay]="500"
                  >
                  </p-tag>
                  <p-tag *ngFor="let legalPerson of task?.relatedLegalPersons"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-warning text-white border-1 border-warning cursor-pointer"
                         value="{{ legalPerson.name }}"
                         [rounded]="true" (click)="onCustomerTagClick(legalPerson.id, 'legalPerson')"
                         pTooltip="{{ legalPerson.name }}"
                         [showDelay]="500"
                  >
                  </p-tag>
                </td>
                <!--  COLONNE COLLABORATEUR / POLE -->
                <td class="p-2 col-2">
                  <div class="flex flex-column">
                    <p-tag *ngIf="task.collaborator"
                           styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                           value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                           [rounded]="true">
                    </p-tag>
                    <p-tag *ngIf="task.pole"
                           styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-white text-black border-blue"
                           value="{{ translate(task.pole) }}"
                           [rounded]="true">
                    </p-tag>
                  </div>
                </td>
                <!-- ACTION BUTTON -->
                <td class="p-2 col-1">
                  <div class="flex">
                    <button pButton type="button" icon="pi pi-pencil"
                            class="mr-3 p-button-raised p-button-text"
                            (click)="editTask(task)"></button>
                    <button pButton type="button" icon="pi pi-check-circle"
                            class="p-button-raised p-button-success p-button-text"
                            (click)="openCloseDialog(task)"></button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <!--  AUCUNE TÂCHE TROUVÉE -->
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="p-3 text-center text-sm">Aucune tâche trouvée</td>
              </tr>
            </ng-template>
          </p-table>
        </p-fieldset>
        <!--  HISTORIQUE DES TACHES -->
        <ng-template [ngIf]="naturalPersonHistoricalTasks && naturalPersonHistoricalTasks.length > 0">
          <p-fieldset styleClass="mt-4 task-history" legend="Historique des tâches" [toggleable]="true">
            <p-table [formGroup]="filterForm" #historicalTable [value]="naturalPersonHistoricalTasks" dataKey="id" [rows]="10"
                     responsiveLayout="scroll"
                     [paginator]="true" [filterDelay]="0" [alwaysShowPaginator]="false"
                     [rowsPerPageOptions]="[10,25,50]"
                     stateStorage="local" [stateKey]="tableCustomerHistoricalStorageKey"
                     (onStateRestore)="onDashboardStateRestore($event, dashboardType.HISTORICAL)"
                     styleClass="mt-5"
                     [showCurrentPageReport]="true"
                     currentPageReportTemplate="Tâches terminées {first} à {last} sur {totalRecords} trouvées"
            >
              <ng-template pTemplate="header">
                <tr>
                  <!-- TRIE ECHEANCE -->
                  <th class="text-sm flex flex-row align-items-baseline" pSortableColumn="closingDate">
                    <p>Date de clôture</p>
                    <p-sortIcon class="ml-3" field="closingDate"></p-sortIcon>
                  </th>
                  <th>Tâche</th>
                  <!-- TRIE COLLABORATEUR -->
                  <th class="text-sm" pSortableColumn="poleAndCollaborator.pole">Collaborateur
                    <p-sortIcon class="ml-auto" field="poleAndCollaborator.pole"></p-sortIcon>
                  </th>
                  <th class="text-sm">
                    <div class="flex justify-content-end">
                      Charge réelle
                      <i class="pi pi-clock ml-2"></i>
                    </div>
                  </th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <!-- FILTRE COLLABORATEUR -->
                  <th class="col-2">
                    <p-dropdown #filterPoleHistorical
                                appendTo="body"
                                styleClass="w-10rem"
                                placeholder="..."
                                formControlName="poleFilterHistorical"
                                [showClear]="true"
                                [options]="collaboratorOptions"
                                [group]="true"
                                (onChange)="handleFiltering($event.value, dashboardFiltersType.POLE)"
                    ></p-dropdown>
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>
              <!--  CORPS DU TABLEAU -->
              <ng-template pTemplate="body" let-task>
                <tr>
                  <!--  COLONNE ÉCHÉANCE -->
                  <td class="p-2 col-2">
                    <p class="text-sm">{{ task.closingDate | date: 'dd/MM/yyyy' }}</p>
                  </td>
                  <td class="p-2 col-5">
                    <p class="text-sm font-bold m-0 mt-1">{{ task.name }}</p>
                  </td>
                  <!--  COLONNE COLLABORATEUR -->
                  <td class="p-2">
                    <div class="flex flex-column">
                      <p-tag *ngIf="task.collaborator"
                             styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                             value="{{ task.collaborator.lastName }} {{ task.collaborator.firstName }}"
                             [rounded]="true">
                      </p-tag>
                    </div>
                  </td>
                  <td class="p-2">
                    <div class="flex flex-row align-items-baseline justify-content-end">
                      <p>{{formatDurationTime(task.realWorkload)}}</p>
                    </div>
                  </td>
                  <td class="p-2">
                    <div class="flex justify-content-end">
                      <button pButton type="button" icon="pi pi-eye"
                              class="ml-auto p-button-text p-button-raised"
                              (click)="editTask(task)"></button>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <!--  AUCUNE TÂCHE TROUVÉE -->
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" class="p-3 text-center text-sm">Aucune tâche trouvée</td>
                </tr>
              </ng-template>
            </p-table>
          </p-fieldset>
        </ng-template>
        <!--  COMPTES RENDUS -->
        <p-fieldset styleClass="mt-4 cr" legend="Comptes rendus" [toggleable]="true">
          <div class="flex justify-content-end">
            <button pButton type="button" icon="pi pi-list" label="Nouveau CR" class="p-button-success p-button-raised p-button-text mt-3 ml-3"
                    (click)="onClickNavigateTo('/meeting-report-new', {naturalPerson: naturalPerson?.id})"></button>
          </div>
          <p-table #meetingReportTable [formGroup]="filterForm" [value]="compteRendus" dataKey="id" [rowHover]="true"
                   [rows]="10" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"
                   styleClass="mt-5"
                   [paginator]="true" [showCurrentPageReport]="true"
                   currentPageReportTemplate="{totalRecords} comptes rendus trouvés"
                   [filterDelay]="0" [alwaysShowPaginator]="false"
          >
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th>
                  <div pSortableColumn="meetingDate" class="flex align-items-center text-sm">
                    Date du rendez-vous
                    <p-sortIcon class="ml-2" field="meetingDate"></p-sortIcon>
                  </div>
                </th>
                <th>
                  <div pSortableColumn="mainCollaborator.lastName" class="flex align-items-center text-sm">
                    Collaborateur
                    <p-sortIcon class="ml-2" field="mainCollaborator.lastName"></p-sortIcon>
                  </div>
                </th>
                <th></th>
              </tr>
              <tr>
                <th>
                  <button pButton class="p-button-raised p-button-sm bg-white text-black border-none"
                          icon="pi pi-filter-slash" label="Réinitialiser"
                          (click)="clearFilters($event, 'meetingReports')"></button>
                </th>
                <th></th>
                <th>
                  <p-dropdown
                    #filterCollaboratorMeetingReport
                    appendTo="body"
                    styleClass="w-12rem"
                    placeholder="..."
                    formControlName="collaboratorMeetingReport"
                    [showClear]="true"
                    [group]="true"
                    [options]="collaboratorOptions"
                    (onChange)="handleFiltering($event.value, dashboardFiltersType.COLLABORATOR)"
                  >
                  </p-dropdown>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <!--  CORPS DU TABLEAU -->
            <ng-template pTemplate="body" let-compteRendu>
              <tr>
                <td class="col-2">
                  <p class="text-sm font-semibold">{{compteRendu.subject}}</p>
                </td>
                <td class="col-4">
                  <p class="text-sm">le {{compteRendu.meetingDate | date: 'dd/MM/yyyy' }}</p>
                </td>
                <td class="col-3">
                  <p-tag *ngFor="let collaborator of compteRendu.collaboratorPresent"
                         styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium vertical-align-middle bg-blue"
                         value="{{ collaborator.lastName }} {{ collaborator.firstName }}"
                         [rounded]="true">
                  </p-tag>
                </td>
                <td>
                  <div class="flex justify-content-end">
                    <button pButton type="button" icon="pi pi-pencil" class="p-button-raised p-button-text mt-3 ml-3"
                            (click)="onClickNavigateTo('/meeting-report-edit/' + compteRendu.id)"></button>

                    <button pButton type="button" icon="pi pi-eye" class="p-button-raised p-button-text mt-3 ml-3"
                            (click)="onClickNavigateTo('/meeting-report/' + compteRendu.id)"></button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <!--  AUCUN COMPTE RENDU TROUVÉ -->
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" class="p-3 text-center text-sm">Aucun compte rendu trouvé</td>
              </tr>
            </ng-template>
          </p-table>
        </p-fieldset>
      </p-tabPanel>
      <!--  PANNEAU INFORMATIONS -->
      <p-tabPanel header="Informations">
        <div [formGroup]="form" *ngIf="!isLoading">
          <app-recueil-information formControlName="informations" [client]="naturalPerson" ></app-recueil-information>
          <div class="flex justify-content-end mt-5">
            <div class="inline-block"
                 pTooltip="Le formulaire contient des erreurs"
                 tooltipPosition="left"
                 [tooltipDisabled]="!form.invalid">
              <p-button label="Enregistrer"
                        [loading]="submitLoading"
                        [disabled]="form.invalid || submitLoading"
                        icon="pi pi-check"
                        styleClass="mr-2 mt-6 p-button-raised p-button-success"
                        (click)="submitForm(true)">
              </p-button>

            </div>
          </div>
        </div>
      </p-tabPanel>
      <!--  PANNEAU RELATIONS -->
      <p-tabPanel header="Relations">
        <!--  RELATIONS -->
        <div class="flex justify-content-between align-items-center mt-4">
          <div class="flex align-items-center">
            <h3 class="inline text-900">Relations</h3>
            <p-badge value="{{ naturalPerson?.naturalNaturalRelations?.length }}"
                     styleClass="ml-2 font-light"></p-badge>
          </div>
          <button pButton type="button" icon="pi pi-plus"
                  class="p-button-raised p-button-success p-button-text"
                  (click)="showAddNaturalNaturalRelationModal = true"></button>
        </div>
        <div class="mt-3">
          <ng-container *ngIf="naturalPerson?.naturalNaturalRelations?.length; else noNaturalRelations">
            <!--  TEMPLATE SI RELATIONS -->
            <p-menu #menuNaturalRelation [popup]="true" [model]="naturalRelationitems" appendTo="body"></p-menu>
            <p-tag *ngFor="let relation of naturalPerson?.naturalNaturalRelations"
                   styleClass="p-0 pr-3 m-2 bg-white text-900 font-light border-solid border-1 border-secondary cursor-pointer"
                   value="{{relation?.enfantDe === null ? translate(relation?.type?.label) : ''}}
                          {{relation?.enfantDe ? ' Enfant de ' + translate(relation?.enfantDe) : ''}}
                          {{relation?.adopted ? ' | Adoption ' + translate(relation?.adopted) : ''}}
                          {{(relation?.aChargeFiscale && relation?.aChargeFiscale !== aChargeFiscale.CHARGE_NO) ? ' | A charge ' + translate(relation?.aChargeFiscale) : ''}}"
                   [rounded]="true"
                   (click)="activeRelation = relation; menuNaturalRelation.toggle($event)">
              <p-tag styleClass="mr-2" value="{{ relation?.secondNaturalPerson?.lastName }} {{ relation?.secondNaturalPerson?.firstName }}" [rounded]="true"></p-tag>
            </p-tag>
          </ng-container>
          <!--  TEMPLATE SI PAS DE RELATIONS -->
          <ng-template #noNaturalRelations>
            <p>Aucune relation</p>
          </ng-template>
        </div>
        <!--  PERSONNES MORALES -->
        <div class="flex justify-content-between align-items-center mt-5">
          <div class="flex align-items-center">
            <h3 class="inline text-900">Personnes Morales</h3>
            <p-badge value="{{ naturalPerson?.naturalLegalRelations?.length }}" styleClass="ml-2 font-light"></p-badge>
          </div>
          <button pButton type="button" icon="pi pi-plus"
                  class="p-button-raised p-button-success p-button-text"
                  (click)="showAddNaturalLegalRelationModal = true"></button>
        </div>
        <div class="mt-3">
          <ng-container
            *ngIf="naturalPerson?.naturalLegalRelations?.length ; then legalRelations else noLegalRelations"></ng-container>
          <!--  TEMPLATE SI RELATIONS -->
          <ng-template #legalRelations>
            <p-menu #menuLegalRelation [popup]="true" [model]="legalRelationitems" appendTo="body"></p-menu>
            <p-tag *ngFor="let relation of naturalPerson?.naturalLegalRelations"
                   styleClass="p-0 pr-3 m-2 bg-white text-900 font-light border-solid border-1 border-secondary cursor-pointer"
                   value="{{ relation?.legalPerson?.name }}"
                   [rounded]="true"
                   (click)="activeRelation = relation; menuLegalRelation.toggle($event)">
              <p-tag styleClass="mr-2" value="{{ relation?.naturalPersonRole }}" [rounded]="true"></p-tag>
            </p-tag>
          </ng-template>
          <!--  TEMPLATE SI PAS DE RELATIONS -->
          <ng-template #noLegalRelations>
            <p>Aucune relation</p>
          </ng-template>
        </div>

      </p-tabPanel>
      <!--  PANNEAU RECUEIL -->
      <p-tabPanel header="Recueil" *ngIf="naturalPerson?.customerStatus?.includes(clientStatus.PROSPECT) || naturalPerson?.customerStatus?.includes(clientStatus.CLIENT)">
        <div [formGroup]="form" *ngIf="!isLoading">
          <app-recueil-admin formControlName="recueil" [clientType]="clientType.NATURAL_PERSON" [clientId]="naturalPerson?.id"></app-recueil-admin>
        </div>
      </p-tabPanel>
      <!--  PANNEAU GED -->
      <p-tabPanel header="Documents GED">
        <app-ged-read *ngIf="(activePanelIndex === 4) || (!naturalPerson?.customerStatus?.includes(clientStatus.PROSPECT) && !naturalPerson?.customerStatus?.includes(clientStatus.CLIENT) && activePanelIndex === 3)"
                      [clientType]="clientType.NATURAL_PERSON"
                      [clientFullname]="naturalPerson?.lastName + ' ' + naturalPerson?.firstName"></app-ged-read>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<!--CUSTOMER TASK CLOSE DIALOG-->
<app-close-task-modal *ngIf="closingTask?.id"
                      [display]="displayCloseDialog"
                      [task]="closingTask"
                      (displayCloseDialog)="displayCloseDialog = $event"
></app-close-task-modal>

<!--  MODALE ÉDITION INFO CABINET -->
<p-dialog
  class="border-round"
  [(visible)]="showEditNaturalPersonModal"
  (onHide)="hideEditNaturalPersonModal()"
  [style]="{ width: '70vw', height: '680px'}"
  [modal]="true"
  header="Modifier les information de {{ naturalPerson?.lastName }} {{ naturalPerson?.firstName }}">
  <form [formGroup]="form" *ngIf="!isLoading">
    <app-info-cabinet-form formControlName="infoCabinet"></app-info-cabinet-form>
  </form>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="hideEditNaturalPersonModal()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="submitInfoCabinet()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--  MODALE CONFIRMATION ÉDITION STATUS PERSONNE PHYSIQUE -->
<p-dialog
  class="border-round"
  [(visible)]="showStatusConfirmModal"
  (onHide)="showStatusConfirmModal = false"
  [style]="{ width: '50vw', height: '300px'}"
  [modal]="true"
  header="Modifier le statut {{ naturalPerson?.lastName }} {{ naturalPerson?.firstName }}">
  <p>Ce contact a des données de patrimoine</p>
  <p class="invalid-feedback font-bold">Attention! En supprimant le statut client ou prospect, les informations de patrimoine seront supprimées</p>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="showStatusConfirmModal = false"></p-button>
      <p-button label="Confirmer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="confirmUpdateNaturalPerson(naturalPerson)"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--  MODALE AJOUTER UN RELATION NATURAL - NATURAL -->
<p-dialog
  class="border-round"
  [(visible)]="showAddNaturalNaturalRelationModal"
  (onHide)="hideAddNaturalNaturalRelationModal()"
  [style]="{width: '70vw', height: '560px'}"
  [modal]="true"
  header="Ajouter une relation"
  styleClass="natural-natural-relation"
>
  <div class="py-4 px-2">
    <app-natural-natural-relation-form [currentNaturalPersonId]="naturalPerson?.id"
                                       [naturalNaturalRelations]="naturalPerson?.naturalNaturalRelations"></app-natural-natural-relation-form>
  </div>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-between">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="hideAddNaturalNaturalRelationModal()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised p-button-text"
                (click)="submitNaturalNaturalRelationForm()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!--  MODALE AJOUTER UN RELATION NATURAL - LEGAL -->
<p-dialog
  class="border-round"
  [(visible)]="showAddNaturalLegalRelationModal"
  (onHide)="hideAddNaturalLegalRelationModal()"
  [style]="{width: '70vw'}"
  [modal]="true"
  header="Ajouter une relation"
  styleClass="natural-natural-relation"
>
  <div class="py-4 px-2">
    <app-natural-legal-relation-form [clientType]="clientType.NATURAL_PERSON" [currentNaturalPersonId]="naturalPerson?.id"></app-natural-legal-relation-form>
  </div>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-between">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="hideAddNaturalLegalRelationModal()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised p-button-text"
                (click)="submitNaturalLegalRelationsForm()"></p-button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  class="border-round"
  [(visible)]="showDeleteConfirmModal"
  (onHide)="showDeleteConfirmModal = false"
  [style]="{ width: '40vw' }"
  [modal]="true"
  header="Supprimer la personne physique">
  <p class="invalid-feedback font-bold">Attention : vous allez supprimer une personne physique. Cette action est définitive.
    Veuillez confirmer la suppression</p>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-between">
      <p-button label="Annuler la suppression" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="showDeleteConfirmModal = false"></p-button>
      <p-button label="Confirmer la suppression" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-text" (click)="deleteNaturalPerson()"></p-button>
    </div>
  </ng-template>
</p-dialog>
