import { User } from '../../../user/state/user.model';
import { ID } from '@datorama/akita';
import { Task } from '../../task/state/task.model';

export interface Comment {
  id?: ID;
  author: User;
  content: string;
  assignedUsers: User[];
  resolved: boolean;
  blocked: boolean;
  creationDate: string;
  task?: Task;
}

export function createComment(params: Partial<Comment>) {
  return {} as Comment;
}
