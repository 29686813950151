import { ID } from '@datorama/akita';
import { Fournisseur } from '../../fournisseur/state/fournisseur.model';

export interface Product {
  id: ID;
  name: string;
  fournisseur: Fournisseur;
}

export function createProduct(params: Partial<Product>) {
  return {

  } as Product;
}
