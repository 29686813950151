<div [formGroup]="form">
  <div class="pb-3" *ngIf="form.get('isContact')?.value && recueilNaturalPerson">Votre adresse email de contact est utilisée pour vous connecter. Pour la modifier, contactez votre conseiller.</div>
  <div class="grid p-fluid">
    <div class="field col-3" *ngIf="advanced">
      <label for="type">Type</label>
      <p-dropdown
        formControlName="type"
        id="type"
        [options]="contactTypeOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="typeF?.touched && typeF?.errors" [fieldError]="typeF?.errors"></lib-field-error>
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="label">Description</label>
      <input formControlName="label" id="label" type="text" size="30" pInputText class="inputfield">
    </div>
    <div class="field col-3">
      <label for="email-input">Email</label>
      <input formControlName="email" id="email-input" type="text" size="30" pInputText class="inputfield">
      <lib-field-error *ngIf="emailF?.errors" [fieldError]="emailF?.errors"></lib-field-error>
    </div>
    <div class="field col-3 flex flex-column" *ngIf="advanced && !recueilNaturalPerson">
      <label for="isContact">Email de contact</label>
      <div class="flex align-items-center">
        <div [ngClass]="{'text-400' : value.isContact}">Non</div>
        <p-inputSwitch  id="isContact"
                        formControlName="isContact"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                        (onChange)="onIsContactChange($event)"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.isContact}">Oui</div>
      </div>
    </div>

  </div>
</div>
