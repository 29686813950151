import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Fournisseur } from './fournisseur.model';

export interface FournisseurState extends EntityState<Fournisseur> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'fournisseurs'
})
export class FournisseurStore extends EntityStore<FournisseurState> {

  constructor() {
    super();
  }

}
