import { HttpClient } from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {catchError, map, tap} from 'rxjs/operators';
import {SessionState, SessionStore} from './session.store';
import {of} from 'rxjs';
import {resetStores} from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(@Inject('config') private config: any, private sessionStore: SessionStore, private http: HttpClient) {
  }

  login(data: {username: string; password: string}) {
    return this.http.post<SessionState>(`${this.config.apiUrl}/login_check`, data)
      .pipe(tap(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        this.sessionStore.login(user);
      }));
  }

  logout() {
    // remove user from local storage to log user out
    this.sessionStore.logout();
    resetStores({ exclude: ['uiScreen'] });
  }

  checkTokenValidity(token: string|null) {
    return this.http.get<SessionState>(`${this.config.apiUrl}/check_token`, {headers: {Authorization: `Bearer ${token}`}})
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  refreshToken(data: {refresh_token: string|null}) {
    return this.http.post<SessionState>(`${this.config.apiUrl}/token/refresh`, data);
  }
}
