import {
  Component,
  forwardRef, Input,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {distinctUntilChanged, Subscription} from 'rxjs';
import {DropdownOption, DropdownOptionsService} from 'common';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneFormComponent),
      multi: true
    }
  ]
})
export class PhoneFormComponent implements ControlValueAccessor, OnDestroy{
  @Input() toogleIsContact: any;
  // sert a conditionner l'affichage de certains champs
  @Input() advanced = false;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  contactTypeOptions: DropdownOption<any>[] = [];
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  phoneNumberFormat = PhoneNumberFormat;
  selectedCountryCode =  CountryISO.France;
  initialValues =
    {
      id: null,
      dialCode: null,
      number: null,
      countryCode: 'fr',
      isContact: null,
      phone: null,
      type: null,
      label: null,
    };
  constructor(private formBuilder: UntypedFormBuilder,private dropdownOptionsService: DropdownOptionsService) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.phoneF.valueChanges.subscribe(() => {
        this.onPhoneChange();
      }),
      this.form.valueChanges
        .pipe(distinctUntilChanged((prev, curr) => {
          const changed =
            curr.number !== prev.number ||
            curr.dialCode !== prev.dialCode ||
            curr.isContact !== prev.isContact ||
            curr.label !== prev.label ||
            curr.type !== prev.type;
          return !changed;
        }))
        .subscribe(value => {
          const { phone, ...rest } = value;
          this.onChange(rest);
          this.onTouched();
      })
    );
    this.contactTypeOptions = this.dropdownOptionsService.contactType;
  }

  get typeF(): any {
    return this.form.get('type');
  }

  get phoneF(): any {
    return this.form.get('phone');
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    this.selectedCountryCode = value.countryCode ? value.countryCode : this.countryISO.France;
    this.value = {
      id: value.id,
      dialCode: value.dialCode,
      countryCode: value.countryCode,
      isContact: value.isContact,
      phone: value.number,
      number: value.number,
      type: value.type,
      label: value.label,
    };
  }

  onPhoneChange() {
    if(this.phoneF.value?.number !== undefined){
      this.form.patchValue({
          number: this.phoneF.value?.number,
      }, {onlySelf: true, emitEvent:false});
    }
    if(this.phoneF.value?.dialCode !== undefined){
      this.form.patchValue({
        dialCode: this.phoneF.value?.dialCode,
      }, {onlySelf: true, emitEvent:false});
    }
    if(this.phoneF.value?.countryCode !== undefined){
      this.form.patchValue({
        countryCode: this.phoneF.value?.countryCode,
      }, {onlySelf: true, emitEvent:false});
    }
  }

  onIsContactChange(event: any) {
    if(event.checked){
      this.toogleIsContact.callParentMethod();
      this.form.get('isContact')?.patchValue(true,{onlySelf: true, emitEvent:false});
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

}
