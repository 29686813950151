import { Inject, Injectable } from '@angular/core';
import { FournisseurState, FournisseurStore } from './fournisseur.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class FournisseurService extends NgEntityService<FournisseurState>{

  constructor(
    @Inject('config') public override config: any,
    protected override store: FournisseurStore
  ) {
    super(store);
  }

}
