import {ID} from '@datorama/akita';

export interface BienDiversType {
  id?: ID;
  label: string;
  code: string;
  position: number;
}

export function createBienDiversType(params: Partial<BienDiversType>) {
  return {

  } as BienDiversType;
}
