import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import {TableModule} from 'primeng/table';
import {SidebarModule} from 'primeng/sidebar';
import {ToolbarModule} from 'primeng/toolbar';
import {RippleModule} from 'primeng/ripple';
import { FieldErrorComponent } from './field-error/field-error.component';
import {DropdownModule} from 'primeng/dropdown';
import {SelectButtonModule} from 'primeng/selectbutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import { SignatureMakerComponent } from './signature-maker/signature-maker.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {PasswordModule} from 'primeng/password';
import {DividerModule} from 'primeng/divider';
import {SpeedDialModule} from 'primeng/speeddial';
import { ChipModule } from 'primeng/chip';
import { InputNumberComponent } from './input-number/input-number.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { SkeletonModule } from 'primeng/skeleton';
import { FormGuardDialogComponent } from './form-guard-dialog/form-guard-dialog.component';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {BadgeModule} from 'primeng/badge';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {MenuModule} from 'primeng/menu';
import {KeyFilterModule} from 'primeng/keyfilter';
import {InplaceModule} from 'primeng/inplace';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {StepsModule} from 'primeng/steps';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {FileUploadModule} from 'primeng/fileupload';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import {PanelMenuModule} from 'primeng/panelmenu';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input-gg';



@NgModule({
  declarations: [
    FieldErrorComponent,
    SignatureMakerComponent,
    InputNumberComponent,
    FormGuardDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    InputNumberModule,
    AutoCompleteModule,
    DropdownModule,
    SelectButtonModule,
    RadioButtonModule,
    MultiSelectModule,
    CalendarModule,
    InputSwitchModule,
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    ToastModule,
    TableModule,
    SidebarModule,
    ToolbarModule,
    RippleModule,
    PasswordModule,
    DividerModule,
    SpeedDialModule,
    ChipModule,
    ProgressSpinnerModule,
    TabViewModule,
    TagModule,
    BadgeModule,
    SkeletonModule,
    ScrollPanelModule,
    MenuModule,
    NgxIntlTelInputModule,
    KeyFilterModule,
    InplaceModule,
    CheckboxModule,
    DragDropModule,
    StepsModule,
    FullCalendarModule,
    InputTextareaModule,
    FileUploadModule,
    PanelMenuModule
  ],
  exports: [
    ButtonModule,
    CardModule,
    InputTextModule,
    InputNumberModule,
    AutoCompleteModule,
    DropdownModule,
    SelectButtonModule,
    RadioButtonModule,
    SignatureMakerComponent,
    CalendarModule,
    MultiSelectModule,
    DialogModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    ToastModule,
    SidebarModule,
    TableModule,
    ToolbarModule,
    RippleModule,
    InputSwitchModule,
    FieldErrorComponent,
    PasswordModule,
    DividerModule,
    SpeedDialModule,
    ChipModule,
    InputNumberComponent,
    ProgressSpinnerModule,
    FormGuardDialogComponent,
    TabViewModule,
    TagModule,
    BadgeModule,
    SkeletonModule,
    ScrollPanelModule,
    MenuModule,
    NgxIntlTelInputModule,
    KeyFilterModule,
    InplaceModule,
    CheckboxModule,
    DragDropModule,
    StepsModule,
    InputTextareaModule,
    FullCalendarModule,
    FileUploadModule,
    PanelMenuModule
  ]
})
export class UiModule {}
