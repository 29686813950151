import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { RecueilStore, RecueilState } from './recueil.store';

@Injectable({ providedIn: 'root' })
export class RecueilService extends NgEntityService<RecueilState> {

  constructor(protected override store: RecueilStore) {
    super(store);
  }

}
