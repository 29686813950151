import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {MessageService} from 'primeng/api';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  Comment,
  DropdownOption,
  DropdownOptionsService,
  NavigationService, NotificationStatus,
  PoleInterne,
  SessionQuery,
  SessionState,
  Task,
  TaskHelperService,
  TaskQuery,
  TaskService,
  TaskStatus,
  TranslatorService,
  UserQuery,
  UserRole,
  NotificationQuery,
  NotificationService,
  UiScreenQuery
} from 'common';
import {addWeekDays, weekDays} from 'moment-business';
import * as moment from 'moment';
import {take} from 'rxjs';

type StatusOptionType = { label: string; value: string; disabled: boolean};

@UntilDestroy()
@Component({
  selector: 'app-page-task-internal',
  templateUrl: './page-task-internal.component.html',
  styleUrls: ['./page-task-internal.component.scss'],
})
export class PageTaskInternalComponent implements OnInit {

  user: SessionState;
  leaderOrPoleManager: boolean;
  isDirigeantUser: boolean;
  isFormEditing: boolean;
  taskEditing: Task;
  taskRemainingTime: 'OK' | 'WARNING' | 'EXCEED';
  rawTaskStatus = TaskStatus;
  isLeaderPrivateTask = false;
  isPrivateTask = false;
  isEditable = true;
  isFormReady = false;
  realWorkloadBeforeEdition!: number|undefined;
  deltaDateBeforeEditing!: number;
  comesFromTimeline: boolean;
  comesFromMondayReview = false;
  // Form.
  form = this.formBuilder.group({
    name: [null, Validators.required],
    startDate: [null, Validators.required],
    deadlineDate: [null, Validators.required],
    closingDate: null,
    importance: [null, Validators.required],
    plannedWorkload: [null],
    realWorkload: [null],
    poles: [null, Validators.required],
    collaborator: [null],
    contractor: [null, Validators.required],
    comments: this.formBuilder.array([this.formBuilder.group({
      author: {id: this.sessionQuery.getValue().id},
      content: '',
      assignedUsers: this.formBuilder.array([['']]),
      resolved: false,
      blocked: false,
      creationDate: new Date(),
    })]),
    status: [null],
    isInternal: [null],
    isPriority: [null],
    isPrivate: [null],
    isLeaderPrivate: [null],
  });
  // Form default values and options.
  startDateDefaultValue = new Date();
  deadLineDefaultValue = new Date(
    new Date().setDate(this.startDateDefaultValue.getDate() + 1)
  );
  importanceOptions: { label: string; value: any }[] = [];
  polesOptions: { label: string; value: any }[] = [];
  collaboratorOptions: { label: string; value: any }[] = [];
  contractorOptions: { label: string; value: any }[] = [];
  statusOptions: StatusOptionType[] = [];
  contractorDefaultValue: { label: string; value: any };
  statusBeforeEditing!: string;
  displayCloseDate = false;
  today = new Date();
  taskHasBlockedComment: boolean;
  blockingCommentWarningState = false;

  constructor(
    private sessionQuery: SessionQuery,
    private formBuilder: UntypedFormBuilder,
    public translatorService: TranslatorService,
    private userQuery: UserQuery,
    private dropdownService: DropdownOptionsService,
    private navigation: NavigationService,
    private taskService: TaskService,
    private taskQuery: TaskQuery,
    private router: Router,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private taskHelperService: TaskHelperService,
    private notificationQuery: NotificationQuery,
    public uiScreenQuery: UiScreenQuery,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  get name() { return this.form?.get('name'); }
  get startDate() { return this.form?.get('startDate'); }
  get deadlineDate() { return this.form?.get('deadlineDate'); }
  get closingDate() { return this.form?.get('closingDate'); }
  get importance() { return this.form?.get('importance'); }
  get plannedWorkload() { return this.form?.get('plannedWorkload'); }
  get realWorkload() { return this.form?.get('realWorkload'); }
  get poles() { return this.form?.get('poles'); }
  get collaborator() { return this.form?.get('collaborator'); }
  get contractor() { return this.form?.get('contractor'); }
  getComments() { return this.form?.get('comments') as UntypedFormArray; }
  get isInternal() { return this.form?.get('isInternal'); }
  get isPriority() { return this.form?.get('isPriority'); }
  get isPrivate() { return this.form?.get('isPrivate'); }
  get status() { return this.form?.get('status'); }
  get isLeaderPrivate() { return this.form?.get('isLeaderPrivate'); }
  // @ts-ignore
  get taskLatestCommentAssignedUser() { return this.form?.get('comments').controls.slice(-1)[0]?.get('assignedUsers') as UntypedFormArray;};
  // @ts-ignore
  get taskLatestCommentContent() { return this.form?.get('comments').controls.slice(-1)[0]?.get('content') as UntypedFormArray;};

  ngOnInit(): void {
    this.getCurrentUserInformation();
    this.checkUser();
    this.initForm();
    this.setInitializedForm();
  }

  getCurrentUserInformation() {
    this.sessionQuery.select().pipe(untilDestroyed(this)).subscribe((sessionUser) => {
      if (sessionUser && sessionUser.id) {
        this.userQuery.selectEntity(sessionUser.id).subscribe((user) => {
          if (user) {
            this.leaderOrPoleManager = user.roles.includes(UserRole.ROLE_DIRIGEANT) ||
              user.roles.includes(UserRole.ROLE_RESPONSABLE_POLE);
          }
        });
      }
    });
  }

  checkUser() {
    this.sessionQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe((currentUser) => {
        this.user = currentUser;
        this.isDirigeantUser = currentUser.roles.includes(UserRole.ROLE_DIRIGEANT);
        this.contractorDefaultValue = {
          label: currentUser.lastname + ' ' + currentUser.firstname,
          value: {id: currentUser.id},
        };
      });
  }

  initForm() {
    this.setPolesOptions();
    this.setCollaboratorsOptions();
    this.setTaskStatusOptions();
  }

  setPolesOptions() {
    const allPoles: string[] = Object.values(PoleInterne);
    this.translatorService.getTranslation(allPoles).subscribe(allTranslatedPoles=>{
      this.polesOptions = [];
      Object.entries(allTranslatedPoles).map(([value, label])=>{
        this.polesOptions = [
          ...this.polesOptions,
          {
            label: label as string,
            value,
          },
        ];
      });
    });
  }

  setCollaboratorsOptions() {
    this.userQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        const collaborators = users.filter((user) =>
          user.roles.includes(UserRole.ROLE_COLLABORATEUR)
        );
        const fullCollaboratorOptions = collaborators.map((collaborator) => this.dropdownService.mapUserIntoDropdownOption(collaborator))
          .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));

        this.contractorOptions = fullCollaboratorOptions;
        const collaboratorOptions = fullCollaboratorOptions;
        // @ts-ignore
        collaboratorOptions.unshift({label: 'Aucun', value: null});

        this.collaboratorOptions = collaboratorOptions;
      });
  }

  // Set task taskForm status field options.
  setTaskStatusOptions() {
    let statusOptions: [] | StatusOptionType[] = [];
    Object.keys(this.rawTaskStatus).map((status) => {
      this.translatorService
        .getTranslation(status)
        .pipe(untilDestroyed(this))
        .subscribe((translatedStatus: string) => {
          statusOptions = [
            ...statusOptions,
            { label: translatedStatus, value: status, disabled: status === this.rawTaskStatus.PLANNED },
          ];
        });
    });
    this.statusOptions = statusOptions;
  }

  setInitializedForm() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        if (params['from'] === 'mondayReview') {
          this.comesFromMondayReview = true;
        }
        if (params['from'] === 'timeline') {
          this.comesFromTimeline = true;
        }
        if (params['id']) {
          this.taskService
            .get(params['id'])
            .pipe(untilDestroyed(this))
            .subscribe();
          this.taskQuery
            .selectEntity(params['id'])
            .pipe(untilDestroyed(this))
            .subscribe((editingTask) => {
              if (editingTask) {
                this.isFormReady = false;
                // on detache la detection des changement le temps de maj le form, cela evite des bug des references
                this.cdr.detach();
                this.isFormEditing = true;
                this.taskEditing = editingTask;
                this.statusBeforeEditing = this.taskEditing.status;
                this.realWorkloadBeforeEdition = this.taskEditing.realWorkload;
                this.deltaDateBeforeEditing = weekDays(moment(this.taskEditing.startDate, 'YYYYMMDD').clone(), moment(this.taskEditing.deadlineDate, 'YYYYMMDD').clone());
                this.taskRemainingTime = this.taskHelperService.calculateDeadlineIndicator( new Date(this.taskEditing.startDate), new Date(this.taskEditing.deadlineDate));
                this.isPrivateTask = this.taskEditing.isPrivate;
                this.isLeaderPrivateTask = this.taskEditing.isLeaderPrivate;

                if (this.taskEditing.status === this.rawTaskStatus.DONE) {
                  this.isEditable = false;
                  this.cdr.reattach();
                  this.cdr.markForCheck();
                } else {
                  this.form = this.taskHelperService.populateInternalTaskEditingForm(editingTask);
                  this.cdr.reattach();
                  this.isFormReady =  true;
                }

                if (this.taskEditing.comments) {
                  this.taskHasBlockedComment = this.taskEditing.comments.some((comment: Comment) => comment.blocked && !comment.resolved);
                }
              }
            });
        } else {
          this.setDefaultStartAndEndDate();
          this.setDefaultContractor(); // Set current logged user as default contractor.
          this.setDefaultPole();
          this.setDefaultImportance();
          this.setDefaultPlannedWorkLoad();
          this.isFormReady = true;
        }
      });
  }

  // Set creation taskForm start and deadline dates.
  setDefaultStartAndEndDate() {
    this.startDate?.setValue(new Date());
    this.deadlineDate?.setValue(new Date(new Date().setDate(new Date().getDate() + 1)));
  }

  // Set creation taskForm current logged user as default contractor.
  setDefaultContractor() {
    this.contractor?.setValue({id: this.user.id});
  }

  // Set creation taskForm pole.
  setDefaultPole() {
    this.poles?.setValue(PoleInterne.CABINET);
  }

  // Set creation taskForm importance.
  setDefaultImportance() {
    this.importance?.setValue(1);
  }

  // Set creation taskForm planned work load.
  setDefaultPlannedWorkLoad() {
    this.plannedWorkload?.setValue(45);
  }

  getStartDate(){
    return new Date(this.startDate?.value);
  }

  getDeadlineDate(){
    return new Date(this.deadlineDate?.value);
  }

  onSelectCheckboxIsLeaderPrivate(event: any) {
    this.isLeaderPrivate?.setValue(event.checked);
    this.isPrivate?.setValue(false);
    this.isPrivateTask = false;
  }

  onSelectCheckboxIsPrivate(event: any) {
    this.isPrivate?.setValue(event.checked);
    this.isLeaderPrivate?.setValue(false);
    this.isLeaderPrivateTask = false;
  }

  addCommentAssignedUser() {
    this.taskLatestCommentAssignedUser.push(new UntypedFormControl('', Validators.required));
  }

  removeCommentAssignedUser(index: number) {
    this.taskLatestCommentAssignedUser.removeAt(index);
  }

  onStatusClick(selectedStatus: string) {
    if (selectedStatus === this.rawTaskStatus.DONE){
      // affichage date de cloture
      this.closingDate?.setValue(new  Date());
      this.displayCloseDate = true;
      // date cloture et charge reelle obligatoire
      this.closingDate?.setValidators([Validators.required]);
      this.realWorkload?.setValidators([Validators.required]);
      //si pas de realworkload, prerempli avec plannedWorkload
      if (this.realWorkload?.value === null){
        this.realWorkload?.setValue(this.plannedWorkload?.value);
      }
      //si pas de collaborateur assigné, prerempli avec user connecté
      if (this.collaborator?.value === null){
        this.collaborator?.setValue({id: this.user.id});
      }
    } else {
      this.displayCloseDate = false;
      this.closingDate?.setValidators([]);
      this.closingDate?.setValue(null);
      this.realWorkload?.setValidators([]);
      this.realWorkload?.setValue(this.realWorkloadBeforeEdition);
    }
    //si StatusTODO, startDAte = today
    //si StatusDOING et previous status != StatusTODO, startDAte = today
    if (selectedStatus === this.rawTaskStatus.TODO ||
      (selectedStatus === this.rawTaskStatus.DOING && this.statusBeforeEditing !== this.rawTaskStatus.TODO)
    ){
      this.startDate?.setValue(new Date());
      //decalage deadlineDate function of startDate
      this.deadlineDate?.setValue(addWeekDays(moment(new Date()).clone(), this.deltaDateBeforeEditing).toDate());
    }
  }

  onStartDateSelect(event: Date){
    const selectedDate = event.toLocaleDateString('fr');
    const today = new Date().toLocaleDateString('fr');
    //si startdate > today : statusPLANNED
    if(selectedDate > today){
      this.status?.setValue(this.rawTaskStatus.PLANNED);
    }
    //si startdate = today : statusTODO
    if(selectedDate === today){
      this.status?.setValue(this.rawTaskStatus.TODO);
    }
    //decalage deadlineDate function of startDate
    this.deadlineDate?.setValue(addWeekDays(moment(event).clone(), this.deltaDateBeforeEditing).toDate());
  }

  formCancel() {
    this.navigation.back();
  }

  formDelete() {
    this.taskService
      .delete(this.taskEditing.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskInternalDeleteSuccess();
        },
        error: () => {
          this.onSaveError();
        },
      });
  }

  formSubmit() {
    const commentsArrayLength = this.form.value.comments.length;
    // @ts-ignore
    const latestCommentFormControl = this.form.controls.comments.controls[commentsArrayLength - 1].controls;
    const latestCommentFormValue = this.form.value.comments[commentsArrayLength - 1];

    // reinit latest comment validators
    //ici la validation n'est pas dynamique maise seulement au submit, donc on reset les validateurs avant de les recalculer
    latestCommentFormControl.assignedUsers.controls[0].setErrors(null);
    latestCommentFormControl.assignedUsers.setErrors(null);
    latestCommentFormControl.content.setErrors(null);

    // When the comment does not have a user assigned, the dropdown defaults to null, removes it.
    latestCommentFormValue.assignedUsers = latestCommentFormValue.assignedUsers.filter((item: any) => item !== null && item !== '');


    // Création d'un commentaire :
    // Erreur si commentaire marqué comme point bloquant, mais sans collaborateur assigné
    if (latestCommentFormValue.blocked && latestCommentFormValue.assignedUsers.length === 0) {
      latestCommentFormControl.assignedUsers.controls[0].setErrors({ required: true });
      latestCommentFormControl.assignedUsers.setErrors({ required: true });
    }

    // Création d'un commentaire :
    // Erreur si commentaire marqué comme point bloquant, mais sans contenu
    if (latestCommentFormValue.blocked && latestCommentFormValue.content === '') {
      latestCommentFormControl.content.setErrors({ required: true });
    }

    // Création d'un commentaire :
    // Erreur si présence d'un collaborateur assigné, mais contenu du commentaire est vide
    if (latestCommentFormValue.assignedUsers.length > 0 && latestCommentFormValue.content === '') {
      latestCommentFormControl.content.setErrors({ required: true });
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    // New tasks that'll be persiste.
    const newInternalTask: Task = {
      name: this.name?.value,
      status: this.isFormEditing
        ? this.status?.value
        : this.taskHelperService.calculateStatus(this.startDate?.value, TaskStatus.PLANNED),
      startDate: this.taskHelperService.convertStartDateToUTC(this.startDate?.value),
      deadlineDate: this.taskHelperService.convertDeadlineDateToUTC(this.deadlineDate?.value),
      closingDate: this.closingDate?.value,
      importance: this.importance?.value,
      plannedWorkload: this.plannedWorkload?.value,
      realWorkload: this.realWorkload?.value,
      pole: this.poles?.value,
      collaborator: this.collaborator?.value,
      contractor: this.contractor?.value,
      isInternal: true,
      isPriority: this.isPriority?.value ?? false,
      isPrivate: this.isPrivate?.value ?? false,
      isLeaderPrivate: this.isLeaderPrivate?.value ?? false,
    };

    newInternalTask.comments = this.form.value.comments;
    // Remove comment if content is empty.
    const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
    newInternalTask.comments = this.form.value.comments.filter((comment: any) =>
      !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

    if (!this.isFormEditing) {
      this.createTaskInternal(newInternalTask);
    }
    if (this.isFormEditing) {
      this.updateTaskInternal(newInternalTask);
    }
  }

  unlockComment(commentId: number) {
    // Update blocked comment notification state from 'unread' > 'read'.
    const relatedNotificationLink = '/task-internal-edit/' + this.taskEditing.id + '#' + commentId;
    this.notificationQuery.selectAll({
      filterBy: [
        (entity) => entity.link === relatedNotificationLink
      ]
    }).pipe(untilDestroyed(this)).pipe(take(1)).subscribe((notifications) => {
      if (notifications) {
        notifications.map((notification) => {
          this.notificationService.update(notification.id, {state: NotificationStatus.READ}).pipe(untilDestroyed(this)).subscribe();
        });
      }
    });

    // Resolve comment.
    const comments = this.form.value.comments;
    const updatedCommentIndex = comments.findIndex((comment: any) => comment.id === commentId);
    this.form.value.comments[updatedCommentIndex].resolved = true;

    // Handle comment without assigned user.
    const commentsArrayLength = this.form.value.comments.length;
    const latestCommentAssignedUsersRaw = this.form.value.comments[commentsArrayLength - 1].assignedUsers;
    this.form.value.comments[commentsArrayLength - 1].assignedUsers = latestCommentAssignedUsersRaw.filter((item: any) => item !== null);

    // Set the "assigned collaborator(s)" field to error if the comment is blocking and no collaborator(s) are assigned.
    if (this.form.value.comments[commentsArrayLength - 1].blocked
      && this.form.value.comments[commentsArrayLength - 1].assignedUsers.length === 0) {
      this.form.value.comments[commentsArrayLength - 1].assignedUsers.controls[0].setErrors({required: true});
      return;
    }

    // Handle comment without content.
    const regexForEmptyComment = new RegExp(/^<p> *<\/p>$/);
    this.form.value.comments = this.form.value.comments.filter((comment: any) =>
      !(regexForEmptyComment.test(comment.content) || comment.content === '' || comment.content === null));

    this.updateComment(this.form.value);
  }

  createTaskInternal(taskInternal: Task) {
    this.taskService
      .add(taskInternal)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskInternalCreationSuccess();
        },
        error: () => {
          this.onSaveError();
        },
      });
  }

  updateTaskInternal(taskInternal: Task) {
    this.taskService
      .update(this.taskEditing.id, taskInternal)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onTaskInternalUpdateSuccess();
        },
        error: () => {
          this.onSaveError();
        },
      });
  }

  updateComment(task: Task) {
    this.taskService
      .update(this.taskEditing.id, task)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.onCommentUpdateSucces();
        },
        error: () => {
          this.onCommentUpdateError();
        },
      });
  }

  onTaskInternalCreationSuccess() {
    if (this.comesFromMondayReview) {
      this.router.navigate(['/monday-review']);
    } else if (this.comesFromTimeline){
      this.router.navigate(['/timeline']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'La nouvelle tâche a été créée',
      life: 10000,
    });
  }

  onTaskInternalUpdateSuccess() {
    if (this.comesFromMondayReview) {
      this.router.navigate(['/monday-review']);
    } else if (this.comesFromTimeline){
      this.router.navigate(['/timeline']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Modifications enregistrées',
      life: 10000,
    });
  }

  onTaskInternalDeleteSuccess() {
    if (this.comesFromMondayReview) {
      this.router.navigate(['/monday-review']);
    } else if (this.comesFromTimeline){
      this.router.navigate(['/timeline']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'La tâche a été supprimée',
      life: 10000,
    });
  }

  onSaveError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000,
    });
  }

  onCommentUpdateSucces() {
    this.messageService.add({
      severity: 'success',
      summary: 'Commentaire débloqué avec succès',
      life: 10000,
    });
  }

  onCommentUpdateError() {
    this.messageService.add({
      severity: 'success',
      summary: 'Une erreur est survenue lors du déblocage du commentaire',
      life: 10000,
    });
  }

  // Used to display a warning comment when user choose to block a comment.
  blockingCommentWarning() {
    this.blockingCommentWarningState = !this.blockingCommentWarningState;
  }
}
