import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ConfigurationStore, ConfigurationState } from './configuration.store';
import {ConfigurationCode} from '../../../enums/ConfigurationCode';

@Injectable({ providedIn: 'root' })
export class ConfigurationQuery extends QueryEntity<ConfigurationState> {

  selectLogoutInactivityDuration$= this.selectAll({
    filterBy: [
      (entity) => entity.code === ConfigurationCode.LOGOUT_INACTIVITY_DURATION
    ]
  });

  constructor(protected override store: ConfigurationStore) {
    super(store);
  }
}
