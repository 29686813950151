<div [formGroup]="form">
  <div class="grid p-fluid" *ngIf="advanced">
    <div class="field col-3">
      <label for="type">Type</label>
      <p-dropdown
        formControlName="type"
        id="type"
        [options]="addressTypeOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
    </div>
    <div class="field col-3">
      <label for="label">Description</label>
      <input formControlName="label" id="label" type="text" size="30" pInputText class="inputfield w-full">
    </div>
    <div class="field col-3 flex flex-column">
      <label for="fiscale">Adresse fiscale</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.fiscale}">Non</div>
        <p-inputSwitch  id="fiscale"
                        formControlName="fiscale"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                        (onChange)="onFiscaleChange($event)"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.fiscale}">Oui</div>
      </div>
    </div>
    <div class="field col-3 flex flex-column">
      <label for="isContact">Adresse de contact</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.isContact}">Non</div>
        <p-inputSwitch  id="isContact"
                        formControlName="isContact"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
                        (onChange)="onIsContactChange($event)"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.isContact}">Oui</div>
      </div>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-6">
      <label for="street-input">N° de voie</label>
      <input pInputText formControlName="street" id="street-input" type="text" placeholder="Numéro et rue"  #addressText  (change)="onAddressChange()">
      <lib-field-error *ngIf="streetF?.touched && streetF?.errors" [fieldError]="streetF?.errors"></lib-field-error>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="city-input">Ville</label>
      <input formControlName="city" id="city-input" type="text" size="30" pInputText class="inputfield">
      <lib-field-error *ngIf="cityF?.touched && cityF?.errors" [fieldError]="cityF?.errors"></lib-field-error>
    </div>
    <div class="field col-3">
      <label for="zipCode-input">Code postal</label>
      <input formControlName="zipCode" id="zipCode-input" type="text" size="30" pInputText class="inputfield">
      <lib-field-error *ngIf="zipCodeF?.touched && zipCodeF?.errors" [fieldError]="zipCodeF?.errors"></lib-field-error>
    </div>
    <div class="field col-3">
      <label for="state-input">Région</label>
      <input formControlName="state" id="state-input" type="text" size="30" pInputText class="inputfield">
      <lib-field-error *ngIf="stateF?.touched && stateF?.errors" [fieldError]="stateF?.errors"></lib-field-error>
    </div>
    <div class="field col-3">
      <label for="country-input">Pays</label>
      <input formControlName="country" id="country-input" type="text" size="30" pInputText class="inputfield">
      <lib-field-error *ngIf="countryF?.touched && countryF?.errors" [fieldError]="countryF?.errors"></lib-field-error>
    </div>
  </div>
</div>
