import { ID } from '@datorama/akita';
import { StepMaster } from '../../stepMaster/state/step-master.model';
import { TaskMaster } from '../../taskMaster/state/task-master.model';

export interface GroupMaster {
  id: ID;
  position: number;
  delayBefore: number;
  stepMasters?: StepMaster[];
  taskMasters?: TaskMaster[];
}

export function createGroupMaster(params: Partial<GroupMaster>) {
  return {

  } as GroupMaster;
}
