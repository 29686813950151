<div class="w-11 m-auto">
  <h2 class="my-7" *ngIf="!isEdit">Créer un produit</h2>
  <h2 class="my-7" *ngIf="isEdit">Éditer un produit</h2>
  <ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
    <app-desktop-only></app-desktop-only>
  </ng-container>
  <ng-template #desktop>
    <!--  FORMULAIRE  -->
    <app-product-form></app-product-form>
    <!--  BOUTONS D'ACTION DU FORMULAIRE  -->
    <div class="flex justify-content-end mt-5">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="onCancel()"></p-button>
      <p-button type="submit" label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="onSubmit()"></p-button>
    </div>
    <!--  FORMULAIRE  -->
  </ng-template>
</div>
