import { Injectable } from '@angular/core';
import {ClientOptionValue, DropdownOption, StepAndTaskOptionValue} from '../types/DropdownOption';
import { NaturalPerson } from '../store/client/naturalPerson/state/natural-person.model';
import { LegalPerson } from '../store/client/legalPerson/state/legal-person.model';
import { User } from '../store/user/state/user.model';
import { NaturalNaturalRelationType } from '../store/client/natural-natural-relation-type/state/natural-natural-relation-type.model';
import { TranslateService } from '@ngx-translate/core';
import { ID } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { NaturalPersonQuery } from '../store/client/naturalPerson/state/natural-person.query';
import { LegalPersonQuery } from '../store/client/legalPerson/state/legal-person.query';
import { PoleInterne } from '../enums/user/PoleInterne';
import { UserQuery } from '../store/user/state/user.query';
import { StepMasterQuery } from '../store/task/stepMaster/state/step-master.query';
import { StepMaster } from '../store/task/stepMaster/state/step-master.model';
import { LegalStatus } from '../enums/LegalStatus';
import { CustomerOrigin } from '../enums/client/CustomerOrigin';
import {
  NaturalNaturalRelationTypeQuery
} from '../store/client/natural-natural-relation-type/state/natural-natural-relation-type.query';
import { ClientStatus } from '../enums/client/ClientStatus';
import {Civility, MesureProtection} from '../enums/client/Civility';
import {PoleClient} from '../enums/user/PoleClient';
import {DetenteurBien, ImmobilierNature, ImmobilierType, ModeDetention} from '../enums/client/ImmobilierEnums';
import grouped, {
  CreditType, ObjetPret,
  SouscripteurAvoriFinancier,
  SouscripteurCredit
} from '../enums/client/FinancierEnums';
import {YesNo} from '../enums/client/RecueilEnums';
import {RegimeMatrimonial, RegimePacsimonial, SituationMatrimoniale} from '../enums/client/MatrimonialeEnums';
import {ProfessionalSituation} from '../enums/client/ProfessionalSituationEnums';
import {AddressType, ContactType} from '../enums/client/ContactEnums';
import {SegmentationClient} from '../enums/client/SegmentationClient';
import {
  AChargeFiscale,
  Adoption,
  EnfantDe,
  Handicap,
  LienPersonne,
} from '../enums/client/PersonneChargeEnums';

@Injectable({
  providedIn: 'root',
})
export class DropdownOptionsService {
  constructor(
    private translateService: TranslateService,
    private naturalPersonQuery: NaturalPersonQuery,
    private legalPersonQuery: LegalPersonQuery,
    private stepMasterQuery: StepMasterQuery,
    private userQuery: UserQuery,
    private naturalNaturalRelationTypeQuery: NaturalNaturalRelationTypeQuery,
  ) {}

  get clients() {
    const naturalPersons$ = this.naturalPersonQuery.selectAll();
    const legalPersons$ = this.legalPersonQuery.selectAll();

    const naturalPersonsLoading$ = this.naturalPersonQuery.selectLoading();
    const legalPersonsLoading$ = this.legalPersonQuery.selectLoading();

    let clients: DropdownOption<ClientOptionValue>[] = [];

    combineLatest([
      naturalPersons$,
      legalPersons$,
      naturalPersonsLoading$,
      legalPersonsLoading$,
    ]).subscribe(
      ([
        naturalPersons,
        legalPersons,
        isNaturalPersonsLoading,
        isLegalPersonsLoading,
      ]) => {
        if (!isNaturalPersonsLoading && !isLegalPersonsLoading) {
          const naturalPersonsOptions = naturalPersons.map((naturalPerson) =>
            this.mapNaturalPersonIntoDropdownOption(naturalPerson)
          );
          const legalPersonsOptions = legalPersons.map((legalPerson) =>
            this.mapLegalPersonIntoDropdownOption(legalPerson)
          );

          clients = [...naturalPersonsOptions, ...legalPersonsOptions];
        }
      }
    );

    return clients;
  }

  get collaborators() {
    let collaborators: DropdownOption<{id}>[] = [];

    const users = this.userQuery.getAll();
    collaborators = users.map((user) => this.mapUserIntoDropdownOption(user));

    return collaborators.sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  get tasksImportance() {
    return [
      { label: 'Courant', value: 1 },
      { label: 'Important', value: 2 },
      { label: 'Très important', value: 3 },
    ] as DropdownOption[];
  }

  get addressType() {
    return Object.keys(AddressType).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get aChargeFiscale() {
    return Object.keys(AChargeFiscale).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get adoption() {
    return Object.keys(Adoption).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get civility() {
    return Object.keys(Civility).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get clientStatuses() {
    return Object.keys(ClientStatus).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get contactType() {
    return Object.keys(ContactType).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get enfantDe() {
    return Object.keys(EnfantDe).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get lienPersonne() {
    return Object.keys(LienPersonne).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get handicap() {
    return Object.keys(Handicap).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get legalStatuses() {
    // Doit respecter ordre de l'enum
    return Object.keys(LegalStatus).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get customerOrigins() {
    // Doit respecter ordre de l'enum
    return Object.keys(CustomerOrigin).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get immobilierNature() {
    return Object.keys(ImmobilierNature).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get immobilierType() {
    return Object.keys(ImmobilierType).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get detenteurBien() {
    return Object.keys(DetenteurBien).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get modeDetention() {
    return Object.keys(ModeDetention).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get souscripteurCredit() {
    return Object.keys(SouscripteurCredit).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get souscripteurAvoirFinancier() {
    return Object.keys(SouscripteurAvoriFinancier).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get creditType(){
    return Object.keys(CreditType).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get objetPret(){
    return Object.keys(ObjetPret).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get yesNo(){
    return Object.keys(YesNo).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }



  get avoirFinancierType(){
    let groupedAvoirs: any[] = [];
    Object.entries(grouped).map(
      ([key, values], index) => {
        groupedAvoirs = [...groupedAvoirs, {label:this.translateService.instant(key) , value:key, items:[]}];
        values.map(val => {
          groupedAvoirs[index].items = [...groupedAvoirs[index].items, {label:this.translateService.instant(val) , value:val}];
        });
      }
    );
    return groupedAvoirs;
  }

  get relationTypes() {
    // Doit respecter ordre des constantes renvoyées par API
    let relationTypeOptions: DropdownOption[] = [];

    const relationTypes = this.naturalNaturalRelationTypeQuery.getAll();

    relationTypeOptions = relationTypes.map(relationType => this.mapNaturalNaturalRelationTypeIntoDropdownOption(relationType));


    return relationTypeOptions;
  }

  get mesureProtection(){
    return Object.keys(MesureProtection).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get situationPro(){
    return Object.keys(ProfessionalSituation).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get segmentationClient(){
    return Object.keys(SegmentationClient).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get situationMatrimoniale(){
    return Object.keys(SituationMatrimoniale).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get regimeMatrimonial(){
    return Object.keys(RegimeMatrimonial).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get regimePacsimonial(){
    return Object.keys(RegimePacsimonial).map((key) => ({
      label: this.translateService.instant(key),
      value: key,
    }));
  }

  get poles() {
    let poles: [] | any[] = [];

    const rawCustomerPoles = Object.keys(PoleClient).map((key) => ({
      label: this.translateService.instant(key),
      value: key
    }));

    const rawInternalPoles = Object.keys(PoleInterne).map((key) => ({
      label: this.translateService.instant(key),
      value: key
    }));

    poles = [...rawCustomerPoles, ...rawInternalPoles];

    return poles;
  }

  get polesAndCollaborators() {
    let poles: [] | any[] = [];

    const rawCustomerPoles = Object.keys(PoleClient).map((key) => ({
      label: this.translateService.instant(key),
      value: {
        id: key,
        type: 'pole',
      },
    }));

    const rawInternalPoles = Object.keys(PoleInterne).map((key) => ({
      label: this.translateService.instant(key),
      value: {
        id: key,
        type: 'pole',
      },
    }));

    poles = [...rawCustomerPoles, ...rawInternalPoles];

    const collaborators = this.collaborators.map((collaborator) => ({
      ...collaborator,
      value: {
        id: collaborator.value.id,
        type: 'collaborator',
      },
    }));

    return [
      {
        label: 'Autres',
        value: 'other',
        items: [{ label: 'Mes tâches', value: { id: 'MYTASKS', type: 'other' } }],
      },
      { label: 'Pôles', value: 'poles', items: poles },
      { label: 'Collaborateurs', value: 'collaborator', items: collaborators },
    ];
  }

  get steps() {
    let stepsOptions: DropdownOption<StepAndTaskOptionValue>[] = [];

    const steps = this.stepMasterQuery.getAll();
    stepsOptions = this.buildStepsDropdownOptions(steps);

    stepsOptions.push({label: 'Suivi', value: {name: 'Suivi', type: 'STEP'}});

    return stepsOptions;
  }

  get stepsTasks() {
    let stepsTasks: DropdownOption<string>[] = [];

    const steps = this.stepMasterQuery.getAll();
    stepsTasks = this.buildStepsTasksDropdownOptions(steps);

    return stepsTasks;
  }

  mapNaturalPersonIntoDropdownOption(
    naturalPerson: NaturalPerson
  ): DropdownOption<ClientOptionValue> {
    return {
      label: naturalPerson.lastName + ' ' + naturalPerson.firstName,
      value: {
        id: naturalPerson.id as ID,
        type: 'naturalPerson',
      },
    };
  }

  mapLegalPersonIntoDropdownOption(
    legalPerson: LegalPerson
  ): DropdownOption<ClientOptionValue> {
    return {
      label: legalPerson.name,
      value: {
        id: legalPerson.id as ID,
        type: 'legalPerson',
      },
    };
  }

  mapUserIntoDropdownOption(user: User): DropdownOption<{id}> {
    return {
      label: user.lastName + ' ' + user.firstName,
      value: {id: user.id },
    };
  }

  mapNaturalNaturalRelationTypeIntoDropdownOption(
    naturalNaturalRelationType: NaturalNaturalRelationType
  ): any {
    return {
      label: this.translateService.instant(naturalNaturalRelationType.label),
      value: naturalNaturalRelationType,
    };
  }

  buildStepsDropdownOptions(steps: StepMaster[]) {
    return steps.map((step) => ({
      label: step.name,
      value: {
        name: step.name,
        type: 'STEP'
      }
    } as DropdownOption<any>));
  }

  buildStepsTasksDropdownOptions(steps: StepMaster[]) {
    return steps.map((step) => {
      let stepTasks: [] | any[] = [];

      // Récupère toutes les tâches d'une étape (tous groupes confondus)
      step?.groupMasters?.map((group) => {
        group?.taskMasters?.map((task) => (
          stepTasks = [...stepTasks, {
            label: task.name,
            value: {
              name: task.name,
              type: 'TASK'
            },
          }]
        ));
      });

      return {
        label: step.name,
        value: step.name,
        items: stepTasks,
      } as DropdownOption<string>;
    });
  }
}
