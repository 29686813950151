import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DocsToDeleteState {
  docs: any[];
}

export function createInitialState(): DocsToDeleteState {
  return {
    docs: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'docsToDelete', resettable: true })
export class DocsToDeleteStore extends Store<DocsToDeleteState> {

  constructor() {
    super(createInitialState());
  }

}
