
<div class="flex justify-content-end">
  <p-button [loading]="submitLoading" [disabled]="submitLoading" icon="pi pi-save" styleClass="p-button-raised p-button-success p-button-icon-only fixed ml-3" (onClick)="submit()"></p-button>
</div>
<p-steps [model]="steps" [readonly]="false" [(activeIndex)]="stepActiveIndex" styleClass="mt-6 mb-8"></p-steps>
<div [formGroup]="form" class="mt-5">
  <div [hidden]="stepActiveIndex !== 0">
    <h2 class="rec">Objectifs</h2>
    <p-divider></p-divider>
    <app-recueil-objectif formControlName="objectif"></app-recueil-objectif>
    <ng-container *ngIf="client.recueil?.rempliPar === rempliPar.TIERS">
      <h2 class="rec">Ce recueil a été rempli par un tiers</h2>
      <p-card class="rempli-par">
        <div *ngIf="client.recueil?.tiersCivility"><strong>Civilité:</strong> {{client.recueil?.tiersCivility}}</div>
        <div class="flex">
          <div class="mr-2" *ngIf="client.recueil?.tiersFirstName"><strong>Prénom:</strong> {{client.recueil?.tiersFirstName | titlecase}} </div>
          <div *ngIf="client.recueil?.tiersLastName"><strong>Nom:</strong> {{client.recueil?.tiersLastName}} </div>
        </div>
        <div *ngIf="client.recueil?.rempliParNomStructure !== ''"><strong>Structure:</strong> {{client.recueil?.rempliParNomStructure | titlecase}}</div>
        <div *ngIf="client.recueil?.causeRempliParUnTiers"><strong>Cause:</strong> {{translate(client.recueil?.causeRempliParUnTiers)}}</div>
      </p-card>
    </ng-container>
    <app-comment-read title="Commentaire questions filtrantes" [content]="client.recueil?.comQuestionFiltrante"></app-comment-read>
    <app-comment-read title="Commentaire informations civiles" [content]="client.recueil?.comInfoCivile"></app-comment-read>
    <app-comment-read title="Commentaire conjoint" [content]="client.recueil?.comConjoint"></app-comment-read>
    <app-comment-read title="Commentaire personnes à charge" [content]="client.recueil?.comPersonneACharge"></app-comment-read>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading" icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
  <div [hidden]="stepActiveIndex !== 1">
    <app-recueil-immobilier formControlName="immobilier" [clientId]="clientId" [clientType]="clientType" [clientOrAdmin]="'ADMIN'"></app-recueil-immobilier>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading"  icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
  <div [hidden]="stepActiveIndex !== 2">
    <app-recueil-financier formControlName="financier" [clientId]="clientId" [clientType]="clientType" [clientOrAdmin]="'ADMIN'"></app-recueil-financier>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading"  icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
  <div [hidden]="stepActiveIndex !== 3">
    <app-recueil-bien-divers formControlName="bienDivers" [clientId]="clientId" [clientType]="clientType" [clientOrAdmin]="'ADMIN'"></app-recueil-bien-divers>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading"  icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
  <div [hidden]="stepActiveIndex !== 4">
    <app-recueil-autre formControlName="autre" [clientId]="clientId" [clientType]="clientType" [clientOrAdmin]="'ADMIN'"></app-recueil-autre>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading"  icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
  <div [hidden]="stepActiveIndex !== 5">
    <app-recueil-recap [clientId]="clientId" [clientType]="clientType" [clientOrAdmin]="'ADMIN'"></app-recueil-recap>
    <div class="flex justify-content-end mt-5">
      <p-button label="Enregistrer" [loading]="submitLoading" [disabled]="submitLoading"  icon="pi pi-check" styleClass="p-button-raised p-button-success mr-2 mt-6" (click)="submit()"></p-button>
    </div>
  </div>
</div>
