export enum SituationMatrimoniale {
  CELIBATAIRE = 'CELIBATAIRE',
  CONCUBINAGE = 'CONCUBINAGE',
  MARIE = 'MARIE',
  PACSE = 'PACSE',
  SEPARE = 'SEPARE',
  DIVORCE = 'DIVORCE',
  VEUF = 'VEUF',
}

export enum RegimeMatrimonial {
  SEPARATION_DE_BIENS_PURE_ET_SIMPLE = 'SEPARATION_DE_BIENS_PURE_ET_SIMPLE',
  SEPARATION_DE_BIENS_AVEC_ADJONCTION_DE_SOCIETE_ACQUETS = 'SEPARATION_DE_BIENS_AVEC_ADJONCTION_DE_SOCIETE_ACQUETS',
  COMMUNAUTE_UNIVERSELLE = 'COMMUNAUTE_UNIVERSELLE',
  COMMUNAUTE_REDUITE_AUX_ACQUETS = 'COMMUNAUTE_REDUITE_AUX_ACQUETS',
  REGIME_FRANCO_ALLEMAND = 'REGIME_FRANCO_ALLEMAND',
  PARTICIPATION_AUX_ACQUETS = 'PARTICIPATION_AUX_ACQUETS',
}

export enum RegimePacsimonial {
  SEPARATION_DE_BIENS = 'SEPARATION_DE_BIENS',
  INDIVISION = 'INDIVISION',
}

export enum SensDonation {
  MADAME_TO_MONSIEUR = 'MADAME_TO_MONSIEUR',
  MONSIEUR_TO_MADAME = 'MONSIEUR_TO_MADAME',
  MUTUELLE = 'MUTUELLE',
}
