import {Component, forwardRef, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {
  BienDiversTypeQuery, BienDiversType
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-bien-divers-form',
  templateUrl: './bien-divers-form.component.html',
  styleUrls: ['./bien-divers-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BienDiversFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BienDiversFormComponent),
      multi: true
    }
  ]
})
export class BienDiversFormComponent implements ControlValueAccessor, OnDestroy {
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  autresBiens = '';
  autres = false;
  bienDiversTypes: BienDiversType[] = [];
  initialValues = {
    autreBienDivers: null,
    bienDivers: null,
  };
  constructor(private formBuilder: UntypedFormBuilder, private bienDiversTypeQuery: BienDiversTypeQuery) {
    this.form = this.formBuilder.group(this.initialValues);
    this.bienDiversTypeQuery.selectAll().pipe(untilDestroyed(this)).subscribe(biens => {
      this.bienDiversTypes = [];
      biens.map(bien => this.bienDiversTypes = [...this.bienDiversTypes, bien]);
    });

    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  get bienDivers(): UntypedFormControl {
    return this.form.get('bienDivers') as UntypedFormControl;
  }

  get value(): string[] {
    return this.form.get('bienDivers')?.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
}
