import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DocsToAddStore } from './docs-to-add.store';

@Injectable({ providedIn: 'root' })
export class DocsToAddService {

  constructor(private docsToAddStore: DocsToAddStore, private http: HttpClient) {
  }


}
