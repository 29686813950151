import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {fr} from '../assets/i18n/fr';
import {TranslationService} from '../store/translation/state/translation.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root'
})
@UntilDestroy()
export class TranslatorService {
  private availableLanguages = {fr};

  constructor(private translate: TranslateService, private translationService: TranslationService) {
  }

  public init(language: string|null = null): any {
    //TRADUCTIONS
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    //Init des trads locales
    this.initLocalTranslations(language);

    //Merge avec les trads en bdd
    this.translationService.get().pipe(untilDestroyed(this)).subscribe((translations: any)=>{
      //merge with translations from database
      const bddTranslations: any = {};
      translations.map( (translation: any) =>{
        bddTranslations[translation.code] = translation.value;
      });
      this.translate.setTranslation('fr',bddTranslations, true);
    });
  }

  initLocalTranslations(language: string|null = null): any{
    if (language) {
      // @ts-ignore
      this.translate.setTranslation(language, this.availableLanguages[language], true);
    } else {
      Object.keys(this.availableLanguages).map((lang) => {
        // @ts-ignore
        this.translate.setTranslation(lang, this.availableLanguages[lang], true);
      });
    }
  }

  getTranslation(key: string | string[]) {
    // seule methode qui semble continuer de stream son observable
    return this.translate.getStreamOnTranslationChange(key);
  }

  instant(key: string): string {
    return this.translate.instant(key);
  }
}
