import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommentFormComponent),
      multi: true
    }
  ]
})
export class CommentFormComponent implements ControlValueAccessor {

  form: UntypedFormGroup;
  editor: any;
  initialValues =
    {
      content: '',
    };
  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group(this.initialValues);
  }

  get value(): any {
    return this.form.value.content;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  onEditorInit(event: any){

    this.editor = event.editor;
    this.editor.on('text-change', (delta: any, oldDelta: any, source: any) => {
      if(source === 'user'){
        this.onChange(this.value);
      }
    });
  }
  writeValue(value: any) {
    if (value) {
      setTimeout(() => {
        this.value = {content: value};
      });
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

}
