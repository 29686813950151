import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Email } from './email.model';

export interface EmailState extends EntityState<Email> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'emails' })
export class EmailStore extends EntityStore<EmailState> {

  constructor() {
    super();
  }

}
