<h2 class="mt-4">Nouvelle étape client</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <p-steps styleClass="mb-5" [model]="steps" [activeIndex]="activeIndex"></p-steps>
  <ng-container [ngSwitch]="activeIndex">
    <div *ngSwitchCase="0">
      <form [formGroup]="form">
        <div class="grid mt-3">
          <div class="field col-3" formArrayName="clientsConcernes">
            <label>Contact(s) concerné(s)</label>
            <div *ngFor="let client of clientsConcernesF.controls; let index = index" class="flex">
              <div class="flex w-full p-fluid">
                <p-autoComplete
                  inputStyleClass="form-control"
                  [forceSelection]="true"
                  class="w-full"
                  appendTo="body"
                  formControlName="{{ index }}"
                  [suggestions]="clientSearchService.searchBarResults"
                  placeholder="Rechercher un client"
                  (completeMethod)="clientSearchService.searchClient($event)"
                  field="label"
                  [minLength]="3">
                </p-autoComplete>
                <lib-field-error *ngIf="clientsConcernesF?.touched && clientsConcernesF?.errors" [fieldError]="clientsConcernesF?.errors"></lib-field-error>
                <ng-container *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
                <ng-template #addClientButton>
                  <button pButton type="button" icon="pi pi-plus" class="border-noround border-400 mb-2 ml-2" (click)="addClientConcerne()"></button>
                </ng-template>
                <ng-template #removeClientButton>
                  <button pButton type="button" icon="pi pi-times" class="bg-white text-danger border-noround border-400 mb-2 ml-2" (click)="removeClientConcerne(index)"></button>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="field col-3">
            <label>Choix de l'étape</label>
            <div class="flex w-full">
              <p-dropdown
                class="flex-grow-1 mb-2"
                styleClass="inputfield w-full"
                formControlName="step"
                appendTo="body"
                id="step"
                [options]="stepOptions"
                optionLabel="label"
                optionValue="value"
                (onChange)="onSelectedStepChange($event)"
                [autoDisplayFirst]="false"
                [filter]="true">
              </p-dropdown>
            </div>
            <lib-field-error *ngIf="stepF?.touched && stepF?.errors" [fieldError]="stepF?.errors"></lib-field-error>
          </div>
          <div class="field col-3" [hidden]="!stepF?.value.hasProduct">
            <label>Produit souscrit</label>
            <div class="flex w-full">
              <p-dropdown
                class="flex-grow-1"
                styleClass="inputfield w-full"
                formControlName="product"
                id="product"
                appendTo="body"
                [options]="productOptions"
                optionLabel="label"
                optionValue="value"
                [autoDisplayFirst]="false"
                [filter]="true">
              </p-dropdown>
            </div>
            <lib-field-error *ngIf="productF?.touched && form.errors?.['productRequired']" [fieldError]="{required: true}"></lib-field-error>
          </div>
          <div class="field col-3" [hidden]="!stepF?.value.hasAmount">
            <label>Montant</label>
            <div class="flex w-full">
              <div class="p-inputgroup">
                <input formControlName="amount" pInputText type="number" placeholder="Montant">
                <span class="p-inputgroup-addon">€</span>
              </div>
            </div>
            <lib-field-error *ngIf="amountF?.touched && form.errors?.['amountRequired']" [fieldError]="{required: true}"></lib-field-error>
          </div>
        </div>
        <div class="mb-4" *ngIf="stepF?.value">
          <p-divider></p-divider>
          <p-checkbox name="custom-group" [(ngModel)]="checkAllTasks" [binary]="true" (onChange)="onCheckAllTasks($event)" [ngModelOptions]="{standalone: true}" [label]="'Toutes les tâches du workflow'" [value]="checkAllTasks"></p-checkbox>
        </div>
        <div *ngFor="let task of workflowTasks; let i = index">
          <p-checkbox name="workflow-group" class="mb-2" [(ngModel)]="selectedTasks" (onChange)="onSelectTask($event)" [ngModelOptions]="{standalone: true}" [label]="task.task.name" [value]="task"></p-checkbox>
        </div>
        <lib-field-error *ngIf="stepF?.value && form.errors?.['needTasks']" [fieldError]="form.errors"></lib-field-error>
        <ng-container *ngIf="stepF?.value">
          <p-divider></p-divider>
          <!--      TACHES CUSTOM-->
          <div *ngFor="let customTask of customTasks; let j = index">
            <p-checkbox name="custom-group" id="custom-{{ j }}" class="mb-2" [(ngModel)]="customTasks" (onChange)="onSelectCustomTask($event)" [ngModelOptions]="{standalone: true}" [label]="customTask.name" [value]="customTask"></p-checkbox>
          </div>
        </ng-container>
      </form>

      <form [formGroup]="newCustomTaskForm">
        <div class="grid mt-3 mb-2" *ngIf="stepF?.value">
          <div class="field col-12">
            <div class="flex">
              <div class="flex w-full p-fluid">
                <input formControlName="name" id="new-task" placeholder="Tâches supplémentaires" type="text" size="30" pInputText class="inputfield w-full">
                <button pButton pRipple type="button" label="Ajouter et sélectionner cette tâche" [style]="{width: '400px'}" class="bg-white text-primary border-noround border-400 ml-2" (click)="addCustomTask()"></button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngSwitchCase="1">
      <div class="field step-start-date">
        <label for="deadlineDate">Date du début de l'étape</label>
        <p-calendar
          appendTo="body"
          placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
          id="stepStartDate"
          inputId="stepStartDate"
          styleClass="inputfield"
          [(ngModel)]="stepStartDate"
          [firstDayOfWeek]=1
          [disabledDays]="[0,6]"
          dateFormat="dd/mm/yy"
          [showIcon]="true">
        </p-calendar>
      </div>
      <div [formGroup]="orderingForm">
        <ng-container formArrayName="groups">
          <div cdkDropListGroup>
            <ng-container *ngFor="let group of groupsF.controls; let groupIndex = index">
              <div [formGroupName]="groupIndex">
                <ng-container *ngIf="groupIndex!==0">
                  <div class="field delay-input">
                    <label for="delay">Délai</label>
                    <p-inputNumber formControlName="delayBefore" [showButtons]="true" buttonLayout="horizontal" id="delay" spinnerMode="horizontal" [min]="0" [step]="1"
                                   decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" suffix=" jours">
                    </p-inputNumber>
                  </div>
                </ng-container>
                <ng-container formArrayName="tasks">
                  <div cdkDropList class="group-list" (cdkDropListDropped)="dropItemByGroupIndex($event, groupIndex)"  [cdkDropListData]="group.get('tasks')">

                    <div class="flex">
                      <div class="group-rank">{{group.value.position}}</div>
                      <div class="flex flex-column w-full">
                        <div class="task-box" *ngFor="let task of getTasksFByGroupIndex(groupIndex).controls; let taskIndex = index" cdkDrag (cdkDragEnded)="onDragEnded(task.value.id)">

                          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                          <div [formGroupName]="taskIndex" class="flex">
                            <div class="drag-handle" cdkDragHandle><i class="pi pi-arrows-v"></i></div>
                            <div class="flex w-full align-items-center justify-content-between">
                              <div class="col-2 ">
                                {{getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('name')?.value}}
                              </div>
                              <div class="flex align-items-center">
                                <label class="col-fixed" for="task-plannedWorkload">Charge prévue</label>
                                <div class="col">
                                  <p-inputNumber formControlName="plannedWorkload" [showButtons]="true" buttonLayout="horizontal" id="task-plannedWorkload" spinnerMode="horizontal" [min]="0" [step]="15"
                                                 decrementButtonClass="p-button" incrementButtonClass="p-button" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" suffix=" minutes">
                                  </p-inputNumber>
                                  <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.errors"
                                                   [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('plannedWorkload')?.errors">
                                  </lib-field-error>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

        </ng-container>
      </div>
    </div>
    <div *ngSwitchCase="2">
      <div [formGroup]="orderingForm">
        <ng-container formArrayName="groups">
          <div>
            <ng-container *ngFor="let group of groupsF.controls; let groupIndex = index">
              <div [formGroupName]="groupIndex">
                <p-divider *ngIf="groupIndex!==0 && getTasksFByGroupIndex(groupIndex).controls.length > 0"></p-divider>
                <ng-container formArrayName="tasks">
                  <div>

                    <div class="flex">
                      <div class="group-rank" *ngIf="getTasksFByGroupIndex(groupIndex).controls.length > 0">{{group.value.position}}</div>
                      <div class="flex flex-column w-full">
                        <p-card class="task-box" *ngFor="let task of getTasksFByGroupIndex(groupIndex).controls; let taskIndex = index" >

                          <div [formGroupName]="taskIndex">
                            <div class="task-name">
                              {{getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('name')?.value}}
                            </div>
                            <div class="grid w-full">
                              <div class="col-2 field">
                                <label for="startDate">Date de début</label>
                                <p-calendar
                                  appendTo="body"
                                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
                                  id="startDate"
                                  inputId="startDate"
                                  styleClass="inputfield w-full"
                                  formControlName="startDate"
                                  dateFormat="dd/mm/yy"
                                  (onSelect)="onStartDateSelect($event, groupIndex, taskIndex)"
                                  [firstDayOfWeek]=1
                                  [disabledDays]="[0,6]"
                                  [minDate]="today"
                                  [showIcon]="true">
                                </p-calendar>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('startDate')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('startDate')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('startDate')?.errors">
                                </lib-field-error>
                              </div>
                              <div class="col-2 field">
                                <label class="mb-2" for="deadlineDate">Date d'échéance</label>
                                <p-calendar
                                  appendTo="body"
                                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
                                  id="deadlineDate"
                                  inputId="deadlineDate"
                                  styleClass="inputfield w-full"
                                  formControlName="deadlineDate"
                                  dateFormat="dd/mm/yy"
                                  [firstDayOfWeek]=1
                                  [disabledDays]="[0,6]"
                                  [minDate]="today"
                                  [showIcon]="true">
                                </p-calendar>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('deadlineDate')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('deadlineDate')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('deadlineDate')?.errors">
                                </lib-field-error>
                              </div>
                              <div class="col-2 field flex flex-column">
                                <label for="pole">Pôle concerné</label>
                                <p-dropdown formControlName="pole" id="pole"
                                            styleClass="w-full"
                                            [options]="polesOptions"
                                            [autoDisplayFirst]="false" placeholder="Pôle">
                                </p-dropdown>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('pole')?.errors">
                                </lib-field-error>
                              </div>
                              <div class="col-2 field flex flex-column">
                                <label for="collaborator">Collab. assigné</label>
                                <p-dropdown formControlName="collaborator" id="collaborator"
                                            styleClass="w-full"
                                            [options]="collaboratorOptions"
                                            [autoDisplayFirst]="false" placeholder="...">
                                </p-dropdown>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('collaborator')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('collaborator')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('collaborator')?.errors">

                                </lib-field-error>
                              </div>
                              <div class="col-2 field flex flex-column">
                                <label for="contractor">Donneur d'ordre</label>
                                <p-dropdown formControlName="contractor" id="contractor"
                                            styleClass="w-full"
                                            [options]="collaboratorOptions"
                                            [autoDisplayFirst]="false" placeholder="...">
                                </p-dropdown>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('contractor')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('contractor')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('contractor')?.errors">

                                </lib-field-error>
                              </div>
                              <div class="col-2 field flex flex-column">
                                <label>Importance</label>
                                <app-importance-input radioName="{{groupIndex}}-{{taskIndex}}" [formControl]="$any(getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('importance'))"></app-importance-input>
                                <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.errors"
                                                 [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('importance')?.errors">
                                </lib-field-error>
                              </div>
                              <div class="col-12" *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('helpText')?.value !== ''">
                                <label for="help-text">Aide : </label>
                                {{getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('helpText')?.value}}
                              </div>
                              <div class="field col-12" formArrayName="comments">
                                <ng-container [formGroupName]="0">
                                  <label for="help-text">Commentaire</label>
                                  <input formControlName="content" id="help-text" type="text" pInputText class="inputfield w-full">
                                  <lib-field-error *ngIf="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('comments')?.touched &&
                                                    getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('comments')?.errors"
                                                   [fieldError]="getTasksFByGroupIndex(groupIndex).controls[taskIndex].get('comments')?.errors">
                                  </lib-field-error>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </p-card>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>

        </ng-container>
      </div>
      <div class="flex justify-content-end">
        <div *ngIf="dateConsistencyError" class="invalid-feedback p-mt-2">
          Les dates saisies ne sont pas conformes à la règle définie : les tâches d'un groupe ne peuvent pas démarrer avant la fin des tâches du groupe qui les précèdent.
        </div>
      </div>
    </div>
  </ng-container>


  <div class="col-12 flex justify-content-between">
    <p-button *ngIf="activeIndex > 0" label="Précédent" (onClick)="prevPage()" styleClass="p-button p-button-raised p-button-text" icon="pi pi-angle-left" iconPos="left"></p-button>
    <p-button label="Annuler" (onClick)="cancel()" styleClass="p-button-danger p-button-raised p-button-text" icon="pi pi-times" iconPos="left"></p-button>
    <p-button *ngIf="activeIndex < 2" label="Suivant" (onClick)="nextPage()" styleClass="p-button p-button-raised p-button-text" icon="pi pi-angle-right" iconPos="right"></p-button>
    <p-button *ngIf="activeIndex === 2" label="Valider" (onClick)="submitForm(true)" styleClass="p-button p-button-raised p-button-success" icon="pi pi-check" iconPos="left"></p-button>
  </div>

  <p-dialog
    class="border-round"
    [(visible)]="showCustomTaskNotAddedModal"
    (onHide)="showCustomTaskNotAddedModal = false"
    [style]="{ width: '40vw' }"
    [modal]="true"
    header="Tâche supplémentaire non ajoutée">
    <p>Vous avez saisi une tâche supplémentaire sans cliquer sur ajouter. Celle-ci ne sera pas prise en compte.</p>
    <p>Veuillez effacer la saisie ou cliquer sur Ajouter pour continuer.</p>
    <ng-template pTemplate="footer">
      <div class="col-12 flex justify-content-between">
        <p-button label="Fermer" icon="pi pi-check" styleClass="p-button-success p-button-raised p-button-text" (click)="showCustomTaskNotAddedModal = false"></p-button>
      </div>
    </ng-template>
  </p-dialog>
</ng-template>
