<ng-container *ngIf="(uiScreenQuery.isMobile$ | async), else desktop;">
  <app-desktop-only [clientOrAdmin]="'CLIENT'"></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div class="header flex justify-content-center">
    <div class="banner">
      <img src="assets/recueil/{{getHeaderImg()}}" alt="recueil-header/">
    </div>
  </div>

  <div class="w-11 mx-auto rec">
    <ng-container *ngIf="formIsReady && !error">
      <form [formGroup]="form">
        <app-presentation-form [hidden]="activeIndex !== -2" formControlName="presentation"></app-presentation-form>
        <app-question-filtre-form [toggleQuestionFiltre] ="toggleQuestionFiltre()" [hidden]="activeIndex !== -1"
                                  formControlName="questionFiltres"></app-question-filtre-form>
        <ng-container *ngIf="activeIndex > -1 &&  activeIndex < steps.length">
          <h1 class="rec text-center mt-8" [ngSwitch]="steps[activeIndex].id">
            <ng-container *ngSwitchCase="'CLIENT_STEP_VOUS_STEPPER_TITLE'">
              {{'CLIENT_STEP_VOUS_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_CONJOINT_STEPPER_TITLE'">
              {{'CLIENT_STEP_CONJOINT_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_FAMILLE_STEPPER_TITLE'">
              {{'CLIENT_STEP_FAMILLE_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_BIENSIMMO_STEPPER_TITLE'">
              {{'CLIENT_STEP_BIENSIMMO_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_AVOIRSFI_STEPPER_TITLE'">
              {{'CLIENT_STEP_AVOIRSFI_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_BIENSDIV_STEPPER_TITLE'">
              {{'CLIENT_STEP_BIENSDIV_TITLE' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_COMPLEMENT_STEPPER_TITLE'">
              {{'CLIENT_STEP_COMPLEMENT_TITLE' | translate}}
            </ng-container>
          </h1>

          <p-steps [model]="steps" [readonly]="nextButtonDisabled" [(activeIndex)]="activeIndex"
                   styleClass="mt-6 mb-8"></p-steps>

          <ng-container [ngSwitch]="steps[activeIndex].id">
            <ng-container *ngSwitchCase="'CLIENT_STEP_VOUS_STEPPER_TITLE'">
              <app-recueil-natural-person formControlName="informations" [clientId]="client.id"
                                          [personSituation]="this.form.value.questionFiltres?.situationMatrimoniale"></app-recueil-natural-person>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_CONJOINT_STEPPER_TITLE'">
              <app-recueil-conjoint [conjoint]="conjointData" [conjointHasPhones]="conjointHasPhones" [conjointHasAddresses]="conjointHasAddresses"
                                    formControlName="conjoint" [clientId]="client.id"
                                    [personSituation]="this.form.value.questionFiltres?.situationMatrimoniale"></app-recueil-conjoint>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_FAMILLE_STEPPER_TITLE'">
              <app-recueil-a-charge formControlName="famille" [clientId]="client.id"
                                    [clientRelations]="clientRelations"></app-recueil-a-charge>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_BIENSIMMO_STEPPER_TITLE'">
              <app-recueil-immobilier formControlName="immobilier" [clientId]="client.id" [clientType]="clientType"
                                      [clientOrAdmin]="'CLIENT'"></app-recueil-immobilier>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_AVOIRSFI_STEPPER_TITLE'">
              <app-recueil-financier formControlName="financier" [clientId]="client.id"
                                     [clientType]="clientType" [clientOrAdmin]="'CLIENT'"></app-recueil-financier>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_BIENSDIV_STEPPER_TITLE'">
              <app-recueil-bien-divers formControlName="bienDivers" [clientId]="client.id" [clientType]="clientType"
                                       [clientOrAdmin]="'CLIENT'"></app-recueil-bien-divers>
            </ng-container>
            <ng-container *ngSwitchCase="'CLIENT_STEP_COMPLEMENT_STEPPER_TITLE'">
              <app-recueil-autre formControlName="autre" [clientId]="client.id" [clientType]="clientType"
                                 [clientOrAdmin]="'CLIENT'"></app-recueil-autre>
            </ng-container>
          </ng-container>
        </ng-container>
        <div *ngIf="activeIndex === steps.length">
          <app-recueil-recap formControlName="recap" [clientId]="client.id" [clientType]="clientType"
                             [clientOrAdmin]="'CLIENT'"></app-recueil-recap>
        </div>

      </form>
      <!--action buttons-->
      <div class="flex w-full mt-3 mb-5 justify-content-between">
        <div>
          <p-button *ngIf="activeIndex > -2" label="Précédent" (onClick)="prevPage()" styleClass="p-button p-button-raised"
                    icon="pi pi-chevron-left" iconPos="left"></p-button>
        </div>
        <div class="flex flex-row">
          <p-button label="Terminer plus tard" (onClick)="submitForm(false, true)"
                    class="ml-auto" styleClass="p-button p-button-outlined" icon="pi pi-check" iconPos="left"
                    [loading]="loadingRecueilValidation" ></p-button>
          <p-button *ngIf="activeIndex === steps.length" label="Valider mon recueil" (onClick)="submitForm(true, false)"
                    class="ml-3" styleClass="p-button p-button-raised" icon="pi pi-check" iconPos="left"
                    [loading]="loadingRecueilValidation" ></p-button>
          <div class="ml-3"
               pTooltip="Vous devez renseigner au moins un numéro de téléphone de contact et une adresse postale
                fiscale de contact avant de continuer"
               [tooltipDisabled]="!nextButtonDisabled"
               [tooltipPosition]="'left'">
            <p-button *ngIf="activeIndex < steps.length" label="Suivant" (onClick)="nextPage()" class="ml-auto"
                      styleClass="p-button p-button-raised" icon="pi pi-chevron-right" iconPos="right"
                      [disabled]="nextButtonDisabled"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!formIsReady && !error">
      <app-loader [internal]="true"></app-loader>
    </ng-container>
    <ng-container *ngIf="error">
      <app-error></app-error>
    </ng-container>

  </div>
</ng-template>


<p-dialog
  [(visible)]="showConfirmQuestionFiltreModal"
  [modal]="true"
  [style]="{width: '70vw'}"
  header="Vous vous apprêtez à supprimer des données">
  <ng-container *ngIf="selectedConfirmQuestionFiltreType === 'RELATION'">
    <div class="p-d-flex p-ai-center p-3 field">
      <p>
        <strong>Attention :</strong>
        Vous avez déjà renseigné des enfants ou des personnes à charge, souhaitez-vous que vos relations avec ces personnes soient supprimées ?
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedConfirmQuestionFiltreType !== 'RELATION'">
    <div class="p-d-flex p-ai-center p-3 field">
      <p>
        <strong>Attention :</strong>
        Vous avez déjà renseigné ces informations, souhaitez-vous que ces informations soient supprimées ?
      </p>
    </div>
  </ng-container>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end mt-5">
      <p-button (onClick)="cancelQuestionFiltreModal()" icon="pi pi-times" label="Annuler"
                styleClass="p-button-danger p-button-raised p-button-text">

      </p-button>
      <p-button (onClick)="confirmQuestionFiltreModal()" icon="pi pi-check"
                label="Confirmer"
                styleClass="ml-3 p-button-primary p-button-raised">
      </p-button>
    </div>
  </ng-template>
</p-dialog>



