import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {FilterService, MenuItem, MessageService} from 'primeng/api';
import { LegalPersonFormComponent } from '../../../components/form/legal-person-form/legal-person-form.component';
import { NaturalLegalRelationFormComponent } from '../../../components/form/natural-legal-relation-form/natural-legal-relation-form.component';
import {
  LegalPerson,
  LegalPersonQuery,
  TranslatorService,
  LegalPersonService,
  CompteRendu,
  StepQuery,
  Step,
  TaskStatus,
  Task,
  PoleInterne,
  User,
  TaskHelperService,
  DashboardFiltersType,
  StepAndTaskOptionValue,
  PoleAndCollaboratorOptionValue,
  DropdownOption,
  DropdownOptionsService,
  DashboardType,
  StepService,
  UiScreenQuery,
  ClientType
} from 'common';
import {Table} from 'primeng/table';
import {UntypedFormBuilder} from '@angular/forms';
import {Dropdown} from 'primeng/dropdown';
import * as moment from 'moment/moment';

// Object shape added to task to allow filtering by step and task name.
type AddonFilterStepAndTaskName = {
  relatedStep: string;
  taskName: string;
};

// Object shape added to task to allow filtering by pole / collaborator / my tasks.
type AddonFilterPoleAndCollaborator = {
  pole: PoleInterne;
  collaborator?: User;
  isPriority: boolean;
  status: TaskStatus;
};

interface CurrentStepsDashboardTask extends Task {
  step?: Step;
  stepAndTaskName?: AddonFilterStepAndTaskName;
  poleAndCollaborator?: AddonFilterPoleAndCollaborator;
}

@UntilDestroy()
@Component({
  selector: 'app-page-legal-person',
  templateUrl: './page-legal-person.component.html',
  styleUrls: ['./page-legal-person.component.scss'],
})
export class PageLegalPersonComponent implements OnInit {
  @ViewChild(LegalPersonFormComponent, { static: false }) legalPersonForm: LegalPersonFormComponent;
  @ViewChild(NaturalLegalRelationFormComponent, { static: false }) naturalLegalRelationForm: NaturalLegalRelationFormComponent;
  @ViewChild('currentStepsTable', { static: false }) currentStepsTable: Table;
  @ViewChild('historicalTable', { static: false }) historicalTable: Table;
  @ViewChild('meetingReportTable', { static: false }) meetingReportTable: Table;
  @ViewChild('filterStepsCurrentSteps', { static: false }) filterStepsCurrentSteps: Dropdown;
  @ViewChild('filterTasksCurrentSteps', { static: false }) filterTasksCurrentSteps: Dropdown;
  @ViewChild('filterCollaboratorAndPoleCurrentSteps', { static: false }) filterCollaboratorAndPoleCurrentSteps: Dropdown;
  @ViewChild('filterPoleHistorical', { static: false }) filterPoleHistorical: Dropdown;
  @ViewChild('filterCollaboratorMeetingReport', { static: false }) filterCollaboratorMeetingReport: Dropdown;

  isLoading = true;
  legalPerson?: LegalPerson;
  legalPersonCurrentStepsTasks: [] | CurrentStepsDashboardTask[];
  legalPersonHistoricalTasks: [] | Task[];
  dashboardType = DashboardType; // Reference for use in HTML.
  dashboardFiltersType = DashboardFiltersType; // Reference for use in HTML.
  compteRendus: [] | CompteRendu[];
  showEditLegalPersonModal = false;
  showAddNaturalLegalRelationModal = false;
  defaultAddressType: string;
  items: MenuItem[];
  activeRelation: any;
  showGeneralCommentEdit = false;
  generalComment? = '';
  taskStatus = TaskStatus; // Reference for use in HTML.
  displayCloseDialog = false;
  closingTask: any = null;
  stepsOptions: DropdownOption<any>[];
  stepsTasksOption: DropdownOption<any>[];
  stepFilterRelatedTaskOption: DropdownOption<any>[];
  poleAndCollaboratorOptions: DropdownOption<any>[];
  collaboratorOptions: DropdownOption<any>[];
  form = this.formBuilder.group({
    stepFilterCurrentSteps: [],
    stepTaskFilterCurrentSteps: [],
    poleAndCollaboratorFilterCurrentSteps: [],
    poleFilterHistorical: [],
    collaboratorMeetingReport: []
  });
  tableCustomerCurrentStorageKey: string;
  tableCustomerHistoricalStorageKey: string;
    clientType = ClientType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public legalPersonQuery: LegalPersonQuery,
    private messageService: MessageService,
    public translatorService: TranslatorService,
    private legalPersonService: LegalPersonService,
    private stepQuery: StepQuery,
    private taskHelperService: TaskHelperService,
    private dropdownOptions: DropdownOptionsService,
    private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    public uiScreenQuery: UiScreenQuery,
    private stepService: StepService
  ) {}

  get addressF() { return this.legalPersonForm.form.get('address');}

  setStepFilter(filterValue: StepAndTaskOptionValue){
    this.form.get('stepFilter')?.setValue(filterValue);
    this.currentStepsTable.filter(filterValue, 'step', 'containsStep');
  }

  setStepTaskFilter(filterValue: StepAndTaskOptionValue){
    this.form.get('stepTaskFilter')?.setValue(filterValue);
    this.currentStepsTable.filter(filterValue, 'name', 'containsStepTask');
  }

  setCollaboratorAndPoleFilter(filterValue: PoleAndCollaboratorOptionValue, table: DashboardType){
    if (table === DashboardType.CURRENT) {
      if (filterValue !== null) {
        this.form.get('poleAndCollaboratorFilterCurrentSteps')?.setValue({id: filterValue.id, type: filterValue.type });
        this.currentStepsTable.filter({id: filterValue.id, type: filterValue.type}, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      } else {
        this.form.get('poleAndCollaboratorFilterCurrentSteps')?.setValue(null);
        this.currentStepsTable.filter(null, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      }
    }
    if (table === DashboardType.HISTORICAL) {
      if (filterValue !== null) {
        this.form.get('poleFilterHistorical')?.setValue({id: filterValue.id, type: filterValue.type });
        this.historicalTable.filter({id: filterValue.id, type: filterValue.type}, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      } else {
        this.form.get('poleFilterHistorical')?.setValue(null);
        this.historicalTable.filter(null, 'poleAndCollaborator', 'containsPoleOrCollaborator');
      }
    }
  }

  setCollaboratorFilter(filterValue: StepAndTaskOptionValue){
    this.form.get('collaboratorMeetingReport')?.setValue(filterValue);
    this.meetingReportTable.filter(filterValue, 'collaboratorPresent', 'containsCollaborator');
  }

  ngOnInit(): void {
    this.getLegalPersonInformation();
    this.setDropdownOptions();
    this.setTableFilters();
    this.setRelationMenu();
  }

  onDashboardStateRestore(event: any, dashboardType: DashboardType) {
    if (event.filters) {
      if (event.filters.step) { this.form.get('stepFilterCurrentSteps')?.setValue(event.filters.step.value);}
      if (event.filters.name) {this.form.get('stepTaskFilterCurrentSteps')?.setValue(event.filters.name.value);}
      if (event.filters.poleAndCollaborator && dashboardType === DashboardType.CURRENT) {this.form.get('poleAndCollaboratorFilterCurrentSteps')?.setValue(event.filters.poleAndCollaborator.value);}
      if (event.filters.poleAndCollaborator && dashboardType === DashboardType.HISTORICAL) {this.form.get('poleFilterHistorical')?.setValue(event.filters.poleAndCollaborator.value);}
    }
  }

  // Get the information and steps of the legal person concerned.
  getLegalPersonInformation() {
    this.route.params.subscribe((params) => {
      const id = +params['id'];

      this.tableCustomerCurrentStorageKey = 'dashboard_customer_legal_current_' + params['id'];
      this.tableCustomerHistoricalStorageKey = 'dashboard_customer_legal_historical_' + params['id'];

      this.legalPersonService.get(id).pipe(untilDestroyed(this)).subscribe();
      this.legalPersonQuery.selectEntity(id).pipe(untilDestroyed(this)).subscribe((legalPerson) => {
        this.legalPerson = legalPerson;

        /* eslint-disable */
        this.stepService.get({
          params: {'legalPersons': this.legalPerson?.id}, upsert: true
        }).subscribe();
        // /* eslint-enable */

        // TODO : hasOwnProperty('addresses') pour tester si l'objet est complet : a ameliorer
        if (this.legalPerson?.hasOwnProperty('addresses')) {
          this.legalPersonForm?.createFormGroup(legalPerson, true);
          this.isLoading = false;
          this.generalComment = this.legalPerson.generalComment;

          if (this.legalPerson.compteRendus) {
            this.compteRendus = this.legalPerson.compteRendus.map((report) => ({
              ...report,
              mainCollaborator: report.collaboratorPresent[0]
            })).sort((reportA, reportB) => this.abstractSorter(reportA.meetingDate, reportB.meetingDate, 'DESC'));
          } else {
            this.compteRendus = [];
          }

          // Get legal person related steps.
          this.stepQuery.selectAll({filterBy: [
              (entity) => entity.legalPersons !== undefined && entity.legalPersons.some((person) => person.id === this.legalPerson?.id)
            ]}).pipe(untilDestroyed(this)).subscribe((steps) => {
            if (steps) {
              this.legalPersonCurrentStepsTasks = this.addPropertiesToTasks(this.getlegalPersonCurrentStepsTasks(steps));
              this.legalPersonHistoricalTasks = this.addPropertiesToTasks(this.getLegalPersonHistoricalTasks(steps));
            }
          });
        }
      });
    });
    this.defaultAddressType = 'Correspondance O2S';
  }

  setDropdownOptions() {
    this.stepsOptions = this.dropdownOptions.steps;
    this.stepsTasksOption = this.dropdownOptions.stepsTasks;
    this.stepFilterRelatedTaskOption = this.dropdownOptions.stepsTasks;
    this.poleAndCollaboratorOptions = this.dropdownOptions.polesAndCollaborators.filter((item) => item.value !== 'other');
    this.collaboratorOptions = this.dropdownOptions.polesAndCollaborators.filter((item) => item.value !== 'other' && item.value !== 'poles');
  }

  setTableFilters() {
    this.filterService.register('containsPoleOrCollaborator',(value: AddonFilterPoleAndCollaborator, filter: PoleAndCollaboratorOptionValue): boolean =>
      this.doesTaskContainPoleOrCollaborator(value, filter));
    this.filterService.register('containsStep',(value: Step, filter: StepAndTaskOptionValue): boolean => this.doesTaskContainStepName(value, filter));
    this.filterService.register('containsStepTask',(value: any, filter: any): boolean => this.doesTaskContainsTaskName(value, filter));
    this.filterService.register('containsCollaborator',(value: any, filter: any): boolean => this.doesTaskContainsCollaborator(value, filter));
  }

  getlegalPersonCurrentStepsTasks(steps: [] | Step[]): [] | CurrentStepsDashboardTask[] {
    let tasks: [] | any[] = [];

    steps.map((step) => {
      step.stepGroups.map((group) => {
        group.tasks.map((task) => {
          const relatedNaturalPersons = step.naturalPersons?.filter((naturalPerson) => naturalPerson.id !== this.legalPerson?.id);
          const relatedLegalPersons = step.legalPersons?.filter((legalPersons) => legalPersons.id !== this.legalPerson?.id);

          tasks = [...tasks, {
            ...task,
            step,
            relatedNaturalPersons,
            relatedLegalPersons
          }];
        });
      });
    });
    tasks = tasks.filter((task: Task) => task.status !== TaskStatus.DONE);
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(moment(taskA.deadlineDate, 'YYYYMMDD'), moment(taskB.deadlineDate, 'YYYYMMDD'), 'ASC'));
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(moment(taskA.startDate, 'YYYYMMDD'), moment(taskB.startDate, 'YYYYMMDD'), 'ASC'));
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(taskA.isPriority, taskB.isPriority));

    // Filter dropdown options with data related to the currentStep table tasks.
    if (tasks.length > 0) {
      this.stepsOptions = this.filterStepOptions(tasks, this.stepsOptions);
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(tasks);
    }

    return tasks;
  }

  // Get tasks of historical tasks table.
  getLegalPersonHistoricalTasks(steps: [] | Step[]): [] | Task[] {
    let tasks: [] | any[] = [];

    steps.map((step) => {
      step.stepGroups.map((group) => {
        group.tasks.map((task) => {
          tasks = [...tasks, {
            ...task,
            step,
          }];
        });
      });
    });

    tasks = tasks.filter((task: Task) => task.status === TaskStatus.DONE);
    tasks = tasks.sort((taskA, taskB) => this.abstractSorter(taskA.startDate, taskB.startDate, 'DESC'));
    return tasks;
  }

  abstractSorter(taskA: any, taskB: any, sort: 'ASC' | 'DESC' = 'DESC') {
    if (taskA > taskB) {
      return sort === 'DESC' ? -1 : +1;
    } else if (taskA < taskB) {
      return sort === 'DESC' ? +1 : -1;
    } else {
      return 0;
    }
  }

  // Add the appropriate data to tasks.
  addPropertiesToTasks(tasks: Task[]): CurrentStepsDashboardTask[] {
    // Add property for filtering by pole and collaborator.
    const tasksWithPoleAndCollaboratorProperty = this.addPoleAndCollaboratorKeyToTasks(tasks);
    // Add property for filtering by step and task name.
    const tasksWithStepAndTasknameProperty = this.addStepAndTaskNameKeyToTasks(tasksWithPoleAndCollaboratorProperty);
    // Add property for display calculated deadline progress.
    return this.addDeadlineProgressStatusKeyToTasks(tasksWithStepAndTasknameProperty);
  }

  // Add property for filtering / sorting by poles and collaborators.
  addPoleAndCollaboratorKeyToTasks(tasks: CurrentStepsDashboardTask[]) {
    return tasks.map((task) => ({
      ...task,
      poleAndCollaborator: {
        pole: task.pole as PoleInterne,
        collaborator: task.collaborator,
        isPriority: task.isPriority,
        status: task.status as TaskStatus,
      },
    }));
  }

  // Add property for filtering / sorting by step and task name.
  addStepAndTaskNameKeyToTasks(tasks: CurrentStepsDashboardTask[]) {
    return tasks.map((task) => ({
      ...task,
      stepAndTaskName: {
        relatedStep: task?.step?.name ?? '',
        taskName: task.name,
      },
    }));
  }

  // Add property for display calculated deadline progress.
  addDeadlineProgressStatusKeyToTasks(tasks: Task[]) {
    return tasks.map((task) => ({
      ...task,
      deadlineProgress: this.taskHelperService.calculateDeadlineIndicator(
        new Date(task.startDate),
        new Date(task.deadlineDate)
      ),
    }));
  }

  filterStepOptions(tasks: CurrentStepsDashboardTask[], stepOptions: DropdownOption<StepAndTaskOptionValue>[]): DropdownOption<StepAndTaskOptionValue>[] {
    if (stepOptions) {
      return stepOptions.filter((stepOption) => tasks.some((task) => stepOption.value.name === task.step?.name))
        .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
    } else {
      return stepOptions;
    }
  }

  filterStepTasksOptions(tasks: CurrentStepsDashboardTask[]): DropdownOption<StepAndTaskOptionValue>[] {
    let stepTasks: [] | DropdownOption<StepAndTaskOptionValue>[] = [];

    tasks.map((task: CurrentStepsDashboardTask) => {
      if (!stepTasks.some((stepTask: DropdownOption<StepAndTaskOptionValue> ) => stepTask.value.name === task.step?.name) && task.step?.name) {
        stepTasks = [...stepTasks, { label: task.step?.name, value: { name: task.step?.name, type: 'STEP' }, items: [] }];
      }

      stepTasks.map((stepTask, index) => {
        if (task.step && stepTask.value.name === task.step.name && stepTask.items && !stepTask.items.some((stepTaskItem) => stepTaskItem.value.name === task.name)) {
          // @ts-ignore
          stepTasks[index].items.push({label: task.name, value: { name: task.name, type: 'TASK' }});
        }
      });
    });

    return stepTasks.sort((resultA: DropdownOption<StepAndTaskOptionValue>, resultB: DropdownOption<StepAndTaskOptionValue>) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
  }

  // Logic for collaborator / pole / "Mes tâches" filter.
  doesTaskContainPoleOrCollaborator(item: AddonFilterPoleAndCollaborator, filterValue: PoleAndCollaboratorOptionValue) {
    if (item && filterValue.type === 'pole') {
      return item.pole === filterValue.id;
    }
    if (item.collaborator && filterValue.type === 'collaborator') {
      return item.collaborator.id === filterValue.id;
    }
    return !filterValue;
  }

  // Logic for step name filter.
  doesTaskContainStepName(item: Step, filterValue: StepAndTaskOptionValue) {
    if (item) {
      // Set step related tasks for task filter options.
      this.stepFilterRelatedTaskOption = this.stepFilterRelatedTaskOption.filter((stepTasks) => stepTasks.label === filterValue.name);
      return item.name === filterValue.name;
    }
    return !filterValue;
  }

  // Logic for task name filter.
  doesTaskContainsTaskName(item: Step['name'], filterValue: StepAndTaskOptionValue) {
    if (item) { return item === filterValue.name; }
    return !filterValue;
  }

  // Logic for collaborator filter.
  doesTaskContainsCollaborator(items: any, filterValue: any) {
    if (items) { return items.some((item: any) => item.id === filterValue.id); }
    return !filterValue;
  }

  // Apply filter and register filter in store.
  handleFiltering(filterValue: any, fieldToFilter: DashboardFiltersType) {
    if (fieldToFilter === DashboardFiltersType.STEP) {
      // Re-filter step related task option if step filter is changed.
      this.stepFilterRelatedTaskOption = this.filterStepTasksOptions(this.legalPersonCurrentStepsTasks);
      this.setStepFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.STEPTASK) {
      this.setStepTaskFilter(filterValue);
    } else if (fieldToFilter === DashboardFiltersType.COLLABORATORANDPOLE) {
      this.setCollaboratorAndPoleFilter(filterValue, DashboardType.CURRENT);
    } else if (fieldToFilter === DashboardFiltersType.POLE) {
      this.setCollaboratorAndPoleFilter(filterValue, DashboardType.HISTORICAL);
    } else if (fieldToFilter === DashboardFiltersType.COLLABORATOR) {
      this.setCollaboratorFilter(filterValue);
    }
  }

  // Function to reset array filters.
  clearFilters(event: Event, table: 'currentSteps' | 'historical' | 'meetingReports') {
    // If we do not clean each filter we encounter the following problem :
    // the filter is reset but the filter label remains selected in the dropdown.
    if (table === 'currentSteps') {
      this.filterStepsCurrentSteps.clear(event);
      this.filterTasksCurrentSteps.clear(event);
      this.filterCollaboratorAndPoleCurrentSteps.clear(event);
      this.currentStepsTable.clear();
    }
    if (table === 'historical') {
      this.filterPoleHistorical.clear(event);
      this.historicalTable.clear();
    }
    if (table === 'meetingReports') {
      this.filterCollaboratorMeetingReport.clear(event);
      this.meetingReportTable.clear();
    }
  }

  // Function to reset task filter after cleared step filter.
  clearFilterTask(event: Event) {
    this.stepFilterRelatedTaskOption = this.stepsTasksOption;
    this.filterTasksCurrentSteps.clear(event);
    this.form.get('stepTaskFilterCurrentSteps')?.setValue(undefined);
  }

  formatDurationTime(duration: number) {
    // 60000 to transform minutes in millisecond.
    const hours = moment.utc(duration*60000).format('HH');
    const minutes = moment.utc(duration*60000).format('mm');
    return hours + 'H' + minutes;
  }

  // Redirect user on customer detail page.
  onCustomerTagClick(customerId: number, customerType: 'legalPerson' | 'naturalPerson') {
    if (customerType === 'legalPerson') {
      this.router.navigate(['/legal-person/' + customerId]);
    } else if (customerType === 'naturalPerson') {
      this.router.navigate(['/natural-person/' + customerId]);
    }
  }

  editTask(task: any) {
    this.router.navigate(['/task-customer-edit', task.step.id, task.id], {queryParams: {id: this.legalPerson?.id ,type: 'legalPerson'}});
  }

  openCloseDialog(task: any) {
    this.closingTask = task;
    this.displayCloseDialog = true;
  }

  // Menu displayed when user click on relation tag.
  setRelationMenu() {
    this.items = [
      {
        label: 'Voir la fiche',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          this.goToRelation();
        },
      },
      {
        label: 'Supprimer',
        icon: 'pi pi-fw pi-times',
        command: () => {
          this.deleteRelation();
        },
      },
    ];
  }

  deleteRelation() {
    if (this.legalPerson?.naturalLegalRelations) {
      let relationToKeepPayload: any[] = [];
      const relationsToKeep = this.legalPerson.naturalLegalRelations.filter(
        (relation) =>
          relation.naturalPerson !== this.activeRelation.naturalPerson
      );
      relationsToKeep.map(relation => {
        relationToKeepPayload = [...relationToKeepPayload, {
          id: relation.id,
          legalPerson: {id: this.legalPerson?.id},
          naturalPerson: {id: relation.naturalPerson.id},
          naturalPersonRole: relation.naturalPersonRole
        }];
      });
      const updatedLegalPerson = { ...this.legalPerson };
      updatedLegalPerson.naturalLegalRelations = [...relationToKeepPayload];
      this.updateLegalPerson(updatedLegalPerson);
    }
  }

  goToRelation() {
    this.router.navigate([
      'natural-person/' + this.activeRelation.naturalPerson.id,
    ]);
  }

  submitLegalPersonForm() {
    if (this.legalPersonForm.form.invalid) {
      this.legalPersonForm.form.markAllAsTouched();
      return;
    }

    const updatedLegalPerson: Partial<LegalPerson> = {
      name: this.legalPersonForm.nameF?.value.toUpperCase(),
      siretNumber: this.legalPersonForm.siretNumberF?.value,
      structureJuridique: this.legalPersonForm.structureJuridiqueF?.value,
      creationDate: this.legalPersonForm.creationDateF?.value,
      customerStatus: this.legalPersonForm.customerStatusF?.value,
    };
    /* ADDRESSES - empty address relation if all address fiels are empty */
    if (this.addressF?.value) {
      updatedLegalPerson.addresses = [
        {
          type: this.defaultAddressType,
          street: this.addressF?.value.street,
          city: this.addressF?.value.city,
          zipCode: this.addressF?.value.zipCode,
          state: this.addressF?.value.state,
          country: this.addressF?.value.country,
          fiscale: this.addressF?.value.fiscale,
          isContact: true,
        },
      ];
      if (this.legalPerson && this.legalPerson.addresses.length > 0) {
        updatedLegalPerson.addresses[0].id = this.legalPerson?.addresses[0].id;
      }
    } else {
      updatedLegalPerson.addresses = [];
    }
    this.updateLegalPerson(updatedLegalPerson);
  }

  onUpdateLegalPersonError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000,
    });
  }

  hideEditLegalPersonModal() {
    this.showEditLegalPersonModal = false;
    this.legalPersonForm.form.reset(this.legalPersonForm.initialValues);
  }

  submitNaturalLegalRelationsForm() {
    if (this.naturalLegalRelationForm.form.invalid) {
      this.naturalLegalRelationForm.form.markAllAsTouched();
      return;
    }
    if (this.legalPerson?.naturalLegalRelations) {
      let existingRelations: any[] = [];
      this.legalPerson.naturalLegalRelations.map(relation => {
        existingRelations = [...existingRelations, {
          id: relation.id,
          legalPerson: {id: this.legalPerson?.id},
          naturalPerson: {id: relation.naturalPerson.id},
          naturalPersonRole: relation.naturalPersonRole
        }]
      })
      const updatedLegalPerson: Partial<LegalPerson> = {
        naturalLegalRelations: [
          ...existingRelations,
          {
            // @ts-ignore
            legalPerson: { id: this.legalPerson.id },
            naturalPerson: {
              id: this.naturalLegalRelationForm.naturalPersonF?.value,
            },
            naturalPersonRole:
              this.naturalLegalRelationForm.naturalPersonRoleF?.value,
          },
        ],
      };
      this.updateLegalPerson(updatedLegalPerson);
      this.showAddNaturalLegalRelationModal = false;
      this.clearAddNaturalLegalRelationForm();
    }
  }

  updateLegalPerson(legalPerson: Partial<LegalPerson>) {
    this.legalPersonService.update(this.legalPerson?.id, legalPerson).subscribe({
        next: () => {
          this.showEditLegalPersonModal = false;
          this.messageService.add({
            severity: 'success',
            summary:
              'Les informations de ' +
              this.legalPerson?.name +
              ' ont bien été modifiées.',
            life: 10000,
          });
        },
        error: () => this.onUpdateLegalPersonError(),
      });
  }

  hideAddNaturalLegalRelationModal() {
    this.showAddNaturalLegalRelationModal = false;
    this.clearAddNaturalLegalRelationForm();
  }

  clearAddNaturalLegalRelationForm() {
    this.naturalLegalRelationForm.naturalPersonF?.reset();
    this.naturalLegalRelationForm.naturalPersonRoleF?.reset();
  }

  translate(word: string | undefined): string | void {
    if (word) {
      return this.translatorService.instant(word);
    }
  }

  sortMeetingReportsByDate(meetingReports: CompteRendu[], order: 'ASC' | 'DESC') {
    return order === 'ASC'
      ? meetingReports.sort(
          (a, b) =>
            new Date(a.meetingDate).getTime() -
            new Date(b.meetingDate).getTime()
        )
      : meetingReports.sort(
          (a, b) =>
            new Date(a.meetingDate).getTime() +
            new Date(b.meetingDate).getTime()
        );
  }

  displayGeneralCommentEdit() {
    this.showGeneralCommentEdit = true;
  }

  cancelGeneralCommentEdit() {
    this.generalComment = this.legalPersonForm.initialValues.generalComment;
    this.showGeneralCommentEdit = false;
  }

  submitGeneralComment() {
    if (this.legalPerson) {
      const updatedLegalPerson: Partial<LegalPerson> = {
        generalComment: this.generalComment,
      };
      this.updateLegalPerson(updatedLegalPerson);
      this.showGeneralCommentEdit = false;
    }
  }

  onClickNavigateTo(url: string, parameters?: Record<string, any>) {
      this.router.navigate([url], {queryParams: parameters});
  }
}
