<h2 class="mt-4">Liste des textes administrables </h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async), else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>

<ng-template #desktop>

  <div>
    <p-table #dt (onEditComplete)="checkEditTableComplete($event)" (onEditInit)="onEditInit($event)" [value]="data"
             dataKey="id" [rows]="10" [paginator]="true" [globalFilterFields]="['adminValue', 'clientValue', 'page']"
             currentPageReportTemplate="Textes administrables 1 à 10 sur {totalRecords} trouvées"
             [showCurrentPageReport]="true">
      <ng-template pTemplate="caption">
        <div class="flex align-items-center justify-content-between">
          <span class="p-input-icon-right">
            <i class="pi pi-search"></i>
            <input pInputText type="text" #textInput (input)="dt.filterGlobal(textInput.value, 'contains')"
                   placeholder="Rechercher un terme"/>
        </span>

        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="px-5">Pages</th>
          <th class="px-5 ">Textes internes</th>
          <th class="px-5">Textes clients</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-translation let-rowData let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td class="px-5">
            {{translation.page}}
          </td>

          <td class="px-5">
            {{translation.adminValue}}
          </td>
          <td class="px-5" id="editableCol" [pEditableColumn]="rowData" [pEditableColumnField]="'Textes clients'"
              [pEditableColumnRowIndex]="index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <textarea class="px-5 w-full" pInputText type="text" (keydown)="disableNavigation($event)" [(ngModel)]="translation.clientValue"></textarea>
              </ng-template>
              <ng-template pTemplate="output">
                {{translation.clientValue}}
              </ng-template>
            </p-cellEditor>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end mt-5">
    <p-button type="submit" label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
              (click)="submitForm()" [loading]="loading"></p-button>
  </div>
</ng-template>
