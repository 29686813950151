import { ID } from '@datorama/akita';
import { Product } from '../../product/state/product.model';

export interface Fournisseur {
  id: ID;
  name: string;
  products?: Product[];
}

export function createFournisseur(params: Partial<Fournisseur>) {
  return {

  } as Fournisseur;
}
