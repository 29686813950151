import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  ClientType, DocumentType,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
} from 'common';
import {ID} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-recueil-bien-divers',
  templateUrl: './recueil-bien-divers.component.html',
  styleUrls: ['./recueil-bien-divers.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilBienDiversComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilBienDiversComponent),
      multi: true
    }
  ]
})
export class RecueilBienDiversComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  client: NaturalPerson|LegalPerson;
  documentType = DocumentType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public naturalPersonQuery: NaturalPersonQuery,
    public legalPersonQuery: LegalPersonQuery,
  ) {
    this.form = this.formBuilder.group({
      bienDivers: this.formBuilder.control({}),
    });
  }

  get bienDiversControl() {
    return this.form.get('bienDivers');
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      bienDivers: this.formBuilder.control({}),
    });
    if(this.clientOrAdmin === 'ADMIN'){
      this.form.addControl('comConseilleBienDivers', this.formBuilder.control(null));
    }
    if(this.clientOrAdmin === 'CLIENT'){
      this.form.addControl('comBienDivers', this.formBuilder.control(null));
      this.form.addControl('docs', this.formBuilder.control(null));
    }

    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        //on remet les documents et bienDivers dans leur objet initial
        const { docs, bienDivers, ...rest } = value;
        this.onChange({...rest, ...value.docs, bienDivers: value.bienDivers.bienDivers, autreBienDivers: value.bienDivers.autreBienDivers});
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(this.clientOrAdmin === 'ADMIN') {
      this.value = {
        bienDivers: {bienDivers: value.bienDivers, autreBienDivers: value.autreBienDivers},
        comConseilleBienDivers: value.comConseilleBienDivers
      };
    }
    if(this.clientOrAdmin === 'CLIENT'){
      this.value = {
        bienDivers: {bienDivers: value.bienDivers, autreBienDivers: value.autreBienDivers},
        comBienDivers: value.comBienDivers,
        docs: {
          titreProprieteBienDivers: value.titreProprieteBienDivers,
          justifInvestissementBienDivers: value.justifInvestissementBienDivers,
        }
      };
    }
    this.form.markAsPristine();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientType'] && changes['clientId']){
      if (changes['clientType'].currentValue === ClientType.NATURAL_PERSON){
        this.naturalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(naturalPerson => {
          if(naturalPerson){
            this.client = naturalPerson;
          }
        });
      }
      if (changes['clientType'].currentValue === ClientType.LEGAL_PERSON){
        this.legalPersonQuery.selectEntity(this.clientId).pipe(untilDestroyed(this)).subscribe(legalPerson => {
          if(legalPerson){
            this.client = legalPerson;
          }
        });
      }
    }

  }

}
