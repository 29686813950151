<div [formGroup]="form">
  <div class="grid p-fluid">
    <div class="field col-3 w-auto">
      <label for="politiquementExpose">Personne politiquement exposée ?</label>
      <app-help-tooltip class="ml-2" [text]="'Il s’agit des personnes qui exercent, ou ont cessé d’exercer depuis moins' +
       ' d’un an, des fonctions politiques, juridictionnelles ou administratives pour le compte de la France, d’un État' +
        ' étranger ou d’une organisation internationale, ainsi que leurs proches.' +
         ' Les maires et les employés de mairie ne sont pas des personnes politiquement exposées.'">
      </app-help-tooltip>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.politiquementExpose}">Non</div>
        <p-inputSwitch  id="politiquementExpose"
                        formControlName="politiquementExpose"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.politiquementExpose}">Oui</div>
      </div>
    </div>
    <div class="field col-3 w-min ml-8">
      <label class="w-max" for="prochePolitiquementExpose">Proche d’une personne politiquement exposée ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.prochePolitiquementExpose}">Non</div>
        <p-inputSwitch  id="prochePolitiquementExpose"
                        formControlName="prochePolitiquementExpose"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.prochePolitiquementExpose}">Oui</div>
      </div>
    </div>
  </div>
</div>

