import { ID } from '@datorama/akita';
import { Task } from '../../task/state/task.model';
import { Step } from '../../step/state/step.model';

export interface Group {
  id: ID;
  delayBefore: number;
  position: number;
  tasks: Task[];
  step: Step;
}

export function createGroup(params: Partial<Group>) {
  return {

  } as Group;
}
