import {Injectable} from '@angular/core';
import {NaturalPerson} from '../store/client/naturalPerson/state/natural-person.model';
import {LegalPerson} from '../store/client/legalPerson/state/legal-person.model';
import {DetenteurBien, ImmobilierType} from '../enums/client/ImmobilierEnums';
import {DocumentType} from '../enums/client/DocumentType';
import {AvoirFinancierType} from '../enums/client/FinancierEnums';
import {ID} from '@datorama/akita';
import {SituationMatrimoniale} from '../enums/client/MatrimonialeEnums';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NaturalNaturalRelationTypeEnums} from '../enums/client/NaturalNaturalRelationTypeEnums';
import {AChargeFiscale} from '../enums/client/PersonneChargeEnums';
import {Document} from '../store/document/state/document.model';
import {DocsToAddQuery} from '../store/docsToAdd/state/docs-to-add.query';
import {DocsToDeleteQuery} from '../store/docsToDelete/state/docs-to-delete.query';

type docItemType = { doc: any | Document; owner?: any };
type categorieItemType = { label?: string; subLabel?: string | undefined; icon: string; targetId?: ID  };

export type docSummaryType = {
 sent: { categorie: categorieItemType; docItems?: docItemType[]}[];
 notSent: { categorie: categorieItemType; docItems?: docItemType[]}[];
};
@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class RecueilHelperService {

  documentType = DocumentType;
  avoirFinancierType = AvoirFinancierType;
  constructor(
    private docsToAddQuery: DocsToAddQuery,
    private docsToDeleteQuery: DocsToDeleteQuery,
    ) { }

  calculatePatrimoine(client: NaturalPerson|LegalPerson): number {
    let immoValue = 0;
    client.recueil?.bienImmobiliers?.map(immo=> {
      if(immo.valeur && immo.detenteur !== DetenteurBien.IMMO_DETENTEUR_AUTRE){
        immoValue += immo.valeur;
      }
    });

    let financierValue = 0;
    client.recueil?.avoirFinanciers?.map(financier=> {
      if(financier.valeur){
        financierValue += financier.valeur;
      }
    });

    return immoValue + financierValue;
  }

  getNaturalPersonDocument(client: NaturalPerson): docSummaryType {

    const enfants: Partial<NaturalPerson>[] | undefined = client.naturalNaturalRelations?.filter(val => val.type.label === NaturalNaturalRelationTypeEnums.ENFANT)
      .map(val => val.secondNaturalPerson);

    const aCharges: Partial<NaturalPerson>[] | undefined = client.naturalNaturalRelations?.filter(val =>
      (val.type.label !== NaturalNaturalRelationTypeEnums.ENFANT &&
        (val.aChargeFiscale === AChargeFiscale.CHARGE_PARTAGEE || val.aChargeFiscale === AChargeFiscale.CHARGE_TOTALE))
    ).map(val => val.secondNaturalPerson);

    //INDISPENSABLE
    const conjoint: Partial<NaturalPerson> | undefined = client.naturalNaturalRelations?.find(val =>
       (
        val.type.label === NaturalNaturalRelationTypeEnums.MARIE ||
        val.type.label === NaturalNaturalRelationTypeEnums.PACSE ||
        val.type.label === NaturalNaturalRelationTypeEnums.CONCUBIN
       )
    )?.secondNaturalPerson;

    const indispensableValue: docSummaryType = {sent:[], notSent:[]};
    let indispensableSent: docItemType[] = [];
    let indispensableNotSent: docItemType[] = [];

    if(client.pi) {
      indispensableSent = [...indispensableSent, {doc: {...client.pi, type: this.documentType.CIVIL_PI}}];
    } else {
      indispensableNotSent = [...indispensableNotSent, {doc: {type: this.documentType.CIVIL_PI}}];
    }
    if(conjoint){
      if(conjoint.pi) {
        indispensableSent = [...indispensableSent, {doc: {...conjoint.pi, type: this.documentType.CIVIL_PI}, owner: conjoint}];
      } else {
        indispensableNotSent = [...indispensableNotSent, {doc: {type: this.documentType.CIVIL_PI}, owner: conjoint}];
      }
    }

    indispensableValue.sent = [...indispensableValue.sent,
      { categorie: {label: 'STEP_RECAP_DOC_INDISPENSABLE', icon: 'icon-attention'}, docItems: indispensableSent }];
    indispensableValue.notSent = [...indispensableValue.notSent,
      { categorie: {label: 'STEP_RECAP_DOC_INDISPENSABLE', icon: 'icon-attention'}, docItems: indispensableNotSent }];

    //CONJOINT

    const conjointValue: docSummaryType = {sent:[], notSent:[]};
    let conjointSent: docItemType[] = [];
    let conjointNotSent: docItemType[] = [];

    if(conjoint){
      if(conjoint.justifDomicile) {
        conjointSent = [...conjointSent, {doc: {...conjoint.justifDomicile, type: this.documentType.CIVIL_JUSTIF_DOMICILE}, owner: conjoint}];
      } else {
        conjointNotSent = [...conjointNotSent, {doc: {type: this.documentType.CIVIL_JUSTIF_DOMICILE}, owner: conjoint}];
      }
    }

    conjointValue.sent = [...conjointValue.sent,
      { categorie: {label: 'STEP_RECAP_CONJOINT', icon: 'icon-1-cat-all-info-civiles'}, docItems: conjointSent }];
    conjointValue.notSent = [...conjointValue.notSent,
      { categorie: {label: 'STEP_RECAP_CONJOINT', icon: 'icon-1-cat-all-info-civiles'}, docItems: conjointNotSent }];

    //CIVILE

    const civileValue: docSummaryType = {sent:[], notSent:[]};
    let civileSent: docItemType[] = [];
    let civileNotSent: docItemType[] = [];

    if(client.otherDocs?.length){
      client.otherDocs.filter(val=>val.type === this.documentType.AUTRE_CIVIL.code).map(otherDoc => {
        civileSent = [...civileSent, {doc: {...otherDoc, type: this.documentType.AUTRE_CIVIL}}];
      });
    }
    if(client.justifDomicile) {
      civileSent = [...civileSent, {doc: {...client.justifDomicile, type: this.documentType.CIVIL_JUSTIF_DOMICILE}}];
    } else {
      civileNotSent = [...civileNotSent, {doc: {type: this.documentType.CIVIL_JUSTIF_DOMICILE}}];
    }
    if(client.situationMatrimoniale === SituationMatrimoniale.MARIE || client.situationMatrimoniale === SituationMatrimoniale.PACSE) {
      if(client.contratMariage){
        civileSent = [...civileSent, {doc: {...client.contratMariage, type: this.documentType.MATRIMONIAL_CONTRAT_MARIAGE}}];
      } else {
        civileNotSent = [...civileNotSent, {doc: {type: this.documentType.MATRIMONIAL_CONTRAT_MARIAGE}}];
      }
    }
    if(client.situationMatrimoniale === SituationMatrimoniale.MARIE) {
      if (client.donationDernierVivant) {
        if (client.attestDonation) {
          civileSent = [...civileSent, {doc: {...client.attestDonation, type: this.documentType.CIVIL_DONATION_DERNIER_VIVANT}}];
        } else {
          civileNotSent = [...civileNotSent, {doc: {type: this.documentType.CIVIL_DONATION_DERNIER_VIVANT}}];
        }
      }
    }
    if(client.situationMatrimoniale === SituationMatrimoniale.DIVORCE) {
      if(client.jugementDivorce){
        civileSent = [...civileSent, {doc: {...client.jugementDivorce, type: this.documentType.MATRIMONIAL_JUGEMENT_DIVORCE}}];
      } else {
        civileNotSent = [...civileNotSent, {doc: {type: this.documentType.MATRIMONIAL_JUGEMENT_DIVORCE}}];
      }
    }

    civileValue.sent = [...civileValue.sent,
      { categorie: {label: 'STEP_VOUS_ETAT_CIVIL', icon: 'icon-1-cat-all-info-civiles'}, docItems: civileSent }];
    civileValue.notSent = [...civileValue.notSent,
      { categorie: {label: 'STEP_VOUS_ETAT_CIVIL', icon: 'icon-1-cat-all-info-civiles'}, docItems: civileNotSent }];

    const familleValue: docSummaryType = {sent:[], notSent:[]};
    if(enfants?.length){
      if(client.livretFamille) {
        familleValue.sent = [
          { categorie: {label: 'STEP_FAMILLE_TITLE', icon: 'icon-3-cat-personne-a-charges--livret-de-famille'},
            docItems: [{doc: {...client.livretFamille, type: this.documentType.CIVIL_LIVRET_FAMILLE}}]}];
      } else {
        familleValue.notSent = [
          { categorie: {label: 'STEP_FAMILLE_TITLE', icon: 'icon-3-cat-personne-a-charges--livret-de-famille'},
            docItems: [{doc: {type: this.documentType.CIVIL_LIVRET_FAMILLE}}]}];
      }
    }


    //PERSONNES A CHARGE

    const enfantValue: docSummaryType = {sent:[], notSent:[]};
    let enfantSent: docItemType[] = [];
    let enfantNotSent: docItemType[] = [];


    if(enfants?.length){
      enfants.map(enfant => {

        if(enfant.pi) {
          enfantSent = [...enfantSent, {doc: {...enfant.pi, type: this.documentType.CIVIL_PI}, owner: enfant}];
        } else {
          enfantNotSent = [...enfantNotSent, {doc: {type: this.documentType.CIVIL_PI}, owner: enfant}];
        }

      });
    }

    enfantValue.sent = [...enfantValue.sent,
      { categorie: {label: 'STEP_RECAP_ENFANT', icon: 'icon-3-cat-personne-a-charges--enfants'}, docItems: enfantSent }];
    enfantValue.notSent = [...enfantValue.notSent,
      { categorie: {label: 'STEP_RECAP_ENFANT', icon: 'icon-3-cat-personne-a-charges--enfants'}, docItems: enfantNotSent }];

    const aChargeValue: docSummaryType = {sent:[], notSent:[]};
    let aChargeSent: docItemType[] = [];
    let aChargeNotSent: docItemType[] = [];

    if(aCharges?.length){
      aCharges.map(aCharge => {
        if (aCharge.pi) {
          aChargeSent = [...aChargeSent, {doc: {...aCharge.pi, type: this.documentType.CIVIL_PI}, owner: aCharge}];
        } else {
          aChargeNotSent = [...aChargeNotSent, {doc: {type: this.documentType.CIVIL_PI}, owner: aCharge}];
        }
      });
    }

    aChargeValue.sent = [...aChargeValue.sent,
      { categorie: {label: 'STEP_RECAP_PERSONNE_A_CHARGE', icon: 'icon-3-cat-personne-a-charges--personnes-a-charges'}, docItems: aChargeSent }];
    aChargeValue.notSent = [...aChargeValue.notSent,
      { categorie: {label: 'STEP_RECAP_PERSONNE_A_CHARGE', icon: 'icon-3-cat-personne-a-charges--personnes-a-charges'}, docItems: aChargeNotSent }];

    //IMMO

    const immoValue: docSummaryType = {sent:[], notSent:[]};

    client.recueil?.bienImmobiliers?.map(immo => {
      let sent: docItemType[] = [];
      let notSent: docItemType[] = [];

      if(immo.otherDocs?.length){
        immo.otherDocs.map(otherDoc => {
          sent = [...sent, {doc: {...otherDoc, type: this.documentType.AUTRE_IMMO}, owner: immo}];
        });
      }
      if(immo.attestNotarial){
        sent = [...sent, {doc: {...immo.attestNotarial, type: this.documentType.IMMO_ATTEST_NOTARIAL}, owner: immo}];
      } else {
        notSent = [...notSent, {doc: {type: this.documentType.IMMO_ATTEST_NOTARIAL}, owner: immo}];
      }
      if(immo.taxeFonciere){
        sent = [...sent, {doc: {...immo.taxeFonciere, type: this.documentType.IMMO_TAXE_FONCIERE}, owner: immo}];
      } else {
        notSent = [...notSent, {doc: {type: this.documentType.IMMO_TAXE_FONCIERE}, owner: immo}];
      }
      if(immo.titrePropriete){
        sent = [...sent, {doc: {...immo.titrePropriete, type: this.documentType.IMMO_TITRE_PROPRIETE}, owner: immo}];
      } else {
        notSent = [...notSent, {doc: {type: this.documentType.IMMO_TITRE_PROPRIETE}, owner: immo}];
      }

      if(immo.detenuSciOrScOrSarl){
        if(immo.statutSci){
          sent = [...sent, {doc: {...immo.statutSci, type: this.documentType.IMMO_STATUT_SCI}, owner: immo}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.IMMO_STATUT_SCI}, owner: immo}];
        }
      }

      if(immo.credit){
        if(immo.amortissement){
          sent = [...sent, {doc: {...immo.amortissement, type: this.documentType.IMMO_AMORTISSEMENT}, owner: immo}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.IMMO_AMORTISSEMENT}, owner: immo}];
        }
        if(immo.offrePret){
          sent = [...sent, {doc: {...immo.offrePret, type: this.documentType.IMMO_OFFRE_PRET}, owner: immo}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.IMMO_OFFRE_PRET}, owner: immo}];
        }
      }

      let immoIcon = '';
      let immoAddress: string | undefined = '';
      if(immo.address && immo.typeBien !== ImmobilierType.IMMO_TYPE_PRINCIPALE){
        if(immo.address.street){
          immoAddress += ', ' + immo.address.street;
        }
        if(immo.address.zipCode){
          immoAddress += ', ' + immo.address.zipCode;
        }
        if(immo.address.city){
          immoAddress += ', ' + immo.address.city;
        }
      }
      switch (immo.typeBien) {
        case ImmobilierType.IMMO_TYPE_PRINCIPALE:
          immoIcon = 'icon-4-cat-biens-immo--rprincipale';
          break;
        case ImmobilierType.IMMO_TYPE_SECONDAIRE:
          immoIcon = 'icon-4-cat-biens-immo--rsecondaire';
          break;
        case ImmobilierType.IMMO_TYPE_FONCIER_FORESTIER:
          immoIcon = 'icon-4-cat-biens-immo--foncier-forestier';
          break;
        case ImmobilierType.IMMO_TYPE_LOCATIF:
          immoIcon = 'icon-4-cat-biens-immo--locatif';
          break;
        case ImmobilierType.IMMO_TYPE_SCPI:
          immoIcon = 'icon-4-cat-biens-immo--scpi';
          break;
      }

      immoValue.sent = [...immoValue.sent,
        { categorie: {label: immo.typeBien, subLabel: immoAddress, icon: immoIcon, targetId: immo.id}, docItems: sent }];
      immoValue.notSent = [...immoValue.notSent,
        { categorie: {label: immo.typeBien, subLabel: immoAddress, icon: immoIcon, targetId: immo.id}, docItems: notSent }];
    });

    //FINANCIER
    const financierValue: docSummaryType = {sent:[], notSent:[]};

    client.recueil?.avoirFinanciers?.map(avoir => {
      let sent: docItemType[] = [];
      let notSent: docItemType[] = [];
      if(avoir.otherDocs?.length){
        avoir.otherDocs.map(otherDoc => {
          sent = [...sent, {doc: {...otherDoc, type: this.documentType.AUTRE_FINANCIER}, owner: avoir}];
        });
      }
      if(
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_FIP ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_FCPR ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_FCPI ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PART_HOLDING ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON
      ){
        if(avoir.titrePropriete) {
          sent = [...sent, {doc: {...avoir.titrePropriete, type: this.documentType.FINANCIER_TITRE_PROPRIETE}, owner: avoir}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.FINANCIER_TITRE_PROPRIETE}, owner: avoir}];
        }
      }

      if(
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_CHEQUE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_LIVRET ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_A ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_JEUNE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_LIVRET_EPARGNE_POPULAIRE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_EPARGNE_LOGEMENT ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_A_TERME ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_AUTRE_DISPONIBILITE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEL
      ){
        if(avoir.releveCompte) {
          sent = [...sent, {doc: {...avoir.releveCompte, type: this.documentType.FINANCIER_RELEVE_COMPTE}, owner: avoir}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.FINANCIER_RELEVE_COMPTE}, owner: avoir}];
        }
      }

      if(
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_COMPTE_TITRE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEA ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEA_PME ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_ASSURANCE_VIE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEP ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PER ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PERCO ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_CONTRAT_RETRAITE_MADELIN ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEE ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PEI ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PREFON ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_PERP ||
        avoir.typeAvoir === this.avoirFinancierType.AVOIR_FI_ART_83
      ){
        if(avoir.releveSituation) {
          sent = [...sent, {doc: {...avoir.releveSituation, type: this.documentType.FINANCIER_RELEVE_SITUATION}, owner: avoir}];
        } else {
          notSent = [...notSent, {doc: {type: this.documentType.FINANCIER_RELEVE_SITUATION}, owner: avoir}];
        }
      }

      financierValue.sent = [...financierValue.sent,
        { categorie: {label: avoir.typeAvoir, icon: 'icon-5-cat-avoirs-financiers', targetId: avoir.id}, docItems: sent }];
      financierValue.notSent = [...financierValue.notSent,
        { categorie: {label: avoir.typeAvoir, icon: 'icon-5-cat-avoirs-financiers', targetId: avoir.id}, docItems: notSent }];
    });

    //DIVERS
    const diversValue: docSummaryType = {sent:[], notSent:[]};
    let diversSent: docItemType[] = [];
    let diversNotSent: docItemType[] = [];

    if(client.otherDocs?.length){
      client.otherDocs.filter(val=>val.type === this.documentType.AUTRE_DIVERS.code).map(otherDoc => {
        diversSent = [...diversSent, {doc: {...otherDoc, type: this.documentType.AUTRE_DIVERS}}];
      });
    }

    if(client.recueil?.bienDiversOwner) {
      if(client.recueil?.justifInvestissementBienDivers){
        diversSent = [...diversSent, {doc: {...client.recueil?.justifInvestissementBienDivers, type: this.documentType.DIVERS_JUSTIF_INVEST}}];
      } else {
        diversNotSent = [...diversNotSent, {doc: {type: this.documentType.DIVERS_JUSTIF_INVEST}}];
      }
      if(client.recueil?.titreProprieteBienDivers){
        diversSent = [...diversSent, {doc: {...client.recueil.titreProprieteBienDivers, type: this.documentType.DIVERS_TITRE_PROPRIETE}}];
      } else {
        diversNotSent = [...diversNotSent, {doc: {type: this.documentType.DIVERS_TITRE_PROPRIETE}}];
      }
    }

    diversValue.sent = [...diversValue.sent,
      { categorie: {label: 'STEP_BIENSDIV_TITLE', icon: 'icon-6-cat-biens-divers'}, docItems: diversSent }];
    diversValue.notSent = [...diversValue.notSent,
      { categorie: {label: 'STEP_BIENSDIV_TITLE', icon: 'icon-6-cat-biens-divers'}, docItems: diversNotSent }];


    //IMPOT
    const impotValue: docSummaryType = {sent:[], notSent:[]};

    let impotSent: docItemType[] = [];
    let impotNotSent: docItemType[] = [];

    if(client.avisImpot) {
      impotSent = [...impotSent, {doc: {...client.avisImpot, type: this.documentType.IMPOT_AVIS}}];
    } else {
      impotNotSent = [...impotNotSent, {doc: {type: this.documentType.IMPOT_AVIS}}];
    }
    if(client.declarationImpot) {
      impotSent = [...impotSent, {doc: {...client.declarationImpot, type: this.documentType.IMPOT_DECLARATION}}];
    } else {
      impotNotSent = [...impotNotSent, {doc: {type: this.documentType.IMPOT_DECLARATION}}];
    }

    impotValue.sent = [...impotValue.sent,
      { categorie: {label: 'STEP_RECAP_IMPOT', icon: 'icon-7-cat-infos-complementaire--impots'}, docItems: impotSent }];
    impotValue.notSent = [...impotValue.notSent,
      { categorie: {label: 'STEP_RECAP_IMPOT', icon: 'icon-7-cat-infos-complementaire--impots'}, docItems: impotNotSent }];


    //CREDIT
    const creditValue: docSummaryType = {sent:[], notSent:[]};

    client.recueil?.creditNonImmobiliers?.map(credit => {
      let sent: docItemType[] = [];
      let notSent: docItemType[] = [];

      if(credit.otherDocs?.length){
        credit.otherDocs.map(otherDoc => {
          sent = [...sent, {doc: {...otherDoc, type: this.documentType.AUTRE_CREDIT}, owner: credit}];
        });
      }
      if(credit.offreCredit){
        sent = [...sent, {doc: {...credit.offreCredit, type: this.documentType.CREDIT_OFFRE}, owner: credit}];
      } else {
        notSent = [...notSent, {doc: {type: this.documentType.CREDIT_OFFRE}, owner: credit}];
      }

      if(credit.amortissement){
        sent = [...sent, {doc: {...credit.amortissement, type: this.documentType.CREDIT_AMORTISSEMENT}, owner: credit}];
      } else {
        notSent = [...notSent, {doc: {type: this.documentType.CREDIT_AMORTISSEMENT}, owner: credit}];
      }

      creditValue.sent = [...creditValue.sent,
        { categorie: {label: credit.objetPret, icon: 'icon-7-cat-infos-complementaire--credits', targetId: credit.id}, docItems: sent }];
      creditValue.notSent = [...creditValue.notSent,
        { categorie: {label: credit.objetPret, icon: 'icon-7-cat-infos-complementaire--credits', targetId: credit.id}, docItems: notSent }];

    });

    //DONATION
    const donationValue: docSummaryType = {sent:[], notSent:[]};

    let donationSent: docItemType[] = [];
    let donationNotSent: docItemType[] = [];

    if(client.otherDocs?.length){
      client.otherDocs.filter(val=>val.type === this.documentType.AUTRE_DONATION.code).map(otherDoc => {
        donationSent = [...donationSent, {doc: {...otherDoc, type: this.documentType.AUTRE_DONATION}}];
      });
    }
    if(client.recueil?.realiseDonation){
      if(client.acteDonation) {
        donationSent = [...donationSent, {doc: {...client.acteDonation, type: this.documentType.ACTE_DONATION}}];
      } else {
        donationNotSent = [...donationNotSent, {doc: {type: this.documentType.ACTE_DONATION}}];
      }
    }
    if(client.recueil?.redigeTestament){
      if(client.testament) {
        donationSent = [...donationSent, {doc: {...client.testament, type: this.documentType.TESTAMENT}}];
      } else {
        donationNotSent = [...donationNotSent, {doc: {type: this.documentType.TESTAMENT}}];
      }
    }

    donationValue.sent = [...donationValue.sent,
      { categorie: {label: 'STEP_RECAP_DONATION_TESTAMENT', icon: 'icon-7-cat-infos-complementaire--donations-et-testaments'}, docItems: donationSent }];
    donationValue.notSent = [...donationValue.notSent,
      { categorie: {label: 'STEP_RECAP_DONATION_TESTAMENT', icon: 'icon-7-cat-infos-complementaire--donations-et-testaments'}, docItems: donationNotSent }];

    //PREVOYANCE
    const prevoyanceValue: docSummaryType = {sent:[], notSent:[]};

    let prevoyanceSent: docItemType[] = [];
    let prevoyanceNotSent: docItemType[] = [];

    if(client.recueil?.contratPrevoyance) {
      if(client.otherDocs?.length){
        client.otherDocs.filter(val=>val.type === this.documentType.AUTRE_PREVOYANCE.code).map(otherDoc => {
          prevoyanceSent = [...prevoyanceSent, {doc: {...otherDoc, type: this.documentType.AUTRE_PREVOYANCE}}];
        });
      }
      if(client.recueil?.conditionPrevoyances){
        prevoyanceSent = [...prevoyanceSent, {doc: {...client.recueil.conditionPrevoyances, type: this.documentType.PREVOYANCE_CONDITION}}];
      } else {
        prevoyanceNotSent = [...prevoyanceNotSent, {doc: {type: this.documentType.PREVOYANCE_CONDITION}}];
      }
      if(client.recueil?.certifPrevoyances){
        prevoyanceSent = [...prevoyanceSent, {doc: {...client.recueil.certifPrevoyances, type: this.documentType.PREVOYANCE_CERTIF}}];
      } else {
        prevoyanceNotSent = [...prevoyanceNotSent, {doc: {type: this.documentType.PREVOYANCE_CERTIF}}];
      }
    }

    prevoyanceValue.sent = [...prevoyanceValue.sent,
      { categorie: {label: 'STEP_RECAP_PREVOYANCE', icon: 'icon-7-cat-infos-complementaire--prevoyances'}, docItems: prevoyanceSent }];
    prevoyanceValue.notSent = [...prevoyanceValue.notSent,
      { categorie: {label: 'STEP_RECAP_PREVOYANCE', icon: 'icon-7-cat-infos-complementaire--prevoyances'}, docItems: prevoyanceNotSent }];


    const summary = {
      sent: [
        ...indispensableValue.sent,
        ...civileValue.sent,
        ...conjointValue.sent,
        ...familleValue.sent,
        ...enfantValue.sent,
        ...aChargeValue.sent,
        ...immoValue.sent,
        ...financierValue.sent,
        ...diversValue.sent,
        ...impotValue.sent,
        ...creditValue.sent,
        ...donationValue.sent,
        ...prevoyanceValue.sent,
      ],
      notSent: [
        ...indispensableValue.notSent,
        ...civileValue.notSent,
        ...conjointValue.notSent,
        ...familleValue.notSent,
        ...enfantValue.notSent,
        ...aChargeValue.notSent,
        ...immoValue.notSent,
        ...financierValue.notSent,
        ...diversValue.notSent,
        ...impotValue.notSent,
        ...creditValue.notSent,
        ...donationValue.notSent,
        ...prevoyanceValue.notSent,
      ]
    };
    return summary;
  }

  //netoyage des items de collections vides et données conditionelles coté conseillé
  clean(values: any): any{
    const enfants: Partial<NaturalPerson>[] | undefined = values.naturalNaturalRelations?.filter((val: any) => val.type.label === NaturalNaturalRelationTypeEnums.ENFANT)
      .map((val: any) => val.secondNaturalPerson);

    const aCharges: Partial<NaturalPerson>[] | undefined = values.naturalNaturalRelations?.filter((val: any) =>
      (val.type.label !== NaturalNaturalRelationTypeEnums.ENFANT &&
        (val.aChargeFiscale === AChargeFiscale.CHARGE_PARTAGEE || val.aChargeFiscale === AChargeFiscale.CHARGE_TOTALE))
    ).map((val: any) => val.secondNaturalPerson);

    ////////////////////////////////IMMOBILIER
    // regarde tout les champ des objet immo, excepté le type de bien qui est add par défaut, et le retire si les champs sont null ou false
    values.recueil.bienImmobiliers = values.recueil.bienImmobiliers?.filter((val: any) => {
      const { typeBien, ...rest } = val;
      return !Object.values(rest).every( field => field === null || field === false);
    });
    // champs conditionnels
    values.recueil.bienImmobiliers?.map((val: any) => {
      if(!val.credit){
        val.typeCredit = null;
        val.souscripteur = null;
      }
      if(!val.genereRevenus){
        val.revenusAnnuels = null;
      }
      if(!val.detenuSciOrScOrSarl){
        val.companyName = null;
        val.partsDetenues = null;
      }
    });

    ////////////////////////////////FINANCIER
    // regarde tout les champ des objet financier, excepté le type d'avoir qui est add par défaut, et le retire si les champs sont null ou false
    values.recueil.avoirFinanciers = values.recueil.avoirFinanciers?.filter((val: any) => {
      const { typeAvoir, ...rest } = val;
      return !Object.values(rest).every( field => field === null || field === false);
    });
    // champs conditionnels
    values.recueil.avoirFinanciers?.map((val: any) => {
      if(!val.versementProgramme){
        val.montantVersementProgramme = null;
      }
    });

    ////////////////////////////////CREDIT
    // regarde tout les champ des objet credit, excepté l'objet du pret qui est add par défaut, et le retire si les champs sont null ou false
    values.recueil.creditNonImmobiliers = values.recueil.creditNonImmobiliers?.filter((val: any) => {
      const { objetPret, ...rest } = val;
      return !Object.values(rest).every( field => field === null || field === false);
    });

    ////////////////////////////////INFORMATIONS CIVILE
    if(values.dead === false){
      values.deathDate = null;
    }

    ////////////////////////////////PHONE
    if(values.phones) {
      values.phones = values.phones.filter((val: any) => val.number);
    }
    ////////////////////////////////EMAIL
    if(values.emails) {
      values.emails = values.emails.filter((val: any) => val.email);
    }
    ////////////////////////////////ADRESSES
    if(values.addresses) {
      values.addresses = values.addresses.filter((val: any) => {
        const { isContact,type, ...rest } = val;
        return !Object.values(rest).every( field => field === null || field === false);
      });
    }
    if(values.rentPrincipalResidence === false){
      values.loyerAnnuel = null;
    }
    ////////////////////////////////INFORMATIONS MATRIMONIALE
    if(values.situationMatrimoniale && values.situationMatrimoniale !== SituationMatrimoniale.MARIE &&
      values.situationMatrimoniale !== SituationMatrimoniale.PACSE){
      values.dateMariage = null;
      values.lieuMariage = null;
    }
    if(values.situationMatrimoniale && values.situationMatrimoniale !== SituationMatrimoniale.MARIE){
      values.regimeMatrimonial = null;
      values.donationDernierVivant = false;
      values.sensDonation = null;
    }
    if(values.situationMatrimoniale && values.situationMatrimoniale !== SituationMatrimoniale.PACSE){
      values.regimePacsimonial = null;
      values.testamentCroise = false;
      values.sensTestamentCroise = null;
    }

    ////////////////////////////////INFORMATIONS COMPLEMENTAIRE
    if(values.vecuEtrange === false){
      values.vecuEtrangeDixAns = false;
    }

    ////////////////////////////////QUESTIONS FILTRANTES
    if(values.recueil.bienImmobiliers?.length > 0){
      values.recueil.bienImmobilierOwner = true;
    } else {
      values.recueil.bienImmobilierOwner = false;
    }

    if(values.recueil.avoirFinanciers?.length > 0){
      values.recueil.avoirFinancierOwner = true;
    } else {
      values.recueil.avoirFinancierOwner = false;
    }
    if(values.recueil.bienDivers?.length > 0 || (values.recueil.autreBienDivers !== '' && values.recueil.autreBienDivers !== null)){
      values.recueil.bienDiversOwner = true;
    } else {
      values.recueil.bienDiversOwner = false;
    }

    if(aCharges?.length || enfants?.length){
      values.recueil.personneACharge = true;
    } else {
      values.recueil.personneACharge = false;

    }
    return values;
  }

  //netoyage des items de collections vides coté client
  cleanRecueilClient(values: any): any{
    //suppression de toute natural person n'ayant pas civility, firstname ou lastname
    values.naturalNaturalRelations  = values.naturalNaturalRelations.filter((relation: any) =>
      relation.secondNaturalPerson.civility !== null && relation.secondNaturalPerson.civility !== '' &&
      relation.secondNaturalPerson.firstName !== null && relation.secondNaturalPerson.firstName !== '' &&
      relation.secondNaturalPerson.lastName !== null && relation.secondNaturalPerson.lastName !== ''
    );
    return values;
  }

  handleDocToDelete(values: any): any{
    const docToDelete = this.docsToDeleteQuery.getValue().docs;
    docToDelete.map(docItem => {
      switch (docItem.doc.type) {
        //CIVILE
        case DocumentType.CIVIL_PI:
          if(docItem.owner){
            values.naturalNaturalRelations.find((val: any) => val.secondNaturalPerson.id === docItem.owner.id).secondNaturalPerson.pi = null;
          } else {
            values.pi = null;
          }
          break;
        case DocumentType.CIVIL_JUSTIF_DOMICILE:
          values.justifDomicile = null;
          break;
        case DocumentType.CIVIL_DONATION_DERNIER_VIVANT:
          values.attestDonation = null;
          break;
        case DocumentType.CIVIL_LIVRET_FAMILLE:
          values.livretFamille = null;
          break;
        //MATRIMONIALE
        case DocumentType.MATRIMONIAL_JUGEMENT_DIVORCE:
          values.jugementDivorce = null;
          break;
        case DocumentType.MATRIMONIAL_CONTRAT_MARIAGE:
          values.contratMariage = null;
          break;
        //IMMO
        case DocumentType.IMMO_AMORTISSEMENT:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].amortissement = null;
          break;
        case DocumentType.IMMO_OFFRE_PRET:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].offrePret = null;
          break;
        case DocumentType.IMMO_ATTEST_NOTARIAL:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].attestNotarial = null;
          break;
        case DocumentType.IMMO_TAXE_FONCIERE:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].taxeFonciere = null;
          break;
        case DocumentType.IMMO_TITRE_PROPRIETE:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].titrePropriete = null;
          break;
        case DocumentType.IMMO_STATUT_SCI:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].statutSci = null;
          break;
        //DIVERS
        case DocumentType.DIVERS_JUSTIF_INVEST:
          values.recueil.justifInvestissementBienDivers = values.recueil.justifInvestissementBienDivers.filter((val: any) => val.id !== docItem.doc.id);
          break;
        case DocumentType.DIVERS_TITRE_PROPRIETE:
          values.recueil.titreProprieteBienDivers = values.recueil.titreProprieteBienDivers.filter((val: any) => val.id !== docItem.doc.id);
          break;
        //FINANCIER
        case DocumentType.FINANCIER_RELEVE_COMPTE:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].releveCompte = null;
          break;
        case DocumentType.FINANCIER_RELEVE_SITUATION:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].releveSituation = null;
          break;
        case DocumentType.FINANCIER_TITRE_PROPRIETE:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].titrePropriete = null;
          break;
        //IMPOT
        case DocumentType.IMPOT_AVIS:
          values.avisImpot = null;
          break;
        case DocumentType.IMPOT_DECLARATION:
          values.declarationImpot = null;
          break;
        //CREDIT
        case DocumentType.CREDIT_OFFRE:
          values.recueil.creditNonImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].amortissement = null;
          break;
        case DocumentType.CREDIT_AMORTISSEMENT:
          values.recueil.creditNonImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].offreCredit = null;
          break;
        //TESTAMENT
        case DocumentType.TESTAMENT:
          values.testament = null;
          break;
        case DocumentType.ACTE_DONATION:
          values.acteDonation = null;
          break;
        //PREVOYANCE
        case DocumentType.PREVOYANCE_CONDITION:
          values.recueil.conditionPrevoyances = values.recueil.conditionPrevoyances.filter((val: any) => val.id !== docItem.doc.id);
          break;
        case DocumentType.PREVOYANCE_CERTIF:
          values.recueil.certifPrevoyances = values.recueil.certifPrevoyances.filter((val: any) => val.id !== docItem.doc.id);
          break;
      }
    });
    return values;
  }

  handleDocToAdd(values: any): any{
    const docToAdd = this.docsToAddQuery.getValue().docs;
    docToAdd.map(docItem => {
      switch (docItem.doc.type) {
        case DocumentType.CIVIL_PI.code:
          if(docItem.owner){
            values.naturalNaturalRelations.find((val: any) => val.secondNaturalPerson.id === docItem.owner.id).secondNaturalPerson.pi = {type: docItem.doc.type, external: true};
          } else {
            values.pi = {type: docItem.doc.type, external: true};
          }
          break;
        case DocumentType.CIVIL_JUSTIF_DOMICILE.code:
          values.justifDomicile = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.CIVIL_DONATION_DERNIER_VIVANT.code:
          values.attestDonation = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.CIVIL_LIVRET_FAMILLE.code:
          values.livretFamille = {type: docItem.doc.type, external: true};
          break;
        //MATRIMONIALE
        case DocumentType.MATRIMONIAL_JUGEMENT_DIVORCE.code:
          values.jugementDivorce = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.MATRIMONIAL_CONTRAT_MARIAGE.code:
          values.contratMariage = {type: docItem.doc.type, external: true};
          break;
        //IMMO
        case DocumentType.IMMO_AMORTISSEMENT.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].amortissement = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMMO_OFFRE_PRET.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].offrePret = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMMO_ATTEST_NOTARIAL.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].attestNotarial = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMMO_TAXE_FONCIERE.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].taxeFonciere = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMMO_TITRE_PROPRIETE.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].titrePropriete = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMMO_STATUT_SCI.code:
          values.recueil.bienImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].statutSci = {type: docItem.doc.type, external: true};
          break;
        //DIVERS
        case DocumentType.DIVERS_JUSTIF_INVEST.code:
          values.recueil.justifInvestissementBienDivers = [...values.recueil.justifInvestissementBienDivers, {type: docItem.doc.type, external: true}];
          break;
        case DocumentType.DIVERS_TITRE_PROPRIETE.code:
          values.recueil.titreProprieteBienDivers = [...values.recueil.titreProprieteBienDivers, {type: docItem.doc.type, external: true}];
          break;
        //FINANCIER
        case DocumentType.FINANCIER_RELEVE_COMPTE.code:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].releveCompte = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.FINANCIER_RELEVE_SITUATION.code:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].releveSituation = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.FINANCIER_TITRE_PROPRIETE.code:
          values.recueil.avoirFinanciers.filter((val: any) => val.id === docItem.targetId)[0].titrePropriete = {type: docItem.doc.type, external: true};
          break;
        //IMPOT
        case DocumentType.IMPOT_AVIS.code:
          values.avisImpot = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.IMPOT_DECLARATION.code:
          values.declarationImpot = {type: docItem.doc.type, external: true};
          break;
        //CREDIT
        case DocumentType.CREDIT_OFFRE.code:
          values.recueil.creditNonImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].offreCredit = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.CREDIT_AMORTISSEMENT.code:
          values.recueil.creditNonImmobiliers.filter((val: any) => val.id === docItem.targetId)[0].amortissement = {type: docItem.doc.type, external: true};
          break;
        //TESTAMENT
        case DocumentType.TESTAMENT.code:
          values.testament = {type: docItem.doc.type, external: true};
          break;
        case DocumentType.ACTE_DONATION.code:
          values.acteDonation = {type: docItem.doc.type, external: true};
          break;
        //PREVOYANCE
        case DocumentType.PREVOYANCE_CONDITION.code:
          values.recueil.conditionPrevoyances = [...values.recueil.conditionPrevoyances, {type: docItem.doc.type, external: true}];
          break;
        case DocumentType.PREVOYANCE_CERTIF.code:
          values.recueil.certifPrevoyances = [...values.recueil.certifPrevoyances, {type: docItem.doc.type, external: true}];
          break;
      }
    });
    return values;
  }
}
