<form [formGroup]="form" *ngIf="isFormReady">
  <!-- CHAMPS CLIENTS CONCERNES - DATE - OBJET -->
  <div class="grid mt-3">
    <div class="field col-3" formArrayName="clientsConcernes">
      <label>Contact(s) concerné(s)</label>
      <div *ngFor="let client of clientsConcernesF.controls; let index = index" class="flex">
        <div class="flex w-full p-fluid">
          <p-autoComplete
            inputStyleClass="form-control"
            [forceSelection]="true"
            class="w-full"
            formControlName="{{ index }}"
            [suggestions]="clientSearchService.searchBarResults"
            placeholder="Choisir un client"
            (completeMethod)="clientSearchService.searchClient($event)"
            field="label"
            [minLength]="3">
          </p-autoComplete>
          <lib-field-error *ngIf="clientsConcernesF?.touched && clientsConcernesF?.errors" [fieldError]="clientsConcernesF?.errors"></lib-field-error>
          <ng-container *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
          <ng-template #addClientButton>
            <button pButton type="button" icon="pi pi-plus" class="flex-shrink-0 border-noround border-400 mb-2 ml-2" (click)="addClientConcerne()"></button>
          </ng-template>
          <ng-template #removeClientButton>
            <button pButton type="button" icon="pi pi-times" class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2" (click)="removeClientConcerne(index)"></button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="field col-3">
      <label for="meetingDate-input">Date du rendez-vous</label>
      <p-calendar appendTo="body"
                  styleClass="w-full"
                  formControlName="meetingDate"
                  id="meetingDate-input"
                  [firstDayOfWeek]=1
                  [disabledDays]="[0,6]"
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
      <lib-field-error *ngIf="meetingDateF?.touched && meetingDateF?.errors" [fieldError]="meetingDateF?.errors"></lib-field-error>
    </div>
    <div class="field col-6">
      <label for="subject-input">Objet du rendez-vous</label>
      <input formControlName="subject" id="subject-input" type="text" pInputText class="inputfield w-full">
      <lib-field-error *ngIf="subjectF?.touched && subjectF?.errors" [fieldError]="subjectF?.errors"></lib-field-error>
    </div>
  </div>
  <!-- CHAMPS COLLABORATEURS PRESENTS - DURÉE -->
  <div class="grid mt-3">
    <div class="field col-3" formArrayName="collaboratorPresent">
      <label>Collaborateur(s) présent(s)</label>
      <div *ngFor="let collaborator of collaboratorPresentF.controls; let index = index" class="flex">
        <p-dropdown
          class="min-w-0 flex-grow-1"
          styleClass="w-full"
          formControlName="{{ index }}"
          id="{{ index }}"
          [options]="collaboratorPresentOptions"
          optionLabel="label"
          optionValue="value"
          [autoDisplayFirst]="false">
        </p-dropdown>
        <lib-field-error *ngIf="collaboratorPresentF?.touched && collaboratorPresentF?.errors" [fieldError]="collaboratorPresentF?.errors"></lib-field-error>
        <ng-container *ngIf="index === 0 ; then addCollaboratorButton else removeCollaboratorButton"></ng-container>
        <ng-template #addCollaboratorButton>
          <button pButton type="button" icon="pi pi-plus" class="flex-shrink-0 border-noround border-400 mb-2 ml-2" (click)="addCollaboratorPresent()"></button>
        </ng-template>
        <ng-template #removeCollaboratorButton>
          <button pButton type="button" icon="pi pi-times" class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2" (click)="removeCollaboratorPresent(index)"></button>
        </ng-template>
      </div>
    </div>
    <div class="field col-3">
      <label for="meetingDuration-input">Durée du rendez-vous</label>
      <p-inputNumber formControlName="meetingDuration"
                     id="meetingDuration-input"
                     inputStyleClass="w-1"
                     buttonLayout="horizontal"
                     spinnerMode="horizontal"
                     decrementButtonClass="p-button"
                     decrementButtonIcon="pi pi-minus"
                     incrementButtonClass="p-button"
                     incrementButtonIcon="pi pi-plus"
                     suffix=" min"
                     [showButtons]="true"
                     [min]="0"
                     [step]="15"
      ></p-inputNumber>
      <lib-field-error *ngIf="meetingDurationF?.touched && meetingDurationF?.errors" [fieldError]="meetingDurationF?.errors"></lib-field-error>
    </div>
  </div>
  <div class="field mt-3">
    <p-editor class="w-full" formControlName="content" [style]="{'height':'320px'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
            <span class="ql-formats">
              <select class="ql-size">
                <option value="huge">Titre</option>
                <option value="large">Sous-titre</option>
                <option selected></option>
              </select>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <button type="button" class="ql-strike" aria-label="Strike"></button>
            </span>
            <span class="ql-formats">
              <select title="Text Color" class="ql-color" defaultValue="rgb(0, 0, 0)"></select>
              <select title="Background Color" class="ql-background" defaultValue="rgb(255, 255, 255)"></select>
          </span>
            <span class="ql-formats">
              <button type="button" class="ql-list" value="ordered" aria-label="List"></button>
              <button type="button" class="ql-list" value="bullet" aria-label="Bullet"></button>
            </span>
            <span class="ql-formats">
              <button type="button" class="ql-link" aria-label="Link"></button>
              <button type="button" class="ql-image" aria-label="Image"></button>
            </span>
        </span>
      </ng-template>
    </p-editor>
    <lib-field-error *ngIf="contentF?.touched && contentF?.errors" [fieldError]="contentF?.errors"></lib-field-error>
  </div>
</form>
