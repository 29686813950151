import {Component, forwardRef, OnDestroy} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DropdownOptionsService, DropdownOption} from 'common';
import {ID} from '@datorama/akita';
import {UntilDestroy} from '@ngneat/until-destroy';
@UntilDestroy()

@Component({
  selector: 'app-info-cabinet-form',
  templateUrl: './info-cabinet-form.component.html',
  styleUrls: ['./info-cabinet-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InfoCabinetFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InfoCabinetFormComponent),
      multi: true
    }
  ]
})
export class InfoCabinetFormComponent implements ControlValueAccessor, OnDestroy {
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  interlocutorOptions: DropdownOption<{ id } | null>[] = [];
  segmentationOptions: DropdownOption[];
  statusOptions: DropdownOption[];
  customerOriginOptions: DropdownOption[];
  currentDate = new Date();
  initialValues =
    {
      mainInterlocutor: null,
      secondaryInterlocutor: null,
      dateEntreeEnRelation: null,
      customerOrigin: '',
      customerStatus: [],
      segmentation: null,
    };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private dropdownService: DropdownOptionsService,
    ) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.interlocutorOptions = this.dropdownService.collaborators;
    this.interlocutorOptions.unshift({
      label: 'Aucun',
      value: null
    });

    this.statusOptions = this.dropdownService.clientStatuses;
    this.segmentationOptions = this.dropdownService.segmentationClient;
    this.customerOriginOptions = this.dropdownService.customerOrigins;
  }
  get mainInterlocutorF() { return this.form?.get('mainInterlocutor');}
  get secondaryInterlocutorF() { return this.form?.get('secondaryInterlocutor');}
  get segmentationF() { return this.form?.get('segmentation');}
  get dateEntreeEnRelationF() { return this.form?.get('dateEntreeEnRelation');}
  get customerOriginF() { return this.form?.get('customerOrigin');}
  get customerStatusF() { return this.form?.get('customerStatus');}
  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      this.value = {...value,
        mainInterlocutor: value.mainInterlocutor ? {id: value.mainInterlocutor?.id} : null,
        secondaryInterlocutor: value.secondaryInterlocutor ? {id: value.secondaryInterlocutor.id} : null
      };
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

}
