import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-comment-read',
  templateUrl: './comment-read.component.html',
  styleUrls: ['./comment-read.component.scss']
})
export class CommentReadComponent  {
  @Input() title?: string;
  @Input() content?: string;
  constructor() { }



}
