import { Inject, Injectable } from '@angular/core';
import { TaskState, TaskStore } from './task.store';
import { NgEntityService } from '@datorama/akita-ng-entity-service';

@Injectable({ providedIn: 'root' })
export class TaskService extends NgEntityService<TaskState> {

  constructor(
    @Inject('config') public override config: any,
    protected override store: TaskStore
  ) {
    super(store);
  }

}
