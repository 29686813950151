import { ID } from '@datorama/akita';
import { GroupMaster } from '../../groupMaster/state/group-master.model';
import { PoleInterne } from '../../../../enums/user/PoleInterne';

export interface TaskMaster {
  id: ID;
  name: string;
  importance: number;
  groupMaster: GroupMaster;
  plannedWorkload?: number;
  helpText?: string;
  pole?: PoleInterne;
}

export function createTaskMaster(params: Partial<TaskMaster>) {
  return {

  } as TaskMaster;
}
