import {Injectable} from '@angular/core';
import {ID, Order, QueryConfig, QueryEntity} from '@datorama/akita';
import {NaturalNaturalRelationTypeState, NaturalNaturalRelationTypeStore} from './natural-natural-relation-type.store';
import {NaturalNaturalRelationType} from './natural-natural-relation-type.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
@QueryConfig({
  sortBy: 'position',
  sortByOrder: Order.ASC
})
export class NaturalNaturalRelationTypeQuery extends QueryEntity<NaturalNaturalRelationTypeState> {

  constructor(protected override store: NaturalNaturalRelationTypeStore) {
    super(store);
  }

  selectInverse(value: string): Observable<NaturalNaturalRelationType | undefined> {
    return this.selectAll(({filterBy: ({label}) => label === value})).pipe(map((entity: any[]) => entity[0]?.inverseBy));
  }

}
