import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ID} from '@datorama/akita';
import {DocumentCodeType, DocumentType, NaturalPerson} from 'common';

@UntilDestroy()
@Component({
  selector: 'app-recueil-natural-person',
  templateUrl: './recueil-natural-person.component.html',
  styleUrls: ['./recueil-natural-person.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilNaturalPersonComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilNaturalPersonComponent),
      multi: true
    }
  ]
})
export class RecueilNaturalPersonComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() clientId: ID | undefined;
  @Input() personSituation = '';
  documentType = DocumentType;
  documentTypes: DocumentCodeType[] = [
    this.documentType.CIVIL_PI,
    this.documentType.CIVIL_JUSTIF_DOMICILE,
    this.documentType.AUTRE_CIVIL
  ];
  client: NaturalPerson;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  initialValues =
    {
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: null,
        dead: false,
        deathDate: null,
      },
      situationPro: {
        situationPro: null,
        currentJob: null,
        jobCompany: null,
        revenusAnnuel: null,
        rentesAnnuelle: null,
      },
      celebre: {
        politiquementExpose: null,
        prochePolitiquementExpose: null,
      },
      etranger: {
        usPerson: null,
        vecuEtrange: null,
        vecuEtrangeDixAns: null,
      },
      contact: {
        phones: [],
        emails: [],
        addresses: [],
        rentPrincipalResidence: null,
        loyerAnnuel: null,
      },
      comInfoCivile: '',
      docs: {
        pi: null,
        justifDomicile: null,
        jugementDivorce: null,
        otherDocs: null
      }
    };

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges
        .subscribe(value => {
          //on remet les documents dans leur objet initial
          const { docs, ...rest } = value;
          this.onChange({...rest, ...value.docs});
          this.onTouched();
        })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    if(this.personSituation === 'DIVORCE'){
      this.documentTypes = [...this.documentTypes, this.documentType.MATRIMONIAL_JUGEMENT_DIVORCE];
    }
  }

  writeValue(value: any) {
    if (value) {
      const { pi,
        justifDomicile,
        jugementDivorce,
        otherDocs,
        ...rest } = value;
      this.value = {...rest, docs: {
          pi: value.pi,
          justifDomicile: value.justifDomicile,
          jugementDivorce: value.jugementDivorce,
          otherDocs: value.otherDocs
        }};
    }
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {valid: false};
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.form.disable() : this.form.enable();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

}
