import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Input} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-signature-maker',
  templateUrl: './signature-maker.component.html',
  styleUrls: ['./signature-maker.component.scss']
})
export class SignatureMakerComponent implements OnInit, AfterViewInit {

  /* This compoment allow user to create a signature,
  It emit the data url encoded in base64 when the user end its drawing. (png encoded, can be use directly in <img src="..." />
  It emit null after signature is cleared.
  */

  @Input() height = 100;
  @Input() width = 100;
  @Output() signatureEventEmitter = new EventEmitter();

  canvasElement: any;
  showFakeSign: boolean | undefined;
  signaturePad: SignaturePad | undefined;

  constructor() { }

  @ViewChild('imageCanvas') set content(content: ElementRef) {
    if (content) {
      this.canvasElement = content.nativeElement;
    }
  }


  ngOnInit(): void {
    this.showFakeSign = true;
  }

  ngAfterViewInit(): void {
    this.canvasElement.width = this.width;
    this.canvasElement.height = this.height;
    this.signaturePad = new SignaturePad(this.canvasElement, {
      dotSize: 2,
      velocityFilterWeight: 0.9
    });
    this.signaturePad.addEventListener('beginStroke', () => {
      this.showFakeSign = false;
    });
    this.signaturePad.addEventListener('endStroke', () => {
      this.emitSignatureDataUrl();
    });
  }

  emitSignatureDataUrl() {
    this.signatureEventEmitter.emit(this.signaturePad?.toDataURL());
  }

  handleClearButtonClick() {
    this.signaturePad?.clear();
    this.signatureEventEmitter.emit(null);
  }
}
