import { Injectable } from '@angular/core';
import { NgEntityServiceNotifier } from '@datorama/akita-ng-entity-service';
import {MessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class EntityNotificationService {
  constructor(
    private notifier: NgEntityServiceNotifier,
    private messageService: MessageService,
  ) {}

  listen() {
    this.notifier.action$.subscribe((action) => {

      switch (action.storeName){
        case 'natural_people':
          switch (action.method){
            case 'PUT':
              if (action.type === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Les informations de ' + action.payload.lastName + ' ' + action.payload.firstName + ' ont bien été modifiées.',
                  life: 10000
                });
              }
              if (action.type === 'error'){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Une erreur est survenue lors de l\'enregistrement : ',
                  detail: 'Veuillez essayer à nouveau',
                  life: 15000
                });
              }
              break;
            case 'DELETE':
              if (action.type === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Le contact a bien été supprimé.',
                  life: 10000
                });
              }
              if (action.type === 'error'){
                this.messageService.add({
                  severity: 'error',
                  summary: 'Une erreur est survenue lors de la suppression : ',
                  detail: 'Veuillez essayer à nouveau',
                  life: 15000
                });
              }
          }
      }

    });
  }
}
