<form [formGroup]="form">
  <h4 class="rec">Cabinet</h4>
  <p-divider></p-divider>
  <div class="grid mt-5">
    <div class="field col-4">
      <label for="dateEntreeEnRelation">Date d'entrée en relation</label>
      <p-calendar appendTo="body"
                  styleClass="w-full"
                  formControlName="dateEntreeEnRelation"
                  id="dateEntreeEnRelation"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
      <lib-field-error *ngIf="dateEntreeEnRelationF?.touched && dateEntreeEnRelationF?.errors" [fieldError]="dateEntreeEnRelationF?.errors"></lib-field-error>
    </div>
    <div class="field col-4">
      <label for="customerOrigin-input">Origine de la relation</label>
      <p-dropdown styleClass="w-full"
                  id="customerOrigin-input"
                  formControlName="customerOrigin"
                  [options]="customerOriginOptions"
                  [autoDisplayFirst]="false"
      ></p-dropdown>
      <lib-field-error *ngIf="customerOriginF?.touched && customerOriginF?.errors" [fieldError]="customerOriginF?.errors"></lib-field-error>
    </div>
    <div class="field col-4">
      <label for="customerStatus-input">Statut</label>
      <p-multiSelect styleClass="inputfield w-full"
                     id="customerStatus-input"
                     [options]="statusOptions"
                     formControlName="customerStatus"
                     [filter]="false"
                     emptyMessage="aucun pôle disponible"
                     [showHeader]="false"
                     optionLabel="label"
                     optionValue="value"
                     display="chip"
      ></p-multiSelect>
      <lib-field-error *ngIf="customerStatusF?.touched && customerStatusF?.errors" [fieldError]="customerStatusF?.errors"></lib-field-error>
    </div>
  </div>
  <div class="grid">
    <div class="field col-4">
      <label for="mainInterlocutor-input">Interlocuteur principal</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="mainInterlocutor"
        id="mainInterlocutor-input"
        [options]="interlocutorOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="mainInterlocutorF?.touched && mainInterlocutorF?.errors" [fieldError]="mainInterlocutorF?.errors"></lib-field-error>
    </div>
    <div class="field col-4">
      <label for="secondaryInterlocutor-input">Interlocuteur secondaire</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="secondaryInterlocutor"
        id="secondaryInterlocutor-input"
        [options]="interlocutorOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="secondaryInterlocutorF?.touched && secondaryInterlocutorF?.errors" [fieldError]="secondaryInterlocutorF?.errors"></lib-field-error>
    </div>
    <div class="field col-4">
      <label for="segmentation-input">Segmentation client</label>
      <p-dropdown
        styleClass="w-full"
        formControlName="segmentation"
        id="segmentation-input"
        [options]="segmentationOptions"
        optionLabel="label"
        optionValue="value"
        placeholder="Sélectionnez"
        [autoDisplayFirst]="false"
      >
      </p-dropdown>
      <lib-field-error *ngIf="segmentationF?.touched && segmentationF?.errors" [fieldError]="segmentationF?.errors"></lib-field-error>
    </div>
  </div>
</form>
