import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NaturalNaturalRelationStore, NaturalNaturalRelationState } from './natural-natural-relation.store';

@Injectable({ providedIn: 'root' })
export class NaturalNaturalRelationQuery extends QueryEntity<NaturalNaturalRelationState> {

  constructor(protected override store: NaturalNaturalRelationStore) {
    super(store);
  }

}
