import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CompteRenduStore, CompteRenduState } from './compte-rendu.store';

@Injectable({ providedIn: 'root' })
export class CompteRenduQuery extends QueryEntity<CompteRenduState> {

  constructor(protected override store: CompteRenduStore) {
    super(store);
  }

}
