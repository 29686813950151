import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from './services/translator.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-common',
  template: `
    <p>
      common works!
    </p>
  `,
  styles: [
  ]
})
export class CommonComponent {

  constructor(private translate: TranslateService, private translatorService: TranslatorService) {
  }

}
