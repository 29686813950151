import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent {
  @Input() content = '';
  @Input() title = 'Note';
  constructor() { }

}
