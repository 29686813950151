<div>
  <h2 class="mt-5">Nouvelle Personne Physique</h2>
  <ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
    <app-desktop-only></app-desktop-only>
  </ng-container>
  <ng-template #desktop>
    <h4 class="rec">Contact</h4>
    <form [formGroup]="form">
      <p-divider></p-divider>
      <app-info-civile-form formControlName="infoCivile"></app-info-civile-form>

      <app-info-cabinet-form formControlName="infoCabinet"></app-info-cabinet-form>

      <h4 class="rec">Coordonnées</h4>
      <p-divider></p-divider>
      <app-email-form formControlName="email"></app-email-form>
      <app-phone-form formControlName="phone"></app-phone-form>
      <app-address-form formControlName="address"></app-address-form>
    </form>
    <div class="col-12 flex justify-content-end mt-5">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="cancel()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="submitForm()" [loading]="submissionLoading"></p-button>
    </div>
  </ng-template>
</div>
