import { Injectable } from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import { LegalPerson } from '../store/client/legalPerson/state/legal-person.model';
import { NaturalPerson } from '../store/client/naturalPerson/state/natural-person.model';
import { LegalPersonQuery } from '../store/client/legalPerson/state/legal-person.query';
import { NaturalPersonQuery } from '../store/client/naturalPerson/state/natural-person.query';
import {ClientType} from '../enums/client/ClientType';


@Injectable({
  providedIn: 'root'
})
export class ClientSearchService {

  // texte recherché
  text = '';
  // resultat de recherche avec objet complet
  searchResults: any[] = [];
  // format pour affichage de l'autocomplete
  searchBarResults: {label: string; value: any; items: LegalPerson[]| NaturalPerson[]}[] = [];

  constructor(private legalPersonQuery: LegalPersonQuery,
              private naturalPersonQuery: NaturalPersonQuery,) { }

  searchClient(event: any){
    let naturalPersonResults: any[] = [];
    let legalPersonResults: any[] = [];
    // get des legalPeople et naturalPeople en filtrant sur le text recherché
    // mise en forme du resultat dans un format adapté à l'autocomplete et en ajoutant le type LEGAL ou NATURAL
    const legalPersonObs = this.legalPersonQuery.selectAll({
      filterBy: [
        (entity) => entity.name.toLowerCase().includes(event.query.toLowerCase()),
      ]
    }).pipe(
      tap(() => legalPersonResults = []),
      map(clients => clients.map(client => {
        legalPersonResults = [...legalPersonResults, {label: client.name, value: {...client, clientType:ClientType.LEGAL_PERSON}}];
      }))
    );

    const naturalPersonObs = this.naturalPersonQuery.selectAll({
      filterBy: [
        (entity) => (entity.firstName.toLowerCase() + ' ' +entity.lastName.toLowerCase()).includes(event.query.toLowerCase()),
      ]
    }).pipe(
      tap(() => naturalPersonResults = []),
      map(clients => clients.map(client => {
        naturalPersonResults = [...naturalPersonResults, {
          label: client.lastName + ' ' + client.firstName,
          value: {
            ...client,
            clientType: ClientType.NATURAL_PERSON
          }
        }];
      }))
    );

    // reception des 2 observables
    // mise en forme de l'objet searchBarResults pour l'autocomplete, trié par type de clients

    combineLatest([legalPersonObs, naturalPersonObs]).subscribe(
      () => {
        this.searchResults = [];
        this.searchBarResults = [...legalPersonResults, ...naturalPersonResults].sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));
        naturalPersonResults.map(naturalPersonResult => {
          this.searchResults =  [...this.searchResults,naturalPersonResult.value];
        });

        legalPersonResults.map(legalPersonResult => {
          this.searchResults =  [...this.searchResults,legalPersonResult.value];
        });
      });
  }
}
