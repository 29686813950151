import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NaturalNaturalRelation } from './natural-natural-relation.model';

export interface NaturalNaturalRelationState extends EntityState<NaturalNaturalRelation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'natural_natural_relations' })
export class NaturalNaturalRelationStore extends EntityStore<NaturalNaturalRelationState> {

  constructor() {
    super();
  }

}
