import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CompteRendu } from './compte-rendu.model';

export interface CompteRenduState extends EntityState<CompteRendu> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'compte_rendus' })
export class CompteRenduStore extends EntityStore<CompteRenduState> {

  constructor() {
    super();
  }

}
