import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { TranslationStore, TranslationState } from './translation.store';

@Injectable({ providedIn: 'root' })
export class TranslationService extends NgEntityService<TranslationState> {

  constructor(protected override store: TranslationStore) {
    super(store);
  }

}
