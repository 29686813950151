import {ID} from '@datorama/akita';
import {Document} from '../../../document/state/document.model';

export interface CreditNonImmobilier {
  id: ID;
  typeCredit?: string;
  objetPret?: string;
  souscripteur?: string;
  amortissement?: Document;
  offreCredit?: Document;
  otherDocs?: Document[];

}

export function createCreditNonImmobilier(params: Partial<CreditNonImmobilier>) {
  return {

  } as CreditNonImmobilier;
}
