import {ID} from '@datorama/akita';
import {NaturalPerson} from '../../naturalPerson/state/natural-person.model';
import {NaturalNaturalRelationType} from '../../natural-natural-relation-type/state/natural-natural-relation-type.model';

export interface NaturalNaturalRelation {
  id?: ID;
  firstNaturalPerson: Partial<NaturalPerson>;
  secondNaturalPerson: Partial<NaturalPerson>;
  type: Partial<NaturalNaturalRelationType>;
  enfantDe: string;
  aChargeFiscale: string;
  adopted: string;
}

export function createNaturalNaturalRelation(params: Partial<NaturalNaturalRelation>) {
  return {

  } as NaturalNaturalRelation;
}
