<ng-container [formGroup]="form">
  <!-- Situation matrimoniale -->
  <h2 class="rec">{{'CLIENT_STEP_CONJOINT_WEDDING' | translate}}</h2>
  <p-divider></p-divider>
  <app-situation-matrimoniale-form [conjoint]="true" [personSituation]="personSituation"
                                   formControlName="situationMatrimoniale"></app-situation-matrimoniale-form>

  <!-- infos civiles -->
  <h2 class="rec">{{'CLIENT_STEP_CONJOINT_PARTNER' | translate}}</h2>
  <p-divider></p-divider>

  <div *ngIf="(uiRecueilQuery.isConjointSent$ | async) === false; else noform;">
    <p-button (click)="showConjointModal()" icon="pi pi-send" label="Demander à mon conjoint de remplir cette page"
              styleClass="p-button-raised mr-2"></p-button>

    <div class="pt-4">
      <app-info-civile-form [advanced]="true" formControlName="infoCivile"></app-info-civile-form>
    </div>

    <!-- Contact -->
    <h2 class="rec">{{'CLIENT_STEP_CONJOINT_CONTACT' | translate}}</h2>
    <p-divider></p-divider>
    <app-contact-form [clientOrAdmin]="'CLIENT'" [client]="client" [conjointHasAddresses]="conjointHasAddresses"
                      [conjointHasPhones]="conjointHasPhones" [conjoint]="true"
                      formControlName="contact"></app-contact-form>

    <!-- Situation pro -->
    <h2 class="rec">{{'CLIENT_STEP_CONJOINT_JOB' | translate}}</h2>
    <p-divider></p-divider>
    <app-situation-pro-form formControlName="situationPro"></app-situation-pro-form>

    <!-- Celebre -->
    <h2 class="rec">{{'CLIENT_STEP_CONJOINT_FAMOUS' | translate}}</h2>
    <p-divider></p-divider>
    <app-celebre-form formControlName="celebre"></app-celebre-form>

    <!-- International -->
    <h2 class="rec">{{'CLIENT_STEP_CONJOINT_US_PERSON' | translate}}</h2>
    <p-divider></p-divider>
    <app-etranger-form [clientOrAdmin]="'ADMIN'" formControlName="etranger"></app-etranger-form>
  </div>

  <!-- Documents -->
  <ng-container
    *ngIf="personSituation === 'MARIE' && form.get('situationMatrimoniale')?.value.donationDernierVivant === true; else noDonationCase">
    <app-documents-form
      [clientId]="clientId"
      [clientType]="'NATURAL_PERSON'"
      [documentTypes]="[
          this.documentType.MATRIMONIAL_CONTRAT_MARIAGE,
          this.documentType.CIVIL_DONATION_DERNIER_VIVANT,
          this.documentType.CIVIL_PI,
          this.documentType.CIVIL_JUSTIF_DOMICILE,
          this.documentType.AUTRE_CIVIL
        ]"
      formControlName="docs"></app-documents-form>
  </ng-container>
  <ng-template #noDonationCase>
    <app-documents-form
      [clientId]="clientId"
      [clientType]="'NATURAL_PERSON'"
      [documentTypes]="[
          this.documentType.MATRIMONIAL_CONTRAT_MARIAGE,
          this.documentType.CIVIL_PI,
          this.documentType.CIVIL_JUSTIF_DOMICILE,
          this.documentType.AUTRE_CIVIL
        ]"
      formControlName="docs"></app-documents-form>
  </ng-template>

  <div *ngIf="(uiRecueilQuery.isConjointSent$ | async) === false">
    <!-- Commentaire -->
    <h2 class="rec">{{'CLIENT_STEP_CONJOINT_COMMENT' | translate}}</h2>
    <p-divider></p-divider>
    <app-comment-form formControlName="comConjoint"></app-comment-form>
  </div>

  <ng-template #noform>
    <app-note
      title="Demande envoyée"
      content="Vous avez envoyé une demande de complétion à votre conjoint. Il n’y a
      plus qu’à attendre sa réponse !">
    </app-note>
  </ng-template>
</ng-container>

<!-- Conjoint Modal -->
<p-dialog
  [(visible)]="showConjointInfoModal"
  [modal]="true"
  [style]="{width: '70vw', height: '590px'}"
  header="Vous manquez de temps ou vous avez peur de vous tromper ? Demander à votre conjoint de remplir cette page à votre place.">
  <ng-container>
    <div class="p-d-flex p-ai-center p-3 field">
      <p>
        <strong>Attention :</strong>
        toutes les informations déjà saisies dans le formulaire ne pourront pas être conservées. Votre conjoint
        recevra un formulaire vierge à compléter lui-même.
      </p>
      <label> Pour lui demander de remplir cette page il vous suffit de renseigner les informations suivantes :
      </label>
      <app-conjoint-form #dialogForm [conjoint]="conjoint"></app-conjoint-form>
    </div>
  </ng-container>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end mt-5">
      <p-button (onClick)="closeConjointModal()" icon="pi pi-times" label="Annuler"
                styleClass="p-button-danger p-button-raised p-button-text">

      </p-button>
      <p-button (onClick)="sendToConjoint()" [disabled]="this.conjointFormComponent.getFormData() === null"
                icon="pi pi-send"
                label="Demander à mon conjoint de remplir cette page"
                styleClass="ml-3 p-button-primary p-button-raised">
      </p-button>
    </div>
  </ng-template>
</p-dialog>
