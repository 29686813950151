
<ng-container [formGroup]="form">

  <!-- infos civiles -->
  <h2 class="rec">{{'CLIENT_STEP_VOUS_SITUATION_CIVILE' | translate}}</h2>
  <p-divider></p-divider>
  <app-info-civile-form formControlName="infoCivile"
                        [advanced]="true"
                        [existence]="true"
                        [fullNameDisabled]="true"
  ></app-info-civile-form>


    <!-- Contact -->
    <h2 class="rec">{{'CLIENT_STEP_VOUS_CONTACT' | translate}}</h2>
    <p-divider></p-divider>
    <app-contact-form [clientOrAdmin]="'CLIENT'" formControlName="contact" [client]="client" [recueilNaturalPerson]="true" ></app-contact-form>

    <!-- Situation pro -->
    <h2 class="rec">{{'CLIENT_STEP_VOUS_JOB' | translate}}</h2>
    <p-divider></p-divider>
    <app-situation-pro-form formControlName="situationPro"></app-situation-pro-form>

    <!-- Celebre -->
    <h2 class="rec">{{'CLIENT_STEP_VOUS_FAMOUS' | translate}}</h2>
    <p-divider></p-divider>
    <app-celebre-form formControlName="celebre"></app-celebre-form>

    <!-- International -->
    <h2 class="rec">{{'CLIENT_STEP_VOUS_US_PERSON' | translate}}</h2>
    <p-divider></p-divider>
    <app-etranger-form [clientOrAdmin]="'CLIENT'" formControlName="etranger"></app-etranger-form>

    <!-- Commentaire -->
    <h2 class="rec">{{'CLIENT_STEP_VOUS_COMMENT' | translate}}</h2>
    <p-divider></p-divider>
    <app-comment-form formControlName="comInfoCivile"></app-comment-form>

    <!-- Documents -->
    <app-documents-form
      formControlName="docs"
      [clientId]="clientId"
      [clientType]="'NATURAL_PERSON'"
      [documentTypes]="documentTypes"></app-documents-form>
</ng-container>






