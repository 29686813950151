import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {
  SessionQuery,
  Step,
  StepQuery,
  StepService,
  TaskHelperService,
  TaskQuery,
  TaskService,
  TaskStatus,
  Comment,
  UserRole,
  UserQuery,
  DropdownOptionsService,
  Task,
  UiScreenQuery,
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-close-task-modal',
  templateUrl: './close-task-modal.component.html',
  styleUrls: ['./close-task-modal.component.scss']
})
export class CloseTaskModalComponent implements OnInit, OnChanges {

  @Input() display = false;
  @Input() task: any|null = null;
  @Output() displayCloseDialog = new EventEmitter<boolean>();

  step!: Step;
  taskEditing: any;
  taskIndex = 0;
  groupIndex = 0;
  isFormReady = false;
  currentTaskDeadlinDate!: Date;
  rawTaskStatus = TaskStatus;
  collaboratorOptions: { label: string; value: any }[] = [];

  stepForm = this.formBuilder.group({
    id: null,
    name: '',
    product: null,
    amount: null,
    isFollowStep: false,
    legalPersons: null,
    naturalPersons: null,
    stepGroups:this.formBuilder.array([]),
  });

  internalTaskForm = this.formBuilder.group({
    name: [null, Validators.required],
    startDate: [null, Validators.required],
    deadlineDate: [null, Validators.required],
    closingDate: null,
    importance: [null, Validators.required],
    plannedWorkload: [null],
    realWorkload: [null],
    poles: [null, Validators.required],
    collaborator: [null],
    contractor: [null, Validators.required],
    comments: this.formBuilder.array([]),
    status: [null],
    isInternal: [null],
    isPriority: [null],
    isPrivate: [null],
    isLeaderPrivate: [null],
  });
  isInternalTaskFormReady = false;
  taskHasBlockedComment: boolean;


  constructor(
    private taskHelperService: TaskHelperService,
    private formBuilder: UntypedFormBuilder,
    private stepService: StepService,
    private stepQuery: StepQuery,
    private taskService: TaskService,
    private taskQuery: TaskQuery,
    private messageService: MessageService,
    private userQuery: UserQuery,
    private dropdownService: DropdownOptionsService,
    private sessionQuery: SessionQuery,
    private router: Router,
    public uiScreenQuery: UiScreenQuery
  ) { }

  get groups() { return this.stepForm?.get('stepGroups') as UntypedFormArray; }
  get tasks() { return this.groups.controls[this.groupIndex].get('tasks') as UntypedFormArray; }
  get taskComments() { return this.tasks.controls[this.taskIndex].get('comments') as UntypedFormArray; }
  get taskClosingDate() { return this.tasks.controls[this.taskIndex].get('closingDate'); }
  get taskName() { return this.tasks.controls[this.taskIndex].get('name'); }
  get taskRealWorkload() { return this.tasks.controls[this.taskIndex].get('realWorkload'); }
  get taskPlannedWorkload() { return this.tasks.controls[this.taskIndex].get('plannedWorkload'); }
  get taskStatus() { return this.tasks.controls[this.taskIndex].get('status'); }
  get taskCollaborator() { return this.tasks.controls[this.taskIndex].get('collaborator'); }

  get internalTaskClosingDate() { return this.internalTaskForm?.get('closingDate'); }
  get internalTaskStatus() { return this.internalTaskForm?.get('status'); }
  get internalTaskRealWorkload() { return this.internalTaskForm?.get('realWorkload'); }
  get internalTaskPlannedWorkload() { return this.internalTaskForm?.get('plannedWorkload'); }
  get internalTaskName() { return this.internalTaskForm?.get('name'); }
  get internalTaskComments() { return this.internalTaskForm.get('comments') as UntypedFormArray; }
  get internalTaskCollaborator() { return this.internalTaskForm.get('collaborator'); }

  ngOnInit(): void {
    this.setCollaboratorsOptions();
  }

  setCollaboratorsOptions() {
    this.userQuery
      .selectAll()
      .pipe(untilDestroyed(this))
      .subscribe((users) => {
        const collaborators = users.filter((user) =>
          user.roles.includes(UserRole.ROLE_COLLABORATEUR)
        );
        const collaboratorOptions = collaborators.map((collaborator) => this.dropdownService.mapUserIntoDropdownOption(collaborator))
          .sort((resultA, resultB) => resultA.label.localeCompare(resultB.label, 'fr', {sensitivity: 'base'}));

        // @ts-ignore
        collaboratorOptions.unshift({label: 'Aucun', value: null});

        this.collaboratorOptions = collaboratorOptions;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.task.comments) {
      this.taskHasBlockedComment = this.task.comments.some((comment: Comment) => comment.blocked && !comment.resolved);
    }

    if((changes['task'] && changes['task'].currentValue) || changes['display'] && changes['display'].currentValue) {
      if(!this.task?.isInternal){
        this.stepQuery.selectEntity(this.task.step.id).pipe(untilDestroyed(this)).subscribe(step => {
          if(step){
            this.step = step;
            step.stepGroups.map( (group, groupIndex) => {
              group.tasks.map((task, taskIndex) => {
                if(task.id === this.task.id){
                  this.groupIndex = groupIndex;
                  this.taskIndex = taskIndex;
                }
              });
            });
            this.taskEditing = step.stepGroups[this.groupIndex].tasks[this.taskIndex];
            this.currentTaskDeadlinDate = this.taskEditing.deadlineDate;
            this.stepForm = this.taskHelperService.populateTaskEditingForm(this.step, this.groupIndex, this.taskIndex);
            this.taskRealWorkload?.setValue(this.taskPlannedWorkload?.value);
            this.taskClosingDate?.setValue(new Date());
            //si pas de collaborateur assigné, prerempli avec user connecté
            if (this.taskCollaborator?.value === null){
              this.sessionQuery
                .select()
                .pipe(untilDestroyed(this))
                .subscribe((currentUser) => {
                  this.taskCollaborator?.setValue({id: currentUser.id});
                });
            }
            this.isFormReady = true;
          }
        });
      }
      if(this.task?.isInternal){
        this.taskQuery.selectEntity(this.task.id).pipe(untilDestroyed(this)).subscribe(task => {
          if(task){
            this.taskEditing = task;
            this.currentTaskDeadlinDate = this.taskEditing.deadlineDate;
            this.internalTaskForm = this.taskHelperService.populateInternalTaskEditingForm(this.taskEditing);
            this.internalTaskRealWorkload?.setValue(this.internalTaskPlannedWorkload?.value);
            this.internalTaskClosingDate?.setValue(new Date());
            //si pas de collaborateur assigné, prerempli avec user connecté
            if (this.internalTaskCollaborator?.value === null){
              this.sessionQuery
                .select()
                .pipe(untilDestroyed(this))
                .subscribe((currentUser) => {
                  this.internalTaskCollaborator?.setValue({id: currentUser.id});
                });
            }
            this.isFormReady = true;
          }
        });
      }
    }
  }

  onCloseDialog() {
    this.isFormReady = false;
    this.isInternalTaskFormReady = false;
    this.stepForm.reset();
    this.internalTaskForm.reset();
    this.displayCloseDialog.emit(false);
  }

  editTask(task: Task) {
    if (!task.isInternal) {
      this.router.navigate(['/task-customer-edit', this.task.step.id, this.task.id]);
    } else if (task.isInternal) {
      this.router.navigate(['/task-internal-edit/', this.task.id]);
    }
  }

  closeTask() {
    if(!this.taskEditing.isInternal) {
      //save close form data to apply after populate form
      const closingDate = this.taskClosingDate?.value;
      const realWorkload = this.taskRealWorkload?.value;
      const taskComment = this.taskComments?.value;
      const taskCollaborator = this.taskCollaborator?.value;

      //update step form with recalculated date and status.
      const isGroupComplete = this.taskHelperService.isGroupComplete(this.tasks.value, this.taskEditing.id);
      if( isGroupComplete ) {
        const offset = this.taskHelperService.getCloseDateOffset(this.step, this.groupIndex);

        this.groups.reset(this.taskHelperService.recalculateStepDateAndStatus(this.groups.value, this.groupIndex, offset));

      }
      this.stepForm.get('legalPersons')?.setValue(this.taskHelperService.getLegalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));
      this.stepForm.get('naturalPersons')?.setValue(this.taskHelperService.getNaturalPersonsConcernes(this.stepForm, this.groupIndex, this.taskIndex));
      this.taskStatus?.setValue(this.rawTaskStatus.DONE);
      this.taskClosingDate?.setValue(closingDate);
      this.taskRealWorkload?.setValue(realWorkload);
      this.taskComments?.setValue(taskComment);
      this.taskCollaborator?.setValue(taskCollaborator);
      // cleaning empty comments
      this.taskComments.value.map((comment: Comment, index: number) => {
        if(comment.content === ''){
          this.taskComments.removeAt(index);
        }
        comment.assignedUsers = comment.assignedUsers.filter((userId: any) => userId !== null);
      });
      this.stepService
        .update(this.step.id, this.stepForm.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'La tâche a été clôturée',
              life: 10000,
            });
            this.onCloseDialog();
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Une erreur est survenue lors de l\'enregistrement : ',
              detail: 'Veuillez essayer à nouveau',
              life: 15000,
            });
          },
        });
    } else {
      this.internalTaskStatus?.setValue(this.rawTaskStatus.DONE);
      // cleaning empty comments
      this.internalTaskComments.value.map((comment: Comment, index: number) => {
        if(comment.content === ''){
          this.internalTaskComments.removeAt(index);
        }
        comment.assignedUsers = comment.assignedUsers.filter((userId: any) => userId !== null);
      });
      this.taskService
        .update(this.taskEditing.id, this.internalTaskForm.value)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'La tâche a été clôturée',
              life: 10000,
            });
            this.onCloseDialog();
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              summary: 'Une erreur est survenue lors de l\'enregistrement : ',
              detail: 'Veuillez essayer à nouveau',
              life: 15000,
            });
          },
        });
    }
  }

}
