<p-fileUpload #fileInput
  [customUpload]="true"
  [auto]="true"
  [multiple]="true"
  [maxFileSize]="2000000"
  accept=".jpeg, .jpg, .png, .heif, .hevc, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .pdf"
  [invalidFileSizeMessageSummary]="''"
  [invalidFileTypeMessageSummary]="''"
  [invalidFileSizeMessageDetail]="'Le fichier ne doit pas dépasser 2Mo'"
  [invalidFileTypeMessageDetail]="'Les types de fichier autorisés sont: jpeg, jpg, png, heif, hevc, doc, docx, xls, xlsx, ppt, pptx, pdf'"
  (uploadHandler)="documentType.multiple ?  openOtherDocDialog($event.files) : upload($event.files)"
  (onSelect)="onSelect($event)"
  [ngClass]="{'selected' : fileInput.files.length > 0}"
>
  <ng-template let-file pTemplate="file">
    <div class="flex file-custom align-items-center">
      <i class="pi pi-file mr-3" style="font-size: 1.5rem"></i>
      <div class="flex flex-column w-full">
        <div class="flex justify-content-between">
          <div *ngIf="!errorMsg;  else errorTpl">
            {{file.name}}
          </div>
          <ng-template #errorTpl>
            {{errorMsg}}
          </ng-template>
          <div class="flex">
            <i *ngIf="!loading" class="icon-delete font-icon mr-3 trash-icon" (click)="remove()"></i>
            {{file.size/1024 | number: '1.0-0'}}Ko
            <i *ngIf="success && !loading" class="pi pi-check ml-3 success-icon"></i>
            <i *ngIf="loading" class="pi pi-spin pi-spinner ml-3 loading-icon"></i>
            <i *ngIf="error && !loading" class="pi pi-times ml-3 error-icon"></i>
          </div>
        </div>
        <div class="w-full mt-3">
          <p-progressBar class="custom" *ngIf="success && !loading" [ngClass]="'success'"[value]="100"></p-progressBar>
          <p-progressBar class="custom" *ngIf="loading" mode="indeterminate"></p-progressBar>
          <p-progressBar class="custom" *ngIf="error && !loading" [ngClass]="'error'" [value]="100"></p-progressBar>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content" let-files>
    <div class="flex content-custom" *ngIf="files.length === 0" (click)="choose()">
      <i class="pi pi-paperclip" style="font-size: 1.5rem"></i>
      <a class="btn ml-3 mr-3" >Téléchargez</a>
      <span>ou cliquez déposez le fichier ici</span>
    </div>
  </ng-template>
</p-fileUpload>
<p-dialog *ngIf="documentType.multiple"
  class="border-round"
  [(visible)]="showOtherDocDialog"
  (onHide)="showOtherDocDialog = false"
  [style]="{ width: '40vw' }"
  [modal]="true"
  header="Ajouter un autre type de document">
  <form [formGroup]="form">
    <div class="grid p-fluid mb-3 mt-3">
      <div class="field col-6">
        <label for="name">Titre du document *</label>
        <input formControlName="name" id="name" type="text" pInputText class="inputfield">
      </div>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="col-12 flex justify-content-end">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (onClick)="cancelOtherDocDialog()"></p-button>
      <p-button label="Ajouter" icon="pi pi-check" [disabled]="form.invalid" styleClass="p-button-raised" (onClick)="upload(filesToUpload)"></p-button>
    </div>
  </ng-template>
</p-dialog>
