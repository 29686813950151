import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ClientType,
  LegalPerson,
  LegalPersonQuery,
  NaturalPerson,
  NaturalPersonQuery,
  DetenteurBien,
  RecueilHelperService,
  docSummaryType,
  Document,
  DocumentHelperService,
  TranslatorService,
  DocsToAddStore,
  DocsToDeleteStore, DocumentType, DocumentService, NaturalPersonService
} from 'common';
import {ID} from '@datorama/akita';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription, take} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-recueil-recap',
  templateUrl: './recueil-recap.component.html',
  styleUrls: ['./recueil-recap.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilRecapComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilRecapComponent),
      multi: true
    }
  ]
})
export class RecueilRecapComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() clientOrAdmin: string;
  client: NaturalPerson | LegalPerson;
  patrimoine: number;
  docSummary: docSummaryType;
  initialDocSummary: docSummaryType;
  docToUpload: any[] = [];
  docToAdd: any[] = [];
  docToDelete: any[] = [];
  form: UntypedFormGroup;
  detenteurBien = DetenteurBien;
  subscriptions: Subscription[] = [];
  sentDocsEmpty = false;

  constructor(
    public naturalPersonQuery: NaturalPersonQuery,
    public naturalPersonService: NaturalPersonService,
    public legalPersonQuery: LegalPersonQuery,
    public recueilHelperService: RecueilHelperService,
    private documentHelperService: DocumentHelperService,
    private docsToAddStore: DocsToAddStore,
    private docsToDeleteStore: DocsToDeleteStore,
    private documentService: DocumentService,
    private translatorService: TranslatorService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.form = this.formBuilder.group({});
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        //on remet les documents dans leur objet initial
        const { docs, ...rest } = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  //METHODE UTILISE POUR LA PARTIE CONSEILLER
  onNotSentClick(doc: any, categorie: any){
    //*************************************************************
    // GESTION DES DOCS A ADD/DEL HORS DES FORMS
    // On maintient à jour 2 stores docToAdd et docToDelete en fonction des coches cliquées
    // La sauvegarde générale du recueil et le formguard de naturalPerson consultent ces stores pour savoir quel docItems ajouter ou supprimer
    //*************************************************************

    // on passe le doc dans le tableau sent pour affichage
    if(this.docSummary.sent.some(val => val.categorie.label === categorie.label)){
      this.docSummary.sent.find(val => val.categorie.label === categorie.label)?.docItems?.push(doc);
    } else {
      this.docSummary.sent = [...this.docSummary.sent, {categorie, docItems: [doc]}];
    }
    this.docSummary.notSent.map(val => {
      if(val.categorie.label === categorie.label){
        val.docItems = val.docItems?.filter(x => x !== doc);
      }
    });

    // on ajoute le doc à la liste des docItems à creer si pas encore present
    if(!this.initialDocSummary.sent.some(val => val.docItems?.some(x => x.doc === doc.type && x.owner.id === doc.targetId)) &&
      !this.docToAdd.includes(doc)){
      this.docToAdd = [...this.docToAdd, { doc: {type: doc.doc.type.code}, owner: doc.owner, targetId: categorie.targetId}];
      this.docsToAddStore.update({docs: this.docToAdd});
    }
    //on enleve de la liste des docItems à supprmer si present
    if(this.docToDelete.some(val => val.type === doc.type && val.targetId === doc.targetId)){
      this.docToDelete = this.docToDelete.filter(val => val.type !== doc.type &&  val.targetId !== doc.targetId );
      this.docsToDeleteStore.update({docs: this.docToDelete});
    }

  }

  //METHODE UTILISE POUR LA PARTIE CONSEILLER
  onSentClick(doc: any, categorie: any){
    // on passe le doc dans le tableau notSent pour affichage
    if(this.docSummary.notSent.some(val => val.categorie.label === categorie.label)){
      this.docSummary.notSent.find(val => val.categorie.label === categorie.label)?.docItems?.push(doc);
    } else {
      this.docSummary.notSent = [...this.docSummary.notSent, {categorie, docItems: [doc]}];
    }
    this.docSummary.sent.map(val => {
      if(val.categorie.label === categorie.label){
        val.docItems = val.docItems?.filter(x => x !== doc);
      }
    });

    // on ajoute le doc à la liste des docItems à supprimer si pas encore present
    if(!this.initialDocSummary.notSent.some(val => val.docItems?.some(x => x.doc === doc.type && x.owner.id === doc.targetId)) &&
    !this.docToDelete.includes(doc)){
      this.docToDelete = [...this.docToDelete, {...doc, targetId: categorie.targetId}];
      this.docsToDeleteStore.update({docs: this.docToDelete});
    }
    //on enleve de la liste des docItems à créer si present
    if(this.docToAdd.some(val => val.type === doc.type && val.targetId === doc.targetId)){
      this.docToAdd = this.docToAdd.filter(val => val.type !== doc.type &&  val.targetId !== doc.targetId);
      this.docsToAddStore.update({docs: this.docToAdd});

    }
  }

  onDocumentClick(doc: Document) {
    const files = doc.files;
    if (files) {
      window.open(this.documentHelperService.getFileDownloadLink(this.client, ClientType.NATURAL_PERSON, files[0]), 'Document');
    }
  }

  //METHODE UTILISE POUR LA PARTIE CLIENT
  onDocumentRemove(doc: any, categorie: any){
    const removedDoc = {...doc.doc.type, owner: doc.owner, id: doc.doc.id};

    // on passe le doc dans le tableau notSent pour affichage
    if(!doc.doc.type.multiple){
      if(this.docToUpload.some(val => val.categorie.label === categorie.label)){
        this.docToUpload.find(val => val.categorie.label === categorie.label).docs = [...this.docToUpload.find(val => val.categorie.label === categorie.label)?.docs, removedDoc];
      } else {
        this.form.addControl(categorie.label ? categorie.label : '', this.formBuilder.control(null));
        this.docToUpload = [...this.docToUpload, {categorie, docs: [removedDoc]}];
      }
    }

    //on retire le doc du tableau sent
    this.docSummary.sent.map(val => {
      if(val.categorie.label === categorie.label){
        val.docItems = val.docItems?.filter(x => x !== doc);
      }
    });
    //suppression du doc
    this.documentService.delete(removedDoc.id).pipe(untilDestroyed(this)).subscribe(()=>{
      // on rappel natural person pour eviter de reinjecter null dans les champs de form correspondant,
      // sinon la sauvegarde plante car les id de doc supprimés ne sont plus trouvé en back mais conservé par le form
      this.naturalPersonService.get(this.clientId).pipe(take(1),untilDestroyed(this)).subscribe();
    });

  }

  writeValue(value: any) {
    if (value && Object.keys(value).length) {
      this.value.recap = value;
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }
  translate(word?: string) {
    if(word){
      return this.translatorService.instant(word);
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.docsToAddStore.reset();
    this.docsToDeleteStore.reset();
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.naturalPersonQuery.selectEntity(this.clientId).pipe( untilDestroyed(this)).subscribe(naturalPerson => {
      if(naturalPerson){
        this.client = naturalPerson;
        this.patrimoine = this.recueilHelperService.calculatePatrimoine(this.client);
        this.docSummary = this.recueilHelperService.getNaturalPersonDocument(this.client);
        this.docToUpload = [];
        this.docSummary.notSent.map(categorie => {
          if(categorie.docItems?.length){
            let docs: any[] = [];
            categorie.docItems.map(doc => {
              docs = [...docs, {
                ...Object.entries(DocumentType).filter((key: any) => key[1].code === doc.doc.type.code)[0][1], owner: doc.owner
              }];
            });
            const cat = {categorie: categorie.categorie, docs};
            this.form.addControl(categorie.categorie.label ? categorie.categorie.label : '', this.formBuilder.control(null));
            this.docToUpload = [...this.docToUpload, cat];
          }
        });
        this.initialDocSummary = this.recueilHelperService.getNaturalPersonDocument(this.client);
        this.docsToAddStore.reset();
        this.docsToDeleteStore.reset();
        let i = 0;
        this.docSummary.sent.map((categorie)=> {
          if(categorie.docItems?.length === 0){
            i++;
          }
        });
        this.sentDocsEmpty = i === this.docSummary.sent.length;
      }
    });
  }
}
