export enum ContactType {
  CONTACT_PERSONEL = 'CONTACT_PERSONEL',
  CONTACT_PROFESSIONNEL = 'CONTACT_PROFESSIONNEL',
  AUTRE = 'AUTRE',
}

export enum AddressType {
  ADDRESS_PRINCIPALE = 'ADDRESS_PRINCIPALE',
  ADDRESS_SECONDAIRE = 'ADDRESS_SECONDAIRE',
  ADDRESS_PROFESSIONNELLE = 'ADDRESS_PROFESSIONNELLE',
  AUTRE = 'AUTRE',

}
