import {ID} from '@datorama/akita';
import {NaturalPerson} from '../../naturalPerson/state/natural-person.model';
import {LegalPerson} from '../../legalPerson/state/legal-person.model';

export interface NaturalLegalRelation {
  id?: ID;
  naturalPerson: Partial<NaturalPerson>;
  legalPerson: Partial<LegalPerson>;
  naturalPersonRole: string;
}

export function createNaturalLegalRelation(params: Partial<NaturalLegalRelation>) {
  return {

  } as NaturalLegalRelation;
}
