import {Component, OnInit} from '@angular/core';
import {
  BienDiversTypeQuery, BienDiversType, UiScreenQuery, BienDiversTypeService, MessageService, NavigationService
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {forkJoin} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-page-bien-divers-list',
  templateUrl: './page-bien-divers-list.component.html',
  styleUrls: ['./page-bien-divers-list.component.scss']
})
export class PageBienDiversListComponent implements OnInit {
  bienDivers: BienDiversType[] = [];
  loading = false;
  previousEditedValue = '';
  showDeleteConfirmModal = false;
  selectedBien: BienDiversType|null = null;

  constructor(
    public bienDiversTypeQuery: BienDiversTypeQuery,
    public bienDiversTypeService: BienDiversTypeService,
    public uiScreenQuery: UiScreenQuery,
    private messageService: MessageService,
    private navigation: NavigationService,

  ) { }

  ngOnInit(): void {
    this.bienDiversTypeQuery.selectAll().pipe(untilDestroyed(this)).subscribe(biens => {
      this.bienDivers = [];
      biens.map(bien => this.bienDivers = [...this.bienDivers, {...bien}]);
      this.bienDivers.sort((a,b) => a.position - b.position);
    });
  }

  add(){
    this.bienDivers = [...this.bienDivers, {code:'', label:'', position: 1000}];
  }

  handleBienDiversDelete(selectedBien: BienDiversType){
    if (selectedBien.id) {
      this.showDeleteConfirmModal = true;
      this.selectedBien = selectedBien;
    } else {
      this.bienDivers = this.bienDivers.filter(val => val !== selectedBien);
    }
  }

  onSubmit() {
    this.loading = true;
    let position = 1;
    this.bienDivers.map(bien => {
      bien.position = position;
      bien.code = bien.code.toUpperCase();
      position++;
    });

    let updateRequests: any[] = [];
    let createRequests: any[] = [];

    this.bienDivers
      .filter((val: any) => val.code !== '' && val.label !== '')
      .map((bien, index) => {
        if(bien.id){
          updateRequests = [...updateRequests, this.bienDiversTypeService.update(bien.id, bien)];
        } else{
          createRequests = [...createRequests, this.bienDiversTypeService.add(bien)];
        }
      });

    forkJoin([...updateRequests, ...createRequests]).subscribe({
      next: () => {
        this.onSuccess();
        this.loading = false;
      },
      error: () => {
        this.onError();
        this.loading = false;
      },
    });
  }

  delete(bien: BienDiversType | null){
    this.showDeleteConfirmModal = false;
    this.bienDiversTypeService.delete(bien?.id).subscribe(
      {
        next: () => {
          this.onSuccess();
          this.loading = false;
        },
        error: () => {
          this.onError();
          this.loading = false;
        },
      }
    );
  }

  onSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Les modifications ont bien été sauvegardées',
      life: 10000
    });
  }

  onError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la sauvegarde',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }

  onCancel() {
    this.navigation.back();
  }

  onEditComplete(event: any): void {
    //verification code unique
    if(event.field === 'code' && this.bienDivers.some(bien => (bien.code.toUpperCase() === event.data.code.toUpperCase() && bien.id !== event.data.id))){
      this.bienDivers.filter(bien => bien === event.data)[0].code = this.previousEditedValue;
      this.messageService.add({
        severity: 'error',
        summary: 'Ce code existe déja',
        detail: 'Les codes doivent être uniques',
        life: 5000
      });
    }


  }

  onEditInit(event: any): void {
    this.previousEditedValue = event.data[event.field];
  }

}
