import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Immobilier } from './immobilier.model';

export interface ImmobilierState extends EntityState<Immobilier> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'immobilier' })
export class ImmobilierStore extends EntityStore<ImmobilierState> {

  constructor() {
    super();
  }

}
