import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  StepMaster,
  TaskMaster,
  StepMasterQuery,
  StepMasterService,
  TaskImportance,
  PoleInterne,
  TranslatorService,
  GroupMaster,
  NavigationService,
  PoleClient,
  UiScreenQuery
} from 'common';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MessageService} from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-page-steps-master',
  templateUrl: './page-steps-master.component.html',
  styleUrls: ['./page-steps-master.component.scss']
})
export class PageStepsMasterComponent implements OnInit {

  stepMaster: undefined | StepMaster;
  submissionLoading = false;

  form = this.formBuilder.group({
    groupMasters: this.formBuilder.array([
      this.formBuilder.group({
        id:null,
        position: '',
        delayBefore: 0,
        taskMasters: this.formBuilder.array([]),
      })
    ]),
  });

  tasks: TaskMaster[] = [];
  formIsReady = false;
  importanceOptions: { label: string; value: any }[] = [];
  polesOptions: { label: string; value: any }[] = [];

  constructor(
    private route: ActivatedRoute,
    private stepsMasterQuery: StepMasterQuery,
    private stepMasterService: StepMasterService,
    private formBuilder: UntypedFormBuilder,
    private translatorService: TranslatorService,
    private navigation: NavigationService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    public uiScreenQuery: UiScreenQuery
  ) {
  }

  get groupMastersF() { return this.form.get('groupMasters') as UntypedFormArray;}

  getTaskMasterByGroup(group: GroupMaster){
    const taskMasters: any[] = [];

    if(group.taskMasters) {
      group.taskMasters.map((task, index) => {
        taskMasters.push(
          this.formBuilder.group({
            id: task.id,
            name: [task.name, Validators.required],
            pole: task.pole,
            importance: [task.importance, Validators.required],
            plannedWorkload: task.plannedWorkload,
            helpText: task.helpText,
          })
        );
      });
    }
    return taskMasters;
  }

  getTaskMastersFByGroupIndex(index: number) { return this.groupMastersF.controls[index].get('taskMasters') as UntypedFormArray;}

  setTaskImportance() {
    const taskImportance = Object.values(TaskImportance);
    taskImportance.map((importanceLevel, index) => {
      this.translatorService
        .getTranslation(importanceLevel)
        .pipe(untilDestroyed(this))
        .subscribe((importanceLabel: string) => {
          this.importanceOptions = [
            ...this.importanceOptions,
            {
              label: importanceLabel,
              value: index + 1,
            },
          ];
        });
    });
  }

  setPolesOptions() {
    const polesCustomer = Object.values(PoleClient);
    polesCustomer.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          this.polesOptions = [
            ...this.polesOptions,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
    const polesInterne = Object.values(PoleInterne);
    polesInterne.map((pole) => {
      this.translatorService
        .getTranslation(pole)
        .pipe(untilDestroyed(this))
        .subscribe((poleLabel: string) => {
          this.polesOptions = [
            ...this.polesOptions,
            {
              label: poleLabel,
              value: pole,
            },
          ];
        });
    });
  }

  ngOnInit(): void {
    this.setPolesOptions();
    this.setTaskImportance();
    this.route.params.subscribe(params => {
      const stepsMasterId = params['id'];
      this.stepsMasterQuery.selectEntity(stepsMasterId).subscribe(stepMaster => {
        this.stepMaster = stepMaster;
        // this.tasksMasterF.clear();
        this.groupMastersF.clear();
        stepMaster?.groupMasters?.map(group => {
          this.groupMastersF.push(
            this.formBuilder.group({
              id: group.id,
              position: group.position,
              delayBefore: group.delayBefore,
              taskMasters: this.formBuilder.array(
                this.getTaskMasterByGroup(group)
              )
            })
          );
          }
        );
        this.cleanGroups();
      });
    });
  }

  addTask(){
    this.getTaskMastersFByGroupIndex(this.groupMastersF.length-1).push(
      this.formBuilder.group({
        name: ['', Validators.required],
        pole: null,
        importance: ['', Validators.required],
        plannedWorkload: null,
        helpText: '',
      })
    );
    this.addEmptyGroup();
    this.cdr.detectChanges();
  }

  removeTask(groupIndex: number, taskIndex: number){
    this.getTaskMastersFByGroupIndex(groupIndex).controls.splice(taskIndex,1);
    this.cdr.detectChanges();
    this.syncForm();
    this.cleanGroups();
  }

  addEmptyGroup(){
    this.groupMastersF.push(
      this.formBuilder.group({
        position: [this.groupMastersF.length+1],
        delayBefore: 0,
        taskMasters: this.formBuilder.array([]),
      })
    );
    this.cdr.detectChanges();
  }

  dropItemByGroupIndex(event: CdkDragDrop<any>, groupIndex: number) {
    //deplacement dans le tableau controls du form
    if (event.previousContainer === event.container){
      // moveItemInArray(this.getTaskMastersFByGroupIndex(groupIndex).controls, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data.controls,
        this.getTaskMastersFByGroupIndex(groupIndex).controls,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.cdr.detectChanges();
    this.syncForm();

    this.cleanGroups();
  }

  onDragEnded(taskId: number){
    //au deplacement d'une tache, on met l'id de la tache à null pour que celle ci soit remove (par cascade) puis recréé
    //taskId: l'id de la tache deplacée
    this.groupMastersF.controls.map( (group, index) => {
      this.getTaskMastersFByGroupIndex(index).controls.map( (taskControls, taskIndex) => {
        if(taskControls.value.id === taskId){
          // delete taskControls.value.id;
          taskControls.patchValue({id: null});
        }
      });
    });
  }

  syncForm(){
    // synchro des values par rapport aux controls du form
    // sinon, les array value ne sont plus en lignes avec les array controls
    // ex: controls:Array(2) value:Array(1)
    this.groupMastersF.controls.map( (group, index) => {
      //recuperation des values de chaque control
      let values: any[] = [];
      this.getTaskMastersFByGroupIndex(index).controls.map( control => values = [...values, control]);
      //update des values
      this.getTaskMastersFByGroupIndex(index).patchValue(values);
    });
  }

  cleanGroups(){
    // suppression  groupes vide intermediaires si besoin et ajout groupe vide
    let groupsToKeep: AbstractControl[] = [];
    this.groupMastersF.controls.map( (group, index) => {
      if(this.getTaskMastersFByGroupIndex(index).controls.length > 0){
        groupsToKeep = [...groupsToKeep, group];
      }
    });

    this.groupMastersF.clear();
    this.groupMastersF.controls = groupsToKeep;
    this.addEmptyGroup();
    this.updatePositions();
    this.cdr.detectChanges();
  }

  updatePositions(){
    this.groupMastersF.controls.map( (group, index) => {
      group.patchValue({position:index+1});
    });
  }

  cancel(){
    this.navigation.back();
  }

  submit(){
    this.cdr.detectChanges();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    let groupValuesToKeep: any[] = [];
    this.groupMastersF.controls.map( (group, index) => {
      if(this.getTaskMastersFByGroupIndex(index).controls.length > 0){
        groupValuesToKeep = [...groupValuesToKeep, group.value];
      }
    });

    const stepMaster = {
      groupMasters: groupValuesToKeep
    };

    this.stepMasterService.update(this.stepMaster?.id,stepMaster).subscribe({
      next: (success) => {
        this.onUpdateSuccess(true);
      },
      error: (e) => {
        this.onSaveError();
      }
    });
  }

  onUpdateSuccess(navigate = true) {
    this.submissionLoading = false;
    if(navigate){
      this.navigation.back();
    }
    this.messageService.add({
      severity: 'success',
      summary: 'L\'étape a bien été modifiée.',
      life: 10000
    });
  }
  onSaveError() {
    this.submissionLoading = false;
  }

}
