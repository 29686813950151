<div class="grid">
  <p *ngIf="warningMessage !== ''" class="invalid-feedback flex align-items-center"><i class="pi pi-exclamation-triangle mr-2"></i>{{ warningMessage }}</p>
  <ng-container *ngIf="documentsTree.length > 0">
    <!-- arbo des catégories/type de document -->
    <div class="col-4"><p-panelMenu [model]="documentsTree" id="documentsTree"></p-panelMenu></div>
    <div class="col-8">
      <!-- breadcrumb -->
      <p *ngIf="breadcrumb.length > 0" class="flex align-items-center"><span>{{ breadcrumb[0] }}</span><span class="ml-1 mr-1"><i class="pi pi-chevron-right"></i></span><span class="underline">{{ breadcrumb[1] }}</span></p>

      <!-- liste des documents -->
      <p-scrollPanel [style]="{width: '100%', height: '300px'}">
        <div *ngIf="documentsList.length > 0" class="documents-list">
          <ng-container *ngFor="let document of documentsList">
            <p class="document-item flex cursor-pointer" (click)="onDocumentClick(document.gedId)">
              <ng-container *ngIf="loadingDocumentGedId === document.gedId ; else fileIcon">
                <span class="mr-2"><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></span>
              </ng-container>
              <ng-template #fileIcon>
                <span class="mr-2"><i class="pi pi-file" style="font-size: 2rem"></i></span>
              </ng-template>
              <span class="filename">{{ document.filename }}</span>
            </p>
          </ng-container>
        </div>
      </p-scrollPanel>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
  <div class="col-4">
    <p-skeleton height="200px"></p-skeleton>
  </div>
  <div class="col-8">
    <p-skeleton height="200px"></p-skeleton>
  </div>
  </ng-container>
</div>
