import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
// Transforme toutes les dates du payload en UTC
// Les dates sont stockées en UTC en base de donnée (meme si PHPMyAdmin affiche GMT+2, PHP ignore le timezone de la bdd et applique le timezone php)
// php.ini doit donc contenir date.timezone=UTC
// chaque client js réadapte la date en fonction de sa timezone pour affichage
// exemple: on poste 12:00 en france (UTC+2), la valeur enregistré en base est 10:00 (timezone ignorée), la valeur renvoyé par le serveur est 10:00+00, le front en france affiche 12:00
export class AngularDateHttpInterceptor implements HttpInterceptor {
  // @ts-ignore
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newBody = this.shiftDates(req.body);
    const newReq = req.clone({ body: newBody });
    // @ts-ignore
    return next.handle(newReq);
  }

  shiftDates(body: any) {
    if (body === null || body === undefined) {
      return body;
    }
    if (typeof body !== 'object') {
      return body;
    }
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = value.toUTCString();
      } else if (typeof value === 'object') {
        this.shiftDates(value);
      }
    }
  }
}
