import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiScreenStore, UiScreenState } from './ui-screen.store';

@Injectable({ providedIn: 'root' })
export class UiScreenQuery extends Query<UiScreenState> {
  isMobile$ = this.select(state => state.screen === 'mobile');

  constructor(protected override store: UiScreenStore) {
    super(store);
  }

}
