import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService, UiScreenQuery} from 'common';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-password-forgotten',
  templateUrl: './page-password-forgotten.component.html',
  styleUrls: ['./page-password-forgotten.component.scss']
})
export class PagePasswordForgottenComponent implements OnInit {

  form: UntypedFormGroup;
  loading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.loading = true;
    this.userService.sendResetPasswordEmail(this.emailF?.value).subscribe(res => {
      this.router.navigate(['/login']);
      this.messageService.add({
        severity: 'success',
        summary: 'Un email pour réinitialiser votre mot de passe va vous être envoyé, cliquez sur le lien pour modifier votre mot de passe',
        life: 10000
      });
    });
  }

  backToLogin() {
    this.router.navigate(['/login']);
  }

  /* get form from template */
  get emailF() { return this.form?.get('email');}

}
