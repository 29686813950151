import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {SessionQuery} from 'common';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(
        private router: Router,
        private sessionQuery: SessionQuery
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.sessionQuery.isLoggedIn()) {
            // not logged in so redirect to login page with the return url
            console.warn('logout from auth gard');
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        // logged in so return true
        return true;
    }
}
