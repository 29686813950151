<p-card>
  <div class="flex">
    <div class="icon">
      <i class="pi pi-info-circle" style="font-size: 2rem"></i>
    </div>
    <div class="content">
      <span class="title" [innerHTML]="title"></span>
      <span [innerHTML]="content"></span>
    </div>
  </div>
</p-card>
