import {
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import {MessageService} from 'primeng/api';
import {
  LegalPersonService,
  LegalPersonQuery,
  TranslatorService,
  FormGuardInterface,
  LegalPerson,
  AddressService,
  AddressQuery,
  NavigationService,
  UiScreenQuery
} from 'common';
import {LegalPersonFormComponent} from '../../../components/form/legal-person-form/legal-person-form.component';
import {UntypedFormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-page-legal-person-new',
  templateUrl: './page-legal-person-new.component.html',
  styleUrls: ['./page-legal-person-new.component.scss']
})
export class PageLegalPersonNewComponent implements FormGuardInterface {
  legalPersonForm: LegalPersonFormComponent;
  form: UntypedFormGroup;
  markAsTouched = false;
  submissionLoading = false;
  addressTypes: string[];
  canSave = true;
  canLeave = true;

  constructor(
    private legalPersonService: LegalPersonService,
    private legalPersonQuery: LegalPersonQuery,
    private addressService: AddressService,
    private addressQuery: AddressQuery,
    public translatorService: TranslatorService,
    private messageService: MessageService,
    private router: Router,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  get addressF() { return this.legalPersonForm.form.get('address');}
  get relationF() { return this.legalPersonForm.form.get('relation');}

  @ViewChild(LegalPersonFormComponent, { static: false }) set ref(legalPersonForm: LegalPersonFormComponent) {
    if (legalPersonForm) {
      this.legalPersonForm = legalPersonForm;
      this.form = this.legalPersonForm.createFormGroup();
      this.form.valueChanges.subscribe((val) => {
        this.canSave = this.form.valid;
        this.canLeave = this.form.pristine;
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  submitForm(navigate = true) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.markAsTouched = true;
      return;
    }
    this.canLeave = true;
    this.submissionLoading = true;

    /* TODO dans la V1 par défaut il faut setter les address type a correspondance O2S et isContact à true.
        à modifier pour le projet recueil. */
    let legalPerson: LegalPerson = {
      name: this.legalPersonForm.nameF?.value.toUpperCase(),
      siretNumber: this.legalPersonForm.siretNumberF?.value,
      structureJuridique: this.legalPersonForm.structureJuridiqueF?.value,
      customerStatus: this.legalPersonForm.customerStatusF?.value,
      addresses: this.addressF?.value ? [{
        street: this.addressF?.value.street,
        city: this.addressF?.value.city,
        zipCode: this.addressF?.value.zipCode,
        state: this.addressF?.value.state,
        country: this.addressF?.value.country,
        fiscale: this.addressF?.value.fiscale,
        isContact: true,
        type: 'Correspondance O2S'
      }] : [],
      // @ts-ignore
      naturalLegalRelations: [ {
        naturalPerson: {
          id: this.relationF?.value.naturalPerson
        },
        naturalPersonRole: this.relationF?.value.naturalPersonRole
      }],
      compteRendus: []
    };
    if(this.legalPersonForm.creationDateF?.value !== null){
      legalPerson = {
        ...legalPerson,
        creationDate: this.legalPersonForm.creationDateF?.value,
      };
    }

    this.legalPersonService.add<LegalPerson>(legalPerson).subscribe({
        next: (legalPersonSuccess) => {
          this.onLegalPersonCreatedSuccess();
          if(navigate){
            this.router.navigate(['/legal-person/' + legalPersonSuccess.id]);
          }
        },
        error: (e) => {
          this.onSaveError();
        }
      });
  }

  cancel() {
    this.navigation.back();
  }

  onLegalPersonCreatedSuccess() {
    this.submissionLoading = false;
    this.messageService.add({
      severity: 'success',
      summary: 'La personne morale a bien été créée.',
      life: 10000
    });
  }
  onSaveError() {
    this.submissionLoading = false;
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de l\'enregistrement : ',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }
}
