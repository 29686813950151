<div [formGroup]="form" class="mt-5">
  <label for="impot" *ngIf="clientOrAdmin === 'CLIENT'">Quel est le montant annuel de vos impôts payés la dernière année ? Sélectionnez une fourchette :</label>

  <div class="flex w-full" id="impot">
    <ul class="tranche">
      <li *ngFor="let tranche of trancheImpots"
          (click)="onTrancheClick(tranche)"
          [ngClass]="tranche.active ? 'active' : ''">
        {{tranche.amount | translate}}
      </li>
    </ul>
  </div>

  <div class="grid p-fluid mt-5">
    <div class="field col-3">
      <label for="assujettiIfi" *ngIf="clientOrAdmin === 'ADMIN'">Assujetti à l’IFI</label>
      <label for="assujettiIfi" *ngIf="clientOrAdmin === 'CLIENT'">Êtes-vous assujetti à l'IFI ?</label>
      <app-help-tooltip class="ml-2" [text]="'L\'IFI est un impôt dû par les personnes physiques détenant un patrimoine immobilier net supérieur à 1.300.000 € au 1er janvier de chaque année.'"></app-help-tooltip>
      <p-dropdown
        formControlName="assujettiIfi"
        id="assujettiIfi"
        [options]="yesNoOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        placeholder="..."
      ></p-dropdown>
    </div>
  </div>
</div>
