import {Inject, Injectable} from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { LegalPersonStore, LegalPersonState } from './legal-person.store';

@Injectable({ providedIn: 'root' })
export class LegalPersonService extends NgEntityService<LegalPersonState> {

  constructor(
    @Inject('config') public override config: any,
    protected override store: LegalPersonStore
  ) {
    super(store);
  }

}
