import {Component, OnInit} from '@angular/core';
import {
  UiScreenQuery,
  Translation,
  TranslationQuery,
  TranslatorService,
  TranslationService,
  FormGuardInterface} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MessageService} from 'primeng/api';
import {forkJoin} from 'rxjs';

interface TranslatedText{
  id: number;
  code: string;
  clientValue: string;
  adminValue: string;
  page: string;
}

@UntilDestroy()
@Component({
  selector: 'app-page-administrable-text-list',
  templateUrl: './page-administrable-text-list.component.html',
  styleUrls: ['./page-administrable-text-list.component.scss']
})
export class PageAdministrableTextListComponent implements FormGuardInterface,OnInit {
  canLeave = true;
  canSave = true;
  countUpdatedValues = 0;
  translations: Translation[] = [];
  previousEditedValue = '';
  data: TranslatedText[] = [];
  loading = false;
  pagesList: {code: string; pageName: string}[] = [
    {code:'WELCOME_TITLE', pageName:'Bienvenue'},
    {code:'WELCOME_TEXT1', pageName:'Bienvenue'},
    {code:'WELCOME_TEXT2', pageName:'Bienvenue'},
    {code:'PREZ_TEXT_TIERS', pageName:'Présentation'},
    {code:'REQUEST_FIRST_TITLE', pageName:'Questions-filtre-premier-recueil'},
    {code:'REQUEST_FIRST_COMMENT', pageName:'Questions-filtre-premier-recueil'},
    {code:'REQUEST_UPDATE_TITLE', pageName:'Questions-filtre-mise-à-jour'},
    {code:'REQUEST_UPDATE_COMMENT', pageName:'Questions-filtre-mise-à-jour'},
    {code:'STEP_VOUS_ETAT_CIVIL', pageName:'Etape Vous '},
    {code:'STEP_VOUS_SITUATION_CIVILE', pageName:'Etape Vous '},
    {code:'STEP_VOUS_CONTACT', pageName:'Etape Vous '},
    {code:'STEP_VOUS_JOB', pageName:'Etape Vous '},
    {code:'STEP_VOUS_FAMOUS', pageName:'Etape Vous '},
    {code:'STEP_VOUS_US_PERSON', pageName:'Etape Vous '},
    {code:'STEP_VOUS_COMMENT', pageName:'Etape Vous '},
    {code:'STEP_CONJOINT_STEPPER_TITLE', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_WEDDING', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_PARTNER', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_CONTACT', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_JOB', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_FAMOUS', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_US_PERSON', pageName:'Etape Conjoint'},
    {code:'STEP_CONJOINT_COMMENT', pageName:'Etape Conjoint'},
    {code:'STEP_FAMILLE_STEPPER_TITLE', pageName:'Etape Famille'},
    {code:'STEP_FAMILLE_TITLE', pageName:'Etape Famille'},
    {code:'STEP_FAMILLE_COMMENT', pageName:'Etape Famille'},
    {code:'STEP_BIENSIMMO_STEPPER_TITLE', pageName:'Etape Biens immo'},
    {code:'STEP_BIENSIMMO_TITLE', pageName:'Etape Biens immo'},
    {code:'STEP_BIENSIMMO_COMMENT', pageName:'Etape Biens immo'},
    {code:'STEP_AVOIRSFI_STEPPER_TITLE', pageName:'Etape Avoirs financiers'},
    {code:'STEP_AVOIRSFI_TITLE', pageName:'Etape Avoirs financiers'},
    {code:'STEP_AVOIRSFI_COMMENT', pageName:'Etape Avoirs financiers'},
    {code:'STEP_BIENSDIV_STEPPER_TITLE', pageName:'Etape Biens divers'},
    {code:'STEP_BIENSDIV_TITLE', pageName:'Etape Biens divers'},
    {code:'STEP_BIENSDIV_COMMENT', pageName:'Etape Biens divers'},
    {code:'STEP_COMPLEMENT_STEPPER_TITLE', pageName:'Etape Complements'},
    {code:'WELCOME_STEP_COMPLEMENT_TITLETEXT1', pageName:'Etape Complements'},
    {code:'STEP_COMPLEMENT_SUBTITLE1', pageName:'Etape Complements'},
    {code:'STEP_COMPLEMENT_SUBTITLE2', pageName:'Etape Complements'},
    {code:'STEP_COMPLEMENT_SUBTITLE3', pageName:'Etape Complements'},
    {code:'STEP_COMPLEMENT_SUBTITLE4', pageName:'Etape Complements'},
    {code:'RESUME_TITLE_BEFORE_AMOUNT', pageName:'Recap'},
    {code:'RESUME_SUBTITLE', pageName:'Recap'},
    {code:'RESUME_COMMENT', pageName:'Recap'},
    {code:'END_TEXT', pageName:'Final '},
    {code:'STEP_RECAP_DONATION_TESTAMENT', pageName:'Catégorie document'},
  ];

  constructor(public uiScreenQuery: UiScreenQuery,public translationQuery: TranslationQuery,
              public translatorService: TranslatorService, public translationService: TranslationService,private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.translationQuery.selectAll().pipe(untilDestroyed(this)).subscribe(translations => {
      this.data = [];
      this.translations = translations ;
      this.translations.map( (translation: any) =>{
        const clientCode = translation.code.replace('CLIENT_','');
        const pageNameValue = this.pagesList.find((page)=>page.code === clientCode)?.pageName;
        this.data = [...this.data, {id:translation.id,code : translation.code, clientValue:translation.value,
          adminValue: (this.translatorService.instant(clientCode) !== clientCode? this.translatorService.instant(clientCode) : ''), page:pageNameValue||'' }];
      });
    });
  }

  checkEditTableComplete(event: any){
    this.translations.map(translation=>{
      if(translation.id === event.data.id && translation.value !== event.data.clientValue){
        this.countUpdatedValues++;
      } else if (translation.id === event.data.id && translation.value === event.data.clientValue && this.countUpdatedValues !== 0)
      {this.countUpdatedValues--;}
    });
    this.canLeave = this.countUpdatedValues === 0;
    // Verification s'il existe une valeur vide
    if(this.data.some(d => d.id === event.data.id && event.data.clientValue === '')){
      this.data.filter(d => d === event.data)[0].clientValue = this.previousEditedValue;
      this.messageService.add({
        severity: 'error',
        summary: 'Valeur vide',
        detail: 'Vous ne pouvez pas saisir une valeur vide',
        life: 4000
      });
    }
  }

  submitForm(navigate = true): void{
    let updateRequests: any[] = [];
    let translationItemsToUpdate: Translation[] = [];
    this.data.map(translatedText=>{
      this.translations.map(translation=>{
        if (translatedText.id === translation.id && translatedText.clientValue !== translation.value){
          translationItemsToUpdate = [...translationItemsToUpdate, {id:translatedText.id, code:translatedText.code, value:translatedText.clientValue}];
        }
      });
    });
    translationItemsToUpdate.map(translationItemToUpdate=>{
      updateRequests = [...updateRequests, this.translationService.update(translationItemToUpdate.id, translationItemToUpdate)];
    });
    this.loading = translationItemsToUpdate.length !== 0;
    this.canLeave = true;
    forkJoin([...updateRequests]).subscribe(
      {
        next: () => {
          this.onSuccess();
          this.loading = false;
        },
        error: () => {
          this.onError();
          this.loading = false;
        },
      }
    );
  }

  onSuccess() {
    this.messageService.add({
      severity: 'success',
      summary: 'Les modifications ont bien été sauvegardées',
      life: 10000
    });
  }

  onError() {
    this.messageService.add({
      severity: 'error',
      summary: 'Une erreur est survenue lors de la sauvegarde',
      detail: 'Veuillez essayer à nouveau',
      life: 15000
    });
  }

  onEditInit(event: any){
    this.previousEditedValue = event.data.clientValue;
  }

  disableNavigation(event: any){
    if(event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'ArrowUp'){
      event.stopPropagation();
    }
  }

}
