import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DocsToAddStore, DocsToAddState } from './docs-to-add.store';

@Injectable({ providedIn: 'root' })
export class DocsToAddQuery extends Query<DocsToAddState> {

  constructor(protected override store: DocsToAddStore) {
    super(store);
  }

}
