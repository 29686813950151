import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NaturalPerson } from './natural-person.model';

export interface NaturalPersonState extends EntityState<NaturalPerson> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'natural_people',
})
export class NaturalPersonStore extends EntityStore<NaturalPersonState> {

  constructor() {
    super();
  }

}
