import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AddressStore, AddressState } from './address.store';

@Injectable({ providedIn: 'root' })
export class AddressQuery extends QueryEntity<AddressState> {

  constructor(protected override store: AddressStore) {
    super(store);
  }

}
