import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {SessionService} from '../store/session/state/session.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {TranslatorService} from '../services/translator.service';
import {HttpResponseMessageType} from '../enums/HttpResponseMessageType';
import {fr} from '../assets/i18n/fr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionService,
              private messageService: MessageService,
              private translatorService: TranslatorService,
              private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(response => {
      // Cas de l'authentification expirée
      if (response.status === 401 && !this.router.url.startsWith('/login')) {
        // géré par jwt interceptor
      }
      if (response.status === 401 && response.error.message === 'Identifiants invalides.' && this.router.url.startsWith('/login')) {
        this.sessionService.logout();
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur de connexion',
          detail: 'Identifiants invalides.',
          life: 5000
        });
        this.router.navigate(['/login']);
      }
      if (response.status === 404 && this.router.url === '/password-forgotten') {
        this.messageService.add({
          severity: 'success',
          summary: 'Un email pour réinitialiser votre mot de passe va vous être envoyé, cliquez sur le lien pour modifier votre mot de passe',
          life: 5000
        });
        this.router.navigate(['/login']);
      }
      if (response.status === 450) {
        /* Error message translation, json response from back must have summary and detail key translated in front  */
        const errorMessage = response.error;
        this.messageService.add({
          severity: 'error',
          // @ts-ignore
          summary: Object.keys(fr).includes(errorMessage) ? this.translatorService.instant(fr[errorMessage].summary) : 'Une erreur est survenue',
          // @ts-ignore
          detail: Object.keys(fr).includes(errorMessage) ? this.translatorService.instant(fr[errorMessage].detail) : 'Veuillez réessayer ou contacter un administrateur TIM',
          life: 15000
        });
      }
      if (
        (response.error.summary === HttpResponseMessageType.RESET_PASSWORD_TOKEN_EXPIRED) ||
        (response.error.summary === HttpResponseMessageType.WRONG_RESET_PASSWORD_TOKEN) ||
        (response.error.summary === HttpResponseMessageType.USER_NOT_FOUND) ||
        (response.error.summary === HttpResponseMessageType.USER_INACTIVE)
      ) {
        this.router.navigate(['/login']);
      }
      // const error = response ;
      return throwError(response);
    }));
  }
}
