import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DropdownOption,
  FormGuardInterface,
  HttpResponseMessageType,
  PoleClient,
  PoleInterne,
  TranslatorService,
  User,
  UserQuery,
  UserRole,
  UserService,
  NavigationService,
  UiScreenQuery
} from 'common';
import { MessageService } from 'primeng/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-page-user-new',
  templateUrl: './page-user-new.component.html',
  styleUrls: ['./page-user-new.component.scss']
})
export class PageUserNewComponent implements OnInit, FormGuardInterface {

  isCreation = true;
  user: User | undefined;
  form: UntypedFormGroup;
  poles: DropdownOption[] = [];
  roles: DropdownOption[] = [];
  submissionLoading = false;
  isDirigeant = false;
  canUserBeDeleted: boolean;
  showDeleteUserModal = false;
  // Les rôles Dirigeant et Collaborateur ne peuvent pas être supprimés en mode Édition
  readonlyRoles = [UserRole.ROLE_DIRIGEANT, UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_CLIENT];
  canLeave = true;
  canSave = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userQuery: UserQuery,
    public translatorService: TranslatorService,
    private messageService: MessageService,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery
  ) { }

  ngOnInit(): void {
    if (this.router.url === '/user-new') {
      this.form = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        poles: [[], [Validators.required]],
        roles: [[UserRole.ROLE_COLLABORATEUR], [Validators.required]],
        isActive: [true, [Validators.required]]
      });
      this.form.valueChanges.subscribe((val) => {
        this.canSave = this.form.valid;
        this.canLeave = this.form.pristine;
      });
    } else {
      this.isCreation = false;
      this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
        const userId = +params['id'];
        // this.userService.get(userId).subscribe();
        this.userQuery.selectEntity(userId).pipe(untilDestroyed(this)).subscribe(user => {
          this.user = user;
          if (this.user?.roles.includes(UserRole.ROLE_DIRIGEANT)) {
            this.isDirigeant = true;
          }
          // @ts-ignore
          this.userService.userHasLinkedData(user.email).pipe(untilDestroyed(this)).subscribe(res => {
            if (this.isDirigeant) {
              this.canUserBeDeleted = false;
            } else {
              this.canUserBeDeleted = res === HttpResponseMessageType.USER_HAS_NO_DATA_LINKED;
            }
          });
          this.form = this.formBuilder.group({
            firstName: [user?.firstName, Validators.required],
            lastName: [user?.lastName, Validators.required],
            email: [user?.email, [Validators.required, Validators.email]],
            poles: [user?.poles, [Validators.required]],
            roles: [user?.roles, [Validators.required]],
            isActive: [{ value: user?.isActive, disabled: this.isDirigeant }, Validators.required]
          });
          this.form.valueChanges.subscribe((val) => {
            this.canSave = this.form.valid;
            this.canLeave = this.form.pristine;
          });
        });
      });
    }

    // @ts-ignore
    const allPoles = Object.values(PoleInterne).concat(Object.values(PoleClient));
    allPoles.map(pole => {
      this.translatorService.getTranslation(pole).pipe(untilDestroyed(this)).subscribe((poleLabel: string) => {
        this.poles = [...this.poles, {
          label: poleLabel,
          value: pole
        }];
      });
    });

    const allRoles = this.isCreation ?
      [UserRole.ROLE_ADMIN, UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_RESPONSABLE_POLE]
      : [UserRole.ROLE_ADMIN, UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_DIRIGEANT, UserRole.ROLE_RESPONSABLE_POLE];
    allRoles.map(role => {
      this.translatorService.getTranslation(role).pipe(untilDestroyed(this)).subscribe((roleLabel: string) => {
        this.roles = [...this.roles, {
          label: roleLabel,
          value: role,
          disabled: this.readonlyRoles.includes(role)
        }];
      });
    });
  }

  setShowDeleteUserModal(value: boolean) {
    this.showDeleteUserModal = value;
  }

  onRoleRemove(removedRole: string) {
    const filteredRoles = this.rolesF?.value.filter((role: string) => role !== removedRole);
    this.rolesF?.setValue(filteredRoles);
    // mark as touched sinon le form n'est pas considéré touched -> pas de formGuard
    this.form.markAsTouched();
  }

  cancel() {
    this.navigation.back();
  }

  submitForm(navigate = true) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.canLeave = true;
    this.submissionLoading = true;
    const user: User = {
      firstName: this.firstNameF?.value,
      lastName: this.lastNameF?.value.toUpperCase(),
      email: this.emailF?.value,
      poles: this.polesF?.value,
      roles: this.rolesF?.value,
      isActive: this.isActiveF?.value,
    };
    if (this.isCreation) {
      this.createUser(user, navigate);
    } else {
      this.updateUser(user, navigate);
    }
  }

  createUser(user: User, navigate = true) {
    this.userService.add(user).subscribe({
      next: (success) => {
        this.onUserCreatedSuccess(navigate);
      },
      error: (e) => {
        this.onSaveError();
      }
    });
  }

  updateUser(partialUser: Partial<User>, navigate = true) {
    this.userService.update(this.user?.id, partialUser).subscribe({
      next: (success) => {
        this.onUserUpdateSuccess(navigate);
      },
      error: (e) => {
        this.onSaveError();
      }
    });
  }

  deleteUser() {
    this.canLeave = true;

    this.userService.delete(this.user?.id).subscribe({
      next: () => {
        this.setShowDeleteUserModal(false);
        this.router.navigate(['/user-list']);
        this.messageService.add({
          severity: 'success',
          summary: 'L\'utilisateur a bien été supprimé.',
          life: 10000
        });
      },
      error: () => {
        this.setShowDeleteUserModal(false);
        this.messageService.add({
          severity: 'error',
          summary: 'Une erreur est survenue lors de la suppression',
          detail: 'Veuillez essayer à nouveau',
          life: 15000
        });
      }
    });
  }

  onUserCreatedSuccess(navigate = true) {
    this.submissionLoading = false;
    if(navigate) {
      this.router.navigate(['/user-list']);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Le nouvel utilisateur a bien été créé.',
      detail: this.isActiveF?.value? 'Il va recevoir un email pour choisir un mot de passe' : 'L\'utilisateur n\'est pas activé, aucun email ne va lui être envoyé',
      life: 10000
    });
  }

  onUserUpdateSuccess(navigate = true) {
    this.submissionLoading = false;
    if(navigate){
      this.navigation.back();
    }
    this.messageService.add({
      severity: 'success',
      summary: 'L\'utilisateur a bien été modifié.',
      life: 10000
    });
  }
  onSaveError() {
    this.submissionLoading = false;
  }

  /* get form from template */
  get firstNameF() { return this.form?.get('firstName');}
  get lastNameF() { return this.form?.get('lastName');}
  get emailF() { return this.form?.get('email');}
  get polesF() { return this.form?.get('poles');}
  get rolesF() { return this.form?.get('roles');}
  get isActiveF() { return this.form?.get('isActive');}
}
