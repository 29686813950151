export enum HttpResponseMessageType {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_INACTIVE = 'USER_INACTIVE',
  WRONG_RESET_PASSWORD_TOKEN = 'WRONG_RESET_PASSWORD_TOKEN',
  RESET_PASSWORD_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED',
  TOKEN_VALID = 'TOKEN_VALID',
  RESET_PASSWORD_EMAIL_SENT = 'RESET_PASSWORD_EMAIL_SENT',
  USER_HAS_NO_DATA_LINKED = 'USER_HAS_NO_DATA_LINKED',
  USER_HAS_DATA_LINKED = 'USER_HAS_DATA_LINKED',
  NEW_RECUEIL_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED',
  NEW_RECUEIL_CONJOINT_LINK_TOKEN_EXPIRED = 'NEW_RECUEIL_CONJOINT_LINK_TOKEN_EXPIRED',
  USER_ALREADY_ACTIVATED = 'USER_ALREADY_ACTIVATED',
  WRONG_NEW_RECUEIL_TOKEN = 'WRONG_RESET_PASSWORD_TOKEN',
  WRONG_NEW_RECUEIL_CONJOINT_LINK_TOKEN = 'WRONG_NEW_RECUEIL_CONJOINT_LINK_TOKEN',
}
