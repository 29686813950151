<div>
  <h2 class="mt-5">Nouvelle Personne Morale</h2>
  <ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
    <app-desktop-only></app-desktop-only>
  </ng-container>
  <ng-template #desktop>
    <app-legal-person-form [isNewLegalPerson]="true" [markAsTouched]="markAsTouched"></app-legal-person-form>
    <!--  FOOTER  -->
    <div class="flex justify-content-end mt-5">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="cancel()"></p-button>
      <p-button label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="submitForm()" [loading]="submissionLoading"></p-button>
    </div>
  </ng-template>
</div>
