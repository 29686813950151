import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ID} from '@datorama/akita';
import {NaturalPerson} from 'common';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-recueil-information',
  templateUrl: './recueil-information.component.html',
  styleUrls: ['./recueil-information.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecueilInformationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecueilInformationComponent),
      multi: true
    }
  ]
})
export class RecueilInformationComponent implements ControlValueAccessor, OnDestroy {
  @Input() client: NaturalPerson | undefined;

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(private formBuilder: UntypedFormBuilder,) {
    this.form = this.formBuilder.group({
      infoCivile: this.formBuilder.control({}),
      situationMatrimoniale: this.formBuilder.control({}),
      situationPro: this.formBuilder.control({}),
      comConseilleInformation: this.formBuilder.control({}),
      celebre: this.formBuilder.control({}),
      etranger: this.formBuilder.control({}),
      contact: this.formBuilder.control({})
    });
    this.form.markAsPristine();
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    this.value = {
      infoCivile : value.infoCivile,
      situationMatrimoniale: value.situationMatrimoniale,
      situationPro: value.situationPro,
      comConseilleInformation: value.comConseilleInformation,
      etranger: value.etranger,
      celebre: value.celebre,
      contact: value.contact,
    };
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

}
