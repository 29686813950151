<div [formGroup]="form">
  <!-- Enfants -->
  <div #title></div>
  <h2 class="rec">Vos enfants</h2>
  <p-divider></p-divider>
  <p-button label="Ajouter un enfant" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            (click)="add('ENFANT')"></p-button>


  <ng-container formArrayName="enfants">
    <p-accordion styleClass="mb-6 pt-4" *ngFor="let item of enfantControl?.controls; index as i">
      <p-accordionTab [(selected)]="!collapsedPanelsEnfants[i].collapsed">
        <ng-template pTemplate="header">
          <div class="flex justify-content-between w-full align-items-center">
            <h3
              class="rec"> {{item.value.infoCivile.lastName && item.value.infoCivile.firstName ? (item.value.infoCivile.firstName + ' ' + item.value.infoCivile.lastName) : 'Enfant ' + (i + 1)}} </h3>
            <div>
              <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text"
                      (click)="remove(i, 'ENFANT')"></button>
            </div>
          </div>
        </ng-template>
        <app-enfant-personnes-a-charge-form [type]="'ENFANT'" [clientId]="clientId"
                                            [formControlName]="i"></app-enfant-personnes-a-charge-form>
      </p-accordionTab>
    </p-accordion>
  </ng-container>

  <!-- Personnes a charge -->
  <h2 class="rec">Autres personnes à charge</h2>
  <p-divider></p-divider>
  <p-button label="Ajouter une personne à charge" icon="pi pi-plus" styleClass="p-button-raised mr-2"
            (click)="add('ACHARGE')"></p-button>
  <ng-container formArrayName="personnesCharge">
    <p-accordion styleClass="mb-6 pt-4" *ngFor="let item of personnesChargeControl?.controls; index as i">
      <p-accordionTab [(selected)]="!collapsedPanelsCharge[i].collapsed">
        <ng-template pTemplate="header">
          <div class="flex justify-content-between w-full align-items-center">
            <h3
              class="rec">{{item.value.infoCivile.lastName && item.value.infoCivile.firstName ? (item.value.infoCivile.firstName + ' ' + item.value.infoCivile.lastName) : 'Personne à charge ' + (i + 1)}}</h3>
            <div>
              <button pButton type="button" icon="icon-font icon-delete" class="p-button-raised p-button-text"
                      (click)="remove(i, 'ACHARGE')"></button>
            </div>
          </div>
        </ng-template>
        <app-enfant-personnes-a-charge-form [type]="'ACHARGE'" [clientId]="clientId"
                                            [formControlName]="i"></app-enfant-personnes-a-charge-form>
      </p-accordionTab>
    </p-accordion>
  </ng-container>

  <!-- Documents -->
  <ng-container *ngIf="form.get('enfants')?.value.length !== 0; else enfantsEmpty">
    <app-documents-form formControlName="docs"
                        [clientType]="'NATURAL_PERSON'"
                        [clientId]="clientId"
                        [documentTypes]="[this.documentType.CIVIL_LIVRET_FAMILLE,
                      this.documentType.AUTRE_CIVIL]"></app-documents-form>
  </ng-container>
  <ng-template #enfantsEmpty>
    <app-documents-form formControlName="docs"
                        [clientId]="clientId"
                        [clientType]="'NATURAL_PERSON'"
                        [documentTypes]="[this.documentType.AUTRE_CIVIL]"></app-documents-form>
  </ng-template>

  <!-- Commentaire -->
  <h2 class="rec">{{'CLIENT_STEP_CONJOINT_COMMENT' | translate}}</h2>
  <p-divider></p-divider>
  <app-comment-form formControlName="comPersonneACharge"></app-comment-form>
</div>
