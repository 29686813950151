import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {ClientType} from 'common';
import {HttpClient} from '@angular/common/http';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MenuItem, MessageService} from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-ged-read',
  templateUrl: './ged-read.component.html',
  styleUrls: ['./ged-read.component.scss']
})
export class GedReadComponent implements OnInit {

  @Input() clientType: string;
  @Input() clientFullname: string;
  loading = true;
  loadingDocumentGedId?: number;
  documentsTree: MenuItem[] = [];
  breadcrumb: string[] = [];
  documentsList: any[] = [];
  warningMessage = '';

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    private cdref: ChangeDetectorRef,
    @Inject('config') private config: any,
  ) { }

  ngOnInit(): void {
    const url = this.config.apiUrl + '/ged/search/' + this.clientFullname;
    this.loading = true;
    this.httpClient.get<any>(url).pipe(untilDestroyed(this)).subscribe({
        next: (results) => {
          if (results.success) {
            // Les documents sont classés par catégorie puis type de document
            results.documents.map((documentCat: any, catIndex: number) => {
              let documentsCat: any[] = [];
              documentCat.catDocTypes.map((documentType: any, typeIndex: number) => {
                documentsCat = [
                  ...documentsCat,
                  {
                    label: documentType.typeLabel,
                    icon: 'pi pi-folder',
                    command: () => {
                      // Quand on click un type de document on le rend selectionné, on affiche le breadcrumb et la liste des docs associé
                      this.breadcrumb = [documentCat.catLabel, documentType.typeLabel];
                      this.documentsList = documentType.documents;
                      this.openDocumentTreeItem(catIndex, typeIndex);
                    }
                  }
                ];
              });
              this.documentsTree = [
                ...this.documentsTree,
                {
                  label: documentCat.catLabel,
                  items: documentsCat,
                }
              ];
            });
          } else {
            this.warningMessage = results.message;
          }
          this.loading = false;
          this.cdref.markForCheck();
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Une erreur est survenue lors de la récupération des documents de ' + this.clientFullname +'. Veuillez essayer à nouveau ou contactez un administrateur.',
            life: 15000,
          });
          this.loading = false;
          this.cdref.markForCheck();
        }
      });
  }

  // Rend selectionné un item de l'arbo => correspond à un type de document
  // On rend tous les items de type de doc "fermés" et on rend ouvert le type de doc courant
  openDocumentTreeItem(catIndex: number, typeIndex: number) {
    this.documentsTree.map((documentCatItem: any, documentCatItemIndex) => {
      documentCatItem.items.map((documentTypeItem: any, typeItemIndex: number) => {
        // @ts-ignore
        this.documentsTree[documentCatItemIndex].items[typeItemIndex].icon = 'pi pi-folder';
      });
    });
    // @ts-ignore
    this.documentsTree[catIndex].items[typeIndex].icon = 'pi pi-folder-open';
  }

  // Click sur un doc, on va chercher le lien temporaire puis on ouvre un onglet vers le lien du doc GED
  onDocumentClick(selectedDocumentGedId: number) {
    if (this.loadingDocumentGedId === undefined) { // On ne peut pas DL plusieurs documents à la fois
      this.loadingDocumentGedId = selectedDocumentGedId;
      // Récupération du lien du document
      this.httpClient.get<any>(this.config.apiUrl + '/ged/download/' + selectedDocumentGedId).pipe(untilDestroyed(this)).subscribe({
        next: (results) => {
          if (results.success) {
            window.open(results.doclink, '_blank');
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Une erreur est survenue lors de la récupération du document. Veuillez essayer à nouveau ou contactez un administrateur. ' +
                'Détail de l\'erreur: ' + results.message,
              life: 15000,
            });
          }
          this.loadingDocumentGedId = undefined;
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erreur',
            detail: 'Une erreur est survenue lors de la récupération du document. Veuillez essayer à nouveau ou contactez un administrateur.',
            life: 15000,
          });
          this.loadingDocumentGedId = undefined;
        }
      });
    }
  }
}
