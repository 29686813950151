<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <ng-container *ngIf="isPageLoading">
    <div>
      <h1>
        <p-skeleton class="w-4" height="43px" ></p-skeleton>
      </h1>
      <p-skeleton class="w-4" height="188px" ></p-skeleton>
    </div>
  </ng-container>
  <div class="w-8 mx-auto my-5" [hidden]="isPageLoading" >
    <h2>{{ compteRendu?.subject }}</h2>
    <div class="my-3 grid">
      <!-- COLLABORATEURS PRÉSENTS -->
      <div class="col-4">
        <p class="font-semibold">Collaborateurs présents : </p>
        <p-tag *ngFor="let collaborator of compteRendu?.collaboratorPresent"
               styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium bg-blue"
               value="{{ collaborator.lastName }} {{ collaborator.firstName }}"
               [rounded]="true">
        </p-tag>
      </div>
      <!-- CLIENTS CONCERNÉS -->
      <div class="col-4">
        <p class="font-semibold">Contact(s) concerné(s) : </p>
        <span class="cursor-pointer" *ngFor="let naturalPerson of compteRendu?.naturalPersonsConcerned" [routerLink]="['/natural-person', naturalPerson.id]">
          <p-tag severity="info"
                 styleClass="tag-nowrap mr-2 mb-1 px-2 font-medium bg-info"
                 value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                 [rounded]="true">
          </p-tag>
        </span>
        <span class="cursor-pointer" *ngFor="let legalPerson of compteRendu?.legalPersonsConcerned" [routerLink]="['/legal-person', legalPerson.id]">
          <p-tag severity="info"
                 styleClass="tag-nowrap mr-2 mb-1 px-2 font-medium bg-warning"
                 value="{{ legalPerson.name }}"
                 [rounded]="true">
          </p-tag>
        </span>
      </div>
      <div class="col-4">
        <p class="font-semibold">Rendez-vous du {{ compteRendu?.meetingDate | date: 'd MMMM y' }}</p>
        <p class="font-semibold">Durée : {{ convertMinutesToDuration(compteRendu?.meetingDuration) }}</p>
      </div>
    </div>
    <!-- CONTENU DU COMPTE RENDU -->
    <div class="mt-5" [innerHTML]="compteRendu?.content"></div>
    <a pButton pRipple
       class="p-button-primary p-button-raised p-button-text mt-7"
       label="Retour"
       icon="pi pi-arrow-left"
       type="button"
       (click)="navigation.back()"
    ></a>
  </div>
</ng-template>
