import {ID} from '@datorama/akita';

export interface NaturalNaturalRelationType {
  id: ID;
  label: string;
  inverseBy: NaturalNaturalRelationType;
  position: number;
}

export function createNaturalNaturalRelationType(params: Partial<NaturalNaturalRelationType>) {
  return {

  } as NaturalNaturalRelationType;
}
