import { Injectable } from '@angular/core';
import {ID, Store, StoreConfig} from '@datorama/akita';

// Every dashboard filters type.
export enum DashboardFiltersType {
  IMPORTANCE = 'IMPORTANCE',
  STEP = 'STEP',
  STEPTASK = 'STEPTASK',
  CUSTOMER = 'CUSTOMER',
  COLLABORATOR = 'COLLABORATOR',
  COLLABORATORANDPOLE = 'COLLABORATORANDPOLE',
  POLE = 'POLE',
}

// View for dashboard.
export enum DashboardView {
  CUSTOMER = 'CUSTOMER',
  INTERNAL = 'INTERNAL',
}

// View for monday review dashboard.
export enum MondayDashboard {
  DONE = 'DONE',
  PROGRESS = 'PROGRESS'
}

export enum MondayDashboardTaskType {
  CUSTOMER = 'CUSTOMER',
  INTERNAL = 'INTERNAL',
  ALL = 'ALL'
}

// Customer detail page dashboard type.
export enum DashboardType {
  CURRENT = 'CURENT',
  HISTORICAL = 'HISTORICAL',
}

export enum TimelineFilter {
  WEEK = 'week',
  TYPE = 'type',
  STEP = 'step',
  TASK = 'task',
  CLIENT = 'client',
  POLE_AND_COLLABORATOR = 'poleAndCollaborator',
}

export interface UiState {
  dashboard: {
    view: undefined | DashboardView;
  };
  mondayDashboard: {
    view?: undefined | MondayDashboard;
    progressDate?: undefined | string;
    doneDate?: undefined | string;
    progressTaskType?: MondayDashboardTaskType;
    doneTaskType?: MondayDashboardTaskType;
  };
  timeline: {
    filters: {
      week?: undefined | null | any;
      type?: undefined | null | string;
      step?: undefined | null | string;
      task?: undefined | null | string;
      client?: undefined | null | string;
      poleAndCollaborator?: undefined | null | { id: ID | undefined; type: string };
    };
  };
}

export function createInitialState(): UiState {
  return {
    dashboard: {
      view: undefined,
    },
    timeline: {
      filters: {
        [TimelineFilter.WEEK]: undefined,
        [TimelineFilter.TYPE]: undefined,
        [TimelineFilter.STEP]: undefined,
        [TimelineFilter.TASK]: undefined,
        [TimelineFilter.CLIENT]: undefined,
        [TimelineFilter.POLE_AND_COLLABORATOR]: undefined,
      }
    },
    mondayDashboard: {
      view: undefined,
      progressDate: undefined,
      doneDate: undefined,
      progressTaskType: MondayDashboardTaskType.ALL,
      doneTaskType: MondayDashboardTaskType.ALL
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'ui' })
export class UiStore extends Store<UiState> {
  constructor() {
    super(createInitialState());
  }
}
