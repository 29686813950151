export enum LegalStatus {
    EI = 'EI',
    EIRL = 'EIRL',
    EURL = 'EURL',
    SARL = 'SARL',
    SAS = 'SAS',
    SASU = 'SASU',
    SA = 'SA',
    ASSOCIATION = 'ASSOCIATION',
    AUTO_ENTREPRISE = 'AUTO_ENTREPRISE',
    SC_SCI = 'SC/SCI',
}
