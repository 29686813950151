export enum AChargeFiscale {
  CHARGE_TOTALE = 'CHARGE_TOTALE',
  CHARGE_PARTAGEE = 'CHARGE_PARTAGEE',
  CHARGE_NO = 'CHARGE_NO',
}

export enum Adoption {
  ADOPTION_SIMPLE = 'ADOPTION_SIMPLE',
  ADOPTION_PLEINIERE = 'ADOPTION_PLEINIERE',
}

export enum EnfantDe {
  ENFANT_DE_MOI = 'ENFANT_DE_MOI',
  ENFANT_DE_NOUS = 'ENFANT_DE_NOUS',
  ENFANT_DE_CONJOINT = 'ENFANT_DE_CONJOINT',
}

export enum Handicap {
  YES = 'YES',
  NO = 'NO',
}

export enum LienPersonne {
  PARENT = 'PARENT',
  FRATRIE = 'FRATRIE',
  ONCLE_TANTE = 'ONCLE_TANTE',
  GRAND_PARENT = 'GRAND_PARENT',
  TIERS = 'TIERS',
  AUTRE_FAMILLE = 'AUTRE_FAMILLE',
}
