<div [formGroup]="form">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="situationPro">Situation professionnelle</label>
      <p-dropdown
        formControlName="situationPro"
        id="situationPro"
        [options]="situationProOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
    </div>
    <div class="field col-3">
      <label for="currentJob">Profession actuelle</label>
      <app-help-tooltip class="ml-3" [text]="'ou dernière exercée si retraité'">
      </app-help-tooltip>
      <input formControlName="currentJob" id="currentJob" type="text" pInputText class="inputfield">
    </div>
    <div class="field col-3">
      <label for="jobCompany">Société, employeur</label>
      <input formControlName="jobCompany" id="jobCompany" type="text" pInputText class="inputfield">
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="revenusAnnuel">Revenus professionnels annuels</label>
      <p-inputNumber id="revenusAnnuel" formControlName="revenusAnnuel"></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
    <div class="field col-3">
      <label for="rentesAnnuelle">Rentes annuelles</label>
      <p-inputNumber id="rentesAnnuelle" formControlName="rentesAnnuelle"></p-inputNumber>
      <div class="text-xs flex mt-2"><i class="pi pi-info-circle mr-2"></i>Nombre entier uniquement, sans virgule ni point</div>
    </div>
  </div>
</div>

