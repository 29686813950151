<h2 class="mt-4">Liste des nouvelles personnes physiques </h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async), else desktop;">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <div *ngIf="!loading ; else load">
    <p-table [paginator]="true"
             [rows]="10"
             [value]="naturalPersons"
             dataKey="id"
             currentPageReportTemplate="Personnes physiques 1 à 10 sur {totalRecords} trouvées"
             [showCurrentPageReport]="true"
    >

      <ng-template pTemplate="header">
        <tr>
          <th class="px-5" pSortableColumn="lastName">Nom
            <p-sortIcon field="lastName"></p-sortIcon>
          </th>
          <th class="px-5 " pSortableColumn="firstName">Prénom
            <p-sortIcon field="firstName"></p-sortIcon>
          </th>
          <th class="px-5" pSortableColumn="civility">Civilité
            <p-sortIcon field="civility"></p-sortIcon>
          </th>
          <th class="px-5" pSortableColumn="createdAt">Date de création
            <p-sortIcon field="createdAt"></p-sortIcon>
          </th>
          <th class="px-5">Contact créé par</th>
          <th class="px-5"></th>

        </tr>
      </ng-template>
      <ng-template let-index="rowIndex" let-naturalPerson pTemplate="body">
        <tr [pReorderableRow]="index">
          <td class="px-5">
            {{naturalPerson.lastName}}
          </td>
          <td class="px-5">
            {{naturalPerson.firstName}}
          <td class="px-5">
            {{naturalPerson.civility}}
          </td>
          <td class="px-5">
            {{naturalPerson.createdAt | date: 'dd/MM/yyyy'}}
          </td>
          <td id="naturalRelations">
            <span *ngFor="let relation of naturalPerson?.naturalNaturalRelations">
                 <p-tag
                   *ngIf="relation?.secondNaturalPerson?.customerStatus?.includes(clientStatus.PROSPECT) ||
                   relation?.secondNaturalPerson?.customerStatus?.includes(clientStatus.CLIENT)"
                   styleClass="p-0 pr-3 m-2 bg-white text-900 font-light border-solid border-1 border-secondary"
                   value="{{relation?.enfantDe === null ? translate(relation?.type?.label) : ''}}
                          {{relation?.enfantDe ? ' Enfant de ' + translate(relation?.enfantDe) : ''}}
                          {{relation?.adopted ? ' | Adoption ' + translate(relation?.adopted) : ''}}
                          {{relation?.aChargeFiscale ? ' | A charge ' + translate(relation?.aChargeFiscale) : ''}}"
                   [rounded]="true" (click)="onRelationTagClick(relation?.secondNaturalPerson?.id)"
                 >
              <p-tag styleClass="mr-2"
                     value="{{ relation?.secondNaturalPerson?.lastName }} {{ relation?.secondNaturalPerson?.firstName }}"
                     [rounded]="true"></p-tag>
            </p-tag>
            </span>
          </td>

          <td class="px-5">
            <p-button icon="pi pi-check" label="Traité" typeahead id="traitingButton"
                      styleClass="p-button-raised p-button-text p-button-success mr-2"
                      (click)="updateNaturalPersonDoublon(naturalPerson)"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="flex justify-content-end mt-5">

  </div>

  <!-- loader -->
  <ng-template #load>
    <div class="grid">
      <p-skeleton class="col" height="59px"></p-skeleton>
    </div>
    <div class="grid">
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
    </div>
    <div class="grid">
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
    </div>
    <div class="grid">
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
    </div>
    <div class="grid">
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
      <p-skeleton class="col-2" height="43px"></p-skeleton>
    </div>
  </ng-template>
</ng-template>
