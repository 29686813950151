import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder, UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {DropdownOption, DropdownOptionsService, Immobilier, DocumentType, DocumentCodeType} from 'common';
import {ID} from '@datorama/akita';

@Component({
  selector: 'app-immobilier-form',
  templateUrl: './immobilier-form.component.html',
  styleUrls: ['./immobilier-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImmobilierFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImmobilierFormComponent),
      multi: true
    }
  ]
})
export class ImmobilierFormComponent implements ControlValueAccessor, OnDestroy, OnInit {

  @Input() clientType: string;
  @Input() clientId: ID | undefined;
  @Input() documentTypes: DocumentCodeType[];
  @Input() clientOrAdmin: string;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  natureBienOptions: DropdownOption<any>[] = [];
  modeDetentionOptions: DropdownOption<any>[] = [];
  detenteurOptions: DropdownOption<any>[] = [];
  typeCreditOptions: DropdownOption<any>[] = [];
  souscripteurOptions: DropdownOption<any>[] = [];
  immobilierTypeOptions: DropdownOption<any>[] = [];

  initialValues = {
    id: null,
    typeBien: null,
    natureBien: null,
    valeur: null,
    partsDetenues: null,
    detenteur: null,
    modeDetention: null,
    address: null,
    credit: false,
    typeCredit: null,
    souscripteur: null,
    genereRevenus: false,
    revenusAnnuels: null,
    detenuSciOrScOrSarl: false,
    companyName: null,
    docs: null
  };

  constructor(private formBuilder: UntypedFormBuilder,private dropdownOptionsService: DropdownOptionsService,) {

  }

  get value(): Immobilier {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        // docs conditionnés en fonction du form
        if (this.documentTypes && !value.detenuSciOrScOrSarl && this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_STATUT_SCI.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.IMMO_STATUT_SCI.code);
        }
        if (this.documentTypes && !value.credit && this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_AMORTISSEMENT.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.IMMO_AMORTISSEMENT.code);
        }
        if (this.documentTypes && !value.credit && this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_OFFRE_PRET.code)){
          this.documentTypes = this.documentTypes.filter(docType => docType.code !== DocumentType.IMMO_OFFRE_PRET.code);
        }
        if(this.documentTypes && value.detenuSciOrScOrSarl && !this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_STATUT_SCI.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.IMMO_STATUT_SCI];
        }
        if(this.documentTypes && value.credit && !this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_AMORTISSEMENT.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.IMMO_AMORTISSEMENT];
        }
        if(this.documentTypes && value.credit && !this.documentTypes?.find(docType => docType.code === DocumentType.IMMO_OFFRE_PRET.code)){
          this.documentTypes = [...this.documentTypes, DocumentType.IMMO_OFFRE_PRET];
        }
        //on remet les documents dans leur objet initial
        const { docs, ...rest } = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
    this.immobilierTypeOptions = this.dropdownOptionsService.immobilierType;
    this.detenteurOptions = this.dropdownOptionsService.detenteurBien;
    this.modeDetentionOptions = this.dropdownOptionsService.modeDetention;
    this.souscripteurOptions = this.dropdownOptionsService.souscripteurCredit;
    this.typeCreditOptions = this.dropdownOptionsService.creditType;
    this.natureBienOptions = this.dropdownOptionsService.immobilierNature;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      //on place les documents dans un objet docs pour qu'ils soient gérés par app-documents-form
      const { amortissement,
        attestNotarial,
        taxeFonciere,
        titrePropriete,
        statutSci,
        offrePret,
        otherDocs,
        ...rest } = value;
      this.value = {...rest, docs: {
          amortissement: value.amortissement,
          attestNotarial: value.attestNotarial,
          taxeFonciere: value.taxeFonciere,
          titrePropriete: value.titrePropriete,
          statutSci: value.statutSci,
          offrePret: value.offrePret,
          otherDocs: value.otherDocs,
        }};
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

}
