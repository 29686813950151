  <h2 class="mt-5">Compte rendu de rendez-vous</h2>
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop">
  <app-desktop-only></app-desktop-only>
</ng-container>
<ng-template #desktop>
  <p-divider></p-divider>
  <app-create-meeting-report-form [meetingReport]="meetingReport"></app-create-meeting-report-form>
  <!--  FOOTER  -->
  <div class="flex justify-content-end mt-5">
    <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text" (click)="formCancel()"></p-button>
    <p-button type="submit" label="Enregistrer" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised" (click)="formSubmit()"></p-button>
  </div>
</ng-template>
