import { Injectable } from '@angular/core';
import {ID, Store, StoreConfig} from '@datorama/akita';


export interface SessionState {
  token: string|null;
  refresh_token: string|null;
  id?: ID;
  firstname: string|null;
  lastname: string|null;
  roles: string[];
  connexionTime: string|null;
}

export function createInitialState(): SessionState {
  return {
    token: null,
    refresh_token: null,
    // id: null,
    firstname: null,
    lastname: null,
    roles: [],
    connexionTime: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'session' ,
  cache: {
    ttl: 2880000
  }
})
export class SessionStore extends Store<SessionState> {

  constructor() {
    super(createInitialState());
  }

  login(session: SessionState) {
    session.connexionTime = Date();
    this.update(session);
  }

  logout() {
    this.update(createInitialState());
    localStorage.clear();
  }
}
