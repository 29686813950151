import { ID } from '@datorama/akita';
import { GroupMaster } from '../../groupMaster/state/group-master.model';

export interface StepMaster {
  id: ID;
  name: string;
  hasProduct: boolean;
  hasAmount: boolean;
  groupMasters?: GroupMaster[];
}

export function createStepMaster(params: Partial<StepMaster>) {
  return {

  } as StepMaster;
}
