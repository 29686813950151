export enum CreditType {
  CREDIT_TYPE_AMORTISSABLE = 'CREDIT_TYPE_AMORTISSABLE',
  CREDIT_TYPE_IN_FINE = 'CREDIT_TYPE_IN_FINE',
}

export enum SouscripteurCredit {
  CREDIT_SOUSCRIPTEUR_MOI = 'CREDIT_SOUSCRIPTEUR_MOI',
  CREDIT_SOUSCRIPTEUR_CONJOINT = 'CREDIT_SOUSCRIPTEUR_CONJOINT',
  CREDIT_SOUSCRIPTEUR_NOUS = 'CREDIT_SOUSCRIPTEUR_NOUS',
  AUTRE = 'AUTRE',
}

export enum SouscripteurAvoriFinancier {
  AVOIR_FI_SOUSCRIPTEUR_MOI = 'CREDIT_SOUSCRIPTEUR_MOI',
  AVOIR_FI_SOUSCRIPTEUR_CONJOINT = 'CREDIT_SOUSCRIPTEUR_CONJOINT',
  AVOIR_FI_SOUSCRIPTEUR_NOUS = 'CREDIT_SOUSCRIPTEUR_NOUS',
  AVOIR_FI_SOUSCRIPTEUR_ENFANT = 'CREDIT_SOUSCRIPTEUR_ENFANT',
  AUTRE = 'AUTRE',
}

export enum AvoirFinancierType {
  AVOIR_FI_COMPTE_CHEQUE = 'AVOIR_FI_COMPTE_CHEQUE',
  AVOIR_FI_COMPTE_LIVRET = 'AVOIR_FI_COMPTE_LIVRET',
  AVOIR_FI_LIVRET_A = 'AVOIR_FI_LIVRET_A',
  AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE = 'AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE',
  AVOIR_FI_LIVRET_JEUNE = 'AVOIR_FI_LIVRET_JEUNE',
  AVOIR_FI_LIVRET_EPARGNE_POPULAIRE = 'AVOIR_FI_LIVRET_EPARGNE_POPULAIRE',
  AVOIR_FI_COMPTE_EPARGNE_LOGEMENT = 'AVOIR_FI_COMPTE_EPARGNE_LOGEMENT',
  AVOIR_FI_COMPTE_A_TERME = 'AVOIR_FI_COMPTE_A_TERME',
  AVOIR_FI_AUTRE_DISPONIBILITE = 'AVOIR_FI_AUTRE_DISPONIBILITE',
  AVOIR_FI_PEL = 'AVOIR_FI_PEL',
  AVOIR_FI_COMPTE_TITRE = 'AVOIR_FI_COMPTE_TITRE',
  AVOIR_FI_PEA = 'AVOIR_FI_PEA',
  AVOIR_FI_CROWDFUNDING = 'AVOIR_FI_CROWDFUNDING',
  AVOIR_FI_PEA_PME = 'AVOIR_FI_PEA_PME',
  AVOIR_FI_FIP = 'AVOIR_FI_FIP',
  AVOIR_FI_FCPR = 'AVOIR_FI_FCPR',
  AVOIR_FI_FCPI = 'AVOIR_FI_FCPI',
  AVOIR_FI_PART_HOLDING = 'AVOIR_FI_PART_HOLDING',
  AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON = 'AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON',
  AVOIR_FI_ASSURANCE_VIE = 'AVOIR_FI_ASSURANCE_VIE',
  AVOIR_FI_PEP = 'AVOIR_FI_PEP',
  AVOIR_FI_CONTRAT_VIE_GENERATION = 'AVOIR_FI_CONTRAT_VIE_GENERATION',
  AVOIR_FI_CONTRAT_CAPITALISATION = 'AVOIR_FI_CONTRAT_CAPITALISATION',
  AVOIR_FI_PER = 'AVOIR_FI_PER',
  AVOIR_FI_PERCO = 'AVOIR_FI_PERCO',
  AVOIR_FI_CONTRAT_RETRAITE_MADELIN = 'AVOIR_FI_CONTRAT_RETRAITE_MADELIN',
  AVOIR_FI_PEE = 'AVOIR_FI_PEE',
  AVOIR_FI_PEI = 'AVOIR_FI_PEI',
  AVOIR_FI_PREFON = 'AVOIR_FI_PREFON',
  AVOIR_FI_PERP = 'AVOIR_FI_PERP',
  AVOIR_FI_ART_83 = 'AVOIR_FI_ART_83',
  AVOIR_FI_AUTRE = 'AVOIR_FI_AUTRE',
}

export enum AvoirFinancierGroup {
  DISPONIBILITE = 'DISPONIBILITE',
  VALEUR_IMMO = 'VALEUR_IMMO',
  ASSURANCE_VIE = 'ASSURANCE_VIE',
  EPARGNE_RETRAITE = 'EPARGNE_RETRAITE',
  AUTRE = 'AUTRE',
}

const GroupedAvoirFinancierType = {
  DISPONIBILITE:[
    AvoirFinancierType.AVOIR_FI_COMPTE_CHEQUE,
    AvoirFinancierType.AVOIR_FI_COMPTE_LIVRET,
    AvoirFinancierType.AVOIR_FI_LIVRET_A,
    AvoirFinancierType.AVOIR_FI_LIVRET_DEV_DURABLE_SOLIDAIRE,
    AvoirFinancierType.AVOIR_FI_LIVRET_JEUNE,
    AvoirFinancierType.AVOIR_FI_LIVRET_EPARGNE_POPULAIRE,
    AvoirFinancierType.AVOIR_FI_COMPTE_EPARGNE_LOGEMENT,
    AvoirFinancierType.AVOIR_FI_COMPTE_A_TERME,
    AvoirFinancierType.AVOIR_FI_PEL,
    AvoirFinancierType.AVOIR_FI_AUTRE_DISPONIBILITE,
  ],
  VALEUR_IMMO:[
    AvoirFinancierType.AVOIR_FI_COMPTE_TITRE,
    AvoirFinancierType.AVOIR_FI_PEA,
    AvoirFinancierType.AVOIR_FI_CROWDFUNDING,
    AvoirFinancierType.AVOIR_FI_PEA_PME,
    AvoirFinancierType.AVOIR_FI_FIP,
    AvoirFinancierType.AVOIR_FI_FCPR,
    AvoirFinancierType.AVOIR_FI_FCPI,
    AvoirFinancierType.AVOIR_FI_PART_HOLDING,
    AvoirFinancierType.AVOIR_FI_TITRE_EN_DIRECT_COTE_OU_NON,
  ],
  ASSURANCE_VIE:[
    AvoirFinancierType.AVOIR_FI_ASSURANCE_VIE,
    AvoirFinancierType.AVOIR_FI_PEP,
    AvoirFinancierType.AVOIR_FI_CONTRAT_VIE_GENERATION,
    AvoirFinancierType.AVOIR_FI_CONTRAT_CAPITALISATION,
  ],
  EPARGNE_RETRAITE:[
    AvoirFinancierType.AVOIR_FI_PER,
    AvoirFinancierType.AVOIR_FI_PERCO,
    AvoirFinancierType.AVOIR_FI_CONTRAT_RETRAITE_MADELIN,
    AvoirFinancierType.AVOIR_FI_PEE,
    AvoirFinancierType.AVOIR_FI_PEI,
    AvoirFinancierType.AVOIR_FI_PREFON,
    AvoirFinancierType.AVOIR_FI_PERP,
    AvoirFinancierType.AVOIR_FI_ART_83,
  ],
  AUTRE:[
    AvoirFinancierType.AVOIR_FI_AUTRE,
  ]
};
export default GroupedAvoirFinancierType;

export enum ObjetPret {
  PRET_CONSO = 'PRET_CONSO',
  PRET_ETUDIANT = 'PRET_ETUDIANT',
  PRET_LOA = 'PRET_LOA',
  PRET_LDD = 'PRET_LDD',
}

export enum TrancheImpot {
  ZERO_CINQ_MILLE = 'ZERO_CINQ_MILLE',
  CINQ_MILLE_QUINZE_MILLE = 'CINQ_MILLE_QUINZE_MILLE',
  QUINZE_MILLE_VINGT_CINQ_MILLE = 'QUINZE_MILLE_VINGT_CINQ_MILLE',
  PLUS_VINGT_CINQ_MILLE = 'PLUS_VINGT_CINQ_MILLE',
}
