<div [formGroup]="form">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label for="civility">Civilité</label>
      <p-dropdown
        formControlName="civility"
        id="civility"
        [options]="civilityOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false">
      </p-dropdown>
      <lib-field-error *ngIf="civilityF?.errors" [fieldError]="civilityF?.errors"></lib-field-error>

    </div>
    <div class="field col-3">
      <label for="firstName">Prénom</label>
      <input formControlName="firstName"
             id="firstName"
             type="text"
             pInputText
             class="inputfield"
             pTooltip="Pour modifier votre prénom ou votre nom, veuillez contacter votre conseiller"
             [tooltipDisabled]="!fullNameDisabled"
             [tooltipPosition]="'top'">
      <lib-field-error *ngIf="firstNameF?.errors" [fieldError]="firstNameF?.errors"></lib-field-error>

    </div>
    <div class="field col-3">
      <label for="lastName">Nom</label>
      <input formControlName="lastName"
             id="lastName"
             [value]="value?.lastName"
             type="text"
             pInputText
             appUpperCaseInput
             class="inputfield"
             pTooltip="Pour modifier votre prénom ou votre nom, veuillez contacter votre conseiller"
             [tooltipDisabled]="!fullNameDisabled"
             [tooltipPosition]="'top'">
      <lib-field-error *ngIf="lastNameF?.errors" [fieldError]="lastNameF?.errors"></lib-field-error>
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="birthName">Nom de naissance</label>
      <input formControlName="birthName"
             id="birthName"
             [value]="value?.birthName"
             type="text"
             pInputText
             appUpperCaseInput
             class="inputfield">
    </div>
    <div class="field col-3" *ngIf="personACharge">
      <label for="birthDate">Date de naissance</label>
      <p-calendar appendTo="body"
                  formControlName="birthDate"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
    </div>
  </div>
  <div class="grid p-fluid">
    <div class="field col-3" [hidden]="personACharge">
      <label for="birthDate">Date de naissance</label>
      <p-calendar appendTo="body"
                  formControlName="birthDate"
                  id="birthDate"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"
      ></p-calendar>
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="birthCity">Commune de naissance</label>
      <input formControlName="birthCity" id="birthCity" type="text" size="30" pInputText class="inputfield" >
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="birthCity">Département de naissance</label>
      <input formControlName="birthState" id="birthState" type="text" size="30" pInputText class="inputfield" >
    </div>
    <div class="field col-3" *ngIf="advanced">
      <label for="birthCountry">Pays de naissance</label>
      <input formControlName="birthCountry" id="birthCountry" type="text" size="30" pInputText class="inputfield" >
    </div>
  </div>
  <div class="grid p-fluid" *ngIf="advanced">
    <div class="field col-3">
      <label for="nationality">Nationalité</label>
      <input formControlName="nationality" id="nationality" type="text" size="30" pInputText class="inputfield" >
    </div>
    <div class="field col-3">
      <label for="otherNationality">Autre nationalité</label>
      <input formControlName="otherNationality" id="otherNationality" type="text" size="30" pInputText class="inputfield" >
    </div>
  </div>
  <div class="grid p-fluid" *ngIf="advanced">
    <div class="field col-3">
      <label for="mesureProtection">Soumis à une mesure de protection</label>
      <p-dropdown
        formControlName="mesureProtection"
        id="mesureProtection"

        [options]="mesureProtectionOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="true">
      </p-dropdown>
    </div>
    <div class="field col-3">
      <label for="handicap">Porteur d'un handicap ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.handicap}">Non</div>
        <p-inputSwitch  id="handicap"
                        formControlName="handicap"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"

        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.handicap}">Oui</div>
      </div>
    </div>
    <div class="field col-3" [hidden]="existence || conjointExistence">
      <label for="dead">Personne décédée ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.dead}">Non</div>
        <p-inputSwitch  id="dead"
                        formControlName="dead"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"

        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.dead}">Oui</div>
      </div>
    </div>
    <div class="field col-3" *ngIf="value.dead">
      <label for="deathDate">Date du décès</label>
      <p-calendar appendTo="body"
                  formControlName="deathDate"
                  id="deathDate"
                  [firstDayOfWeek]=1
                  [maxDate]="currentDate"
                  [showIcon]="true"
                  dateFormat="dd/mm/yy"
                  placeholder="{{ currentDate | date: 'dd/MM/yyyy'}}"

      ></p-calendar>
    </div>
  </div>
</div>
