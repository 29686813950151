<p-dialog
  class="border-round"
  [(visible)]="display"
  (onHide)="onCloseDialog()"
  [style]="{width: '750px'}"
  [modal]="true"
  header="{{taskEditing?.isInternal ? internalTaskName?.value  :taskName?.value}}"
>
  <div *ngIf="taskHasBlockedComment">
    <p>Un commentaire bloquant empêche la fermeture de cette tâche.</p>
  </div>
  <div *ngIf="!taskHasBlockedComment">
    <!--  CUSTOMER TASK FORM-->
    <p>Tâche terminée le
      : {{(taskEditing?.isInternal ? internalTaskClosingDate?.value : taskClosingDate?.value) | date: 'dd/MM/yyyy'}}</p>
    <p>Veuillez indiquer la charge réelle passée sur cette tâche avant de la clore définitivement.</p>
    <ng-container *ngIf="!taskEditing.isInternal">
      <form [formGroup]="stepForm" class="py-6 px-2" *ngIf="isFormReady">
        <ng-container formArrayName="stepGroups">
          <ng-container [formGroupName]="groupIndex">
            <ng-container formArrayName="tasks">
              <ng-container [formGroupName]="taskIndex">
                <div class="grid">
                  <div class="field" [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
                    <label for="close-date">Date de clôture de la tâche</label>
                    <p-calendar appendTo="body"
                                styleClass="w-full"
                                id="close-date"
                                [disabled]="true"
                                [firstDayOfWeek]=1
                                formControlName="closingDate" dateFormat="dd/mm/yy" [showIcon]="true"
                    >
                    </p-calendar>
                    <lib-field-error *ngIf="taskClosingDate?.touched && taskClosingDate?.errors"
                                     [fieldError]="taskClosingDate?.errors">
                    </lib-field-error>
                  </div>
                  <div class="field" [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
                    <label for="real-workload-close">Charge réelle</label>
                    <p-inputNumber formControlName="realWorkload" [showButtons]="true" buttonLayout="horizontal"
                                   styleClass="w-full"
                                   id="real-workload-close" spinnerMode="horizontal" [min]="0" [step]="15"
                                   inputStyleClass="w-1"
                                   decrementButtonClass="p-button" incrementButtonClass="p-button"
                                   incrementButtonIcon="pi pi-plus"
                                   decrementButtonIcon="pi pi-minus" suffix=" min">
                    </p-inputNumber>
                    <lib-field-error *ngIf="taskRealWorkload?.touched && taskRealWorkload?.errors"
                                     [fieldError]="taskRealWorkload?.errors">
                    </lib-field-error>
                  </div>
                  <!-- COLLABOREUR ASSIGNE -->
                  <div class="field" [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
                    <label for="collaborator-input">Collaborateur assigné</label>
                    <p-dropdown styleClass="w-full" formControlName="collaborator" id="collaborator-input"
                                [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                placeholder="..."
                                appendTo="body">
                    </p-dropdown>
                  </div>
                  <div class="field col-12 mt-3" formArrayName="comments">
                    <ng-container [formGroupName]="taskComments.controls.length - 1">
                      <label for="comment-close">Commentaire</label>
                      <p-editor id="comment-close" formControlName="content" [style]="{'height':'120px'}">
                        <ng-template pTemplate="header">
                          <span class="ql-format-group"></span>
                        </ng-template>
                      </p-editor>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </ng-container>
    <!--  INTERNAL TASK FORM-->
    <ng-container *ngIf="taskEditing.isInternal">
      <form [formGroup]="internalTaskForm" class="py-6 px-2" *ngIf="isFormReady">
        <div class="grid">
          <div class="field"  [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
            <label for="close-date-internal">Date de clôture de la tâche</label>
            <p-calendar appendTo="body"
                        styleClass="w-full"
                        [disabled]="true"
                        [firstDayOfWeek]=1
                        id="close-date-internal"
                        formControlName="closingDate" dateFormat="dd/mm/yy" [showIcon]="true"
            >
            </p-calendar>
            <lib-field-error *ngIf="internalTaskClosingDate?.touched && internalTaskClosingDate?.errors"
                             [fieldError]="internalTaskClosingDate?.errors">
            </lib-field-error>
          </div>
          <div class="field col-6" [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
            <label for="real-workload-close-internal">Charge réelle</label>
            <p-inputNumber formControlName="realWorkload" [showButtons]="true" buttonLayout="horizontal"
                           id="real-workload-close-internal" spinnerMode="horizontal" [min]="0" [step]="15"
                           styleClass="w-full"
                           inputStyleClass="w-1"
                           decrementButtonClass="p-button" incrementButtonClass="p-button"
                           incrementButtonIcon="pi pi-plus"
                           decrementButtonIcon="pi pi-minus" suffix=" min">
            </p-inputNumber>
            <lib-field-error *ngIf="internalTaskRealWorkload?.touched && internalTaskRealWorkload?.errors"
                             [fieldError]="internalTaskRealWorkload?.errors">
            </lib-field-error>
          </div>
          <!-- COLLABOREUR ASSIGNE -->
          <div class="field" [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12 flex flex-column' : 'col-6'">
            <label for="collaborator-input">Collaborateur assigné</label>
            <p-dropdown styleClass="w-full" formControlName="collaborator" id="collaborator-input"
                        [options]="collaboratorOptions" optionLabel="label" optionValue="value" placeholder="..."
                        appendTo="body">
            </p-dropdown>
          </div>
          <div class="field col-12 mt-3" formArrayName="comments">
            <ng-container [formGroupName]="internalTaskComments.controls.length - 1">
              <label for="comment-close-internal">Commentaire</label>
              <p-editor id="comment-close-internal" formControlName="content" [style]="{'height':'120px'}">
                <ng-template pTemplate="header">
                  <span class="ql-format-group"></span>
                </ng-template>
              </p-editor>
            </ng-container>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
  <ng-template pTemplate="footer">
    <div *ngIf="!taskHasBlockedComment" class="flex justify-content-end">
      <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                (click)="onCloseDialog()"></p-button>
      <p-button label="Clore la tâche" icon="pi pi-check" styleClass="ml-3 p-button-success p-button-raised"
                (click)="closeTask()"></p-button>
    </div>
    <div *ngIf="taskHasBlockedComment" class="flex justify-content-end">
      <p-button label="Consulter la tâche" icon="pi pi-arrow-right" styleClass="p-button-primary p-button-raised p-button-text"
                (click)="editTask(task)"></p-button>
    </div>
  </ng-template>
</p-dialog>
