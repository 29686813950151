import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CreditNonImmobilierStore, CreditNonImmobilierState } from './credit-non-immobilier.store';

@Injectable({ providedIn: 'root' })
export class CreditNonImmobilierQuery extends QueryEntity<CreditNonImmobilierState> {

  constructor(protected override store: CreditNonImmobilierStore) {
    super(store);
  }

}
