<p-sidebar [(visible)]="display"
           (onHide)="onCloseMenuClick()"
           [baseZIndex]="10000"
           [transitionOptions]="'0ms'"
           position="top"
           [showCloseIcon]="false"
           [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'mobile' : 'desktop'"
>
  <div class="w-11 mx-auto mt-2">
    <ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
      <button pButton type="button" icon="pi pi-times"
              class="p-button-raised p-button-rounded p-button-outlined bg-white mt-3 mb-5"
              (click)="onCloseMenuClick()"
      ></button>
    </ng-container>
    <ng-template #desktop>
      <button pButton type="button" icon="pi pi-times"
              class="p-button-raised p-button-outlined bg-white mt-3 mb-5"
              (click)="onCloseMenuClick()"
      ></button>
    </ng-template>
    <div class="grid">
      <div *ngIf="(uiScreenQuery.isMobile$ | async)" class="flex justify-content-center align-items-center cursor-pointer mx-auto mb-3" routerLink="/">
        <img src="assets/HEADER-LOGO-EMD.png" class="logo-emd-mobile m-1">
        <img src="assets/header-splitter.png" class="logo-spliter-mobile mt-2">
        <h1 class="text-white m-0">TIM</h1>
      </div>
      <ng-container *ngFor="let menuCategory of menu">
        <ng-container *ngIf="((uiScreenQuery.isMobile$ | async) &&  menuCategory.mobileAccessible) || (uiScreenQuery.isMobile$ | async) === false">
          <div [ngClass]="(uiScreenQuery.isMobile$ | async) ? 'col-12' : 'xl:col-3 lg:col-4 col-4'">
            <span class="menu-item-title">{{ menuCategory.menuCategoryLabel }}</span>
            <p-divider></p-divider>
            <ng-container *ngFor="let menuItem of menuCategory.menuItems">
              <ng-container *ngIf="((uiScreenQuery.isMobile$ | async) &&  menuItem.mobileAccessible) || (uiScreenQuery.isMobile$ | async) === false">
                <p class="menu-item align-items-center cursor-pointer" (click)="onCloseMenuClick()" [routerLink]=menuItem.link>
                  <ng-container *ngIf="menuItem.primeIconName !== null; else customIcon">
                    <i class="{{ menuItem.primeIconName }} mr-1"></i>
                  </ng-container>
                  <ng-template #customIcon>
                    <img class="custom-icon" src="assets/icons/{{ menuItem.customIconFileName }}">
                  </ng-template>
                  {{ menuItem.label }}
                </p>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <!-- MOBILE DECONNEXION -->
      <div *ngIf="(uiScreenQuery.isMobile$ | async)" class="grid justify-content-end align-items-center mx-auto mt-5">
        <p class="mr-2 text-white user-name">{{ userFirstname }} {{ userLastname | slice:0:1 }}.</p>
        <p-button icon="pi pi-sign-out"
                  class="align-items-end p-button-raised"
                  (click)="logOut()">
        </p-button>
      </div>
    </div>
    <div class="version">V. {{configuration.version}}</div>
  </div>
</p-sidebar>
