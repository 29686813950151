import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {DropdownOption, DropdownOptionsService, DocumentType, DocumentCodeType} from 'common';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ID} from '@datorama/akita';

@Component({
  selector: 'app-enfant-personnes-a-charge-form',
  templateUrl: './enfant-personnes-a-charge-form.component.html',
  styleUrls: ['./enfant-personnes-a-charge-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnfantPersonnesAChargeFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EnfantPersonnesAChargeFormComponent),
      multi: true
    }
  ]
})
export class EnfantPersonnesAChargeFormComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() type = '';
  @Input() clientId: ID | undefined;
  aCchargeFiscalOptions: DropdownOption[] = [];
  enfantDeOptions: DropdownOption[] = [];
  adoptionOptions: DropdownOption[] = [];
  enfantHandicapOptions: DropdownOption[] = [];
  lienPersonneOptions: DropdownOption[] = [];
  documentType = DocumentType;
  documentTypes: DocumentCodeType[] = [
    this.documentType.CIVIL_PI,
  ];
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  initialValues =
    {
      relationID: null,
      id: null,
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: false,
        dead: false,
        deathDate: null,
      },
      enfantDe: null,
      aChargeFiscale: null,
      adopted: null,
      situationHandicap: null,
      lienPersonne: ['', Validators.required],
      docs: {
        pi: null,
      }
    };

  constructor(private dropdownService: DropdownOptionsService, private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        const {docs, ...rest} = value;
        this.onChange({...rest, ...value.docs});
        this.onTouched();
      })
    );
  }

  get lienPersonneF() {
    return this.form?.get('lienPersonne');
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnInit(): void {
    this.aCchargeFiscalOptions = this.dropdownService.aChargeFiscale;
    this.enfantDeOptions = this.dropdownService.enfantDe;
    this.adoptionOptions = this.dropdownService.adoption;
    this.enfantHandicapOptions = this.dropdownService.handicap;
    this.lienPersonneOptions = this.dropdownService.lienPersonne;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if (value) {
      const {
        pi,
        ...rest
      } = value;
      this.value = {
        ...rest,
        docs: {
          pi: value.pi,
        }
      };
    }
  }

  onChange: any = () => {
  };

  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : {valid: false};
  }

}
