import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonComponent } from './common.component';
import {SessionService} from './store/session/state/session.service';
import {ConfirmationService} from 'primeng/api';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {
  NG_ENTITY_SERVICE_CONFIG,
} from '@datorama/akita-ng-entity-service';
import {TranslatorService} from './services/translator.service';
import {PersistState} from '@datorama/akita';

@NgModule({
  declarations: [
    CommonComponent,
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      isolate: true
    }),
  ],
  exports: [
    CommonComponent
  ]
})
export class CommonModule {
  static forRoot(config: any, storage: PersistState): ModuleWithProviders<CommonModule> {
    return {
      ngModule: CommonModule,
      providers: [
        SessionService,
        { provide: 'config', useValue: config },
        { provide: 'persistStorage', useValue: storage },
        TranslatorService,
        ConfirmationService,
        MessageService,
        {
          provide: NG_ENTITY_SERVICE_CONFIG,
          useValue: {
            baseUrl: config.apiUrl
          }
        },
      ]
    };
  }
}
