export enum CauseRempliTier {
  PERSONNE_MINEURE = 'PERSONNE_MINEURE',
  PERSONNE_SOUS_TUTELLE = 'PERSONNE_SOUS_TUTELLE',
  PERSONNE_SOUS_CURATELLE = 'PERSONNE_SOUS_CURATELLE',
  AUTRE = 'CAUSE_REMPLI_PAR_AUTRE',
}

export enum YesNo {
  YES = 'YES',
  NO = 'NO',
  DONT_KNOW = 'DONT_KNOW',
}

export enum RempliPar {
  YOU = 'YOU',
  TIERS = 'TIERS',
}
