import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {DropdownOption, DropdownOptionsService, NaturalPerson} from 'common';

@Component({
  selector: 'app-conjoint-form',
  templateUrl: './conjoint-form.component.html',
  styleUrls: ['./conjoint-form.component.scss']
})
export class ConjointFormComponent implements OnInit {
  @Input() conjoint: NaturalPerson | undefined;
  form: UntypedFormGroup;
  civilityOptions: DropdownOption[];
  initialValues =
    {
      civility: ['', Validators.required],
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      email: {
        email: [null, Validators.required],
        id: null,
        isContact: true,
        label: null,
        type: null,
      },
    };

  constructor(private formBuilder: UntypedFormBuilder, private dropdownService: DropdownOptionsService,) {
    this.form = this.formBuilder.group(this.initialValues);
    this.civilityOptions = this.dropdownService.civility;
  }

  get civilityF() {
    return this.form?.get('civility');
  }

  get lastNameF() {
    return this.form?.get('lastName');
  }

  get firstNameF() {
    return this.form?.get('firstName');
  }

  get emailF() {
    return this.form?.get('email');
  }

  get value(): any {
    return this.form.value;
  }

  ngOnInit(): void {
    this.form.reset({
      civility: this.conjoint?.civility ? this.conjoint?.civility : '',
      lastName: this.conjoint?.lastName ? this.conjoint?.lastName : '',
      firstName: this.conjoint?.firstName ? this.conjoint?.firstName : '',
      email: {
        email: this.conjoint?.emails?.length ? this.conjoint?.emails?.filter(mail=>mail.isContact)[0].email : null,
        id: null,
        isContact: true,
        label: null,
        type: null,
      }
    });
  }

  getFormData() {
    if (this.form.valid) {
      return this.form.value;
    }
    return null;
  }

}
