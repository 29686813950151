<div #title></div>
<h2 class="rec" *ngIf="clientOrAdmin === 'ADMIN'">Avoirs financiers</h2>
<h2 class="rec" *ngIf="clientOrAdmin === 'CLIENT'">Avoirs financiers</h2>
<p-divider></p-divider>

<div class="grid p-fluid align-items-end">
  <div class="field col-3">
    <label for="typeAvoir">Type</label>
    <p-dropdown
      [(ngModel)]="selectedavoirFinancierType"
      [group]="true"
      id="typeAvoir"
      [options]="avoirFinancierTypeOptions"
      optionLabel="label"
      optionValue="value"
      [autoDisplayFirst]="false"
      placeholder="..."
    >
      <ng-template let-group pTemplate="group">
        <div class="flex align-items-center">
          <span>{{group.label}}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <div class="field col-3">
    <div class="inline-block"
         pTooltip="Vous devez séléctionner un type d'avoir financier"
         [tooltipDisabled]="selectedavoirFinancierType !== null">
      <p-button label="Ajouter cet avoir" icon="pi pi-plus" styleClass="p-button-raised mr-2"
        [disabled]="!selectedavoirFinancierType"
        (onClick)="add()"></p-button>
    </div>
  </div>
</div>

<ng-container *ngIf="clientOrAdmin === 'ADMIN'">
  <div [formGroup]="form" class="mt-5">
  <ng-container formArrayName="avoirFinanciers">
    <ng-container *ngFor="let item of financierControl?.controls; index as i">
      <p-fieldset legend="{{item.value['typeAvoir'] | translate}}" [toggleable]="true" styleClass="mb-3" [(collapsed)]="collapsedPanels[i].collapsed">
        <div class="flex justify-content-end mb-3">
          <button pButton type="button" icon="icon-delete font-icon" class="p-button-raised p-button-text" (click)="remove(i)"></button>
        </div>
        <app-financier-form [formControlName]="i" [clientOrAdmin]="clientOrAdmin"></app-financier-form>
      </p-fieldset>
    </ng-container>
  </ng-container>

  <app-comment-read *ngIf="client.recueil !== undefined"
                    title="Informations complémentaires client"
                    [content]="client.recueil?.comAvoirFinancier"
  ></app-comment-read>
  <h3 class="rec mt-6">Informations complémentaires conseiller</h3>
  <p-divider class="mt-5"></p-divider>
  <app-comment-form formControlName="comConseilleAvoirFinancier"></app-comment-form>
</div>
</ng-container>

<ng-container *ngIf="clientOrAdmin === 'CLIENT'">
  <div [formGroup]="form" class="mt-5">
    <ng-container formArrayName="avoirFinanciers">
      <p-accordion *ngFor="let item of financierControl?.controls; index as i" styleClass="mb-6">
        <p-accordionTab [(selected)]="!collapsedPanels[i].collapsed">
          <ng-template pTemplate="header">
          <div class="flex justify-content-between w-full align-items-center">
            <h3 class="rec">{{item.value['typeAvoir'] | translate}}</h3>
            <button pButton type="button" icon="icon-delete font-icon" class="p-button-raised p-button-text" (click)="remove(i)"></button>
          </div>
          </ng-template>
          <app-financier-form
            [formControlName]="i"
            [clientId]="clientId"
            [clientOrAdmin]="clientOrAdmin"
            [clientType]="clientType"
            [documentTypes]="[documentType.FINANCIER_RELEVE_COMPTE,
              documentType.FINANCIER_RELEVE_SITUATION,
              documentType.FINANCIER_TITRE_PROPRIETE,
              documentType.AUTRE_FINANCIER]"
          ></app-financier-form>
        </p-accordionTab>
      </p-accordion>
    </ng-container>

    <h3 class="rec mt-6">{{'CLIENT_STEP_AVOIRSFI_COMMENT' | translate}}</h3>
    <p-divider class="mt-5"></p-divider>
    <app-comment-form formControlName="comAvoirFinancier"></app-comment-form>
  </div>
</ng-container>
