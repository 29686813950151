import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { PhoneStore, PhoneState } from './phone.store';

@Injectable({ providedIn: 'root' })
export class PhoneService extends NgEntityService<PhoneState> {

  constructor(protected override store: PhoneStore) {
    super(store);
  }

}
