import {ID} from '@datorama/akita';

export interface Address {
  id?: ID;
  label?: string;
  state: string;
  country: string;
  city: string;
  zipCode: string;
  street: string;
  type: string;
  isContact: boolean;
  fiscale: boolean;
}

export function createAddress(params: Partial<Address>) {
  return {

  } as Address;
}
