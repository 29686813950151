import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {FullCalendarComponent} from '@fullcalendar/angular';
import { CalendarOptions, EventInput, EventClickArg} from '@fullcalendar/core';
import {
  ClientOptionValue, DropdownOption,
  DropdownOptionsService, LegalPerson, NaturalPerson,  SessionQuery, Step,
  StepAndTaskOptionValue, StepQuery,
  Task,
  TaskQuery,
  TaskStatus,
  TranslatorService, UiQuery, UiStore, User, UserQuery, UserRole, TimelineFilter, UiState, UiScreenQuery
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineQueries, ID} from '@datorama/akita';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-page-timeline',
  templateUrl: './page-timeline.component.html',
  styleUrls: ['./page-timeline.component.scss']
})
export class PageTimelineComponent implements OnInit, AfterViewInit {
  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;
  @ViewChild('eventContent') eventContent: any;

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin]
  };

  events: EventInput [] = [];
  showUnfinishedTasksWarning = false;

  stepOptions: DropdownOption<StepAndTaskOptionValue>[];
  taskOptions: DropdownOption<string>[];
  clientOptions: DropdownOption<ClientOptionValue>[];
  poleAndCollaboratorOptions: { label: string; value: string; items: any[] }[];

  timelineFilter = TimelineFilter;
  authenticatedUser: Partial<User>;

  uiStoreState: UiState;

  filterValues: any;

  constructor(
    private uiStore: UiStore,
    private uiQuery: UiQuery,
    public uiScreenQuery: UiScreenQuery,
    private sessionQuery: SessionQuery,
    private userQuery: UserQuery,
    private taskQuery: TaskQuery,
    private stepQuery: StepQuery,
    private dropdownService: DropdownOptionsService,
    private translatorService: TranslatorService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit() {
    this.calendarOptions = this.getFullCalendarOptions();
  }

  ngAfterViewInit(): void {
    this.translatorService.init();

    this.stepOptions = this.dropdownService.steps;
    this.taskOptions = this.dropdownService.stepsTasks;
    this.clientOptions = this.dropdownService.clients;
    this.poleAndCollaboratorOptions = this.dropdownService.polesAndCollaborators.filter((val: any) => val.value !== 'other');

    combineQueries([
        this.uiQuery.select().pipe(untilDestroyed(this)),
        this.sessionQuery.select().pipe(untilDestroyed(this)),
        this.stepQuery.selectAll().pipe(untilDestroyed(this)),
        this.taskQuery.selectAll({ filterBy: [
            (task) => task.isInternal
          ]
        }).pipe(untilDestroyed(this)),
      ]
    ).pipe(untilDestroyed(this)).subscribe(
      ([uiState, userState, clientStepTasks, internalTasks]) => {
        let clientTasks: any[] = [];

        if (uiState && userState && clientStepTasks && internalTasks){
          this.uiStoreState = uiState;
          this.authenticatedUser = userState;

          this.filterValues = Object.assign({},this.uiStoreState.timeline.filters);

          if (this.uiStoreState.timeline.filters.poleAndCollaborator === undefined){
            this.uiStore.update({...this.uiStoreState, timeline: { filters: { ...this.filterValues, poleAndCollaborator : {
                    id: this.authenticatedUser.id,
                    type: 'collaborator'
                  } } }});
          }


          clientStepTasks.map((step) => {
              step.stepGroups.map((group) =>
                group.tasks.map((task) => {
                  clientTasks = [...clientTasks, {
                    ...task,
                    step,
                    naturalPersons: step.naturalPersons ?? [],
                    legalPersons: step.legalPersons ?? [],
                  }];
                })
              );
            }
          );
          const tasks = [...clientTasks, ...internalTasks];
          // On retire des tâches du tableau conformément aux règles métier spécifiées
          const prefilteredTasks: Task[] = tasks.filter(task =>
            this.applyBusinessRulesToTask(task)
          );
          // On transforme les tâches en événements de la forme attendus par le calendrier
          this.events = prefilteredTasks.map((task): EventInput =>
            this.mapTaskIntoEvent(task)
          );

          // On applique les filtres
          this.filterTasks();
          this.changeDetectorRef.detectChanges();
          if(this.uiStoreState.timeline.filters.week){
            this.fullcalendar.getApi().gotoDate(this.uiStoreState.timeline.filters.week );
          }
        }
      }
    );


  }

  // FULLCALENDAR CONFIG
  getFullCalendarOptions = (): CalendarOptions => ({
    plugins: [dayGridPlugin],
    initialView: 'dayGridWeek',
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    firstDay: 1,
    locale: 'fr',
    height: 'auto',
    // events: this.events,
    eventDisplay: 'block',
    displayEventTime: false,
    weekends: false,
    // HEADER
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    titleFormat: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    eventOrder: '-isPriority, -importance',
    titleRangeSeparator:' \u2192 ',
    eventClick: (arg: EventClickArg) => {
      if (arg.event.extendedProps['isInternal']) {
        this.router.navigate(['/task-internal-edit', arg.event.id, { from: 'timeline' }]);
      } else {
        this.router.navigate(['/task-customer-edit', arg.event.extendedProps['stepId'], arg.event.id, { from: 'timeline' }]);
      }
    },
    eventDidMount: (info: any) => {
      const eventProperties = info.event.extendedProps;
      info.el.setAttribute('data-tooltip', `${eventProperties.clients.length > 0  ? [...eventProperties.clients] + '\n' : ''} ${eventProperties.taskName}`);
    },
  });

  mapTaskIntoEvent = (task: Task & { step?: Step }): EventInput & { pole: string; collaborator?: ID } => {
    const clients = [
      ...(task.legalPersons?.map((client: LegalPerson) => ' ' + client.name) || []),
      ...(task.naturalPersons?.map((client: NaturalPerson) => ' ' + client.lastName[0] + client.lastName.toLowerCase().slice(1) + ' ' + client.firstName[0] + '.') || [])
    ];

    return ({
      // PROPRIÉTÉS NÉCESSAIRES À L'AFFICHAGE DE L'ÉVÉNEMENT
      id: task.id as string,
      title: `
            ${task.importance > 1 ? this.convertImportanceToIndicator(task.importance) + ' | ' : ''}
            ${this.convertMinutesToDuration(task.plannedWorkload)} |
            ${task.isInternal ? '' : [...clients]} ${clients.length > 0 ? ' | ' : '' }
            ${task.name}
            `,
      start: task.startDate,
      end: task.deadlineDate,
      // STYLE DE L'ÉVÉNEMENT
      className: task.importance > 1 ? 'font-semibold' : '',
      color: task.isPriority ? '#bd1818' : '#fff',
      borderColor: task.isPriority ? '#bd1818' : '#b8cede',
      textColor: task.isPriority ? '#fff' : '#000',
      // PROPRIÉTÉS SUPPLÉMENTAIRES POUR TRIER ET FILTRER
      deadline: task.deadlineDate,
      isPriority: task.isPriority,
      isInternal: task.isInternal,
      step: task.isInternal ? task.taskGroup?.step?.name : task.step?.name,
      stepId: task.isInternal ? task.taskGroup?.step?.id : task.step?.id,
      taskName: task.name,
      legalPersons: task.isInternal ? task?.taskGroup?.step?.legalPersons : task.legalPersons,
      naturalPersons: task.isInternal ? task?.taskGroup?.step?.naturalPersons : task.naturalPersons,
      pole: task.pole,
      collaborator: task.collaborator?.id,
      clients,
    });
  };

  // FILTERING METHODS
  applyBusinessRulesToTask = (task: Task) => {
    // Les tâches privées dirigeant ne sont visibles que pour les utilisateurs dirigeants
    if (task.isLeaderPrivate) {
      return this.authenticatedUser.roles?.includes(UserRole.ROLE_DIRIGEANT) && task.status !== TaskStatus.DONE;
    }
    // Les tâches privées ne sont visibles que par leur donneur d'ordre
    if (task.isPrivate) {
      return task.contractor.id === this.authenticatedUser.id && task.status !== TaskStatus.DONE;
    }
    // Les tâches terminées ne sont pas affichées
    return task.status !== TaskStatus.DONE;
  };

  filterTasks(filterValue?: any , field?: TimelineFilter) {
    if (field) {
      this.filterValues[field] = filterValue;
      this.uiStore.update({...this.uiStoreState, timeline: { filters: { ...this.filterValues } }});
    }
    this.calendarOptions.events = this.events.filter(event => (
      this.isEventInternalOrClient(event, this.filterValues[TimelineFilter.TYPE])
    ));
    this.calendarOptions.events = this.calendarOptions.events.filter(event => (
      this.doesEventContainStepName(event, this.filterValues[TimelineFilter.STEP])
    ));
    this.calendarOptions.events = this.calendarOptions.events.filter(event => (
      this.doesEventContainTaskName(event, this.filterValues[TimelineFilter.TASK])
    ));
    this.calendarOptions.events = this.calendarOptions.events.filter(event =>
      this.doesEventContainClient(event, this.filterValues[TimelineFilter.CLIENT])
    );
    this.calendarOptions.events = this.calendarOptions.events.filter(event =>
      this.doesEventContainPoleOrCollaborator(event, this.filterValues[TimelineFilter.POLE_AND_COLLABORATOR])
    );

    this.showUnfinishedTasksWarning = this.shouldShowUnfinishedTasksWarning(this.calendarOptions.events);
  }

  isEventInternalOrClient = (event: EventInput, filterValue: any) => {
    if (filterValue === 'internal') {
      return !!event['isInternal'];
    } else if (filterValue === 'client') {
      return !event['isInternal'];
    }
    return !filterValue;
  };

  doesEventContainStepName = (event: EventInput, filterValue: any) => {
    if (filterValue) {
      this.taskOptions = this.taskOptions.filter((stepTasks) => stepTasks.label === filterValue.name);

      return event['step'] === filterValue.name;
    }
    this.taskOptions = this.dropdownService.stepsTasks;

    return !filterValue;
  };

  doesEventContainTaskName = (event: EventInput, filterValue: any) => {
    if (filterValue) {
      return event['taskName'] === filterValue.name;
    }
    return !filterValue;
  };

  doesEventContainClient = (event: EventInput, filterValue: any) => {
    if (filterValue?.type === 'naturalPerson') {
      return event['naturalPersons']?.some((naturalPerson: NaturalPerson) => naturalPerson.id === filterValue.id);
    }
    if (filterValue?.type === 'legalPerson') {
      return event['legalPersons']?.some((legalPerson: LegalPerson) => legalPerson.id === filterValue.id);
    }
    return !filterValue;
  };

  doesEventContainPoleOrCollaborator = (event: EventInput, filterValue: any) => {
    if (filterValue?.type === 'pole') {
      return event['pole'] === filterValue.id;
    }
    if (filterValue?.type === 'collaborator') {
      return event['collaborator'] === filterValue.id;
    }
    if (filterValue === undefined) {
      return event['collaborator'] === this.authenticatedUser.id;
    }

    return !filterValue;
  };

  onWeekNavigation = () => {
    this.filterTasks();
    this.uiStore.update({
      ...this.uiStoreState,
      timeline: {
        filters: {
          ...this.filterValues,
          week: this.fullcalendar.getApi().getCurrentData().currentDate
        }
      }
    });
  };

  // HELPERS FOR TASK DISPLAY
  convertImportanceToIndicator = (importance: number) => {
    let indicator = '';
    new Array(importance).fill(null).map(() => indicator += '€');

    return indicator;
  };

  convertMinutesToDuration = (duration = 0) => {
    const formattedDuration = duration / 60;

    const hours = Math.floor(formattedDuration);
    const minutes = Math.floor((formattedDuration - Math.floor(formattedDuration)) * 60);

    if (hours === 0) {
      return minutes + 'min';
    } else if (minutes === 0) {
      return hours + 'h';
    } else if (minutes.toString().length < 2) {
      return hours + 'h' + '0' + minutes;
    } else {
      return hours + 'h' + minutes;
    }
  };

  shouldShowUnfinishedTasksWarning = (events: EventInput[]): boolean => {
    let unfinishedTasksCounter = 0;

    events.map(event => {
      if (new Date(event['deadline']) < this.fullcalendar.getApi().view?.currentStart) {
        unfinishedTasksCounter++;
      }
    });

    return unfinishedTasksCounter > 0;
  };
}
