import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NaturalNaturalRelationType } from './natural-natural-relation-type.model';

export interface NaturalNaturalRelationTypeState extends EntityState<NaturalNaturalRelationType> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'natural_natural_relation_types',
})
export class NaturalNaturalRelationTypeStore extends EntityStore<NaturalNaturalRelationTypeState> {

  constructor() {
    super();
  }

}
