<form [formGroup]="form">
  <div class="grid">
    <div class="field col-6">
      <label for="name-input">Nom du produit</label>
      <input formControlName="name" id="name-input" type="text" pInputText class="inputfield w-full">
      <lib-field-error *ngIf="name?.touched && name?.errors" [fieldError]="name?.errors"></lib-field-error>
    </div>
    <div class="field col-6">
        <label for="fournisseur-input">Fournisseur</label>
        <p-dropdown
          appendTo="body"
          styleClass="w-full"
          formControlName="fournisseur"
          id="fournisseur-input"
          [options]="fournisseurs"
          optionLabel="name"
          optionValue="id"
          [autoDisplayFirst]="false"
          [filter]="true">
        </p-dropdown>
        <lib-field-error *ngIf="fournisseur?.touched && fournisseur?.errors" [fieldError]="fournisseur?.errors"></lib-field-error>
      </div>
    </div>
</form>
