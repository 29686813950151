import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { AvoirFinancier } from './avoir-financier.model';

export interface AvoirFinancierState extends EntityState<AvoirFinancier> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'avoirFinancier' })
export class AvoirFinancierStore extends EntityStore<AvoirFinancierState> {

  constructor() {
    super();
  }

}
