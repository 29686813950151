import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { ImmobilierStore, ImmobilierState } from './immobilier.store';

@Injectable({ providedIn: 'root' })
export class ImmobilierService extends NgEntityService<ImmobilierState> {

  constructor(protected override store: ImmobilierStore) {
    super(store);
  }

}
