import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ObjectifStore, ObjectifState } from './objectif.store';

@Injectable({ providedIn: 'root' })
export class ObjectifQuery extends QueryEntity<ObjectifState> {

  constructor(protected override store: ObjectifStore) {
    super(store);
  }

}
