import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ClientSearchResultStore } from './client-search-result.store';

@Injectable({ providedIn: 'root' })
export class ClientSearchResultService {

  constructor(private clientSearchResultStore: ClientSearchResultStore, private http: HttpClient) {
  }


}
