import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef, forwardRef, Input, OnDestroy,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {GoogleMapService} from 'common';
import PlaceResult = google.maps.places.PlaceResult;
import {DropdownOption, DropdownOptionsService} from 'common';


@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressFormComponent),
      multi: true
    }
  ]
})
export class AddressFormComponent implements ControlValueAccessor, OnDestroy, AfterViewChecked {
  @Input() toogleIsContact: any;
  @Input() toogleIsFiscale: any;
  // sert a conditionner l'affichage de certains champs
  @Input() advanced = false;
  @ViewChild('addressText') addressText: ElementRef;

  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  initialValues =
    {
      id: null,
      label: null,
      street: null,
      city: null,
      zipCode: null,
      state: null,
      country: null,
      isContact: false,
      fiscale: false,
      type: null,
    };
  googleServiceInitied = false;
  addressTypeOptions: DropdownOption<any>[] = [];
  place: PlaceResult;
  protected placeSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private googleMapService: GoogleMapService,
    private dropdownOptionsService: DropdownOptionsService
  ) {
    this.form = this.formBuilder.group(this.initialValues);
    this.subscriptions.push(
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      })
    );
    this.addressTypeOptions = this.dropdownOptionsService.addressType;
  }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value, {onlySelf: true, emitEvent:false});
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }

  writeValue(value: any) {
    if(value) {
      this.value = {
        id: value.id,
        label: value.label,
        street: value.street,
        city: value.city,
        zipCode: value.zipCode,
        state: value.state,
        country: value.country,
        isContact: value.isContact,
        fiscale: value.fiscale,
        type: value.type,
      };
    }
  }

  onIsContactChange(event: any) {
    if(event.checked){
      this.toogleIsContact.callParentMethod();
      this.form.get('isContact')?.patchValue(true);
    }
  }

  onFiscaleChange(event: any) {
    if(event.checked){
      this.toogleIsFiscale.callParentMethod();
      this.form.get('fiscale')?.patchValue(true);
    }
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate(_: UntypedFormControl) {
    return this.form.valid ? null : { valid: false };
  }

  ngAfterViewChecked(): void {
    if(this.addressText && !this.googleServiceInitied) {
      this.googleMapService.getPlaceAutocomplete(this.addressText);
      this.googleServiceInitied = true;
    }
  }

  onAddressChange(): void {
    this.placeSubscription =
      this.googleMapService.placeObservable.subscribe(
        (place) => {
          this.place = place;
          this.form.patchValue({
              city: this.googleMapService.getCity(this.place),
              zipCode: this.googleMapService.getPostCode(this.place),
              street: this.googleMapService.getStreetNumber(this.place) !== undefined ?
                this.googleMapService.getStreetNumber(this.place) + ' ' + this.googleMapService.getStreet(this.place) :
                this.googleMapService.getStreet(this.place),
              country: this.googleMapService.getCountry(this.place),
              state: this.googleMapService.getState(this.place)
          });
          this.placeSubscription.unsubscribe();
        }
      );
  }
  /* get form from template */
  get streetF() { return this.form?.get('street');}
  get cityF() { return this.form?.get('city');}
  get zipCodeF() { return this.form?.get('zipCode');}
  get stateF() { return this.form?.get('state');}
  get countryF() { return this.form?.get('country');}
}
