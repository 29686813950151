import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { BienDiversTypeStore, BienDiversTypeState } from './bien-divers-type.store';

@Injectable({ providedIn: 'root' })
export class BienDiversTypeService extends NgEntityService<BienDiversTypeState> {

  constructor(protected override store: BienDiversTypeStore) {
    super(store);
  }

}
