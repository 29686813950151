/*
 * Public API Surface of common
 */

export * from './lib/common.service';
export * from './lib/common.component';
export * from './lib/ui/field-error/field-error.component';
export * from './lib/ui/signature-maker/signature-maker.component';
export * from './lib/ui/input-number/input-number.component';
export * from './lib/ui/form-guard-dialog/form-guard-dialog.component';
export * from './lib/common.module';
export * from './lib/ui/ui.module';

export * from './lib/services/google-map.service';
export * from './lib/services/dropdown-options.service';
export * from './lib/services/sse.service';
export * from './lib/services/navigation.service';
export * from './lib/services/entity-notification.service';
export * from './lib/services/task-helper.service';
export * from './lib/services/recueil-helper.service';
export * from './lib/services/client-search.service';
export * from './lib/services/document-helper.service';

export * from './lib/enums/ConfigurationCode';
export * from './lib/enums/user/UserRole';
export * from './lib/enums/user/PoleClient';
export * from './lib/enums/user/PoleInterne';
export * from './lib/enums/client/ClientType';
export * from './lib/enums/client/DocumentType';
export * from './lib/enums/HttpResponseMessageType';
export * from './lib/enums/client/ClientStatus';
export * from './lib/enums/client/Civility';
export * from './lib/enums/client/ContactEnums';
export * from './lib/enums/client/FinancierEnums';
export * from './lib/enums/client/ImmobilierEnums';
export * from './lib/enums/client/MatrimonialeEnums';
export * from './lib/enums/client/PersonneChargeEnums';
export * from './lib/enums/client/ProfessionalSituationEnums';
export * from './lib/enums/client/RecueilEnums';
export * from './lib/enums/client/NaturalNaturalRelationTypeEnums';
export * from './lib/enums/DropdownOptionType';
export * from './lib/enums/TaskImportance';
export * from './lib/enums/TaskStatus';
export * from './lib/enums/InitialSteps';
export * from './lib/enums/TaskDeadlineStatus';
export * from './lib/enums/LegalStatus';
export * from './lib/enums/client/CustomerOrigin';
export * from './lib/enums/NotificationState';
export * from './lib/enums/NotificationNature';

export * from './lib/store/session/state/session.query';
export * from './lib/store/session/state/session.service';
export * from './lib/store/session/state/session.store';

export * from './lib/store/configuration/state/configuration.query';
export * from './lib/store/configuration/state/configuration.service';
export * from './lib/store/configuration/state/configuration.model';
export * from './lib/store/configuration/state/configuration.store';

export * from './lib/store/user/state/user.model';
export * from './lib/store/user/state/user.query';
export * from './lib/store/user/state/user.service';
export * from './lib/store/user/state/user.store';

export * from './lib/store/client/address/state/address.model';
export * from './lib/store/client/address/state/address.query';
export * from './lib/store/client/address/state/address.service';
export * from './lib/store/client/address/state/address.store';

export * from './lib/store/client/avoirFinancier/state/avoir-financier.model';
export * from './lib/store/client/avoirFinancier/state/avoir-financier.query';
export * from './lib/store/client/avoirFinancier/state/avoir-financier.service';
export * from './lib/store/client/avoirFinancier/state/avoir-financier.store';

export * from './lib/store/client/bienDiversType/state/bien-divers-type.model';
export * from './lib/store/client/bienDiversType/state/bien-divers-type.query';
export * from './lib/store/client/bienDiversType/state/bien-divers-type.service';
export * from './lib/store/client/bienDiversType/state/bien-divers-type.store';

export * from './lib/store/client/creditNonImmobilier/state/credit-non-immobilier.model';
export * from './lib/store/client/creditNonImmobilier/state/credit-non-immobilier.query';
export * from './lib/store/client/creditNonImmobilier/state/credit-non-immobilier.service';
export * from './lib/store/client/creditNonImmobilier/state/credit-non-immobilier.store';

export * from './lib/store/client/immobilier/state/immobilier.model';
export * from './lib/store/client/immobilier/state/immobilier.query';
export * from './lib/store/client/immobilier/state/immobilier.service';
export * from './lib/store/client/immobilier/state/immobilier.store';

export * from './lib/store/client/objectif/state/objectif.model';
export * from './lib/store/client/objectif/state/objectif.query';
export * from './lib/store/client/objectif/state/objectif.service';
export * from './lib/store/client/objectif/state/objectif.store';

export * from './lib/store/client/email/state/email.model';
export * from './lib/store/client/email/state/email.query';
export * from './lib/store/client/email/state/email.service';
export * from './lib/store/client/email/state/email.store';

export * from './lib/store/client/phone/state/phone.model';
export * from './lib/store/client/phone/state/phone.query';
export * from './lib/store/client/phone/state/phone.service';
export * from './lib/store/client/phone/state/phone.store';

export * from './lib/store/compteRendu/state/compte-rendu.model';
export * from './lib/store/compteRendu/state/compte-rendu.query';
export * from './lib/store/compteRendu/state/compte-rendu.service';
export * from './lib/store/compteRendu/state/compte-rendu.store';

export * from './lib/store/document/state/document.model';
export * from './lib/store/document/state/document.query';
export * from './lib/store/document/state/document.service';
export * from './lib/store/document/state/document.store';

export * from './lib/store/docsToAdd/state/docs-to-add.query';
export * from './lib/store/docsToAdd/state/docs-to-add.service';
export * from './lib/store/docsToAdd/state/docs-to-add.store';

export * from './lib/store/docsToDelete/state/docs-to-delete.query';
export * from './lib/store/docsToDelete/state/docs-to-delete.service';
export * from './lib/store/docsToDelete/state/docs-to-delete.store';

export * from './lib/store/client/legalPerson/state/legal-person.model';
export * from './lib/store/client/legalPerson/state/legal-person.query';
export * from './lib/store/client/legalPerson/state/legal-person.service';
export * from './lib/store/client/legalPerson/state/legal-person.store';

export * from './lib/store/client/naturalPerson/state/natural-person.model';
export * from './lib/store/client/naturalPerson/state/natural-person.query';
export * from './lib/store/client/naturalPerson/state/natural-person.service';
export * from './lib/store/client/naturalPerson/state/natural-person.store';

export * from './lib/store/client/natural-legal-relation/state/natural-legal-relation.model';
export * from './lib/store/client/natural-legal-relation/state/natural-legal-relation.query';
export * from './lib/store/client/natural-legal-relation/state/natural-legal-relation.service';
export * from './lib/store/client/natural-legal-relation/state/natural-legal-relation.store';

export * from './lib/store/client/natural-natural-relation/state/natural-natural-relation.model';
export * from './lib/store/client/natural-natural-relation/state/natural-natural-relation.query';
export * from './lib/store/client/natural-natural-relation/state/natural-natural-relation.service';
export * from './lib/store/client/natural-natural-relation/state/natural-natural-relation.store';

export * from './lib/store/client/natural-natural-relation-type/state/natural-natural-relation-type.model';
export * from './lib/store/client/natural-natural-relation-type/state/natural-natural-relation-type.query';
export * from './lib/store/client/natural-natural-relation-type/state/natural-natural-relation-type.service';
export * from './lib/store/client/natural-natural-relation-type/state/natural-natural-relation-type.store';

export * from './lib/store/client/recueil/state/recueil.model';
export * from './lib/store/client/recueil/state/recueil.query';
export * from './lib/store/client/recueil/state/recueil.service';
export * from './lib/store/client/recueil/state/recueil.store';

export * from './lib/store/client/clientSearchResult/state/client-search-result.query';
export * from './lib/store/client/clientSearchResult/state/client-search-result.service';
export * from './lib/store/client/clientSearchResult/state/client-search-result.store';
export { createInitialState } from './lib/store/client/clientSearchResult/state/client-search-result.store';

export * from './lib/store/task/taskMaster/state/task-master.model';
export * from './lib/store/task/groupMaster/state/group-master.model';

export * from './lib/store/task/stepMaster/state/step-master.model';
export * from './lib/store/task/stepMaster/state/step-master.query';
export * from './lib/store/task/stepMaster/state/step-master.service';
export * from './lib/store/task/stepMaster/state/step-master.store';

export * from './lib/store/task/task/state/task.model';
export * from './lib/store/task/task/state/task.query';
export * from './lib/store/task/task/state/task.service';
export * from './lib/store/task/task/state/task.store';

export * from './lib/store/task/group/state/group.model';
export * from './lib/store/task/group/state/group.query';
export * from './lib/store/task/group/state/group.service';
export * from './lib/store/task/group/state/group.store';

export * from './lib/store/task/step/state/step.model';
export * from './lib/store/task/step/state/step.query';
export * from './lib/store/task/step/state/step.service';
export * from './lib/store/task/step/state/step.store';

export * from './lib/store/task/comment/state/comment.model';
export * from './lib/store/task/comment/state/comment.query';
export * from './lib/store/task/comment/state/comment.service';
export * from './lib/store/task/comment/state/comment.store';

export * from './lib/store/task/product/state/product.model';
export * from './lib/store/task/product/state/product.query';
export * from './lib/store/task/product/state/product.service';
export * from './lib/store/task/product/state/product.store';

export * from './lib/store/task/fournisseur/state/fournisseur.model';
export * from './lib/store/task/fournisseur/state/fournisseur.query';
export * from './lib/store/task/fournisseur/state/fournisseur.service';
export * from './lib/store/task/fournisseur/state/fournisseur.store';

export * from './lib/store/task/product/state/product.model';
export * from './lib/store/task/product/state/product.query';
export * from './lib/store/task/product/state/product.service';
export * from './lib/store/task/product/state/product.store';

export * from './lib/store/task/fournisseur/state/fournisseur.model';
export * from './lib/store/task/fournisseur/state/fournisseur.query';
export * from './lib/store/task/fournisseur/state/fournisseur.service';
export * from './lib/store/task/fournisseur/state/fournisseur.store';

export * from './lib/store/task/stepMaster/state/step-master.model';
export * from './lib/store/task/stepMaster/state/step-master.query';
export * from './lib/store/task/stepMaster/state/step-master.service';
export * from './lib/store/task/stepMaster/state/step-master.store';

export * from './lib/store/task/group/state/group.model';
export * from './lib/store/task/group/state/group.query';
export * from './lib/store/task/group/state/group.service';
export * from './lib/store/task/group/state/group.store';

export * from './lib/store/ui/state/ui.query';
export * from './lib/store/ui/state/ui.service';
export * from './lib/store/ui/state/ui.store';

export * from './lib/store/uiScreen/state/ui-screen.query';
export * from './lib/store/uiScreen/state/ui-screen.service';
export * from './lib/store/uiScreen/state/ui-screen.store';

export * from './lib/store/uiRecueil/state/ui-recueil.query';
export * from './lib/store/uiRecueil/state/ui-recueil.service';
export * from './lib/store/uiRecueil/state/ui-recueil.store';

export * from './lib/store/notification/state/notification.model';
export * from './lib/store/notification/state/notification.query';
export * from './lib/store/notification/state/notification.service';
export * from './lib/store/notification/state/notification.store';

export * from './lib/store/translation/state/translation.model';
export * from './lib/store/translation/state/translation.query';
export * from './lib/store/translation/state/translation.service';
export * from './lib/store/translation/state/translation.store';

export * from './lib/interceptors/jwt.interceptor';
export * from './lib/interceptors/error.interceptor';
export * from './lib/guards/form-guard';

export * from './lib/types/DropdownOption';

export { HttpClient } from '@angular/common/http';

export { ConfirmationService, MessageService } from 'primeng/api';
export * from './lib/services/translator.service';
