import { ID } from '@datorama/akita';
import { Phone } from '../../phone/state/phone.model';
import { User } from '../../../user/state/user.model';
import { Address } from '../../address/state/address.model';
import { Email } from '../../email/state/email.model';
import { NaturalNaturalRelation } from '../../natural-natural-relation/state/natural-natural-relation.model';
import { NaturalLegalRelation } from '../../natural-legal-relation/state/natural-legal-relation.model';
import { CompteRendu } from '../../../compteRendu/state/compte-rendu.model';
import { Step } from '../../../task/step/state/step.model';
import { Recueil } from '../../recueil/state/recueil.model';
import { Document } from '../../../document/state/document.model';

export interface NaturalPerson {
  id?: ID;
  generalComment?: string;
  phones: Phone[];
  civility: string;
  lastName: string;
  firstName: string;
  mainInterlocutor?: User;
  secondaryInterlocutor?: User;
  birthDate?: Date;
  dateEntreeEnRelation?: Date;
  customerOrigin?: string;
  customerStatus: string[];
  addresses: Address[];
  emails: Email[];
  uuidO2s?: string;
  naturalNaturalRelations?: NaturalNaturalRelation[];
  naturalLegalRelations?: NaturalLegalRelation[];
  compteRendus?: CompteRendu[];
  clientType: string;
  steps: [] | Step[];
  recueil?: Recueil|null;
  birthCity?: string;
  birthState?: string;
  birthCountry?: string;
  birthName?: string;
  nationality?: string;
  otherNationality?: string;
  mesureProtection?: string;
  handicap?: boolean;
  rentPrincipalResidence?: boolean;
  loyerAnnuel?: number;
  politiquementExpose?: boolean;
  prochePolitiquementExpose?: boolean;
  usPerson?: boolean;
  vecuEtrange?: boolean;
  vecuEtrangeDixAns?: boolean;
  dead?: boolean;
  deathDate?: Date;
  userAccount?: User;
  situationMatrimoniale?: string;
  precedentMariage?: boolean;
  dateMariage?: Date;
  lieuMariage?: string;
  regimeMatrimonial?: string;
  regimePacsimonial?: string;
  donationDernierVivant?: boolean;
  sensDonation?: string;
  testamentCroise?: boolean;
  sensTestamentCroise?: string;
  situationPro?: string;
  currentJob?: string;
  jobCompany?: string;
  segmentation?: string;
  revenusAnnuel?: number;
  rentesAnnuelle?: number;
  doublonTraite?: boolean;
  attestDonation?: Document;
  contratMariage?: Document;
  jugementDivorce?: Document;
  pi?: Document;
  justifDomicile?: Document;
  livretFamille?: Document;
  avisImpot?: Document;
  declarationImpot?: Document;
  testament?: Document;
  acteDonation?: Document;
  otherDocs?: Document[];
  createdAt?: Date;
}

export function createNaturalPerson(params: Partial<NaturalPerson>) {
  return {} as NaturalPerson;
}
