export type DocumentCodeType = {code: string; field: string; multiple: boolean; owner?: any};
export const DocumentType = {
  //CIVILE
  CIVIL_PI: {code: 'CIVIL_PI', field:'pi', multiple: false},
  CIVIL_JUSTIF_DOMICILE: {code: 'CIVIL_JUSTIF_DOMICILE', field:'justifDomicile', multiple: false},
  CIVIL_DONATION_DERNIER_VIVANT: {code: 'CIVIL_DONATION_DERNIER_VIVANT', field:'attestDonation', multiple: false},
  CIVIL_LIVRET_FAMILLE: {code: 'CIVIL_LIVRET_FAMILLE', field:'livretFamille', multiple: false},

  //MATRIMONIALE
  MATRIMONIAL_JUGEMENT_DIVORCE: {code: 'MATRIMONIAL_JUGEMENT_DIVORCE', field:'jugementDivorce', multiple: false},
  MATRIMONIAL_CONTRAT_MARIAGE: {code: 'MATRIMONIAL_CONTRAT_MARIAGE', field:'contratMariage', multiple: false},

  //IMMO
  IMMO_AMORTISSEMENT: {code: 'IMMO_AMORTISSEMENT', field:'amortissement', multiple: false},
  IMMO_OFFRE_PRET: {code: 'IMMO_OFFRE_PRET', field:'offrePret', multiple: false},
  IMMO_ATTEST_NOTARIAL: {code: 'IMMO_ATTEST_NOTARIAL', field:'attestNotarial', multiple: false},
  IMMO_TAXE_FONCIERE: {code: 'IMMO_TAXE_FONCIERE', field:'taxeFonciere', multiple: false},
  IMMO_TITRE_PROPRIETE: {code: 'IMMO_TITRE_PROPRIETE', field:'titrePropriete', multiple: false},
  IMMO_STATUT_SCI: {code: 'IMMO_STATUT_SCI', field:'statutSci', multiple: false},

  //DIVERS
  DIVERS_TITRE_PROPRIETE: {code: 'DIVERS_TITRE_PROPRIETE', field:'titreProprieteBienDivers', multiple: false},
  DIVERS_JUSTIF_INVEST: {code: 'DIVERS_JUSTIF_INVEST', field:'justifInvestissementBienDivers', multiple: false},

  //FINANCIER
  FINANCIER_RELEVE_COMPTE: {code: 'FINANCIER_RELEVE_COMPTE', field:'releveCompte', multiple: false},
  FINANCIER_RELEVE_SITUATION: {code: 'FINANCIER_RELEVE_SITUATION', field:'releveSituation', multiple: false},
  FINANCIER_TITRE_PROPRIETE: {code: 'FINANCIER_TITRE_PROPRIETE', field:'titrePropriete', multiple: false},

  //IMPOT
  IMPOT_AVIS: {code: 'IMPOT_AVIS', field:'avisImpot', multiple: false},
  IMPOT_DECLARATION: {code: 'IMPOT_DECLARATION', field:'declarationImpot', multiple: false},

  //CREDIT
  CREDIT_OFFRE: {code: 'CREDIT_OFFRE', field:'offreCredit', multiple: false},
  CREDIT_AMORTISSEMENT: {code: 'CREDIT_AMORTISSEMENT', field:'amortissement', multiple: false},

  TESTAMENT: {code: 'TESTAMENT', field:'testament', multiple: false},
  ACTE_DONATION: {code: 'ACTE_DONATION', field:'acteDonation', multiple: false},
  RECUEIL: {code: 'RECUEIL', field:'recueilDoc', multiple: false},

  //PREVOYANCE
  PREVOYANCE_CERTIF: {code: 'PREVOYANCE_CERTIF', field:'certifPrevoyances', multiple: false},
  PREVOYANCE_CONDITION: {code: 'PREVOYANCE_CONDITION', field:'conditionPrevoyances', multiple: false},

  //AUTRE
  AUTRE_CIVIL: {code: 'AUTRE_CIVIL', field:'otherDocs', multiple: true},
  AUTRE_IMMO: {code: 'AUTRE_IMMO', field:'otherDocs', multiple: true},
  AUTRE_FINANCIER: {code: 'AUTRE_FINANCIER', field:'otherDocs', multiple: true},
  AUTRE_CREDIT: {code: 'AUTRE_CREDIT', field:'otherDocs', multiple: true},
  AUTRE_IMPOT: {code: 'AUTRE_IMPOT', field:'otherDocs', multiple: true},
  AUTRE_TESTAMENT: {code: 'AUTRE_TESTAMENT', field:'otherDocs', multiple: true},
  AUTRE_PREVOYANCE: {code: 'AUTRE_PREVOYANCE', field:'otherDocs', multiple: true},
  AUTRE_DONATION: {code: 'AUTRE_DONATION', field:'otherDocs', multiple: true},
  AUTRE_DIVERS: {code: 'AUTRE_DIVERS', field:'otherDocs', multiple: true},


  AUTRE_DOC: {code: 'AUTRE_DOC', field:'otherDocs', multiple: true},
};
