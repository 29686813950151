import {ID} from '@datorama/akita';

export interface Phone {
  id?: ID;
  label?: string;
  number: string;
  type: string;
  isContact: boolean;
  dialCode: string;
  countryCode: string;
}

export function createPhone(params: Partial<Phone>) {
  return {

  } as Phone;
}
