import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StepMasterStore, StepMasterState } from './step-master.store';

@Injectable({ providedIn: 'root' })
export class StepMasterQuery extends QueryEntity<StepMasterState> {

  constructor(protected override store: StepMasterStore) {
    super(store);
  }

}
