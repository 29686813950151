import {ID} from '@datorama/akita';

export interface Email {
  id?: ID;
  email: string;
  label?: string;
  type: string;
  isContact: boolean;
}

export function createEmail(params: Partial<Email>) {
  return {

  } as Email;
}
