import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import {
  ConfigurationQuery,
  ConfigurationService,
  SessionService,
  NaturalPersonService,
  LegalPersonService,
  LegalPersonStore,
  LegalPersonQuery,
  NaturalPersonStore,
  UserService,
  NaturalNaturalRelationTypeService,
  CompteRenduService,
  ProductService,
  FournisseurService,
  TaskService,
  StepMasterService,
  SseService,
  SessionQuery,
  NaturalPersonQuery,
  NavigationService,
  StepService,
  UiScreenStore,
  NotificationService,
  NotificationStore,
  TaskStore,
  StepStore,
  StepQuery,
  TranslationService,
  TranslatorService,
  EntityNotificationService,
  ObjectifService,
  BienDiversTypeService, UserRole
} from 'common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import * as moment from 'moment';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { combineQueries } from '@datorama/akita';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ViewChild } from '@angular/core';
import {Subscription, take} from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent{

  title = 'tim';
  logoutInactivityDuration: any;
  timout: ReturnType<typeof setTimeout>;
  adminEntityServices: any[] = [];
  clientEntityServices: any[] = [];
  isLoading = false;
  isLogged = false;
  loaderSubscribtion: Subscription;
  public displayHeaderByRoute = true;
  public displayToastByRoute = true;

  constructor(
    private translate: TranslateService,
    private navigation: NavigationService,
    private configurationService: ConfigurationService,
    private naturalNaturalRelationTypeService: NaturalNaturalRelationTypeService,
    private naturalPersonService: NaturalPersonService,
    private naturalPersonStore: NaturalPersonStore,
    private naturalPersonQuery: NaturalPersonQuery,
    private objectifService: ObjectifService,
    private legalPersonService: LegalPersonService,
    private legalPersonStore: LegalPersonStore,
    private legalPersonQuery: LegalPersonQuery,
    private compteRenduService: CompteRenduService,
    private taskService: TaskService,
    private stepMasterService: StepMasterService,
    private bienDiversTypeService: BienDiversTypeService,
    private productService: ProductService,
    private fournisseurService: FournisseurService,
    private userService: UserService,
    private sessionService: SessionService,
    private sseService: SseService,
    private configurationQuery: ConfigurationQuery,
    private sessionQuery: SessionQuery,
    private router: Router,
    private config: PrimeNGConfig,
    private stepMasters: StepMasterService,
    private stepService: StepService,
    private notificationService: NotificationService,
    private uiScreenStore: UiScreenStore,
    public bo: BreakpointObserver,
    private notificationStore: NotificationStore,
    private taskStore: TaskStore,
    private stepStore: StepStore,
    private stepQuery: StepQuery,
    private translationService: TranslationService,
    private translatorService: TranslatorService,
    private entityNotificationService: EntityNotificationService,
    private cdref: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    //workaround bug sur candeactivate qui casse le router history
    router.canceledNavigationResolution = 'computed';

    console.log('version: '+environment.version);

    this.entityNotificationService.listen();

    this.config.setTranslation({
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
      monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Decembre' ],
      monthNamesShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Au', 'Sep', 'Oct', 'Nov', 'Dec' ],
      weekHeader: ''
    });

    //GESTION AFFICHAGE HEADER & TOAST
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        this.displayHeaderByRoute = !data.url.startsWith('/login') &&
          !data.url.startsWith('/activate-account') &&
          !data.url.startsWith('/password-forgotten') &&
          !data.url.startsWith('/change-password') &&
          !data.url.startsWith('/welcome') &&
          !data.url.startsWith('/recueil');

        this.displayToastByRoute = !data.url.startsWith('/recueil');
      }
    });


    //INACTIVITY LOGOUT
    this.configurationService.get().subscribe();
    this.configurationQuery.selectLogoutInactivityDuration$.subscribe(logoutInactivityDuration => {
      if (logoutInactivityDuration && logoutInactivityDuration[0]) {
        /* in database, the LOGOUT_INACTIVITY_DURATION must respect number space unit format. units available are : 'seconds' 'minutes' 'hours' 'days' 'weeks' 'months' 'years' */
        // @ts-ignore
        const [value, unit] = logoutInactivityDuration[0].value.split(' ');
        this.logoutInactivityDuration = moment.duration(value, unit).asMilliseconds();
        if (this.logoutInactivityDuration === 0) {
          /* Catch database code errors */
          console.error('Configuration LOGOUT_INACTIVITY_DURATION code in database is WRONG, the logout delay is set to 45 minutes by default');
          this.logoutInactivityDuration = moment.duration(45, 'minutes').asMilliseconds();
        }
        /* launch the logout timer with configuration ms duration */
        this.renewLogoutTimer(this.logoutInactivityDuration);
        /* User activity tracking */
        document.body.addEventListener('click', (e) => {
          this.renewLogoutTimer(this.logoutInactivityDuration);
        });
      }
    });

    // ABONEMENT TEMPS REEL

    this.sseService.getServerSentEvent(environment.mercureUrl+'?topic=/notifications/').subscribe(msg => {
      const notification = JSON.parse(msg.data);
      if(notification['@id']) {
        // remove notification from the store
        const id = Number(notification['@id'].substring(notification['@id']?.lastIndexOf('/') + 1, notification['@id'].length));
        this.notificationStore.remove(id);
      } else {
        // Add new notification to the store.
        this.notificationStore.add(notification);
      }
      this.cdref.markForCheck();
    });

    this.sseService.getServerSentEvent(environment.mercureUrl+'?topic=/steps/').subscribe(msg => {
      const step = JSON.parse(msg.data);
      // Add new step to the store.
      this.stepStore.upsert(step.id,step);
      this.cdref.markForCheck();
    });

    this.sseService.getServerSentEvent(environment.mercureUrl + '?topic=/tasks/').subscribe(msg => {
      // Type task as Task create an error when add in store.
      const task = JSON.parse(msg.data);

      // Add new task to the store
      this.taskStore.upsert(task.id, task);
      this.cdref.markForCheck();
    });


    // TOUTES LES ENTITES A CHARGER POUR ADMIN
    this.adminEntityServices = [
      naturalPersonService,
      legalPersonService,
      naturalNaturalRelationTypeService,
      compteRenduService,
      stepMasters,
      productService,
      fournisseurService,
      stepMasterService,
      notificationService,
      objectifService,
      bienDiversTypeService
    ];

    // TOUTES LES ENTITES A CHARGER POUR CLIENT
    this.clientEntityServices = [
      objectifService,
      bienDiversTypeService,
      naturalNaturalRelationTypeService
    ];

    this.sessionQuery.isLoggedIn$.subscribe((isLogged: boolean) => {
      this.translatorService.init();
      this.isLogged = isLogged;
      if(isLogged){
        if(this.sessionQuery.getValue().roles.every((role: string) => role === UserRole.ROLE_CLIENT) && this.sessionQuery.getValue().id){
          //ACCES CLIENT
          this.subscribeEntities(this.clientEntityServices);
          this.cdref.markForCheck();
        } else {
          //ACCES ADMIN
          this.loaderSubscribtion = combineQueries([this.naturalPersonQuery.selectLoading(), this.legalPersonQuery.selectLoading()]).pipe(untilDestroyed(this)).subscribe(
            ([naturalPersonLoading, legalPersonLoading]) => {
              this.isLoading = !(!naturalPersonLoading && !legalPersonLoading);
              this.cdref.markForCheck();
            }
          );
          this.subscribeEntities(this.adminEntityServices);
          this.cdref.markForCheck();
          //RECUPERATION DE TOUTES LES TACHES INTERNES ET CLIENT NOT DONE
          /* eslint-disable */
          this.stepService.get({
            params: {'stepGroups.tasks.status[]': ['PLANNED', 'TODO', 'DOING']}, upsert: true
          }).subscribe(() => this.cdref.markForCheck());

          this.taskService.get({
            params: {'status[]': ['PLANNED', 'TODO', 'DOING']}, upsert: true
          }).subscribe(() => this.cdref.markForCheck());

          //RECUPERATION DE TOUTES LES TACHES INTERNES ET CLIENT DONE DEPUIS LUNDI
          const previousMonday = moment().startOf('isoWeek').format('DD-MM-YYYY');

          this.stepService.get({
            params: {'stepGroups.tasks.status[]': 'DONE', 'stepGroups.tasks.closingDate[strictly_after]': previousMonday}, upsert: true
          }).subscribe(() => this.cdref.markForCheck());

          this.taskService.get({
            params: {'status': 'DONE', 'closingDate[strictly_after]': previousMonday}, upsert: true
          }).subscribe(() => this.cdref.markForCheck());

          this.userService.get({
            params: {'roles[]': [UserRole.ROLE_ADMIN, UserRole.ROLE_RESPONSABLE_POLE, UserRole.ROLE_COLLABORATEUR, UserRole.ROLE_DIRIGEANT]}, upsert: true
          }).subscribe(() => this.cdref.markForCheck());
          /* eslint-enable */
        }

      } else {
        // this.router.navigate(['/login'])
        if(this.loaderSubscribtion){
          this.loaderSubscribtion.unsubscribe();
        }
        this.isLoading = false;
      }
    });

    // GESTION RESPONSIVE
    this.bo.observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((bs: BreakpointState) => {
        if(bs.matches) {
          // console.log('SCREEN: MOBILE');
          this.uiScreenStore.update({screen: 'mobile'});
        } else {
          // console.log('SCREEN: DESKTOP');
          this.uiScreenStore.update({screen: 'desktop'});
        }
        this.cdref.markForCheck();
      });
  }

  // SCROLL TOP
  // app component contient un scrollPanel. De ce fait, les composants enfants n'ont pas directement acces à la ref du scrollPanel pour effectuer un scrollTop
  // quand le composant scrollPanel est pret, on envoi la ref au navigation service qui pourra alors effectuer un scrollTop au besoin.
  //
  // explication du set content():
  // le scrollPanel est dans un ngIf donc pas toujours dispo
  // en utilisant un setter, viewchild vient lui meme, quand il est dispo, envoyer sa ref au service qui en a besoin.
  @ViewChild('mainPanel', { static: false }) set ref(mainPanel: ElementRef) {
    if(mainPanel) {
      this.navigation.setScrollPanelRef(mainPanel);
    }
  }

  subscribeEntities(entityServices: any[] ){
    entityServices.map(entityService => {
      entityService.get({upsert: true}).subscribe();
    });
  }

  renewLogoutTimer(logoutInactivityDuration: number) {
    clearTimeout(this.timout);
    this.timout = setTimeout(() => {
      this.sessionService.logout();
      this.router.navigate(['/login']);
      console.log('LOGOUT FROM INACTIVITY TIMER');
    }, logoutInactivityDuration);
  }

}
