import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  StepMaster,
  StepMasterQuery,
  UiScreenQuery
} from 'common';


@UntilDestroy()
@Component({
  selector: 'app-page-steps-master-list',
  templateUrl: './page-steps-master-list.component.html',
  styleUrls: ['./page-steps-master-list.component.scss']
})
export class PageStepsMasterListComponent implements OnInit {

  stepMasterList: StepMaster[] = [];
  loading = false;

  constructor(
    private stepMasterQuery: StepMasterQuery,
    private router: Router,
    public uiScreenQuery: UiScreenQuery
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.stepMasterQuery.selectLoading().subscribe(loading => {
      this.loading = false;
    });
    this.stepMasterQuery.selectAll().subscribe(stepMaster => {
      this.stepMasterList = stepMaster;
    });
  }

  editStepMaster(stepMaster: StepMaster) {
    this.router.navigate(['/steps-master-edit', stepMaster.id]);
  }
}
