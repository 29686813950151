import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {
  HttpResponseMessageType,
  NaturalPerson,
  NaturalPersonQuery,
  NaturalPersonService,
  TranslatorService, UiScreenQuery, UserService
} from 'common';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';


@UntilDestroy()
@Component({
  selector: 'app-page-recueil-conjoint',
  templateUrl: './page-recueil-conjoint.component.html',
  styleUrls: ['./page-recueil-conjoint.component.scss']
})
export class PageRecueilConjointComponent implements OnInit {
  client: NaturalPerson;
  conjoint: NaturalPerson;
  token: string;
  userEmail: string;
  conjointId: number;
  loading = false;
  error = false;
  errorMsg = 'Votre lien n\'est plus  valide, veuillez contacter votre conseiller EMD Patrimoine.';
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  submitLoading = false;
  initialValues =
    {
      infoCivile: {
        civility: '',
        lastName: '',
        firstName: '',
        birthName: null,
        birthDate: null,
        birthCity: null,
        birthState: null,
        birthCountry: null,
        nationality: null,
        otherNationality: null,
        mesureProtection: null,
        handicap: false,
        dead: false,
        deathDate: null,
      },
      situationPro: {
        situationPro: null,
        currentJob: null,
        jobCompany: null,
        revenusAnnuel: null,
        rentesAnnuelle: null,
      },
      celebre: {
        politiquementExpose: false,
        prochePolitiquementExpose: false,
      },
      etranger: {
        usPerson: false,
        vecuEtrange: false,
        vecuEtrangeDixAns: false,
      },
      contact: {
        phones: [],
        emails: [],
        addresses: [],
        rentPrincipalResidence: false,
        loyerAnnuel: null,
      },
      comment: '',
    };

  constructor(private activatedRoute: ActivatedRoute, private naturalPersonQuery: NaturalPersonQuery,
              private translatorService: TranslatorService, private naturalPersonService: NaturalPersonService,
              private changeDetector: ChangeDetectorRef, @Inject('config') private config: any,
              private httpClient: HttpClient, private userService: UserService,
              private formBuilder: UntypedFormBuilder,
              private messageService: MessageService,
              private router: Router,
              public uiScreenQuery: UiScreenQuery,
  ) {
    this.form = this.formBuilder.group(this.initialValues);
  }

  get value(): any {
    return this.form.value;
  }

  ngOnInit(): void {
    this.loading = true;
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.token = queryParams['token'];
      this.userEmail = queryParams['email'];
      this.activatedRoute.params.subscribe((params) => {
        this.conjointId = params['conjointId'];
      });
      this.naturalPersonService.checkRecueilConjointLinkToken(this.token, this.userEmail).subscribe({
        next: (results) => {
          this.error = false;
            this.naturalPersonService.get(this.conjointId,{
              params: {
                token : this.token,
                email : this.userEmail
              }
            }).subscribe((conjointResult: any) => {
              this.conjoint = conjointResult;
              this.form.reset({
                infoCivile: {
                  civility: this.conjoint?.civility,
                  lastName: this.conjoint?.lastName,
                  firstName: this.conjoint?.firstName,
                  birthName: null,
                  birthDate: null,
                  birthCity: null,
                  birthState: null,
                  birthCountry: null,
                  nationality: null,
                  otherNationality: null,
                  mesureProtection: null,
                  handicap: false,
                  dead: false,
                  deathDate: null,
                },
                situationPro: {
                  situationPro: null,
                  currentJob: null,
                  jobCompany: null,
                  revenusAnnuel: null,
                  rentesAnnuelle: null,
                },
                celebre: {
                  politiquementExpose: false,
                  prochePolitiquementExpose: false,
                },
                etranger: {
                  usPerson: false,
                  vecuEtrange: false,
                  vecuEtrangeDixAns: false,
                },
                contact: {
                  phones: [],
                  emails: this.conjoint.emails,
                  addresses: [],
                  rentPrincipalResidence: false,
                  loyerAnnuel: null,
                },
                comment: '',
              });
              this.loading = false;
              this.changeDetector.markForCheck();
            });
        },
        error: (val: any) => {
          this.loading = false;
          if (val.token_validity === HttpResponseMessageType.NEW_RECUEIL_CONJOINT_LINK_TOKEN_EXPIRED) {
            this.errorMsg = 'Votre session est expirée, veuillez contacter votre conseiller EMD Patrimoine.';
            this.error = true;
          } else {
            this.error = true;
            this.errorMsg = 'Une erreur est survenue, veuillez contacter votre conseiller EMD Patrimoine.';
          }
          this.changeDetector.markForCheck();
        }
      });

    });
    this.changeDetector.markForCheck();
  }

  translate(word: string | undefined): string | void {
    if (word) {
      return this.translatorService.instant(word);
    }
  }

  submitForm() {
    if (this.form.valid) {
      this.submitLoading = true;
      const conjointToUpdate: Partial<NaturalPerson> = {
        id: this.conjoint.id,
        civility: this.form.get('infoCivile')?.value.civility,
        lastName: this.form.get('infoCivile')?.value.lastName ? this.form.get('infoCivile')?.value.lastName.toUpperCase() : null,
        firstName: this.form.get('infoCivile')?.value.firstName,
        birthName: this.form.get('infoCivile')?.value.birthName,
        birthDate: this.form.get('infoCivile')?.value.birthDate,
        birthCity: this.form.get('infoCivile')?.value.birthCity,
        birthState: this.form.get('infoCivile')?.value.birthState,
        birthCountry: this.form.get('infoCivile')?.value.birthCountry,
        nationality: this.form.get('infoCivile')?.value.nationality,
        otherNationality: this.form.get('infoCivile')?.value.otherNationality,
        mesureProtection: this.form.get('infoCivile')?.value.mesureProtection,
        handicap: this.form.get('infoCivile')?.value.handicap,
        dead: this.form.get('infoCivile')?.value.dead,
        deathDate: this.form.get('infoCivile')?.value.deathDate,
        situationPro: this.form.get('situationPro')?.value.situationPro,
        currentJob: this.form.get('situationPro')?.value.currentJob,
        jobCompany: this.form.get('situationPro')?.value.jobCompany,
        revenusAnnuel: this.form.get('situationPro')?.value.revenusAnnuel,
        rentesAnnuelle: this.form.get('situationPro')?.value.rentesAnnuelle,
        politiquementExpose: this.form.get('celebre')?.value.politiquementExpose,
        prochePolitiquementExpose: this.form.get('celebre')?.value.prochePolitiquementExpose,
        usPerson: this.form.get('etranger')?.value.usPerson,
        vecuEtrange: this.form.get('etranger')?.value.vecuEtrange,
        vecuEtrangeDixAns: this.form.get('etranger')?.value.vecuEtrangeDixAns,
        phones: this.form.get('contact')?.value.phones,
        emails: this.form.get('contact')?.value.emails,
        addresses: this.form.get('contact')?.value.addresses,
        rentPrincipalResidence: this.form.get('contact')?.value.rentPrincipalResidence,
        loyerAnnuel: this.form.get('contact')?.value.loyerAnnuel,
        generalComment: this.form.get('comment')?.value,
      };

      this.naturalPersonService.update(this.conjointId, conjointToUpdate, {
        params: {
          token : this.token,
          email : this.userEmail
        }
      }).subscribe({
        next: (val) => {
          this.submitLoading=false;
          this.messageService.clear();
          this.messageService.add({
            severity: 'success',
            summary: 'Vos informations ont bien été enregistrées',
            life: 10000
          });
          this.router.navigate(['/recueil/recueil-final'],{ queryParams: { person: 'conjoint' } });
        }
      });
    }
  }

}
