import { ID } from '@datorama/akita';

export interface User {
  id?: ID;
  firstName: string;
  lastName: string;
  email: string;
  poles: string[];
  roles: string[];
  isActive: boolean;
}

export function createUser(params: Partial<User>) {
  return {

  } as User;
}
