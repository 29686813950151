<!-- MOBILE -->
<ng-container *ngIf="(uiScreenQuery.isMobile$ | async); else desktop;">
  <!-- HEADER NOUVELLE TACHE -->
  <ng-template [ngIf]="!isFormEditing"><h2 class="mt-5 text-center">Nouvelle tâche suivi client</h2></ng-template>
  <!-- HEADER EDITION TACHE -->
  <ng-template [ngIf]="isFormReady && isFormEditing && stepEditing.name">
    <!-- SECTION ÉTAPE EN COURS -->
    <div class="flex align-items-center mt-5">
      <!-- INDICATEUR TEMPS RESTANT -->
      <div
        *ngIf="['WARNING', 'EXCEED'].includes(taskRemainingTime)"
        [ngClass]="{'bg-warning': taskRemainingTime === 'WARNING', 'bg-danger': taskRemainingTime === 'EXCEED'}"
        class="flex-shrink-0 indicator text-white text-2xl font-semibold flex justify-content-center align-items-center mr-4">
        <svg width="20" height="20" viewBox="0 0 512 512">
          <path
            d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
            fill="#ffffff" data-original="#000000" class=""/>
          <path
            d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
            fill="#ffffff" data-original="#000000" class=""/>
        </svg>
      </div>
      <!-- NOM DE LA TÂCHE -->
      <h2 class="my-0">{{getTaskName(groupIndex, taskIndex)?.value}}</h2>
    </div>
    <!-- NOM DE L'ETAPE -->
    <p-tag styleClass="step-tag mt-3 text-base">
      {{stepEditing.name}}
    </p-tag>
  </ng-template>
  <!-- FORM -->
  <form [formGroup]="stepForm" *ngIf="isFormReady && isEditable" class="mt-4">
    <ng-container formArrayName="stepGroups">
      <ng-container [formGroupName]="groupIndex">
        <ng-container formArrayName="tasks">
          <ng-container [formGroupName]="taskIndex">
            <p-accordion>
              <!-- ACCORDION DETAILS TACHE -->
              <p-accordionTab
                [ngClass]="{'accordion-error' : getTaskName(groupIndex, taskIndex)?.touched && getTaskName(groupIndex, taskIndex)?.errors ||
                getTaskRelatedPeople(0, 0)?.touched && getTaskRelatedPeople(0, 0)?.status === 'INVALID'}">
                <ng-template pTemplate="header"><p class="text-primary">Détails de la tâche</p></ng-template>
                <ng-template pTemplate="content">
                  <!-- SI CREATION : CHAMP NOM DE LA TÂCHE -->
                  <div *ngIf="!isFormEditing">
                    <div class="field col-12 p-0">
                      <label for="name-input">Tâche</label>
                      <input #autofocusInput formControlName="name" id="name-input" type="text" size="30" pInputText
                             class="inputfield w-full">
                      <lib-field-error
                        *ngIf="getTaskName(groupIndex, taskIndex)?.touched && getTaskName(groupIndex, taskIndex)?.errors"
                        [fieldError]="getTaskName(groupIndex, taskIndex)?.errors"></lib-field-error>
                    </div>
                  </div>
                  <!-- CONTACT(S) CONCERNE(S) -->
                  <div class="col-12 p-0 mt-5">
                    <div class="field p-0" formArrayName="relatedPeople">
                      <label>Contact(s) concerné(s)</label>
                      <div
                        *ngFor="let client of getTaskRelatedPeople(this.groupIndex, this.taskIndex).controls; let index = index"
                        class="flex">
                        <div class="flex w-full p-fluid">
                          <p-autoComplete
                            inputStyleClass="form-control"
                            class="w-full"
                            formControlName="{{ index }}"
                            [forceSelection]="true"
                            [suggestions]="clientSearchService.searchBarResults"
                            placeholder="Rechercher un client"
                            (completeMethod)="clientSearchService.searchClient($event)"
                            field="label"
                            [minLength]="3">
                          </p-autoComplete>
                          <lib-field-error
                            *ngIf="getTaskRelatedPeople(this.groupIndex, this.taskIndex)?.touched && getTaskRelatedPeople(0, 0)?.errors"
                            [fieldError]="getTaskRelatedPeople(0, 0)?.errors"></lib-field-error>
                          <ng-container
                            *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
                          <ng-template #addClientButton>
                            <button pButton type="button" icon="pi pi-plus"
                                    class="flex-shrink-0 border-noround border-400 mb-2 ml-2"
                                    (click)="addRelatedPeople()"></button>
                          </ng-template>
                          <ng-template #removeClientButton>
                            <button pButton type="button" icon="pi pi-times"
                                    class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2"
                                    (click)="removeRelatedPeople(index)"></button>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- DATE DE DEBUT -->
                  <div class="field col-12 p-0 mt-5">
                    <label for="start-date-input">Date de début</label>
                    <p-calendar styleClass="w-full"
                                appendTo="body"
                                placeholder="{{ startDateDefaultValue | date: 'dd/MM/yyyy'}}"
                                id="start-date-input"
                                (onSelect)="onStartDateSelect($event)"
                                [minDate]="today"
                                [firstDayOfWeek]=1
                                [disabledDays]="[0,6]"
                                formControlName="startDate"
                                dateFormat="dd/mm/yy"
                                [showIcon]="true">
                    </p-calendar>
                    <lib-field-error
                      *ngIf="getTaskStartDate(groupIndex, taskIndex)?.touched && getTaskStartDate(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskStartDate(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                  <!-- DATE DE FIN -->
                  <div class="field col-12 p-0 mt-5">
                    <label for="deadline-date-input">Date d'échéance</label>
                    <p-calendar styleClass="w-full" appendTo="body"
                                placeholder="{{ deadLineDefaultValue | date: 'dd/MM/yyyy'}}"
                                id="deadline-date-input"
                                [firstDayOfWeek]=1
                                [disabledDays]="[0,6]"
                                [minDate]="today"
                                formControlName="deadlineDate" dateFormat="dd/mm/yy" [showIcon]="true"
                                [minDate]="getTaskStartDate(groupIndex, taskIndex)?.value">
                    </p-calendar>
                    <lib-field-error
                      *ngIf="getTaskDeadlineDate(groupIndex, taskIndex)?.touched && getTaskDeadlineDate(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskDeadlineDate(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                  <!-- CHARGE PREVUE -->
                  <div class="field col-12 p-0 mt-5">
                    <label for="planned-work-input">Charge prévue</label>
                    <p-inputNumber formControlName="plannedWorkload"
                                   [showButtons]="true"
                                   buttonLayout="horizontal"
                                   id="planned-work-input"
                                   spinnerMode="horizontal"
                                   [min]="0"
                                   [step]="15"
                                   [disabled]="isFormEditing"
                                   decrementButtonClass="p-button" incrementButtonClass="p-button"
                                   incrementButtonIcon="pi pi-plus"
                                   decrementButtonIcon="pi pi-minus" suffix=" min"
                                   class="w-full"
                                   styleClass="w-full"
                                   inputStyleClass="w-full min-w-0">
                    </p-inputNumber>
                    <lib-field-error
                      *ngIf="getTaskPlannedWorkload(groupIndex, taskIndex)?.touched && getTaskPlannedWorkload(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskPlannedWorkload(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                </ng-template>
              </p-accordionTab>
              <!-- ACCORDION INFO CABINET -->
              <p-accordionTab>
                <ng-template pTemplate="header"><p class="text-primary">Informations cabinet</p></ng-template>
                <ng-template pTemplate="content">
                  <!-- POLE CONCERNE -->
                  <div class="field col-12 p-0">
                    <label for="poles-input">Pôle concerné</label>
                    <p-dropdown styleClass="w-full" formControlName="pole" id="poles-input"
                                [options]="polesOptions" optionLabel="label" optionValue="value" placeholder="...">
                    </p-dropdown>
                    <lib-field-error
                      *ngIf="getTaskPoles(groupIndex, taskIndex)?.touched && getTaskPoles(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskPoles(groupIndex, taskIndex)?.errors"></lib-field-error>
                  </div>
                  <!-- COLLABOREUR ASSIGNE -->
                  <div class="field col-12 p-0 mt-5">
                    <label for="collaborator-input">Collaborateur assigné</label>
                    <p-dropdown styleClass="w-full" formControlName="collaborator" id="collaborator-input"
                                [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                placeholder="..."
                                appendTo="body">
                    </p-dropdown>
                  </div>
                  <!-- DONNEUR D'ORDRE -->
                  <div class="field col-12 p-0 mt-5">
                    <label for="contractor-input">Donneur d'ordre</label>
                    <p-dropdown appendTo="body" styleClass="w-full" formControlName="contractor" id="contractor-input"
                                [options]="contractorOptions" optionLabel="label" optionValue="value"
                                placeholder="..."
                                [autoDisplayFirst]="false">
                    </p-dropdown>
                    <lib-field-error
                      *ngIf="getTaskContractor(groupIndex, taskIndex)?.touched && getTaskContractor(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskContractor(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                </ng-template>
              </p-accordionTab>
              <!-- ACCORDION COMMENTAIRE -->
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <ng-template [ngIf]="!isFormEditing">
                    <p class="text-primary">Ajoutez un commentaire</p>
                  </ng-template>
                  <ng-template [ngIf]="isFormEditing">
                    <p class="flex align-items-center text-primary">Commentaire(s)
                      <ng-container *ngIf="taskHasBlockedComment">
                        <i class="pi pi-lock red-lock ml-2"></i>
                      </ng-container>
                    </p>
                  </ng-template>
                </ng-template>
                <ng-template pTemplate="content">
                  <ng-template [ngIf]="!isFormEditing">
                    <!-- COMMENTAIRE INPUT CREATION-->
                    <div class="field col-12 p-0" formArrayName="comments">
                      <p>Régidez votre commentaire ici :</p>
                      <ng-container [formGroupName]="0">
                        <p-editor id="comments-input" formControlName="content" styleClass="w-full"
                                  [style]="{'height':'130px'}">
                          <ng-template pTemplate="header">
                            <span class="ql-format-group"></span>
                          </ng-template>
                        </p-editor>
                      </ng-container>
                    </div>
                  </ng-template>
                  <ng-template [ngIf]="isFormEditing && taskEditing.comments?.length">
                    <!-- LISTE COMMENTAIRES -->
                    <div class="field col-12 p-0">
                      <div class="mb-4 col-12 surface-100 p-3"
                           *ngFor="let comment of taskEditing.comments | sortBydate : 'creationDate'">
                        <!-- COMMENTAIRE HEADER -->
                        <div class="pb-3 mb-3 border-bottom align-items-center">
                          <p class="m-0 text-right">Le {{comment.creationDate | date: 'dd/MM/yyyy'}}</p>
                          <div *ngIf="comment.blocked || comment.resolved"
                               class="flex flex-row mt-3 justify-content-end">
                            <div *ngIf="comment.resolved">
                              <p-chip icon="pi pi-lock-open" label="Point débloqué"
                                      styleClass="text-xs font-bold bg-success text-white px-3 custom-chip"></p-chip>
                            </div>
                            <div *ngIf="comment.blocked && !comment.resolved">
                              <p-chip icon="pi pi-lock" label="Point bloquant"
                                      styleClass="text-xs font-bold bg-danger text-white px-3 custom-chip"></p-chip>
                            </div>
                            <div *ngIf="comment.blocked && !comment.resolved">
                              <p-button
                                type="submit"
                                icon="pi pi-unlock"
                                styleClass="ml-3 p-button-success p-button-raised p-button-text bg-white py-1"
                                (click)="unlockComment(comment.id)">
                              </p-button>
                            </div>
                          </div>
                        </div>
                        <!-- COMMENTAIRE BODY -->
                        <div class="grid">
                          <!-- DE -->
                          <div class="flex flex-row col-12">
                            <p class="w-4rem mt-1">De :</p>
                            <div>
                              <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle"
                                     value="{{ comment.author.lastName }} {{ comment.author.firstName }}"
                                     [rounded]="true">
                              </p-tag>
                            </div>
                          </div>
                          <!-- POUR -->
                          <div *ngIf="comment.assignedUsers.length > 0" class="flex flex-row col-12">
                            <p class="w-4rem mt-1">Pour :</p>
                            <div class="flex flex-column">
                              <div *ngFor="let assignedUser of comment.assignedUsers">
                                <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle mb-2"
                                       value="{{ assignedUser.lastName }} {{ assignedUser.firstName }}"
                                       [rounded]="true">
                                </p-tag>
                              </div>
                            </div>
                          </div>
                          <p class="text-from-editor col-12" [innerHtml]="comment.content"></p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <ng-container *ngIf="isFormEditing">
                    <!-- INPUT COMMENTAIRE EDITION-->
                    <div class="mt-5 field" formArrayName="comments">
                      <!-- ici on parcours les comments alors qu'on a besoin que du dernier, mais un bug ne rafraichi pas les data d'un formGroupName qui change dans un formArrayName-->
                      <!-- de cette maniere, le ngIf dessous "toogle" le dom -->
                      <!-- https://github.com/angular/angular/issues/23016-->
                      <ng-container *ngFor="let item of getTaskComments(groupIndex, taskIndex).controls; index as i">
                        <ng-container [formGroupName]="i"
                                      *ngIf="getTaskComments(groupIndex, taskIndex).controls.length - 1 === i">
                          <div class="mt-5">
                            <!-- COLLABORATEUR(S) -->
                            <div>
                              <p>Collaborateur(s) concerné(s)</p>
                              <div
                                *ngFor="let commentAssignedUser of getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.controls; let index = index"
                                class="flex w-full p-fluid">
                                <ng-container class="col-12" formArrayName="assignedUsers">
                                  <p-dropdown class="w-full" formControlName="{{ index }}"
                                              [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                              placeholder="..." appendTo="body">
                                  </p-dropdown>
                                  <ng-container
                                    *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
                                  <ng-template #addClientButton>
                                    <button pButton type="button" icon="pi pi-plus"
                                            class="flex-shrink-0 border-noround border-400 mb-2 ml-2"
                                            (click)="addCommentAssignedUser()"></button>
                                  </ng-template>
                                  <ng-template #removeClientButton>
                                    <button pButton type="button" icon="pi pi-times"
                                            class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2"
                                            (click)="removeCommentAssignedUser(index)"></button>
                                  </ng-template>
                                </ng-container>
                              </div>
                              <lib-field-error
                                *ngIf="getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.touched && getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.errors"
                                [fieldError]="getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.errors"></lib-field-error>
                            </div>
                            <!-- BLOCAGE -->
                            <div class="flex flex-column align-items-start mt-4">
                              <div class="flex align-items-center mt-2">
                                <p-inputSwitch formControlName="blocked" id="is-blocking-input" class="mr-3"
                                               (onChange)="blockingCommentWarning()">
                                </p-inputSwitch>
                                <label>Point de blocage</label>
                              </div>
                              <p *ngIf="blockingCommentWarningState">Cette action va envoyer une notification "Point
                                bloquant" au(x) collaborateur(s) assigné(s).</p>
                            </div>
                          </div>
                          <!-- WYSIWYG -->
                          <div class="mt-5">
                            <p-editor id="comments-edit-input" formControlName="content" [style]="{'height':'130px'}">
                              <ng-template pTemplate="header">
                                <span class="ql-format-group"></span>
                              </ng-template>
                            </p-editor>
                          </div>
                          <lib-field-error
                            *ngIf="getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.touched && getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.errors"
                            [fieldError]="getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.errors"></lib-field-error>
                        </ng-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-template>
              </p-accordionTab>
              <!-- ACCORDION PRIORISATION -->
              <p-accordionTab>
                <ng-template pTemplate="header"><p class="text-primary">Priorisation</p></ng-template>
                <ng-template pTemplate="content">
                  <!-- CHAMP PRIORITÉ -->
                  <div class="field col-12 flex align-items-center p-0">
                    <p-inputSwitch class="switch-danger" formControlName="isPriority"
                                   id="is-priority-input">
                    </p-inputSwitch>
                    <label for="is-priority-input"
                           class="ml-2"
                           [ngClass]="{'text-400' : !getTaskIsPriority(groupIndex, taskIndex)?.value}">{{ getTaskIsPriority(groupIndex, taskIndex)?.value ? "Prioritaire" : "Non prioritaire" }}
                    </label>
                  </div>
                  <!-- IMPORTANCE -->
                  <div class="field col-12 p-0 mt-5">
                    <label>Importance</label>
                    <app-importance-input radioName="importance"
                                          [formControl]="$any(getTaskImportance(groupIndex, taskIndex))">
                    </app-importance-input>
                    <lib-field-error
                      *ngIf="getTaskImportance(groupIndex, taskIndex)?.touched && getTaskImportance(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskImportance(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                </ng-template>
              </p-accordionTab>
              <!-- ACCORDION AVANCEMENT -->
              <ng-template [ngIf]="isFormEditing">
                <p-accordionTab>
                  <ng-template pTemplate="header"><p class="text-primary">Avancement</p></ng-template>
                  <ng-template pTemplate="content">
                    <!-- STATUT DE LA TÂCHE -->
                    <div class="field col-12 p-0">
                      <div *ngFor="let status of statusOptions" class="mb-3">
                        <p-radioButton value="{{status.value}}"
                                       label="{{status.label}}"
                                       formControlName="status"
                                       [disabled]="status.disabled || (status.value === 'DONE' && taskHasBlockedComment)"
                                       (onClick)="onStatusClick(status.value)"
                        ></p-radioButton>
                        <ng-template [ngIf]="status.value === 'DONE' && taskHasBlockedComment">
                          <p class="ng-invalid">Le commentaire bloquant empêche la clôture de la tâche</p>
                        </ng-template>
                      </div>
                    </div>
                    <p class="mt-5 mb-2 text-primary font-semibold pl-0">Avant de clôturer...</p>
                    <div class="divider bg-primary"></div>
                    <!-- CHARGE REELLE -->
                    <div class="field col-12 p-0 mt-5">
                      <label for="real-work-input">Charge réelle</label>
                      <p-inputNumber formControlName="realWorkload"
                                     id="real-work-input"
                                     spinnerMode="horizontal"
                                     [min]="0"
                                     [step]="15"
                                     [showButtons]="true"
                                     buttonLayout="horizontal"
                                     decrementButtonClass="p-button"
                                     incrementButtonClass="p-button"
                                     incrementButtonIcon="pi pi-plus"
                                     decrementButtonIcon="pi pi-minus"
                                     suffix=" min"
                                     class="w-full"
                                     styleClass="w-full"
                                     inputStyleClass="w-full min-w-0">
                      </p-inputNumber>
                      <lib-field-error
                        *ngIf="getTaskRealWorkload(groupIndex, taskIndex)?.touched && getTaskRealWorkload(groupIndex, taskIndex)?.errors"
                        [fieldError]="getTaskRealWorkload(groupIndex, taskIndex)?.errors">
                      </lib-field-error>
                    </div>
                    <!-- DATE DE CLOTURE -->
                    <div class="field col-12 p-0 mt-5" [hidden]="!displayCloseDate">
                      <div class="field flex flex-column">
                        <label for="closing-date-input">Date de clôture</label>
                        <p-calendar appendTo="body"
                                    formControlName="closingDate"
                                    id="closing-date-input"
                                    styleClass="w-full"
                                    dateFormat="dd/mm/yy"
                                    [showIcon]="true"
                                    [firstDayOfWeek]=1
                                    [disabled]="true"
                                    [disabledDays]="[0,6]"
                                    [minDate]="getTaskStartDate(groupIndex, taskIndex)?.value"
                        >
                        </p-calendar>
                        <lib-field-error
                          *ngIf="getTaskClosingDate(groupIndex, taskIndex)?.touched && getTaskClosingDate(groupIndex, taskIndex)?.errors"
                          [fieldError]="getTaskClosingDate(groupIndex, taskIndex)?.errors">
                        </lib-field-error>
                      </div>
                    </div>
                  </ng-template>
                </p-accordionTab>
              </ng-template>
            </p-accordion>
            <!-- BOUTONS ACTION -->
            <div>
              <div class="col flex justify-content-between mt-5">
                <!-- BOUTONS ACTION ANNULER -->
                <p-button label="Annuler" icon="pi pi-times"
                          styleClass="p-button-danger p-button-raised p-button-text"
                          (click)="formCancel()">
                </p-button>
                <!-- BOUTONS ACTION SAUVEGARDER -->
                <p-button
                  type="submit"
                  label="{{getTaskStatus(groupIndex,taskIndex)?.value === rawTaskStatus.DONE ? 'Clore la tâche' : 'Enregistrer'}}"
                  icon="pi pi-check"
                  styleClass="ml-3 p-button-success p-button-raised" (click)="formSubmit()">
                </p-button>
              </div>
              <!-- BOUTONS ACTION SUPPRIMER -->
              <ng-template [ngIf]="isFormEditing && leaderOrPoleManager">
                <p class="pt-4 text-center text-500 underline" (click)="formDelete()">Supprimer la tâche</p>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
  <!-- SI TÂCHE TERMINÉE : VISUALISATION DE LA TÂCHE  -->
  <div *ngIf="!isEditable">
    <h2 class="mt-5">{{taskEditing.name}}</h2>
    <div class="grid">
      <div class="grid col-6 mt-5">
        <div class="field col-12">
          <label for="start-date-done">Date de début</label>
          <div id="start-date-done" class="font-bold">{{taskEditing.startDate | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="field col-12">
          <label for="deadeline-date-done">Date de d'échéance</label>
          <div id="deadeline-date-done" class="font-bold">{{taskEditing.deadlineDate | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="field col-12">
          <label for="closing-date-done">Date de clôture</label>
          <div id="closing-date-done" class="font-bold">{{taskEditing.closingDate | date: 'dd/MM/yyyy' }}</div>
        </div>
        <div class="field col-12">
          <label for="real-workload-done">Charge réelle</label>
          <div id="real-workload-done" class="font-bold">{{taskEditing.realWorkload}} min</div>
        </div>
      </div>
      <div class="grid col-6 mt-5">
        <div class="field col-12">
          <label for="pole-done">Pôle concerné</label>
          <div id="pole-done" class="font-bold">{{translatorService.getTranslation(taskEditing.pole) | async}}</div>
        </div>
        <div class="field col-12" *ngIf="taskEditing.collaborator">
          <label class="w-full" for="colab-done">Collaborateur assigné</label>
          <p-tag id="colab-done" styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium bg-blue"
                 value="{{ taskEditing.collaborator?.firstName + ' ' + taskEditing.collaborator?.lastName }}"
                 [rounded]="true">
          </p-tag>
        </div>
        <div class="field col-12">
          <label class="w-full" for="contractor-done">Donneur d'ordre</label>
          <p-tag id="contractor-done" styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium bg-blue"
                 value="{{taskEditing.contractor.firstName + ' ' + taskEditing.contractor.lastName }}"
                 [rounded]="true">
          </p-tag>
        </div>
      </div>
    </div>
    <!-- COMMENTAIRES DE LA TÂCHE -->
    <div class="mt-6 grid" *ngIf="taskEditing.comments?.length">
      <div class="mb-4 col-12 surface-100 p-3"
           *ngFor="let comment of taskEditing.comments | sortBydate : 'creationDate'">
        <!-- COMMENTAIRE HEADER -->
        <div class="pb-3 mb-3 border-bottom align-items-center">
          <p class="m-0 text-right">Le {{comment.creationDate | date: 'dd/MM/yyyy'}}</p>
          <div *ngIf="comment.blocked || comment.resolved"
               class="flex flex-row mt-3 justify-content-end">
            <div *ngIf="comment.resolved">
              <p-chip icon="pi pi-lock-open" label="Point débloqué"
                      styleClass="text-xs font-bold bg-success text-white px-3 custom-chip"></p-chip>
            </div>
            <div *ngIf="comment.blocked && !comment.resolved">
              <p-chip icon="pi pi-lock" label="Point bloquant"
                      styleClass="text-xs font-bold bg-danger text-white px-3 custom-chip"></p-chip>
            </div>
            <div *ngIf="comment.blocked && !comment.resolved">
              <p-button
                type="submit"
                icon="pi pi-unlock"
                styleClass="ml-3 p-button-success p-button-raised p-button-text bg-white py-1"
                (click)="unlockComment(comment.id)">
              </p-button>
            </div>
          </div>
        </div>
        <!-- COMMENTAIRE BODY -->
        <div class="grid">
          <!-- DE -->
          <div class="flex flex-row col-12">
            <p class="w-4rem mt-1">De :</p>
            <div>
              <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle"
                     value="{{ comment.author.lastName }} {{ comment.author.firstName }}"
                     [rounded]="true">
              </p-tag>
            </div>
          </div>
          <!-- POUR -->
          <div *ngIf="comment.assignedUsers.length > 0" class="flex flex-row col-12">
            <p class="w-4rem mt-1">Pour :</p>
            <div class="flex flex-column">
              <div *ngFor="let assignedUser of comment.assignedUsers">
                <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle mb-2"
                       value="{{ assignedUser.lastName }} {{ assignedUser.firstName }}"
                       [rounded]="true">
                </p-tag>
              </div>
            </div>
          </div>
          <p class="text-from-editor col-12" [innerHtml]="comment.content"></p>
        </div>
      </div>
    </div>
    <!-- BOUTONS D'ACTION -->
    <div class="flex justify-content-between mt-5">
      <p-button label="Retour" icon="pi pi-arrow-left" styleClass="p-button-primary p-button-raised p-button-text"
                (click)="formCancel()">
      </p-button>
    </div>
  </div>
</ng-container>

<!-- DESKTOP -->
<ng-template #desktop>
  <ng-container *ngIf="!isFormReady && isEditable">
    <h1>
      <p-skeleton class="w-4" height="43px"></p-skeleton>
    </h1>
    <p-skeleton class="w-4" height="188px"></p-skeleton>
  </ng-container>
  <form [formGroup]="stepForm" *ngIf="isFormReady && isEditable" class="grid">
    <ng-container formArrayName="stepGroups">
      <ng-container [formGroupName]="groupIndex">
        <ng-container formArrayName="tasks">
          <ng-container [formGroupName]="taskIndex">

            <!-- ASIDE -->
            <div class="col-3 p-0 mt-5">
              <div class="card">

                <ng-template [ngIf]="isFormEditing && stepEditing.name">
                  <!-- SECTION ÉTAPE EN COURS -->
                  <p class="m-0 mb-2 text-primary font-semibold pl-0">Étape en cours</p>
                  <div class="divider bg-primary"></div>
                  <p-tag styleClass="step-tag mt-4 text-base">
                    <div class="flex flex-column">
                      {{stepEditing.name}}
                      <div *ngIf="stepEditing.product !== null" class="ml-3">- {{stepEditing.product}}</div>
                      <div *ngIf="stepEditing.amount !== null" class="ml-3">- Montant: {{stepEditing.amount | number:'':'fr-FR'}} €</div>
                    </div>
<!--                    {{stepEditing | json}}-->
                  </p-tag>
                </ng-template>

                <!-- SECTION ATTRIBUTS DE LA TÂCHE -->
                <p class="m-0 mb-2 text-primary font-semibold pl-0"
                   [ngClass]="{'mt-6': isFormEditing && stepEditing.name}">Priorisation</p>
                <div class="divider bg-primary"></div>

                <!-- CHAMP PRIORITÉ -->
                <div class="flex align-items-center mt-4">
                  <p-inputSwitch class="switch-danger" formControlName="isPriority"
                                 id="is-priority-input">
                  </p-inputSwitch>
                  <label for="is-priority-input"
                         class="ml-2"
                         [ngClass]="{'text-400' : !getTaskIsPriority(groupIndex, taskIndex)?.value}">{{ getTaskIsPriority(groupIndex, taskIndex)?.value ? "Prioritaire" : "Non prioritaire" }}
                  </label>
                </div>
                <!-- IMPORTANCE -->
                <div class="flex flex-column mt-4">
                  <label>Importance</label>
                  <app-importance-input radioName="importance"
                                        [formControl]="$any(getTaskImportance(groupIndex, taskIndex))">
                  </app-importance-input>
                  <lib-field-error
                    *ngIf="getTaskImportance(groupIndex, taskIndex)?.touched && getTaskImportance(groupIndex, taskIndex)?.errors"
                    [fieldError]="getTaskImportance(groupIndex, taskIndex)?.errors">
                  </lib-field-error>
                </div>
                <!-- SI ÉDITION : SECTION AVANCEMENT -->
                <ng-template [ngIf]="isFormEditing">
                  <p class="mt-6 mb-2 text-primaryœ font-semibold pl-0">Avancement</p>
                  <div class="divider bg-primary"></div>

                  <!-- STATUT DE LA TÂCHE -->
                  <div class="field flex align-content-center mt-4">
                    <div class="flex flex-column">
                      <div *ngFor="let status of statusOptions" class="mr-5 mb-3 flex flex-row align-items-center">
                        <p-radioButton value="{{status.value}}"
                                       label="{{status.label}}"
                                       formControlName="status"
                                       [disabled]="status.disabled || (status.value === 'DONE' && taskHasBlockedComment)"
                                       (onClick)="onStatusClick(status.value)"
                                       [pTooltip]="status.value === 'DONE' && taskHasBlockedComment ? 'Le commentaire bloquant empêche la clôture de la tâche' : ''"
                        ></p-radioButton>
                      </div>
                    </div>
                  </div>

                  <!-- SI ÉDITION : SECTION AVANT DE CLÔTURER -->
                  <p class="mt-5 mb-2 text-primary font-semibold pl-0">Avant de clôturer...</p>
                  <div class="divider bg-primary"></div>
                  <div class="w-10">
                    <!-- CHARGE RÉELLE -->
                    <div class="mt-4">
                      <div class="field flex flex-column">
                        <label for="real-work-input">Charge réelle</label>
                        <p-inputNumber formControlName="realWorkload"
                                       id="real-work-input"
                                       spinnerMode="horizontal"
                                       [min]="0"
                                       [step]="15"
                                       [showButtons]="true"
                                       buttonLayout="horizontal"
                                       decrementButtonClass="p-button"
                                       incrementButtonClass="p-button"
                                       incrementButtonIcon="pi pi-plus"
                                       decrementButtonIcon="pi pi-minus"
                                       suffix=" min"
                                       class="w-full"
                                       styleClass="w-full"
                                       inputStyleClass="w-full min-w-0">
                        </p-inputNumber>
                        <lib-field-error
                          *ngIf="getTaskRealWorkload(groupIndex, taskIndex)?.touched && getTaskRealWorkload(groupIndex, taskIndex)?.errors"
                          [fieldError]="getTaskRealWorkload(groupIndex, taskIndex)?.errors">
                        </lib-field-error>
                      </div>
                    </div>
                    <!-- DATE DE CLOTURE -->
                    <div class="mt-4" [hidden]="!displayCloseDate">
                      <div class="field flex flex-column">
                        <label for="closing-date-input">Date de clôture</label>
                        <p-calendar appendTo="body"
                                    formControlName="closingDate"
                                    id="closing-date-input"
                                    styleClass="w-full"
                                    dateFormat="dd/mm/yy"
                                    [showIcon]="true"
                                    [firstDayOfWeek]=1
                                    [disabled]="true"
                                    [disabledDays]="[0,6]"
                                    [minDate]="getTaskStartDate(groupIndex, taskIndex)?.value"
                        >
                        </p-calendar>
                        <lib-field-error
                          *ngIf="getTaskClosingDate(groupIndex, taskIndex)?.touched && getTaskClosingDate(groupIndex, taskIndex)?.errors"
                          [fieldError]="getTaskClosingDate(groupIndex, taskIndex)?.errors">
                        </lib-field-error>
                      </div>
                    </div>
                  </div>
                </ng-template>

              </div>
            </div>
            <!-- PARTIE DROITE DU FORMULAIRE -->
            <div class="col-9 pl-6">
              <!-- HEADER SI CRÉATION -->
              <ng-template [ngIf]="!isFormEditing">
                <h2 class="mt-5">Nouvelle tâche suivi client</h2>
              </ng-template>
              <!-- HEADER SI ÉDITION -->
              <ng-template [ngIf]="isFormEditing">
                <div class="flex align-items-center mt-5">
                  <!-- INDICATEUR TEMPS RESTANT -->
                  <div
                    *ngIf="['WARNING', 'EXCEED'].includes(taskRemainingTime)"
                    [ngClass]="{'bg-warning': taskRemainingTime === 'WARNING', 'bg-danger': taskRemainingTime === 'EXCEED'}"
                    class="flex-shrink-0 indicator text-white text-2xl font-semibold flex justify-content-center align-items-center mr-4">
                    <svg width="20" height="20" viewBox="0 0 512 512">
                      <path
                        d="M405.333,102.99V64C428.865,64,448,44.865,448,21.333V10.667C448,4.771,443.229,0,437.333,0H74.667     C68.771,0,64,4.771,64,10.667v10.667C64,44.865,83.135,64,106.667,64v38.99c0,39.25,16.729,76.823,45.906,103.073L208.052,256     l-55.479,49.938c-29.177,26.25-45.906,63.823-45.906,103.073V448C83.135,448,64,467.135,64,490.667v10.667     C64,507.229,68.771,512,74.667,512h362.667c5.896,0,10.667-4.771,10.667-10.667v-10.667C448,467.135,428.865,448,405.333,448     v-38.99c0-39.25-16.729-76.823-45.906-103.073L303.948,256l55.479-49.938C388.604,179.813,405.333,142.24,405.333,102.99z      M85.333,21.333h341.333c0,11.76-9.573,21.333-21.333,21.333H106.667C94.906,42.667,85.333,33.094,85.333,21.333z      M426.667,490.667H85.333c0-11.76,9.573-21.333,21.333-21.333h298.667C417.094,469.333,426.667,478.906,426.667,490.667z      M181.329,448L256,348.448L330.671,448H181.329z M345.156,190.208l-64.292,57.865c-2.25,2.021-3.531,4.906-3.531,7.927     s1.281,5.906,3.531,7.927l64.292,57.865C369.844,344.01,384,375.802,384,409.01V448h-26.672l-92.797-123.729     c-4.021-5.375-13.042-5.375-17.063,0L154.672,448H128v-38.99c0-33.208,14.156-65,38.844-87.219l64.292-57.865     c2.25-2.021,3.531-4.906,3.531-7.927s-1.281-5.906-3.531-7.927l-64.292-57.865C142.156,167.99,128,136.198,128,102.99V64h256     v38.99C384,136.198,369.844,167.99,345.156,190.208z"
                        fill="#ffffff" data-original="#000000" class=""/>
                      <path
                        d="M329.521,149.333H182.469c-4.219,0-8.042,2.49-9.75,6.344c-1.708,3.865-0.99,8.365,1.854,11.49l1.667,1.948     c1.531,1.823,3.083,3.635,4.854,5.219l67.75,61.354c2.031,1.844,4.594,2.76,7.156,2.76s5.125-0.917,7.156-2.76l67.729-61.344     c1.792-1.604,3.344-3.406,4.865-5.229l1.667-1.948c2.844-3.125,3.563-7.625,1.854-11.49     C337.563,151.823,333.74,149.333,329.521,149.333z M256,213.385l-47.177-42.719h94.344L256,213.385z"
                        fill="#ffffff" data-original="#000000" class=""/>
                    </svg>
                  </div>
                  <!-- NOM DE LA TÂCHE -->
                  <h2 class="my-0">{{getTaskName(groupIndex, taskIndex)?.value}}</h2>
                </div>
              </ng-template>
              <div class="divider mt-3 mb-5"></div>

              <!-- RESTE DES CHAMPS -->
              <div class="mt-5 p-2">
                <!-- CONTACT(S) CONCERNE(S) -->
                <div class="col-12 p-0">
                  <div class="field col-4 px-0" formArrayName="relatedPeople">
                    <label>Contact(s) concerné(s)</label>
                    <div
                      *ngFor="let client of getTaskRelatedPeople(this.groupIndex, this.taskIndex).controls; let index = index"
                      class="flex">
                      <div class="flex w-full p-fluid">
                        <p-autoComplete
                          inputStyleClass="form-control"
                          class="w-full"
                          formControlName="{{ index }}"
                          [forceSelection]="true"
                          [suggestions]="clientSearchService.searchBarResults"
                          placeholder="Rechercher un client"
                          (completeMethod)="clientSearchService.searchClient($event)"
                          field="label"
                          [minLength]="3">
                        </p-autoComplete>
                        <lib-field-error
                          *ngIf="getTaskRelatedPeople(this.groupIndex, this.taskIndex)?.touched && getTaskRelatedPeople(0, 0)?.errors"
                          [fieldError]="getTaskRelatedPeople(0, 0)?.errors"></lib-field-error>
                        <ng-container *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
                        <ng-template #addClientButton>
                          <button pButton type="button" icon="pi pi-plus"
                                  class="flex-shrink-0 border-noround border-400 mb-2 ml-2"
                                  (click)="addRelatedPeople()"></button>
                        </ng-template>
                        <ng-template #removeClientButton>
                          <button pButton type="button" icon="pi pi-times"
                                  class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2"
                                  (click)="removeRelatedPeople(index)"></button>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- SI ÉDITION : CHAMP NOM DE LA TÂCHE -->
                <div class="mt-3" *ngIf="!isFormEditing">
                  <div class="field col-12 px-0">
                    <label for="name-input">Tâche</label>
                    <input #autofocusInput formControlName="name" id="name-input" type="text" size="30" pInputText
                           class="inputfield w-full">
                    <lib-field-error
                      *ngIf="getTaskName(groupIndex, taskIndex)?.touched && getTaskName(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskName(groupIndex, taskIndex)?.errors"></lib-field-error>
                  </div>
                </div>
                <div class="grid mt-3">
                  <!-- DATE DE DEBUT -->
                  <div class="field col-4">
                    <label for="start-date-input">Date de début</label>
                    <p-calendar styleClass="w-full"
                                appendTo="body"
                                placeholder="{{ startDateDefaultValue | date: 'dd/MM/yyyy'}}"
                                id="start-date-input"
                                (onSelect)="onStartDateSelect($event)"
                                [minDate]="today"
                                [firstDayOfWeek]=1
                                [disabledDays]="[0,6]"
                                formControlName="startDate"
                                dateFormat="dd/mm/yy"
                                [showIcon]="true"
                    >
                    </p-calendar>
                    <lib-field-error
                      *ngIf="getTaskStartDate(groupIndex, taskIndex)?.touched && getTaskStartDate(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskStartDate(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                  <!-- DATE DE FIN -->
                  <div class="field col-4">
                    <label for="deadline-date-input">Date d'échéance</label>
                    <p-calendar styleClass="w-full" appendTo="body"
                                placeholder="{{ deadLineDefaultValue | date: 'dd/MM/yyyy'}}"
                                id="deadline-date-input"
                                [firstDayOfWeek]=1
                                [disabledDays]="[0,6]"
                                [minDate]="today"
                                formControlName="deadlineDate" dateFormat="dd/mm/yy" [showIcon]="true"
                                [minDate]="getTaskStartDate(groupIndex, taskIndex)?.value">
                    </p-calendar>
                    <lib-field-error
                      *ngIf="getTaskDeadlineDate(groupIndex, taskIndex)?.touched && getTaskDeadlineDate(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskDeadlineDate(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                  <!-- CHARGE PREVUE -->
                  <div class="field col-4">
                    <label for="planned-work-input">Charge prévue</label>
                    <p-inputNumber formControlName="plannedWorkload"
                                   [showButtons]="true"
                                   buttonLayout="horizontal"
                                   id="planned-work-input"
                                   spinnerMode="horizontal"
                                   [min]="0"
                                   [step]="15"
                                   [disabled]="isFormEditing"
                                   decrementButtonClass="p-button" incrementButtonClass="p-button"
                                   incrementButtonIcon="pi pi-plus"
                                   decrementButtonIcon="pi pi-minus" suffix=" min"
                                   class="w-full"
                                   styleClass="w-full"
                                   inputStyleClass="w-full min-w-0">
                    </p-inputNumber>
                    <lib-field-error
                      *ngIf="getTaskPlannedWorkload(groupIndex, taskIndex)?.touched && getTaskPlannedWorkload(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskPlannedWorkload(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                </div>
                <div class="grid mt-3">
                  <!-- POLE CONCERNE -->
                  <div class="field col-4">
                    <label for="poles-input">Pôle concerné</label>
                    <p-dropdown styleClass="w-full" formControlName="pole" id="poles-input"
                                [options]="polesOptions" optionLabel="label" optionValue="value" placeholder="...">
                    </p-dropdown>
                    <lib-field-error
                      *ngIf="getTaskPoles(groupIndex, taskIndex)?.touched && getTaskPoles(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskPoles(groupIndex, taskIndex)?.errors"></lib-field-error>
                  </div>
                  <!-- COLLABOREUR ASSIGNE -->
                  <div class="field col-4">
                    <label for="collaborator-input">Collaborateur assigné</label>
                    <p-dropdown styleClass="w-full" formControlName="collaborator" id="collaborator-input"
                                [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                placeholder="..."
                                appendTo="body">
                    </p-dropdown>
                  </div>
                  <!-- DONNEUR D'ORDRE -->
                  <div class="field col-4">
                    <label for="contractor-input">Donneur d'ordre</label>
                    <p-dropdown appendTo="body" styleClass="w-full" formControlName="contractor" id="contractor-input"
                                [options]="contractorOptions" optionLabel="label" optionValue="value" placeholder="..."
                                [autoDisplayFirst]="false">
                    </p-dropdown>
                    <lib-field-error
                      *ngIf="getTaskContractor(groupIndex, taskIndex)?.touched && getTaskContractor(groupIndex, taskIndex)?.errors"
                      [fieldError]="getTaskContractor(groupIndex, taskIndex)?.errors">
                    </lib-field-error>
                  </div>
                </div>
                <!-- TEXTE D'AIDE -->
                <div class="grid mt-3" *ngIf="taskEditing && taskEditing.helpText && taskEditing.helpText !== ''">
                  <div class="field col-12">
                    <p-tag [rounded]="true" value="i" class="mr-2"></p-tag>
                    <label for="help-text">Aide: </label>
                    <span id="help-text" class="ml-2" [innerHTML]="taskEditing.helpText | linkify"></span>
                  </div>
                </div>
                <ng-template [ngIf]="!isFormEditing">
                  <!-- COMMENTAIRE INPUT -->
                  <div class="mt-5 w-full">
                    <div class="field" formArrayName="comments">
                      <ng-container [formGroupName]="0">
                        <p class="text-xl text-primary font-semibold">Ajoutez un commentaire</p>
                        <div class="divider mt-3 mb-5"></div>
                        <p-editor id="comments-input" formControlName="content" styleClass="w-full"
                                  [style]="{'height':'130px'}">
                          <ng-template pTemplate="header">
                            <span class="ql-format-group"></span>
                          </ng-template>
                        </p-editor>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
                <!-- COMMENTAIRES LISTE -->
                <ng-template [ngIf]="isFormEditing">
                  <ng-template [ngIf]="isFormEditing && taskEditing.comments?.length">
                    <p class="text-xl text-primary font-semibold mt-6">Commentaire(s)</p>
                    <div class="divider mt-3 mb-5"></div>
                    <div class="mt-6 grid">
                      <div class="mb-4 col-12 surface-100 p-3"
                           *ngFor="let comment of taskEditing.comments | sortBydate : 'creationDate'">
                        <div class="flex flex-row pb-3 mb-3 border-bottom align-items-center">
                          <p class="m-0">Le {{comment.creationDate | date: 'dd/MM/yyyy'}}</p>
                          <div *ngIf="comment.blocked || comment.resolved" class="flex flex-row ml-auto">
                            <div *ngIf="comment.resolved">
                              <p-chip icon="pi pi-lock-open" label="Point débloqué"
                                      styleClass="text-xs font-bold bg-success text-white px-3 custom-chip"></p-chip>
                            </div>
                            <div *ngIf="comment.blocked && !comment.resolved">
                              <p-chip icon="pi pi-lock" label="Point bloquant"
                                      styleClass="text-xs font-bold bg-danger text-white px-3 custom-chip"></p-chip>
                            </div>
                            <div *ngIf="comment.blocked && !comment.resolved">
                              <p-button
                                type="submit"
                                icon="pi pi-unlock"
                                styleClass="ml-3 p-button-success p-button-raised p-button-text bg-white py-1"
                                (click)="unlockComment(comment.id)">
                              </p-button>
                            </div>
                          </div>
                        </div>
                        <div class="grid">
                          <div class="col-4">
                            <div class="flex flex-row mb-3">
                              <p class="w-4rem my-1">De :</p>
                              <div>
                                <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle"
                                       value="{{ comment.author.lastName }} {{ comment.author.firstName }}"
                                       [rounded]="true">
                                </p-tag>
                              </div>
                            </div>
                            <div *ngIf="comment.assignedUsers.length > 0" class="flex flex-row">
                              <p class="w-4rem my-1">Pour :</p>
                              <div class="flex flex-column">
                                <div *ngFor="let assignedUser of comment.assignedUsers">
                                  <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle mb-2"
                                         value="{{ assignedUser.lastName }} {{ assignedUser.firstName }}"
                                         [rounded]="true">
                                  </p-tag>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p class="text-from-editor col-8 my-2" [innerHtml]="comment.content"></p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <div class="w-full">
                    <div class="mt-5 field" formArrayName="comments">
                      <ng-container *ngFor="let item of getTaskComments(groupIndex, taskIndex).controls; index as i">
                        <ng-container [formGroupName]="i"
                                      *ngIf="getTaskComments(groupIndex, taskIndex).controls.length - 1 === i">
                        <p class="text-xl text-primary font-semibold">Ajoutez un commentaire</p>
                        <div class="divider mt-3 mb-5"></div>
                        <div class="mt-5 flex">
                          <div>
                            <p>Collaborateur(s) concerné(s)</p>
                            <div
                              *ngFor="let commentAssignedUser of getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.controls; let index = index"
                              class="flex">
                              <ng-container formArrayName="assignedUsers">
                                <div class="flex w-full p-fluid">
                                  <p-dropdown styleClass="w-12rem" formControlName="{{ index }}"
                                              [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                              placeholder="..." appendTo="body">
                                  </p-dropdown>
                                  <ng-container
                                    *ngIf="index === 0 ; then addClientButton else removeClientButton"></ng-container>
                                  <ng-template #addClientButton>
                                    <button pButton type="button" icon="pi pi-plus"
                                            class="flex-shrink-0 border-noround border-400 mb-2 ml-2"
                                            (click)="addCommentAssignedUser()"></button>
                                  </ng-template>
                                  <ng-template #removeClientButton>
                                    <button pButton type="button" icon="pi pi-times"
                                            class="flex-shrink-0 bg-white text-danger border-noround border-400 mb-2 ml-2"
                                            (click)="removeCommentAssignedUser(index)"></button>
                                  </ng-template>
                                </div>
                              </ng-container>
                            </div>

                            <!--FAKE DROPDOWN IN CASE NO ASSIGNED USER (JUST FOR DISPLAY, OTHERWISE THE DROPDOWN DISAPPEAR)-->
                            <div
                              *ngIf="getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.controls.length === 0"
                              class="flex">
                              <div class="flex w-full p-fluid">
                                <p-dropdown styleClass="w-12rem"
                                            [options]="collaboratorOptions" optionLabel="label" optionValue="value"
                                            placeholder="..." appendTo="body">
                                </p-dropdown>

                                <button pButton type="button" icon="pi pi-plus"
                                        class="flex-shrink-0 border-noround border-400 mb-2 ml-2"></button>
                              </div>
                            </div>

                            <lib-field-error
                              *ngIf="getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.touched && getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.errors"
                              [fieldError]="getTaskLatestCommentAssignedUser(this.groupIndex, this.taskIndex)?.errors"></lib-field-error>
                          </div>
                          <div class="flex flex-column align-items-start mt-6 ml-4">
                            <div class="flex align-items-center mt-2">
                              <p-inputSwitch formControlName="blocked" id="is-blocking-input" class="mr-3"
                                             (onChange)="blockingCommentWarning()">
                              </p-inputSwitch>
                              <label>Point de blocage</label>
                            </div>
                            <p *ngIf="blockingCommentWarningState">Cette action va envoyer une notification "Point
                              bloquant" au(x) collaborateur(s) assigné(s).</p>
                          </div>
                        </div>
                        <div class="mt-5">
                          <p-editor id="comments-edit-input" formControlName="content" [style]="{'height':'130px'}">
                            <ng-template pTemplate="header">
                              <span class="ql-format-group"></span>
                            </ng-template>
                          </p-editor>
                        </div>
                        <lib-field-error
                          *ngIf="getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.touched && getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.errors"
                          [fieldError]="getTaskLatestCommentContent(this.groupIndex, this.taskIndex)?.errors"></lib-field-error>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="col flex justify-content-end mt-5">
                <ng-template [ngIf]="isFormEditing && leaderOrPoleManager">
                  <p-button label="Supprimer" icon="pi pi-trash" class="mr-auto"
                            styleClass="p-button-danger p-button-raised p-button-text"
                            (click)="formDelete()">
                  </p-button>
                </ng-template>
                <p-button label="Annuler" icon="pi pi-times" styleClass="p-button-danger p-button-raised p-button-text"
                          (click)="formCancel()">
                </p-button>
                <p-button
                  type="submit"
                  label="{{getTaskStatus(groupIndex,taskIndex)?.value === rawTaskStatus.DONE ? 'Clore la tâche' : 'Enregistrer'}}"
                  icon="pi pi-check"
                  styleClass="ml-3 p-button-success p-button-raised" (click)="formSubmit()">
                </p-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
  <!-- SI TÂCHE TERMINÉE : VISUALISATION DE LA TÂCHE  -->
  <div *ngIf="!isEditable">
    <h2 class="mt-5">{{taskEditing.name}}</h2>
    <div class="col-12 grid mt-5">
      <div class="field col-3">
        <label for="start-date-done">Date de début</label>
        <div id="start-date-done" class="font-bold">{{taskEditing.startDate | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div class="field col-3">
        <label for="deadeline-date-done">Date de d'échéance</label>
        <div id="deadeline-date-done" class="font-bold">{{taskEditing.deadlineDate | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div class="field col-3">
        <label for="closing-date-done">Date de clôture</label>
        <div id="closing-date-done" class="font-bold">{{taskEditing.closingDate | date: 'dd/MM/yyyy' }}</div>
      </div>
      <div class="field col-3">
        <label for="real-workload-done">Charge réelle</label>
        <div id="real-workload-done" class="font-bold">{{taskEditing.realWorkload}} min</div>
      </div>
    </div>
    <div class="col-12 grid mt-5">
      <div class="field col-3">
        <label for="pole-done">Pôle concerné</label>
        <div id="pole-done" class="font-bold">{{translatorService.getTranslation(taskEditing.pole) | async}}</div>
      </div>
      <div class="field col-3" *ngIf="taskEditing.collaborator">
        <label class="w-full" for="colab-done">Collaborateur assigné</label>
        <p-tag id="colab-done" styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium bg-blue"
               value="{{ taskEditing.collaborator?.firstName + ' ' + taskEditing.collaborator?.lastName }}"
               [rounded]="true">
        </p-tag>
      </div>
      <div class="field col-3">
        <label class="w-full" for="contractor-done">Donneur d'ordre</label>
        <p-tag id="contractor-done" styleClass="tag-nowrap mr-1 mb-1 px-2 text-xs font-medium bg-blue"
               value="{{taskEditing.contractor.firstName + ' ' + taskEditing.contractor.lastName }}"
               [rounded]="true">
        </p-tag>
      </div>
      <!-- CLIENTS CONCERNÉS -->
      <div class="field col-3">
        <label class="w-full">Contact(s) concerné(s) : </label>
        <span class="cursor-pointer" *ngFor="let naturalPerson of stepEditing.naturalPersons"
              [routerLink]="['/natural-person', naturalPerson.id]">
          <p-tag severity="info"
                 styleClass="tag-nowrap mr-2 mb-1 px-2 font-medium bg-info"
                 value="{{ naturalPerson.lastName }} {{ naturalPerson.firstName }}"
                 [rounded]="true">
          </p-tag>
        </span>
        <span class="cursor-pointer" *ngFor="let legalPerson of stepEditing.legalPersons"
              [routerLink]="['/legal-person', legalPerson.id]">
          <p-tag severity="info"
                 styleClass="tag-nowrap mr-2 mb-1 px-2 font-medium bg-warning"
                 value="{{ legalPerson.name }}"
                 [rounded]="true">
          </p-tag>
        </span>
      </div>
    </div>

    <!-- COMMENTAIRES DE LA TÂCHE -->
    <div class="mt-6 grid" *ngIf="taskEditing.comments?.length">
      <div class="mb-4 col-12 surface-100 p-3"
           *ngFor="let comment of taskEditing.comments | sortBydate : 'creationDate'">
        <div class="flex flex-row pb-3 mb-3 border-bottom">
          <p class="m-0">Le {{comment.creationDate | date: 'dd/MM/yyyy'}}</p>
          <div *ngIf="comment.resolved" class="flex flex-row ml-auto">
            <p-chip icon="pi pi-lock-open" label="Point débloqué"
                    styleClass="text-xs font-bold bg-success text-white px-3 custom-chip"></p-chip>
          </div>
        </div>
        <div class="grid">
          <div class="col-3">
            <div class="flex flex-row mb-3">
              <p class="w-4rem my-1 flex-shrink-0">De :</p>
              <div>
                <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle"
                       value="{{ comment.author.lastName }} {{ comment.author.firstName }}"
                       [rounded]="true">
                </p-tag>
              </div>
            </div>
            <div *ngIf="comment.assignedUsers.length > 0" class="flex flex-row">
              <p class="w-4rem my-1 flex-shrink-0">Pour :</p>
              <div class="flex flex-column">
                <div *ngFor="let assignedUser of comment.assignedUsers">
                  <p-tag styleClass="tag-nowrap bg-blue text-xs font-medium vertical-align-middle mb-2"
                         value="{{ assignedUser.lastName }} {{ assignedUser.firstName }}"
                         [rounded]="true">
                  </p-tag>
                </div>
              </div>
            </div>
          </div>
          <p class="text-from-editor col-8 my-2" [innerHtml]="comment.content"></p>
        </div>
      </div>
    </div>
    <!-- BOUTONS D'ACTION -->
    <div class="flex justify-content-between mt-5">
      <p-button label="Retour" icon="pi pi-arrow-left" styleClass="p-button-primary p-button-raised p-button-text"
                (click)="formCancel()">
      </p-button>
    </div>
  </div>
</ng-template>
