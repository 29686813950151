import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  CompteRendu,
  CompteRenduQuery,
  CompteRenduService,
  LegalPerson,
  NaturalPerson,
  SessionQuery,
  NavigationService,
  UiScreenQuery,
  ClientType
} from 'common';
import {
  CreateMeetingReportFormComponent
} from '../../components/create-meeting-report-form/create-meeting-report-form.component';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import {ID} from '@datorama/akita';

@Component({
  selector: 'app-page-meeting-report-new',
  templateUrl: './page-meeting-report-new.component.html',
  styleUrls: ['./page-meeting-report-new.component.scss']
})
export class PageMeetingReportNewComponent implements OnInit, OnDestroy {
  meetingReportForm: CreateMeetingReportFormComponent;
  authenticatedUserId: ID | undefined;
  compteRendu: CompteRendu;
  autoSave: ReturnType<typeof setTimeout>;

  constructor(
    private compteRenduService: CompteRenduService,
    private compteRenduQuery: CompteRenduQuery,
    private messageService: MessageService,
    private router: Router,
    private sessionQuery: SessionQuery,
    private navigation: NavigationService,
    public uiScreenQuery: UiScreenQuery,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  @ViewChild(CreateMeetingReportFormComponent, { static: false }) set ref(meetingReportForm: CreateMeetingReportFormComponent) {
    if (meetingReportForm) {
      this.meetingReportForm = meetingReportForm;
      this.meetingReportForm.createFormGroup(this.authenticatedUserId);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.sessionQuery.select(['id']).subscribe(authenticatedUser => {
      if (authenticatedUser.id) {
        this.authenticatedUserId = authenticatedUser.id;
      }
    });

    // Création automatique du compte rendu au bout de 5 minutes
    this.autoSave = setTimeout(() => this.formSubmit(true), 1000 * 60 * 5);
  }

  formSubmit(autoSave = false) {
    if (this.meetingReportForm.form.invalid) {
      this.meetingReportForm.form.markAllAsTouched();
      return;
    }

    const values = this.meetingReportForm.form.value;

    const personnesPhysiquesConcernees: NaturalPerson[] = values.clientsConcernes.filter((client: any) => client.value.clientType === ClientType.NATURAL_PERSON);
    const personnesMoralesConcernees: LegalPerson[] = values.clientsConcernes.filter((client: any) => client.value.clientType === ClientType.LEGAL_PERSON);

    const compteRendu = {
      ...values,
      naturalPersonsConcerned: personnesPhysiquesConcernees.map((personnePhysique: any) => personnePhysique.value),
      legalPersonsConcerned: personnesMoralesConcernees.map((personneMorale: any) => personneMorale.value),
    };

    this.compteRenduService.add<CompteRendu>(compteRendu).subscribe({
      next: (createdMeetingReport) => {
        const firstClientConcerne = this.meetingReportForm.clientsConcernesF?.value[0].value;
        const redirectionRoute = firstClientConcerne.clientType === ClientType.NATURAL_PERSON ? '/natural-person' : '/legal-person';

        this.messageService.add({
          severity: 'success',
          summary: 'Le compte rendu a bien été créé.',
          life: 10000
        });

        if (this.meetingReportForm.defaultClientConcerneValue) {
          this.navigation.back();
        } else {
          this.router.navigate(autoSave ? ['/meeting-report-edit', createdMeetingReport.id] : [redirectionRoute, firstClientConcerne.id]);
        }
      },
      error: () => this.messageService.add({
        severity: 'error',
        summary: 'Une erreur est survenue lors de la création',
        detail: 'Veuillez essayer à nouveau',
        life: 15000
      }),
    });
  }

  ngOnDestroy() {
    clearTimeout(this.autoSave);
  }

  formCancel() {
    this.navigation.back();
  }
}
