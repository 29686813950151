import { Injectable } from '@angular/core';
import { NgEntityService } from '@datorama/akita-ng-entity-service';
import { EmailStore, EmailState } from './email.store';

@Injectable({ providedIn: 'root' })
export class EmailService extends NgEntityService<EmailState> {

  constructor(protected override store: EmailStore) {
    super(store);
  }

}
