import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-form-guard-dialog',
  templateUrl: './form-guard-dialog.component.html',
  styleUrls: ['./form-guard-dialog.component.scss']
})
  export class FormGuardDialogComponent {
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  cancel(){
    this.ref.close('cancel');
  }

  save(){
    this.ref.close('save');
  }

  noSave(){
    this.ref.close('noSave');
  }

}
