import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {HttpResponseMessageType, UiScreenQuery, UserService, SessionService} from 'common';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-page-recueil-client-bienvenue',
  templateUrl: './page-recueil-client-bienvenue.component.html',
  styleUrls: ['./page-recueil-client-bienvenue.component.scss']
})
export class PageRecueilClientBienvenueComponent implements OnInit {

  token: string | null = null;
  userEmail: string | null = null;
  userId: number;
  loading = false;
  error = false;
  errorMsg = 'Votre lien n\'est plus  valide, veuillez contacter votre conseiller EMD Patrimoine.';
  form: UntypedFormGroup;
  submissionLoading = false;

  constructor(
    public uiScreenQuery: UiScreenQuery,
    private activeRoute: ActivatedRoute,
    @Inject('config') private config: any,
    private formBuilder: UntypedFormBuilder,
    private cdref: ChangeDetectorRef,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private sessionService: SessionService,
    private httpClient: HttpClient,) {
  }

  get passF() {
    return this.form?.get('password');
  }

  get confirmPassF() {
    return this.form?.get('confirmPass');
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: this.formBuilder.control(null, [
        Validators.required,
        this.validatePasswordStrength(),
        this.checkSamePasswordValidator('confirmPass', true)]),
      confirmPass: this.formBuilder.control(null, [
        Validators.required,
        this.checkSamePasswordValidator('password')])
    });
    this.loading = true;
    this.activeRoute.params.subscribe(params => {
      this.userId = params['userId'];
    });
    this.activeRoute.queryParams
      .subscribe((params) => {
        this.token = params['token'];
        this.userEmail = params['email'];
        this.httpClient.get(this.config.apiUrl + '/check_new_recueil_token?token=' + this.token + '&email=' + this.userEmail).subscribe({
          next: (val) => {
            this.loading = false;
            this.error = false;
            this.cdref.markForCheck();
          },
          error: (val: any) => {
            this.loading = false;
            if (val.error === HttpResponseMessageType.USER_ALREADY_ACTIVATED) {
              this.router.navigate(['/login']);
            } else if (val.error === HttpResponseMessageType.NEW_RECUEIL_TOKEN_EXPIRED) {
              this.error = true;
            } else {
              this.errorMsg = 'Le lien que vous utilisez n\'est pas valide. Veuillez contacter votre conseiller.';
              this.error = true;
            }
            this.cdref.markForCheck();
          }
        });
      });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.submissionLoading = true;
    const token = this.token ? this.token : '';
    const userEmail = this.userEmail ? this.userEmail : '';
    const password: string = this.passF?.value ? this.passF?.value : '';
    this.userService.defineClientPassword(token, userEmail, password).subscribe(res => {
      this.sessionService.login({username: userEmail, password}).subscribe({
        next: (success) => {
          this.router.navigate(['/recueil/NATURAL_PERSON/' + this.userId]);
        }
      });
    });
  }

  /* Password Strength Validator */
  validatePasswordStrength(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // Regex validant présence d'au moins 10 caractères dont : une minuscule, une majuscule et un chiffre
      const strongEnough = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)^.{12,}$/gm.test(control.value);

      return strongEnough ? null : {weakPassword: true};
    };
  }

  /* Same Password Validator */
  checkSamePasswordValidator(
    matchTo: string,
    reverse?: boolean
  ): ValidatorFn {
    return (control: AbstractControl):
      ValidationErrors | null => {
      if (control.parent && reverse) {
        const c = (control.parent?.controls as any)[matchTo] as AbstractControl;
        if (c) {
          c.updateValueAndValidity();
        }
        return null;
      }
      return !!control.parent &&
      !!control.parent.value &&
      control.value ===
      (control.parent?.controls as any)[matchTo].value
        ? null
        : {notSamePassword: true};
    };
  }

}
