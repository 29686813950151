<div [formGroup]="form" class="mt-5">
  <div class="grid p-fluid">
    <div class="field col-3">
      <label *ngIf="clientOrAdmin === 'ADMIN'" for="realiseDonation">A déjà réalisé des donations ?</label>
      <label *ngIf="clientOrAdmin === 'CLIENT'" for="realiseDonation">Avez-vous déjà réalisé des donations ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.realiseDonation}">Non</div>
        <p-inputSwitch  id="realiseDonation"
                        formControlName="realiseDonation"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.realiseDonation}">Oui</div>
      </div>
    </div>
    <div class="field col-3">
      <label *ngIf="clientOrAdmin === 'ADMIN'" for="recuDonation">A déjà reçu des donations ?</label>
      <label *ngIf="clientOrAdmin === 'CLIENT'" for="recuDonation">Avez-vous déjà reçu des donations ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.recuDonation}">Non</div>
        <p-inputSwitch  id="recuDonation"
                        formControlName="recuDonation"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.recuDonation}">Oui</div>
      </div>
    </div>
    <div class="field col-3">
      <label *ngIf="clientOrAdmin === 'ADMIN'" for="redigeTestament">A rédigé un testament ?</label>
      <label *ngIf="clientOrAdmin === 'CLIENT'" for="redigeTestament">Avez-vous déjà rédigé un testament ?</label>
      <div class="flex flex-grow-1 align-items-center">
        <div [ngClass]="{'text-400' : value.redigeTestament}">Non</div>
        <p-inputSwitch  id="redigeTestament"
                        formControlName="redigeTestament"
                        [trueValue]="true"
                        [falseValue]="false"
                        [ngClass]="'mt-2 ml-2 mr-2 align-self-end'"
        ></p-inputSwitch>
        <div [ngClass]="{'text-400' : !value.redigeTestament}">Oui</div>
      </div>
    </div>
  </div>
</div>
